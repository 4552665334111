import './geoLocationField.scss';

import {memo, useEffect} from 'react';

import {effectiveValue} from 'app/modules/work/helpers';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';

const GOOGLE_MAPS_LINK = 'https://maps.google.com?q=';

function GeoLocationField({
  item,
  column,
  value,
  fieldEditable,
  readOnly,
  onChange,
  enableDetectGeoLocation = false,
  className,
}) {
  const {showError} = useSnackNotification();
  const {t} = useTranslate();

  const effValue = effectiveValue(value);
  const field = column?.field;

  const onOpenLink = () => {
    if (effValue?.latitude && effValue?.longitude) {
      window.open(GOOGLE_MAPS_LINK + effValue?.latitude + ',' + effValue?.longitude, '_blank');
    }
  };

  const handleRequestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geoLocation = {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
            timestamp: position?.timestamp,
          };

          if (onChange) {
            onChange && onChange(item, field, effValue, geoLocation);
          }
        },
        (error) => {
          showError(t('sheet_table_geolocation_user_denied'));
        }
      );
    } else {
      showError(t('sheet_table_geolocation_browser_not_support'));
    }
  };

  useEffect(() => {
    if (!effValue?.latitude && !effValue?.longitude && enableDetectGeoLocation) {
      handleRequestLocation();
    }
  }, [effValue?.latitude, effValue?.longitude, enableDetectGeoLocation]);

  return (
    <div
      className={`p-1 d-flex align-items-center justify-content-center ${className}`}
      onClick={onOpenLink}
    >
      {effValue?.latitude && effValue?.longitude ? (
        <span className='title-limit-text-1 cursor-pointer prevent-copying-and-pasting'>
          {effValue?.latitude}, {effValue?.longitude}
        </span>
      ) : null}
    </div>
  );
}

export default memo(GeoLocationField);
