/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  // LineChart,
  BarChart,
  // PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import get from 'lodash/get';
import {registerLocale} from 'react-datepicker';
import {vi} from 'date-fns/locale';
import {getCSSVariableValue} from 'core/assets/ts/_utils';
registerLocale('vi', vi);
// Register the required components

export function ColumnChart({
  className,
  chartWidth,
  widgetHeight = '400px',
  data = null,
  value1Field = null,
  value1Name = '',
  value2Field = null,
  value2Name = '',
  series = [],
  dimensionField = '',
  chartId = 'chartdiv',
}) {
  const echartRef = useRef(null);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    CanvasRenderer,
  ]);

  useEffect(() => {
    if (echartRef.current) {
      const echartInstance = echartRef.current?.getEchartsInstance?.();
      const echartId = echartInstance?.id;
      echartId && echartRef.current?.echarts?.getInstanceById?.(echartId)?.resize?.();
    }
  }, [chartWidth]);

  const fontFamily = getCSSVariableValue('--kt-font-family');

  const option = {
    textStyle: {
      fontFamily: fontFamily,
      fontSize: 12,
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `${params.name} <br/> ${params.marker} ${get(series, '0.name')}: <b>${
          params.value
        }</b> `;
      },
    },
    legend: {
      top: '5%',
      left: 'center',
      show: true,
    },
    label: {
      show: false,
      position: 'center',
    },
    labelLine: {
      show: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45,
      },
      data: !data
        ? []
        : data.map((dataItem) => {
            let name = get(dataItem, dimensionField);
            if (typeof name === 'object') {
              name = name?.label || '';
            }
            return name;
          }),
    },
    yAxis: {
      type: 'value',
    },
    series: series.map(({field, name}, index) => ({
      data: !data
        ? []
        : data.map((dataItem) => {
            let name = get(dataItem, dimensionField);
            if (typeof name === 'object') {
              name = name?.label || '';
            }
            return {
              value: get(dataItem, field),
              name,
            };
          }),
      type: 'bar',
    })),
  };

  return (
    <div className='table-chart-wrapper-1 w-100 table-borderless overflow-auto rounded'>
      <div className='flex-grow-1 p-3'>
        <ReactEChartsCore
          ref={(e) => {
            echartRef.current = e;
          }}
          echarts={echarts}
          option={option}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>
    </div>
  );
}
