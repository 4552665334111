import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api';

const endPoints = {
  createItemTemplate: `/api/template/create`,
  getTemplateBySheet: (id) => `/api/template/sheet/${id}`,
  createItemFromTemplate: `/api/sheet/template/create`,
  updateTemplate: (id) => `/api/template/${id}`,
  removeTemplate: (id) => `/api/template/${id}`,
};

export function createItemTemplate(params) {
  return postApiV2(endPoints.createItemTemplate, params);
}

export function getTemplateBySheet(id) {
  return getApiV2(endPoints.getTemplateBySheet(id));
}

export function createItemFromTemplate(params) {
  return postApiV2(endPoints.createItemFromTemplate, params);
}

export function updateTemplate(id, params) {
  return putApiV2(endPoints.updateTemplate(id), params);
}

export function removeTemplate(id) {
  return deleteApiV2(endPoints.removeTemplate(id));
}
