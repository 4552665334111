import {effectiveValue as getEffectiveValue} from 'app/modules/work/helpers';
import get from 'lodash/get';

export default function AutoNumberField({value, column}) {
  const effectiveValue = getEffectiveValue(value);
  const prefix = get(column, ['settings', 'prefix']);
  const suffix = get(column, ['settings', 'suffix']);

  const formattedValue = effectiveValue ? `${prefix || ''}${effectiveValue}${suffix || ''}` : '';

  return (
    <div className='d-flex align-items-center p-2'>
      <span className='text-ellipsis'>{formattedValue}</span>
    </div>
  );
}
