import './styles.scss'
import React from 'react';
import clsx from 'clsx';
import {KTSVG} from 'core/helpers';
import {
  ThemeModeType,
  useThemeMode,
} from 'core/layouts/layout2/partials/theme-mode/ThemeModeProvider';
import {useTranslate} from 'core/i18n/i18nProvider';

const ThemeModeSwitcher = ({className}) => {
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode();
  const {t} = useTranslate();
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
  };
  return (
    <div className={`theme-mode-switcher ${className}`}>
      <a href='#' className='menu-link px-5 d-flex'>
        <span className='menu-title'>{t('user_header_menu_theme')}</span>
        <span className='icon-arrow-down fs-icon '></span>
      </a>
      <div className='menu-sub'>
        <div className='menu-item px-3 my-1'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'light'})}
            onClick={() => switchMode('light')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>{t('user_header_menu_theme_light')}</span>
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'dark'})}
            onClick={() => switchMode('dark')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen061.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>{t('user_header_menu_theme_dark')}</span>
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}
            onClick={() => switchMode('system')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen062.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>{t('user_header_menu_theme_system')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThemeModeSwitcher;
