import {createSlice} from "@reduxjs/toolkit";

const initialCoreState = {
  listLoading: false,
  actionsLoading: false,
  common: null,
  allUsers: null,
  allTeams: [],
  apps: [],
  usersByIds: {},
  lastError: null,
  user: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const coreSlice = createSlice({
  name: 'core',
  initialState: initialCoreState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    allUsersFetched: (state, action) => {
      const {data} = action.payload;
      const usersByIds = {};
      for (let i = 0; i < data.data.length; i++) {
        const user = data.data[i];
        usersByIds[user._id] = user;
      }
      state.listLoading = false;
      state.error = null;
      state.allUsers = data.data || [];
      state.usersByIds = usersByIds || {};
    },
    allTeamsFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allTeams = data.data || [];
    },
    domainInformationFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.common = data.result ? data.result : [];
    },
    currentUserInfoFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.user = data.result ? data.result : {};
    },

    appsFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.apps = data.data ? data.data : [];
    },
  },
});
