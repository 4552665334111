import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {listColumnEssentials, listColumnsSuperUseful} from '../../../../view/table/constants';
import { removeSign } from 'app/common/_helpers';
const listColumns = [...listColumnsSuperUseful, ...listColumnEssentials];
const SheetDataViewSort = ({sheet}) => {
  const {t} = useTranslate();
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText && searchText !== '') {
      let searchString = removeSign(searchText.toLocaleLowerCase());
      let newListView = sheet?.columns?.filter((column) => {
        let columnName = column?.name ?? '';
        columnName = removeSign(columnName.toLocaleLowerCase());
        return columnName.includes(searchString);
      });
      setColumns(newListView);
    } else {
      let listColumn = sheet?.columns ?? [];
      listColumn = listColumn.filter((column) => column?.field !== 'complete');
      setColumns(listColumn);
    }
  }, [searchText]);

  useEffect(() => {
    let listColumn = sheet?.columns ?? [];
    listColumn = listColumn.filter((column) => column?.field !== 'complete');
    setColumns(listColumn);
  }, [sheet]);

  const getColumnIcon = (field) => {
    let column = listColumns.find((column) => column.value === field);
    return column?.icon;
  };
  const popover = (
    <Popover>
      <Popover.Body>
        <div className='d-flex justify-content-between gap-5'>
          <p>{t('sheet_view_tool_sort_by')}</p>
          <p className='cursor-pointer'>{t('sheet_view_tool_copy_setting')}</p>
        </div>
        <div className='h-8px border-top mx-2'></div>
        <div className='d-flex align-items-center position-relative my-3'>
          <i className='la fab la-sistrix position-absolute ms-2' />
          <input
            type='search'
            data-kt-user-table-filter='search'
            className='form-control form-control ps-10'
            placeholder={t('sheet_view_search_view_placeholder')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className='d-flex flex-column gap-2 h-300px overflow-auto'>
          {columns.map((column, index) => (
            <div
              key={index}
              className='btn btn-light bg-white gap-2 align-items-center justify-content-start d-flex'
            >
              <i className={getColumnIcon(column?.field) ?? 'la la-th-list'} />
              <span>{column?.name}</span>
            </div>
          ))}
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      overlay={popover}
      rootClose
      rootCloseEvent='click'
    >
      <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
        <i className='la la-sort' />
        <span>{t('sheet_view_tool_sort')}</span>
      </div>
    </OverlayTrigger>
  );
};

export default SheetDataViewSort;
