import {Field} from 'formik';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Input} from 'app/common/_partials/controls';

export default function ConfigAutoNumberColumn({isEdit, errors}) {
  const {t} = useTranslate();

  return (
    <div className='form-group mb-2'>
      <div className='row mb-2'>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label>{t('sheet_column_type_auto_number_prefix')}</label>
        </div>
        <div className='col-9'>
          <Field
            autoComplete='off'
            name='settings.prefix'
            component={Input}
            placeholder={t('sheet_column_type_auto_number_prefix_placeholder')}
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label>{t('sheet_column_type_auto_number_suffix')}</label>
        </div>
        <div className='col-9'>
          <Field
            autoComplete='off'
            name='settings.suffix'
            component={Input}
            placeholder={t('sheet_column_type_auto_number_suffix_placeholder')}
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label className='text-end'>
            {t('sheet_column_type_auto_number_initial_number_value')}
          </label>
        </div>
        <div className='col-9'>
          <Field
            autoComplete='off'
            type='number'
            disabled={isEdit}
            name='settings.initial_number_value'
            className={`form-control ${isEdit ? 'text-gray-400' : ''}`}
            component={Input}
            placeholder={t('sheet_column_type_auto_number_initial_number_value_placeholder')}
          />
          {errors?.settings?.initial_number_value && (
            <span className='text-danger fs-7'>{t(errors.settings.initial_number_value)}</span>
          )}
        </div>
      </div>
    </div>
  );
}
