export const conjunctionOptions = [
  {
    value: 'and',
    label: 'and',
  },
  {
    value: 'or',
    label: 'or',
  },
];

export const operatorOptions = [
  {
    value: '=',
    label: 'is',
  },
  {
    value: '!=',
    label: 'is not',
  },
  {
    value: 'contains',
    label: 'contains',
  },

  {
    value: 'empty',
    label: 'is empty',
  },
  {
    value: 'not empty',
    label: 'is not empty',
  },
];
