import { get } from "lodash";
import { hasSheetPermission } from "./permissionHelper";
import { useMemo } from "react";

export const ShowForSheetPermission = ({ sheet, permission, user, children }) => {
  
  const isAllowed = useMemo(
    () => hasSheetPermission(sheet, user, permission),
    [sheet, permission, user]);

  if (isAllowed) {
    return children; // rendering nested elements
  } else {
    /*
     it also might be false, empty string or empty array
     (and for React 18 it can be `return;` or `return undefined` as well);
     also you even can omit explicit `return undefined;` for `else` branch
     but this way intention is more clear
    */
    return null; 
  }
}