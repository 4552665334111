import {Field} from 'formik';
import {useDispatch} from 'react-redux';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';
import {getSheetAction} from 'app/modules/work/sheet/stores/sheetActions';
import omit from 'lodash/omit';

export const ConfigLookupColumn = ({
  isEdit,
  values,
  errors,
  setErrors,
  setFieldValue,
  linkedColumnOptions = [],
}) => {
  const {t} = useTranslate();
  const dispatch = useDispatch();

  const linkedColumnField = values?.settings?.linked_field;
  const lookupField = values?.settings?.lookup_field;
  const [lookupFieldOptions, setLookupFieldOptions] = useState([]);

  // only used for edit lookup column
  const lookupPageDefaultOption = useMemo(() => {
    if (linkedColumnField && isEdit) {
      return linkedColumnOptions.find((option) => option?.value === linkedColumnField?.value);
    }

    return undefined;
  }, [isEdit, linkedColumnOptions, linkedColumnField]);

  const linkedColumnPageId = useMemo(() => {
    if (linkedColumnField) {
      const existedLinkedColumn = linkedColumnOptions.find(
        (option) => option?.value === linkedColumnField?.value
      );
      return existedLinkedColumn?.page_id;
    }

    return null;
  }, [linkedColumnField, linkedColumnOptions]);

  const fetchLookupPageById = useCallback(
    (id) => {
      dispatch(getSheetAction({id})).then((result) => {
        if (result?.data?.data) {
          let _sheet = result.data.data;
          const availableLookupFieldOptions = _sheet.columns
            .filter((column) => !column?.deleted && !['linked', 'lookup'].includes(column?.type))
            .map((column) => ({label: column?.name, value: column?.field, type: column?.type}));

          setLookupFieldOptions(availableLookupFieldOptions);
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (linkedColumnPageId) {
      fetchLookupPageById(linkedColumnPageId);
    }
  }, [fetchLookupPageById, linkedColumnPageId]);

  useEffect(() => {
    if (!isEdit && linkedColumnField && lookupField) {
      setFieldValue('generatedColumnName', `${lookupField?.label} (${linkedColumnField?.label})`);
    }
  }, [isEdit, linkedColumnField, lookupField, setFieldValue, values?.name]);

  return (
    <div className='form-group mb-2'>
      <div className='row mb-2'>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label>
            {t('sheet_advanced_filter_column_placeholder')}&nbsp;
            {t('block_control_subitem_add_link')}:
          </label>
        </div>
        <div className='col-9'>
          <Field
            autoFocus={!isEdit}
            autoComplete='off'
            defaultValue={lookupPageDefaultOption}
            name='settings.linked_field'
            component={SelectSearch}
            isDisabled={isEdit}
            options={linkedColumnOptions}
            onChange={(selectedValue) => {
              setFieldValue('settings.linked_field', omit(selectedValue, ['page_id']));
              setErrors({});
              if (lookupField) {
                // reset lookup field when change linked field
                setFieldValue('settings.lookup_field', '');
              }
            }}
            placeholder={t('sheet_column_type_lookup_select_page_id')}
            noOptionsMessage={() => t('sheet_column_type_lookup_no_options')}
          />
          {errors?.settings?.linked_field && (
            <span className='text-danger fs-7'>
              {t('sheet_column_type_lookup_lookup_page_id_required')}
            </span>
          )}
        </div>
      </div>
      <div className='row mb-2'>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label>{t('sheet_column_type_lookup')}</label>
        </div>
        <div className='col-9'>
          <Field
            autoComplete='off'
            value={values?.settings?.lookup_field}
            defaultValue={values?.settings?.lookup_field}
            name='settings.lookup_field'
            component={SelectSearch}
            options={lookupFieldOptions}
            onChange={(selectedValue) => {
              setFieldValue('settings.lookup_field', selectedValue);
              setErrors({});
            }}
            placeholder={t('sheet_column_type_lookup_select_field')}
            noOptionsMessage={() => t('sheet_column_type_lookup_no_options')}
          />
          {errors?.settings?.lookup_field && (
            <span className='text-danger fs-7'>
              {t('sheet_column_type_lookup_lookup_field_required')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
