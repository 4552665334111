import React, {useEffect, useRef, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import {FieldFeedbackLabel} from './FieldFeedbackLabel';
import {DndProvider} from 'react-dnd';
import {getBackendOptions, MultiBackend, Tree} from '@minoru/react-dnd-treeview';
import clsx from 'clsx';
import {useOutsideClick} from 'app/common/hooks/useOutsideClick';
import { isEmpty } from 'lodash';

export function TreeSelectField({
  treeData,
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  notifyChange,
  children,
  placeholder = '',
  ...props
}) {
  const [field, meta] = useField(props);
  const {touched, error} = meta;
  const {setFieldValue} = useFormikContext();
  const [showOption, setShowOption] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if(isInitial && treeData && !isEmpty(treeData)){
      if(field?.value){
        const initValue = treeData.find((item) => item._id === field.value)
        onSelectItem(initValue);
      }
      setIsInitial(false)
    }
  },[treeData])

  const handleClickOutside = () => {
    setShowOption(null);
  };

  const treeSelectRef = useOutsideClick(handleClickOutside);

  const onSelectItem = (item) => {
    setFieldValue(field.name, item._id);
    notifyChange && notifyChange(item);
    setItemSelect(item);
    setShowOption(null);
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div className='form-control controller-list-tree-select' ref={treeSelectRef}>
        <div className='item-select' onClick={() => setShowOption(!showOption)}>
          {itemSelect ? (
            <div className='d-flex align-items-center'>
              {itemSelect?.name}
              <i
                className='icon-sm ms-auto las la-times cursor-pointer'
                onClick={() => setItemSelect(null)}
              ></i>
            </div>
          ) : (
            <span className='text-secondary'>{placeholder}</span>
          )}
        </div>
        {showOption && (
          <div className='list-tree-select border rounded'>
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <Tree
                tree={treeData}
                rootId={0}
                render={(item, {depth, isOpen, onToggle}) => {
                  return (
                    <TreeNode
                      item={item}
                      depth={depth}
                      isOpen={isOpen}
                      onToggle={onToggle}
                      paddingLeft={24}
                      onSelectItem={onSelectItem}
                    />
                  );
                }}
                sort={false}
                classes={{
                  root: 'list-tree-page m-0',
                  placeholder: 'placeholder-container',
                }}
              />
            </DndProvider>
          </div>
        )}
      </div>

      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

const TreeNode = (props) => {
  const {item, paddingLeft} = props;
  const itemActive = useRef();

  const indent = 4 + props.depth * paddingLeft;

  const ItemIcon = ({item, isOpen}) => {
    return <i className={`icon-sm fas ${isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right'} `} />;
  };

  return (
    <div
      className={`tree-node root ${clsx({})}`}
      ref={itemActive}
      style={{paddingInlineStart: indent}}
    >
      <span
        className='svg-icon menu-icon d-flex align-teams-center'
        style={{width: '0.75rem'}}
      >
        <div
          className='rstm-toggle-icon'
          onClick={(event) => {
            event.stopPropagation();
            props.onToggle();
          }}
        >
          <ItemIcon item={item} isOpen={props.isOpen}></ItemIcon>
        </div>
      </span>
      <div
        className={'label-grid-item d-flex align-teams-center flex-1 '}
        onClick={() => props.onSelectItem(item)}
      >
        <div className='d-inline-block text-nowrap text-truncate'>{item.name}</div>
      </div>
    </div>
  );
};
