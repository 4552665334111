import * as requestFromServer from './api';
import {notificationSlice, callTypes} from './slice';

const {actions} = notificationSlice;

export const searchPeopleAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .searchPeople(params)
    .then((response) => {
      if (response.data?.success) {
        return response.data.data;
      } else {
        Promise.reject();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't search people";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const searchNotificationAction = (params, loadMore) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .searchNotification(params)
    .then((response) => {
      const {data} = response
      const payload = {
        data: data,
        loadMore: loadMore
      }
      dispatch(actions.notificationsFetched(payload));
    })
    .catch((error) => {
      error.clientMessage = "Can't search people";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const updateNotificationAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .updateNotification(params)
    .catch((error) => {
      error.clientMessage = "Can't update team"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    })
}
