import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider, useIntl} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'


import {WithChildren} from '../helpers'

const viMessages = () => {
  const viAllMessages = {
    ...require('./messages/vi.json'),
    ...require('./old-messages/vi.json'),
    ...require('../../app/modules/meeting/i18n/vi.json'),
    ...require('../../app/modules/work/i18n/vi.json'),
    ...require('../../app/modules/work/overview/i18n/vi.json'),
    ...require('../../app/modules/teams/i18n/vi.json'),
    ...require('../../app/modules/profile/i18n/vi.json'),
    ...require('../../app/modules/auth/i18n/vi.json'),
    ...require('../../app/modules/dashboard/i18n/vi.json'),
    ...require('../../app/modules/notifications/i18n/vi.json'),
    ...require('../../app/modules/admin/i18n/vi.json'),
    ...require('../../app/modules/admin/template/i18n/vi.json'),
    ...require('../../app/modules/assistant/i18n/vi.json'),
    ...require('../../app/modules/work/sheet/component/formula-editor/i18n/vi.json'),
    ...require('../../app/modules/work/sheet/validation/i18n/vi.json'),
    ...require('../../app/modules/work/sheet/component/field/i18n/vi.json'),
    ...require('../../app/modules/admin/teams/i18n/vi.json'),
    ...require('../../app/modules/admin/users/i18n/vi.json'),
    ...require('../../app/modules/admin/departments/i18n/vi.json'),
    ...require('../../app/modules/admin/connections/i18n/vi.json'),
    ...require('../../app/modules/admin/workflows/i18n/vi.json'),
    ...require('../../app/modules/admin/permission/i18n/vi.json'),
    ...require('../../app/modules/application/i18n/vi.json'),
    ...require('../../app/modules/home/i18n/vi.json'),
    ...require('../../app/modules/developer/i18n/vi.json'),
    ...require('../../app/modules/search/i18n/vi.json')


  };
  return viAllMessages
}

const enMessages = () => {
  const enAllMessages = {
    ...require('./messages/en.json'),
    ...require('../../app/modules/work/i18n/en.json'),
  };
  return enAllMessages;
};

const allMessages = {
  en: enMessages(),
  vi: viMessages(),
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages} timeZone='asia/ho_chi_minh'>
      {children}
    </IntlProvider>
  );
}

export {I18nProvider}

export const useTranslate = () => {
  const {formatMessage} = useIntl()

  const t = (id: string, params: object = {}) => {
    return formatMessage({id: id}, {...params})
  }

  return {t}
}
