import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select';
import clsx from 'clsx';

function ModalMoveGroupToSheet({show, onHide, oldSheet, newSheet, onMoveGroup}) {
  const {t} = useTranslate();
  const [columnTypeNewSheet, setColumnTypeNewSheet] = useState({});
  const [columnsMapping, setColumnsMapping] = useState({});

  useEffect(() => {
    if (newSheet?.columns) {
      const columnType = {};
      for (let i = 0; i < newSheet.columns.length; i++) {
        const column = newSheet.columns[i];
        if (columnType[column.type]) {
          columnType[column.type].push(column);
        } else {
          columnType[column.type] = [column];
        }
      }
      setColumnTypeNewSheet(columnType);

      const columnMapping = {};
      for (let i = 0; i < oldSheet?.columns?.length; i++) {
        const column = oldSheet.columns[i];
        const options = columnType?.[column?.type];
        const mapWitchColumn = options?.find((option) => option.field === column.field);
        columnMapping[column.field] = mapWitchColumn?.field || null;
      }
      setColumnsMapping(columnMapping);
    }
  }, [newSheet]);

  const onChangeColumn = (option, action) => {
    if (action.action === 'clear') {
      const optionSelect = {...action.removedValues[0]};
      const cloneColumnMapping = {...columnsMapping};
      if (optionSelect.field) {
        cloneColumnMapping[optionSelect.field] = null;
      }
      setColumnsMapping(cloneColumnMapping);
    } else {
      const optionSelect = {...option};
      const cloneColumnMapping = {...columnsMapping};
      if (optionSelect.field) {
        cloneColumnMapping[optionSelect.field] = optionSelect.value;
      }
      setColumnsMapping(cloneColumnMapping);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div>{t('sheet_move_modal_title1')}</div>
        <div>{t('sheet_move_modal_title2')}</div>
        <div className='row mt-5 fw-bold'>
          <div className='col-lg-6'>{oldSheet?.name}</div>
          <div className='col-lg-6'>{newSheet?.name}</div>
        </div>
        <div className='d-flex flex-column mt-4'>
          {oldSheet?.columns?.map((column) => {
            const listOptionByName = {};
            const options = columnTypeNewSheet?.[column?.type]
              ?.filter((columnNewSheet) => !columnNewSheet.deleted)
              ?.map((columnNewSheet) => {
                const option = {
                  label: columnNewSheet.name,
                  value: columnNewSheet.field,
                  field: column.field,
                };
                listOptionByName[column.field] = option;
                return option;
              });
            const optionField = options?.find((option) => option.value === column.field);
            const hasColumnMapping = columnsMapping[column.field];
            if (!column.deleted && !column.hidden) {
              return (
                <div key={column._id} className='row py-3'>
                  <div className='col-lg-6 d-flex justify-content-between align-items-center'>
                    <span className='fw-bold'>{column.name}</span>
                    <i
                      className={`fa-solid fa-arrow-right fs-3 ${clsx({
                        'text-success': hasColumnMapping,
                      })}`}
                    ></i>
                  </div>
                  <div className='col-lg-6'>
                    {options?.length ? (
                      <Select
                        className='basic-single'
                        isClearable={true}
                        defaultValue={optionField}
                        classNamePrefix='select'
                        placeholder={t('sheet_move_modal_select_column')}
                        options={options}
                        onChange={onChangeColumn}
                      />
                    ) : (
                      <div className='border border-gray-400 p-2 rounded'>
                        {t('sheet_move_modal_select_column_none')}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            return <React.Fragment key={column._id} />;
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <div className='cursor-pointer w-50px fw-bold' onClick={onHide}>
          {t('common_cancel')}
        </div>
        <Button
          variant={'primary'}
          onClick={() => onMoveGroup(columnsMapping)}
          className='w-120px fw-bold'
        >
          {t('sheet_move_menu_item_move_to_group')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalMoveGroupToSheet;
