export const boardType = {
  PAGE: 'page',
  SHEET: 'sheet',
  DOC: 'doc',
  OVERVIEW: 'overview',
};

export const boardTypes = [
  {
    type: boardType.PAGE,
    name: 'Thư mục',
  },
  {
    type: boardType.SHEET,
    name: 'Bảng',
  },
  {
    type: boardType.DOC,
    name: 'Tài liệu',
  },
  {
    type: boardType.OVERVIEW,
    name: 'Bảng tin',
  },
];

export const subType = {
  PDF: 'pdf',
  DOC_BLOCK: 'doc_block',
  GOOGLE_DOC: 'gdoc',
};
