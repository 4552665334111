import { SelectSearch } from "app/common/_partials/controls/SelectSearch";

const groups = [
  {
    value: '',
    label: '',
  },
  {
    value: 'group',
    label: 'Nhóm',
  },
  {
    value: 'people',
    label: 'Người',
  },
  {
    value: 'status',
    label: 'Trạng thái',
  },
];

export const GroupSearch = ({handleGanttChange, editWidget}) => {
  return (
    <SelectSearch
      options={groups}
      placeholder={'Nhóm với'}
      value={groups.find((group) => {
        return group.value === editWidget.data?.group?.column;
      })}
      onChange={handleGanttChange}
    ></SelectSearch>
  );
};
