import {closestColor, invertColor} from 'app/common/_helpers/ColorHelper';
import Select from 'react-select';
import {useTranslate} from 'core/i18n/i18nProvider';
import { useMemo } from 'react';
function ColorValue({value, item, column, sheet, onChange, disabled}) {
  const options = column.values ?? [];
  const {t} = useTranslate();
  const colorValue = useMemo(() => {
    let columnValues = column?.values ?? [];
    return columnValues.find((val) => val.value === value);
  },[value, options])

  const renderOptionLabel = (option) => {
    return (
      <div
        className='d-flex align-items-center p-2'
        style={{
          color: closestColor(invertColor(option?.backgroundColor), ['#ffffff', '#000000']),
          backgroundColor: option?.backgroundColor,
        }}
      >
        {option?.label === '' ? t('common_empty') : option?.label}
      </div>
    );
  };

  const onChangeSelect = (selectedOption) => {
    onChange(selectedOption);
  };
  return (
    <Select
      value={colorValue}
      className='w-100'
      options={options}
      isDisabled={disabled}
      formatOptionLabel={renderOptionLabel}
      onChange={onChangeSelect}
    />
  );
}

export default ColorValue;
