import React, {useMemo, useState} from 'react';
import './approvalItem.scss';
import moment from 'moment';
import {effectiveValue} from 'app/modules/work/helpers';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';
import ModalNoteReject from './ModalNoteReject';
import {PeopleColumn} from 'app/modules/dashboard/widgets/components/columns/PeopleColumn';
import {APPROVAL_STATUS} from './contants';
import ModalReopen from './ModalReopen';
import {isArray} from 'lodash';

function ApprovalItem({
  step,
  column,
  item,
  value,
  index,
  onChange,
  steps,
  approvals,
  members,
  onApproveStep,
  assigneeRoleUsers,
}) {
  const {t} = useTranslate();
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalReopen, setShowModalReopen] = useState(false);
  const [allowEnd, setAllowEnd] = useState(false);
  const {user} = useSession();
  const createdBy = effectiveValue(item?.created_by);
  const effValue = value;
  const stepItem = column?.steps?.[index];
  const roleUser = assigneeRoleUsers.find(
    (assigneeRoleUser) => assigneeRoleUser.id === step?.assignee_role
  );
  const peopleFieldEditable =
    effValue?.status !== APPROVAL_STATUS.APPROVED && effValue?.status !== APPROVAL_STATUS.REJECTED;
  let approval = isArray(effValue?.value)
    ? effValue?.value?.find((approval) => approval?.assignee_role === step?.assignee_role)
    : null;

  if (approval) {
    approval = {assignees: roleUser?.assignees, ...approval, ...step};
  } else {
    approval = {...step, assignees: roleUser?.assignees};
  }

  const showAction = () => {
    const isShowAction = isArray(effValue?.value)
      ? effValue?.value?.some(
          (itemCurrentStep) => itemCurrentStep.assignee_role === step.assignee_role
        )
      : false;
    let member = approval?.assignees?.find((member) => {
      if (member?.type === 'team') {
        let userTeams = user?.teams ?? [];
        return userTeams.find((team) => team?.id === member?.id);
      } else {
        return member?.id === user?._id;
      }
    });
    if (isShowAction && member) {
      return true;
    }
    return false;
  };

  const handleApprove = () => {
    setShowModalApprove(false);
    const params = {
      name: 'approve_step',
      stepId: step.id,
    };

    onApproveStep(params);
  };

  const handleApproveAndEnd = () => {
    setShowModalApprove(false);
    const params = {
      name: 'approve_and_end_step',
      stepId: step.id,
    };

    onApproveStep(params);
  };

  const handleRejected = (note) => {
    const params = {
      name: 'reject_step',
      note: note,
      stepId: step.id,
    };
    onApproveStep(params);
  };

  const handleReopen = (stepId, note) => {
    const params = {
      name: 'reopen_step',
      stepId: stepId,
      note: note,
    };
    onApproveStep(params);
    setShowModalReopen(false);
  };

  const personOfStep = useMemo(() => {
    if (index > 0) {
      return approval?.assignees;
    }
    return createdBy ? [createdBy] : [];
  }, [index, approval?.assignees, createdBy]);

  return (
    <div className=''>
      <div className='approval-item bg-light rounded p-2'>
        <div className='d-flex align-items-center justify-content-between w-100 px-3'>
          <div className='approval-item-status fw-bold'>{step?.name}</div>
          <div className='approval-item-date'>
            {showAction() &&
              stepItem.completion_time &&
              moment(stepItem?.created_at)
                .add(stepItem.completion_time, 'days')
                .format('DD/MM/yyyy')}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between w-100 px-3 mt-3'>
          <div className='approval-item-status'>
            <PeopleColumn
              cell={{value: personOfStep}}
              column={{display_mode: 'full'}}
              fieldEditable={peopleFieldEditable}
            />
          </div>
          <ApprovalItemAction
            showAction={showAction}
            index={index}
            effValue={effValue}
            approval={approval}
            stepId={stepItem?.id}
            step={step}
            setShowModalApprove={setShowModalApprove}
            setShowModalReject={setShowModalReject}
            setShowModalReopen={setShowModalReopen}
            allowEndId={column?.allowEnd}
            handleApprove={handleApprove}
            t={t}
          />
        </div>
        {effValue?.note && effValue?.steps?.length === index && (
          <div className='px-3 mt-3'>{effValue?.note}</div>
        )}
      </div>
      <ModalConfirm
        style={{zIndex: 10000}}
        show={showModalApprove}
        onHide={() => setShowModalApprove(false)}
        title={t('sheet_table_modal_title_approve')}
        handleExcute={() => {
          if (allowEnd) {
            handleApproveAndEnd();
          } else {
            handleApprove();
          }
        }}
        children={
          <>
            <div>{t('sheet_table_column_approval_approve_content')}</div>
            <div className='mt-2'>
              <label className={`checkbox checkbox-lg checkbox-single`}>
                <input type='radio' checked={allowEnd} onChange={() => setAllowEnd(true)} />
                <span />
                &nbsp;{t('sheet_table_column_approval_approve_alow_end')}
              </label>
            </div>
            <div className='mt-2'>
              <label className={`checkbox checkbox-lg checkbox-single`}>
                <input type='radio' checked={!allowEnd} onChange={() => setAllowEnd(false)} />
                <span />
                &nbsp;{t('sheet_table_column_approval_approve_next_step')}
              </label>
            </div>
          </>
        }
      />
      <ModalNoteReject
        style={{zIndex: 10000}}
        show={showModalReject}
        onHide={() => setShowModalReject(false)}
        handleExcute={handleRejected}
      />
      {showModalReopen && (
        <ModalReopen
          style={{zIndex: 10000}}
          show={showModalReopen}
          onHide={() => setShowModalReopen(false)}
          steps={steps}
          stepsValue={effValue?.steps}
          handleExcute={handleReopen}
        />
      )}
    </div>
  );
}

export default ApprovalItem;

const ApprovalItemAction = ({
  showAction,
  index,
  effValue,
  setShowModalApprove,
  setShowModalReject,
  setShowModalReopen,
  stepId,
  allowEndId,
  handleApprove,
  step,
  approval,
  t,
}) => {
  const renderApprovalButton = (status) => {
    switch (status) {
      case APPROVAL_STATUS.APPROVED:
        return (
          <div className='bg-success w-100px me-1 p-1 rounded text-center'>
            {t('sheet_table_column_approval_approve')}
          </div>
        );
      case APPROVAL_STATUS.REJECTED:
        return (
          <div className='bg-danger w-100px ms-1 p-1 rounded text-white text-center'>
            {t('sheet_table_column_approval_reject')}
          </div>
        );
      default:
        return null;
    }
  };

  const renderPendingButtons = () => (
    <>
      {step?.actionReject && (
        <div
          className='bg-secondary w-80px me-1 p-1 rounded cursor-pointer text-center'
          onClick={() => setShowModalReject(true)}
        >
          {step?.titleActionReject || t('sheet_table_column_approval_reject')}
        </div>
      )}
      {step?.actionReopen && (
        <div
          className='bg-warning w-80px ms-1 p-1 rounded text-white cursor-pointer text-center'
          onClick={() => setShowModalReopen(true)}
        >
          {step?.titleActionReopen || t('sheet_table_column_approval_reopen')}
        </div>
      )}
      {step?.actionApprove && (
        <div
          className='bg-primary w-80px ms-1 p-1 rounded text-white cursor-pointer text-center'
          onClick={() => {
            if (stepId === allowEndId) {
              setShowModalApprove(true);
            } else {
              handleApprove();
            }
          }}
        >
          {step?.titleActionApprove || t('sheet_table_column_approval_approve_action')}
        </div>
      )}
    </>
  );

  const renderStatusPending = () => {
    switch (effValue?.status) {
      case APPROVAL_STATUS.PENDING:
        return (
          <div className='bg-warning w-80px me-1 p-1 rounded text-center text-white'>
            {t('sheet_table_column_approval_pending')}
          </div>
        );
      case APPROVAL_STATUS.APPROVED:
        return (
          <div className='bg-success w-100px me-1 p-1 rounded text-center'>
            {t('sheet_table_column_approval_approve')}
          </div>
        );
      case APPROVAL_STATUS.REJECTED:
        return (
          <div className='bg-danger w-100px ms-1 p-1 rounded text-white text-center'>
            {t('sheet_table_column_approval_reject')}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='approval-item-date'>
      {showAction() || index === 0 ? (
        <div className='d-flex align-items-center'>
          {!effValue?.steps?.length && index === 0 && step?.actionCreateApproval ? (
            <div
              className='bg-primary w-100px ms-1 p-1 rounded text-white cursor-pointer text-center'
              onClick={handleApprove}
            >
              {step?.titleActionCreateApproval || t('sheet_table_column_approval_create_approve')}
            </div>
          ) : (
            ''
          )}
          {effValue?.steps?.length && index > 0 ? (
            <>
              {effValue?.status === APPROVAL_STATUS.REJECTED ||
              effValue?.status === APPROVAL_STATUS.APPROVED
                ? renderApprovalButton(effValue?.status)
                : ''}
              {(approval?.status === APPROVAL_STATUS.PENDING ||
                approval?.status === APPROVAL_STATUS.REOPEN) &&
              effValue?.status !== APPROVAL_STATUS.REJECTED &&
              effValue?.status !== APPROVAL_STATUS.APPROVED
                ? renderPendingButtons()
                : ''}
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {!showAction() &&
        effValue?.steps?.length === index &&
        approval?.status === APPROVAL_STATUS.PENDING &&
        renderStatusPending()}
    </div>
  );
};
