import React, {useEffect, useState} from 'react';
import {Dropdown, Spinner} from 'react-bootstrap';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import './listAppDropdown.scss';
import {getAppsAction} from 'core/store/core/coreActions';

function ListAppDropdown(props) {
  const dispatch = useDispatch();
  const {apps} = useSelector((state) => state.core, shallowEqual);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (show && apps?.length === 0) {
      setIsLoading(true);
      dispatch(getAppsAction()).then(() => setIsLoading(false));
    }
  }, [show, dispatch]);

  const renderIcon = (appIcon) => {
    if (appIcon?.icon?.type === 'image' || appIcon?.type === 'image') {
      return (
        <div className='d-flex align-items-center w-100 h-100'>
          <img className='w-100 h-100 rounded' src={appIcon?.icon?.data || appIcon?.data} alt='' />
        </div>
      );
    }
    return (
      <span
        className='icon-app'
        dangerouslySetInnerHTML={{
          __html: appIcon?.icon?.data || appIcon?.icon || '<i class="bi bi-box-seam "></i>',
        }}
      ></span>
    );
  };
  const renderContent = () => {
    if (isLoading)
      return (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden loading'></span>
          </Spinner>
        </div>
      );

    if (apps?.length === 0) {
      return <div className='text-center p-3'>Chưa có ứng dụng nào</div>;
    }

    return apps.map((app) => {
      const url = `/application/view/${app?._id}`;
      return (
        <div className='app-item col-4 mb-3 cursor-pointer bg-hover-light rounded-3' key={app?._id}>
          <Link
            to={url}
            className='p-4 border-gray-400 cursor-pointer d-flex flex-column w-100 py-4 align-items-center justify-content-center'
          >
            <div
              className='min-w-50px w-50px h-50px rounded d-flex justify-content-center align-items-center text-white fs-1'
              style={{backgroundColor: app?.appearance?.color ?? 'var(--kt-primary)'}}
            >
              {renderIcon(app?.appearance)}
            </div>
            <div className='text-truncate app-name-limit-text-1 w-80px text-center mt-2'>
              {app?.name}
            </div>
          </Link>
        </div>
      );
    });
  };

  return (
    <Dropdown className='dropdown-inline h-100' align='end' onToggle={onToggle}>
      <Dropdown.Toggle
        id='dropdown-toggle-top'
        className='w-30px h-30px w-md-40px bg-transparent'
        as={DropdownCustomToggler}
      >
        <span className='icon-box3 fs-icon'></span>
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-menu dropdown-menu-xs dropdown-menu-left px-2 w-350px rounded-3'>
        <div className='d-flex flex-wrap list-app-dropdown'>{renderContent()}</div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ListAppDropdown;

const DropdownCustomToggler = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className='d-flex align-items-center justify-content-center h-30px w-40px'
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
});
