import { useTranslate } from 'core/i18n/i18nProvider';
import {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';

export default function SheetStatusFilter({sheet, filter, filterValue, column, applyFilter}) {
  const [values, setValues] = useState([]);
  const {t} = useTranslate()

  useEffect(() => {
    if(filterValue && filterValue?.value && filterValue?.value?.in){
      setValues(filterValue?.value?.in)
    }else{
      setValues([])
    }
  },[filterValue])

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);

    applyFilter && applyFilter(filter, {in: newValues});
  };

  return (
    <>
      <div className='d-flex flex-column align-items-stretch '>
        {column?.values &&
          column.values.map((value) => (
            <ToggleButton
              id={`toggle-${column.field}-${value.value}`}
              key={`toggle-${column.field}-${value.value}`}
              type='checkbox'
              size='sm'
              variant='filter'
              className={`mb-2 text-nowrap text-dark d-flex align-items-center gap-2 ${
                filterValue?.value?.in?.includes(value.value) ? 'bg-light-primary' : 'bg-secondary'
              }`}
              checked={filterValue?.value.in?.includes(value.value)}
              value='1'
              onChange={(e) => onToggleValue(value.value)}
            >
              <div className='rounded-circle w-15px h-15px' style={{backgroundColor: `${value.backgroundColor}`}}></div>
              {value?.label || t('sheet.filter.blank_label')}&nbsp;
            </ToggleButton>
          ))}
      </div>
    </>
  );
}
