import './modalAddNewItem.scss';

import {Button, Modal, Spinner} from 'react-bootstrap';
import {isObject, set} from 'lodash';
import {
  hasEditItemColumnPermission,
  hasEditItemPermission,
  hasViewSheetColumnPermission,
} from 'app/modules/work/sheet/permission/permissionHelper';
import {useEffect, useMemo, useRef, useState} from 'react';

import ItemField from '../../../field/ItemField';
import cloneDeep from 'lodash/cloneDeep';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useInterfaceThemeContext} from '../../../sheet-view/interface-view/InterfaceThemeContext';

export default function ModalAddNewItem(props) {
  const {
    show,
    onHide,
    sheet,
    view,
    onSave,
    sheetItemPermissions,
    confirmVariant,
    configurable,
    initialValues,
    isLoadingAddItem,
  } = props;
  const {backgroundColor, textColor, addButtonVariant} = useInterfaceThemeContext();

  const {t} = useTranslate();
  const inputRef = useRef();
  const {user} = useSession();

  const disableTypeColumn = ['approval'];

  const [newItem, setNewItem] = useState({});
  const [itemEditable, setItemEditable] = useState(false);

  const [titleColumn, setTitleColumn] = useState(null);

  const columns = useMemo(() => {
    let columns = sheet?.columns ?? [];
    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: false};
      });
      columns = [...listColumnsByView, ...listColumns];
    }
    return columns ?? [];
  }, [sheet?.columns, view?.columns]);

  useEffect(() => {
    let titleColumn = columns.find(
      (column) => !column?.deleted && (column?.field === 'title' || column?.is_title === true)
    );
    setTitleColumn(titleColumn);
  }, [columns]);

  const onSaveItem = (_item, field, _oldValue, newValue) => {
    const updateItemParams = cloneDeep(newItem);

    let newFieldValue = null;

    if (isObject(newValue) && newValue.hasOwnProperty('value')) {
      newFieldValue = newValue;
    } else {
      newFieldValue = {
        value: newValue,
      };
    }

    updateItemParams[field] = newFieldValue;
    setNewItem((prevState) => ({...prevState, ...updateItemParams}));
  };

  useEffect(() => {
    if (titleColumn) {
      inputRef.current?.focus();
    }
  }, [titleColumn]);

  useEffect(() => {
    const updateItemParams = cloneDeep(newItem);

    columns?.forEach((column) => {
      if (column.default && column.default !== '') {
        if (column.type === 'people') {
          if (column.default === 'current_user') {
            set(updateItemParams, column.field, {
              value: [{name: user.name, id: user._id, _id: user._id}],
            });
          }
          if (column.default === 'default_users') {
            set(updateItemParams, column.field, {value: column.default_users});
          }
        }
        if (column.type === 'status') {
          if (column.default) {
            set(updateItemParams, column.field, {value: column.default});
          }
        }
        if (column.type === 'date') {
          if (column.default === 'today') {
            set(updateItemParams, column.field, {value: new Date().getTime() / 1000});
          }
        }
      } else {
        if (column.type === 'approval') {
          if (column.steps) {
            const currentStep = column.steps?.[0];
            const assignedRoleUser = column?.assignee_role_users?.find(
              (assignee) => assignee.id === currentStep?.assignee_role
            );
            set(updateItemParams, column.field, {
              value: [
                {
                  ...currentStep,
                  assignees: assignedRoleUser.assignees
                    ? [...assignedRoleUser.assignees, user]
                    : [user],
                },
              ],
            });
          }
        } else {
          set(updateItemParams, column.field, {value: initialValues?.[column.field]});
        }
      }

      if (initialValues?.[column.field]) {
        set(updateItemParams, column.field, {value: initialValues?.[column.field]});
      }

      // mapping columns values if it has type select
      if (column?.values) {
        column.values = column.values.map((item) => {
          const updatedOption = {...item, value: item?.value || 'default'};
          const label = updatedOption?.label;

          return {
            ...updatedOption,
            label,
          };
        });
      }
    });
    setNewItem(updateItemParams);
  }, [columns, initialValues]);

  useEffect(() => {
    setItemEditable(hasEditItemPermission(sheetItemPermissions, user, newItem));
  }, [sheet, user, newItem, sheetItemPermissions]);

  return (
    <Modal
      centered
      size='lg'
      show={show}
      onHide={onHide}
      style={{zIndex: 1400}}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('sheet_add_item')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mt-4 add-form'>
          {columns?.map((column) => {
            const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
            if (!hasViewColumnPermission || column.deleted || column.hidden) {
              return null;
            } else {
              return (
                <div
                  key={column.field}
                  className={`d-flex text-gray-800 py-2 mt-2 rounded px-2 border ${
                    !hasViewColumnPermission && configurable ? 'bg-gray-300' : ''
                  }`}
                >
                  <div className='field-container'>
                    <div className='field-name text-capitalize'>{column.name}</div>
                    <div className='field-value border min-h-30px ms-2 add-new-item-modal-container'>
                      {!disableTypeColumn.includes(column.type) && (
                        <ItemField
                          valueClassName=''
                          value={newItem?.[column?.field]}
                          item={newItem}
                          sheet={sheet}
                          column={column}
                          onChange={onSaveItem}
                          enableDetectGeoLocation
                          fieldEditable={
                            itemEditable && hasEditItemColumnPermission(user, column, newItem)
                          }
                          mode='detail'
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <Button variant='secondary' onClick={onHide} className='w-100px'>
          {t('common_cancel')}
        </Button>
        <div
          disabled={isLoadingAddItem}
          variant={confirmVariant ? confirmVariant : backgroundColor ? '' : addButtonVariant}
          onClick={() => onSave(newItem)}
          className='btn btn-primary btn-elevate w-150px'
          style={{color: textColor, backgroundColor}}
        >
          {isLoadingAddItem && <Spinner animation='border' variant='' size='sm' className='me-2' />}
          {t('common_add_new')}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
