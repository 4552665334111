import {Parser, SUPPORTED_FORMULAS} from 'hot-formula-parser';

export const formulaParser = new Parser();

//All function supported > 300
export const defaultSupportedFormula = SUPPORTED_FORMULAS;

//Current supported in optimi
export const supportedFormula = [
  {
    name: 'SUM',
    example: 'SUM(Number1, Number2, ..., Number30)',
    description: 'formula.description.sum',
  },
  {
    name: 'COUNT',
    example: 'COUNT(Value1, Value2, ... Value30)',
    description: 'formula.description.count',
  },
  {
    name: 'AVERAGE',
    example: 'AVERAGE(Number1, Number2, ...Number30)',
    description: 'formula.description.avarage',
  },
  {
    name: 'MAX',
    example: 'MAX(Number1, Number2, ...Number30)',
    description: 'formula.description.max',
  },
  {
    name: 'AND',
    example: 'AND(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)',
    description: 'formula.description.and',
  },
  {
    name: 'MEDIAN',
    example: 'MEDIAN(Number1, Number2, ...Number30)',
    description: 'formula.description.median',
  },
  {
    name: 'MIN',
    example: 'MIN(Number1, Number2, ...Number30)',
    description: 'formula.description.min',
  },
  {
    name: 'ABS',
    example: 'ABS(Number)',
    description: 'formula.description.abs',
  },
  {
    name: 'IF',
    example: 'IF(Test, Then value, Otherwisevalue)',
    description: 'formula.description.if',
  },
  {
    name: 'HOUR',
    example: 'HOUR(Time)',
    description: 'formula.description.hour',
  },
  {
    name: 'DAYS',
    example: 'DAYS(Date2, Date1)',
    description: 'formula.description.days',
  },
  {
    name: 'DAY',
    example: 'DAY(Number)',
    description: 'formula.description.day',
  },
  {
    name: 'DATEVALUE',
    example: 'DATEVALUE(Datestring)',
    description: 'formula.description.date_value',
  },
  {
    name: 'DATE',
    example: 'DATE(Year, Month, Day)',
    description: 'formula.description.date',
  },
  {
    name: 'MONTH',
    example: 'MONTH(Number)',
    description: 'formula.description.month',
  },
  {
    name: 'OR',
    example: 'OR(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)',
    description: 'formula.description.or',
  },
  {
    name: 'NOW',
    example: 'NOW()',
    description: 'formula.description.now',
  },
  {
    name: 'NOT',
    example: 'NOT(Logicalvalue)',
    description: 'formula.description.not',
  },
  {
    name: 'WEEKDAY',
    example: 'WEEKDAY(Date, Type)',
    description: 'formula.description.week_day',
  },
  {
    name: 'TRIM',
    example: 'TRIM("Text")',
    description: 'formula.description.trim',
  },
  {
    name: 'TODAY',
    example: 'TODAY()',
    description: 'formula.description.today',
  },
  {
    name: 'SWITCH',
    example: 'SWITCH(Expression1, Value1[, Expression2, Value2[..., Expression_n, Value_n]])',
    description: 'formula.description.switch',
  },

  {
    name: 'SQRT',
    example: 'SQRT(Number)',
    description: 'formula.description.sqrt',
  },
  {
    name: 'YEAR',
    example: 'YEAR(Number)',
    description: 'formula.description.year',
  },
  {
    name: 'XOR',
    example: 'XOR(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)',
    description: 'formula.description.xor',
  },
  {
    name: 'WORKDAY',
    example: 'WORKDAY(Date, Shift[, Holidays])',
    description: 'formula.description.work_day',
  },
  {
    name: 'WEEKNUM',
    example: 'WEEKNUM(Date, Type)',
    description: 'formula.description.week_num',
  },
  {
    name: 'COMBINE',
    example: 'COMBINE(value1,value2,...)',
    description: 'formula.description.combine'
  }
];

//Set custom variable constants
export const constantForFormula = [
  {
    name: 'FALSE',
    example: '',
    description: 'formula.const.false',
  },
  {name: 'TRUE', example: '', description: 'formula.const.true'},
  {
    name: 'PI',
    example: '',
    description: 'formula.const.pi',
  },
];

formulaParser.setVariable('TRUE', true);
formulaParser.setVariable('FALSE', false);
formulaParser.setVariable('PI', formulaParser.parse('PI()').result);


//Error message
export const parseError = {
  '#ERROR!':'formula.parse_error.common',
  '#DIV/0!':'formula.parse_error.divide_by_zero',
  '#NAME?':'formula.parse_error.name',
  '#N/A':'formula.parse_error.na',
  '#NUM!':'formula.parse_error.num',
  '#VALUE!':'formula.parse_error.value'
}

// Set custom function
formulaParser.setFunction('COMBINE',(params) => {
  let result = ''
  params.forEach(item => {
    result = result + item
  })
  return result
})