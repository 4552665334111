import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {installStoreAppAction} from '../../stores/storeApp/action';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import clsx from 'clsx';

const AppStoreAppView = ({app, onClose, onInstalled}) => {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const notification = useSnackNotification();
  const [loadingInstall, setLoadingInstall] = useState(false);
  const [previewThumbnail, setPreviewThumbnail] = useState(app?.media_files?.[0]);

  const handleInstall = () => {
    setLoadingInstall(true);
    dispatch(installStoreAppAction({store_app_id: app.store_app_id})).then((res) => {
      if (res?.data?.error) {
        notification.showError(t('store_app.detail.install_error'));
      } else {
        const app = res.data[0];
        notification.showSuccess(t('store_app.detail.install_success'));
        onInstalled?.(app);
      }
      onClose?.();
      setLoadingInstall(false);
    });
  };

  const handleBack = () => {
    onClose?.();
  };

  if (loadingInstall) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center h-100'>
        <div class='spinner-border' role='status'>
          <span class='sr-only'>{t('loading')}</span>
        </div>
        <div className='mt-4 fw-bold'>{t('store_app.detail.install_progress')}</div>
      </div>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderToolbar className={'w-100'}>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='d-flex align-items-center'>
              <i className='las la-arrow-left fs-2 me-2 cursor-pointer' onClick={handleBack}></i>{' '}
              <span className='fw-bold fs-2'>{app.name}</span>
            </div>
            <Button variant='primary' className='' onClick={handleInstall}>
              {t('store_app.detail.install')}
            </Button>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className='app-detail-wrapper'>
          <div
            className='mb-5'
            dangerouslySetInnerHTML={{__html: app.store_app_long_description}}
          ></div>
          <div className='w-100 h-300px rounded bg-secondary'>
            <img
              className='w-100 h-100 rounded image-screenshot-app'
              src={previewThumbnail}
              alt={app.name}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          </div>
          <div className='d-flex screenshot-apps mt-6'>
            {app?.media_files?.map((image, idx) => {
              return (
                <div
                  key={idx}
                  className='screenshot-app bg-secondary rounded'
                  onClick={() => setPreviewThumbnail(image)}
                >
                  <img
                    className={`w-100 h-100 rounded image-screenshot-app ${clsx({active: image === previewThumbnail})}`}
                    src={image}
                    alt={app.name}
                    onError={(e) => {
                      e.target.style.display = 'none';
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AppStoreAppView;
