import { useTranslate } from 'core/i18n/i18nProvider';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

function ModalReopen({
  show,
  onHide,
  handleExcute,
  page,
  btnHide = true,
  steps,
  step,
  stepsValue,
  ...props
}) {
  const {t} = useTranslate();
  const [note, setNote] = useState('');

  let stepReopen = steps?.find((stepItem) => stepItem.id === step?.reopenStep);
  if (!stepReopen) {
    const indexStep = steps?.findIndex((stepItem) => stepItem.id === step?.id);
    stepReopen = steps[indexStep - 1];
    if(!stepReopen){
      stepReopen = steps[0];
    }
  }

  return (
    <Modal show={show} onHide={onHide} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{t('sheet_table_modal_title_reopen')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className='mb-1'>{t('sheet_table_modal_content_reopen_step')}</label>
        <div
          className={`d-flex justify-content-between align-items-center rounded mb-4 bg-light p-2`}
        >
          <div>{stepReopen?.name}</div>
        </div>
        <label className='mb-1'>{t('sheet_table_modal_content_reopen_note')}</label>
        <input
          type='text'
          className='form-control mb-2'
          onChange={(e) => setNote(e.target.value)}
          placeholder='Nhập lý do trả lại tại đây...'
          value={note}
        />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        {btnHide && (
          <Button variant='secondary' onClick={onHide} className='w-150px'>
            {t('common_cancel')}
          </Button>
        )}
        <Button
          variant={'primary'}
          onClick={() => handleExcute(stepReopen.id, note)}
          className='w-150px'
        >
          {t('sheet_table_column_approval_reopen')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalReopen;
