const chartWidths = [
  {
    value: 4,
    display: '1/3',
    icon: <i class='bi bi-layout-three-columns'></i>,
  },
  {
    value: 6,
    display: '1/2',
    icon: <i class='bi bi-layout-split'></i>,
  },
  {
    value: 12,
    display: '100%',
    icon: <i class='bi bi-square'></i>,
  },
];
export const ChartWidthSelect = ({value, onChange}) => {
  return (
    <div className='d-flex align-items-center'>
      {chartWidths.map((width) => {
        return (
          <button
            key={width.value}
            className={`d-flex align-items-center btn btn-sm ${
              width.value === value ? 'btn-light' : ''
            } text-capitalize`}
            onClick={() => {
              onChange(width.value);
            }}
          >
            {width.icon} {width.display}
          </button>
        );
      })}
    </div>
  );
};
