import {put, takeLatest} from 'redux-saga/effects';

import {getUserByToken} from './authApi';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  Login: '[Login] Action',
  Authenticated: '[Authenticated] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
};

const initialAuthState = {
  user: undefined,
  auth: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const user = {auth: action.payload.user};
      return {user};
    }

    case actionTypes.Authenticated: {
      const auth = action.payload.auth;
      return {auth};
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    case actionTypes.UserLoaded: {
      const {user} = action.payload;
      return {...state, user};
    }

    case actionTypes.SetUser: {
      const {user} = action.payload;
      return {...state, info: user};
    }

    default:
      return state;
  }
};

export const actions = {
  loginAction: (user) => ({type: actionTypes.Login, payload: {user}}),
  authenticateAction: (auth) => ({type: actionTypes.Authenticated, payload: {auth}}),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: {authToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: {user},
  }),
  fulfillUser: (user) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUserAction: (user) => ({type: actionTypes.SetUser, payload: {user}}),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    //yield put(actions.loginAction());
    //yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
