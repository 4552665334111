import { useDispatch } from 'react-redux';
import { createItemTemplateActions } from '../../stores/template/templateActions';

function useSheetTemplateHook() {
  const dispatch = useDispatch()

  const handleCreateTemplate = (templates, callback) => {
    dispatch(createItemTemplateActions(templates)).then((res) => {
      callback && callback();
    });
  };

  return {handleCreateTemplate};
}

export default useSheetTemplateHook;