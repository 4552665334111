import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

const SheetDataViewRowHeight = () => {
  const {t} = useTranslate();
  const popover = (
    <Popover>
      <Popover.Body>
        <p>{t('sheet_view_tool_row_height')}</p>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-arrow-circle-o-down' />
          <span>{t('sheet_view_tool_row_short')}</span>
        </div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-arrow-circle-o-down' />
          <span>{t('sheet_view_tool_row_medium')}</span>
        </div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-arrow-circle-o-down' />
          <span>{t('sheet_view_tool_row_tall')}</span>
        </div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-arrow-circle-o-down' />
          <span>{t('sheet_view_tool_row_extra_tall')}</span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      overlay={popover}
      rootClose
      rootCloseEvent='click'
    >
      <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
        <i className='la la-align-justify' />
      </div>
    </OverlayTrigger>
  );
};

export default SheetDataViewRowHeight;
