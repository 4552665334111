import './sheetKanbanView.scss';

import {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import KanbanColumn from './column/KanbanColumn';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import {isEqual} from 'lodash';
import update from 'immutability-helper';
import {useSession} from 'core/store/core/hooks';
import {useSheetData} from '../../../SheetDataHook';
import {useSheetViewEvent} from '../SheetViewEventHook';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from '../../../validation/useValidation';
import {validationErrorCode} from '../../../validation/constants';
import {useSheetPermission} from '../../../SheetPermissionHook';
import ItemDetailView from '../../item-detail/ItemDetailView';

export default function SheetKanbanView({
  sheetId,
  sheet,
  view,
  setSheet,
  updateView,
  onEditView,
  onSaveFilter,
  configurable,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
  },
  editable,
  renderToolbar,
}) {
  const {t} = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const {itemId} = useParams();
  const [kanbanColumn, setKanbanColumn] = useState(null);
  const [kanbanColumnValues, setKanbanColumnValues] = useState([]);
  const [itemDisplayFields, setItemDisplayFields] = useState([]);

  const [editItem, setEditItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const {user, getDomain} = useSession();
  const snackNotification = useSnackNotification();
  const {userSheetPermissions} = useSheetPermission({sheetId});
  const {
    fetchSheetItems,
    sheetItemList: items,
    addItemQuick: onAddItem,
    updateItem: onUpdateItem,
    deleteItem: onDeleteItem,
    setGroupByColumn,
    viewItemsByColumns,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
    requestFetchSheetItem,
    doAction: onDoAction,
  } = useSheetData({id: sheetId, sheet, view, user, setSheet, userSheetPermissions});

  const {getValidationErrorMessage} = useValidation({});
  const eventHandler = useSheetViewEvent();

  useEffect(() => {
    if (sheetId) {
      fetchSheetItems();
      eventHandler.initialize({
        domain: getDomain(),
        sheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [sheetId]);

  useEffect(() => {
    if (editItem && items) {
      let foundItem = items.find((x) => x._id === itemId || x._id === editItem?._id);
      if (foundItem) {
        setEditItem(foundItem);
      }
    }
  }, [itemId, items]);

  useEffect(() => {
    //Should move this logic inside hook
    const columns = sheet?.master_page?.columns || sheet?.columns || [];
    var column = null;
    if (view.kanban_column?.field) {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field === view.kanban_column.field) {
          column = columns[i];
          break;
        }
      }
    } else {
      for (var index = 0; index < columns.length; index++) {
        if (columns[index].type === 'status') {
          column = columns[index];
          break;
        }
      }
    }
    setKanbanColumn(column);
    setGroupByColumn(column);
    let columnDisplayValues = [];

    //Insert empty value column if not exist
    if (column?.values?.filter((x) => x.value === null || x.value === '').length > 0) {
      columnDisplayValues = [...column.values];
    } else {
      if (column?.values) {
        columnDisplayValues = [{label: '', value: ''}, ...column?.values];
      }
    }

    if (view.values_positions) {
      columnDisplayValues.sort(function (a, b) {
        return view.values_positions.indexOf(a.value) - view.values_positions.indexOf(b.value);
      });
    }

    setKanbanColumnValues(columnDisplayValues);

    if (view.display_fields) {
      let displayFields = view.display_fields;
      displayFields = displayFields.map((field) => {
        let column = columns.find((column) => column.field === field.field);
        return {
          ...field,
          ...column,
        };
      });
      setItemDisplayFields(displayFields);
    } else {
      //Build default display fields
      let displayFields = [];
      //Priotize builtin field
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field === 'people' || columns[i].field === 'date') {
          displayFields.push(columns[i]);
        }
      }

      setItemDisplayFields(displayFields);
    }
  }, [sheet, view]);

  const onShowItemDetail = (item) => {
    if (editItem?._id === item._id) {
      onHideKanbanItemDetail(item);
    } else {
      const isNotInApplicationContext = !location.pathname.includes('/application');
      if (isNotInApplicationContext) {
        navigate(`/work/sheet/${sheet._id}/views/${view.code}/items/${item._id}`, {replace: false});
      }
      setEditItem(item);
    }
  };

  const onHideKanbanItemDetail = () => {
    setEditItem(null);
  };

  const onSaveItemAction = (item, params, callback) => {
    const paramsAction = {
      ...params,
      itemId: item?._id,
    };
    onDoAction(paramsAction, callback);
  };

  const onSaveItem = (item, field, oldValue, newValue, callback) => {
    if (oldValue !== newValue) {
      const defaultCallback = (res) => {
        if (res?.data?.success) {
          snackNotification.showSuccess(t('common_update_success'));
        } else {
          if (res.data.code === validationErrorCode) {
            snackNotification.showError(
              res?.data?.message
                ? getValidationErrorMessage(res?.data?.message)
                : t('common_update_error')
            );
          } else {
            snackNotification.showError(
              res?.data?.message ? t(res?.data?.message) : t('common_update_error')
            );
          }
        }
      };
      onUpdateItem && onUpdateItem(item, field, oldValue, newValue, callback ?? defaultCallback);
    }
  };

  const onDeleteItemInit = (item) => {
    setItemToDelete(item);
  };

  const onDeleteItemConfirm = () => {
    onDeleteItem(itemToDelete, () => {
      setItemToDelete(null);
      setEditItem(null);
    });
  };

  const updateKanbanColumnPositions = (columns) => {
    let valuesPositions = columns.map((col) => col.value);
    updateView && updateView(view, {...view, values_positions: valuesPositions});
  };

  const itemSelected = (item) => {
    return itemId === item._id;
  };

  const isReadOnly = () => {
    return !editable;
  };

  const showAddButton = () => {
    return editable;
  };

  return (
    <>
      {options?.showToolbar && renderToolbar && (
        <>
          {renderToolbar({
            sheet: sheet,
            view: view,
            onSetting: () => onEditView(view),
            onFilterApply: applyFilter,
            onSearch: applySearch,
            saveFilter: onSaveFilter,
            onFilterReset: resetFilters,
            options: options?.userActions,
            activeFilters: activeFilters,
            filterActive: filterActive,
            sheetEditable: editable,
            showAddButton: showAddButton(),
            userSheetPermissions,
          })}
        </>
      )}
      <DndProvider backend={HTML5Backend}>
        <KanbanBoard
          sheet={sheet}
          permissions={userSheetPermissions}
          column={kanbanColumn}
          columnValues={kanbanColumnValues}
          items={viewItemsByColumns}
          displayFields={itemDisplayFields}
          onSaveItem={onSaveItem}
          onAddItem={onAddItem}
          onShowItemDetail={onShowItemDetail}
          itemSelected={itemSelected}
          updateKanbanColumnPositions={updateKanbanColumnPositions}
          configurable={configurable}
          editable={editable}
          onAction={onSaveItemAction}
        />
      </DndProvider>
      <ItemDetailView
        open={editItem !== null}
        item={editItem}
        permissions={userSheetPermissions}
        key={view.code + '-item-detail'}
        sheet={sheet}
        view={view}
        onHide={() => onHideKanbanItemDetail()}
        onDelete={onDeleteItemInit}
        onUpdateItem={onUpdateItem}
        readOnly={isReadOnly()}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={itemToDelete !== null}
        onHide={() => setItemToDelete(null)}
        title={t('sheet_item_delete_confirm_title')}
        content={t('sheet_item_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteItemConfirm}
      />
    </>
  );
}

const KanbanBoard = ({
  sheet,
  column,
  permissions,
  columnValues,
  items,
  onSaveItem,
  onAddItem,
  displayFields,
  onShowItemDetail,
  itemSelected,
  updateKanbanColumnPositions,
  configurable,
  editable,
  onAction,
}) => {
  const [internalColumnValues, setInternalColumnValues] = useState(columnValues);
  useEffect(() => {
    setInternalColumnValues(columnValues);
  }, [columnValues]);

  const moveColumn = useCallback((dragIndex, hoverIndex) => {
    setInternalColumnValues((prevColumns) =>
      update(prevColumns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevColumns[dragIndex]],
        ],
      })
    );
  }, []);

  const saveColumn = () => {
    if (internalColumnValues && !isEqual(columnValues, internalColumnValues)) {
      updateKanbanColumnPositions && updateKanbanColumnPositions(internalColumnValues);
    }
  };

  return (
    <div className='d-flex align-items-stretch sheet-kanban-board'>
      {internalColumnValues.map((value, index) => {
        return (
          <KanbanColumn
            sheet={sheet}
            permissions={permissions}
            key={value.value}
            column={column}
            columnIndex={index}
            columnValue={value}
            items={items[value.value]}
            displayFields={displayFields}
            onSaveItem={onSaveItem}
            onAddItem={onAddItem}
            onShowItemDetail={onShowItemDetail}
            moveColumn={moveColumn}
            saveColumn={saveColumn}
            itemSelected={itemSelected}
            configurable={configurable}
            editable={editable}
            onAction={onAction}
          />
        );
      })}
    </div>
  );
};
