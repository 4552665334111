import {get, isArray, isEmpty} from 'lodash';

export const sheetActions = {
  config: 'board.edit',
  edit: 'edit',
  view: 'view',
};

const hasRootPermission = (user) => {
  if (user?.roles?.find((role) => role.indexOf('root') >= 0)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Check if a user has permission to perform an action on a given resource.
 *
 * @param {array} permissions - List of permission objects with action and resource properties.
 * @param {object} user - User object.
 * @param {string} resource - Resource key, e.g: 'item', 'board', ...
 * @param {string} action - Action key, e.g: 'view', 'edit',...
 *
 * @returns {boolean} If the user has the permission.
 */
const hasPermissionFor = (permissions, _user, resource, action) => {
  const matchPermissions = permissions?.find((permission) => {
    const isActionPermitted = permission.action.some((a) => a.includes('*') || a.includes(action));

    const isResourcePermitted = permission.resource.some(
      (r) => r.indexOf('*') >= 0 || r.indexOf(resource) >= 0
    );

    return isActionPermitted && isResourcePermitted;
  });

  return Boolean(matchPermissions);
};

const isUserInTeam = (user, memberIds) => {
  return (user?.teams ?? []).some((team) => memberIds.includes(team?.id));
};

const getPermissionForRole = (sheet, role, action) => {
  return get(sheet.roles, `${role}.permissions.${action}`);
};

export const hasSheetPermission = (sheet, user, action) => {
  if (user?.roles?.find((role) => role.indexOf('root') >= 0)) {
    return true;
  }

  const memberIds = sheet?.members?.map((member) => member?.id) || [];

  if (action === sheetActions.view) {
    if (sheet?.privacy === 'shareable') {
      return true;
    }
    if (sheet?.privacy === 'public' && user?._id) {
      return true;
    }
    return memberIds.includes(user?._id) || isUserInTeam(user, memberIds);
  }

  let member = sheet?.members?.find((member) => {
    if (member?.type === 'team') {
      let userTeams = user?.teams ?? [];
      return userTeams.find((team) => team?.id === member?.id);
    } else {
      return member?.id === user?._id;
    }
  });

  if (action === sheetActions.edit) {
    return !!member;
  }

  let role = member?.role;
  if (member && role) {
    if (getPermissionForRole(sheet, role, action)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const hasSheetBoardPermission = (userSheetPermissions, user, action) => {
  const permissions = userSheetPermissions?.permissions ?? [];
  if (hasRootPermission(user) || hasPermissionFor(permissions, user, 'board', action)) {
    return true;
  } else {
    return false;
  }
};

export const hasEditSheetDataPermission = (userSheetPermissions, user, action) => {
  const permissions = userSheetPermissions?.permissions ?? [];
  if (hasRootPermission(user) || hasPermissionFor(permissions, user, 'item:all', action)) {
    return true;
  } else {
    if (hasPermissionFor(permissions, user, 'item:assigned', action)) {
      return true;
    }
  }
  return false;
};

export const hasEditItemColumnPermission = (user, column, row) => {
  const userTeams = user?.teams ?? [];
  const isPermittedUserEdit = column?.permitted_users?.some((permittedUser) => {
    if (permittedUser?.type && permittedUser?.type === 'team') {
      return userTeams.find((team) => permittedUser.id === team.id);
    } else {
      return permittedUser.id === user._id;
    }
  });
  const isPermittedColumnEdit = column?.permitted_user_columns?.some((permittedUser) => {
    const peopleItems = row?.[permittedUser?.value]?.value;
    if (peopleItems) {
      return peopleItems.some((people) => {
        if (people?.type && people?.type === 'team') {
          return userTeams.find((team) => people.id === team.id);
        } else {
          return people?._id === user?._id;
        }
      });
    }
    return false;
  });

  if (!column?.is_permitted || isPermittedUserEdit || isPermittedColumnEdit) {
    return true;
  } else {
    return false;
  }
};

export const hasViewSheetColumnPermission = (currentUser, column) => {
  if (!column?.is_view_permitted) {
    return true;
  }
  const userTeams = currentUser?.teams ?? [];
  const viewPermittedUsersIds =
    column?.view_permitted_users?.map((user) => {
      if (user?.type && user?.type === 'team') {
        let find = userTeams.find((team) => team?.id === user?.id);
        if (find) {
          return currentUser?._id;
        } else {
          return null;
        }
      } else {
        return user?._id;
      }
    }) || [];
  return viewPermittedUsersIds?.includes(currentUser?._id);
};

export const hasViewViewPermission = (user, view) => {
  if (!view?.permitted_users) {
    return true;
  }
  const userTeams = user?.teams ?? [];
  return (
    view?.permitted_users.includes(user?._id) ||
    userTeams.some((team) => view?.permitted_users.includes(team?.id))
  );
};

export const hasEditItemPermission = (userSheetPermissions, user, row) => {
  if (row?._id === '66ecfb53d03962230d0d103f') {
    console.log('hasEditItemPermission', userSheetPermissions);
  }
  const permissions = userSheetPermissions?.permissions ?? [];
  const editAssignedColumns = userSheetPermissions?.editAssignedColumns ?? [];
  if (!permissions?.length > 0 || !user || !row) {
    return false;
  }
  if (hasRootPermission(user) || hasPermissionFor(permissions, user, 'item:all', 'edit')) {
    return true;
  } else {
    if (hasPermissionFor(permissions, user, 'item:assigned', 'edit')) {
      let canEdit = false;
      let userTeams = user?.teams ?? [];
      //1. get people columns in setting or get all.
      let sheetItemAssignedColumnsCanEdit = editAssignedColumns;

      if (isEmpty(sheetItemAssignedColumnsCanEdit)) {
        sheetItemAssignedColumnsCanEdit = Object.keys(row).filter((key) => {
          return row[key]?.value?.length > 0;
        });
      }
      //2. loop all assigned columns and check cellVal and current user
      sheetItemAssignedColumnsCanEdit.forEach((column) => {
        let cellPeopleVal = get(row, `${column}.value`) || [];
        if (isArray(cellPeopleVal)) {
          const peoplesAssignee = cellPeopleVal?.flatMap((value) => {
            return value?.assignees || [];
          });
          if (!isEmpty(peoplesAssignee)) {
            cellPeopleVal = cellPeopleVal.concat(peoplesAssignee);
          }
        }

        if (isEmpty(cellPeopleVal)) {
          return false;
        }

        const peopleIds = cellPeopleVal ? cellPeopleVal?.map?.((i) => i?.id) : [];
        if (
          peopleIds &&
          (peopleIds?.includes(user._id) || userTeams.some((team) => peopleIds?.includes(team.id)))
        ) {
          canEdit = true;
        }
      });

      return canEdit;
    }
  }
  return false;
};
