import React, { createContext, useState, useContext } from "react";
import "./snackNotificationProvider.scss"
import CustomizedSnackbar from "app/common/_partials/controls/Snackbar";

const SnackNotificationContext = createContext();

export function useSnackNotification() {
  return useContext(SnackNotificationContext);
}

export const SnackNotificationConsumer = SnackNotificationContext.Consumer;

export function SnackNotificationProvider({ children }) {
  const [snack, setSnack] = useState({
    open: false,
    id: null,
    message: "",
    variant: "success",
  });

  const showSuccess = (message) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "success",
    });
  };

  const showError = (message) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: 'danger',
    });
  };

  const showWarning = (message) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "warning",
    });
  };

  const value = {snack, setSnack, showSuccess, showError, showWarning};

  return (
    <SnackNotificationContext.Provider value={value}>
      {children}
      <CustomizedSnackbar
        vertical={snack.vertical}
        horizontal={snack.horizontal}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={snack.open}
        options={snack}
        id={snack.id}
        variant={snack.variant}
        content={snack.message}
      />
    </SnackNotificationContext.Provider>
  );
}
