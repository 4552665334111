import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import './modalCopyToColumn.scss';
import {useSession} from 'core/store/hooks';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {hasEditItemColumnPermission} from '../../permission/permissionHelper';
import clsx from 'clsx';
import {capitalizeFirstLetter} from 'app/common/_helpers/FunctionHelper';

function ModalCopyToColumn({title, sheet, view, show, onHide, onCopyColumn}) {
  const {t} = useTranslate();
  const {user} = useSession();
  const snackNotification = useSnackNotification();

  const [columns, setColumns] = useState([]);
  const [originalColumn, setOriginalColumn] = useState(null);
  const [toColumn, setToColumn] = useState(null);
  const [listMoveColumn, setListMoveColumn] = useState([]);
  const [listCopySuggest, setListCopySuggest] = useState([]);
  const [listColumnOriginalSelect, setListColumnOriginalSelect] = useState([]);
  const listColumnSuggestDefaultRef = useRef([]);

  const handleSelectOriginalColumn = (e) => {
    const value = e.target.value;
    setOriginalColumn(value);
  };

  useEffect(() => {
    if (originalColumn) {
      const fromColumn = columns.find((column) => column?.field === originalColumn);
      const listMoveColumn = columns.filter(
        (column) =>
          !column.deleted &&
          !column.hidden &&
          column?.type === fromColumn?.type &&
          column?.field !== fromColumn?.field
      );
      setListMoveColumn(listMoveColumn);
    }
  }, [originalColumn, columns]);

  useEffect(() => {
    if (view?.columns && sheet?.columns) {
      let listColumnsByView = view?.columns?.map((item) => {
        let columnData = sheet.columns?.find((column) => column.field === item.field);
        return {
          ...columnData,
          hidden: item.hidden,
          name: capitalizeFirstLetter(columnData?.name),
        };
      });
      let listColumns = sheet?.columns?.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {
          ...item,
          hidden: false,
          name: capitalizeFirstLetter(item.name),
        };
      });

      setColumns([...listColumnsByView, ...listColumns]);
    } else {
      const listColumnFormat = sheet?.columns?.map((column) => ({
        ...column,
        name: capitalizeFirstLetter(column.name),
      }));
      setColumns(listColumnFormat);
    }
  }, [sheet?.columns, view?.columns]);

  const handleCopyColumn = () => {
    let fieldFromColumn = originalColumn;
    let fieldToColumn = toColumn;
    if (fieldFromColumn && fieldToColumn) {
      const _toColumnItem = columns.find((column) => column.field === fieldToColumn);
      const columnEditable = hasEditItemColumnPermission(user, _toColumnItem);
      if (columnEditable) {
        onCopyColumn(fieldFromColumn, fieldToColumn);
      } else {
        snackNotification.showWarning(t('sheet_copy_to_column_false'));
      }
    }
  };

  const hasPermissionCopyColumn = (columnTo, columnFrom) =>{
    const viewPermittedUsersColumnTo = columnTo?.view_permitted_users || [];
    const isViewableColumnTo = !columnTo?.is_view_permitted || viewPermittedUsersColumnTo.find(permittedUser => permittedUser.id === user?._id);

    const viewPermittedUsersColumnFrom = columnFrom?.view_permitted_users || [];
    const isViewableColumnFrom = !columnFrom?.is_view_permitted  || viewPermittedUsersColumnFrom.find(permittedUser => permittedUser.id === user?._id);

    return isViewableColumnTo && isViewableColumnFrom
  }

  useEffect(() => {
    if (columns.length) {
      const listCopySuggest = [];
      const listColumnOriginalSelect = [];
      for (let i = 0; i < columns.length; i++) {
        const element = columns[i];
        columns.forEach((column) => {
          if (
            !element.deleted &&
            !element.hidden &&
            !column.deleted &&
            !column.hidden &&
            column?.type === element?.type &&
            column?.field !== element?.field &&
            isColumnSelect(column)
          ) {
            if (hasPermissionCopyColumn(column, element)) {
              if (!listColumnOriginalSelect.includes(element)) {
                listColumnOriginalSelect.push(element.field);
              }
              listCopySuggest.push({fromColumn: element, toColumn: column});
            }
          }
        });
      }

      listColumnSuggestDefaultRef.current = listCopySuggest;
      setListCopySuggest(listCopySuggest);
      setListColumnOriginalSelect(listColumnOriginalSelect);
    }
  }, [columns]);

  useEffect(() => {
    return () => {
      if (!show) {
        setListCopySuggest(listColumnSuggestDefaultRef.current);
      }
    };
  }, [show]);

  const isColumnSelect = (column) => {
    return !column.deleted && !column.hidden && column.type !== 'status' && column.type !== 'color' && column.type !== 'last_updated_date';
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <div className='fs-5 fw-bold'>{title || ''}</div>
        <div className=' mt-4 fw-bold'>{t('sheet_copy_to_column_suggest')}</div>
        {listCopySuggest.map((column) => {
          return (
            <div className='row p-2 mt-2 bg-light rounded px-0 mx-0 bg-hover-se' key={column.fromColumn.field}>
              <div className='col-5'>
                <div className='p-2 rounded text-center'>
                  {capitalizeFirstLetter(column?.fromColumn?.name)}
                </div>
              </div>
              <div
                className={`col-2 icon-copy-column cursor-pointer bg-secondary p-2 rounded d-flex justify-content-center align-items-center
                  ${clsx({
                    'bg-info':
                      originalColumn === column?.fromColumn?.field &&
                      toColumn === column?.toColumn?.field,
                  })}
                `}
                onClick={() => {
                  setOriginalColumn(column?.fromColumn?.field);
                  setToColumn(column?.toColumn?.field);
                }}
              >
                <i
                  className={`fa-solid fa-angle-right icon-copy  
                    ${clsx({
                      'text-white':
                        originalColumn === column?.fromColumn?.field &&
                        toColumn === column?.toColumn?.field,
                    })}`}
                ></i>
              </div>
              <div className='col-5'>
                <div className='p-2 rounded text-center'>
                  {capitalizeFirstLetter(column?.toColumn?.name)}
                </div>
              </div>
            </div>
          );
        })}
        <div className='fw-bold mt-4'>{t('sheet_copy_to_column_select')}</div>
        <div className='row mt-4'>
          <div className='col-6'>
            <select
              className='form-control'
              value={originalColumn}
              onChange={handleSelectOriginalColumn}
            >
              <option value=''>---{t('sheet_copy_from_column')}---</option>
              {columns?.map((column) => {
                if (isColumnSelect(column) && listColumnOriginalSelect.includes(column.field)) {
                  return (
                    <option value={column.field} key={column.field}>
                      {column.name}
                    </option>
                  );
                } else {
                  return <React.Fragment key={column.field} />;
                }
              })}
            </select>
          </div>
          <div className='col-6'>
            <select
              className='form-control'
              value={toColumn}
              onChange={(e) => setToColumn(e.target?.value)}
            >
              <option value=''>---{t('sheet_copy_to_column')}---</option>
              {listMoveColumn?.map((column) => {
                if (isColumnSelect(column)) {
                  return (
                    <option value={column.field} key={column.field}>
                      {column.name}
                    </option>
                  );
                } else {
                  return <React.Fragment key={column.field} />;
                }
              })}
            </select>
          </div>
        </div>
        <div className='fw-bold mt-3'>{t('sheet_copy_to_column_warning')}</div>
        <Modal.Footer className='p-0 mt-4 border-0'>
          <button
            type='button'
            className='btn btn-light btn-elevate btn-secondary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
            onClick={onHide}
          >
            {t('common_cancel')}
          </button>
          <button
            type='button'
            className='btn btn-primary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-110px'
            onClick={handleCopyColumn}
          >
            {t('common_copy')}
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCopyToColumn;
