import {useMemo, useState} from 'react';

const headingLevel = [1, 2, 3];

const BlockToc = (props) => {
  const {blockItems, onSelectHeading} = props;
  const [isTocExpand, setIsTocExpand] = useState(false);

  const toc = useMemo(() => {
    if (!blockItems.length) {
      return null;
    }

    let heading = [];

    for (let block of blockItems) {
      const blockType = block?.content?.type;
      const blockHeadingLevel = block?.content?.data?.level;
      const isHeadingTag = !!(blockType === 'header' && headingLevel.includes(blockHeadingLevel));

      if (isHeadingTag) {
        heading.push({
          id: block?.content?.id,
          level: blockHeadingLevel,
          text: new DOMParser().parseFromString(block.content?.data?.text, 'text/html')
            .documentElement.textContent,
        });
      }
    }

    return heading;
  }, [blockItems]);

  return (
    <div className='toc-list d-none d-xl-flex flex-column'>
      <div
        className='cursor-pointer d-flex align-items-center justify-content-center btn-toc-toggle'
        onClick={() => setIsTocExpand((prevState) => !prevState)}
      >
        {isTocExpand ? (
          <i className='bi bi-chevron-left text-gray-600 fs-6'></i>
        ) : (
          <i className='bi bi-list-task text-gray-600 fs-3'></i>
        )}
      </div>
      <div className='d-flex flex-column flex-shrink-1 flex-grow-1 flex-basic-0 pt-3'>
        <ul
          className={`list-unstyled text-gray-700 ps-5 mb-0 flex-shrink-1 flex-grow-1 flex-basic-0 overflow-auto ${
            isTocExpand ? 'toc-expanded' : ''
          }`}
        >
          {toc?.map((item) => (
            <li
              key={item.id}
              className={`fst-normal cursor-pointer my-2 text-truncate toc-h${item.level}`}
              onClick={() => onSelectHeading(item, true)}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlockToc;
