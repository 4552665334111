export function generateNameAvatar(name) {
  let words = name ? name.trim() : '';
  words = words?.split(' ');
  let nameAvatar = name?.[0];
  if (words?.length > 1) {
    nameAvatar = words[0][0] + words[1][0];
  } else if (words?.length == 1 && words[0].length > 1) {
    nameAvatar = words[0][0] + words[0][1];
  } else {
    nameAvatar = name;
  }
  return nameAvatar;
}

export function getAggregationName(aggregation) {
  switch (aggregation) {
    case 'sum':
      return 'Tổng';
    case 'avg':
      return 'Trung bình';
    case 'count':
      return 'Đếm';
    case 'count_item':
      return 'Đếm phần tử';
    case 'max':
      return 'Lớn nhất';
    case 'min':
      return 'Nhỏ nhất';
    default:
      return aggregation;
  }
}

const AGGREGATIONS = ['count', 'sum', 'max', 'min', 'avg'];

export const getAggregationOptions = () => {
  return AGGREGATIONS.map((val) => {
    return {label: getAggregationName(val), value: val};
  });
};

export const getAggregationOptionByValue = (value)=>{
  return getAggregationOptions().find((option) => option.value === value);
}

//Backward compartibility for old field
export function effectiveValue(fieldValue) {
  let effValue = fieldValue?.value;
  if (fieldValue && fieldValue.hasOwnProperty('value')) {
    effValue = fieldValue.value;
  } else if (fieldValue) {
    effValue = fieldValue;
  } else {
    effValue = null;
  }

  return effValue;
}
