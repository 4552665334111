import React from 'react';
import {Card, CardBody} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch} from 'react-redux';
import {useItemDetail} from '../../component/item-detail/ItemDetailHook';
import ItemFileView from '../../component/item-detail/ItemFile';
import {downloadLinkFileAction} from '../../stores/sheetActions';
import './itemDetail.scss';
import ItemField from '../../component/field/ItemField';

export default function ItemDetail({item, columns, onHide}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();

  const {itemFiles, setCurrentFile} = useItemDetail(item);

  const disableFieldColumn = ['title', 'approval'];

  const onViewFile = (itemFile) => {
    const params = {
      'get-content': true,
    };
    dispatch(downloadLinkFileAction(itemFile._id, params)).then((res) => {
      if (res.data?.link) {
        setCurrentFile({...itemFile, link: res.data?.link});
      }
    });
  };

  return (
    <>
      <div
        id='kt_quick_panel'
        className='pt-5 pb-10 w-700px h-100 d-flex flex-column'
        key={item?._id}
      >
        <div className='offcanvas-header offcanvas-header-navs'>
          <span>&nbsp;</span>
        </div>

        <div
          className='offcanvas-close mt-n1 pe-5'
          style={{position: 'absolute', top: '15px', left: '10px'}}
        >
          <div
            className='btn btn-xs btn-icon btn-light btn-hover-primary'
            id='kt_quick_panel_close'
            onClick={() => onHide && onHide()}
          >
            <i className='ki ki-close icon-xs text-muted'></i>
          </div>
        </div>
        <div className='flex-row-auto ps-10 pe-10' id='kt_profile_aside'>
          {/* begin::Item */}
          <div className='d-flex align-items-center justify-content-between'>
            <div className='w-100'>
              <span className=' item-text-input-detail w-100 fw-bold fs-4 bg-body text-dark'>
                {item?.title?.value}
              </span>
            </div>
          </div>
        </div>
        <div className='flex-shrink overflow-auto'>
          <div className='ps-10 pe-10 mb-4'>
            <div className='mt-3 px-2'>{t('sheet_item_detail_comment')}</div>
            <div
              className={`mt-3 px-2`}
              dangerouslySetInnerHTML={{
                __html: item?.content?.value,
              }}
            ></div>

            <div className='mt-2'>
              {columns?.map((column) => {
                if (
                  !disableFieldColumn.includes(column.field) &&
                  !column.deleted &&
                  !column.hidden
                ) {
                  return (
                    <div
                      className='detail-field-container d-flex text-gray-800 py-2 border mt-2 rounded px-2'
                      key={column?.field}
                    >
                      <div
                        className='detail-field-name text-capitalize text-truncate'
                        style={{minWidth: '80px'}}
                      >
                        {column?.name}
                      </div>
                      <div className={`detail-field-value ms-3 border h-30px ms-2 w-150px`}>
                        <ItemField
                          valueClassName='border h-30px ms-2 kanban-field-value w-150px'
                          value={item?.[column?.field]}
                          item={item}
                          column={column}
                          fieldEditable={false}
                        />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div>
            <Card>
              <CardBody className='py-0 pb-2 '>
                <div className='ps-1 d-flex flex-wrap align-items-center gap-3'>
                  {itemFiles &&
                    itemFiles.map((itemFile) => {
                      return (
                        <div className='' key={itemFile._id}>
                          <ItemFileView itemFile={itemFile} viewFile={onViewFile}></ItemFileView>
                        </div>
                      );
                    })}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
