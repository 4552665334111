import React, {useEffect, useRef, useState} from 'react';
import './textarea.scss';

const Textarea = ({
  key,
  defaultValue,
  readOnly,
  className,
  placeholder,
  onBlur,
  textEditable = false,
}) => {
  const [val, setVal] = useState();
  const ref = useRef(null);

  useEffect(() => {
    if (textEditable) {
      ref.current.style.height = 'auto';
      ref.current.style.height = ref.current.scrollHeight + 'px';
    }
  }, [val]);

  const textAreaClass = `${className} ${textEditable && 'textarea-editable'}`;
  return (
    <>
      <textarea
        key={key}
        ref={ref}
        value={val}
        onChange={(e) => setVal(e?.target?.value)}
        readOnly={readOnly}
        className={textAreaClass}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={onBlur}
        rows={1}
      ></textarea>
    </>
  );
};

export default Textarea;
