export const backgroundColorOptions = [
  {
    label: 'Xanh lam',
    value: '#1B84FF',
  },
  {
    label: 'Xanh lá',
    value: '#17C653',
  },
  {
    label: 'Tím',
    value: '#7239EA',
  },
  {
    label: 'Vàng',
    value: '#F6C000',
  },
  {
    label: 'Đỏ',
    value: '#F8285A',
  },
  {
    label: 'Đen',
    value: '#1E2129',
  },
  {
    label: 'Xanh mạ',
    value: '#1BC5BD',
  },
  {
    label: 'Nâu',
    value: '#7f5347',
  },
  {
    label: 'Xám',
    value: '#9aadbd',
  },
  {
    label: 'Cà phê',
    value: '#cd9282',
  },
  {
    label: 'Cam',
    value: '#ff642e',
  },
  {
    label: 'Hồng',
    value: '#faa1f1',
  },
];
