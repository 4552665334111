import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import React, {useRef, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import './mainField.scss';

function MainField({
  value,
  item,
  column,
  onChange,
  mode,
  fieldEditable,
  readOnly,
  containerClassName = '',
  onToggleSubitem,
  showExpand,
  showDetail,
}) {
  const field = column?.field;
  const effValue = effectiveValue(value);
  const itemInputRef = useRef(null);
  const itemTitleRef = useRef(null);
  const isDetailViewMode = mode === 'detail';
  const placeholder = isDetailViewMode ? column?.placeholder : '';
  const textAlignClassName = mode === 'detail' ? 'text-start' : '';

  const [showSubItem, setShowSubItem] = useState(false);
  const toggleShowSubitem = () => {
    let newVisibility = !showSubItem;
    let subitemsContainer = document.getElementById(item._id + '-subitems-container');
    if (subitemsContainer) {
      if (newVisibility) {
        subitemsContainer.style.display = 'block';
      } else {
        subitemsContainer.style.display = 'none';
      }
    }

    setShowSubItem(newVisibility);
    onToggleSubitem(item._id);
  };

  const onBlur = (e) => {
    if (effValue !== e.target.value) {
      onChange(item, field, value, {value: e.target.value});
    }
  };

  const isShowInputTooltip =
    itemInputRef?.current?.offsetWidth < itemInputRef?.current?.scrollWidth;

  const isShowTitleTooltip =
    itemTitleRef?.current?.offsetWidth < itemTitleRef?.current?.scrollWidth;

  if (readOnly) {
    return (
      <div className='d-flex sheet-item-detail-title align-items-center w-100'>
        {showExpand && (
          <div
            className='btn btn-icon btn-xs text-nowrap w-30px flex-shrink-0 cursor-pointer'
            onClick={(e) => {
              e.stopPropagation();
              toggleShowSubitem();
            }}
          >
            {showSubItem ? (
              <i className='bi bi-chevron-down '></i>
            ) : (
              <i className='bi bi-chevron-right cursor-pointer'></i>
            )}
          </div>
        )}
        {isShowTitleTooltip ? (
          <OverlayTrigger
            key='title-item'
            placement='top'
            overlay={
              <Tooltip
                className='tooltip-inverse tooltip-title-item position-fixed'
                id='tooltip-title-item'
                style={{width: `${itemTitleRef?.current?.offsetWidth}px`}}
              >
                {effValue}
              </Tooltip>
            }
          >
            <span
              className='flex-grow-1 w-100 pt-2 pb-3 px-2 title-limit-text-1 h-35px'
              ref={itemTitleRef}
            >
              {effValue}
            </span>
          </OverlayTrigger>
        ) : (
          <span
            className='flex-grow-1 w-100 pt-2 pb-3 px-2 title-limit-text-1 h-35px'
            ref={itemTitleRef}
          >
            {effValue}
          </span>
        )}

        {item._id && (
          <div
            className='btn btn-icon btn-xs text-nowrap w-45px border-start flex-shrink-0'
            onClick={(e) => {
              e.stopPropagation();
              showDetail(item);
            }}
          >
            <i className='las la-expand-arrows-alt cursor-pointer'></i>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={clsx('d-flex sheet-item-detail-title align-items-center w-100')}>
      {showExpand && (
        <div
          className='btn btn-icon btn-xs text-nowrap w-30px flex-shrink-0 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            toggleShowSubitem();
          }}
        >
          {showSubItem ? (
            <i className='bi bi-chevron-down '></i>
          ) : (
            <i className='bi bi-chevron-right cursor-pointer'></i>
          )}
        </div>
      )}

      <div className='flex-grow-1 w-100 title-limit-text-1'>
        {isShowInputTooltip ? (
          <OverlayTrigger
            key='title-item'
            placement='top'
            overlay={
              <Tooltip
                className='tooltip-inverse tooltip-title-item position-fixed'
                id='tooltip-title-item'
                style={{width: `${itemInputRef?.current?.offsetWidth}px`}}
              >
                {effValue}
              </Tooltip>
            }
          >
            {fieldEditable ? (
              <input
                key={effValue}
                ref={itemInputRef}
                type='text'
                className={`form-control form-control-xs item-text-input bg-transparent ${textAlignClassName}`}
                defaultValue={effValue}
                onBlur={onBlur}
                placeholder={placeholder}
              ></input>
            ) : (
              <span className='w-100 px-2 title-limit-text-1' ref={itemInputRef}>
                {effValue}
              </span>
            )}
          </OverlayTrigger>
        ) : (
          <>
            {fieldEditable ? (
              <input
                key={effValue}
                ref={itemInputRef}
                type='text'
                className={`form-control form-control-xs item-text-input bg-transparent ${textAlignClassName}`}
                defaultValue={effValue}
                onBlur={onBlur}
                placeholder={placeholder}
              ></input>
            ) : (
              <span className='w-100 px-2 title-limit-text-1' ref={itemInputRef}>
                {effValue}
              </span>
            )}
          </>
        )}
      </div>
      {item._id && (
        <div
          className='w-45px d-flex align-items-center justify-content-center btn-show-dedail'
          onClick={(e) => {
            e.stopPropagation();
            showDetail(item);
          }}
        >
          <i className='las la-expand-arrows-alt cursor-pointer'></i>
        </div>
      )}
    </div>
  );
}

export default MainField;
