import './sheetPagingTableV2.scss';
import {CardBody, PaginationLinks, PaginationToolbar} from 'app/common/_partials/controls';
import {HeaderFormatter} from './ColumnHeader';
import {
  hasViewSheetColumnPermission,
  hasEditItemColumnPermission,
  hasSheetPermission,
} from '../../../permission/permissionHelper';
import {useEffect, useMemo, useRef, useState} from 'react';

import {ActionBar} from '../../action-bar/ActionBar';
import ColumnUserPermittedEdit from '../../sheet-config/column/ColumnUserPermittedEdit';
import ConfigUsersCanViewColumnDialog from 'app/modules/work/sheet/permission/ConfigUsersCanViewColumnDialog';
import ItemField from '../../field/ItemField';
import {Modal} from 'react-bootstrap';
import ModalAddNewItem from './modal/ModalAddNewItem';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import ModalFormulaEditor from '../../formula-editor/ModalFormulaEditor';
import SheetAddColumn from '../../sheet-config/column/add/SheetAddColumn';
import SheetEditColumn from '../../sheet-config/column/SheetEditColumn';
import {useParams} from 'react-router-dom';
import {useSession} from 'core/store/core/hooks';
import {useSheetData} from '../../../SheetDataHook';
import useSheetTableHook from './SheetTableHook';
import {useSheetViewEvent} from '../SheetViewEventHook';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from '../../../validation/useValidation';
import {validationErrorCode} from '../../../validation/constants';
import {useSheetPermission} from '../../../SheetPermissionHook';
import GridView from '../../../../components/GridView/GridView';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import {SelectColumn} from 'react-data-grid';
import ItemDetailView from '../../item-detail/ItemDetailView';
import {SheetColumnAddHeader} from '../../sheet-config/column/add/SheetColumnAddHeader';

export default function SheetPagingTableViewV2({
  sheetId,
  sheet,
  setSheet,
  view,
  onSaveFilter,
  sheetMeta,
  onEditView,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
    hideColumnEditor: false,
  },
  configurable,
  editable,
  renderToolbar,
  linkableSheets,
}) {
  const {t} = useTranslate();

  const {getValidationErrorMessage} = useValidation({});
  const {itemId} = useParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);

  const addingItem = useRef(false);
  const {user, getDomain} = useSession();
  const [subSelectItems, setSubSelectItems] = useState({});
  const [showModalAddNewItem, setShowModalAddNewItem] = useState(false);
  const enableActionsSubitems = ['remove_item'];
  const enableActions = ['remove_item', 'move_item', 'move_item:move_to_sheet', 'tools'];
  const {userSheetPermissions} = useSheetPermission({sheetId});
  const {
    requestFetchSheetItem,
    fetchSheetItems,
    sheetItemList,
    addItemFull,
    updateItem: onUpdateItem,
    doAction: onDoAction,
    updateItems: onUpdateItems,
    addValueToItems,
    removeValueInItems,
    deleteItem: onDeleteItem,
    removeItems: onRemoveItems,
    removeSubItems: onRemoveSubItems,
    moveItems: onMoveItems,
    exportExcel: onExportExcel,
    tools,
    totalItems,
    paging,
    sheetGroups,
    setPaging,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
    addItemsAndSubItems,
    userViewableColumns,
    addValueToItem,
    removeValueInItem,
    fetchSheet,
    isLoadingAddItem,
  } = useSheetData({
    id: sheetId,
    sheet,
    setSheet,
    view,
    user,
    isRemote: true,
  });

  const {
    setNewItems,
    editColumn,
    setEditColumn,
    onEditColumn,
    onEditFormula,
    editFormulaColumn,
    setEditFormulaColumn,
    onSaveFormula,
    onSaveColumn,
    userEditColumn,
    setUserEditColumn,
    onOpenPermittedEditColumn,
    onToggleColumnPermitted,
    onOpenUsersCanViewColumnDialog,
    currentColumn,
    isShowConfigUsersCanViewColumnDialog,
    setIsShowConfigUsersCanViewColumnDialog,
    onDeleteColumnClick,
    onDeleteColumnConfirm,
    onExportExcelConfirm,
    onShowItemDetail,
    editItem,
    setEditItem,
    itemToDelete,
    setItemToDelete,
    columnToDelete,
    setColumnToDelete,
    showAddColumn,
    setShowAddColumn,
    onHideItemDetail,
    onUpdateValidation,
    onSaveItem,
    onSaveItemAction,
    onDeleteItemInit,
    onDeleteItemConfirm,
    onAddColumnSubmit,
    snackNotification,
    handleMoveItem,
    handleRemoveItems,
    handleRemoveSubItems,
    onHideToolbar,
    handleAction,
    showMessageResult,
  } = useSheetTableHook({
    sheetMeta,
    onExportExcel,
    options,
    sheet,
    view,
    sheetItemList,
    onUpdateItem,
    onUpdateItems,
    onDoAction,
    addValueToItems,
    removeValueInItems,
    addValueToItem,
    removeValueInItem,
    onDeleteItem,
    onMoveItems,
    onRemoveItems,
    onRemoveSubItems,
    subSelectItems,
    setSubSelectItems,
    tools,
    selectedItems,
    setSelectedItems,
    selectedItemsData,
    setSelectedItemsData,
  });

  const eventHandler = useSheetViewEvent();

  useEffect(() => {
    if (sheetId) {
      eventHandler.initialize({
        domain: getDomain(),
        sheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [sheetId]);

  useEffect(() => {
    if (sheetId) {
      fetchSheetItems();
    }
  }, [sheetId, paging]);

  useEffect(() => {
    if (sheetId && activeFilters) {
      if (paging?.pageNumber === 1) {
        fetchSheetItems();
      } else {
        setPaging((prev) => ({
          ...prev,
          pageNumber: 1,
        }));
      }
    }
  }, [activeFilters]);

  useEffect(() => {
    let itemDetailId = itemId ?? editItem?._id;
    if (itemDetailId && sheetItemList) {
      let foundItems = sheetItemList.filter((x) => x._id === itemDetailId);
      if (foundItems.length > 0) {
        setEditItem(foundItems[0]);
      }
    }
  }, [itemId, sheetItemList]);

  const selectedRows = useMemo(() => {
    return new Set(selectedItems);
  }, [selectedItems]);

  const availableColumns = useMemo(() => {
    let columns = userViewableColumns;

    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: item.hidden ?? true};
      });
      columns = [...listColumnsByView, ...listColumns];
    }

    return columns?.filter((column) => !column?.deleted && !column?.hidden);
  }, [userViewableColumns, view.columns]);

  const gridColumns = useMemo(() => {
    let sheetColumns = availableColumns;
    const visibleTitleColumn = availableColumns.find(
      (column) =>
        !column?.deleted &&
        !column?.hidden &&
        (column?.field === 'title' || column?.is_title === true)
    );

    if (!visibleTitleColumn) {
      const actionColumn = {
        name: '',
        type: 'action',
        field: 'action',
        styles: {
          width: '40px',
        },
      };

      sheetColumns = [actionColumn, ...sheetColumns];
    }

    const mappingGridColumns = sheetColumns.map((column, index) => {
      const columnField = column?.field;
      const columnWidth = get(column, ['styles', 'width']);
      const parseWidth = columnWidth ? Number(columnWidth.replace(/\D/g, '')) : undefined;
      const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
      const isPeopleField = columnField.includes('people');

      const gridColumnConfig = {
        key: columnField,
        name: column?.name,
        frozen: index === 0, // frozen first column
        resizable: columnField !== 'action' && options?.resizableColumns,
        width: parseWidth,
        editable: columnField !== 'title',
        minWidth: index === 1 ? 210 : 40,
        text: column?.name,
        column: cloneDeep(column),
        renderHeaderCell: ({column}) => {
          const columnKey = column?.key;
          if (columnKey === 'action') {
            return null;
          }

          return (
            <HeaderFormatter
              column={column}
              editable={hasSheetPermission(sheet, user, 'board.edit') && !options?.hideColumnEditor}
              onEdit={() => onEditColumn(column)}
              onToggleColumnPermitted={() => onToggleColumnPermitted(column)}
              onOpenPermittedEditColumn={() => onOpenPermittedEditColumn(column)}
              onSaveColumn={onSaveColumn}
              onDelete={() => onDeleteColumnClick(column)}
              onEditFormula={() => onEditFormula(column?.column)}
              onUpdateValidation={onUpdateValidation}
              onOpenUsersCanViewColumnDialog={onOpenUsersCanViewColumnDialog}
            />
          );
        },
        renderCell: (cell) => {
          const value = get(cell, `row.${columnField}`);
          if (!hasViewColumnPermission) {
            return <div className='bg-gray-300 h-100' onClick={(e) => e.stopPropagation()}></div>;
          }

          return (
            <ItemField
              readOnly={column?.type === 'title'}
              column={column}
              item={cell.row}
              value={value}
              sheet={sheet}
              showDetail={onShowItemDetail}
              onChange={onSaveItem}
              onAction={onSaveItemAction}
              onEditFormula={onEditFormula}
              containerClassName={isPeopleField ? 'grid-cell-wrapper-people-field' : ''}
              fieldEditable={
                hasEditItemColumnPermission(user, column, cell?.row) &&
                cell?.row?.userHasEditItemPermission
              }
            />
          );
        },
        renderEditCell: (cell) => {
          const value = get(cell, `row.${columnField}`);
          if (!hasViewColumnPermission) {
            return <div className='bg-gray-300 h-100' onClick={(e) => e.stopPropagation()}></div>;
          }

          return (
            <ItemField
              column={column}
              item={cell.row}
              value={value}
              sheet={sheet}
              onEditFormula={onEditFormula}
              showDetail={onShowItemDetail}
              onChange={onSaveItem}
              onAction={onSaveItemAction}
              containerClassName={isPeopleField ? 'grid-cell-wrapper-people-field' : ''}
              fieldEditable={
                hasEditItemColumnPermission(user, column, cell?.row) &&
                cell?.row?.userHasEditItemPermission
              }
            />
          );
        },
      };

      return gridColumnConfig;
    });

    if (configurable && !options?.hideColumnEditor) {
      mappingGridColumns.push({
        key: '+',
        name: '+',
        resizable: false,
        frozen: false,
        width: 50,
        text: '',
        renderHeaderCell: () => (
          <SheetColumnAddHeader
            sheetColumns={sheetColumns}
            onAdd={onAddColumnSubmit}
            linkableSheets={linkableSheets}
          />
        ),
        editable: false,
      });
    }

    return editable ? [SelectColumn, ...mappingGridColumns] : mappingGridColumns;
  }, [
    availableColumns,
    configurable,
    options?.hideColumnEditor,
    options?.resizableColumns,
    editable,
    user,
    sheet,
    onSaveColumn,
    onUpdateValidation,
    onOpenUsersCanViewColumnDialog,
    onEditColumn,
    onToggleColumnPermitted,
    onOpenPermittedEditColumn,
    onDeleteColumnClick,
    onEditFormula,
    onShowItemDetail,
    onSaveItem,
    onSaveItemAction,
    onAddColumnSubmit,
    linkableSheets,
  ]);

  const onColumnResize = debounce((index, width) => {
    const editedColumn = gridColumns?.[index];
    const roundWidth = Math.floor(width);
    sheetMeta.updateColumn({
      ...editedColumn?.column,
      styles: {
        ...editedColumn?.column?.styles,
        width: `${roundWidth}px`,
      },
    });
  }, 500);

  const onSelectedRowsChange = (selectedRows) => {
    const selectedItems = Array.from(selectedRows);
    const selectedItemsData = sheetItemList.filter((item) => selectedItems.includes(item._id));
    setSelectedItems(selectedItems);
    setSelectedItemsData(selectedItemsData);
  };

  const onGridFill = ({columnKey, sourceRow, targetRow}) => {
    const columnInfo = gridColumns.find((column) => column.key === columnKey);

    if (columnInfo) {
      // Verify if the user has permission for both source and target rows.
      const hasEditSourceRowPermission =
        hasEditItemColumnPermission(user, columnInfo, sourceRow) &&
        sourceRow?.userHasEditItemPermission;

      const hasEditTargetRowPermission =
        hasEditItemColumnPermission(user, columnInfo, targetRow) &&
        targetRow?.userHasEditItemPermission;

      if (hasEditSourceRowPermission && hasEditTargetRowPermission) {
        const sourceFieldData = sourceRow[columnKey];
        const targetFieldData = targetRow[columnKey];
        onSaveItem(targetRow, columnKey, targetFieldData, sourceFieldData, showMessageResult);
      } else {
        snackNotification.showError(t('sheet_copy_to_column_false'));
      }
    }
  };

  const handleShowModalAddNewItem = () => {
    setShowModalAddNewItem(true);
  };

  const handleAddNewItem = (newItem) => {
    if (newItem?.title?.value === '') {
      snackNotification.showError(t('sheet_table_title_required'));
    } else if (!addingItem.current) {
      addingItem.current = true;
      addItemFull({...newItem, group: newItem?.group?.value ?? 'default'}, user, (result) => {
        if (result.success) {
          let newItem = result.data;
          setNewItems([newItem._id]);
          setShowModalAddNewItem(false);
        } else {
          if (result.code === validationErrorCode) {
            snackNotification.showError(
              result?.message
                ? getValidationErrorMessage(result?.message)
                : t('common_update_error')
            );
          } else {
            snackNotification.showError(
              result?.message ? t(result?.message) : t('common_update_error')
            );
          }
        }
        addingItem.current = false;
      });
    }
  };

  const paginationProps = {
    ...paging,
    custom: true,
    sizePerPageList: [
      {text: '10', value: 10},
      {text: '20', value: 20},
    ],
    pageSize: 10,
    pageNumber: 1,
    paginationSize: 3,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total'>
        Hiển thị {from} đến {to}/<b>{size}</b> Kết quả
      </span>
    ),
    totalSize: totalItems,
    sizePerPage: paging.pageSize,
    page: paging.pageNumber,
    dataSize: totalItems,
    pageStartIndex: 1,
    onPageChange: (page) => {
      setPaging((prev) => ({
        ...prev,
        pageNumber: page,
        page: page,
      }));
    },
  };

  const isReadOnly = () => {
    return !editable;
  };

  const showAddButton = () => {
    return editable;
  };

  return (
    <div className='d-flex flex-column h-100'>
      {options?.showToolbar && renderToolbar && (
        <>
          {renderToolbar({
            sheetId: sheetId,
            sheet: sheet,
            view: view,
            key: sheet?._id + view.id,
            onSetting: () => onEditView(view),
            onAddNew: handleShowModalAddNewItem,
            onFilterApply: applyFilter,
            onFilterReset: resetFilters,
            activeFilters: activeFilters,
            filterActive: filterActive,
            saveFilter: onSaveFilter,
            onSearch: applySearch,
            mode: 'edit',
            onExportExcel: onExportExcelConfirm,
            options: options?.userActions,
            addItemsAndSubItems: addItemsAndSubItems,
            showAddButton: showAddButton(),
            isLoadingAddItem: isLoadingAddItem,
          })}
        </>
      )}

      <CardBody className='overflow-hidden'>
        <GridView
          className='h-100'
          columns={gridColumns}
          rows={sheetItemList}
          selectedRows={selectedRows}
          noDataText={t('sheet_table_default_no_data_text')}
          onColumnResize={onColumnResize}
          onFill={onGridFill}
          onCellClick={(args, event) => {
            event?.preventGridDefault();
            args?.selectCell(true);
          }}
          onSelectedRowsChange={onSelectedRowsChange}
        />
      </CardBody>
      <div className='d-flex justify-content-between align-items-center flex-wrap position-sticky bottom-0 start-0 bg-body'>
        <PaginationLinks paginationProps={paginationProps} />
        <div></div>
        <PaginationToolbar isLoading={false} paginationProps={paginationProps} />
      </div>
      {editItem && (
        <ItemDetailView
          open={editItem !== null}
          item={editItem}
          sheet={sheet}
          permissions={userSheetPermissions}
          view={view}
          onHide={() => onHideItemDetail()}
          onDelete={onDeleteItemInit}
          onUpdateItem={onUpdateItem}
          onSaveItemAction={onSaveItemAction}
          readOnly={isReadOnly()}
        />
      )}
      {editColumn && (
        <SheetEditColumn
          show={editColumn !== null}
          onHide={() => setEditColumn(null)}
          column={editColumn}
          onSubmit={onSaveColumn}
          sheet={sheet}
          linkableSheets={linkableSheets}
        ></SheetEditColumn>
      )}
      <Modal size='md' centered show={showAddColumn} onHide={() => setShowAddColumn(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_column_add_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SheetAddColumn onSubmit={onAddColumnSubmit}></SheetAddColumn>
        </Modal.Body>
      </Modal>
      <Modal
        size='md'
        centered
        show={userEditColumn !== null}
        onHide={() => setUserEditColumn(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('sheet_column_user_edit_title') + ' ' + userEditColumn?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-3'>
          {userEditColumn && (
            <ColumnUserPermittedEdit
              column={userEditColumn}
              sheetColumns={sheet.columns}
              onSubmit={onSaveColumn}
            ></ColumnUserPermittedEdit>
          )}
        </Modal.Body>
      </Modal>
      <ConfigUsersCanViewColumnDialog
        column={currentColumn}
        show={isShowConfigUsersCanViewColumnDialog}
        onHide={() => setIsShowConfigUsersCanViewColumnDialog(false)}
        onSubmit={onSaveColumn}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={itemToDelete !== null}
        onHide={() => setItemToDelete(null)}
        title={t('sheet_item_delete_confirm_title')}
        content={t('sheet_item_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteItemConfirm}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={columnToDelete !== null}
        onHide={() => setColumnToDelete(null)}
        title={t('sheet_column_delete_confirm_title')}
        content={t('sheet_column_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteColumnConfirm}
      />
      {showModalAddNewItem && (
        <ModalAddNewItem
          view={view}
          sheet={sheet}
          sheetItemPermissions={userSheetPermissions}
          currentView={view}
          sheetGroups={sheetGroups}
          show={showModalAddNewItem}
          configurable={configurable}
          onSave={handleAddNewItem}
          onHide={() => setShowModalAddNewItem(false)}
          isLoadingAddItem={isLoadingAddItem}
        />
      )}

      {!isEmpty(subSelectItems) && (
        <ActionBar
          handleRemoveItems={handleRemoveSubItems}
          sheet={sheet}
          view={view}
          selectedItems={subSelectItems}
          onHide={onHideToolbar}
          enableActions={enableActionsSubitems}
        />
      )}
      {!isEmpty(selectedItems) && (
        <ActionBar
          handleMoveItem={handleMoveItem}
          handleRemoveItems={handleRemoveItems}
          handleAction={handleAction}
          sheet={sheet}
          view={view}
          selectedItems={selectedItems}
          selectedItemsData={selectedItemsData}
          onHide={onHideToolbar}
          enableActions={enableActions}
        />
      )}
      <ModalFormulaEditor
        show={editFormulaColumn !== null}
        onHide={() => setEditFormulaColumn(null)}
        onSave={onSaveFormula}
        metaColumns={sheetMeta.getColumnsByView(view)}
        column={editFormulaColumn}
      />
    </div>
  );
}
