import {createSlice} from '@reduxjs/toolkit';

const initialNotificationState = {
  listLoading: false,
  actionsLoading: false,
  notifications: [],
  countNotifications: 0
};

export const callTypes = {
  list: 'list',
  action: 'action',
};


export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialNotificationState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    notificationsFetched: (state, action) => {
        const {data, loadMore} = action.payload;
        state.listLoading = false;
        state.error = null;
        if(loadMore){
          state.notifications = [...state.notifications, ...data?.data]
        }else{
          state.notifications = data?.data ?? []
        }
        state.countNotifications = data?.count ?? 0
      },
  },
});
