export const requiredRoles = [
  {
    name: 'OWNER',
    label: 'Owner',
    permissions: {
      workspace: {
        edit: 'all',
      },
      sheet: {
        create: {
          public: 'all',
          private: 'all',
          share: 'all',
        },
        delete: 'all',
      },
      dashboard: {
        create: 'all',
        delete: 'all',
      },
      doc: {
        create: {
          public: 'all',
          private: 'all',
          share: 'all',
        },
        delete: 'all',
      },
      content: {
        delete: {
          own: 'all',
          all: 'all',
        },
        sheet: 'all',
        doc: 'all',
        template: 'all',
      },
      communication: {
        notification: 'all',
        comment: 'all',
      },
      account: {
        automation: 'all',
      },
    },
  },
  {
    name: 'USER',
    label: 'Người dùng',
    permissions: {
      workspace: {
        edit: '',
      },
      sheet: {
        create: {
          public: '',
          private: '',
          share: '',
        },
        delete: '',
      },
      dashboard: {
        create: '',
        delete: '',
      },
      doc: {
        create: {
          public: '',
          private: '',
          share: '',
        },
        delete: '',
      },
      content: {
        delete: {
          own: 'all',
          all: '',
        },
        sheet: '',
        doc: '',
        template: '',
      },
      communication: {
        notification: '',
        comment: 'all',
      },
      account: {
        automation: '',
      },
    },
  },
];

export const builtinRoles = [
  {
    name: 'ADMIN',
    label: 'Admin',
    permissions: {
      workspace: {
        edit: '',
      },
      sheet: {
        create: {
          public: 'all',
          private: 'all',
          share: 'all',
        },
        delete: 'all',
      },
      dashboard: {
        create: 'all',
        delete: 'all',
      },
      doc: {
        create: {
          public: 'all',
          private: 'all',
          share: 'all',
        },
        delete: 'all',
      },
      content: {
        delete: {
          own: 'all',
          all: 'all',
        },
        sheet: 'all',
        doc: 'all',
        template: 'all',
      },
      communication: {
        notification: 'all',
        comment: 'all',
      },
      account: {
        automation: 'all',
      },
    },
  },
  {
    name: 'MANAGER',
    label: 'Quản lý',
    permissions: {
      workspace: {
        edit: '',
      },
      sheet: {
        create: {
          public: 'all',
          private: 'all',
          share: 'all',
        },
        delete: 'all',
      },
      dashboard: {
        create: 'all',
        delete: 'all',
      },
      doc: {
        create: {
          public: 'all',
          private: 'all',
          share: 'all',
        },
        delete: 'all',
      },
      content: {
        delete: {
          own: 'all',
          all: 'all',
        },
        sheet: 'all',
        doc: 'all',
        template: 'all',
      },
      communication: {
        notification: '',
        comment: 'all',
      },
      account: {
        automation: '',
      },
    },
  },
  {
    name: 'MANAGER-READ',
    label: 'Phó quản lý',
    permissions: {
      workspace: {
        edit: '',
      },
      sheet: {
        create: {
          public: '',
          private: '',
          share: '',
        },
        delete: '',
      },
      dashboard: {
        create: '',
        delete: '',
      },
      doc: {
        create: {
          public: '',
          private: '',
          share: '',
        },
        delete: '',
      },
      content: {
        delete: {
          own: 'all',
          all: 'all',
        },
        sheet: 'all',
        doc: 'all',
        template: 'all',
      },
      communication: {
        notification: '',
        comment: 'all',
      },
      account: {
        automation: '',
      },
    },
  },
];

export const getInitWorkspaceRoles = () => {
  return requiredRoles.reduce(
    (acc, curr) => (
      (acc[curr?.name] = {name: curr?.name, active: true, permissions: curr?.permissions}), acc
    ),
    {}
  );
};
