export const columnDataTypes = [
  {
    label: 'Text',
    value: 'string',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Status',
    value: 'string',
  },
  {
    label: 'Color',
    value: 'string',
  },
  {
    label: 'People',
    value: '',
  },
  {
    label: 'Reference',
    value: 'string',
  },
  {
    label: 'Timeline',
    value: '',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Dependency',
    value: '',
  },
  {
    label: 'Link',
    value: 'string',
  },
  {
    label: 'Formula',
    value: 'string',
  },
];

export const commonValidateOperator = [
  {label: 'validation_required', value: 'required', rule: 'required'},
  {label: 'validation_unique', value: 'unique:', rule: 'unique:{value}'},
  {label: 'validation_min', value: 'min:', rule: 'min:{value}'},
  {label: 'validation_max', value: 'max:', rule: 'max:{value}'},
  {label: 'validation_greater_than', value: 'gt:', rule: 'gt:{value}'},
  {label: 'validation_greater_than_or_equal', value: 'gte:', rule: 'gte:{value}'},
  {label: 'validation_less_than', value: 'lt:', rule: 'lt:{value}'},
  {label: 'validation_less_than_or_equal', value: 'lte:', rule: 'lte:{value}'},
  {label: 'validation_nullable', value: 'nullable:', rule: 'nullable'},
];

export const noValueOperator = ['required','unique:','nullable']

export const validateOperator = {
  date: [
    {
      label: 'validation_is_on_or_before',
      value: 'is_on_or_before',
      rule: 'after_or_equal:{value}|before_or_equal:today',
      type: '',
      negativeValue: true
    },
    {
      label: 'validation_is_on_or_after',
      value: 'is_on_or_after',
      rule: 'after_or_equal:today|before_or_equal:{value}',
      type: '',
      negativeValue: false
    },
    {
      label: 'validation_after',
      value: 'date_after',
      rule: 'after:{value}',
      type: 'date',
      negativeValue: false
    },
    {
      label: 'validation_before_or_equal',
      value: 'before_or_equal',
      rule: 'before_or_equal:{value}',
      type: 'date',
      negativeValue: false
    },
    {
      label: 'validation_before',
      value: 'date_before',
      rule: 'before:{value}',
      type: 'date',
      negativeValue: false
    },
    {
      label: 'validation_after_or_equal',
      value: 'after_or_equal',
      rule: 'after_or_equal:{value}',
      type: 'date',
      negativeValue: false
    },
    {
      label: 'validation_date_equal',
      value: 'date_equal',
      rule: 'date_equals:{value}',
      type: 'date',
      negativeValue: false
    },
  ],
  all: [
    {label: 'validation_required', value: 'required', rule: 'required'},
    {label: 'validation_nullable', value: 'nullable:', rule: 'nullable'},
  ]
};

export const dateValueOption = [
  {value: 'today', label: 'validation_today'},
  {value: 'yesterday', label: 'validation_yesterday'},
  {value: 'tommorow', label: 'validation_tommorow'},
  {value: 'first day of last month', label: 'validation_first_day_of_last_month'},
  {value: 'first day of next month', label: 'validation_first_day_of_next_month'},
  {value: 'first day of last week', label: 'validation_first_day_of_last_week'},
  {value: 'first day of next week', label: 'validation_first_day_of_next_week'},
  {value: 'exactDate', label: 'validation_exact_date'},
];

export const dateUnits = [
  {value: 'days', label: 'validation_days'},
  {value: 'weeks', label: 'validation_months'},
  {value: 'months', label: 'validation_weeks'},
];


export const validationErrorCode = 422
