import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

const SheetDataViewFilterColor = () => {
  const {t} = useTranslate();
  const popover = (
    <Popover>
      <Popover.Body className='p-2'>
        <div className='d-flex flex-column'>
          <div className='btn btn-light p-2 bg-white mb-1'>
            <div className='d-flex justify-content-start align-items-center'>
              <i className='la la-chevron-circle-down' />
              <span>{t('sheet_view_tool_filter_color_select_field')}</span>
            </div>
            <p className='text-start m-0 text-gray-500'>
              {t('sheet_view_tool_filter_color_select_field_description')}
            </p>
          </div>
          <div className='btn btn-light p-2 bg-white'>
            <div className='d-flex justify-content-start align-items-center'>
              <i className='la la-project-diagram' />
              <span>{t('sheet_view_tool_filter_color_condition')}</span>
            </div>
            <p className='text-start m-0 text-gray-500'>{t('sheet_view_tool_filter_color_condition_description')}</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      overlay={popover}
      rootClose
      rootCloseEvent='click'
    >
      <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
        <i className='la la-fill' />
        <span>{t('sheet_view_tool_filter_color')}</span>
      </div>
    </OverlayTrigger>
  );
};

export default SheetDataViewFilterColor;
