/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { } from "react";
import { get, isString } from "lodash";
import { FormattedNumber } from "react-intl";
import './tableChart.scss'

export function PivotTableChart({
  className,
  data = [],
  columns = []
}) {
  const formatValue = (row, column) => {
    const value = get(row, column.field);
    if (['metric', 'pivot'].includes(column.type)) {
      return <FormattedNumber value={get(row, column.field ) || 0}></FormattedNumber>
    } else {
      if(isString(value.label)){
        return value.label;
      }else{
        return ''
      }
    }
  }

  return (
    <div className='table-chart-wrapper w-100 table-borderless rounded'>
      <table className="flex-grow-1 fixed_header w-100 borderless">
        <thead>
          <tr>
            {
              columns.map(column => {
                return <th key={`th-${column.key}`} className={`p-2 text-dark fw-bold text-${['metric', 'pivot'].includes(column.type)?'end': 'start'}`} scope="col">{isString(column.name) ? column.name : null}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {
                    columns.map(column => {
                      return <td key={`${column.key}`}  className={`p-2 text-dark text-${ ['metric', 'pivot'].includes(column.type)?'end': 'start'}`}>{formatValue(row, column )}</td>
                    })
                  }
                </tr>)
            })
          }

        </tbody>
      </table>
    </div>
  );
}
