import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {
  getSheetAction,
  getSheetItemsAction,
  getSheetItemUpdatesAction,
} from '../../stores/sheetActions';
import {cloneDeep} from 'lodash';
import {useSession} from 'core/store/hooks';
import clsx from 'clsx';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';

function ModalAddNewTemplate({show, onHide, title, sheet, selectedItemsData, onCreateTemplate}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {getDomain} = useSession();
  const [sheetItemsByParent, setSheetItemsByParent] = useState([]);
  const [nameTemplate, setNameTemplate] = useState('');
  const [sheetSubItem, setSheetSubItem] = useState(null);

  const items = Object.keys(selectedItemsData).reduce((listId, groupKey) => {
    return listId.concat(selectedItemsData[groupKey]);
  }, []);

  useEffect(() => {
    if (sheet.sub_boards?.[0])
      dispatch(getSheetItemsAction({id: sheet.sub_boards?.[0]})).then((res) => {
        if (res.data.data) {
          const _itemsByParent = {};
          res.data.data?.forEach((item) => {
            if (item.parent_item_id) {
              _itemsByParent[item.parent_item_id] = _itemsByParent[item.parent_item_id]
                ? [..._itemsByParent[item.parent_item_id], item]
                : [item];
            }
          });
          setSheetItemsByParent(_itemsByParent);
        }
      });
    dispatch(getSheetAction({id: sheet.sub_boards?.[0]})).then((result) => {
      if (result?.data?.data) {
        setSheetSubItem(result.data.data);
      }
    });
  }, [sheet.sub_boards]);

  const handleSaveTemplate = async () => {
    const itemsTemplate = cloneDeep(items);
    for (let i = 0; i < itemsTemplate.length; i++) {
      const item = itemsTemplate[i];
      const resultFiles = await dispatch(
        getSheetItemUpdatesAction({itemId: item._id, type: 'file'})
      );
      if (sheetItemsByParent[item._id]) {
        const subItems = sheetItemsByParent[item._id];
        for (let idxSubItem = 0; idxSubItem < subItems.length; idxSubItem++) {
          const subItem = subItems[idxSubItem];
          const resultFilesSubitem = await dispatch(
            getSheetItemUpdatesAction({itemId: subItem._id, type: 'file'})
          );
          subItems[idxSubItem].extras = resultFilesSubitem;
        }
        item.subItems = subItems;
        item.extras = resultFiles;
      }
    }

    const dataCreate = {
      name: nameTemplate || '',
      type: 'item',
      sheetId: sheet._id,
      dmn: getDomain(),
      template: {
        items: itemsTemplate,
      },
      meta: {columns: sheet.columns},
    };
    if (sheetSubItem?.columns) {
      dataCreate.meta.subItemColumns = sheetSubItem.columns;
    }
    onCreateTemplate(dataCreate, () => {
      snackNotification.showSuccess(t('common_add_success'));
    });
  };

  const handleChangeName = (e, id) => {
    const value = e.target.value;
    setNameTemplate(value);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className='fs-5 fw-bold'>{title || ''}</div>
        <div className={clsx('rounded bg-light p-2 mt-3')} key={items._id}>
          <div className='d-flex align-items-center border rounded p-2 cursor-pointer bg-body'>
            <input
              className='w-100 border-0'
              type='text'
              placeholder={t('sheet_name_template_input')}
              value={nameTemplate}
              onChange={(e) => handleChangeName(e)}
            />
          </div>
        </div>
        <Modal.Footer className='p-0 mt-4 border-0'>
          <button
            type='button'
            className='btn btn-light btn-elevate btn-secondary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
            onClick={onHide}
          >
            {t('common_cancel')}
          </button>
          <button
            type='button'
            className='btn btn-primary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-110px'
            onClick={handleSaveTemplate}
          >
            {t('sheet_save_template')}
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddNewTemplate;
