/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react';
import {Badge, Dropdown} from 'react-bootstrap';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {searchPeopleAction} from 'app/modules/work/sheet/stores/sheetActions';
import {effectiveValue, generateNameAvatar} from 'app/modules/work/helpers';
import {debounce, isArray} from 'lodash';
import {useSession} from 'core/store/core/hooks';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import clsx from 'clsx';
import './peopleColumn.scss';

export function PeopleColumn({cell, row, rowIndex, field, column, onChange, fieldEditable = true}) {
  const currentPeople = effectiveValue(cell);
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const {user} = useSession();
  const search = (text) => {
    return dispatch(searchPeopleAction({filter: {q: text}, page: 1, pageSize: 10}));
  };

  const [peopleOptions, setPeopleOptions] = useState([]);
  const [expandedOnce, setExpandedOnce] = useState(false);
  const [peopleOptionIds, setPeopleOptionIds] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const itemPeopleRef = useRef();
  const isShowTitle = true;

  const onToggleDropdown = () => {
    if (fieldEditable) {
      setShowDropdown(!showDropdown);
    }
  };

  useEffect(() => {
    if (expandedOnce) {
      searchPeople('');
    }
  }, [expandedOnce]);

  const searchPeople = (e) => {
    const value = e?.target?.value;
    search(value).then((people) => {
      setPeopleOptions(people);
    });
  };

  const selectPerson = (person) => {
    const maxPeopleAllowed = column?.settings?.max_people_allowed;

    const countCurrentPeople = currentPeople?.length || 0;
    if (maxPeopleAllowed > 1 && countCurrentPeople >= maxPeopleAllowed) {
      snackNotification.showWarning(t('sheet_column_people_warning_max_people'));
      return false;
    }

    const newPeople = [];
    const newPeopleIds = [];

    if (currentPeople && isArray(currentPeople) && (maxPeopleAllowed > 1 || !maxPeopleAllowed)) {
      currentPeople.forEach((people) => {
        if (people._id != person._id) {
          newPeople.push(people);
          newPeopleIds.push(people._id);
        }
      });
    }

    newPeople.push({name: person.name, id: person._id, _id: person._id});
    setPeopleOptionIds(newPeopleIds);
    onChange && onChange(row, field, cell, newPeople);

    return true;
  };

  const removePerson = (person) => {
    const newPeople = [];
    const newPeopleIds = [];
    if (currentPeople) {
      currentPeople.forEach((people) => {
        if (people._id != person._id) {
          newPeople.push(people);
          newPeopleIds.push(people._id);
        }
      });
    }
    setPeopleOptionIds(newPeopleIds);
    onChange && onChange(row, field, cell, newPeople);
  };

  const avatar = (person) => {
    let personItem = {};
    if (person._id === user._id) {
      personItem = user;
    } else {
      personItem = objectUser[person._id] || {};
    }
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={person?.name}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          <span className='position-absolute'>{generateNameAvatar(person?.name)}</span>
          {personItem?.avatar && (
            <img
              src={personItem?.avatar}
              className='w-100 h-100 rounded-circle position-absolute'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          )}
        </span>
      </div>
    );
  };

  const displayPerson = (person, isShowComma, isShowTitle) => {
    let personItem = {};
    if (person._id === user._id) {
      personItem = user;
    } else {
      personItem = objectUser[person._id] || {};
    }
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={isShowTitle ? person?.name : null}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          <span className='position-absolute'>{generateNameAvatar(person?.name)}</span>
          {personItem?.avatar && (
            <img
              src={personItem?.avatar}
              className='w-100 h-100 rounded-circle'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          )}
        </span>
      </div>
    );
  };

  const displayEmpty = () => {
    if (!column?.display_mode || column.display_mode === 'avatar') {
      return <div className='symbol symbol-20px symbol-circle symbol-light border-0'></div>;
    } else {
      return (
        <div className='k'>
          <span className='symbol-label  text-uppercase'></span>
        </div>
      );
    }
  };

  useEffect(() => {
    let newPeopleIds = [];
    if (currentPeople && isArray(currentPeople)) {
      newPeopleIds = currentPeople.map((people) => {
        return people._id;
      });
    }
    setPeopleOptionIds(newPeopleIds);
  }, [currentPeople]);

  return (
    <>
      <Dropdown
        show={showDropdown}
        onToggle={() => {
          onToggleDropdown( );
          setExpandedOnce(true);
        }}
      >
        <Dropdown.Toggle variant='none' size='xs' className='w-100 border-0'>
          {itemPeopleRef.current?.scrollWidth === itemPeopleRef.current?.offsetWidth ? (
            <div
              className={`symbol-group symbol-hover user-select-none d-flex flex-nowrap justify-content-center ms-0 
              ${clsx({'people-name-limit-text-1': column?.display_mode === 'full'})}
              `}
              ref={itemPeopleRef}
            >
              {isArray(currentPeople) && (
                <>
                  {!column?.display_mode || column?.display_mode === 'avatar' ? (
                    currentPeople?.map((member, index) => {
                      const isShowComma = index < currentPeople.length - 1;
                      return (
                        <div key={member._id || index} className='h-20px'>
                          {displayPerson(member, isShowComma, isShowTitle)}
                        </div>
                      );
                    })
                  ) : (
                    <div>{currentPeople?.map((member) => member?.name).join(', ')}</div>
                  )}
                </>
              )}
              {(!currentPeople || currentPeople.length === 0) && displayEmpty()}
            </div>
          ) : (
            <OverlayTrigger
              key='title-item'
              placement='top'
              overlay={
                <Tooltip className='tooltip-inverse tooltip-title-item' id='tooltip-title-item'>
                  {currentPeople?.map((people) => people.name).join(', ')}
                </Tooltip>
              }
            >
              <div
                className={`symbol-group symbol-hover user-select-none d-flex flex-nowrap justify-content-center ms-0 position-relative ${clsx(
                  {'people-name-limit-text-1': column?.display_mode === 'full'}
                )}`}
                ref={itemPeopleRef}
              >
                {isArray(currentPeople) && (
                  <>
                    {!column?.display_mode || column?.display_mode === 'avatar' ? (
                      currentPeople?.map((member, index) => {
                        const isShowComma = index < currentPeople.length - 1;
                        return (
                          <div key={member._id || index} className='h-20px'>
                            {displayPerson(member, isShowComma, isShowTitle)}
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div>{currentPeople?.map((member) => member?.name).join(', ')}</div>
                        <div className='symbol symbol-20px symbol-circle symbol-light border border-dark' />
                      </>
                    )}
                  </>
                )}
                <div className='symbol symbol-20px symbol-circle symbol-light border border-dark position-absolute top-0 end-0'>
                  <span className='symbol-label text-uppercase bg-secondary'>
                    {currentPeople?.length}
                  </span>
                </div>
                {(!currentPeople || currentPeople?.length === 0) && displayEmpty()}
              </div>
            </OverlayTrigger>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className='m-4 w-350px'>
            {currentPeople?.length > 0 && (
              <div className='mb-2'>
                {isArray(currentPeople) &&
                  currentPeople?.map((member, index) => {
                    return (
                      <Badge
                        pill
                        key={member._id || index}
                        bg='light'
                        className='m-1 p-1 align-items-center align-middle'
                      >
                        {avatar(member)}
                        <span className='ms-1 text-dark h-100'>{member.name}</span>
                        {fieldEditable && (
                          <button
                            className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                            onClick={() => removePerson(member)}
                          >
                            <i className='las la-times text-muted'></i>
                          </button>
                        )}
                      </Badge>
                    );
                  })}
              </div>
            )}
            {fieldEditable && (
              <>
                <div className='mb-2'>
                  <input
                    placeholder='Tìm kiếm'
                    type='text'
                    className='form-control form-control-sm'
                    onChange={debounce(searchPeople, 500)}
                  ></input>
                </div>
                <hr className='border-start-1 mt-1' />
                {peopleOptions.map((person) => {
                  let personItem = {};
                  if (person._id === user._id) {
                    personItem = user;
                  } else {
                    personItem = objectUser[person._id] || {};
                  }
                  return (
                    <Dropdown.Item
                      key={person._id}
                      size='xs'
                      className='dropdown-item-xs d-flex align-items-center'
                      onClick={(e) => {
                        selectPerson(person) || e.preventDefault();
                      }}
                      disabled={peopleOptionIds.includes(person._id)}
                    >
                      <div className='position-relative w-20px h-15px'>
                        <i className='la la-user-circle me-2 fs-5 position-absolute'></i>
                        {personItem?.avatar && (
                          <img
                            src={personItem?.avatar}
                            className='w-15px h-15px rounded-circle me-2 position-absolute'
                            onError={(e) => {
                              e.target.style.display = 'none';
                            }}
                            alt=''
                          />
                        )}
                      </div>
                      {person.name}
                    </Dropdown.Item>
                  );
                })}
              </>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
