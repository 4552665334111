import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {searchPeopleAction, searchTeamsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {cloneDeep, debounce, isArray, upperFirst} from 'lodash';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {generateNameAvatar} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {useTranslate} from 'core/i18n/i18nProvider';

const SelectUsers = ({defaultUsers, onChange}) => {
  const dispatch = useDispatch();

  const [currentUsers, setCurrentUsers] = useState(defaultUsers);
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const [userOptions, setUserOptions] = useState([]);
  const {t} = useTranslate();
  const currentUserIds = currentUsers?.map((user) => user.id) || [];

  useEffect(() => {
    searchUsers();
  }, []);

  const search = async (text) => {
    const people = await dispatch(searchPeopleAction({filter: {q: text}, page: 1, pageSize: 10}));
    const teams = await dispatch(searchTeamsAction({filter: {q: text}, page: 1, pageSize: 10}));
    const teamOption = teams.map((team) => {
      return {...team, type: 'team'};
    });
    const newPeopleOptions = [...people, ...teamOption];
    setUserOptions(newPeopleOptions);
  };

  const searchUsers = (e) => {
    const value = e?.target?.value || '';
    search(value)
  };

  const selectUser = (user) => {
    if (!currentUserIds.includes(user?.id)) {
      const _currentUsers = cloneDeep(currentUsers);
      _currentUsers.push({name: user.name, id: user?._id, _id: user?._id, type: user?.type ?? 'user'});

      setCurrentUsers(_currentUsers);
      onChange(_currentUsers);
    }
  };

  const removeUser = (user) => {
    const _currentUsers = currentUsers.filter((currentUser) => currentUser?.id !== user?.id);

    setCurrentUsers(_currentUsers);
    onChange(_currentUsers);
  };

  const avatar = (user) => {
    let userItem = {};
    if (user?._id === user?._id) {
      userItem = user;
    } else {
      userItem = objectUser[user?._id] || {};
    }

    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip className='tooltip-inverse'>{upperFirst(user?.name)}</Tooltip>}
      >
        <div key={user?._id} className='symbol symbol-30px symbol-circle'>
          <span className='symbol-label text-uppercase bg-secondary'>
            {userItem?.avatar ? (
              <img src={userItem?.avatar} className='w-100 h-100 rounded-circle' alt='' />
            ) : (
              generateNameAvatar(user?.name)
            )}
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <div className=''>
        <div className='form-group row'>
          <div className=''>
            {currentUsers?.length > 0 && (
              <div className='mb-2'>
                {isArray(currentUsers) &&
                  currentUsers?.map((member, index) => {
                    return (
                      <Badge
                        pill
                        key={member?._id || index}
                        bg='light'
                        className='m-1 p-1 align-items-center align-middle'
                      >
                        {avatar(member)}
                        <span className='ms-1 text-dark h-100'>{member.name}</span>
                        <button
                          className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                          onClick={() => removeUser(member)}
                        >
                          <i className='las la-times text-muted'></i>
                        </button>
                      </Badge>
                    );
                  })}
              </div>
            )}
            <div className='mb-2'>
              <input
                placeholder={t('sheet_view_search_users')}
                type='text'
                className='form-control form-control-sm'
                onChange={debounce(searchUsers, 500)}
              ></input>
            </div>
            <hr className='border-start-1 mt-1' />
            <div className='h-250px overflow-auto'>
              {userOptions.map((user) => {
                let userItem = {};
                if (user?._id === user?._id) {
                  userItem = user;
                } else {
                  userItem = objectUser[user?._id] || {};
                }
                return (
                  <div
                    className={`dropdown-item-xs dropdown-item ${clsx({
                      'opacity-50': currentUserIds.includes(user?._id),
                      'cursor-pointer': !currentUserIds.includes(user?._id),
                    })}`}
                    key={user?._id}
                    onClick={() => {
                      if (!currentUserIds.includes(user?._id)) {
                        selectUser(user);
                      }
                    }}
                  >
                    {userItem?.avatar ? (
                      <img
                        src={userItem?.avatar}
                        className='w-15px h-15px rounded-circle me-2'
                        alt=''
                      />
                    ) : (
                      <>
                        {user?.type && user?.type === 'team' ? <i className='la la-user-friends me-2 fs-5'/> : <i className='la la-user-circle me-2 fs-5'/>}
                      </>
                    )}
                    {user?.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectUsers;
