import {createSlice} from '@reduxjs/toolkit';

const initialWorkState = {
  listLoading: false,
  actionsLoading: false,
  projects: [],
  countProject: 0,
  error: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};


export const workFolderSlice = createSlice({
  name: 'work.folder',
  initialState: initialWorkState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    
    projectsFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.projects = data.data ? data.data : [];
      state.countProject = data.objects?.total || data.total || 0;
    },
  },
});
