import {useAppDispatch, useAppSelector} from 'core/store/hooks';
import {updateUserStateAction} from 'core/store/userState/userStateActions';
import {cloneDeep, isArray} from 'lodash';
import {useMemo} from 'react';
import {shallowEqual} from 'react-intl/src/utils';

export const ObjectTypes = {page: 'page', workspace: 'workspace', board: 'board', app: 'app'};
export const UiActions = {
  workspace: {
    selectBoard: 'selectBoard',
    toggleBoard: 'toggleBoard',
  },
  app: {
    selectWorkspace: 'selectWorkspace',
  },
  board: {
    selectTabSheet: 'selectTabSheet',
    toggleGroup: 'toggleGroup',
  },
};

function useUserStateHook() {
  const dispatch = useAppDispatch();
  const {userState} = useAppSelector((state) => state.userState, shallowEqual);
  const {currentWorkspace} = useAppSelector((state) => state.work, shallowEqual);

  const handleUserAction = (type, action, data) => {
    if (type === ObjectTypes.board) {
      if (action === UiActions.board.selectTabSheet) { 
        const {view, sheetId} = data;
        const listBoard = cloneDeep(userState.boards);
        const board = listBoard[sheetId] || {};
        board.view = view;
        const params = {key: `boards.${sheetId}`, value: board};
        dispatch(updateUserStateAction(params));
      }
      if (action === UiActions.board.toggleGroup) {
        const {groupId, sheetId, viewId} = data;
        let sheetStates = cloneDeep(userState.sheets) || {};
        if (isArray(sheetStates)) {
          sheetStates = {}
        }
        const sheetState = sheetStates[sheetId] || {id: sheetId, views: {}};
        const sheetViewState = cloneDeep(sheetState.views?.[viewId]) || {id: viewId, groups: {}};

        const groupState = sheetViewState.groups[groupId] || {id: groupId, expanded: true};
        groupState['expanded'] = !groupState['expanded'];

        sheetViewState.groups[groupId] = groupState;
        sheetState.views[viewId] = sheetViewState;
        const params = {key: `sheets.${sheetId}`, value: sheetState};
        dispatch(updateUserStateAction(params));
      }
    }
    if (type === ObjectTypes.workspace) {
      if (action === UiActions.workspace.toggleBoard) {
        const {item, isOpen} = data;
        const folderStates = userState.folders ? cloneDeep(userState.folders) : {};
        const folderState = folderStates[item._id] || {id: item._id};
        folderState.expanded = isOpen;
        folderStates[item._id] = folderState;
        const params = {key: `folders.${item._id}`, value: folderState};
        dispatch(updateUserStateAction(params));
      }
      if (action === UiActions.workspace.selectBoard) {
        const listWorkspace = userState.workspaces ? cloneDeep(userState.workspaces) : {};
        const workspace = listWorkspace[currentWorkspace._id] || {};
        if (workspace) {
          workspace.board = data;
        }
        const params = {key: `workspaces.${currentWorkspace._id}`, value: workspace};
        dispatch(updateUserStateAction(params));
      }
    }
    if (type === ObjectTypes.app) {
      if (action === UiActions.app.selectWorkspace) {
        const appState = {...userState.app, workspace: data};
        const params = {key: `app`, value: appState};
        dispatch(updateUserStateAction(params));
      }
    }
  };

  const expandedFolders = useMemo(() => {
    const listId = [];
    if (userState?.folders) {
      Object.entries(userState?.folders).forEach(([key, value]) => {
        if (value.expanded) {
          listId.push(key);
        }
      });
    }
    return listId;
  }, [userState.folders]);
  

  const groupStateExpanded = (sheetId, viewId, groupId) => {
    if (userState.sheets?.[sheetId]?.views?.[viewId]?.groups?.[groupId]?.expanded) {
      return true;
    } else if (userState.sheets?.[sheetId]?.views?.[viewId]?.groups?.[groupId]) {
      return false;
    } else {
      return true;
    }
  };

  const workspaceSelect = useMemo(() => {
    return userState?.app?.workspace;
  }, [userState?.app?.workspace]);

  const isStartApp = useMemo(() => {
    return userState?.isStartApp
  },[userState?.isStartApp])

  return {
    handleUserAction,
    userState,
    expandedFolders,
    groupStateExpanded,
    workspaceSelect,
    isStartApp,
  };
}

export default useUserStateHook;
