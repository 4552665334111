import {generateNameAvatar} from 'app/modules/work/helpers';
import {searchPeopleAction} from 'app/modules/work/sheet/stores/sheetActions';
import {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';

export default function SheetPeopleFilter({sheet, filter, filterValue, column, applyFilter}) {
  
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const [values, setValues] = useState([]);
  const [memberIds, setMemberIds] = useState([]);

  useEffect(() => {
    if(filterValue && filterValue?.value && filterValue?.value?.in){
      setValues(filterValue?.value?.in)
    }else{
      setValues([])
    }
  },[filterValue])

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);

    applyFilter && applyFilter(filter, {in: newValues});
  };

  const dispatch = useDispatch();
  const [peopleOptions, setPeopleOptions] = useState([]);

  const searchPeople = (searchText) => {
    const param = {
      filter: {
        q: searchText,
      },
      paging: {
        pageNumber: 1,
        pageSize: 1000,
      },
      sort: {ids: memberIds},
    };
    dispatch(searchPeopleAction(param)).then((people) => {
      setPeopleOptions(people);
    });
  };

  useEffect(() => {
    searchPeople();
  }, [memberIds]);

  useEffect(() => {
    const listMemberId = sheet.members?.map((member) => member.id);
    setMemberIds(listMemberId);
  }, [sheet.members]);

  const avatar = (person) => {
    let personItem = objectUser[person._id] || {};
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 bg-info text-uppercase me-2'
        data-toggle='tooltip'
        title={person?.name}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-white fs-8'>
          {personItem?.avatar ? (
            <img src={personItem?.avatar} className='w-100 h-100 rounded-circle' alt='' />
          ) : (
            generateNameAvatar(person?.name)
          )}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex flex-column align-items-stretch '>
        <ToggleButton
          id={`toggle-people-current-${column.field}`}
          key={`toggle-people-current-${column.field}`}
          type='checkbox'
          size='sm'
          variant='filter'
          className={`mb-2  text-nowrap ${
            filterValue?.value?.in?.includes('current') ? 'bg-light-primary' : 'bg-secondary'
          }`}
          checked={filterValue?.value.in?.includes('current')}
          value='1'
          onChange={(e) => onToggleValue('current')}
        >
          Tôi&nbsp;
        </ToggleButton>
        {peopleOptions?.map((value) => (
          <ToggleButton
            id={`toggle-people-${column.field}-${value._id}`}
            key={`toggle-people-${column.field}-${value._id}`}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 d-flex align-items-center text-start text-nowrap ${
              filterValue?.value?.in?.includes(value._id) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            checked={filterValue?.value.in?.includes(value._id)}
            value='1'
            onChange={(e) => onToggleValue(value._id)}
          >
            {avatar(value)} {value?.name}&nbsp;
          </ToggleButton>
        ))}
      </div>
    </>
  );
}
