import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {format} from 'date-fns';
import moment from 'moment';
import {useMemo} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {shallowEqual, useSelector} from 'react-redux';

function LastUpdatedDateField({item, column, containerClass}) {
  const {allUsers} = useSelector(
    (state) => ({allUsers: state.core.allUsers, infoUser: state.auth.info}),
    shallowEqual
  );
  const displayValue = useMemo(() => {
    const lastUpdatedData = item?.last_updated_data;
    const dateFormatSetting = column?.settings?.date_format ?? 'date-time';
    if (!lastUpdatedData) {
      if (item?.created_data && item?.created_data?.created_at)
        return dateFormatSetting === 'date-time'
          ? format(new Date(item?.created_data?.created_at), 'dd-MM-yyyy, HH:mm')
          : moment(item?.created_data?.created_at).fromNow();
      if (item?.created_at)
        return dateFormatSetting === 'date-time'
          ? format(new Date(item?.created_at), 'dd-MM-yyyy, HH:mm')
          : moment(item?.created_at).fromNow();
      return dateFormatSetting === 'date-time'
        ? format(new Date(), 'dd-MM-yyyy, HH:mm')
        : moment().fromNow();
    }
    const updatedAt = lastUpdatedData
      ? lastUpdatedData?.last_updated_at
      : effectiveValue(item?.updated_at);
    const updatedBy = effectiveValue(item?.updated_by);
    const displaySetting = column?.settings?.display ?? 'date';
    let updatedUser = allUsers?.find((user) => user?._id === updatedBy);
    if (
      lastUpdatedData &&
      lastUpdatedData?.last_updated_user &&
      lastUpdatedData?.last_updated_user?.name
    ) {
      updatedUser = {name: lastUpdatedData?.last_updated_user?.name};
    }
    const updatedDate =
      dateFormatSetting === 'date-time'
        ? format(new Date(updatedAt), 'dd-MM-yyyy, HH:mm')
        : moment(updatedAt).fromNow();
    if (displaySetting === 'person') {
      return updatedUser?.name ?? '';
    } else if (displaySetting === 'date') {
      return updatedDate;
    } else {
      if (updatedUser?.name) return `${updatedUser?.name ?? ''} - ${updatedDate}`;
      return updatedDate;
    }
  }, [item, column]);

  return (
    <div className={clsx('d-flex align-items-center justify-content-start text-truncate', containerClass && containerClass)}>
      <OverlayTrigger
        overlay={
          <Tooltip className='tooltip-inverse tooltip-title-item position-fixed'>
            {displayValue}
          </Tooltip>
        }
      >
        <span className='px-2 text-truncate'>{displayValue}</span>
      </OverlayTrigger>
    </div>
  );
}

export default LastUpdatedDateField;
