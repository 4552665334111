/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      
    </>
  )
}
