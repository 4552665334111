
export const menus: ReadonlyArray<{link: string; icon: string; tooltip: string, secondary: boolean}> = [
  {
    link: 'dashboard',
    icon: '/media/icons/duotune/general/gen025.svg',
    tooltip: 'Projects',
    secondary: true,
  },
  {
    link: 'meeting',
    icon: '/media/icons/duotune/general/gen014.svg',
    tooltip: 'Meeting',
    secondary: true,
  },
  {
    link: 'sheet',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'Sheet',
    secondary: true,
  },
  {
    link: 'work',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'Work',
    secondary: true,
  },
  {
    link: 'admin',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'Admin',
    secondary: true,
  },
]