import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';

function NotificationFilter({
  searchValue,
  setSearchValue,
  onSelectPeople,
  filterPeople,
  filterPeopleOption,
}) {
  const [showFilterPeople, setShowFilterPeople] = useState(false);
  const {t} = useTranslate();

  const handleSelect = (value) => {
    const peopleId = getPeopleId(value)
    onSelectPeople(peopleId)
    setShowFilterPeople(false)
  }

  const getPeopleId = (people) => {
    return people?.id ?? people?._id
  }
  
  return (
    <div className='d-flex gap-3 w-100 my-3'>
      <input
        placeholder={t('notification.search')}
        type='text'
        className='form-control'
        style={{width: '60%'}}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div style={{width: '40%'}}>
        <Dropdown
          autoClose
          onToggle={() => setShowFilterPeople(!showFilterPeople)}
          show={showFilterPeople}
        >
          <Dropdown.Toggle id='dropdown-basic' as={DropdownCustomToggle}>
            <div className='d-flex gap-2 align-items-center'>
              <i className='far fa-user-circle' />
              {t('notification.filter_by_person')}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className='w-100 overflow-auto p-3' style={{maxHeight: '300px'}}>
            {filterPeopleOption &&
              filterPeopleOption.map((item, index) => (
                <div
                  key={index}
                  className={`btn ${
                    filterPeople.includes(getPeopleId(item)) ? 'btn-primary' : 'btn-light'
                  } btn-hover-light-primary btn-icon w-100 min-h-40px mt-2`}
                  onClick={() => handleSelect(item)}
                >
                  {item?.name}
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

const DropdownCustomToggle = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className='btn btn-light btn-hover-light-primary btn-icon w-100'
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
});

export default NotificationFilter;
