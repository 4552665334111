import {generateNameAvatar} from 'app/modules/work/helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';
import {shallowEqual, useSelector} from 'react-redux';
import './sheetApprovalFilter.scss';
import {APPROVAL_STATUS} from '../../field/approval/contants';

export default function SheetApprovalFilter({sheet, filter, filterValue, column, applyFilter}) {
  const {t} = useTranslate();
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const [valuesPeople, setValuesPeople] = useState([]);
  const [valuesStatus, setValuesStatus] = useState([]);
  const [valuesStep, setValuesStep] = useState([]);
  const statusApprovals = [
    {
      value: APPROVAL_STATUS.APPROVE,
      label: t('sheet_table_column_approval_approve_action'),
      backgroundColor: '#3699ff',
    },
    {
      value: APPROVAL_STATUS.APPROVED,
      label: t('sheet_table_column_approval_approve'),
      backgroundColor: '#1bc5bd',
    },
    {
      value: APPROVAL_STATUS.PENDING,
      label: t('sheet_table_column_approval_pending'),
      backgroundColor: '#ffa800',
    },
    {
      value: APPROVAL_STATUS.REJECTED,
      label: t('sheet_table_column_approval_reject'),
      backgroundColor: '#f64e60',
    },
  ];

  const [personsFilterApproval, setPersonsFilterApproval] = useState([]);
  const [stepsFilterApproval, setStepsFilterApproval] = useState([]);

  useEffect(() => {
    Object.keys(filterValue?.value || {}).forEach((keyFilter) => {
      if (keyFilter === 'status') {
        const valuesStatus = filterValue?.value[keyFilter]?.in || [];
        setValuesStatus(valuesStatus);
      }
      if (keyFilter === 'people') {
        const valuesPeople = filterValue?.value[keyFilter]?.in || [];
        setValuesPeople(valuesPeople);
      }
    });
  }, [filterValue]);

  useEffect(() => {
    const listAssignee = column?.assignee_role_users?.flatMap((approval) => {
      return approval?.assignees || [];
    });
    const personsFilter = Array.from(new Set(listAssignee.map((item) => item.id))).map((id) => {
      return listAssignee.find((item) => item.id === id);
    });
    setPersonsFilterApproval(personsFilter);
    const listStep = column?.steps?.map((step) => {
      return {...step, _id: step.id};
    });
    setStepsFilterApproval(listStep || []);
  }, [column]);

  const onToggleValuePeople = (value) => {
    let newValues = [...valuesPeople];
    if (valuesPeople.includes(value)) {
      newValues = valuesPeople.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValuesPeople(newValues);
    if (filterValue) {
      applyFilter && applyFilter(filter, {...filterValue.value, people: {in: newValues}});
    } else {
      applyFilter && applyFilter(filter, {people: {in: newValues}});
    }
  };

  const onToggleValueStep = (value) => {
    let newValues = [...valuesStep];
    if (valuesStep.includes(value)) {
      newValues = valuesStep.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValuesStep(newValues);
    if (filterValue) {
      applyFilter && applyFilter(filter, {...filterValue.value, step: {in: newValues}});
    } else {
      applyFilter && applyFilter(filter, {step: {in: newValues}});
    }
  };

  const onToggleValueStatus = (value) => {
    let newValues = [...valuesStatus];
    if (valuesStatus.includes(value)) {
      newValues = valuesStatus.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValuesStatus(newValues);
    if (filterValue) {
      applyFilter && applyFilter(filter, {...filterValue.value, status: {in: newValues}});
    } else {
      applyFilter && applyFilter(filter, {status: {in: newValues}});
    }
  };

  const generateAvatar = (person) => {
    let personItem = objectUser[person._id] || {};
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 bg-info text-uppercase me-2'
        data-toggle='tooltip'
        title={person?.name}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-white fs-8'>
          {personItem?.avatar ? (
            <img src={personItem?.avatar} className='w-100 h-100 rounded-circle' alt='' />
          ) : (
            generateNameAvatar(person?.name)
          )}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex list-filter-approval rounded'>
        <div className='d-flex flex-column align-items-stretch'>
          <div className='mb-2'>{t('sheet_table_column_filter_steps')}</div>
          <div className='overflow-auto max-h-300px'>
            {stepsFilterApproval?.map((value) => (
              <ToggleButton
                id={`toggle-people-${column.field}-${value._id}`}
                key={`toggle-people-${column.field}-${value._id}`}
                type='checkbox'
                size='sm'
                variant='filter'
                className={`mb-2 d-flex align-items-center text-start text-nowrap ${
                  filterValue?.value?.step?.in?.includes(value._id)
                    ? 'bg-light-primary'
                    : 'bg-secondary'
                }`}
                checked={filterValue?.value?.step?.in?.includes(value._id)}
                value='1'
                onChange={(e) => onToggleValueStep(value._id)}
              >
                {value?.name}
              </ToggleButton>
            ))}
          </div>
        </div>
        <div className='d-flex flex-column align-items-stretch '>
          <div className='mb-2'>{t('sheet_table_column_filter_persons')}</div>
          <div className='overflow-auto max-h-300px'>
            <ToggleButton
              id={`toggle-people-${column.field}-${column._id}`}
              key={`toggle-people-${column.field}-${column._id}`}
              type='checkbox'
              size='sm'
              variant='filter'
              className={`mb-2 min-w-100px d-flex align-items-center text-start text-nowrap ${
                filterValue?.value?.people?.in?.includes('current')
                  ? 'bg-light-primary'
                  : 'bg-secondary'
              }`}
              checked={filterValue?.value?.people?.in?.includes('current')}
              value='1'
              onChange={(e) => onToggleValuePeople('current')}
            >
              Tôi&nbsp;
            </ToggleButton>
            {personsFilterApproval?.map((value) => (
              <ToggleButton
                id={`toggle-people-${column.field}-${value._id}`}
                key={`toggle-people-${column.field}-${value._id}`}
                type='checkbox'
                size='sm'
                variant='filter'
                className={`mb-2 d-flex align-items-center text-start text-nowrap ${
                  filterValue?.value?.people?.in?.includes(value._id)
                    ? 'bg-light-primary'
                    : 'bg-secondary'
                }`}
                checked={filterValue?.value?.people?.in?.includes(value._id)}
                value='1'
                onChange={(e) => onToggleValuePeople(value._id)}
              >
                {generateAvatar(value)} {value?.name}&nbsp;
              </ToggleButton>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
