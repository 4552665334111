import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ChatInner} from './ChatInner';


function AssistantDrawer({show, handleClose}) {
  const {t} = useTranslate();

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{minWidth: '600px'}}>
        <Offcanvas.Header className='d-flex flex-column gap-3 border-bottom'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <Offcanvas.Title>{t('assistant.title')}<span className='fw-normal fs-8 ms-2 text-muted'></span></Offcanvas.Title>
            <div className='d-flex gap-5'>
              <div className='cursor-pointer' onClick={handleClose}>
                <i className='ki ki-close icon-xs text-muted' />
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ChatInner isDrawer={true} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AssistantDrawer;
