import {cloneDeep, get, isArray, set} from 'lodash';
import * as requestFromServer from './userStateApi';
import {userStateSlice, callTypes} from './userStateSlice';

const {actions} = userStateSlice;

export const updateUserStateAction = (params) => (dispatch, getState) => {
  const userId = get(getState(), 'auth.info._id');
  const userParams = get(getState(), 'userState.userState');
  const newUserState = {...cloneDeep(userParams), isStartApp: false};
  const firstKey = params.key.split('.')?.[0];
  if (isArray(newUserState[firstKey])) {
    newUserState[firstKey] = {};
    set(newUserState, params.key.split('.'), params.value);
  } else {
    set(newUserState, params.key.split('.'), params.value);
  }
  const dataUpdate = {
    ...params,
    user_id: userId,
  };
  return requestFromServer
    .updateUserState(dataUpdate)
    .then((res) => {
      const data = res.data;
      dispatch(actions.userStateUpdate({data: newUserState}));
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user state";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
};

export const fetchUserStateByUserIdAction = () => (dispatch, getState) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getUserStateByUserId()
    .then((res) => {
      const data = res?.data?.data || {};
      dispatch(actions.userStateUpdate({data: {...data, isStartApp: true}}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get state user";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};
