import { cloneDeep } from "lodash";

export const createMarkup = (html) => {
  return {
    __html: html,
  };
};

export const getTimeProgress = (course) => {
  let now = new Date().getTime();
  if (!course.start_date || !course.end_date || now < course.start_date * 1000) {
    return 0;
  }
  if (now > course.end_date * 1000) {
    return 100;
  }
  let progress =
    ((now - course.start_date * 1000) * 100) / (course.end_date * 1000 - course.start_date * 1000);
  return Math.round(progress);
};

export const transformListToTree = (listData, keyMapping = 'parent') => {
  if (!listData) {
    return [];
  }
  const map = new Map();
  listData.forEach((item) => {
    if (!map.has(item._id)) {
      map.set(item._id, {...cloneDeep(item), children: []});
    }
  });
  listData.forEach((item) => {
    if (item[keyMapping] && map.has(item[keyMapping])) {
      map.get(item[keyMapping]).children.push(map.get(item._id));
    }
  });

  return [...map.values()].filter((item) => !map.has(item[keyMapping]));
};

export const dataArrayToBreadcrumbs = (data) => {
  let newData = [];

  // Helper function to recursively find breadcrumbs
  function buildBreadcrumbs(parentId, breadcrumbs, maxDepth = 2) {
    if (maxDepth == 0 || !parentId) {
      return breadcrumbs.reverse();
    }
    if (parentId === '' || parentId === null || parentId == undefined) {
      return breadcrumbs.reverse();
    }
    const parent = data.find((item) => item._id === parentId);
    if (parent) {
      breadcrumbs.push({id: parent._id, name: parent.name});
      return buildBreadcrumbs(parent.parent, breadcrumbs, maxDepth - 1);
    } else {
      return breadcrumbs.reverse();
    }
  }

  // Iterate through input data
  data.forEach((item) => {
    let newItem = cloneDeep(item);
    newItem.breadcrumbs = buildBreadcrumbs(item.parent, []);
    newData.push(newItem);
  });

  return newData;
};

export function capitalizeFirstLetter(str) {
  if(!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function randomChoice(a, b) {
  return Math.random() < 0.5 ? a : b;
}

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}