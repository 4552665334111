import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {ScrollTop} from './components/ScrollTop';
import {Content} from './components/Content';
import {PageDataProvider} from './core';
import {ActivityDrawer} from './partials/activity-drawer/ActivityDrawer';
import {AsideDefault} from './components/aside/AsideDefault';
import {ThemeModeProvider} from './partials/theme-mode/ThemeModeProvider';
import {MenuComponent} from './assets/ts/components';
import {MasterInit} from './MasterInit';

const NoAsideLayout = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div
          className='d-flex flex-column flex-root'

        >
          {/* begin::Page */}
          <HeaderWrapper />
          <div className='page d-flex flex-row flex-column-fluid'>
            {/* begin::Wrapper */}
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              {/* begin::Content */}
              <div id='kt_content' className='content flex-column-fluid kt-content'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* end::Content */}
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* end:: Drawers */}

        {/* end:: Modals */}
        <ScrollTop />
        <MasterInit />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export {NoAsideLayout};
