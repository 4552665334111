import {useTranslate} from 'core/i18n/i18nProvider';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {Fragment, useRef} from 'react';
import update from 'immutability-helper';
import {useCallback} from 'react';
import {HTML5Backend} from 'react-dnd-html5-backend';
import { capitalize } from 'lodash';

const DND_COMLUMN = 'column';

export default function SheetViewColumnEdit({columns, setColumns}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <SheetViewColumnEditInner columns={columns} setColumns={setColumns} />
    </DndProvider>
  );
}

const SheetViewColumnEditInner = ({columns, setColumns}) => {
  const {t} = useTranslate();

  const moveColumn = useCallback((dragIndex, hoverIndex) => {
    setColumns((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const onDropItem = (item, hidden) => {
    let newColumns = [...columns];
    newColumns.find((element) => element.field === item.field).hidden = hidden;
    setColumns(newColumns);
  };

  const [props, hideColumn] = useDrop(
    () => ({
      accept: DND_COMLUMN,
      hover() {
        props.backgroundColor = '#FFFFFF';
      },
      drop(item) {
        onDropItem(item, true);
      },
    }),
    [onDropItem]
  );

  const [, showColumn] = useDrop(
    () => ({
      accept: DND_COMLUMN,
      hover() {
        props.backgroundColor = '#FFFFFF';
      },
      drop(item) {
        onDropItem(item, false);
      },
    }),
    [onDropItem]
  );

  const getShowColumn = () => {
    return columns.filter((item) => !item.hidden && !item.deleted);
  };

  const getHideColumn = () => {
    return columns.filter((item) => item.hidden && !item?.deleted);
  };

  const getColumnIndex = (field) => {
    return columns.findIndex((item) => item.field === field);
  };

  const isFirstShowColumn = (index) => {
    const firstShowColumnItemIndex = columns.findIndex((item) => item.hidden == false);
    return firstShowColumnItemIndex === index;
  };

  const isLastShowColumn = (index) => {
    const lastShowColumnItemIndex = columns.findLastIndex((item) => item.hidden == false);
    return lastShowColumnItemIndex === index;
  };

  return (
    <div className='border border-secondary rounded p-2 mt-7'>
      <div
        className='offcanvas-title h5 bg-body'
        style={{marginTop: '-20px', width: 'fit-content'}}
      >
        {t('sheet_view_edit_column')}
      </div>
      <div className='d-flex flex-column'>
        <div className='offcanvas-title h5 my-3'>{t('sheet_view_edit_column_show')}</div>
        <div
          ref={showColumn}
          className='d-flex flex-column w-100 bg-secondary p-3 gap-3 rounded'
          style={{minHeight: '50px'}}
        >
          {columns &&
            getShowColumn().map((item, index) => (
              <ColumnItem
                index={getColumnIndex(item.field)}
                text={item.name}
                moveColumn={moveColumn}
                field={item.field}
                key={index}
                hidden={false}
                isFirstShowColumn={isFirstShowColumn(index)}
                isLastShowColumn={isLastShowColumn(index)}
                onToggleHideItem={onDropItem}
              />
            ))}
        </div>
        <div className='offcanvas-title h5 my-3'>{t('sheet_view_edit_column_hide')}</div>
        <div
          ref={hideColumn}
          className='d-flex flex-column w-100 bg-secondary p-3 gap-3 rounded'
          style={{minHeight: '50px'}}
        >
          {columns &&
            getHideColumn().map((item, index) => (
              <ColumnItem
                key={index}
                index={getColumnIndex(item.field)}
                text={item.name}
                moveColumn={moveColumn}
                field={item.field}
                hidden={true}
                onToggleHideItem={onDropItem}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const ColumnItem = ({
  field,
  text,
  index,
  moveColumn,
  hidden,
  onToggleHideItem,
  isFirstShowColumn,
  isLastShowColumn,
}) => {
  const ref = useRef(null);
  const [{handlerId}, drop] = useDrop({
    accept: DND_COMLUMN,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveColumn(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{isDragging}, drag] = useDrag({
    type: DND_COMLUMN,
    item: () => {
      return {field, index};
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleMoveItemDown = () => {
    moveColumn(index, index + 1);
  };

  const handleMoveItemUp = () => {
    moveColumn(index, index - 1);
  };

  return (
    <div
      ref={ref}
      style={{opacity}}
      data-handler-id={handlerId}
      className='card p-3 cursor-pointer shadow d-flex flex-row justify-content-between align-items-center'
    >
      <div>{capitalize(text ?? '')}</div>

      <div className='d-flex gap-3'>
        {!hidden && (
          <>
            {!isFirstShowColumn && <i className='fa la-arrow-up' onClick={handleMoveItemUp} />}
            {!isLastShowColumn && <i className='fa la-arrow-down' onClick={handleMoveItemDown} />}
          </>
        )}
        {field !== 'complete' && (
          <i
            className={`far ${hidden ? 'fa-eye' : 'fa-eye-slash'}`}
            onClick={() => onToggleHideItem({field}, !hidden)}
          />
        )}
      </div>
    </div>
  );
};
