import React from 'react';
import TextField from './text/TextField';
import NumberField from './number/NumberField';
import DateField from './date/DateField';
import ColorField from './color/ColorField';
import LinkField from './link/LinkField';
import LastUpdatedDateField from './lastUpdatedDate/LastUpdatedField';
import DepartmentField from './department/DepartmentField';
import DependencyField from './dependency/DependencyField';
import TimeLineField from './timeline/TimeLineField';
import FileField from './file/FileField';
import ReferenceField from './reference/ReferenceField';
import FormulaField from './formula/FormulaField';
import PeopleField from './people/PeopleField';
import MainField from './main/MainField';
import CheckBoxField from './checkbox/CheckboxField';
import {ApprovalField} from './approval/ApprovalField';
import ActionField from './action/ActionField';
import GeoLocationField from './geoLocation/GeoLocationField';
import CustomAIPromptField from './custom-ai-prompt/CustomAIPromptField';
import LongTextField from './longText/LongTextField';
import LinkedField from './linked/LinkedField';
import LookupField from './lookup/LookupField';
import AutoNumberField from './auto-number/AutoNumberField';

function ItemField({
  value,
  item,
  column,
  sheet,
  onChange,
  onAction,
  fieldEditable,
  readOnly,
  containerClassName,
  mode = 'inline',
  displayMode,
  ...rest
}) {
  const {
    onAddValue,
    onRemoveValue,
    onEditFormula,
    onToggleSubitem,
    showExpand,
    showDetail,
    scrollDomSelector,
  } = rest || {};
  const type = column?.type ?? '';
  const field = column?.field ?? '';

  const renderField = () => {
    if (field === 'title') {
      return (
        <MainField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          onToggleSubitem={onToggleSubitem}
          showExpand={showExpand}
          showDetail={showDetail}
          mode={mode}
          containerClassName={containerClassName}
        />
      );
    }
    if (type === 'action') {
      return (
        <ActionField
          item={item}
          column={column}
          sheet={sheet}
          onToggleSubitem={onToggleSubitem}
          showExpand={showExpand}
          showDetail={showDetail}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'text') {
      return (
        <TextField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'longtext') {
      return (
        <LongTextField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'date') {
      return (
        <DateField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          scrollDomSelector={scrollDomSelector}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'number') {
      return (
        <NumberField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'auto_number') {
      return (
        <AutoNumberField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'people') {
      return (
        <PeopleField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          onAddValue={onAddValue}
          onRemoveValue={onRemoveValue}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'dependency') {
      return (
        <DependencyField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'color' || type === 'status' || type === 'priority') {
      return (
        <ColorField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          mode={mode}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'last_updated_date') {
      return (
        <LastUpdatedDateField item={item} column={column} containerClassName={containerClassName} />
      );
    } else if (type === 'department') {
      return (
        <DepartmentField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'timeline') {
      return (
        <TimeLineField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'file') {
      return (
        <FileField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'link') {
      return (
        <LinkField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'formula') {
      return (
        <FormulaField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onEditFormula}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'reference') {
      return (
        <ReferenceField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          mode={mode}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'approval') {
      return (
        <ApprovalField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          members={sheet?.members}
          onChange={onChange}
          onAction={onAction}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
        />
      );
    } else if (type === 'geolocation') {
      return (
        <GeoLocationField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
          {...rest}
        />
      );
    } else if (type === 'checkbox') {
      return <CheckBoxField value={value} onChange={onChange} />;
    } else if (type === 'custom_ai_prompt') {
      return (
        <CustomAIPromptField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
          mode={mode}
          onAction={onAction}
        />
      );
    } else if (type === 'linked') {
      return (
        <LinkedField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
          showDetail={showDetail}
          mode={mode}
          displayMode={displayMode}
        />
      );
    } else if (type === 'lookup') {
      return (
        <LookupField
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          fieldEditable={fieldEditable}
          readOnly={readOnly}
          containerClassName={containerClassName}
          showDetail={showDetail}
          mode={mode}
        />
      );
    } else {
      return <></>;
    }
  };

  return <>{renderField()}</>;
}

export default ItemField;
