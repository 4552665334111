import IconBarInterfaceViewMode from './IconBarInterfaceViewMode';
import clsx from 'clsx';
import {getViewInfoByType} from './helpers';
import {useTranslate} from 'core/i18n/i18nProvider';

export default function SheetInterfaceToolbar(props) {
  const {views, selectedView, selectedBarIcon, interfacePage, isConfigInterfaceView, handleSelectView, handleSelectViewIconBar} = props;
  const {t} = useTranslate();

  const onSelectedView = (viewKey, index) => {     
    handleSelectView(viewKey);
    handleSelectViewIconBar(index);
  };

  const selectedViewData = getViewInfoByType(selectedView?.type, t);

  return (
    <div
      className={clsx('p-8 w-100', {
        'border-dashed border-2 border-primary rounded-top rounded-start-0 border-bottom-0':
          isConfigInterfaceView,
        'border-bottom': !isConfigInterfaceView,
      })}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='me-5'>
          {interfacePage?.interfaceName} /&nbsp;
          <span className='text-gray-500'>{interfacePage?.name}</span>
        </h2>        
        <div>
          {views?.length > 1 && (                
                <div className='d-flex'>        
                  <div className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary rounded border'>
                    {views.map((view, index) => (
                      <IconBarInterfaceViewMode
                        {...view}
                        key={`interface-view-iconbar-item-${view?.type}-${index}`}
                        index={index}
                        selectedBarIcon={selectedBarIcon}
                        onSelectedView = {onSelectedView}
                      /> 
                    ))}                    
                  </div>
              </div>                
          )}
        </div>
      </div>
    </div>
  );
}
