import {createSlice} from '@reduxjs/toolkit';

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  apps: [],
  app: {},
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialUserState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
    appFetched: (state, action) => {
      const {data} = action.payload;
      state.acionLoading = false;
      state.error = null;
      state.app = data.data ? data.data : {};
    },

    appsFetched: (state, action) => {
      const {data} = action.payload;
      state.acionLoading = false;
      state.error = null;
      state.apps = data.data ? data.data : [];
    },
    resetAppData: (state, action) => {
      state.app = {};
      state.error = null;
    },
  },
});
