import {useTranslate} from 'core/i18n/i18nProvider';
import {RichTextField} from 'app/common/_partials/controls';
import {Field, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import './itemDetail.scss';
import {getStorage, removeStorage, setStorage} from 'app/common/_helpers';
import {Quill} from 'react-quill';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

export default function ItemUpdateAdd({addItemUpdate, item}) {
  const {t} = useTranslate();
  const [editActive, setEditActive] = useState(false);
  const tabPressed = useRef();
  const enterPressed = useRef();
  const [contentTemporary, setContentTemporary] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const formRef = useRef();
  const contentFieldRef = useRef();

  const onSaveUpdate = (values) => {
    addItemUpdate && addItemUpdate(values);
  };

  const onInputFocus = () => {
    setEditActive(true);
    setIsFocused(true);
  };

  const onInputBlur = (event) => {
    const content = formRef?.current?.values?.content;
    onSaveContentTemporary(content);
    setEditActive(false);
    setIsFocused(false);
  };

  const onSaveContentTemporary = (value) => {
    setStorage(`content_temporary_${item._id}`, value);
  };

  const removeContentTemporary = () => {
    removeStorage(`content_temporary_${item._id}`);
    setContentTemporary('');
  };

  const handleKeydown = (event) => {
    if (event.key === 'Tab') {
      tabPressed.current = true;
    }
    if (event.key === 'Enter') {
      enterPressed.current = true;
    }
    if (tabPressed.current && event.key === 'Enter') {
      event.preventDefault();
    }

    if (tabPressed.current && enterPressed.current && isFocused) {
      const quillInstance = contentFieldRef.current?.getEditor();
      quillInstance.root.blur();
      onInputBlur();
      formRef.current.handleSubmit();
      tabPressed.current = false;
      enterPressed.current = false;
    }
  };

  const handleKeyup = (event) => {
    if (event.key === 'Tab') {
      tabPressed.current = false;
    }
    if (event.key === 'Enter') {
      enterPressed.current = false;
    }
  };

  useEffect(() => {
    if (item) {
      const content = getStorage(`content_temporary_${item?._id}`);
      if (content === 'null') {
        setContentTemporary('');
      } else {
        setContentTemporary(content);
      }
    }
  }, [item]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        initialValues={{content: contentTemporary}}
        onSubmit={(values, {resetForm}) => {
          if (values?.content || values?.content !== '') {
            onSaveUpdate(values);
            resetForm();
            setEditActive(true);
            removeContentTemporary();
          }
        }}
      >
        {({handleSubmit}) => {
          return (
            <div
              className={`item-update-input ${
                editActive ? 'item-update-input-active' : 'item-update-input-inactive'
              }`}
            >
              <Field
                name='content'
                placeholder={t('sheet_item_detail_add_update_placeholder')}
                component={RichTextField}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                withFeedbackLabel
                customFeedbackLabel
                innerRef={contentFieldRef}
                onKeyDown={handleKeydown}
                onKeyUp={handleKeyup}
              />
              {editActive && (
                <div className='d-flex align-items-center justify-content-between mt-3'>
                  <div className='d-flex gap-2'>
                    <div className='d-flex gap-2 px-3 py-2 cursor-pointer'>
                      <span className='icon-attachment fs-icon'/>
                      <span>{t('sheet_detail_attachment')}</span>
                    </div>
                    <div className='d-flex gap-2 px-3 py-2 cursor-pointer'>
                      <span className='icon-Smileys fs-icon' />
                      <span>{t('sheet_detail_emoji')}</span>
                    </div>
                    <div className='d-flex gap-2 px-3 py-2 cursor-pointer'>
                      <span className='icon-mention fs-icon' />
                      <span>{t('sheet_detail_mention')}</span>
                    </div>
                  </div>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id='button-submit-tooltip' className='tooltip-inverse'>
                        {t('sheet_item_detail_tooltip_button_save')}
                      </Tooltip>
                    }
                  >
                    <button className='btn btn-primary item-update-button' onClick={handleSubmit}>
                      {t('sheet_item_detail_update')}
                    </button>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
}
