import React, {useState} from 'react';
import ApprovalItem from './ApprovalItem';
import {Nav, OverlayTrigger, Tab, Tooltip} from 'react-bootstrap';
import {shallowEqual, useSelector} from 'react-redux';
import {format} from 'date-fns';
import {generateNameAvatar} from 'app/modules/work/helpers';
import {timeAgoFromDateShortString} from 'app/common/_helpers/TimeHelper';
import {useTranslate} from 'core/i18n/i18nProvider';
import {APPROVAL_STATUS} from './contants';

function ApprovalDetailField({column, item, onChange, members, onAction, valueField}) {
  const {t} = useTranslate();
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const [selectedTab, setSelectedTab] = useState('approval');

  const onApproveStep = (action) => {
    const params = {
      ...action,
      itemId: item._id,
      sheetId: item.sheetId,
      type: 'approval',
      column: column.field,
    };
    onAction(item, params);
  };

  const getLabelStatus = (status, type, isLastItem, isFirstItem) => {
    if (type === 'oldValue' && status === APPROVAL_STATUS.PENDING) {
      return t('sheet_table_column_approval_approve');
    }
    if (isFirstItem) {
      return t('sheet_table_column_approval_create_approve');
    }
    if (!isFirstItem && !isLastItem && status === APPROVAL_STATUS.PENDING) {
      return t('sheet_table_column_approval_approved');
    }
    if (type === 'newValue' && !isLastItem && status === APPROVAL_STATUS.PENDING) {
      return t('sheet_table_column_approval_approve');
    }
    if (status === APPROVAL_STATUS.PENDING) {
      return t('sheet_table_column_approval_pending');
    }
    if (status === APPROVAL_STATUS.APPROVED) {
      return t('sheet_table_column_approval_approve');
    }
    if (status === APPROVAL_STATUS.REJECTED) {
      return t('sheet_table_column_approval_rejected');
    }
    if (status === APPROVAL_STATUS.REOPEN) {
      return t('sheet_table_column_approval_reopen');
    }
  };

  const getBackgroundStatus = (status, type) => {
    if (status === APPROVAL_STATUS.PENDING) {
      return 'bg-info';
    }
    if (status === APPROVAL_STATUS.APPROVED) {
      return 'bg-success';
    }
    if (status === APPROVAL_STATUS.REJECTED) {
      return 'bg-danger';
    }
    if (status === APPROVAL_STATUS.REOPEN) {
      return 'bg-warning';
    }
  };

  return (
    <div className=' mt-3'>
      <Tab.Container defaultActiveKey={selectedTab}>
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between mb-5'>
          <Nav
            onSelect={setSelectedTab}
            as='ul'
            role='tablist'
            className='nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1'
          >
            <Nav.Item as='li'>
              <Nav.Link
                eventKey='approval'
                className={`nav-link ${selectedTab === 'approval' ? 'active' : ''}`}
              >
                {t('sheet_table_approval_detail_tab_approval')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link
                eventKey='history_log'
                className={`nav-link ${selectedTab === 'history_log' ? 'active' : ''}`}
              >
                {t('sheet_table_approval_detail_tab_approval_history')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        {/*end::Header*/}

        {/*begin::Content*/}
        <div
          id='kt_quick_panel_approval'
          role='tabpanel'
          className={`fade pt-3 scroll ps ${selectedTab === 'approval' ? 'active show' : 'd-none'}`}
        >
          <div className='list-approval'>
            {column.steps?.map((step, index) => {
              const effValue = item?.[column?.field];

              const getIcon = () => {
                if ((!effValue && index === 0) || effValue?.step?.length === index) {
                  return <i className='las la-edit icon-check text-info'></i>;
                }
                if (step.note) {
                  return <i className='las la-times-circle icon-check text-danger'></i>;
                }
                if (effValue?.step?.length >= index) {
                  return <i className='las la-check-circle icon-check text-success'></i>;
                }
                return <i className='las la-tasks icon-check text-warning'></i>;
              };
              return (
                <div className='item-approval-timeline' key={step.value || step.id}>
                  <div className='item-approval-timeline-line'>
                    <div>{getIcon()}</div>
                    <div className='line-border border-success'></div>
                  </div>
                  <ApprovalItem
                    step={step}
                    item={item}
                    index={index}
                    steps={column.steps}
                    value={item?.[column?.field]}
                    column={column}
                    onChange={onChange}
                    assigneeRoleUsers={column?.assignee_role_users}
                    members={members}
                    onApproveStep={onApproveStep}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          id='kt_quick_panel_history_log'
          role='tabpanel'
          className={`fade pt-3 scroll ps ${
            selectedTab === 'history_log' ? 'active show' : 'd-none'
          }`}
        >
          <div className=''>
            <table className='w-100'>
              <tbody>
                {valueField?.histories &&
                  valueField.histories.map((itemAudit, idx) => {
                    const personItem = objectUser[itemAudit?.user?.id] || {};
                    const isLastItem = valueField.histories - 1 === idx;
                    const isFirstItem = idx === 0;
                    return (
                      <tr
                        className='border-none border-bottom border-secondary detail-audit-item'
                        key={`${itemAudit.id}`}
                      >
                        <td className='w-80px min-w-80px text-muted font-size-xs me-2'>
                          {itemAudit?.new_values?.last_updated_at && (
                            <OverlayTrigger
                              key={'tooltip-time-item-audit'}
                              placement='top'
                              overlay={
                                <Tooltip
                                  className='tooltip-inverse'
                                  style={{zIndex: 10000}}
                                  id='tooltip-time-item-audit'
                                >
                                  {format(
                                    new Date(itemAudit?.new_values?.last_updated_at * 1000),
                                    'dd-MM-yyyy HH:mm:ss'
                                  )}
                                </Tooltip>
                              }
                            >
                              <span>
                                <i className='las la-clock'></i>
                                {timeAgoFromDateShortString(
                                  itemAudit?.new_values?.last_updated_at * 1000
                                )}
                              </span>
                            </OverlayTrigger>
                          )}
                        </td>
                        <td className='py-2 d-flex align-items-center h-45px text-truncate'>
                          {itemAudit?.user?.name}
                        </td>
                        <td></td>
                        <td className='w-150px pt-2'>
                          <div className='w-150px h-30px d-inline-block text-truncate text-center'>
                            <span
                              className={`w-100 h-30px border-0 btn btn-none btn-xs rounded text-white ${getBackgroundStatus(
                                itemAudit?.new_values?.status
                              )}`}
                            >
                              {getLabelStatus(
                                itemAudit?.new_values?.status,
                                'newValue',
                                isLastItem,
                                isFirstItem
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}

export default ApprovalDetailField;
