/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useLayoutEffect, useRef} from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  // LineChart,
  //BarChart,
  PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import {get} from 'lodash';
import {getCSSVariableValue} from 'core/assets/ts/_utils';
// Register the required components

// Register the required components

export function PieChartV2({
  className,
  widgetHeight = '400px',
  data = null,
  value1Field = null,
  value1Name = '',
  value2Field = null,
  value2Name = '',
  dimensionField = '',
  chartId = 'chartdiv',
}) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
  ]);

  const fontFamily = getCSSVariableValue('--kt-font-family');

  const total = data.reduce((accumulator, item) => {
    if (!isNaN(get(item, value1Field))) {
      return accumulator + get(item, value1Field);
    }
    return accumulator;
  }, 0);

  const formatPercent = (value) => {
    return parseFloat(((value / total) * 100).toFixed(1));
  };

  const option = {
    textStyle: {
      fontFamily: fontFamily,
      fontSize: 12,
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `${params.name} <br/> ${params.marker} ${params.seriesName}: <b>${
          params.value
        }</b> (${formatPercent(params.value)}%)`;
      },
    },
    legend: {
      orient: 'vertical',
      right: '0%',
      textStyle: {
        color: 'var(--kt-body-color)',
      },
      formatter: function (name) {
        const series = option.series[0].data;
        for (let i = 0; i < series.length; i++) {
          if (series[i].name === name) {
            return `${name}: ${series[i]?.value} (${formatPercent(series[i].value)} %)`;
          }
        }
        return name;
      },
    },
    series: [
      {
        name: value1Name,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
          color: 'var(--kt-body-color)',
          fontSize: 14,
        },
        percentPrecision: 1,
        emphasis: {
          label: {
            show: true,
            fontWeight: 'bold',
          },
          itemStyle: {
            shadowBlur: 5,
          },
        },
        labelLine: {
          show: true,
        },
        data: !data
          ? []
          : data.map((dataItem) => {
              return {
                value: get(dataItem, value1Field),
                name: get(dataItem, dimensionField),
              };
            }),
      },
    ],
  };

  return (
    <div className='table-chart-wrapper-1 w-100 table-borderless overflow-auto rounded'>
      <div className=' flex-grow-1 p-3'>
        <ReactEChartsCore echarts={echarts} option={option} notMerge={true} lazyUpdate={true} />
      </div>
    </div>
  );
}
