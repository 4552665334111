import './styles.scss';
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslate} from "core/i18n/i18nProvider";
import MultiRadioButton from "app/common/_partials/controls/MultiRadioButton";
import {cloneDeep} from "lodash";

const ConfigTerminologyDialog = ({
  title = '',
  children,
  onSave,
  defaultTerminology
}) => {
  const {t} = useTranslate();
  const [terminology, setTerminology] = useState(defaultTerminology)
  const [show, setShow] = useState(false);
  const [terminologyOptions, setTerminologyOptions] = useState([]);
  const onHide = () => setShow(false);
  const onShow = () => setShow(true);

  useEffect(() => {
    setTerminology(defaultTerminology)
  },[defaultTerminology])

  const terminologyItems = require('./terminologyList.json');

  useEffect(() => {
    const _terminologyItems = cloneDeep(terminologyItems)
    setTerminologyOptions(_terminologyItems.map(terminologyItem => ({
      text: terminologyItem.name,
      value: terminologyItem.type
    })))
  }, []);

  const onChange = (newType) => {
    let terminologyItem = terminologyItems.find(terminologyItem => terminologyItem.type === newType)
    if (!terminologyItem) {
      terminologyItem = {
        type: newType,
        name: newType,
        singular: newType,
        plural: newType
      }
    }
    setTerminology(terminologyItem);
  }

  const onSubmit = () => {
    onSave && onSave(terminology);
    onHide();
  }

  return (
    <>
      <span onClick={onShow}>{children}</span>
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MultiRadioButton
            className='terminology-list'
            options={terminologyOptions}
            useCustomValue
            defaultValue={terminology?.type}
            onChange={onChange}
          />

        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-light btn-elevate btn-secondary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
            onClick={onHide}
          >
            {t('common_cancel')}
          </button>
          <button
            onClick={onSubmit}
            className='btn btn-primary btn-elevate font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
          >
            {t('common_save')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfigTerminologyDialog;