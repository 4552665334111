import {secondsToMinuteString, timeStringToMilliseconds} from 'app/common/_helpers/TimeHelper';
import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {format, isAfter, isBefore} from 'date-fns';
import {vi} from 'date-fns/locale';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './timeLineField.scss';
import {useTranslate} from 'core/i18n/i18nProvider';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
function TimeLineField({value, item, column, onChange, fieldEditable, readOnly, containerClass}) {
  const field = column?.field;
  const {t} = useTranslate();
  let currentValue = effectiveValue(value);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [rangeLabel, setRangeLabel] = useState('');

  useEffect(() => {
    setRangeLabel(formatLabel(startDate, endDate, startTime, endTime));
  }, [startDate, endDate, startTime, endTime, column?.timeline]);

  const handleRemoveDate = (e) => {
    e.stopPropagation();
    onChange(item, field, currentValue, {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    });
    currentValue = null;
  };

  const handleSaveTimeline = () => {
    if (startDate && endDate && fieldEditable) {
      const timeLineValue = {
        startDate: moment(startDate).unix().valueOf(),
        endDate: moment(endDate).unix().valueOf(),
        startTime: startTime
          ? column.settings?.hasTime
            ? timeStringToMilliseconds(startTime)
            : null
          : null,
        endTime: endTime
          ? column.settings?.hasTime
            ? timeStringToMilliseconds(endTime)
            : null
          : null,
      };
      onChange(item, field, currentValue, timeLineValue);
    }
  };

  useEffect(() => {
    if (currentValue) {
      const startDate = currentValue?.startDate ? new Date(currentValue?.startDate * 1000) : null;
      const endDate = currentValue?.endDate ? new Date(currentValue?.endDate * 1000) : null;
      const startTime = currentValue?.startTime
        ? secondsToMinuteString(currentValue?.startTime)
        : null;
      const endTime = currentValue?.endTime ? secondsToMinuteString(currentValue?.endTime) : null;
      setStartDate(startDate);
      setEndDate(endDate);
      setStartTime(startTime);
      setEndTime(endTime);
    }
  }, [
    currentValue?.startDate,
    currentValue?.endDate,
    currentValue?.startTime,
    currentValue?.endTime,
    fieldEditable,
    currentValue,
  ]);

  const renderTimelineDuration = (days, hours, minutes) => {
    return (
      <span className='timeline-difference'>
        {days} {t('sheet_column_timeline_day')}
        {hours > 0 && (
          <>
            , {hours} {t('sheet_column_timeline_hour')}
          </>
        )}
        {minutes > 0 && `, ${minutes} ${t('sheet_column_timeline_minute')}`}
      </span>
    );
  };

  function formatLabel(start, end, startTime, endTime) {
    if (currentValue?.startDate && currentValue?.endDate) {
      if (
        start &&
        end &&
        new Date(start).valueOf() !== new Date(end).valueOf() &&
        isAfter(new Date(currentValue?.endDate * 1000), new Date())
      ) {
        const diffByDay = moment(end).diff(moment(start), 'days', true);
        const days = Math.floor(diffByDay);
        const hoursDecimal = (diffByDay - days) * 24 < 0 ? 0 : (diffByDay - days) * 24;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.round((hoursDecimal - hours) * 60);
        return (
          <div className='timeline-table-preview cursor-pointer  w-100 text-truncate'>
            {column.settings?.hasTime ? (
              <>
                <span className='timeline-date'>
                  <TimeFormat start={start} end={end} startTime={startTime} endTime={endTime} />
                </span>
                {renderTimelineDuration(days, hours, minutes)}
              </>
            ) : (
              <>
                <span className='timeline-date'>
                  {format(start, 'd MMM', {locale: vi}) +
                    ' - ' +
                    format(end, 'd MMM', {locale: vi})}
                </span>
                <span className='timeline-difference'>{days + 1} ngày</span>
              </>
            )}

            {fieldEditable && (
              <span className='remove-timeline-item' onClick={handleRemoveDate}>
                <i className='las la-times text-dark'></i>
              </span>
            )}
          </div>
        );
      }
      if (
        start &&
        end &&
        new Date(start).valueOf() !== new Date(end).valueOf() &&
        isBefore(new Date(currentValue?.endDate * 1000), new Date())
      ) {
        const diffByDay = moment(end).diff(moment(start), 'days', true);
        const days = Math.floor(diffByDay);
        const hoursDecimal = (diffByDay - days) * 24 < 0 ? 0 : (diffByDay - days) * 24;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.round((hoursDecimal - hours) * 60);
        return (
          <div className='timeline-table-preview cursor-pointer bg-danger w-100 text-truncate'>
            {column.settings?.hasTime ? (
              <>
                <span className='timeline-date'>
                  <TimeFormat start={start} end={end} startTime={startTime} endTime={endTime} />
                </span>
                {renderTimelineDuration(days, hours, minutes)}
              </>
            ) : (
              <> 
                <span className='timeline-date'>
                  {isAfter(new Date(start), new Date(2000, 1, 1)) &&
                    isAfter(new Date(end), new Date(2000, 1, 1)) &&
                    format(start, 'd MMM', {locale: vi}) +
                      ' - ' +
                      format(end, 'd MMM', {locale: vi})}
                </span>
                <span className='timeline-difference'>{days + 1} ngày</span>
              </>
            )}
            {fieldEditable && (
              <span className='remove-timeline-item' onClick={handleRemoveDate}>
                <i className='las la-times text-dark'></i>
              </span>
            )}
          </div>
        );
      }
      if (start && end && new Date(start).valueOf() === new Date(end).valueOf()) {
        const diffByDay = moment(end).diff(moment(start), 'days', true);
        const days = Math.floor(diffByDay);
        const hoursDecimal = (diffByDay - days) * 24;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.round((hoursDecimal - hours) * 60);
        return (
          <div className='timeline-table-preview bg-primary cursor-pointer w-100 text-truncate'>
            {column.settings?.hasTime ? (
              <>
                <span className='timeline-date'>
                  {isAfter(new Date(start), new Date(2000, 1, 1)) &&
                    format(start, 'd MMM', {locale: vi})}
                </span>
                {renderTimelineDuration(days, hours, minutes)}
              </>
            ) : (
              <>
                <span className='timeline-date'>
                  {isAfter(new Date(start), new Date(2000, 1, 1)) &&
                    format(start, 'd MMM', {locale: vi})}
                </span>
                <span className='timeline-difference'>{days + 1} ngày</span>
              </>
            )}
            {fieldEditable && (
              <span className='remove-timeline-item' onClick={handleRemoveDate}>
                <i className='las la-times text-dark'></i>
              </span>
            )}
          </div>
        );
      }
    }
    return <div className='timeline-table-preview bg-secondary cursor-pointer  w-100'>-</div>;
  }

  const onToggle = () => {
    if (startDate && endDate) {
      const start = new Date(startDate).valueOf();
      const end = new Date(endDate).valueOf();
      if (end >= start) {
        handleSaveTimeline();
      }
    }
  };

  const popover = () => {
    return (
      <div
        className={`p-3 d-flex pb-0 ${clsx({
          'dropdown-menu-timeline': column.settings?.hasTime,
        })}`}
      >
        <div className='pe-2'>
          <div className='d-flex align-items-center mb-3'>
            <div
              className={clsx('d-flex date-picker-group', {
                'input-date-control': !column.settings?.hasTime,
              })}
            >
              <DatePicker
                placeholderText={t('sheet_edit_timeline_input_placeholder')}
                className='date-input-timeline form-control py-1 border h-100 rounded'
                locale={'vi'}
                selected={startDate && !isNaN(new Date(startDate)) ? startDate : null}
                onChange={(date) => {
                  if (!isNaN(new Date(startDate))) {
                    const start = new Date(date).valueOf();
                    const end = new Date(endDate).valueOf();
                    if (!endDate || (endDate && start <= end)) {
                      setStartDate(date);
                    }
                  }
                }}
                onFocus={(e) => {
                  e.target.setSelectionRange(0, e.target.value.length);
                }}
                showDateSelect={false}
                dateFormat='dd-MM-yyyy'
              />
              {column.settings?.hasTime && (
                <DatePicker
                  className='form-control w-70px py-1 ms-2 border h-100 rounded'
                  locale={'vi'}
                  selected={
                    startDate && !isNaN(new Date(startDate)) ? startDate : new Date(2000, 1, 1)
                  }
                  onChange={(date) => {
                    const startTime = format(date, 'HH:mm');
                    setStartTime(startTime);
                    setStartDate(date);
                  }}
                  onFocus={(e) => {
                    e.target.setSelectionRange(0, e.target.value.length);
                  }}
                  showTimeSelect={false}
                  showTimeSelectOnly
                  timeCaption='Time'
                  dateFormat='HH:mm'
                />
              )}
            </div>
          </div>
          <DatePicker
            locale={'vi'}
            selected={
              startDate &&
              !isNaN(new Date(startDate)) &&
              isAfter(new Date(startDate), new Date(2000, 1, 1))
                ? new Date(startDate)
                : null
            }
            onChange={(date) => {
              if (!isNaN(new Date(startDate))) {
                const start = new Date(date).valueOf();
                const end = new Date(endDate).valueOf();
                if (!endDate || (endDate && start <= end)) {
                  const startTime = format(date, 'HH:mm');
                  setStartDate(date);
                  setStartTime(startTime);
                }
              }
            }}
            showTimeSelect={column.settings?.hasTime}
            inline
          />
        </div>
        <div className='ps-2'>
          <div className='d-flex align-items-center mb-3'>
            <div
              className={clsx('d-flex date-picker-group', {
                'input-date-control': !column.settings?.hasTime,
              })}
            >
              <DatePicker
                placeholderText={t('sheet_edit_timeline_input_placeholder')}
                className='date-input-timeline form-control py-1 border h-100 rounded'
                locale={'vi'}
                selected={endDate && !isNaN(new Date(endDate)) ? endDate : null}
                onChange={(date) => {
                  if (!isNaN(new Date(endDate))) {
                    const start = new Date(startDate).valueOf();
                    const end = new Date(date).valueOf();
                    if (!startDate || (startDate && start <= end)) {
                      setEndDate(date);
                    }
                  }
                }}
                onFocus={(e) => {
                  e.target.setSelectionRange(0, e.target.value.length);
                }}
                showDateSelect={false}
                dateFormat='dd-MM-yyyy'
              />
              {column.settings?.hasTime && (
                <DatePicker
                  className='form-control w-70px py-1 ms-2 border h-100 rounded'
                  locale={'vi'}
                  selected={endDate && !isNaN(new Date(endDate)) ? endDate : new Date(2000, 1, 1)}
                  onChange={(date) => {
                    const endTime = format(date, 'HH:mm');
                    setEndTime(endTime);
                    setEndDate(date);
                  }}
                  onFocus={(e) => {
                    e.target.setSelectionRange(0, e.target.value.length);
                  }}
                  showTimeSelect={false}
                  showTimeSelectOnly
                  timeCaption='Time'
                  dateFormat='HH:mm'
                />
              )}
            </div>
          </div>
          <DatePicker
            locale={'vi'}
            selected={
              endDate &&
              !isNaN(new Date(endDate)) &&
              isAfter(new Date(endDate), new Date(2000, 1, 1))
                ? new Date(endDate)
                : null
            }
            onChange={(date) => {
              if (!isNaN(new Date(endDate))) {
                const start = new Date(startDate).valueOf();
                const end = new Date(date).valueOf();
                if (!startDate || !endTime || (startDate && start <= end)) {
                  setEndDate(date);
                  const endTime = format(date, 'HH:mm');
                  setEndTime(endTime);
                }
              }
            }}
            showTimeSelect={column.settings?.hasTime}
            inline
          />
        </div>
      </div>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <div className='w-100'>
        <DropdownPopover onToggle={onToggle} renderPopover={popover} popoverClassName={'mh-400px'}>
          {rangeLabel}
        </DropdownPopover>
      </div>
    );
  }

  return <div className='w-100'>{rangeLabel}</div>;
}

export const TimeFormat = ({start, end, startTime, endTime}) => {
  if (
    isAfter(new Date(start), new Date(2000, 1, 1)) &&
    isAfter(new Date(end), new Date(2000, 1, 1))
  ) {
    return (
      <>
        {format(start, 'd MMM', {locale: vi})}
        {startTime ? `|${startTime}` : null} - {format(end, 'd MMM', {locale: vi})}{' '}
        {endTime ? `|${endTime}` : null}
      </>
    );
  }
  return <></>;
};

const DropdownCustomToggler = React.forwardRef(({children, onClick}, ref) => {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
});

export default TimeLineField;
