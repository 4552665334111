import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Offcanvas} from 'react-bootstrap';
import './nodeDetail.scss';
import Select from 'react-select';
import {capitalize, get, isArray, isEmpty, isObject} from 'lodash';
import {useWorkflow} from '../hook/workflowHook';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import ConfigValue from './value/ConfigValue';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getSheetItemsAction} from '../stores/actions';

function NodeDetail({show, onClose, node, sheetList}) {
  const {t} = useTranslate();
  const defaultItemOption = {label: t('workflows.node.item.origin'), value: 'origin'};
  const [nodeData, setNodeData] = useState({});
  const [nodeDataConfig, setNodeDataConfig] = useState(node?.data?.config ?? {});
  const [optionsDisabled, setOptionsDisabled] = useState({});
  const {sheets, workspaces} = useSelector((state) => state.workflow, shallowEqual);
  const [itemOptions, setItemOptions] = useState({
    sheet: [defaultItemOption],
  });
  const [isInitial, setInitial] = useState(true);
  const {saveNode} = useWorkflow();

  useEffect(() => {
    if (isInitial && node?.data?.config && node?.data?.block?.fields) {
      let fields = node?.data?.block?.fields;
      fields.forEach((field) => {
        if (field?.type === 'sheet') {
          let sheet = node?.data?.config[field?.key];
          let sheetId = sheet?.value;
          if (sheetId && field?.key) {
            loadSheetItems(field?.key, sheetId);
          }
        }
      });
      setInitial(false);
    }
  }, [node]);

  const getWorkspaceName = useCallback(
    (workspaceId) => {
      if (!workspaceId) return;
      const workspace = workspaces?.find((item) => item._id === workspaceId);
      return workspace?.name ?? '';
    },
    [workspaces]
  );

  const sheetOptions = useMemo(() => {
    let sheetOptions = sheetList ?? sheets ?? [];
    return sheetOptions?.map((sheet) => {
      let label = sheet?.name;
      if (sheet?.workspace) {
        let workspaceName = getWorkspaceName(sheet?.workspace);
        if (workspaceName) {
          label = workspaceName + ' - ' + label;
        }
      }
      return {
        sheet: sheet,
        label: label,
        value: sheet?._id,
      };
    });
  }, [sheets, sheetList]);

  const getColumnOptions = (key) => {
    const sheetColumns = nodeDataConfig[key]?.sheet?.columns ?? [];
    let columnOptions = sheetColumns.map((column) => ({
      label: capitalize(column?.name),
      value: column?.field,
      type: column?.type,
      column: column,
    }));
    return columnOptions;
  };

  const operatorOptions = [
    {
      value: '=',
      label: t('workflows.node.operation_equal'),
    },
    {
      value: '>',
      label: t('workflows.node.operation_greater'),
    },
    {
      value: '<',
      label: t('workflows.node.operation_less'),
    },
    {
      value: '<>',
      label: t('workflows.node.operation_difference'),
    },
  ];
  const dispatch = useDispatch();
  const nodeBlock = useMemo(() => nodeData?.block, [nodeData]);
  const snackNotification = useSnackNotification();

  const loadSheetItems = (key, sheetId) => {
    if (!sheetId) return;
    dispatch(getSheetItemsAction({sheetId: sheetId})).then((res) => {
      let data = res?.data;
      let itemOptions = data.map((item) => ({
        label: item?.title?.value ?? item?.title,
        value: item._id,
        item: item,
      }));
      itemOptions = [defaultItemOption, ...itemOptions];
      setItemOptions((prev) => ({...prev, [key]: itemOptions}));
    });
  };

  const isFieldDisabled = (field) => {
    let depends = field?.depends;
    let isDisabled = false;
    if (depends && !isEmpty(depends)) {
      isDisabled = depends.every((depend) => {
        return !get(nodeDataConfig, depend?.key ?? depend);
      });
    }

    return isDisabled;
  };

  const initOptionDisabled = () => {
    let fields = nodeBlock?.fields ?? [];
    fields = fields.map((field) => [field?.key, isFieldDisabled(field)]);
    let optionsDisabled = Object.fromEntries(fields);
    setOptionsDisabled(optionsDisabled);
  };

  useEffect(() => {
    initOptionDisabled();
  }, [nodeBlock, nodeData, nodeDataConfig]);

  useEffect(() => {
    if (node && node?.data) {
      setNodeData({
        ...node?.data,
        label:
          node?.data?.label && node?.data?.label !== ''
            ? node?.data?.label
            : t('workflows.node.node_empty_label'),
      });
    }
  }, [node]);

  useEffect(() => {
    setNodeDataConfig(nodeData?.config ?? {});
  }, [nodeData]);

  const getSelectValue = useCallback(
    (key) => {
      let value = get(nodeDataConfig ?? {}, `${key}.value`);
      if (isObject(value)) {
        value = '';
      } else if (isArray(value)) {
        value = value.toString();
      }
      return {
        value: value,
        label: get(nodeDataConfig ?? {}, `${key}.label`) ?? value,
      };
    },
    [nodeDataConfig]
  );

  const onChangeNodeData = (key, value) => {
    setNodeData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeNodeDataConfig = (field, value) => {
    let key = field?.key;
    if (field?.type && field?.type === 'sheet') {
      loadSheetItems(key, value?.value);
    }
    setNodeDataConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getSelectOption = (field) => {
    const type = field?.type;
    const depends = field?.depends;
    if (type === 'column') {
      if (depends && depends[0]) {
        return getColumnOptions(depends[0]?.key ?? depends[0]);
      } else {
        return getColumnOptions('sheet');
      }
    } else if (type === 'item') {
      if (depends && depends[0]) {
        return itemOptions[depends[0]?.key ?? depends[0]];
      } else {
        return itemOptions['sheet'];
      }
    } else if (type === 'operation') {
      return operatorOptions;
    } else if (type === 'sheet') {
      return sheetOptions;
    }
  };

  const onUpdateNode = () => {
    const params = {
      ...node,
      data: {
        ...nodeData,
        config: {
          ...nodeDataConfig,
        },
      },
    };
    saveNode(node?.id, params).then((res) => {
      if (res && res?.success) {
        snackNotification.showSuccess(t('common_save_success'));
      } else {
        snackNotification.showError(t('common_save_error'));
      }
      onClose();
    });
  };

  const onChangeConfigValue = (key, value) => {
    onChangeNodeDataConfig({key: key}, value);
  };

  const getConfigValue = useCallback(
    (key) => {
      return get(nodeDataConfig, `${key}.value`);
    },
    [nodeDataConfig]
  );

  const getConfigValuePropByDepend = (field, propType) => {
    let depends = field?.depends ?? [];
    let depend = depends.find((dep) => dep?.type === propType);
    if (!depend) return {};
    return get(nodeDataConfig, `${depend?.key}.${propType}`);
  };

  return (
    <Offcanvas show={show} onHide={onClose} placement='end' className='node-detail-container'>
      <Offcanvas.Header className='d-flex flex-column gap-3 border-bottom p-5'>
        <div className='d-flex justify-content-between align-items-center w-100 gap-5'>
          <input
            className='node-detail-label fw-bold w-100'
            value={nodeData?.label}
            onChange={(e) => onChangeNodeData('label', e.target.value)}
          />
          <div className='d-flex gap-5'>
            <div className='cursor-pointer' onClick={onClose}>
              <i className='ki ki-close icon-xs text-muted' />
            </div>
          </div>
        </div>
      </Offcanvas.Header>

      <div className='d-flex flex-column p-5 gap-5 justify-content-between h-100 overflow-auto'>
        <div className='d-flex flex-column gap-5'>
          {nodeBlock &&
            nodeBlock?.fields &&
            nodeBlock?.fields.map((field, index) => (
              <div key={index}>
                <label className='mb-2'>
                  {field?.title}
                  {field?.required && <span className='required'></span>}
                </label>
                {field?.type === 'value' ? (
                  <ConfigValue
                    value={getConfigValue(field?.key)}
                    item={getConfigValuePropByDepend(field, 'item')}
                    column={getConfigValuePropByDepend(field, 'column')}
                    sheet={getConfigValuePropByDepend(field, 'sheet')}
                    onChange={(value) => onChangeConfigValue(field?.key, value)}
                    containerClassName='h-100'
                    disabled={optionsDisabled[field?.key]}
                  />
                ) : (
                  <Select
                    value={getSelectValue(field?.key)}
                    onChange={(value) => onChangeNodeDataConfig(field, value)}
                    options={getSelectOption(field)}
                    isDisabled={optionsDisabled[field?.key]}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
      <div className='d-flex justify-content-end p-5'>
        <button className='btn btn-primary' onClick={() => onUpdateNode()}>
          {t('common_save')}
        </button>
      </div>
    </Offcanvas>
  );
}

export default NodeDetail;
