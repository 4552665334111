import React, {useEffect, useState} from 'react';
import {SelectSearchField} from 'app/common/_partials/controls/forms/SelectSearchField';
import {Field, useFormikContext} from 'formik';
import {CheckBoxField} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useDispatch} from 'react-redux';
import {getSheetAction} from 'app/modules/work/sheet/stores/sheetActions';

const ConfigLinkedColumn = ({sheetOptions, column, mode = 'edit', error, setFieldValue}) => {
  const {t} = useTranslate();
  const {values} = useFormikContext();
  const dispatch = useDispatch();
  const [linkedColumnOptions, setLinkedColumnOptions] = useState([]);
  const [viewOptions, setViewOptions] = useState([]);
  useEffect(() => {
    if (values?.settings?.linked) {
      dispatch(getSheetAction({id: values?.settings?.linked?.value})).then((res) => {
        if (res?.data?.success) {
          let sheet = res.data.data;
          let sheetColumns = sheet?.columns ?? [];
          let linkedColumnOptions = [];
          sheetColumns.forEach((column) => {
            if (!column?.deleted && !column?.hidden && column.field !== 'title') {
              linkedColumnOptions.push({value: column.field, label: column.name, ...column});
            }
          });
          setLinkedColumnOptions(linkedColumnOptions);

          //set view options
          let viewOptions = sheet?.views ?? [];
          viewOptions = viewOptions.map((view) => ({
            value: view.code,
            label: view.name || t('sheet_view_type_' + view.type),
          }));
          setViewOptions(viewOptions);
        }
      });
    }
  }, [values?.settings?.linked]);

  useEffect(() => {
    if (values?.settings?.linked) {
      setFieldValue && setFieldValue('name', values?.settings?.linked?.label);
    }
  }, [values?.settings?.linked]);

  return (
    <div>
      <div className='form-group row mb-2 '>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          <label>{t('sheet_column_setting_type_linked')}:</label>
        </div>
        <div className='col-9'>
          <SelectSearchField
            isMulti={false}
            name='settings.linked'
            options={sheetOptions.map((sheet) => ({
              label: sheet.name,
              value: sheet._id,
            }))}
            closeMenuOnSelect={true}
            isDisabled={mode === 'edit'}
            placeholder={t('sheet_column_setting_linked_choose_sheet')}
          />
          {error?.linked && <div className='text-danger'>{error?.linked}</div>}
        </div>
      </div>
      <div className='form-group row mb-2 '>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          <label>{t('sheet_column_setting_linked_display_fields')}:</label>
        </div>
        <div className='col-9'>
          <SelectSearchField
            isMulti={true}
            name='settings.linked_display_fields'
            options={linkedColumnOptions}
            closeMenuOnSelect={true}
            disabled={!values?.settings?.linked}
            placeholder={t('sheet_column_setting_linked_display_fields')}
          />
        </div>
      </div>
      <div className='form-group row mb-2'>
        <div className='col-3 d-flex  justify-content-end'></div>
        <div className='col-9'>
          <div>
            <Field
              labelClass='fs-6'
              name='settings.allow_create'
              component={CheckBoxField}
              autoComplete='off'
              label={t('sheet_column_setting_type_linked_allow_create')}
            />
          </div>
        </div>
      </div>
      <div className='form-group row mb-2'>
        <div className='col-3 d-flex  justify-content-end'></div>
        <div className='col-9'>
          <div>
            <Field
              labelClass='fs-6'
              name='settings.is_multiple'
              component={CheckBoxField}
              autoComplete='off'
              label={t('sheet_column_setting_type_linked_is_multiple')}
            />
          </div>
        </div>
      </div>
      <div className='form-group row mb-2'>
        <div className='col-3 d-flex justify-content-end'></div>
        <div className='col-9'>
          <div>
            <Field
              labelClass='fs-6'
              name='settings.has_linked_view_selectable'
              component={CheckBoxField}
              autoComplete='off'
              label={t('sheet_column_setting_type_linked_limit_selection_view')}
            />
          </div>
        </div>
      </div>
      {column?.settings?.has_linked_view_selectable && (
        <div className='form-group row mb-2 '>
          <div className='col-3 d-flex  justify-content-end'></div>
          <div className='col-9'>
            <SelectSearchField
              isMulti={false}
              name='settings.linked_view_selectable'
              options={viewOptions}
              closeMenuOnSelect={true}
            />
          </div>
        </div>
      )}
      <div className='form-group row mb-2 d-none'>
        <div className='col-3 d-flex  justify-content-end'></div>
        <div className='col-9'>
          <div>
            <Field
              labelClass='fs-6'
              name='settings.has_linked_filter_selectable'
              component={CheckBoxField}
              autoComplete='off'
              label={t('sheet_column_setting_type_linked_limit_selection_filter')}
            />
          </div>
        </div>
      </div>
      {column?.settings?.has_linked_filter_selectable && (
        <div className='form-group row mb-2 '>
          <div className='col-3 d-flex  justify-content-end'></div>
          <div className='col-9'>
            <SelectSearchField
              isMulti={false}
              name='settings.linked_filter_selectable'
              options={[]}
              closeMenuOnSelect={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigLinkedColumn;
