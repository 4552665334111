/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useMemo, useState} from 'react';
import './actionBar.scss';
import {Dropdown} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import ModalMoveItemToGroup from '../modal/ModalMoveItemToGroup';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ModalMoveItemToSheet from '../modal/ModalMoveItemToSheet';
import {isArray, isEmpty} from 'lodash';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import ModalMoveGroupToSheet from '../modal/ModalMoveGroupToSheet';
import {getSheetAction} from '../../stores/sheetActions';
import ModalCopyToColumn from '../modal/ModalCopyToColumn';
import ModalAddNewTemplate from '../../apps/item-template/ModalAddNewTemplate';
import useSheetTemplateHook from '../../apps/item-template/SheetTemplateHook';
import {hasSheetPermission, sheetActions} from '../../permission/permissionHelper';
import {useSession} from 'core/store/hooks';

const ActionBar = ({
  handleMoveItem,
  handleRemoveItems,
  handleAction,
  sheet,
  view,
  selectedItems,
  selectedItemsData,
  onHide,
  enableActions,
}) => {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {currentWorkspacePages} = useSelector((state) => state.work, shallowEqual);
  const {handleCreateTemplate: onCreateTemplate} = useSheetTemplateHook();
  const {user} = useSession();

  const [showMoveToGroup, setShowMoveToGroup] = useState(false);
  const [showMoveToSheet, setShowMoveToSheet] = useState(false);
  const [showCopyToColumn, setShowCopyToColumn] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalAddTemplate, setShowModalAddTemplate] = useState(false);
  const [moveToSheet, setMoveToSheet] = useState(null);
  const [moveGroup, setMoveGroup] = useState(null);

  const onMoveItemsToGroup = (group) => {
    const columnMapping = {};
    for (let i = 0; i < sheet.columns.length; i++) {
      const column = sheet.columns[i];
      columnMapping[column.field] = column.field;
    }
    handleMoveItem && handleMoveItem(group, sheet, columnMapping);
  };

  const onMoveItemsToSheet = (group, sheet) => {
    dispatch(getSheetAction({id: sheet._id})).then((result) => {
      if (result?.data?.data) {
        let _sheet = result.data.data;
        setMoveToSheet(_sheet);
        setShowMoveToSheet(false);
        setMoveGroup(group);
      }
    });
  };

  const handleMoveToSheet = (columnsMapping) => {
    handleMoveItem && handleMoveItem(moveGroup, moveToSheet, columnsMapping);
  };

  const onRemoveItems = () => {
    handleRemoveItems && handleRemoveItems();
  };

  const handleCopyColumn = (fromColumn, toColumn) => {
    const listItemUpdate = Object.keys(selectedItemsData).reduce((listId, groupKey) => {
      return listId.concat(selectedItemsData[groupKey]);
    }, []);

    const newListCopyItem = listItemUpdate.map((item) => ({
      _id: item._id,
      [toColumn]: item[fromColumn] || null,
    }));

    const params = {
      listCopyItem: newListCopyItem,
      callback: () => {
        onHide();
      },
    };
    handleAction && handleAction('copyColumn', params);
  };

  const handleCreateTemplate = (templates) => {
    onCreateTemplate(templates, () => {
      onHide();
    });
  };

  const totalItemCheck = useMemo(() => {
    if (isArray(selectedItems)) {
      return selectedItems.length;
    }
    if (!isEmpty(selectedItems)) {
      return Object.values(selectedItems).reduce((totalItem, listItem) => {
        return (totalItem += listItem.length);
      }, 0);
    }
    return 0;
  }, [selectedItems]);

  useEffect(() => {
    return () => {
      setShowMoveToGroup(false);
      setShowMoveToSheet(false);
      setShowModalDelete(false);
      setShowCopyToColumn(false);
      setMoveToSheet(null);
      setMoveGroup(null);
    };
  }, []);

  return (
    <>
      <div className='engage-toolbar d-flex position-fixed px-5 zindex-2 mt-20 gap-2 toolbar-sheet'>
        <div className='toolbar px-0 py-0 shadow-lg rounded' id='kt_toolbar'>
          <div id='kt_toolbar_container' className={'d-flex align-items-center'}>
            <div className={'d-flex align-items-center flex-wrap'}>
              <div className='flex-shrink-0'>
                <ul className='nav h-60px'>
                  <li className='nav-item'>
                    <div className='number-task-select bg-primary rounded rounded-end-0 text-white'>
                      <p className='title-task-select mb-0'>{t('toolbar_item_number_select')}</p>
                      <span>{totalItemCheck}</span>
                    </div>
                  </li>
                  {(!enableActions || enableActions.includes('move_item')) && (
                    <li className='nav-item w-70px'>
                      <Dropdown className='dropdown-inline h-100' drop={'up-centered'}>
                        <Dropdown.Toggle
                          id='dropdown-toggle-top'
                          className='w-100 border-0 p-0 h-100'
                          variant='none'
                        >
                          <div
                            className='d-flex flex-column justify-content-center align-items-center h-100 item-action-bar bg-body'>
                            <i className='las la-arrow-circle-right mt-5 fs-1 icon-action-bar'></i>
                            <span className='name-action-bar'>{t('toolbar_move')}</span>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu dropdown-menu-xs  px-2'>
                          {(!enableActions ||
                            enableActions.includes('move_item:move_to_sheet')) && (
                            <Dropdown.Item
                              onClick={() => setShowMoveToSheet(true)}
                              className='rounded'
                            >
                              {t('toolbar_move_to_sheet')}
                            </Dropdown.Item>
                          )}
                          {(!enableActions ||
                            enableActions.includes('move_item:move_to_group')) && (
                            <Dropdown.Item
                              onClick={() => setShowMoveToGroup(true)}
                              className='rounded'
                            >
                              {t('toolbar_move_to_group')}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  )}
                  {(!enableActions || enableActions.includes('remove_item')) && (
                    <li className='nav-item w-70px bg-body'>
                      <div
                        className='remove-toolbar cursor-pointer item-action-bar'
                        onClick={() => setShowModalDelete(true)}
                      >
                        <i className='las la-trash-alt icon-remove-toolbar icon-action-bar mt-2 fs-1'></i>
                        <span className='name-remove-toolbar'>{t('common_delete')}</span>
                      </div>
                    </li>
                  )}
                  {(!enableActions || enableActions.includes('tools')) && (
                    <li className='nav-item w-60px'>
                      <Dropdown className='dropdown-inline h-100' drop={'up-centered'}>
                        <Dropdown.Toggle
                          id='dropdown-toggle-top'
                          className='w-100 border-0 p-0 h-100'
                          variant='none'
                        >
                          <div
                            className='d-flex flex-column justify-content-center align-items-center h-100 item-action-bar bg-body'>
                            <i className='las la-ellipsis-h fs-1'></i>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu dropdown-menu-xs  px-2'>
                          <Dropdown.Item
                            onClick={() => setShowCopyToColumn(true)}
                            className='rounded'
                          >
                            {t('toolbar_copy_to_column')}
                          </Dropdown.Item>
                          {hasSheetPermission(sheet, user, sheetActions.config) && (
                            <Dropdown.Item
                              onClick={() => setShowModalAddTemplate(true)}
                              className='rounded'
                            >
                              {t('toolbar_save_template')}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  )}
                  <li className='nav-item'>
                    <div
                      className='close-toolbar rounded rounded-start-0 cursor-pointer'
                      onClick={onHide}
                    >
                      <i className='las la-times icon-close-toolbar'></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalMoveItemToGroup
        title={t('toolbar_select_group')}
        show={showMoveToGroup}
        onHide={() => setShowMoveToGroup(false)}
        listItemSelect={sheet.groups}
        onMoveItems={onMoveItemsToGroup}
      />
      <ModalMoveItemToSheet
        title={t('toolbar_select_table')}
        show={showMoveToSheet}
        onHide={() => setShowMoveToSheet(false)}
        listItemSelect={currentWorkspacePages?.userPages}
        onMoveItems={onMoveItemsToSheet}
      />
      <ModalCopyToColumn
        title={t('toolbar_select_column')}
        show={showCopyToColumn}
        sheet={sheet}
        view={view}
        onHide={() => setShowCopyToColumn(false)}
        onCopyColumn={handleCopyColumn}
      />
      {selectedItemsData && (
        <ModalAddNewTemplate
          title={t('toolbar_save_template')}
          show={showModalAddTemplate}
          sheet={sheet}
          selectedItemsData={selectedItemsData}
          onHide={() => setShowModalAddTemplate(false)}
          onCreateTemplate={handleCreateTemplate}
        />
      )}
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        title={t('toolbar_remove_items_title')}
        content={t('toolbar_remove_items_content')}
        confirmVariant='danger'
        handleExcute={onRemoveItems}
      />
      {!isEmpty(moveToSheet) && (
        <ModalMoveGroupToSheet
          show={moveToSheet !== null}
          onHide={() => setMoveToSheet(null)}
          onMoveGroup={handleMoveToSheet}
          newSheet={moveToSheet}
          oldSheet={sheet}
        />
      )}
    </>
  );
};

export {ActionBar};
