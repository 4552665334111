import React from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import FormulaField from './FormulaField';
import {Field} from 'formik';
import {CheckBoxField} from 'app/common/_partials/controls';

const CustomAIPrompt = ({className, column, metaColumns}) => {
  const {t} = useTranslate();
  const referenceColumns = metaColumns.filter(
    (item) => !item?.hidden && !item?.deleted && column.field !== item?.field
  );

  return (
    <div className={className}>
      <div className='form-group row mb-2'>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label className='mb-2'>{t('sheet_edit_column_custom_ai_prompt_template')}</label>
        </div>
        <div className='col-md-9'>
          <FormulaField referenceColumns={referenceColumns} name='prompt_template' />
        </div>
      </div>

      <div className='form-group row mb-2'>
        <div className='col-3 d-flex justify-content-end'></div>
        <div className='col-9'>
          <Field
            labelClass='fs-6'
            name='settings.allow_auto_gen_ai'
            component={CheckBoxField}
            autoComplete='off'
            label={t('sheet_edit_column_allow_auto_gen_ai')}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomAIPrompt;
