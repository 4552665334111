import './styles.scss';

import {effectiveValue} from 'app/modules/work/helpers';
import {RichText} from 'app/common/_partials/controls';
import React, {useEffect, useRef, useState} from 'react';
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import {stripTag} from 'app/common/_helpers';
import Spinner from 'app/common/_partials/spinner/Spinner';
import {convert} from 'html-to-text';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';

const CustomAIPromptField = ({
  value,
  item,
  column,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName = '',
  mode = 'inline',
  onAction,
}) => {
  const {t} = useTranslate();
  const effValue = effectiveValue(value);
  const field = column?.field;
  const richTextRef = useRef();
  const snackNotification = useSnackNotification();

  const [content, setContent] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [inlineContent, setInlineContent] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const maxLoadingTime = 120000;

  const onInputFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const saveContent = () => {
    if (content !== effValue) {
      onChange &&
        onChange(item, field, effValue, {value: content, is_manual_edited: !!stripTag(content)});
    }
    setShowDialog(false);
  };

  const onHide = () => {
    setShowDialog(false);
    setContent(effValue);
  };

  const onToggle = () => {
    setShowDialog(!showDialog);
  };

  useEffect(() => {
    setIsProcessing(value?.ai_status?.status === 'in_progress');
  }, [value?.ai_status?.status]);

  useEffect(() => {
    setContent(effValue);
    if (effValue) {
      const plainText = convert(effValue || '');
      setInlineContent(plainText);
    }
  }, [value]);

  useEffect(() => {
    if (showDialog) {
      richTextRef.current?.editor?.focus();
    }
  }, [showDialog]);

  const genAI = () => {
    setIsProcessing(true);
    let params = {
      itemId: item._id,
      ai_fields: [column?.field],
      type: 'custom_ai_prompt',
    };

    onAction(item, params, () => snackNotification.showSuccess(t('sheet_item_gen_ai_message')));
  };

  const renderButtonGenAI = () => {
    return (
      <button
        type='submit'
        className='btn btn-outline btn-outline-primary btn-gen-ai px-4'
        onClick={genAI}
      >
        <OverlayTrigger
          key='title-item'
          placement='top'
          overlay={
            <Tooltip className='tooltip-inverse tooltip-title-item' id='tooltip-title-item'>
              {t('sheet_column_gen_ai')}
            </Tooltip>
          }
        >
          <span className='icon-stars-light'></span>
        </OverlayTrigger>
      </button>
    );
  };
  const renderFooterField = () => {
    return (
      <div className='footer-field '>
        <div className='d-flex justify-content-end border-top p-2 gap-5'>
          <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
            {t('common_cancel')}
          </button>
          {renderButtonGenAI()}
          <button type='submit' className='btn btn-primary btn-elevate' onClick={saveContent}>
            {t('common_save')}
          </button>
        </div>
      </div>
    );
  };

  const renderEditor = (showButtonSave = false) => {
    if (fieldEditable && !readOnly) {
      return (
        <div
          className={`w-100 custom-ai-prompt-field  long-richtext-field-input ${
            isFocus ? 'long-richtext-field-input-active' : 'long-richtext-field-input-inactive'
          }`}
        >
          <Spinner isShowLoading={isProcessing} maxLoadingTime={maxLoadingTime}>
            <div className='position-relative'>
              <RichText
                placeholder={column?.placeholder || ''}
                ref={richTextRef}
                defaultValue={content}
                onBlur={onBlur}
                onChange={setContent}
                className='w-100'
                onFocus={onInputFocus}
              />
              <CopyToClipboard
                text={convert(content)}
                onCopy={(text, result) => {
                  if (result && text && text !== '') {
                    snackNotification.showSuccess(t('sheet.formatter.field.copy_success'));
                  }
                }}
              >
                <div className='btn-copy'>
                  <span className='fs-icon icon-documents-file-copy1'></span>
                </div>
              </CopyToClipboard>
            </div>
            {showButtonSave && item?._id && renderFooterField()}
          </Spinner>
        </div>
      );
    }
    return <div className={'custom-ai-prompt-field'} dangerouslySetInnerHTML={{__html: content}} />;
  };

  if (isProcessing) {
    return <Spinner isShowLoading={isProcessing} maxLoadingTime={maxLoadingTime}></Spinner>;
  }

  if (mode === 'detail' && fieldEditable && !readOnly) {
    return renderEditor(true);
  }

  return (
    <>
      {mode === 'inline' && (
        <>
          <Modal show={showDialog} onHide={onHide} centered size={'xl'} className='dialog-expand'>
            <Modal.Header closeButton>
              <Modal.Title>{column?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderEditor()}</Modal.Body>
            <Modal.Footer>
              <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                {t('common_cancel')}
              </button>
              {fieldEditable && !readOnly && (
                <>
                  {renderButtonGenAI()}
                  <button
                    type='submit'
                    className='btn btn-primary btn-elevate'
                    onClick={saveContent}
                  >
                    {t('common_save')}
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          <div
            className={`custom-ai-prompt-field-inline p-2 d-flex align-items-center justify-content-between ${containerClassName}`}
          >
            <Spinner isShowLoading={isProcessing} maxLoadingTime={maxLoadingTime}>
              <span className='text-ellipsis'>{inlineContent}</span>
            </Spinner>
            <span className='icon-expand m-1 cursor-pointer' onClick={onToggle}></span>
          </div>
        </>
      )}
    </>
  );
};

export default CustomAIPromptField;
