import {useState} from 'react';
import './styles.scss';
import {get, isEqual} from 'lodash';
import {formatPercent} from 'core/helpers/number';
import {effectiveValue} from 'app/modules/work/helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import {renameDuplicates} from 'core/helpers/array';

const BatteryChart = ({
  className,
  widgetHeight = '400px',
  data = null,
  value1Field = null,
  dimensionField = '',
  backgroundColorField='backgroundColor',
  chartId = 'chartdiv',
}) => {
  const [currentItem, setCurrentItem] = useState();
  const {t} = useTranslate();
  const total = data.reduce((accumulator, item) => {
    if (!isNaN(get(item, value1Field))) {
      return accumulator + get(item, value1Field);
    }
    return accumulator;
  }, 0);

  data = data.map((item) => {
    return {
      value: get(item, value1Field),
      name: get(item, dimensionField) || t('sheet_widget_item_other'),
      percent: formatPercent(get(item, value1Field), total),
      backgroundColor: get(item, backgroundColorField),
    };
  });

  const renderLabel = () => {
    const item = currentItem || data[0];
    if (!item) {
      return null;
    }
    return (
      <div className='battery-chart__wrapper__current-label'>
        {item?.percent}% {effectiveValue(item?.name)}
      </div>
    );
  };

  data = renameDuplicates(data, 'name');

  return (
    <div className='battery-chart p-3' id={chartId}>
      <div className='battery-chart__wrapper'>
        <div className='battery-chart__wrapper__main'>
          <div className='battery-chart__wrapper__main__body'>
            {data.map((item, index) => (
              <div
                className={`battery-chart__wrapper__main__body__item ${
                  effectiveValue(item?.name) === currentItem?.name ? 'active' : ''
                }`}
                style={{width: `${item?.percent}%`}}
                onClick={() => setCurrentItem(item)}
                key={index}
              >
                <div
                  className='battery-chart__wrapper__main__body__item__color'
                  style={{backgroundColor: item?.backgroundColor}}
                  data-toggle='tooltip'
                  title={` ${effectiveValue(item?.name)}: ${effectiveValue(item?.value)} (${item?.percent}%)`}
                ></div>
              </div>
            ))}
          </div>
          <div className='battery-chart__wrapper__main__head'></div>
        </div>
        {renderLabel()}
      </div>

      <ul className='battery-chart__note'>
        {data.map((item, index) => {
          return (
            <li key={effectiveValue(item?.value) + '-' + index} className='battery-chart__note__item text-dark'>
              <span
                className='battery-chart__note__color'
                style={{backgroundColor: item.backgroundColor}}
              ></span>{' '}
              {effectiveValue(item?.name)}: {effectiveValue(item?.value)} ({item?.percent}%)
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default BatteryChart;
