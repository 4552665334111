import {GroupSearch} from './components/GanttChartGroupSearch';
import {GanttChartViewSelect} from './components/GanttChartViewSelect';
import {set} from 'lodash';

function WidgetEditViewInnerGantt({editWidget, setEditWidget, handleChange}) {
  const handleGanttChange = (params) => {
    let _editWidget = {...editWidget};
    set(_editWidget, 'data.group', {column: params.value});
    setEditWidget(_editWidget);
  };
  return (
    <>
      <div className='mb-2 fs-3 text-dark'>Nhóm biểu đồ</div>
      <div className='mb-4'>
        <GroupSearch handleGanttChange={handleGanttChange} editWidget={editWidget} />
      </div>
      <div className='mb-2 fs-3 text-dark'>Hiển thị</div>
      <GanttChartViewSelect
        value={editWidget?.chart?.view}
        onChange={(type) => handleChange('chart.view', type)}
      ></GanttChartViewSelect>
    </>
  );
}

export default WidgetEditViewInnerGantt;
