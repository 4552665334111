import {useTranslate} from 'core/i18n/i18nProvider';
import {RichTextField} from 'app/common/_partials/controls';
import {Field, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import './itemDetail.scss';
import {Quill} from 'react-quill';
import isEqual from 'lodash/isEqual';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

export default function ItemContent({item, updateContent, editable}) {
  const {t} = useTranslate();
  const [editActive, setEditActive] = useState(false);
  const tabPressed = useRef();
  const enterPressed = useRef();
  const formRef = useRef();
  const quillRef = useRef();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.editor;
      editor.clipboard.addMatcher('IMG', (node, delta) => {
        const Delta = Quill.import('delta');
        return new Delta().insert('');
      });
      editor.clipboard.addMatcher('PICTURE', (node, delta) => {
        const Delta = Quill.import('delta');
        return new Delta().insert('');
      });
    }
  }, [quillRef.current]);

  const onSave = (values) => {
    updateContent &&
      updateContent(values, () => {
        quillRef.current?.editor?.blur?.();
      });
  };

  const onInputFocus = (range, source, editor) => {
    setEditActive(true);
    setIsFocused(true);
  };

  const onInputBlur = (previousRange, source, editor) => {
    setEditActive(false);
    setIsFocused(false);
    formRef.current?.handleSubmit?.();
  };

  const handleKeydown = (event) => {
    if (event.key === 'Tab') {
      tabPressed.current = true;
    }
    if (event.key === 'Enter') {
      enterPressed.current = true;
    }
    if (tabPressed.current && event.key === 'Enter') {
      event.preventDefault();
    }

    if (tabPressed.current && enterPressed.current && isFocused) {
      const quillInstance = quillRef.current?.getEditor();
      quillInstance.root.blur();
      onInputBlur();
      formRef.current.handleSubmit();
      tabPressed.current = false;
      enterPressed.current = false;
    }
  };

  const handleKeyup = (event) => {
    if (event.key === 'Tab') {
      tabPressed.current = false;
    }
    if (event.key === 'Enter') {
      enterPressed.current = false;
    }
  };

  return (
    <>
      {editable ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{content: item?.content}}
          onSubmit={(values, {resetForm}) => {
            const isSameContent = isEqual(item?.content, values?.content);
            if (!isSameContent) {
              onSave(values);
            }
          }}
        >
          {({handleSubmit}) => {
            return (
              <div
                className={`item-content-input ${
                  editActive ? 'item-content-input-active' : 'item-content-input-inactive'
                }`}
              >
                <Field
                  name='content.value'
                  placeholder={t('sheet_item_content_placeholder')}
                  component={RichTextField}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  withFeedbackLabel
                  customFeedbackLabel
                  innerRef={quillRef}
                  onKeyDown={handleKeydown}
                  onKeyUp={handleKeyup}
                />
                {editActive && (
                  <div className='mt-2 d-flex justify-content-end'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id='button-submit-tooltip' className='tooltip-inverse'>
                          {t('sheet_item_detail_tooltip_button_save')}
                        </Tooltip>
                      }
                    >
                      <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        onClick={handleSubmit}
                      >
                        {t('common_save')}
                      </button>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            );
          }}
        </Formik>
      ) : (
        <div className={`item-content-input item-content-input-active fs-7 rounded`}>
          <div
            dangerouslySetInnerHTML={{
              __html: item?.content?.value,
            }}
          ></div>
        </div>
      )}
    </>
  );
}
