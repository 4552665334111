import moment from 'moment';
import 'moment/locale/vi';

export const secondsToMinuteString = (time) => {
  if (typeof time === 'string') {
    // format time period
    const m = String(Math.floor(time / 60)).padStart(2, '0');
    time %= 60;
    const s = String(time).padStart(2, '0');

    return `${m}:${s}`;
  } else {
    // format time field select
    const minutes = time / 60;
    const h = String(Math.floor(minutes / 60)).padStart(2, '0');
    const m = String(minutes % 60).padStart(2, '0');

    return `${h}:${m}`;
  }
};

export const timeStringToMilliseconds = (timeString) => {
  // Split the time string into hours and minutes
  let [hours, minutes] = timeString.split(':').map(Number);

  // Convert hours and minutes to milliseconds
  let totalMilliseconds = (hours * 3600 + minutes * 60);

  return totalMilliseconds;
}

export const msToTimeAgo = (ts) => {
  moment.locale('vi');
  return moment(moment(ts * 1000).toDate()).fromNow();
};

export const groupSessionsByDate = (sessions) => {
  const sessionsGroupByDate = {
    today: [],
    tomorrow: [],
    dayAfterTomorrow: [],
    leftToday: 0,
    leftTomorrow: 0,
    leftDayAfterTomorrow: 0,
  };

  if (sessions?.length) {
    let dayAfterTomorrowAddedNumber = 2;
    let numberOfSessionAllow = 4;
    const todayTimeStamp = moment().endOf('day').unix();
    const tomorrowTimeStamp = moment().add(1, 'day').endOf('day').unix();
    let dayAfterTomorrowTimeStamp = moment()
      .add(dayAfterTomorrowAddedNumber, 'day')
      .endOf('day')
      .unix();
    for (let i = 0; i < sessions.length; i++) {
      if (sessions[i].start_time_ts < todayTimeStamp) {
        if (sessionsGroupByDate.today.length < numberOfSessionAllow) {
          sessionsGroupByDate.today.push(sessions[i]);
        } else {
          sessionsGroupByDate.leftToday++;
        }
      } else if (
        sessions[i].start_time_ts > todayTimeStamp &&
        sessions[i].start_time_ts < tomorrowTimeStamp
      ) {
        if (sessionsGroupByDate.tomorrow.length < numberOfSessionAllow) {
          sessionsGroupByDate.tomorrow.push(sessions[i]);
        } else {
          sessionsGroupByDate.leftTomorrow++;
        }
      } else if (
        sessions[i].start_time_ts > tomorrowTimeStamp &&
        sessions[i].start_time_ts < dayAfterTomorrowTimeStamp
      ) {
        if (sessionsGroupByDate.dayAfterTomorrow.length < numberOfSessionAllow) {
          sessionsGroupByDate.dayAfterTomorrow.push(sessions[i]);
        } else {
          sessionsGroupByDate.leftDayAfterTomorrow++;
        }
      }
    }
    // Sau ngày mai, nếu các ngày tiếp theo trong tuần không có buổi học nào sắp diễn ra thì không show ra buổi học ngày tiếp theo
    while (dayAfterTomorrowAddedNumber < 7 && sessionsGroupByDate.dayAfterTomorrow.length === 0) {
      dayAfterTomorrowAddedNumber++;
      let dayAfterTomorrowTimeStampHasSession = moment()
        .add(dayAfterTomorrowAddedNumber, 'day')
        .endOf('day')
        .unix();
      for (let i = 0; i < sessions.length; i++) {
        if (
          sessions[i].start_time_ts > tomorrowTimeStamp &&
          sessions[i].start_time_ts < dayAfterTomorrowTimeStampHasSession
        ) {
          if (sessionsGroupByDate.dayAfterTomorrow.length < numberOfSessionAllow) {
            sessionsGroupByDate.dayAfterTomorrow.push(sessions[i]);
          } else {
            sessionsGroupByDate.leftDayAfterTomorrow++;
          }
        }
      }
    }
  }

  return sessionsGroupByDate;
};

export const getMomentDurationByHour = (ts, diffFromEnd = false) => {
  const nowTimeFormat = moment().format('DD-MM-YYYY HH:mm');
  const endTimeFormat = moment(ts * 1000).format('DD-MM-YYYY HH:mm');
  const nowTimeMoment = moment(nowTimeFormat, 'DD-MM-YYYY HH:mm');
  const endTimeMoment = moment(moment(endTimeFormat, 'DD-MM-YYYY HH:mm'));
  return diffFromEnd
    ? moment.duration(endTimeMoment.diff(nowTimeMoment))
    : moment.duration(nowTimeMoment.diff(endTimeMoment));
};

export const getMomentDurationByDay = (ts, diffFromEnd = false) => {
  const nowTimeFormat = moment().format('DD-MM-YYYY');
  const endTimeFormat = moment(ts * 1000).format('DD-MM-YYYY');
  const nowTimeMoment = moment(nowTimeFormat, 'DD-MM-YYYY');
  const endTimeMoment = moment(moment(endTimeFormat, 'DD-MM-YYYY'));
  return diffFromEnd
    ? moment.duration(endTimeMoment.diff(nowTimeMoment))
    : moment.duration(nowTimeMoment.diff(endTimeMoment));
};

export const getTimeAgoFromNow = (ts) => {
  moment.locale('vi');
  return moment(moment(ts * 1000).toDate()).fromNow();
};

export const timeAgoFromDateString = (dt) => {
  moment.locale('vi');
  moment.updateLocale('vi', {
    relativeTime: {
      future: 'trong %s',
      past: '%s trước',
      s: 'vài giây',
      ss: '%d giây',
      m: '1 phút',
      mm: '%d phút',
      h: '1 giờ',
      hh: '%d giờ',
      d: '1 giờ',
      dd: '%d ngày',
      w: '1 tuần',
      ww: '%d tuần',
      M: '1 tháng',
      MM: '%d tháng',
      y: '1 năm',
      yy: '%d năm',
    },
  });
  return moment(dt).fromNow();
};

export const timeAgoFromDateShortString = (dt) => {
  moment.locale('vi');
  moment.updateLocale('vi', {
    relativeTime: {
      future: 'trong %s',
      past: '%s trước',
      s: 'vài giây',
      ss: '%d giây',
      m: '1 phút',
      mm: '%d phút',
      h: '1 giờ',
      hh: '%d giờ',
      d: '1 ngày',
      dd: '%d ngày',
      w: '1 tuần',
      ww: '%d tuần',
      M: '1 tháng',
      MM: '%d tháng',
      y: '1 năm',
      yy: '%d năm',
    },
  });
  return moment(dt).fromNow(true);
};

export function roundTimeToNearestFiveMinutes(timeStr) {
  // Tách giờ và phút từ chuỗi thời gian
  let [hours, minutes] = timeStr.split(':').map(Number);

  // Tính phút đã làm tròn lên 5 phút gần nhất
  minutes = Math.ceil(minutes / 5) * 5;

  // Nếu phút bằng 60, tăng giờ lên 1 và đặt phút thành 0
  if (minutes === 60) {
    minutes = 0;
    hours += 1;
  }

  // Định dạng giờ và phút thành chuỗi có 2 chữ số
  let roundedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

  return roundedTime;
}