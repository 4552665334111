export default function FormConfigItem(props) {
  const {label, fieldComponent} = props;

  return (
    <div className='d-flex align-items-center justify-content-between my-3'>
      <p className='mb-0'>{label}</p>
      {fieldComponent}
    </div>
  );
}
