export const getViewInfoByType = (viewType, t) => {
  let icon;
  let viewName;

  switch (viewType) {
    case 'table':
      icon = <i className='las la-table'></i>;
      viewName = t('sheet_view_type_table');
      break;

    case 'table-paging':
      icon = <i className='las la-th-list'></i>;
      viewName = t('sheet_view_type_table_paging');
      break;

    case 'table-paging-with-subitems':
      icon = <i className='las la-th-list'></i>;
      viewName = t('sheet_view_type_table_paging_with_subitems');
      break;

    case 'list':
      icon = <i className='las la-list-alt'></i>;
      viewName = t('sheet_view_type_list');
      break;

    case 'kanban':
      icon = <i className='las la-columns'></i>;
      viewName = t('sheet_view_type_kanban');
      break;

    case 'dashboard':
      icon = <i className='las la-tachometer-alt'></i>;
      viewName = t('sheet_view_type_dashboard');
      break;

    case 'connected-dashboard':
      icon = <i className='las la-sitemap'></i>;
      viewName = t('sheet_view_type_connected_dashboard');
      break;

    case 'calendar':
      icon = <i className='las la-calendar'></i>;
      viewName = t('sheet_view_type_calendar');
      break;

    default:
      icon = <i className='las la-list-alt'></i>;
      viewName = t('sheet_view_type_list');
      break;
  }

  return {
    icon,
    viewName,
  };
};
