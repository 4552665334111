import React, {useCallback, useEffect} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichText.css';

export const RichText = React.forwardRef((props, ref) => {
  const {
    onChange,
    value,
    defaultValue,
    placeholder,
    modules,
    theme = 'snow',
    style,
    className,
    onBlur,
    onFocus,
    onSave,
  } = props;

  useEffect(() => {
    if (ref?.current) {
      const editor = ref.current.editor;
      editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      });
    }
  }, []);

  const handleEditorBlur = useCallback(() => {
    const content = ref.current?.value;
    onSave?.(content);
  }, [onSave, ref]);

  // Ref: https://github.com/slab/quill/issues/2186#issuecomment-533401328
  useEffect(() => {
    const editorDomNode = ref?.current?.editor?.scroll?.domNode;
    editorDomNode?.addEventListener('blur', handleEditorBlur);

    return () => {
      editorDomNode?.removeEventListener('blur', handleEditorBlur);
    };
  }, [handleEditorBlur, ref]);

  return (
    <>
      <ReactQuill
        ref={ref}
        theme={theme}
        onChange={onChange}
        onBlur={onBlur}
        value={value || defaultValue}
        defaultValue={defaultValue}
        placeholder={placeholder}
        modules={modules}
        style={style}
        className={className}
        onFocus={onFocus}
      />
    </>
  );
});
