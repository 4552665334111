import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import TemplatePreview from 'app/modules/work/sheet/apps/item-template/TemplatePreview';
import {
  getTemplateBySheetAction,
  removeTemplateAction,
  updateTemplateAction,
} from 'app/modules/work/sheet/stores/template/templateActions';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import {format} from 'date-fns'

function ModalManagerTemplate({show, onHide, title, sheetId}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const [templates, setTemplates] = useState([]);
  const [selectedItemTemplate, setSelectedItemTemplate] = useState(null);
  const [removeTemplateId, setRemoveTemplateId] = useState(null);
  const [nameTemplate, setNameTemplate] = useState('');
  const [saveTemplate, setSaveTemplate] = useState(true)

  const fetchTemplates = () => {
    dispatch(getTemplateBySheetAction(sheetId)).then((res) => {
      if (res?.data?.data) {
        setTemplates(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (sheetId) {
      fetchTemplates();
    }
  }, [sheetId]);

  const handleToggleTemplate = (template) => {
    if (selectedItemTemplate) {
      setSelectedItemTemplate(null);
    } else {
      setSelectedItemTemplate(template);
      setNameTemplate(template.name);
    }
  };

  const handleUpdateNameTemplate = (e) => {
    const templateId = selectedItemTemplate._id;
    const value = e.target.value;
    const params = {
      name: value,
    };
    dispatch(updateTemplateAction(templateId, params)).then((res) => {
      if (res.data.success) {
        fetchTemplates();
        setSaveTemplate(true)
      }
    });
  };

  const handleRemoveTemplate = () => {
    dispatch(removeTemplateAction(removeTemplateId)).then((res) => {
      if (res.data.success) {
        snackNotification.showSuccess(t('common_delete_success'));
        setRemoveTemplateId(null);
        fetchTemplates()
      } else {
        snackNotification.showError(t('common_delete_error'));
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        centered
        className={clsx({'d-none': removeTemplateId})}
      >
        <Modal.Body>
          <div className='fs-5 fw-bold'>{title || ''}</div>
          <div className='row mt-4'>
            {selectedItemTemplate ? (
              <div className={clsx('rounded bg-light')}>
                <div
                  className='d-flex align-items-center border rounded p-2 cursor-pointer bg-body mt-2'
                  onClick={() => handleToggleTemplate(selectedItemTemplate)}
                >
                  <input
                    className='w-100 border-0 bg-body text-dark'
                    type='text'
                    onClick={(e) => e.stopPropagation()}
                    defaultValue={nameTemplate}
                    onChange={() => setSaveTemplate(false)}
                    onBlur={handleUpdateNameTemplate}
                  />
                  <span className='w-40px text-center'>
                    {saveTemplate ? (
                      <i className='las la-check text-success'></i>
                    ) : (
                      <i className='fa-solid fa-spinner fa-spin'></i>
                    )}
                  </span>
                </div>
                <div className=' overflow-scroll mt-2 bg-body rounded mb-2'>
                  <TemplatePreview
                    items={selectedItemTemplate.template.items}
                    columns={selectedItemTemplate?.meta?.columns}
                    subItemsColumns={
                      selectedItemTemplate?.meta?.subItemColumns?.length > 0
                        ? selectedItemTemplate?.meta?.subItemColumns
                        : selectedItemTemplate?.meta?.columns
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div className='d-flex rounded bg-secondary py-2'>
                  <div className='col-6 fw-bold'>{t('template_manager_name')}</div>
                  <div className='col fw-bold'>{t('template_manager_create_by')}</div>
                  <div className='col text-center fw-bold'>{t('template_manager_create_at')}</div>
                  <div className='col text-end fw-bold'>{t('template_manager_action')}</div>
                </div>
                {templates.map((template) => {
                  return (
                    <div
                      className={clsx('rounded d-flex h-40px border  border-secondary mt-3', {
                        'bg-light': selectedItemTemplate === template._id,
                      })}
                    >
                      <div className='col-6 d-flex align-items-center p-2'>
                        <span className='w-100'>{template.name}</span>
                      </div>
                      <div className='col d-flex align-items-center'>
                        {template?.created_by?.name || ''}
                      </div>
                      <div className='col d-flex align-items-center justify-content-center'>
                        {template?.created_at
                          ? format(new Date(template?.created_at), 'dd/MM/yyyy')
                          : ''}
                      </div>
                      <div className='col d-flex align-items-center justify-content-end'>
                        <span className='ms-3' onClick={() => handleToggleTemplate(template)}>
                          <i className='las la-edit text-dark fs-4'></i>
                        </span>
                        <span className='ms-3' onClick={() => setRemoveTemplateId(template._id)}>
                          <i className='las la-trash text-dark fs-4'></i>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {selectedItemTemplate && (
            <Modal.Footer className='p-0 mt-4 border-0'>
              <button
                type='button'
                className='btn btn-light btn-elevate btn-secondary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
                onClick={() => setSelectedItemTemplate(null)}
              >
                {t('common_cancel')}
              </button>
            </Modal.Footer>
          )}
        </Modal.Body>
      </Modal>
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={!!removeTemplateId}
        onHide={() => setRemoveTemplateId(null)}
        title={t('template_remove_title')}
        content={t('template_remove_content')}
        confirmVariant='danger'
        handleExcute={handleRemoveTemplate}
      />
    </>
  );
}

export default ModalManagerTemplate;
