export const requestGeoLocation = (t, showError) =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geoLocation = {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
            timestamp: position?.timestamp,
          };

          resolve(geoLocation);
        },
        (error) => {
          showError(t('sheet_table_geolocation_user_denied'));
          reject(error);
        }
      );
    } else {
      showError(t('sheet_table_geolocation_browser_not_support'));
      reject(new Error("User's browser doesn't support geolocation!"));
    }
  });
