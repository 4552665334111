import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {searchPeopleAction, searchTeamsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {effectiveValue} from 'app/modules/work/helpers';
import './peopleValue.scss';
import AsyncSelect from 'react-select/async';

function PeopleValue({value, item, column, sheet, onChange, disabled}) {
  const currentPeople = useMemo(() => {
    let peoples = effectiveValue(value) ?? [];
    return peoples.map((people) => {
      return {
        label: people.name,
        value: people._id,
      };
    });
  }, [value]);
  const dispatch = useDispatch();

  const search = async (text) => {
    const people = await dispatch(searchPeopleAction({filter: {q: text}}));
    const teams = await dispatch(searchTeamsAction({filter: {q: text}}));
    const teamOption = teams.map((team) => {
      return {...team, type: 'team'};
    });
    let newPeopleOptions = [...people, ...teamOption];
    newPeopleOptions = newPeopleOptions.map((people) => {
      return {
        value: people._id,
        label: people.name,
        type: people.type ?? 'user',
      };
    });
    return newPeopleOptions;
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(search(inputValue));
      }, 100);
    });

  const onChangeSelect = (selectedOptions) => {
    const newPeoples = selectedOptions.map((people) => ({
      name: people.label,
      id: people.value,
      _id: people.value,
    }));
    onChange({value: newPeoples});
  };

  const renderOptionLabel = (option) => {
    return (
      <div className='d-flex gap-2 align-items-center'>
        {option?.type === 'team' ? (
          <i className='la la-user-friends' />
        ) : (
          <i className='la la-user' />
        )}
        <span>{option?.label}</span>
      </div>
    );
  };

  return (
    <AsyncSelect
      isDisabled={disabled}
      formatOptionLabel={renderOptionLabel}
      value={currentPeople}
      className='w-100'
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      onChange={onChangeSelect}
    />
  );
}

export default PeopleValue;
