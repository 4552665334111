import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useState} from 'react';

const SheetDataViewListViewAction = ({onToggleViewList}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const {t} = useTranslate();
  const handleFindInView = (searchText) => {
    if (searchText && searchText !== '') {
      window.find(searchText);
    }
  };

  return (
    <div className='d-flex gap-2 align-items-center'>
      <div
        className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'
        onClick={onToggleViewList}
      >
        <i className='la la-list-ul' />
        <span>{t('sheet_view_list')}</span>
      </div>
      <i
        className='la la-search cursor-pointer'
        onClick={() => setShowSearchInput(!showSearchInput)}
      />
      {showSearchInput && (
        <input
          className='form-control form-control-sm w-150px bg-white'
          placeholder={t('sheet_view_tool_find_in_view')}
          onBlur={(e) => handleFindInView(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleFindInView(e.target.value);
            }
          }}
        />
      )}
    </div>
  );
};

export default SheetDataViewListViewAction;
