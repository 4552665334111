import { useSelector } from "react-redux";

export const useSession = () => {
  const user = useSelector((state) => state.auth.info);

  const getDomain = () => {
    if (window.APP_DOMAIN && window.APP_DOMAIN !== '') {
      return window.APP_DOMAIN;
    } else {
      var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
      if (subdomain) {
        return subdomain;
      } else {
        return '';
      }
    }
  }

  
  return {getDomain, user  }

}