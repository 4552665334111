/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {Gantt, ViewMode} from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import moment from 'moment';
import './ganttChart.scss'
import {format} from 'date-fns';
import {vi} from 'date-fns/locale';

export function GanttChart({data, mode, widget, onUpdateItem}) {
  const [view, setView] = useState(ViewMode.Week);
  const [tasks, setTasks] = useState(data);

  useEffect(() => {
    setTasks(data);
  }, [data]);

  useEffect(() => {
    setView(widget.chart.view);
  }, [widget.chart.view]);

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.column === 'date') {
      const oldValue = task.item[task.field].value;
      const newValue = moment(task.start).unix();
      if (oldValue !== newValue) {
        onUpdateItem(task.item, task.field, oldValue, newValue);
      }
    }
    if (task.column === 'timeline') {
      const startTime = moment(task.start).unix();
      const endTime = moment(task.end).unix();
      const oldValue = task.item[task.field].value;
      if (oldValue.start !== startTime || oldValue.finish !== endTime) {
        onUpdateItem(task.item, task.field, null, {start: startTime, finish: endTime});
      }
    }
    setTasks(newTasks);
  };

  const cellWidth = () => {
    if (mode === 'view') {
      return '';
    }
    if (widget.chart.width !== 12) {
      return '50px';
    }
    return '130px';
  };

  const TaskListTable = ({tasks, rowWidth, rowHeight, onExpanderClick, handleAddTask}) => {
    const getDateTimeLine = (start, end) => {
      if (new Date(start).valueOf() === new Date(end).valueOf()) {
        return format(start, 'd MMM', {locale: vi});
      }else{
        return (
          format(start, 'd MMM', {locale: vi}) +
          ' - ' +
          format(end, 'd MMM', {locale: vi})
        );
      }
    };
    return (
      <div style={{border: '1px solid #dfe1e5'}}>
        {tasks.map((item, i) => {
          const isProject = item.type === 'project';
          return (
            <div
              key={item.id}
            className='d-flex align-items-center justify-content-between'
              style={{
                height: rowHeight,
                cursor: isProject ? 'pointer' : 'auto',
                borderTop: isProject ? '1px solid #dfe1e5' : '',
                paddingLeft: isProject ? 13 : 30,
              }}
            >
              <p
                onClick={() => onExpanderClick(item)}
                className='d-flex align-items-center m-0 w-100'
              >
                {isProject ? <i className='fa-solid fa-angle-right me-3'></i> : ''}
                <span className=''>{item.name}</span>
                <span className='ms-auto me-4'> {getDateTimeLine(item.start, item.end)}</span>
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className=' flex-grow-1 overflow-scroll gannt-chart p-3'>
      <Gantt
        locale='vi'
        tasks={tasks.length ? tasks : tasksData}
        viewMode={view}
        rowHeight={35}
        fontSize={13}
        todayColor='#FDFCF1'
        listCellWidth={cellWidth()}
        onDateChange={handleTaskChange}
        columnWidth={columnWidth}
        TaskListTable={(props) => <TaskListTable {...props} />}
        TaskListHeader={({headerHeight}) => (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{
              height: headerHeight,
              width: 390,
            }}
          >
            <div></div>
          </div>
        )}
      />
    </div>
  );
}

  const tasksData = [
    {
      start: new Date(),
      end: new Date(),
      id: 'id',
      type: 'task',
      name: '',
    },
  ];