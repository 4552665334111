import { getDepartmentsAction } from 'app/modules/work/sheet/stores/sheetActions';
import { useEffect, useState } from 'react';
import { ToggleButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export default function SheetDepartmentFilter({sheet, filter, filterValue, column, applyFilter}) {
  const [values, setValues] = useState([]);

  useEffect(() => {
    if(filterValue && filterValue?.value && filterValue?.value?.in){
      setValues(filterValue?.value?.in)
    }else{
      setValues([])
    }
  },[filterValue])

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);

    applyFilter && applyFilter(filter, {in: newValues});
  };

  const dispatch = useDispatch();
  const [teamOptions, setTeamOptions] = useState([]);

  const getOrganization = (searchText) => {
    const params = {
      paging: {
        pageNumber: 1,
        pageSize: 500,
      },
    };
    dispatch(getDepartmentsAction(params)).then((res) => {
      setTeamOptions(res.data);
    });
  };

  useEffect(() => {
    getOrganization();
  }, []);

  return (
    <>
      <div className='d-flex flex-column align-items-stretch '>
        {teamOptions?.map((value) => (
          <ToggleButton
            id={`toggle-people-${column.field}-${value._id}`}
            key={`toggle-people-${column.field}-${value._id}`}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 d-flex align-items-center text-start text-nowrap ${
              filterValue?.value?.in?.includes(value._id) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            checked={filterValue?.value.in?.includes(value._id)}
            value='1'
            onChange={(e) => onToggleValue(value._id)}
          >
            {value?.name}
          </ToggleButton>
        ))}
      </div>
    </>
  );
}
