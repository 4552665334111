import {Fragment} from 'react';
import ItemField from '../ItemField';
import {effectiveValue} from 'app/modules/work/helpers';

export default function ItemDetailLinkedField({
  item,
  columns,
  sheet,
  hiddenColumnsType,
  hiddenColumnsField,
  onEditLinkedItem,
}) {
  const title = effectiveValue(item.title);
  return (
    <div
      className='border rounded item-linked-detail py-2 px-3 cursor-pointer min-h-100px'
      onClick={() => onEditLinkedItem?.(item?._id)}
    >
      <div className='title fw-bold fs-5'>{title || item?._id}</div>
      <div className='d-flex gap-2 overflow-auto w-100' onClick={(e) => e.preventDefault()}>
        {columns.map((column) => {
          if (
            !hiddenColumnsType?.includes(column.type) &&
            !hiddenColumnsField?.includes(column.field)
          ) {
            return (
              <div key={column.field} className='w-150px mtt-4 flex-shrink-0'>
                <div className='text-start fs-7 p-2 text-capitalize'>{column.name}</div>
                <div className='mt-1 item-detail-field px-2'>
                  <ItemField
                    displayMode='drawer'
                    value={item[column.field]}
                    item={item}
                    column={{...column, display_mode: 'avatar'}}
                    sheet={sheet}
                    fieldEditable={false}
                    containerClassName={'p-0 text-start item-detail-field'}
                  />
                </div>
              </div>
            );
          }
          return <Fragment key={column.field} />;
        })}
      </div>
    </div>
  );
}
