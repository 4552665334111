import {isArray} from 'lodash';
import {useDispatch} from 'react-redux';
import {downloadLinkFileAction, uploadFileAction} from 'app/modules/work/sheet/stores/sheetActions';
import clsx from 'clsx';
import {
  Badge,
  Button,
  Dropdown,
  Image,
  Modal,
  ModalBody,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import React, {useEffect, useRef, useState} from 'react';
import {ItemFileAttachmentViewer} from '../../item-detail/ItemFileAttachmentViewer';
import {newId} from 'app/common/_helpers/idHelpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import './fileField.scss';
import {effectiveValue} from 'app/modules/work/helpers';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import Spinner from 'app/common/_partials/spinner/Spinner';

const imageTypes = ['jpg', 'jpeg', 'png'];
const videoTypes = ['mp4', 'mpg'];
const audioTypes = ['mp3'];
const pdfTypes = ['pdf'];
const documentTypes = ['doc', 'docx', 'txt'];
const excelTypes = ['xls', 'xlsx'];
const powerpoint = ['pptx', 'ppt'];
const zip = ['zip', 'rar'];
const txt = ['txt'];
const json = ['json'];

function FileField({value, item, column, mode, onChange, fieldEditable, readOnly, containerClass}) {
  const field = column?.field;
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const isDetailViewMode = mode === 'detail';
  const currentFiles = effectiveValue(value) || [];
  const inputUploadFile = useRef();
  const widthHidden = useRef(0);
  const [previewFile, setPreviewFile] = useState(null);
  const [showModalAddLink, setShowModalAddLink] = useState(false);
  const [fileRemove, setFileRemove] = useState(null);
  const [linkDownload, setLinkDownload] = useState(0);
  const widthIconFile = 21;
  const numberFileHidden = Math.floor(widthHidden.current / widthIconFile);
  const [loading, setLoading] = useState(false);

  const onAddItemFile = (file) => {
    setLoading(true);
    const uploadFileParams = {
      files: file,
    };
    dispatch(uploadFileAction(uploadFileParams)).then((result) => {
      let newValues = [];
      if (currentFiles && isArray(currentFiles)) {
        newValues = [...currentFiles, ...result];
      } else {
        newValues.push(result);
      }
      onChange(item, field, value, {value: newValues});
      setLoading(false);
    });
  };

  const onUploadFile = (e) => {
    const files = e.target.files;
    if (!files) return;
    onAddItemFile(files);
    e.target.value = null;
  };

  const onShowModalRemoveFile = (idFileRemove) => {
    setFileRemove(idFileRemove);
  };

  const onRemoveFile = () => {
    const newListFile = currentFiles?.filter((file) => {
      return file?._id !== fileRemove;
    });
    onChange(item, field, value, {value: newListFile});
    setFileRemove(null);
  };

  const createFileFromLink = (value) => {
    let newValues = [];
    const newFile = {type: 'link', value: value.value, label: value.label, _id: newId()};
    if (currentFiles && isArray(currentFiles)) {
      newValues = [...currentFiles, newFile];
    } else {
      newValues.push(newFile);
    }
    onChange(item, field, value, {value: newValues});
  };

  const handleOpenLink = (link) => {
    if (link) {
      let newTab = window.open();
      if (!link.startsWith('https://')) {
        newTab.location.href = `https://${link}`;
      } else {
        newTab.location.href = link;
      }
    }
  };

  const handlePreviewFile = (file) => {
    if (file.type === 'link') {
      handleOpenLink(file.value);
      return;
    }
    setPreviewFile(file);
  };

  useEffect(() => {
    if (previewFile?._id) {
      const params = {
        'get-content': true,
      };
      dispatch(downloadLinkFileAction(previewFile._id, params)).then((res) => {
        if (res.data?.link) {
          setLinkDownload(res.data?.link);
        }
      });
    }
  }, [previewFile, dispatch]);

  const popover = () => {
    if (loading) return <></>;
    return (
      <div className='p-4 mw-400px'>
        {currentFiles?.length > 0 && (
          <div className='mb-2'>
            {isArray(currentFiles) &&
              currentFiles?.flatMap((file, index) => {
                if (file) {
                  return (
                    <Badge
                      pill
                      key={file?._id || index}
                      bg='light'
                      className='m-1 p-1 align-items-center align-middle'
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreviewFile({
                          ...file,
                          display_name: file?.original_name || file?.label,
                        });
                      }}
                    >
                      {getIconFile(file?.extension || file?.type, file)}
                      <span className='ms-1 text-dark h-100'>
                        {file?.original_name || file?.label}
                        {!file?.extension ? '' : `.${file?.extension}`}
                      </span>
                      {fieldEditable && (
                        <button
                          className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                          onClick={(e) => {
                            e.stopPropagation();
                            onShowModalRemoveFile(file?._id);
                          }}
                        >
                          <i className='las la-times text-muted'></i>
                        </button>
                      )}
                    </Badge>
                  );
                } else {
                  return [];
                }
              })}
          </div>
        )}
        {fieldEditable && !readOnly && (
          <div className=''>
            <Dropdown.Item
              size='xs'
              className='dropdown-item-xs d-flex align-items-center py-2 rounded'
              onClick={(e) => {
                inputUploadFile?.current?.click();
              }}
            >
              <div className='position-relative w-20px h-15px'>
                <i className='las la-paperclip me-2 fs-5 position-absolute'></i>
              </div>
              {t('sheet_column_file_up_from_computer')}
            </Dropdown.Item>
            <Dropdown.Item
              size='xs'
              className='dropdown-item-xs d-flex align-items-center py-2 rounded'
              onClick={(e) => {
                setShowModalAddLink(true);
              }}
            >
              <div className='position-relative w-20px h-15px'>
                <i className='las la-link me-2 fs-5 position-absolute'></i>
              </div>
              {t('sheet_column_file_up_from_link')}
            </Dropdown.Item>
          </div>
        )}
      </div>
    );
  };

  const previewFilePopover = (file) => {
    if (!file) return <></>;
    if (file?.type === 'link') {
      return (
        <div className='bg-info preview-file-popover rounded'>
          {getIconFile(file?.extension || file?.type, file)}
        </div>
      );
    }
    if (imageTypes.includes(file.extension)) {
      return (
        <div className='bg-info preview-file-popover rounded'>
          <Image
            className='img-fluid image-file-preview rounded h-100'
            src={file.url || file.link}
          />
        </div>
      );
    }
    if (pdfTypes.includes(file.extension)) {
      return (
        <div className='bg-danger preview-file-popover rounded'>
          {getIconFile(file?.extension || file?.type, file)}
        </div>
      );
    }
    if (zip.includes(file.extension)) {
      return (
        <div className='bg-warning preview-file-popover rounded'>
          {getIconFile(file?.extension || file?.type, file)}
        </div>
      );
    }
    return (
      <div className='bg-success preview-file-popover rounded'>
        {getIconFile(file?.extension || file?.type, file)}
      </div>
    );
  };

  const popoverPreviewFile = (file) => {
    return (
      <Popover id='popover-column-file'>
        <Popover.Body>
          <div className='min-h-200px w-250px'>{previewFilePopover(file)}</div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <DropdownPopover renderPopover={popover} triggerClassName={'min-h-30px'}>
        <div
          className='w-100 h-100 border-0 p-0 position-relative button-dropdown-link overflow-hidden file-container'
          variant='none'
        >
          <div
            className={`d-flex align-items-center h-100 cursor-pointer w-100 gap-2 file-item min-h-30px ${clsx(
              {
                'justify-content-start': currentFiles?.length > 0,
                'justify-content-center': !currentFiles?.length,
              }
            )} ${containerClass}`}
          >
            {fieldEditable && !loading && (
              <div className='add-item-file-field cursor-pointer'>
                {isDetailViewMode && !currentFiles?.length && column?.placeholder ? (
                  <span className='ps-1 text-gray-500'>{column?.placeholder || ''}</span>
                ) : (
                  <i className={`las la-file-medical text-primary fs-2 icon-file-item`}></i>
                )}
              </div>
            )}
            {loading && (
              <div className='loading-file-item'>
                <Spinner isShowLoading={loading} maxLoadingTime={10000000}></Spinner>
              </div>
            )}
            <div
              className='d-flex list-current-file'
              ref={(e) => {
                widthHidden.current = e?.scrollWidth - e?.offsetWidth;
                if (e?.scrollWidth > e?.offsetWidth) {
                  e?.style &&
                    Object.assign(e?.style, {
                      justifyContent: 'start',
                      paddingLeft: '16px',
                    });
                } else {
                  e?.style &&
                    Object.assign(e?.style, {
                      justifyContent: 'center',
                      paddingLeft: 0,
                    });
                }
              }}
            >
              {currentFiles &&
                currentFiles?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className='d-flex align-items-center'
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreviewFile({
                          ...item,
                          display_name: item?.original_name || item?.label,
                        });
                      }}
                    >
                      <OverlayTrigger placement='top' overlay={popoverPreviewFile(item)}>
                        <div>{getIconFile(item?.extension || item?.type, item)}</div>
                      </OverlayTrigger>
                    </div>
                  );
                })}
            </div>
            {numberFileHidden ? (
              <div className='number-file-hidden bg-dark text-white'>{numberFileHidden + 1}</div>
            ) : null}
          </div>
        </div>
      </DropdownPopover>
      <input
        ref={inputUploadFile}
        accept='image/*,video/*,audio/*,.doc,.docx,.pdf,.xlsx,.xls,.ppt,.pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.zip,.rar,.7zip,.json'
        type='file'
        className='d-none'
        multiple
        onChange={onUploadFile}
      ></input>
      <ModalPreviewFile
        show={previewFile !== null}
        onHide={() => setPreviewFile(null)}
        linkDownload={linkDownload}
        currentFiles={currentFiles}
      />
      {showModalAddLink && (
        <ModalAddLinkFile
          show={showModalAddLink}
          onHide={() => setShowModalAddLink(false)}
          onCreateFileFromLink={createFileFromLink}
        />
      )}
      {fileRemove && (
        <ModalConfirm
          style={{zIndex: 10000}}
          show={!!fileRemove}
          onHide={() => setFileRemove(null)}
          title={'Xoá file'}
          content={'Bạn vẫn muốn xoá file này?'}
          handleExcute={onRemoveFile}
        />
      )}
    </>
  );
}

const getIconFile = (type, file, className) => {
  if (type === 'link') {
    return (
      <i
        className={`las la-link icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (imageTypes?.includes(type)) {
    return (
      <>
        <div className={`rounded overflow-hidden w-25px h-25px file-preview-wrapper ${className}`}>
          <Image className='img-fluid image-file-preview' src={file?.url || file?.link} />
        </div>
      </>
    );
  }
  if (videoTypes?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-video icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (audioTypes?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-audio icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (pdfTypes?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-pdf icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (documentTypes?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-word icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (excelTypes?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-excel icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (powerpoint?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-powerpoint icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (zip?.includes(type)) {
    return (
      <i
        className={`las la-file-archive icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (txt?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-lines icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
  if (json?.includes(type)) {
    return (
      <i
        className={`fa-regular fa-file-code icon-thumb rounded fs-2 w-25px h-25px d-flex align-items-center justify-content-center bg-secondary ${className}`}
      ></i>
    );
  }
};

const ModalAddLinkFile = ({show, onHide, onCreateFileFromLink}) => {
  const {t} = useTranslate();
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');

  const onSaveLinkFile = () => {
    onCreateFileFromLink({value: value, label: label});
    onHide();
  };

  return (
    <Modal className='sheet-item-detail-file-viewer-dialog' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('sheet_column_file_up_from_link_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center'>
          <img src='https://cdn.monday.com/images/file-as-link/link-choosing-asset.svg' alt='' />
        </div>
        <div className='mt-2 fw-bold'>{t('sheet_column_file_up_from_link_any')}</div>
        <input
          type='text'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder='Ví dụ. pdf, figma, andobe...'
          className='form-control mt-2'
        />
        <div className='mt-2 fw-bold'>{t('sheet_column_file_link_display')}</div>
        <input
          type='text'
          value={label}
          placeholder='Ví dụ. Thiết kế file v1'
          onChange={(e) => setLabel(e.target.value)}
          className='form-control mt-2'
        />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <Button variant={'primary'} onClick={onSaveLinkFile}>
          {t('common_save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalPreviewFile = ({show, onHide, linkDownload, currentFiles}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const files = currentFiles || [];

  const handlePrevFile = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : files.length - 1));
  };

  const handleNextFile = () => {
    setCurrentIndex((prev) => (prev < files.length - 1 ? prev + 1 : 0));
  };

  return (
    <Modal className='sheet-item-detail-file-viewer-dialog' show={show} onHide={onHide} size='xl'>
      <ModalBody className='p-0'>
        {files?.length > 0 && (
          <div className='d-flex flex-column mb-3'>
            <div className='flex-grow-1'>
              <ItemFileAttachmentViewer
                currentFile={{...files[currentIndex], link: linkDownload}}
              />
            </div>
            <div className='file-list-sidebar d-flex m-auto px-3'>
              {files.map((file, index) => (
                <div
                  key={file?._id}
                  className={`p-1 cursor-pointer hover-bg-light flex-shrink-0 rounded gap-4 ${
                    index === currentIndex ? 'bg-primary' : ''
                  }`}
                  onClick={() => setCurrentIndex(index)}
                >
                  <div className='d-flex align-items-center'>
                    {getIconFile(file?.extension || file?.type, file, 'file-thumb-preview')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 1 && (
          <div className='position-absolute top-50 start-0 translate-middle-y w-100 d-flex justify-content-between px-3'>
            <Button
              variant='secondary'
              className='rounded-circle p-2 d-flex align-items-center justify-content-center h-40px w-40px'
              onClick={handlePrevFile}
            >
              <i className='las la-angle-left fs-2'></i>
            </Button>
            <Button
              variant='secondary'
              className='rounded-circle p-2 d-flex align-items-center justify-content-center h-40px w-40px'
              onClick={handleNextFile}
            >
              <i className='las la-angle-right fs-2'></i>
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FileField;
