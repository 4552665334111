import {effectiveValue} from 'app/modules/work/helpers';
import './longTextField.scss';
import {RichText} from 'app/common/_partials/controls';
import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import {stripTags} from 'core/helpers/string';
import CopyToClipboard from 'react-copy-to-clipboard';
import {convert} from 'html-to-text';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {on} from 'events';
function LongTextField({
  value,
  item,
  column,
  onChange,
  readOnly,
  fieldEditable,
  containerClassName = '',
  mode = 'inline',
}) {
  const {t} = useTranslate();
  const effValue = effectiveValue(value);
  const field = column?.field;
  const canEditable = fieldEditable && !readOnly;

  const [content, setContent] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [inlineContent, setInlineContent] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const snackNotification = useSnackNotification();
  const richTextRef = useRef();
  const onInputFocus = () => {
    setIsFocus(true);
  };

  const onBlur = (event, source) => {
    if (source === 'silent') return;

    setIsFocus(false);
    saveContent();
  };

  const onUpdateContent = (newContent) => {
    if (content !== effValue) {
      onChange?.(item, field, effValue, {value: newContent});
    }
  };

  const saveContent = () => {
    if (content !== effValue) {
      onChange && onChange(item, field, effValue, {value: content});
    }
    setShowDialog(false);
  };

  const onHide = () => {
    setShowDialog(false);
    setContent(effValue);
  };

  const onToggle = () => {
    setShowDialog(!showDialog);
  };

  const renderEditor = (mode) => {
    let onRichTextBlur, onRichTextSave;

    if (mode !== 'modal') {
      onRichTextBlur = onBlur;
      onRichTextSave = onUpdateContent;
    }

    if (canEditable) {
      return (
        <div
          className={`w-100 long-richtext-field-input ${
            isFocus ? 'long-richtext-field-input-active' : 'long-richtext-field-input-inactive'
          }`}
        >
          <CopyToClipboard
            text={convert(content)}
            onCopy={(text, result) => {
              if (result && text && text !== '') {
                snackNotification.showSuccess(t('sheet.formatter.field.copy_success'));
              }
            }}
          >
            <div className='btn-copy'>
              <span className='fs-icon icon-documents-file-copy1'></span>
            </div>
          </CopyToClipboard>
          <RichText
            ref={richTextRef}
            className='w-100'
            defaultValue={content}
            onBlur={onRichTextBlur}
            onChange={setContent}
            onFocus={onInputFocus}
            onSave={onRichTextSave}
            placeholder={column?.placeholder || ''}
          />
        </div>
      );
    }
    return <div className={'custom-ai-prompt-field'} dangerouslySetInnerHTML={{__html: content}} />;
  };

  useEffect(() => {
    if (showDialog) {
      richTextRef.current?.editor?.focus();
    }
  }, [showDialog]);

  useEffect(() => {
    setContent(effValue);
    const plainText = stripTags(effValue) || '';
    setInlineContent(plainText?.replace(/&nbsp;/g, ''));
  }, [effValue]);

  if (mode === 'detail') {
    return renderEditor();
  }

  return (
    <>
      {mode === 'inline' && (
        <>
          <Modal show={showDialog} onHide={onHide} centered size={'xl'}>
            <Modal.Header closeButton>
              <Modal.Title>{column?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderEditor('modal')}</Modal.Body>
            <Modal.Footer>
              <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                {t('common_cancel')}
              </button>
              {canEditable && (
                <button type='submit' className='btn btn-primary btn-elevate' onClick={saveContent}>
                  {t('common_save')}
                </button>
              )}
            </Modal.Footer>
          </Modal>
          <div
            className={`long-richtext-field-inline p-2 d-flex align-items-center justify-content-between ${containerClassName}`}
          >
            <span className='text-ellipsis'>{inlineContent}</span>
            <span className='icon-expand cursor-pointer' onClick={onToggle}></span>
          </div>
        </>
      )}
    </>
  );
}

export default LongTextField;
