import {postApiV2, putApiV2} from 'core/store/api';

const endPoints = {
  searchPeople: '/api/user/search',
  searchNotification: '/api/notification/search',
  updateNotification: (notificationId) => `/api/notification/${notificationId}`,
};

export function searchPeople(params) {
  return postApiV2(endPoints.searchPeople, params);
}

export function searchNotification(params) {
  return postApiV2(endPoints.searchNotification, params);
}

export function updateNotification(params) {
  return putApiV2(endPoints.updateNotification(params.id), params);
}