import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  joinLoading: false,
  actionsLoading: false,
  error: null,
};
export const callTypes = {
  list: "list",
  join: "join",
  action: "action",
};

export const meetingSlice = createSlice({
  name: "meeting",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }
      if (action.payload.callType === callTypes.join) {
        state.joinLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.join) {
        state.joinLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.join) {
        state.joinLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    meetingsFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.meetings = data.data;
    },
    staffsFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.staffs = data.result;
    },
  },
});
