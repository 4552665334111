import React, { useEffect } from 'react'
import {useField, useFormikContext} from 'formik'
import './SelectSearch.scss'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {FieldFeedbackLabel} from '..'
import { isEqual } from 'lodash'
import {useTranslate} from 'core/i18n/i18nProvider';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0.42rem',
    minHeight: 'calc(1.5em + 1.3rem + 2px)',
    border: '1px solid #E4E6EF',
  })
};

export function AsyncSelectSearchField({
  label,
  loadOptions,
  notifyChange,
  defaultOptions,
  ...props
}) {
  const [field, meta] = useField(props)

  const {setFieldValue} = useFormikContext()

  return (
    <>
      {label && <label>{label}</label>}
      <AsyncSelect
        cacheOptions
        classNamePrefix='select'
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        {...field}
        {...props}
        styles={customStyles}
        onChange={(val) => {
          notifyChange && notifyChange(val)
          setFieldValue(field.name, val)
        }}
      />
    </>
  )
}

export function SelectSearchField({
  label,
  valueField = 'value',
  options,
  notifyChange,
  withFeedbackLabel = false,
  customFeedbackLabel,
  isMulti = true,
  isDisabled,
  ...props
}) {
  const [field, meta] = useField(props)
  const {setFieldValue, errors, touched} = useFormikContext();

  const {t} = useTranslate()
  const convertValue = () => {
    if (isMulti) {
      return options.filter((c) => {
        if (field.value?.includes?.(c.value)) {
          return true;
        } else if (field.value?.filter(v => v?.[valueField] === c.value).length > 0) {
          return true;
        } else if (field.value?.filter(v => v?.value === c.value).length > 0) {
          return true;
        } else {
          return false;
        }
      } )
    } else {
      const value = options.find((c) => {
        return isEqual(c.value, field.value?.value) || isEqual(c.value, field?.value);
      });
      return value;
    }
  }
  return (
    <>
      {label && <label>{label}</label>}
      <Select
        cacheOptions
        classNamePrefix='select'
        options={options}
        {...field}
        {...props}
        styles={customStyles}
        isMulti={isMulti}
        value={convertValue()}
        onChange={(val) => {
          notifyChange && notifyChange(val)
          setFieldValue(field.name, val)
        }}
        noOptionsMessage={() => t('common_no_option')}
        isDisabled={isDisabled}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}
