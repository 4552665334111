import {newId} from 'app/common/_helpers/idHelpers';

export const simpleInitApp = (newPage) => {
  return {
    name: 'Ứng dụng mới',
    data: {
      pages: [{type: 'sheet', name: newPage?.name, page_id: newPage?._id}],
    },
    interface: {
      interfaces: [
        {
          id: newId(),
          name: 'Giao diện 1',
          icon: '<i class="bi bi-folder2"></i>',
          interfacePages: [
            {
              id: newId(),
              name: newPage?.name,
              icon: '<i class="bi bi-file-earmark"></i>',
              page_id: newPage?._id,
              views: [
                {
                  type: 'table-paging',
                  code: 'table-paging-1',
                  columns: newPage?.columns ?? [],
                },
              ],
              visibility: true,
            },
          ],
        },
      ],
    },
    automation: {
      automations: [],
      workflows: [],
    },
    appearance: {
      icon: '<i class="bi bi-box-seam"></i>',
      color: '#009ef7',
      darkColor: '#308bc8',
    },
    roles: simpleInitRoles(newPage),
    members: [],
  };
};

const simpleInitRoles = (newPage) => ({
  OWNER: {
    active: true,
    name: 'OWNER',
    label: 'OWNER',
    permissions: {
      configApp: 'all',
      members: 'all',
      data: [],
      interfaces: [],
    },
  },
  ADMIN: {
    active: true,
    name: 'ADMIN',
    label: 'ADMIN',
    permissions: {
      members: 'all',
      data: [],
      interfaces: [],
    },
  },
  MANAGER: {
    active: false,
    name: 'MANAGER',
    label: 'Quản lý',
    permissions: {
      members: 'all',
      data: [],
      interfaces: [],
    },
  },
  USER: {
    active: true,
    name: 'USER',
    label: 'USER',
    permissions: {
      data: [],
      interfaces: [],
    },
  },
});
