import * as requestFromServer from './api';
import {slice, callTypes} from './slice';

const {actions} = slice;

export const fetchWorkflowsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getWorkflows(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.workflowsFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get workflows";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getSheetsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheets(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.sheetsFetched({data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getSheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheet(params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getSheetItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheetItems(params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getWorkflowNodesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getNodes(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.workflowNodesFetched({data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getWorkflowBlocksAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getBlocks(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.blocksFetched({data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const createNodeAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .createNode(params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const createWorkflowAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .createWorkflow(params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updateNodeAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateNode(id, params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteNodeAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deleteNode(id)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteWorkflowAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deleteWorkflow(id)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getWorkspacesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getWorkspaces(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.workspacesFetched({data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getWorkspacePagesAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getWorkspacePages(id, params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const updateWorkflowAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateWorkflow(id, params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getWorkflowAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getWorkflow(id, params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.workflowFetched({data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const duplicateWorkflowAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .duplicateWorkflow(id)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

