export const APPROVAL_STATUS = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
  REOPEN: 'reopen',
  APPROVE: 'approve',
};
export const APPROVAL_TYPE = {
  PARALLEL: 'parallel',
  SERIAL: 'serial',
}

