import WidgetEditView from '../dashboard/widget-edit/WidgetEditView';
import WidgetView from '../dashboard/WidgetView';
import {useWidgetViewContext} from './WidgetDataProvider';

export default function WrapperWidgetView({isEditWidget, ...restProps}) {
  const {sheet, sheetId, items, subSheet, subItems, setInnerSheetId} = useWidgetViewContext();

  if (isEditWidget) {
    return (
      <WidgetEditView
        {...restProps}
        sheet={sheet}
        sheetId={sheetId}
        previewItems={items}
        subSheet={subSheet}
        subItems={subItems}
        setInnerSheetId={setInnerSheetId}
      ></WidgetEditView>
    );
  }

  return (
    <WidgetView
      {...restProps}
      sheet={sheet}
      items={items}
      subSheet={subSheet}
      subItems={subItems}
    ></WidgetView>
  );
}
