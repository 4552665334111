import {Card, CardBody, PaginationLinks, PaginationToolbar} from 'app/common/_partials/controls';
import {OGridViewV2} from 'app/modules/work/components/OGridViewV2';
import {useCallback, useEffect, useState} from 'react';
import './itemTableChart.scss';
import {useTranslate} from 'core/i18n/i18nProvider';
import {cloneDeep, isEqual, isFunction} from 'lodash';
import {HeaderFormatter} from '../../../table/ColumnHeader';
import ItemField from '../../../../field/ItemField';
import ItemDetailView from '../../../../item-detail/ItemDetailView';

function ItemTableChart({data, dateChildren, widget, columns, sheet, subSheet}) {
  const {t} = useTranslate();
  const [items, setItems] = useState(data);
  const [itemDetail, setItemDetail] = useState(null);
  const tableOptions = {
    pageNumber: 1,
    pageSize: 50,
  };
  const [paging, setPagingBase] = useState(tableOptions);

  const setPaging = useCallback((nextPaging) => {
    setPagingBase((prevPaging) => {
      if (isFunction(nextPaging)) {
        nextPaging = nextPaging(prevPaging);
      }

      if (isEqual(prevPaging, nextPaging)) {
        return prevPaging;
      }

      return nextPaging;
    });
  }, []);

  const onShowItemDetail = (item) => {
    setItemDetail(item);
  };

  useEffect(() => {
    const pageNumber = paging?.pageNumber || 0;
    const pageSize = paging?.pageSize || 0;
    const itemsPaging = cloneDeep(data).sort((item, nextItem) => {
      const createdItem = new Date(item.created_at).valueOf();
      const nextCreatedItem = new Date(nextItem.created_at).valueOf();
      if (createdItem < nextCreatedItem) {
        return 1;
      } else {
        return -1;
      }
    });
    const itemStart = (pageNumber - 1) * pageSize;
    const itemEnd = pageNumber * pageSize;
    const listItemPaging = itemsPaging.slice(itemStart, itemEnd);
    setItems(listItemPaging);
  }, [data, paging]);

  useEffect(() => {
    setPagingBase(tableOptions);
  }, [data]);

  const sheetColumns = () => {
    if (columns) {
      const sheetCols = [];
      columns.forEach((column) => {
        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column.name,
            text: column.name,
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap ',
            column: column,
            formatter: (cell, row) => {
              let props = {
                value: cell,
                item: row,
                column: column,
                readOnly: true,
              };
              if (column.field === 'title') {
                props.showDetail = onShowItemDetail;
                props.showExpand = true;
              }
              return <ItemField {...props} />;
            },
            headerFormatter: (column, colIndex, style) => (
              <HeaderFormatter column={column} colIndex={colIndex} style={style} />
            ),
            editable: false,
          };

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          sheetCols.push(col);
        }
      });

      return sheetCols;
    } else {
      return [
        {
          accessor: '',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header text-left',
          editable: false,
        },
      ];
    }
  };

  const paginationProps = {
    ...paging,
    custom: true,
    sizePerPageList: [
      {text: '10', value: 10},
      {text: '20', value: 20},
    ],
    pageSize: 10,
    pageNumber: 1,
    paginationSize: 3,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total'>
        {t('sheet_widget_item_table_pagination', {from: from, to: to, size: size})}
      </span>
    ),
    totalSize: data.length || 0,
    sizePerPage: paging.pageSize,
    page: paging.pageNumber,
    dataSize: data.length || 0,
    pageStartIndex: 1,
    onPageChange: (page) => {
      setPaging((prev) => ({
        ...prev,
        pageNumber: page,
        page: page,
      }));
    },
  };

  const expandRow = {
    renderer: (item, settings) => {
      return (
        <>
          <CardBody className='d-flex border-0'>
            <div className='sheet-group-table-indicator ms-2'></div>
            <SubitemTableChart
              parentItemId={item._id}
              subItems={dateChildren[item._id]}
              columns={subSheet?.columns || []}
              onShowItemDetail={onShowItemDetail}
            ></SubitemTableChart>
          </CardBody>
        </>
      );
    },
  };

  return (
    <div className='w-100 overflow-scroll item-table-chart-wrapper'>
      <Card className='mb-4'>
        <CardBody className='d-flex p-0'>
          <div className='sheet-group-table-indicator ms-2'></div>
          <OGridViewV2
            classes='table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1 sheet-table'
            keyField='_id'
            data={items || []}
            columns={sheetColumns()}
            expandRow={{
              renderer: expandRow.renderer,
            }}
            showFooter={false}
          ></OGridViewV2>
        </CardBody>
        <div className='d-flex justify-content-between align-items-center flex-wrap position-sticky bottom-0 start-0 bg-body'>
          <PaginationLinks paginationProps={paginationProps} />
          <div></div>
          <PaginationToolbar isLoading={false} paginationProps={paginationProps} />
        </div>
      </Card>
      <ItemDetailView
        open={itemDetail !== null}
        onHide={() => setItemDetail(null)}
        readOnly={true}
        item={itemDetail}
        sheet={sheet}
      />
    </div>
  );
}

const SubitemTableChart = ({columns, subItemKey, subItems, onShowItemDetail}) => {
  const sheetColumns = () => {
    if (columns) {
      const sheetCols = [];
      columns.forEach((column) => {
        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column.name,
            text: column.name,
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap ',
            column: column,
            headerFormatter: (column, colIndex, style) => (
              <HeaderFormatter column={column} colIndex={colIndex} style={style} />
            ),
            formatter: (cell, row) => {
              let props = {
                value: cell,
                item: row,
                column: column,
                readOnly: true,
              };
              if (column.field === 'title') {
                props.showDetail = onShowItemDetail;
                props.showExpand = false;
              }
              return <ItemField {...props} />;
            },
            editable: false,
          };

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          sheetCols.push(col);
        }
      });

      return sheetCols;
    } else {
      return [
        {
          accessor: '',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header text-left',
          editable: false,
        },
      ];
    }
  };

  return (
    <OGridViewV2
      classes='table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1 p-3'
      keyField='_id'
      data={subItems || []}
      columns={sheetColumns()}
      subItemKey={subItemKey}
      showFooter={false}
    ></OGridViewV2>
  );
};

export default ItemTableChart;
