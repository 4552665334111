import {callAiAssistantAction} from 'app/modules/work/ai-assistant/stores/actions';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useState} from 'react';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {toAbsoluteUrl} from 'core/helpers';
import {useInterfaceThemeContext} from '../sheet-view/interface-view/InterfaceThemeContext';
import clsx from 'clsx';
const steps = {
  prompt: 'prompt',
  inProgress: 'inProgress',
  hasResult: 'hasResult',
  error: 'error',
};
export default function ToolbarAiAssistant({onCreateTasks}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const [writePrompt, setWritePrompt] = useState('');
  const [step, setStep] = useState(steps.prompt);
  const [showAiAssistantDropdown, setShowAiAssistantDropdown] = useState(false);
  const [generatedTasks, setGeneratedTasks] = useState([]);
  const {backgroundColor, textColor, addButtonVariant} = useInterfaceThemeContext();

  const resetOver = () => {
    setStep(steps.prompt);
    setWritePrompt('');
    setGeneratedTasks([]);
  };

  const toggleDropdown = (show) => {
    setShowAiAssistantDropdown(show);
    if (!show) {
      resetOver();
    }
  };

  const applyResult = () => {
    onCreateTasks &&
      onCreateTasks(generatedTasks, () => {
        resetOver();
        setShowAiAssistantDropdown(false);
      });
  };

  const generateTask = () => {
    const prompt = writePrompt;
    setStep(steps.inProgress);
    dispatch(
      callAiAssistantAction({
        action: 'gen-task',
        prompt: prompt,
      })
    ).then((result) => {
      if (result?.data?.data?.detail_tasks) {
        setStep(steps.hasResult);
        let tasksGenerated = result?.data?.data?.detail_tasks.map((t) => {
          return {
            title: t.major_task?.name,
            items:
              t.subtasks?.map((subtask) => {
                return {title: subtask.name};
              }) || [],
          };
        });
        setWritePrompt('');
        setGeneratedTasks(tasksGenerated);
      } else {
        setStep(steps.error);
        setWritePrompt('');
      }
    });
  };
  return (
    <>
      <Dropdown className='ms-2' show={showAiAssistantDropdown} onToggle={toggleDropdown}>
        <OverlayTrigger
          overlay={<Tooltip className='tooltip-inverse'>{t('ai_assistant_tooltip')}</Tooltip>}
        >
          <Dropdown.Toggle className='btn-sm py-2' variant='secondary' id='dropdown-toggle-top'>
            <span className='icon-fireworks-star fs-icon'></span>
          </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu className='p-2 w-600px'>
          {(step === steps.prompt || step === steps.error) && (
            <div>
              <textarea
                className='form-control h-200px'
                placeholder={t('ai_assistant_create_task_for')}
                value={writePrompt}
                onChange={(e) => {
                  setWritePrompt(e.target.value);
                  setStep(steps.prompt);
                }}
              ></textarea>
            </div>
          )}
          {step === steps.inProgress && (
            <div className='form-control h-200px'>
              {t('ai_assistant_working')}
              <span className='animation-blink'>...</span>
            </div>
          )}
          {step === steps.hasResult && (
            <div className='h-400px overflow-scroll border rounded'>
              <ul>
                {generatedTasks?.map((group) => {
                  return (
                    <li>
                      <div className='fw-bold mt-2'>{group.title}</div>
                      <ul>
                        {group.items?.map((item) => {
                          return (
                            <li>
                              <div>{item.title}</div>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className='my-2 d-flex justify-content-end'>
            {step === steps.prompt && (
              <button
                className={clsx('btn btn-sm float-right', {'btn-primary': !backgroundColor})}
                style={{color: textColor, backgroundColor}}
                onClick={generateTask}
              >
                {t('ai_assistant_call')}
              </button>
            )}
            {step === steps.hasResult && (
              <>
                <button className='btn btn-secondary btn-sm float-right me-2' onClick={resetOver}>
                  {t('ai_assistant_reset')}
                </button>
                <button
                  className={clsx('btn btn-sm float-right', {'btn-primary': !backgroundColor})}
                  onClick={applyResult}
                  style={{color: textColor, backgroundColor}}
                >
                  {t('ai_assistant_create_task')}
                </button>
              </>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
