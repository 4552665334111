import './colorField.scss';

import React, {useMemo, useState} from 'react';
import {closestColor, invertColor} from 'app/common/_helpers/ColorHelper';

import {Dropdown} from 'react-bootstrap';
import clsx from 'clsx';
import {isArray, isEmpty} from 'lodash';

function ColorField({
  value,
  item,
  column,
  mode,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName,
}) {
  const [expandedOnce, setExpandedOnce] = useState(false);

  const field = useMemo(() => column?.field, [column]);
  const options = useMemo(() => column?.values ?? [], [column]);
  const isDetailViewMode = mode === 'detail';

  const cellOption = useMemo(() => {
    if (options && isArray(options)) {
      return options?.filter((option) => {
        if (!value?.value && column?.default) {
          return option.value === column?.default;
        }
        return (
          option.value === value?.value ||
          option.value === value ||
          (!value && !isEmpty(value) && option.value === '')
        );
      });
    }
    return [];
  }, [column?.default, options, value]);

  const label = cellOption?.length > 0 ? cellOption[0].label : '';
  const backgroundColor = cellOption.length > 0 ? cellOption[0].backgroundColor : 'transparent';
  const color = closestColor(invertColor(backgroundColor), ['#ffffff', '#000000']);

  if (fieldEditable && !readOnly) {
    return (
      <Dropdown
        className={clsx('table-column-color', containerClassName && containerClassName)}
        onToggle={() => setExpandedOnce(true)}
      >
        <Dropdown.Toggle
          className={`w-100 border-0 table-column-color text-truncate d-flex align-items-center justify-content-center w-100 ${clsx(
            {
              'text-dark': color === '#000000',
              'text-white': color === '#ffffff',
            }
          )}`}
          variant='none'
          as={CustomToggle}
          hasValue={!!label}
        >
          {label ? (
            <span
              style={{backgroundColor: `${backgroundColor}`}}
              className={`border-0 field-color-label text-truncate d-inline-block ${clsx({
                'text-dark': color === '#000000',
                'text-white': color === '#ffffff',
              })}`}
            >
              {label}
            </span>
          ) : isDetailViewMode ? (
            <span className='ps-1 text-gray-500 lh-xl'>{column?.placeholder}</span>
          ) : null}
        </Dropdown.Toggle>
        {fieldEditable && expandedOnce && (
          <Dropdown.Menu>
            {options &&
              options.map((option) => {
                const color = closestColor(invertColor(option.backgroundColor), [
                  '#ffffff',
                  '#000000',
                ]);
                return (
                  <Dropdown.Item
                    className={`table-column-color-option text-white text-truncate ${clsx({
                      'text-dark': color === '#000000',
                      'text-white': color === '#ffffff',
                    })}`}
                    key={option.value}
                    size='xs'
                    style={{backgroundColor: `${option.backgroundColor}`}}
                    onClick={() => {
                      onChange(item, field, value, {value: option.value, label: option.label});
                    }}
                  >
                    {option?.label}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  }

  return (
    <div
      className={`border-0 table-column-color text-truncate ${clsx({
        'text-dark': color === '#000000',
        'text-white': color === '#ffffff',
      })}`}
      variant='none'
    >
      <span
        className={`field-color-label text-truncate text-center p-2 ${clsx({
          'text-dark': color === '#000000',
          'text-white': color === '#ffffff',
        })}`}
        style={{backgroundColor: `${backgroundColor}`}}
      >
        {label}
      </span>
    </div>
  );
}

export default ColorField;
export const CustomToggle = React.forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      className={`table-column-color cursor-pointer w-100 ${props?.hasValue ? '' : 'd-block'}`}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});
