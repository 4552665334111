/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import {PrivateRoutes} from './PrivateRoutes';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {Logout, AuthPage} from '../modules/auth';
import {App} from '../App';
import MeetingJoinPage from 'app/modules/meeting/pages/MeetingJoinPage';
import {useSession} from 'core/store/core/hooks';
import RouteForFeatureFlag from 'core/features/RouteForFeatureFlag';
import FeatureFlagRoute from 'core/features/RouteForFeatureFlag';
import { useFlag } from '@openfeature/react-sdk';
// import AppFormPage from '../modules/application/AppFormPage';
import FormShareView from 'app/modules/work/sheet-form/FormShareView';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;


const AppRoutes: FC = () => {
  const {user} = useSession();

  const { value: homeOn } = useFlag('optimi.home', false, { updateOnContextChanged: false });

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/p/meeting/j/:meetingId' element={<MeetingJoinPage />} />

          {user ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {homeOn && <Route index element={<Navigate to='/home' />} />}
              <Route index element={<Navigate to='/work' />} />
              <Route path='application/:appId/form/:formId/publish' element={<FormShareView />} />
              <Route path='application/:key/form' element={<FormShareView />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='application/:appId/form/:formId/publish' element={<FormShareView />} />
              <Route path='application/:key/form' element={<FormShareView />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export {AppRoutes};
