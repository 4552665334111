import {createSlice} from '@reduxjs/toolkit';

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  users: [],
  countUsers: 0,
  teamForEdit: {},
  lastError: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    usersFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.users = data.data ? data.data : [];
      state.countUsers = data.count ?? 0;
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
