import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  searchNotificationAction,
  searchPeopleAction,
  updateNotificationAction,
} from './stores/action';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import NotificationTabNavigation from './components/NotificationTabNavigation';
import NotificationFilter from './components/NotificationFilter';
import NotificationTabContent from './components/NotificationTabContent';
import { useSession } from 'core/store/hooks';
const pageSize = 15;
function NotificationView({show, onClose}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useSession()
  const [filterPeopleOption, setFilterPeopleOption] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState('all');
  const [filterPeople, setFilterPeople] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadMore, setLoadMore] = useState(false);

  const {currentState} = useSelector((state) => ({currentState: state.notification}), shallowEqual);
  const {notifications, listLoading, countNotifications} = currentState;
  const currentUserId = user?.id ?? user?._id
  const totalPage = useMemo(() => {
    return Math.ceil(countNotifications / pageSize);
  }, [countNotifications]);

  const onSelectTab = (eventKey) => {
    handleReset();
    setSelectedTab(eventKey);
  };

  const onSelectPeople = (peopleId) => {
    let newFilterPeople = [...filterPeople];
    if (newFilterPeople.includes(peopleId)) {
      newFilterPeople = newFilterPeople.filter((item) => item !== peopleId);
    } else {
      newFilterPeople.push(peopleId);
    }
    handleReset();
    setFilterPeople(newFilterPeople);
  };

  const loadNotification = useCallback(() => {
    let params = {
      filter: {
        searchText: searchValue,
        people: filterPeople.length > 0 ? filterPeople : [currentUserId],
      },
      paging: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };
    if (selectedTab === 'unread') {
      params.filter.read_at = null;
    }
    dispatch(searchNotificationAction(params, loadMore));
  });

  const loadUser = () => {
    dispatch(
      searchPeopleAction({
        paging: {
          pageNumber: 1,
          pageSize: 1000,
        },
      })
    ).then((response) => {
      let listPeople = response.filter(item => item?.id !== currentUserId || item?._id !== currentUserId)
      setFilterPeopleOption(listPeople);
    });
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      loadNotification();
    }
  }, [pageNumber]);

  useEffect(() => {
    loadNotification();
  }, [selectedTab, filterPeople]);

  useEffect(() => {
    setTimeout(loadNotification(), 500);
  }, [searchValue]);

  useEffect(() => {
    if (show) {
      loadNotification();
      loadUser();
    }else {
      handleReset()
    }
  }, [show]);

  

  const onClickNotification = async (notificationId, sheetId, itemId) => {

    dispatch(
      updateNotificationAction({id: notificationId, read_at: new Date().toISOString()})
    ).then((res) => {
      if (res.success) {
        loadNotification();
      }
    });
    onClose();
    if (sheetId) {
      if (itemId) {
        return navigate(`/work/sheet/${sheetId}/items/${itemId}/notification`);
      }
      navigate(`/work/sheet/${sheetId}`);
    }
  };

  const handleScroll = (e) => {
    const isEndScroll =
      Math.floor(e.target.scrollHeight - e.target.scrollTop) >= e.target.clientHeight ||
      Math.ceil(e.target.scrollHeight - e.target.scrollTop) >= e.target.clientHeight;
    if (isEndScroll) {
      if (pageNumber < totalPage && !listLoading) {
        setPageNumber(pageNumber + 1);
        setLoadMore(true);
      }
    }
  };

  const handleReset = () => {
    setPageNumber(1);
    setLoadMore(false);
  };

  return (
    <div className='p-5 d-flex flex-column overflow-hidden' style={{maxHeight:'100%'}}>
      <div className='flex-shrink-0'>
        <NotificationTabNavigation onSelectTab={onSelectTab} />
        <NotificationFilter
          filterPeople={filterPeople}
          filterPeopleOption={filterPeopleOption}
          onSelectPeople={onSelectPeople}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </div>
      <div className='overflow-auto' onScroll={handleScroll} style={{maxHeight:'100%'}}>
        <NotificationTabContent
          notifications={notifications}
          onClickNotification={onClickNotification}
          listLoading={listLoading}
          loadMore={loadMore}
        />
      </div>
    </div>
  );
}

export default NotificationView;
