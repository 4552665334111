import React from 'react';

export function Radio({value, checked, onChange, label}) {
  return (
    <>
      <label className='checkbox checkbox-lg checkbox-single d-flex align-items-center'>
        <input type='radio' name='shareUser' value={value} checked={checked} onChange={onChange} />
        <span />
        <span>&nbsp;{label}</span>
      </label>
    </>
  );
}