import tinycolor from 'tinycolor2';
import './appStoreView.scss';
import { getAppColor } from '../../helpers';
const AppStoreView = ({apps, onAppSelect}) => {

  const onSelectApp = (app) => {
    onAppSelect(app);
  };

  return (
    <div className='card-wrapper h-100 overflow-auto'>
      {apps.map((app, index) => (
        <div
          className='card border border-2 border-gray-300 border card-app-item'
          onClick={() => onSelectApp(app)}
          key={index}
        >
          <div className='p-3 d-flex flex-column gap-2 h-100'>
            <div
              className='d-flex h-150px rounded align-items-center justify-content-center aspect-1 rounded flex-shrink-0'
              style={{backgroundColor: getAppColor(app?.appearance) ?? tinycolor.random()}}
            >
              {app?.app_icon ? (
                <img
                  src={app?.app_icon}
                  alt={app?.name}
                  className='w-100 h-100 rounded icon-app-store'
                />
              ) : (
                <i className={`${app?.icon || 'bi bi-box-seam'} text-white icon-app-store`} />
              )}
            </div>

            <div className='mt-4'>
              <h3 className='cursor-pointer title-app' onClick={() => onSelectApp(app)}>
                {app?.name}
              </h3>
              <p className='mt-2 mb-0 desc-limit-text'>{app?.store_app_short_description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppStoreView;
