import {memo, useEffect, useMemo, useRef, useState} from 'react';
import {NodeToolbar, Position} from 'reactflow';
import './workflowNode.scss';
import clsx from 'clsx';
import {useTranslate} from 'core/i18n/i18nProvider';
import WorkflowHandle from '../handle/WorkflowHandle';
import {Dropdown} from 'react-bootstrap';
import {useWorkflow} from '../../hook/workflowHook';
import {useSelector} from 'react-redux';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';

const WorkflowNode = memo(({id, data, type, isConnectable, ...rest}) => {
  const {addNode, removeNode, saveNode} = useWorkflow();
  const [blockOptions, setBlockOptions] = useState([]);
  const [showNodeOption, setShowNodeOption] = useState(false);
  const [showBlockOption, setShowBlockOption] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showActionMenuChangeType, setShowActionMenuChangeType] = useState(false);
  const [deleteNode, setDeleteNode] = useState(false);
  const {t} = useTranslate();
  const label = useMemo(() => data?.label && data?.label !== '' ? data?.label : t('workflows.node.node_empty_label'), [data]);
  const dataConfig = useMemo(() => data?.config ?? {}, [data]);
  const {blocks} = useSelector((state) => state.workflow);
  const changeBlockOption = (type) => {
    let blockOptions = blocks ?? [];
    blockOptions = blockOptions.filter((option) => option.type === type);
    setBlockOptions(blockOptions);
  };

  const nodeRef = useRef();
  const actionMenuRef = useRef();
  useEffect(() => {
    changeBlockOption(type);
  }, [type]);

  useEffect(() => {
    const outsideClick = (e) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(e.target)) {
        setShowActionMenu(false);
      }
    };
    document.addEventListener('click', outsideClick);
    return () => {
      document.removeEventListener('click', outsideClick);
    }
  }, []);

  const onMouseEventEnter = () => {
    if (data?.empty) {
      setShowNodeOption(true);
    }
  };

  const onMouseEventLeave = () => {
    if (data?.empty) {
      setShowNodeOption(false);
      setShowBlockOption(false);
    }
  };

  const onSelectNodeOption = async (type) => {
    await changeBlockOption(type);
    setShowNodeOption(false);
    setShowBlockOption(true);
  };

  const onSelectBlockOption = (block) => {
    addNode(id, block);
    setShowBlockOption(false);
    setShowNodeOption(false);
    nodeRef.current.click();
  };

  const onChangeNodeBlockType = (e, blockOption) => {
    e.preventDefault();
    e.stopPropagation();
    setShowActionMenuChangeType(false);
    setShowActionMenu(false);
    saveNode(id, {data: {...data, block: blockOption, label: blockOption?.name}});
  };

  const onToggleChangeTypeMenu = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type) {
      if (!showActionMenuChangeType) {
        await changeBlockOption(type);
      }
      await setShowActionMenuChangeType(!showActionMenuChangeType);
    }
  };

  const onDeleteNode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteNode(id);
  };

  const handleDeleteNode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removeNode(id);
    setDeleteNode(false);
  };

  const onChangeLabel = (value) => {
    saveNode(id, {data: {...data, label: value}});
  };

  return (
    <>
      <div className='nowheel nodrag' onMouseEnter={onMouseEventEnter} onMouseLeave={onMouseEventLeave} ref={nodeRef}>
        <div className='workflow-node p-3'>
          <div
            className='workflow-node-action'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowActionMenu(!showActionMenu);
            }}
          >
            <i className='ki ki-bold-more-hor' />
          </div>
          <NodeToolbar position={Position.Right} isVisible={showActionMenu} >
            <div className='p-2 bg-white rounded border' ref={actionMenuRef}>
              <div className='workflow-action-menu-item p-2'>
                <i className='la la-pencil-alt' />
                <span>{t('workflows.node.action.edit')}</span>
              </div>
              <div className='workflow-action-menu-item w-100 p-2'>
                <div
                  className='d-flex gap-2 align-items-center w-100'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowActionMenuChangeType(!showActionMenuChangeType);
                  }}
                >
                  <i className='la la-exchange' />
                  <span>{t('workflows.node.action.change_type')}</span>
                </div>
                <Dropdown
                  className='menu-item p-0'
                  align='end'
                  drop='end'
                  autoclose='true'
                  show={showActionMenuChangeType}
                  onToggle={onToggleChangeTypeMenu}
                >
                  <div className='menu-link p-0' onClick={onToggleChangeTypeMenu}>
                    <span className='menu-arrow'></span>
                  </div>
                  <Dropdown.Menu className='p-2'>
                    {showActionMenuChangeType && blockOptions && (
                      <div className='p-3 workflow-node-create-option gap-2 overrflow-auto mh-300px'>
                        {blockOptions.map((blockOption) => {
                          return (
                            <div
                              className='workflow-node-create-option-item gap-2'
                              key={blockOption?._id}
                              onClick={(e) => onChangeNodeBlockType(e, blockOption)}
                            >
                              <div>
                                <p className='m-0 fw-bold'>{blockOption?.name}</p>
                                <p className='m-0'>{blockOption?.description}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='workflow-action-menu-item p-2' onClick={onDeleteNode}>
                <i className='la la-trash' />
                <span>{t('workflows.node.action.delete')}</span>
              </div>
            </div>
          </NodeToolbar>
          <div className={clsx('workflow-node-icon', data?.empty && 'workflow-node-empty')}>
            {data?.empty ? (
              <i className={`fa-solid fa-plus fs-2`} />
            ) : (
              <i className={`${data?.icon ?? 'la la-long-arrow-alt-down'} text-white fs-2`} />
            )}
          </div>
          <div>
            <input
              className='workflow-node-input'
              value={label}
              onChange={(e) => onChangeLabel(e.target.value)}
            />
          </div>
        </div>
        {showNodeOption && !showBlockOption && (
          <div className='p-3 workflow-node-create-option gap-2'>
            <div
              className='workflow-node-create-option-item gap-2'
              onClick={() => onSelectNodeOption('action')}
            >
              <i className='la la-long-arrow-alt-down' />
              <div>
                <p className='m-0'>{t('workflows.node.action')}</p>
                <p className='m-0'></p>
              </div>
            </div>
            <div
              className='workflow-node-create-option-item gap-2'
              onClick={() => onSelectNodeOption('condition')}
            >
              <i className='la la-code-fork' />
              <div>
                <p className='m-0'>{t('workflows.node.condition')}</p>
                <p className='m-0'></p>
              </div>
            </div>
          </div>
        )}
        {showBlockOption && (
          <div className='p-3 workflow-node-create-option gap-2 mh-300px overflow-auto'>
            {blockOptions.map((blockOption) => {
              return (
                <div
                  className='workflow-node-create-option-item gap-2 p-2'
                  key={blockOption?._id}
                  onClick={() => onSelectBlockOption(blockOption)}
                >
                  <div>
                    <p className='m-0 fw-bold'>{blockOption?.name}</p>
                    <p className='m-0'>{blockOption?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <WorkflowHandle
        type='target'
        position={Position.Top}
        id={`node-target-${data?.id}`}
        style={{bottom: -2, background: '#555'}}
        isConnectable={1}
      />
      {!showNodeOption && (
        <WorkflowHandle
          type='source'
          position={Position.Bottom}
          id={`node-source-${data?.id}`}
          style={{bottom: -2, background: '#555'}}
          isConnectable={1}
        />
      )}
      <ModalDelete
        show={deleteNode}
        close={() => setDeleteNode(false)}
        onClick={handleDeleteNode}
        title={t('workflows.node.delete')}
        content={`${t('workflows.node.delete_confirm')} ${label}`}
      />
    </>
  );
});
export default WorkflowNode; 