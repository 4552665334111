import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import {conjunctionOptions, operatorOptions} from './constanst';
import {Dropdown} from 'react-bootstrap';
import {cloneDeep, uniqueId} from 'lodash';

const FilterCondition = ({
  filter,
  columnOptions,
  dept = 0,
  onAddCondition,
  onAddConditionGroup,
  onDeleteCondition,
  onUpdateCondition,
}) => {
  const {t} = useTranslate();

  const getConjunctionOptionByValue = (conjunction) => {
    return conjunctionOptions.find((conjunctionOption) => conjunctionOption.value === conjunction);
  };

  const getColumnOptionByField = (column) => {
    return columnOptions.find((columnOption) => columnOption.value === column?.field);
  };

  const getOperatorOptionByValue = (operator) => {
    return operatorOptions.find((operatorOption) => operatorOption.value === operator);
  };

  const renderConjunctionByIndex = (index) => {
    if (index === 0)
      return (
        <div className='text-dark d-flex align-items-start h-100 w-100 justify-content-start'>
          {t('sheet_advanced_filter_conjunction_where')}
        </div>
      );
    if (index === 1)
      return (
        <Select
          options={conjunctionOptions}
          value={getConjunctionOptionByValue(filter.conjunction)}
          placeholder={t('sheet_advanced_filter_conjunction_placeholder')}
          onChange={({value}) => onUpdateCondition(filter.id, {...filter, conjunction: value})}
        />
      );
    return (
      <div className='text-dark d-flex align-items-start h-100 w-100 justify-content-start'>
        {filter.conjunction}
      </div>
    );
  };

  const renderFilterValueBasedOnType = (filter, type) => {
    if (type === 'number' || type === 'number') {
      return (
        <input
          className='border border-gray-400 rounded h-100 w-100'
          value={filter.value}
          placeholder={t('sheet_advanced_filter_value_placeholder')}
          onChange={(e) => onUpdateCondition(filter.id, {...filter, value: e.target.value})}
        />
      );
    }
    return (
      <Select
        className='w-100'
        placeholder={t('sheet_advanced_filter_value_placeholder')}
        value={filter.value}
        onChange={({value}) => onUpdateCondition(filter.id, {...filter, value: value})}
      />
    );
  };
  return (
    <div
      className={`d-flex flex-column gap-3 w-100 rounded ${
        dept > 0 ? 'border border-gray-500 p-5' : ''
      }`}
    >
      {dept > 0 && (
        <div className='d-flex justify-content-between w-100 mb-3'>
          <span>{t('sheet_view_tool_filter_condition')}</span>
          <div className='d-flex gap-3 align-items-center'>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>
                <i className='la la-plus cursor-pointer' />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className='d-flex flex-column gap-3 w-200px p-2'>
                  <div
                    className='btn btn-light bg-white p-2 d-flex align-items-center gap-2'
                    onClick={() => onAddCondition(filter?.id, {id: uniqueId()})}
                  >
                    {t('sheet_view_tool_filter_add_condition')}
                  </div>
                  <div
                    className='btn btn-light bg-white p-2 d-flex align-items-center gap-2'
                    onClick={() =>
                      onAddConditionGroup(filter?.id, {
                        id: uniqueId(),
                        type: 'nested',
                        filterSet: [],
                        conjunction: filter.conjunction,
                      })
                    }
                  >
                    {t('sheet_view_tool_filter_add_condition_group')}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <i
              className='la la-trash cursor-pointer'
              onClick={() => onDeleteCondition(filter?.id)}
            />
            <i className='la la-braille cursor-pointer' />
          </div>
        </div>
      )}
      {filter.filterSet.map((filterItem, index) => {
        return (
          <div className={`d-flex flex-column gap-3`}>
            <div className='d-flex gap-3'>
              <div className='w-150px'>{renderConjunctionByIndex(index)}</div>
              {filterItem?.filterSet ? (
                <div className='d-flex gap-3 w-100'>
                  <FilterCondition
                    dept={dept + 1}
                    filter={filterItem}
                    columnOptions={columnOptions}
                    onAddCondition={onAddCondition}
                    onDeleteCondition={onDeleteCondition}
                    onAddConditionGroup={onAddConditionGroup}
                    onUpdateCondition={onUpdateCondition}
                  />
                </div>
              ) : (
                <div className='d-flex gap-3 w-100 align-items-center' key={index}>
                  <Select
                    options={columnOptions}
                    className='w-100'
                    value={getColumnOptionByField(filterItem?.column)}
                    placeholder={t('sheet_advanced_filter_column_placeholder')}
                    onChange={({column}) =>
                      onUpdateCondition(filterItem.id, {...filterItem, column: column})
                    }
                  />
                  <Select
                    options={operatorOptions}
                    className='w-100'
                    value={getOperatorOptionByValue(filterItem?.operator)}
                    placeholder={t('sheet_advanced_filter_operator_placeholder')}
                    onChange={({value}) =>
                      onUpdateCondition(filterItem.id, {...filterItem, operator: value})
                    }
                  />

                  {renderFilterValueBasedOnType(filterItem, filterItem?.column?.type)}
                  <div className='d-flex gap-3'>
                    <i
                      className='la la-trash cursor-pointer'
                      onClick={() => onDeleteCondition(filterItem.id)}
                    />
                    <i className='la la-braille cursor-pointer' />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AdvancedFilter = ({columns = [], filter: initFilter}) => {
  const {t} = useTranslate();
  const [filter, setFilter] = useState({
    id: uniqueId(),
    filterSet: [],
    conjunction: 'and',
  });

  useEffect(() => {
    if (initFilter) {
      setFilter(initFilter);
    }
  }, [initFilter]);
  const columnOptions = useMemo(() => {
    return columns.map((column) => ({value: column?.field, label: column?.name, column: column}));
  }, [columns]);

  const updateFilter = (filterSet, id, action, newFilter) => {
    let newFilterSet = cloneDeep(filterSet);
    newFilterSet.forEach((filter, index) => {
      if (filter.id === id) {
        if (action === 'add') {
          filter.filterSet = [...filter.filterSet, newFilter];
        } else if (action === 'add-group') {
          filter.filterSet = [...filter.filterSet, newFilter];
        } else if (action === 'delete') {
          newFilterSet.splice(index, 1);
        } else if (action === 'update') {
          newFilterSet[index] = newFilter;
        }
      } else if (filter?.filterSet) {
        filter.filterSet = updateFilter(filter.filterSet, id, action, newFilter);
      }
    });
    return newFilterSet;
  };

  const onAddCondition = (filterId, newFilter) => {
    let newFilterSet = cloneDeep(filter.filterSet);
    if (filter.id === filterId) {
      newFilterSet = [...newFilterSet, newFilter];
    } else {
      newFilterSet = updateFilter(newFilterSet, filterId, 'add', newFilter);
    }

    setFilter((prev) => ({...prev, filterSet: newFilterSet}));
  };

  const onAddConditionGroup = (filterId, newFilter) => {
    let newFilterSet = cloneDeep(filter.filterSet);
    if (filter.id === filterId) {
      newFilterSet = [...newFilterSet, newFilter];
    } else {
      newFilterSet = updateFilter(newFilterSet, filterId, 'add-group', newFilter);
    }
    setFilter((prev) => ({...prev, filterSet: newFilterSet}));
  };

  const onUpdateCondition = (filterId, newFilter) => {
    if (filter.id === filterId) {
      setFilter((prev) => ({...prev, ...newFilter}));
    } else {
      let newFilterSet = cloneDeep(filter.filterSet);
      newFilterSet = updateFilter(newFilterSet, filterId, 'update', newFilter);
      setFilter((prev) => ({...prev, filterSet: newFilterSet}));
    }
  };

  const onDeleteCondition = (filterId) => {
    const newFilterSet = updateFilter(filter.filterSet, filterId, 'delete');
    setFilter((prev) => ({...prev, filterSet: newFilterSet}));
  };

  return (
    <div className='d-flex flex-column gap-3' style={{minWidth: '600px'}}>
      <FilterCondition
        filter={filter}
        columnOptions={columnOptions}
        onAddCondition={onAddCondition}
        onDeleteCondition={onDeleteCondition}
        onAddConditionGroup={onAddConditionGroup}
        onUpdateCondition={onUpdateCondition}
      />
      <div className='d-flex mt-3 w-100 justify-content-between'>
        <div className='d-flex gap-3'>
          <div
            className='btn btn-light bg-white p-2 d-flex align-items-center gap-2'
            onClick={() => onAddCondition(filter?.id, {id: uniqueId()})}
          >
            <i className='la la-plus' />
            <span>{t('sheet_view_tool_filter_add_condition')}</span>
          </div>
          <div
            className='btn btn-light bg-white p-2 d-flex align-items-center gap-2'
            onClick={() =>
              onAddConditionGroup(filter?.id, {
                id: uniqueId(),
                type: 'nested',
                filterSet: [],
                conjunction: filter.conjunction,
              })
            }
          >
            <i className='la la-plus' />
            <span>{t('sheet_view_tool_filter_add_condition_group')}</span>
          </div>
        </div>
        <button className='btn btn-primary'>{t('common_save')}</button>
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default AdvancedFilter;
