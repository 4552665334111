import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import './snackbar.scss'

const variantIcon = {
  success: <i className='text-white las la-check-circle fs-4'></i>,
  warning: <i className='text-white las la-exclamation-triangle fs-4'></i>,
  danger: <i className='text-white las la-exclamation-circle fs-4'></i>,
  info: <i className='text-white las la-info fs-4'></i>,
};


export default function CustomizedSnackbar({
  open,
  id,
  vertical = 'top',
  horizontal = 'right',
  content = 'Thông báo!',
  variant = 'success',
  autoHideDuration = 3000,
  options={open: false, id: null, message: 'Thông báo'}
}) {
  
  const [snackOpen, setSnackNopen] = useState(false);
  useEffect(() => {
    setSnackNopen(options.open);
  },[options, open, id])


  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setSnackNopen(false);
  }

  return (
    <div>
      <ToastContainer className='p-3' position='top-end'>
        <Toast bg={variant} onClose={handleClose} show={snackOpen} delay={3000} autohide>
          <div className='h-55px px-4 text-white d-flex align-items-center'>
            {variantIcon[variant]}
            <span className='ps-3 content-snackbar'>{content}</span>
            <i onClick={handleClose} className='ms-auto las la-times text-white cursor-pointer fs-4'></i>
          </div>
        </Toast>
      </ToastContainer>
    </div>
  );
}
