import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

function ApprovalStatus({value, effValue}) {
  const {t} = useTranslate();
  if (effValue?.status === 'rejected') {
    return (
      <div className='d-flex align-items-center w-100'>
        <div className='bg-danger w-100 ms-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_reject')}
        </div>
      </div>
    );
  }

  if (effValue?.status === 'approved') {
    return (
      <div className='d-flex align-items-center w-100'>
        <div className='bg-success w-100 ms-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_approve')}
        </div>
      </div>
    );
  }

  return (
    <>
      <OverlayTrigger
        key='title-item'
        placement='top'
        overlay={
          <Tooltip
            className='tooltip-inverse tooltip-title-item position-fixed'
            id='tooltip-title-item'
          >
            {value?.name}
          </Tooltip>
        }
      >
        <span className='people-name-limit-text-1'>{value?.name}</span>
      </OverlayTrigger>
    </>
  );
}

export default ApprovalStatus;
