import './stylesMeetingJoinPage.scss';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {Input} from 'app/common/_partials/controls';
import * as actions from '../redux/meetingActions';
import {Field, Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Button, Spinner} from 'react-bootstrap';
import Countdown from 'react-countdown';
import {FormattedDate, FormattedTime, useIntl} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {useSession} from 'core/store/core/hooks';
import {ModalErrorJoinMeeting} from './modal/ModalErrorJoinMeeting';

export default function MeetingJoinPage() {
  const intl = useIntl();
  const {meetingId} = useParams();
  const dispatch = useDispatch();
  const [meeting, setMeeting] = useState(null);

  const {currentState} = useSelector((state) => ({currentState: state.core}), shallowEqual);

  const {joinLoading} = useSelector((state) => state.meeting);

  const {domain = {}} = currentState;
  const {user} = useSession();

  const [joinError, setJoinError] = useState({});

  const bgImage = toAbsoluteUrl('/media/bg/bg-12.png');
  const descriptions = meeting?.descriptions ?? '';

  const [meetingOngoing, setMeetingOngoing] = useState(false);

  useEffect(() => {
    dispatch(actions.getMeetingAction({meetingId: meetingId})).then((response) => {
      setMeeting(response.data.data);
      setMeetingOngoing(isOngoing(response.data.data));
    });
  }, [meetingId, dispatch]);

  const joinMeeting = (values) => {
    setJoinError({});

    if (!joinLoading) {
      let params = {
        meetingId: meetingId,
        displayName: values.name,
        passcode: values.passcode,
      };
      dispatch(actions.joinMeetingAction(params)).then((response) => {
        if (
          response.data &&
          response.data.success === true &&
          response?.data?.data?.success !== false
        ) {
          if (values.via === 'web') {
            window.location = response.data.data.url;
          } else if (values.via === 'app') {
            window.location = response.data.data.urlDirect;
          }
        } else {
          setJoinError({
            code: response.data.data.err_code || 400,
            message: response.data.data.message,
          });
        }
      });
    }
  };

  const downloadLink = () => {
    if (navigator.userAgent.indexOf('Mac') !== -1) return 'https://zoom.us/client/latest/Zoom.pkg';
    if (navigator.userAgent.indexOf('Win') !== -1)
      return 'https://zoom.us/client/latest/ZoomInstaller.exe?archType=x64';
  };

  const isOngoing = (meeting) => {
    if (!meeting) return false;
    let now = new Date().getTime();
    let delayNow = new Date().getTime() + 30 * 60 * 1000;

    return delayNow >= meeting.startTimeTs * 1000;
  };

  const isInTheFuture = (meeting) => {
    if (!meeting) return false;
    let now = new Date().getTime();
    return meeting.startTimeTs * 1000 >= now;
  };

  const isInThePast = (meeting) => {
    if (!meeting) return false;
    let now = new Date().getTime();
    return meeting.endTimeTs * 1000 < now;
  };

  const countDownComplete = () => {
    let now = new Date().getTime();
    setMeeting({...meeting, ts: now});
    setMeetingOngoing(true);
  };

  return (
    <>
      <div className='row h-100 meeting-join-page'>
        <div className='col-lg-7 bg-light-primary' style={{backgroundImage: `url(${bgImage})`}}>
          <div className='w-100 text-center mt-4 text-white text-uppercase'>
            <h3></h3>
          </div>
          {meeting && meeting.startTime && (
            <>
              <div className='w-100 mt-30 text-center text-light text-uppercase'>
                <h1 className='text-white'>{meeting?.name}</h1>
              </div>
              <div className='mt-5 w-100 text-center text-light'>
                <h4 className='text-white'>
                  <FormattedTime
                    value={new Date(meeting.date).setTime(
                      meeting.startTime * 60000 - 8 * 60 * 60 * 1000
                    )}
                  ></FormattedTime>{' '}
                  -{' '}
                  <FormattedTime
                    value={new Date(meeting.date).setTime(
                      meeting.endTime * 60000 - 8 * 60 * 60 * 1000
                    )}
                  ></FormattedTime>
                  ,{' '}
                  <FormattedDate
                    value={new Date(meeting.date)}
                    day='2-digit'
                    month='2-digit'
                    year='numeric'
                  ></FormattedDate>
                </h4>
              </div>
            </>
          )}
          {!!descriptions && (
            <div className='d-flex align-items-center justify-content-center mt-10'>
              <div className='box-white-opacity'>
                <div
                  className='container-description '
                  dangerouslySetInnerHTML={{
                    __html: descriptions,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
        <div className='col-lg-5 d-flex flex-column justify-content-between'>
          <div className='w-100 text-center'></div>
          <div className='w-100 text-center'>
            {(user || meeting?.guestAllowed) && meeting && meetingOngoing && (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: user?.name || '',
                  passcode: '',
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Vui lòng nhập tên'),
                })}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                  joinMeeting(values);
                }}
              >
                {({setFieldValue, handleSubmit}) => (
                  <>
                    <Form className='form'>
                      <div className='form-group mb-4 d-flex  justify-content-center'>
                        <Field
                          name='name'
                          className='w-50 text-center form-control'
                          component={Input}
                          placeholder={intl.formatMessage({id: 'meeting_join_name'})}
                          focus
                        />
                      </div>

                      {joinError.code && (
                        <div className='w-100 text-center mb-4 text-danger'>
                          {intl.formatMessage({id: joinError.message})}
                        </div>
                      )}
                      <div>
                        <Button
                          className='w-50'
                          type='submit'
                          variant='primary'
                          onClick={() => {
                            setFieldValue('via', 'app');
                            handleSubmit();
                          }}
                        >
                          {intl.formatMessage({id: 'meeting_join_app'})}
                        </Button>
                      </div>
                      <div className=''>
                        <Button
                          variant='link'
                          onClick={() => {
                            setFieldValue('via', 'web');
                            handleSubmit();
                          }}
                        >
                          {intl.formatMessage({id: 'meeting_join_web'})}
                        </Button>
                      </div>
                      <div style={{height: '50px'}}>
                        {joinLoading && <Spinner animation='border' variant='primary' />}
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            )}
            {meeting && isInTheFuture(meeting) && (
              <>
                <h4>Sự kiện sẽ diễn ra trong</h4>
                <h3 className='text-info'>
                  <Countdown
                    date={meeting.startTimeTs * 1000}
                    daysInHours={false}
                    onComplete={countDownComplete}
                  ></Countdown>
                </h3>
              </>
            )}
            {meeting && isInThePast(meeting) && (
              <h4 className='text-warning'>Sự kiện đã kết thúc!</h4>
            )}
            {!user && !meeting?.guestAllowed && (
              <h4>
                Đây là sự kiện không công khai. Bạn có thể <Link to='/login'>đăng nhập</Link> để
                tham gia hoặc liên hệ người tổ chức để biết thêm thông tin!
              </h4>
            )}
          </div>
          <div className='w-100 text-center mb-4'>
            {intl.formatMessage({id: 'meeting_app_not_install'})}?
            <a href={downloadLink()}> {intl.formatMessage({id: 'meeting_app_install'})}</a>
          </div>
        </div>
      </div>
      <div className='w-100 text-center fixed-bottom mb-4'>
        <div className='row'>
          <div className='col-lg-8'></div>
        </div>
      </div>
      {joinError.code && (
        <ModalErrorJoinMeeting
          show={joinError.code ? true : false}
          close={() => setJoinError({})}
          joinError={joinError}
        />
      )}
    </>
  );
}
