import {useEffect, useState} from 'react';
import {Badge, Dropdown} from 'react-bootstrap';
import debounce from 'debounce-promise';
import {useDispatch} from 'react-redux';
import {searchItemsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {effectiveValue} from 'app/modules/work/helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import './referenceField.scss';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
function ReferenceField({
  value,
  item,
  column,
  mode,
  onChange,
  fieldEditable,
  readOnly,
  containerClass,
}) {
  const reference = column?.reference;
  const field = column?.field;
  const isDetailViewMode = mode === 'detail';
  const [values, setValues] = useState([]);
  const [expandedOnce, setExpandedOnce] = useState(false);

  const {t} = useTranslate();
  const dispatch = useDispatch();

  const search = (text) => {
    return dispatch(searchItemsAction({searchText: text, sheetIds: [reference?.id]}));
  };

  //Prevent loading data too much, only load after the first toggle
  useEffect(() => {
    if (expandedOnce) {
      searchValue('');
    }
  }, [expandedOnce]);

  const searchValue = (searchText) => {
    if (searchText && reference?.id) {
      search(searchText).then((result) => {
        if (result) {
          setValues(result.data);
        }
      });
    }
  };

  const selectValue = (val) => {
    onChange &&
      onChange(item, field, value, {
        id: val._id,
        title: effectiveValue(val.title),
        value: val._id,
        label: effectiveValue(val.title),
      });
  };

  const removeValue = (value) => {
    onChange && onChange(item, field, value, null);
  };

  useEffect(() => {
    if (expandedOnce && reference?.id) {
      dispatch(searchItemsAction({searchText: '', sheetIds: [reference?.id]})).then((res) => {
        setValues(res.data);
      });
    }
  }, [reference?.id, dispatch, expandedOnce]);

  const popover = () => {
    return (
      <div>
        {value?.value && (
          <div className='m-2'>
            <Badge pill bg='light' className='m-1 p-1 align-items-center align-middle'>
              <span className='ms-1 text-dark h-100'>{value.label}</span>
              {fieldEditable && (
                <button
                  className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                  onClick={() => removeValue(value.value)}
                >
                  <i className='las la-times text-muted'></i>
                </button>
              )}
            </Badge>
          </div>
        )}
        <div className='m-4 w-350px overflow-auto'>
          <div className='mb-2 d-flex'>
            <input
              type='text'
              placeholder='Tìm kiếm'
              className='form-control form-control-xs flex-gitem flex-shrink'
              onChange={(e) => debounce(searchValue(e.target.value), 500)}
            ></input>
            <span className='ps-2 text-nowrap'>
              bảng <span className='fw-bold '>{effectiveValue(reference?.title)}</span>
            </span>
          </div>
          <hr className='border-start-1 mt-1' />
          {reference && reference?.id ? (
            <>
              {values?.map((value) => {
                return (
                  <Dropdown.Item
                    key={value._id}
                    size='xs'
                    className='dropdown-item-xs item-column-limit-text-1'
                    onClick={() => {
                      selectValue(value);
                    }}
                    title={effectiveValue(value.title)}
                  >
                    <i className='la la-link me-2'></i>
                    {effectiveValue(value.title)}
                  </Dropdown.Item>
                );
              })}
            </>
          ) : (
            <p className='text-center text-warning'>
              {t('sheet_column_reference_not_choosing_reference')}
            </p>
          )}
        </div>
      </div>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <>
        <DropdownPopover onToggle={() => setExpandedOnce(true)} renderPopover={popover}>
          <div
            variant='link'
            size='xs'
            className='w-100 border-0 overflow-hidden text-start d-flex h-100 align-items-center min-h-30px'
          >
            {value?.label ? (
              <span className='w-100 text-truncate ms-2'>{value?.label}</span>
            ) : isDetailViewMode ? (
              <span className='ps-1 text-gray-500'>{column?.placeholder || ''}</span>
            ) : null}
          </div>
        </DropdownPopover>
      </>
    );
  }

  return (
    <div className='w-100 border-0 overflow-hidden text-start'>
      <span className='w-100 text-truncate ms-2'>{value?.label}</span>
    </div>
  );
}

export default ReferenceField;
