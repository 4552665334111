import React from 'react';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty } from 'lodash';

function NotificationTabContent({listLoading, loadMore, notifications, onClickNotification}) {
  return (
    <>
        {listLoading && !loadMore ? (
          <div className='d-flex w-100 justify-content-center align-items-center'>
            <Spinner animation='border' role='status' variant='secondary'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div>
            <NotificationContentInner
              notificationList={notifications}
              onClickNotification={onClickNotification}
            />
            {listLoading && loadMore && (
              <div className='d-flex w-100 justify-content-center align-items-center'>
                <Spinner animation='border' role='status' variant='secondary'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        )}
    </>
  );
}

const NotificationContentInner = ({notificationList, onClickNotification}) => {
  return (
    <div>
      <div className='d-flex flex-column mt-5 gap-3'>
        {notificationList &&
          notificationList.map((item, index) => (
            <NotifficationItem
              item={item}
              index={index}
              key={index}
              onClickNotification={onClickNotification}
            />
          ))}
      </div>
    </div>
  );
};

const NotifficationItem = ({item, index, onClickNotification}) => {
  if(item?.message){
    const message = item?.message;
    const messageData = isEmpty(message?.data) ? item.data_json?.item : message?.data;

    return (
      <div
        className={`min-h-100px border cursor-pointer d-flex p-3 rounded gap-2 ${
          item.read_at ? 'bg-light' : ''
        }`}
        key={index}
        style={item.read_at ? {} : {backgroundColor: 'rgba(54, 153, 255, 0.25)'}}
        onClick={() =>
          onClickNotification(
            item?.id,
            messageData?.sheet_id || messageData?.sheetId,
            messageData?.item_id || messageData?._id
          )
        }
      >
        {item?.image && (
          <div className='d-flex justify-content-center align-items-center' style={{width: '20%'}}>
            <img
              src={item?.image}
              className='w-100'
              style={{objectFit: 'contain', aspectRatio: '1'}}
              alt='notification'
            />
          </div>
        )}
        <div className='d-flex flex-column gap-2' style={{width: '80%'}}>
          <div className='fs-2 fw-bold'>{message?.title}</div>
          <div>{message?.body}</div>
          {item?.created_at && (
            <div style={{marginTop: 'auto'}}>{moment(item?.created_at).fromNow()}</div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>
  }
};

export default NotificationTabContent;
