import './input.scss';
import React from 'react';

export default function Input({
  label,
  type = 'text',
  focus = false,
  placeholder,
  defaultValue,
  className,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <input
        type={type || 'text'}
        autoComplete={props.autoComplete || 'off'}
        placeholder={placeholder}
        className={`common-input ${className}`}
        {...props}
      />
    </>
  );
}
