import React from 'react';
import {Modal} from 'react-bootstrap';

function ModalMoveItemToGroup({title, show, onHide, listItemSelect, onMoveItems}) {

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <div>{title || ''}</div>
        <div className='d-flex flex-column mt-4'>
          {listItemSelect?.map((item) => {
            return (
              <div
                key={item?.id}
                className='d-flex align-items-center cursor-pointer bg-hover-light p-2 rounded'
                onClick={() => onMoveItems(item)}
              >
                <div className='bg-secondary rounded-circle w-20px h-20px d-flex align-items-center justify-content-center me-2 '>
                  <i className='fa-solid fa-angle-right'></i>
                </div>
                <div>{item?.title}</div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalMoveItemToGroup;
