import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useMemo, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import TemplatePreview from '../item-template/TemplatePreview';
import DropFileInput from 'app/common/_partials/controls/forms/DropFileInput';
import {addMultipleSheetItemsAction, analyzeExcelFileAction} from '../../stores/sheetActions';
import useImportPage from 'app/modules/work/pages/page/add/useImportPage';

const ModalImportItemsFromFile = ({show, sheet, onHide, onSaveSuccess}) => {
  const {t} = useTranslate();
  const [activeKey, setActiveKey] = useState('local');
  const [previewData, setPreviewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sheetId = useMemo(() => sheet?._id, [sheet]);
  const sheetColumns = useMemo(() => sheet?.columns, [sheet]);
  const dispatch = useDispatch();
  const {transformAnalyzeFileToSheet, mappingItemsColumn} = useImportPage();

  const handleClose = () => {
    setIsLoading(false);
    setPreviewData(null);
    onHide();
  };
  const handleUploadFile = async (file) => {
    setIsLoading(true);
    const res = await dispatch(analyzeExcelFileAction(file));
    if (res?.success) {
      let data = transformAnalyzeFileToSheet(res.data);
      data.items = mappingItemsColumn(data.sheet?.columns, sheetColumns, data.items);
      setPreviewData(data);
    }
    setIsLoading(false);
  };

  const renderContent = () => {
    if (activeKey === 'local')
      return (
        <DropFileInput
          onChange={handleUploadFile}
          accept='.csv,.xlsx,.xls,.ppt,.pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.json'
          className='h-100'
          content=''
        />
      );
    if (activeKey === 'link')
      return (
        <div>
          <p>Link</p>
          <input className='form-control' placeholder='link...' />
        </div>
      );
  };

  const handleCreate = () => {
    const {items} = previewData;
    if (sheetId) {
      const params = {
        sheetId: sheetId,
        items: items,
      };
      dispatch(addMultipleSheetItemsAction(params)).then((response) => {
        if (response?.data?.success) {
          const data = response?.data?.data[0];
          onSaveSuccess && onSaveSuccess();
          handleClose();
        }
      });
    }
  };

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('data_page_create_from_file')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className='d-flex justify-content-center align-items-center h-100'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className='d-flex col-12 gap-2'>
              <div className='col-4 d-flex flex-column'>
                <div
                  className='btn btn-light bg-white d-flex align-items-center p-2 gap-3'
                  onClick={() => setActiveKey('local')}
                >
                  <i className='la la-chalkboard' />
                  <span>{t('data_page_create_from_local_file')}</span>
                </div>
                <div
                  className='btn btn-light bg-white d-flex align-items-center p-2 gap-3'
                  onClick={() => setActiveKey('link')}
                >
                  <i className='la la-link' />
                  <span>{t('data_page_create_from_link')}</span>
                </div>
                <div
                  className='btn btn-light bg-white d-flex align-items-center p-2 gap-3'
                  onClick={() => setActiveKey('google')}
                >
                  <i className='la fab la-google-drive' />
                  <span>{t('data_page_create_from_google_drive')}</span>
                </div>
              </div>
              <div className='col-8'>{renderContent()}</div>
            </div>
            {previewData && (
              <div className='w-100 mt-5'>
                <p className='fw-bold'>{t('sheet_view_add_new_page_from_file_preview')}</p>
                <div className='w-100 overflow-auto h-200px'>
                  <TemplatePreview items={previewData.items} columns={sheetColumns} />
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-secondary' onClick={handleClose} disabled={isLoading}>
          {t('common_cancel')}
        </button>
        <button className='btn btn-primary' onClick={handleCreate} disabled={isLoading}>
          {t('common_add')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalImportItemsFromFile;
