import React, {useState, useEffect} from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {ToggleButton} from 'react-bootstrap';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
} from 'date-fns';
import {useTranslate} from 'core/i18n/i18nProvider';

export function SheetDateFilter({filter, column, applyFilter, filterValue}) {
  const {t} = useTranslate();
  const [selectionRange, setSelectionRange] = useState([]);
  const [customDayOption, setCustomDayOption] = useState('before');
  const [customDay, setCustomDay] = useState(null);

  useEffect(() => {
    if (filterValue && filterValue?.value) {
      if (filterValue?.value?.in) {
        setSelectionRange(filterValue?.value?.in);
      }
      if (filterValue?.value?.days) {
        setCustomDay(Number(filterValue?.value?.days));
      }
      if (filterValue?.value?.type) {
        setCustomDayOption(filterValue?.value?.type);
      }
    } else {
      setSelectionRange([]);
    }
  }, [filterValue]);

  const handleSelect = (value) => {
    let newSelectRange = [...selectionRange];
    if (newSelectRange.includes(value)) {
      newSelectRange = newSelectRange.filter((item) => item !== value);
    } else {
      newSelectRange.push(value);
    }
    setSelectionRange(newSelectRange);
    applyRange(newSelectRange);
  };

  const applyRange = (newSelectRange, type, days) => {
    const dynamicDateRangesFilter = dynamicDateRanges.filter((dynamicDateRange) => {
      return newSelectRange.includes(dynamicDateRange.key);
    });
    const ranges = dynamicDateRangesFilter.map((dynamicDateRange) =>
      dynamicDateRange.range(type, days)
    );
    applyFilter &&
      applyFilter(filter, {in: newSelectRange, ranges: ranges, ...(days && {days, type})});
  };

  const isButtonChecked = (value) => {
    return selectionRange.includes(value);
  };

  useEffect(() => {
    let newSelectRange = [...selectionRange];
    if (customDay) {
      if (!newSelectRange.includes('custom-day')) {
        newSelectRange.push('custom-day');
      }
    } else {
      newSelectRange = newSelectRange.filter((item) => item !== 'custom-day');
    }
    applyRange(newSelectRange, customDayOption, customDay);
  }, [customDay, customDayOption]);

  return (
    <div className='d-flex flex-column align-items-stretch w-200px'>
      {dynamicDateRanges.map((value) => {
        if (value.key === 'custom-day')
          return (
            <div className='d-flex gap-2 h-40px'>
              <input
                type='number'
                className='form-control form-control-sm'
                value={customDay}
                onKeyDown={(e) => {
                  if (e.key === '.') {
                    return e.preventDefault();
                  }
                }}
                min={0}
                onChange={(e) => {
                  if (parseFloat(e.target.value) < 0) {
                    e.preventDefault();
                  } else {
                    setCustomDay(parseInt(e.target.value));
                  }
                }}
              />
              <select
                className='form-select p-2'
                value={customDayOption}
                onChange={(e) => {
                  setCustomDayOption(e.target.value);
                }}
              >
                <option value={'before'}>{t('sheet_filter_date_before')}</option>
                <option value={'after'}>{t('sheet_filter_date_after')}</option>
              </select>
            </div>
          );

        return (
          <ToggleButton
            id={`toggle-${column.field}-${value.key}`}
            key={`toggle-${column.field}-${value.key}`}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 text-nowrap text-dark ${
              isButtonChecked(value.key) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            checked={isButtonChecked(value.key)}
            onChange={(e) => handleSelect(value.key)}
          >
            {value?.label}
          </ToggleButton>
        );
      })}
    </div>
  );
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  startOfLastQuarter: startOfQuarter(addMonths(new Date(), -3)),
  endOfLastQuarter: endOfQuarter(addMonths(new Date(), -3)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

function createStaticRanges(ranges) {
  return ranges.map((range) => ({...staticRangeHandler, ...range}));
}

const dynamicDateRanges = createStaticRanges([
  {
    key: 'today',
    label: 'Hôm nay',
    range: () => ({
      startDate: defineds.startOfToday.getTime() / 1000,
      endDate: defineds.endOfToday.getTime() / 1000,
    }),
  },
  {
    key: 'yesterday',
    label: 'Hôm qua',
    range: () => ({
      startDate: defineds.startOfYesterday.getTime() / 1000,
      endDate: defineds.endOfYesterday.getTime() / 1000,
    }),
  },

  {
    key: 'this_week',
    label: 'Tuần này',
    range: () => ({
      startDate: defineds.startOfWeek.getTime() / 1000,
      endDate: defineds.endOfWeek.getTime() / 1000,
    }),
  },
  {
    key: 'last_week',
    label: 'Tuần trước',
    range: () => ({
      startDate: defineds.startOfLastWeek.getTime() / 1000,
      endDate: defineds.endOfLastWeek.getTime() / 1000,
    }),
  },
  {
    key: 'this_month',
    label: 'Tháng này',
    range: () => ({
      startDate: defineds.startOfMonth.getTime() / 1000,
      endDate: defineds.endOfMonth.getTime() / 1000,
    }),
  },
  {
    key: 'last_month',
    label: 'Tháng trước',
    range: () => ({
      startDate: defineds.startOfLastMonth.getTime() / 1000,
      endDate: defineds.endOfLastMonth.getTime() / 1000,
    }),
  },
  {
    key: 'this_quarter',
    label: 'Quý này',
    range: () => ({
      startDate: defineds.startOfQuarter.getTime() / 1000,
      endDate: defineds.endOfQuarter.getTime() / 1000,
    }),
  },
  {
    key: 'last_quarter',
    label: 'Quý trước',
    range: () => ({
      startDate: defineds.startOfLastQuarter.getTime() / 1000,
      endDate: defineds.endOfLastQuarter.getTime() / 1000,
    }),
  },
  {
    key: 'this_year',
    label: 'Năm này',
    range: () => ({
      startDate: defineds.startOfYear.getTime() / 1000,
      endDate: defineds.endOfYear.getTime() / 1000,
    }),
  },
  {
    key: 'custom-day',
    label: '',
    range: (type, days) => {
      if (type === 'before') {
        return {
          startDate: addDays(new Date(), -days).getTime() / 1000,
          endDate: new Date().getTime() / 1000,
        };
      }
      return {
        startDate: new Date().getTime() / 1000,
        endDate: addDays(new Date(), days).getTime() / 1000,
      };
    },
  },
]);
