import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';

function ModalNoteReject({
  show,
  onHide,
  handleExcute,
  page,
  btnHide = true,
  ...props
}) {
  const {t} = useTranslate();
  const [note, setNote] = useState('');

  return (
    <Modal show={show} onHide={onHide} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{t('sheet_table_modal_title_reject')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type='text'
          className='form-control'
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        {btnHide && (
          <Button variant='secondary' onClick={onHide} className='w-150px'>
            {t('common_cancel')}
          </Button>
        )}
        <Button variant={'primary'} onClick={() => handleExcute(note)} className='w-150px'>
          {t('common_save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalNoteReject;
