import ValidationEditor from 'app/modules/work/sheet/validation/ValidationEditor';
import {emitter} from 'core/helpers/EventEmitter';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';

function ModalConfigValidation() {
  const {t} = useTranslate();
  const [column, setColumn] = useState();
  const [onSave, setOnSave] = useState(() => () => {});

  useEffect(() => {
    const subcribe = emitter.addListener('edit-validation', (column, onUpdateValidation) => {
      setColumn(column);
      setOnSave(() => onUpdateValidation)
    });

    return () => {
      subcribe.remove('edit-validation');
    };
  }, []);

  const onHide = () => {
    setColumn(null);
  };

  const handleSave = (rules) => {
    onSave(column, rules);
    onHide();
  };

  return (
    <Modal show={column} onHide={onHide} centered style={{zIndex: 1400}}>
      <Modal.Header closeButton>
        <Modal.Title>{t('sheet_table_view_edit_column_setting_validation')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {column && (
          <ValidationEditor
            name={column?.name}
            type={column?.type}
            rules={column?.rules}
            onSave={handleSave}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalConfigValidation;
