import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import update from 'immutability-helper';
import {cloneDeep, isEqual} from 'lodash';
import {Form, Modal} from 'react-bootstrap';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';

function SheetDataConfigModal({show, onHide, sheet, onSaveSheet, onShowModalAddNew}) {
  const {t} = useTranslate();
  const [columns, setColumns] = useState(sheet?.columns || []);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [columnDelete, setColumnDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = columns[dragIndex];
    setColumns(
      update(columns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedRow],
        ],
      })
    );
  };

  useEffect(() => {
    setColumns(sheet?.columns || []);
  }, [sheet?.columns]);

  const handleSaveSheet = () => {
    setLoading(true);
    if (isEqual(columns, sheet?.columns)) {
      return;
    }
    onSaveSheet(columns, () => {
      setLoading(false)
    });
  };

  useEffect(() => {
    handleSaveSheet();
  }, [columns]);

  const moveColumn = (dragIndex, hoverIndex) => {
    const dragItem = columns[dragIndex];
    const newListColumn = [...columns];
    newListColumn.splice(dragIndex, 1);
    newListColumn.splice(hoverIndex, 0, dragItem);
    setColumns(newListColumn);
  };

  const handleMoveItemUp = (index) => {
    moveColumn(index, index - 1);
  };

  const handleMoveItemDown = (index) => {
    moveColumn(index, index + 1);
  };

  const handleToggleVisibilityColumn = (visibility, field) => {
    const newListColumn = cloneDeep(columns)?.map((column) => {
      if (
        column.field === field ||
        (checkedColumns.includes(column.field) && checkedColumns.includes(field))
      ) {
        if (visibility === 'show') {
          return {
            ...column,
            hidden: true,
          };
        } else {
          return {
            ...column,
            hidden: false,
          };
        }
      } else {
        return column;
      }
    });
    setColumns(newListColumn);
  };

  const isCheckedAll = useMemo(() => {
    const countColumn = columns.filter((column) => !column.deleted)?.length || 0;
    if (checkedColumns.length === countColumn) {
      return true;
    }
    return false;
  }, [checkedColumns, columns]);

  const handleSelectAll = () => {
    if (isCheckedAll) {
      setCheckedColumns([]);
    } else {
      setCheckedColumns(columns?.filter((column) => !column.deleted)?.map((column) => column.field));
    }
  };

  const handleSelectColumn = (column) => {
    if (checkedColumns.includes(column.field)) {
      setCheckedColumns(checkedColumns.filter((field) => field !== column.field));
    } else {
      setCheckedColumns([...checkedColumns, column.field]);
    }
  };

  const handleDeleteColumn = () => {
    const newListColumn = cloneDeep(columns)?.map((column) => {
      if (
        column.field === columnDelete ||
        (checkedColumns.includes(column.field) && checkedColumns.includes(columnDelete))
      ) {
        return {
          ...column,
          deleted: true,
        };
      } else {
        return column;
      }
    });
    setShowModalDelete(false)
    setColumns(newListColumn);
  };

  const handleRenameColumn = (field, newName) => {
    const newListColumn = cloneDeep(columns)?.map((column) => {
      if (column.field === field) {
        return {
          ...column,
          name: newName,
        };
      } else {
        return column;
      }
    });
    setColumns(newListColumn);
  };

  const handleShowModalAddNew = () => {
    onSaveSheet(columns);
    onShowModalAddNew(true);
  };

  const onDeleteColumn = (column) => {
    setColumnDelete(column);
    setShowModalDelete(true);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} fullscreen={true} className='modal-config-sheet'>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_setting_page_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column'>
            <DndProvider backend={HTML5Backend}>
              <table className='table-config'>
                <thead>
                  <tr className='pb-2'>
                    <th>
                      <Form.Check
                        type='checkbox'
                        checked={isCheckedAll}
                        onChange={(e) => {
                          handleSelectAll();
                        }}
                      />
                    </th>
                    <th>{t('sheet_setting_page_name_column')}</th>
                    <th>{t('sheet_setting_page_type_column')}</th>
                    <th>{t('sheet_setting_page_location_column')}</th>
                    <th>{t('sheet_setting_page_visible_column')}</th>
                    <th>{t('sheet_setting_page_delete_column')}</th>
                  </tr>
                </thead>
                <tbody>
                  {columns?.map((row, index) => {
                    if (!row.deleted) {
                      return (
                        <DraggableRow
                          key={row.field}
                          t={t}
                          index={index}
                          row={row}
                          moveRow={moveRow}
                          columns={columns}
                          handleMoveItemUp={handleMoveItemUp}
                          handleMoveItemDown={handleMoveItemDown}
                          handleToggleVisibilityColumn={handleToggleVisibilityColumn}
                          onHandelSelectColumn={handleSelectColumn}
                          onDeleteColumn={onDeleteColumn}
                          checked={checkedColumns.includes(row.field)}
                          handleRenameColumn={handleRenameColumn}
                        />
                      );
                    } else {
                      return <React.Fragment key={row.field} />;
                    }
                  })}
                </tbody>
              </table>
            </DndProvider>
            <div className='d-flex justify-content-center mt-3'>
              <div className='btn btn-primary w-200px' onClick={handleShowModalAddNew}>
                {t('sheet_setting_page_add_column')}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalDelete
        title={'Xoá cột'}
        content={'Bạn có muốn xoá cột'}
        show={showModalDelete}
        close={() => setShowModalDelete(false)}
        onClick={handleDeleteColumn}
        containerClassName={'modal-remove-column'}
      />
    </>
  );
}

export default SheetDataConfigModal;

const ItemType = 'ROW';

const DraggableRow = ({
  row,
  index,
  t,
  moveRow,
  columns,
  handleMoveItemUp,
  handleMoveItemDown,
  handleToggleVisibilityColumn,
  onHandelSelectColumn,
  onDeleteColumn,
  checked,
  handleRenameColumn,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine the middle position of the row
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only move when the mouse has crossed half of the item's height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Move the row and update the state
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: ItemType,
    item: {index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isFirstShowColumn = (index) => {
    const firstShowColumnItemIndex = columns.findIndex((item) => !item.deleted);
    return firstShowColumnItemIndex === index;
  };

  const isLastShowColumn = (index) => {
    const lastShowColumnItemIndex = columns.findLastIndex((item) => !item.deleted);
    return lastShowColumnItemIndex === index;
  };

  const column = listColumns.find((column) => column.value === row.type);

  return (
    <tr ref={ref} style={{opacity: isDragging ? 0.5 : 1}}>
      <td>
        <Form.Check
          type='checkbox'
          checked={checked}
          onChange={(e) => {
            onHandelSelectColumn(row);
          }}
        />
      </td>
      <td>
        <input
          type='text'
          defaultValue={row.name}
          className='border-0'
          onBlur={(e) => handleRenameColumn(row.field, e.target.value)}
        />
      </td>
      <td>{column?.name ? t(column?.name) : row.type}</td>
      <td>
        <span>
          {!isFirstShowColumn(index) ? (
            <i
              className='fa la-arrow-up ms-3 text-gray-600 cursor-pointer'
              onClick={() => handleMoveItemUp(index)}
            />
          ) : (
            <span className='w-20px d-inline-block'></span>
          )}
          {!isLastShowColumn(index) ? (
            <i
              className='fa la-arrow-down ms-3 text-gray-600 cursor-pointer'
              onClick={() => handleMoveItemDown(index)}
            />
          ) : (
            <span className='w-20px d-inline-block'></span>
          )}
        </span>
      </td>
      <td>
        {row.hidden ? (
          <i
            className='las la-eye-slash text-gray-600 cursor-pointer'
            onClick={() => handleToggleVisibilityColumn('hidden', row.field)}
          ></i>
        ) : (
          <i
            className='las la-eye text-gray-600 cursor-pointer'
            onClick={() => handleToggleVisibilityColumn('show', row.field)}
          ></i>
        )}
      </td>
      <td>
        <i
          className='las la-trash-alt cursor-pointer'
          onClick={() => onDeleteColumn(row.field)}
        ></i>
      </td>
    </tr>
  );
};

export const listColumns = [
  {
    name: 'sheet_column_type_priority',
    value: 'color_priority',
    icon: 'la la-list',
  },
  {
    name: 'sheet_column_type_reference',
    value: 'reference',
    icon: 'la la-link',
  },
  {
    name: 'sheet_column_type_timeline',
    value: 'timeline',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_dependency',
    value: 'dependency',
    icon: 'la la-code-fork',
  },
  {
    name: 'sheet_column_type_link',
    value: 'link',
    icon: 'la la-link',
  },
  {
    name: 'sheet_column_type_formula',
    value: 'formula',
    icon: 'la la-code',
  },
  {
    name: 'sheet_column_type_file',
    value: 'file',
    icon: 'la la-file',
  },
  {
    name: 'sheet_column_type_department',
    value: 'department',
    icon: 'la la-building',
  },
  {
    name: 'sheet_column_type_last_updated_date',
    value: 'last_updated_date',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_approval',
    value: 'approval',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_geolocation',
    value: 'geolocation',
    icon: 'la la-map-marked-alt',
  },
  {
    name: 'sheet_column_type_custom_ai_prompt',
    value: 'custom_ai_prompt',
    icon: 'bi bi-magic',
  },
  {
    name: 'sheet_column_type_longtext',
    value: 'longtext',
    icon: 'la la-file-alt',
  },
  {
    name: 'sheet_column_type_text',
    value: 'text',
    icon: 'la la-file-alt',
  },
  {
    name: 'sheet_column_type_number',
    value: 'number',
    icon: 'la la-500px',
  },
  {
    name: 'sheet_column_type_status',
    value: 'status',
    icon: 'la la-list',
  },
  {
    name: 'sheet_column_type_people',
    value: 'people',
    icon: 'la la-user',
  },
  {
    name: 'sheet_column_type_date',
    value: 'date',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_label',
    value: 'color',
    icon: 'la la-list',
  },
];
