import './widgetView.scss';

import {cloneDeep, isEmpty} from 'lodash';

import BatteryChart from 'app/modules/work/sheet/component/view/dashboard/chart/battery';
import {ColumnChart} from './chart/ColumnChart';
import {GanttChart} from './chart/GanttChart';
import ItemTableChart from './chart/itemTable/ItemTableChart';
import NumberChart from './chart/NumberChart';
import {PieChartV2} from './chart/PieChartV2';
import {PivotTableChart} from './chart/PivotTableChart';
import React from 'react';
import {TableChart} from './chart/TableChart';
import {getAggregationName} from 'app/modules/work/helpers';
import {useDataAnalyzer} from './DataAnalyzerHook';
import {useTranslate} from 'core/i18n/i18nProvider';

export default function WidgetView({
  sheet,
  view,
  widget,
  items,
  subSheet,
  subItems,
  className,
  onEdit,
  prefix = '',
  mode = '',
  onUpdateItem,
  onUpdate,
}) {
  const {t} = useTranslate();
  const {
    getChartData,
    getChartColumns,
    getPivotChartColumns,
    getPivotChartData,
    getGanttChartData,
    getNumberChartData,
    getItemTableChartData,
    getSubItemByParentTableChartData,
    columnItemTableChartData,
    getBatteryChartData,
  } = useDataAnalyzer(sheet, widget, items, subItems, subSheet);

  const chartWidth = widget?.chart?.width;

  const renderChart = () => {
    const isUseCount = widget?.data?.metrics[0]?.aggregation === 'count';
    if (
      (widget.data.metrics.length === 0 && widget.chart.type !== 'gantt') ||
      (widget.data.dimensions.length === 0 && !isUseCount) ||
      (isEmpty(widget.data.dimensions) && isEmpty(widget.data.subitem_dimensions)) ||
      (isEmpty(widget.data.dimensions) && widget.chart.type !== 'battery')
    ) {
      return <></>;
    }

    switch (widget.chart.type) {
      case 'pie':
        return (
          <PieChartV2
            data={getChartData()}
            value1Field={`metrics["${widget.data.metrics[0].column}"][${widget.data.metrics[0].aggregation}]`}
            value1Name={getAggregationName(widget.data.metrics[0].aggregation)}
            dimensionField={`dimensions.${widget.data.dimensions[0].column}.label`}
            dimensionFieldName={widget.data.dimensions[0].name}
            key={`${prefix}-widget-${widget.id}`}
            chartId={`${prefix}-widget-${widget.id}`}
          />
        );
      case 'column':
        return (
          <ColumnChart
            chartWidth={chartWidth}
            data={getChartData()}
            series={widget.data.metrics.map((metric, i) => ({
              field: `metrics["${metric.column}"][${metric.aggregation}]`,
              name: getAggregationName(metric.aggregation),
            }))}
            dimensionField={`dimensions.${widget.data.dimensions[0].column}.label`}
            dimensionFieldName={widget.data.dimensions[0].name}
            key={`${prefix}-widget-${widget.id}`}
            chartId={`${prefix}-widget-${widget.id}`}
          />
        );
      case 'table':
        return (
          <TableChart
            data={getChartData()}
            columns={getChartColumns()}
            key={`${prefix}-widget-${widget.id}`}
            chartId={`${prefix}-widget-${widget.id}`}
          />
        );
      case 'pivot':
        return (
          <PivotTableChart
            data={getPivotChartData()}
            columns={getPivotChartColumns()}
            key={`${prefix}-widget-${widget.id}`}
            chartId={`${prefix}-widget-${widget.id}`}
          />
        );
      case 'gantt':
        return (
          <GanttChart
            mode={mode}
            data={getGanttChartData()}
            widget={widget}
            onUpdateItem={onUpdateItem}
          />
        );
      case 'sheet_table':
        return (
          <ItemTableChart
            sheet={sheet}
            subSheet={subSheet}
            data={getItemTableChartData()}
            dateChildren={getSubItemByParentTableChartData()}
            columns={columnItemTableChartData()}
            key={`${prefix}-widget-${widget.id}`}
            chartId={`${prefix}-widget-${widget.id}`}
          />
        );
      case 'number':
        const number = getNumberChartData();
        if (!isNaN(number)) {
          return <NumberChart data={getNumberChartData()} widget={widget} />;
        }
        break;
      case 'battery':
        return (
          <BatteryChart
            data={getBatteryChartData()}
            value1Field={`metrics["${widget.data.metrics[0].column}"][${widget.data.metrics[0].aggregation}]`}
            value1Name={getAggregationName(widget.data.metrics[0]?.aggregation)}
            dimensionField={`label`}
            dimensionFieldName={widget.data.dimensions[0]?.name}
            key={`${prefix}-widget-${widget.id}`}
            chartId={`${prefix}-widget-${widget.id}`}
          />
        );
      default:
        return null;
    }
  };

  const widgetTitle = () => {
    if (widget.chart?.type === 'gantt') {
      return (
        t('widget_chart_type_column') +
        ' ' +
        widget.data.metrics[0]?.name +
        ' ' +
        widget.data.dimensions[0]?.name
      );
    }
    if (widget?.title) {
      return widget?.title;
    }
    return getAggregationName(widget.data.metrics[0]?.aggregation);
  };

  const handleChangeWidgetTitle = (e) => {
    const title = e.target.value;
    const _widget = cloneDeep(widget);
    _widget.chart.title = title;
    onUpdate(_widget);
  };

  return (
    <div
      className={`card card-custom ${className} border border-gray-300 col h-100`}
      key={widget?.id}
    >
      <div className='card-header d-flex align-items-center flex-nowrap p-3 dashboard-widget-header'>
        <h3 className='card-title font-weight-bolder text-dark w-100 text-ellipsis truncate'>
          {mode === 'view' ? (
            widget?.chart?.title || widgetTitle()
          ) : (
            <input
              type='text'
              defaultValue={widget?.chart?.title || widgetTitle()}
              className='py-2 rounded w-100 text-dark bg-transparent widget-title'
              onBlur={handleChangeWidgetTitle}
            />
          )}
        </h3>
        {mode !== 'view' && (
          <div className='card-header-toolbar d-flex align-items-center'>
            <button className='btn btn-icon'>
              <i
                className='las la-pen'
                onClick={() => {
                  onEdit && onEdit(widget);
                }}
              ></i>
            </button>
          </div>
        )}
      </div>
      <div className='card-body d-flex p-0'>{renderChart()}</div>
    </div>
  );
}
