import React, {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {getSheetColumnValuesAction} from '../../../stores/sheetActions';
import {isEmpty} from 'lodash';
import {useTranslate} from 'core/i18n/i18nProvider';

function SheetLinkedFilter({key, sheet, column, filter, filterValue, applyFilter}) {
  const [values, setValues] = useState([]);
  const [linkedOptions, setLinkedOptions] = useState([]);
  const dispatch = useDispatch();
  const {t} = useTranslate();
  useEffect(() => {
    dispatch(getSheetColumnValuesAction({sheetId: sheet?._id, field: column?.field})).then(
      (res) => {
        const linkedData = res?.data ?? [];
        let linkedOptions = [];
        let linkedOptionIds = [];
        linkedData.forEach((lookup) => {
          let value = lookup?.value;
          value.forEach((element) => {
            if (element && !linkedOptionIds.includes(element?.reference_item_id)) {
              linkedOptions.push({
                id: element?.reference_item_id,
                title: element?.reference_display_value,
              });
              linkedOptionIds.push(element?.reference_item_id);
            }
          });
        });
        setLinkedOptions(linkedOptions);
      }
    );
  }, [column]);

  useEffect(() => {
    if (filterValue && filterValue?.value && filterValue?.value?.in) {
      setValues(filterValue?.value?.in);
    } else {
      setValues([]);
    }
  }, [filterValue]);

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);
    applyFilter && applyFilter(filter, {in: newValues});
  };

  return (
    <>
      <div className='d-flex flex-column align-items-stretch '>
        {isEmpty(linkedOptions) && (
          <div className='text-center p-2 bg-secondary rounded w-200px'>
            {t('sheet.filter.no_reference_selected')}
          </div>
        )}
        {linkedOptions?.map((option) => (
          <ToggleButton
            id={`toggle-people-${column.field}-${option.id}`}
            key={`toggle-people-${column.field}-${option.id}`}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 d-flex align-items-center text-start text-nowrap ${
              filterValue?.value?.in?.includes(option.id) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            checked={filterValue?.value.in?.includes(option.id)}
            value='1'
            onChange={(e) => onToggleValue(option.id)}
          >
            <div className='mw-150px text-truncate'>{option?.title}</div>
          </ToggleButton>
        ))}
      </div>
    </>
  );
}

export default SheetLinkedFilter;
