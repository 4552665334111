import {useState} from 'react';
function ActionField({item, onToggleSubitem, showExpand, showDetail}) {
  const [showSubItem, setShowSubItem] = useState(false);

  const toggleShowSubitem = () => {
    let newVisibility = !showSubItem;
    let subitemsContainer = document.getElementById(item._id + '-subitems-container');
    if (subitemsContainer) {
      if (newVisibility) {
        subitemsContainer.style.display = 'block';
      } else {
        subitemsContainer.style.display = 'none';
      }
    }

    setShowSubItem(newVisibility);
    onToggleSubitem(item._id);
  };

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {showExpand && (
        <div
          className='btn btn-icon btn-xs text-nowrap w-30px flex-shrink-0 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            toggleShowSubitem();
          }}
        >
          {showSubItem ? (
            <i className='bi bi-chevron-down '></i>
          ) : (
            <i className='bi bi-chevron-right cursor-pointer'></i>
          )}
        </div>
      )}

      <div
        className='btn btn-icon btn-xs text-nowrap w-40px flex-shrink-0'
        onClick={(e) => {
          e.stopPropagation();
          showDetail(item);
        }}
      >
        <i className='las la-expand-arrows-alt cursor-pointer'></i>
      </div>
    </div>
  );
}

export default ActionField;
