import { useTranslate } from 'core/i18n/i18nProvider';
import {capitalize, cloneDeep} from 'lodash';
import {useState} from 'react';

export const useValidation = ({rules: initRules}) => {
  const defaultRules = [
    {
      ruleOperator: '',
      ruleValue: '',
      ruleValidate: '',
    },
  ];
  const [rules, setRules] = useState(initRules ?? defaultRules);
  const {t} = useTranslate()

  const addNewRule = () => {
    setRules((prev) => [
      ...prev,
      {
        ruleOperator: '',
        ruleValue: '',
      },
    ]);
  };

  const removeRule = (index) => {
    if (rules.length === 1) return;
    let newRules = cloneDeep(rules);
    newRules.splice(index, 1);
    setRules(newRules);
  };

  const processRule = (rule) => {
    let ruleValidate = `${rule?.ruleOperator?.rule}`;
    if (rule?.ruleOperator) {
      ruleValidate = ruleValidate.replace('{value}', `${rule?.ruleOperator?.minusValue ? '-': ''}${rule.ruleValue}`);
    }
    return {
      ...rule,
      ruleValidate,
    };
  };

  const onChangeRule = (index, key, value) => {
    let newRules = cloneDeep(rules);
    let rule = newRules[index];
    rule[key] = value;

    newRules[index] = processRule(rule);
    setRules(newRules);
  };

  const getValidationErrorMessage = (error) => {
    if(error){
      let errorColumn = error.split('#')[0] ?? ''
      let errorMessage = error.split('#')[1] ?? ''
      let errorValue = error.split('#')[2] ?? ''
      return `${t('validation.column')} ${capitalize(errorColumn)} ${t(errorMessage)} ${errorValue}`
    }
    return ''
  }

  return {
    rules,
    addNewRule,
    removeRule,
    onChangeRule,
    getValidationErrorMessage
  };
};
