import {
  addSheetColumnAction, deleteSheetColumnAction,
  getSheetAction,
  moveGroupAction,
  updateSheetAction, updateSheetColumnAction,
} from './stores/sheetActions';
import {
  hasSheetPermission,
  hasViewViewPermission,
} from 'app/modules/work/sheet/permission/permissionHelper';
import {useEffect, useState} from 'react';

import {backgroundColorOptions} from './component/view/table/contains';
import {cloneDeep} from 'lodash';
import {newId} from 'app/common/_helpers/idHelpers';
import update from 'immutability-helper';
import {useDispatch} from 'react-redux';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { AnalyticHandler } from 'core/monitoring/analytic';

export const useSheetMeta = ({sheetId, user, sheet, setSheet}) => {
  const [sheetColumns, setSheetColumns] = useState([]);
  const [sheetViews, setSheetViews] = useState(null);
  const [currentUserMetaPermissions, setSheetMetaUserPermissions] = useState({
    canEdit: false,
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();

  useEffect(() => {
    if (sheetId && user) {
      fetchSheet();
    }
  }, [sheetId]);

  const fetchSheet = async () => {
    setIsLoading(true);
    await dispatch(getSheetAction({id: sheetId})).then((result) => {
      if (result?.data?.data) {
        let _sheet = result.data.data;
        let editable = false;

        if (user?.roles?.includes(`${_sheet.dmn}:root`)) {
          editable = true;
        } else {
          let currentUserMembership = _sheet.members
            ? _sheet.members.filter((x) => x.id === user._id)?.[0]
            : {};
          if (currentUserMembership?.role === 'OWNER') {
            editable = true;
          }
        }

        if (_sheet.master_page && _sheet.master_page._id !== _sheet._id) {
          editable = false;
        }

        setSheetMetaUserPermissions({canEdit: editable});

        setSheet({..._sheet, editable: editable});
        const _columns = _sheet?.master_page?.columns || _sheet.columns;
        setSheetColumns(_columns);
        let _views = _sheet?.master_page?.views ?? _sheet.views ?? [];

        // filter sheet view by people
        _views = _views.filter((view) => {
          if (hasSheetPermission(_sheet, user, 'board.edit') || !view?.is_permitted) {
            return true;
          } else {
            return hasViewViewPermission(user, view);
          }
        });

        const viewsSort = cloneDeep(_views)?.sort((view) => {
          if (view?.is_default) {
            return -1;
          } else {
            return 1;
          }
        });

        // many of the old data sheets lack a default view (the is_default field is not set to true).
        // to address this, set the first element in the list as the default view and display it after fetching the data.
        const hasNoDefaultView = viewsSort?.every((view) => !view?.is_default);

        // set the first sheet view as the default.
        if (hasNoDefaultView && viewsSort?.[0]) {
          viewsSort[0].is_default = true;
        }

        setSheetViews(viewsSort);
      }
    });
    setIsLoading(false);
  };

  const hasSubitems = () => {
    return sheet?.sub_boards?.length > 0 ? true : false;
  };

  const updateSheet = (sheet, field, oldValue, newValue, callback) => {
    let updateParams = {
      id: sheet._id,
    };

    updateParams[field] = newValue;
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((result) => {
      const _sheet = {...sheet};
      _sheet[field] = newValue;
      setSheet(_sheet);
      callback && callback(result);
    });
  };

  const updateSubSheets = (sheet, field, oldValue, newValue, callback) => {
    const subSheetIds = sheet?.sub_boards ?? [];
    subSheetIds.forEach((subSheetId) => {
      let updateParams = {
        id: subSheetId,
      };

      updateParams[field] = newValue;
      AnalyticHandler.trackAction('sheet.update');
      dispatch(updateSheetAction(updateParams)).then((result) => {
        callback && callback(result);
      });
    });
  };
  const showMessageResult = () => {
    snackNotification.showSuccess(t('common_update_success'));
  };
  const updateColumn = (column, callback) => {

    let updateParams = {
      sheetId: sheet._id,
      column,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetColumnAction(updateParams)).then((res) => {
      const _sheet = res?.data?.data;
      setSheet(_sheet);
      callback?.(res) || showMessageResult();
    });
  };

  const deleteColumn = (column, callback) => {
    let params = {
      sheetId: sheet._id,
      column,
    };

    AnalyticHandler.trackAction('sheet.update');
    dispatch(deleteSheetColumnAction(params)).then((res) => {
      const _sheet = res?.data?.data;
      setSheet(_sheet);
      callback?.(res) || showMessageResult();
    });
  };

  const addColumn = (column, callback) => {
    let updateParams = {
      sheetId: sheet._id,
      column,
    };
    AnalyticHandler.trackAction('sheet.update')
    dispatch(addSheetColumnAction(updateParams)).then((res) => {
      if (res?.data?.success) {
        const columns = [...sheet.columns, res?.data?.data];
        setSheet({...sheet, columns});
        callback?.(res) || showMessageResult();
      }
    });
  };

  const addMembers = (members, callback) => {
    if (members?.length > 0) {
      const _sheetMembers = sheet.members || [];
      members.forEach((member) => {
        if (_sheetMembers.filter((x) => x.id === member.id).length === 0) {
          _sheetMembers.push({id: member.id, title: member.title, name: member.name});
        }
      });

      const updateParams = {
        id: sheet._id,
        members: _sheetMembers,
      };
      AnalyticHandler.trackAction('sheet.update');
      dispatch(updateSheetAction(updateParams)).then(() => {
        const _sheet = {...sheet, members: _sheetMembers};
        setSheet(_sheet);
        callback && callback();
      });
    }
  };

  const removeMembers = (members, callback) => {
    if (members?.length > 0 && sheet.members?.length > 0) {
      const _sheetMembers = [];
      sheet.members.forEach((member) => {
        if (members.filter((x) => x.id === member.id).length === 0) {
          _sheetMembers.push(member);
        }
      });

      const updateParams = {
        id: sheet._id,
        members: _sheetMembers,
      };
      AnalyticHandler.trackAction('sheet.update');
      dispatch(updateSheetAction(updateParams)).then(() => {
        const _sheet = {...sheet, members: _sheetMembers};
        setSheet(_sheet);
        callback && callback();
      });
    }
  };

  const setOwners = (members, callback) => {
    if (members?.length > 0 && sheet.members?.length > 0) {
      const _sheetMembers = sheet.members.map((member) => {
        if (members.filter((x) => x.id === member.id).length > 0) {
          return {...member, isOwner: true};
        } else {
          return member;
        }
      });

      const updateParams = {
        id: sheet._id,
        members: _sheetMembers,
      };
      AnalyticHandler.trackAction('sheet.update');
      dispatch(updateSheetAction(updateParams)).then(() => {
        const _sheet = {...sheet, members: _sheetMembers};
        setSheet(_sheet);
        callback && callback();
      });
    }
  };

  const updatePermissions = (permissions, callback) => {
    if (permissions?.length > 0) {
      const _sheetPermissions = permissions.map((permission) => {
        return permission;
      });

      const updateParams = {
        id: sheet._id,
        permissions: _sheetPermissions,
      };
      AnalyticHandler.trackAction('sheet.update');
      dispatch(updateSheetAction(updateParams)).then(() => {
        const _sheet = {...sheet, permissions: _sheetPermissions};
        setSheet(_sheet);
        callback && callback();
      });
    }
  };

  const addView = (view, callback) => {
    //Generate field name
    let newViewCode = new Date().getUTCMilliseconds();
    if (sheetViews?.length > 0) {
      for (let i = 1; i < 100; i++) {
        //Loop until the column name is not exist
        newViewCode = new Date().getTime();
        let foundViews = cloneDeep(sheetViews).filter((x) => {
          return x?.code === newViewCode;
        });
        if (foundViews.length === 0) {
          break;
        }
      }
    }

    let newView = {
      ...view,
      columns: sheet?.columns,
      name: view.name,
      type: view.type,
      code: newViewCode,
    };

    const _sheet = {
      ...sheet,
      views: [...sheetViews, newView],
    };

    setSheetViews(_sheet.views);
    setSheet(_sheet);
    let updateParams = {
      id: sheet._id,
      ..._sheet,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((res) => {
      callback && callback(newView);
    });
  };

  const updateView = (oldView, newView) => {
    if (!oldView || !newView) {
      return;
    }

    const _newSheetViews = sheetViews.map((view) => {
      if (view.code === oldView.code) {
        return newView;
      } else {
        return view;
      }
    });

    const _sheet = {
      ...sheet,
      views: _newSheetViews,
    };
    setSheet(_sheet);
    setSheetViews(_newSheetViews);
    let updateParams = {
      id: sheet._id,
      views: _newSheetViews,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams));
  };

  const updateViews = (views) => {
    const _sheet = {
      ...sheet,
      views: views,
    };
    setSheet(_sheet);
    setSheetViews(views);
    let updateParams = {
      id: sheet._id,
      ..._sheet,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then(() => {
      fetchSheet();
    });
  };

  const deleteView = (viewDelete, callback) => {
    if (!viewDelete) {
      return;
    }

    const _newSheetViews = cloneDeep(sheetViews).flatMap((view) => {
      if (view.code === viewDelete.code) {
        return [];
      } else {
        return view;
      }
    });

    const _sheet = {
      ...sheet,
      views: _newSheetViews,
    };
    setSheet(_sheet);
    setSheetViews(_newSheetViews);
    let updateParams = {
      id: sheet._id,
      ..._sheet,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((res) => {
      if (_newSheetViews?.[0]?.code) {
        callback && callback(_newSheetViews?.[0]);
      }
    });
  };

  const saveViewFilter = (view, filters, callback) => {
    const _newSheetViews = cloneDeep(sheetViews).map((_view) => {
      if (_view.code === view.code) {
        return {
          ..._view,
          rule_filters: filters,
        };
      } else {
        return _view;
      }
    });

    const _sheet = {
      ...sheet,
      views: _newSheetViews,
    };
    setSheet(_sheet);
    setSheetViews(_newSheetViews);
    let updateParams = {
      id: sheet._id,
      ..._sheet,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then(() => {
      callback && callback();
    });
  };

  const addGroup = (title, callback) => {
    const listColorCurrent = sheet.groups
      .filter((group) => group.color)
      .map((group) => group?.color);
    const color = backgroundColorOptions.find((color) => {
      return !listColorCurrent.includes(color.value);
    });
    const newGroup = {
      id: newId(),
      title: title,
      settings: {color: color.value || backgroundColorOptions[0].value},
    };
    const sheetGroups = sheet.groups ? [...sheet.groups, newGroup] : [newGroup];
    const updateParams = {
      id: sheetId,
      groups: sheetGroups,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((result) => {
      const _sheet = {...sheet, groups: sheetGroups};
      setSheet(_sheet);
      callback && callback();
    });
  };

  const removeGroup = (groupId) => {
    const sheetGroups = cloneDeep(sheet.groups)?.filter((group) => {
      return group.id !== groupId;
    });
    const updateParams = {
      id: sheetId,
      groups: sheetGroups,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((result) => {
      const _sheet = {...sheet, groups: sheetGroups};
      setSheet(_sheet);
    });
  };

  const updateGroup = (group, field, oldValue, newValue, callback) => {
    const sheetGroups = sheet.groups.map((sheetGroup) => {
      if (sheetGroup.id === group.id) {
        sheetGroup[field] = newValue;
      }
      return sheetGroup;
    });
    const updateParams = {
      id: sheetId,
      groups: sheetGroups,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((result) => {
      const _sheet = {...sheet, groups: sheetGroups};
      setSheet(_sheet);
      callback && callback();
    });
  };

  const moveGroupToSheet = (newSheet, group, columnsMapping, callback) => {
    const params = {
      oldSheetId: sheetId,
      newSheetId: newSheet._id,
      groupId: group.id,
      columnsMapping: columnsMapping,
    };

    dispatch(moveGroupAction(params)).then((res) => {
      const sheetGroups = cloneDeep(sheet.groups)?.filter((groupItem) => {
        return groupItem.id !== group.id;
      });
      const _sheet = {...sheet, groups: sheetGroups};
      setSheet(_sheet);
      callback && callback();
    });
  };

  const moveGroupToTop = (groupId) => {
    let sheetGroups = cloneDeep(sheet.groups);
    let groupIndex = sheetGroups.findIndex((group) => group.id === groupId);
    if (groupIndex >= 0) {
      sheetGroups.unshift(sheetGroups.splice(groupIndex, 1)[0]);

      const updateParams = {
        id: sheetId,
        groups: sheetGroups,
      };
      AnalyticHandler.trackAction('sheet.update');
      dispatch(updateSheetAction(updateParams)).then((result) => {
        const _sheet = {...sheet, groups: sheetGroups};
        setSheet(_sheet);
      });
    }
  };

  const moveGroupPosition = (groupId, direction) => {
    let sheetGroups = cloneDeep(sheet?.groups);
    const currentIndex = sheetGroups?.findIndex((group) => group.id === groupId);
    const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    if (nextIndex > sheetGroups?.length || nextIndex < 0) return;

    sheetGroups = update(sheetGroups, {
      $splice: [
        [nextIndex, 1],
        [currentIndex, 0, sheetGroups[nextIndex]],
      ],
    });

    const updateParams = {
      id: sheetId,
      groups: sheetGroups,
    };
    AnalyticHandler.trackAction('sheet.update');
    dispatch(updateSheetAction(updateParams)).then((result) => {
      const _sheet = {...sheet, groups: sheetGroups};
      setSheet(_sheet);
    });
  };

  const getColumnsByView = (view) => {
    let columns = sheet?.columns;
    if (view?.columns && columns) {
      let listColumnsByView = view.columns.map((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        return {
          ...columnData,
          hidden: item.hidden,
        };
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: false};
      });
      columns = [...listColumnsByView, ...listColumns];
    }
    return columns;
  };

  return {
    sheetId,
    isLoading,
    sheet,
    currentUserMetaPermissions,
    fetchSheet,
    updateSheet,
    updateSubSheets,
    sheetViews,
    addView,
    updateView,
    updateViews,
    deleteView,
    addMembers,
    removeMembers,
    setOwners,
    updatePermissions,
    sheetColumns,
    updateColumn,
    addColumn,
    deleteColumn,
    hasSubitems,
    addGroup,
    removeGroup,
    updateGroup,
    moveGroupToSheet,
    moveGroupToTop,
    saveViewFilter,
    getColumnsByView,
    moveGroupPosition,
  };
};
