export const simpleInitDocumentRoles = {
  OWNER: {
    active: true,
    name: 'OWNER',
    permissions: {
      document: {
        edit: 'all',
        view: 'all',
      },
      block: {
        view: 'all',
        edit: 'all',
      },
    },
  },
  EDITOR: {
    active: true,
    name: 'EDITOR',
    permissions: {
      document: {
        view: 'all',
      },
      block: {
        view: 'all',
        edit: 'all',
      },
    },
  },
  VIEWER: {
    active: true,
    name: 'VIEWER',
    permissions: {
      document: {
        view: 'all',
      },
      block: {
        view: 'all',
      },
    },
  },
};
