import WorkflowNode from './components/nodes/WorkflowNode';
import WorkflowTriggerNode from './components/nodes/WorkflowTriggerNode';
import WorkflowTempNode from './components/nodes/WorkflowTempNode';
import WorkflowConditionNode from './components/nodes/WorkflowConditionNode';
import WorkflowEdge from './components/edges/WorkflowEdge';
import {cloneDeep, uniqueId} from 'lodash';
import dagre from 'dagre';
const NODE_WIDTH = 300;
const NODE_HEIGHT = 75;

export const worflowInitNodes = [
  {
    id: uniqueId(),
    position: {x: 100, y: 100},
    data: {label: 'Trigger', empty: true},
    type: 'trigger',
  },
];

export const worflowInitEdge = {
  id: '',
  source: '',
  target: '',
  type: 'workflowEdge',
  label: 'Trigger',
};

export const nodeTypes = {
  action: WorkflowNode,
  trigger: WorkflowTriggerNode,
  temp: WorkflowTempNode,
  condition: WorkflowConditionNode,
};

export const edgeTypes = {
  workflowEdge: WorkflowEdge,
};

const workFlowInitActionNode = {
  id: '',
  position: {x: '', y: ''},
  data: {
    icon: 'la la-long-arrow-alt-down',
    label: '',
  },
  type: 'action',
};

const workFlowInitConditionNode = {
  id: '',
  position: {x: '', y: ''},
  data: {
    icon: 'la la-code-fork',
    label: '',
  },
  type: 'condition',
};

const workflowInitTempNode = {
  id: '',
  position: {x: '', y: ''},
  data: {label: '', empty: true},
  type: 'temp',
};

const workflowInitTriggerNode = {
  id: '',
  position: {x: '', y: ''},
  data: {label: ''},
  type: 'trigger',
};

export const workflowCreateNodeDefault = {
  trigger: workflowInitTriggerNode,
  action: workFlowInitActionNode,
  condition: workFlowInitConditionNode,
  temp: workflowInitTempNode,
};

export const worflowNodeTypes = {
  action: 'workflowActionNode',
  condition: 'workFlowInitConditionNode',
};

const edgeTranform = (currentId, prevId, label) => {
  return {
    id: uniqueId(),
    source: prevId,
    target: currentId,
    type: 'workflowEdge',
    label: label,
  };
};

const nodeTranform = (node) => {
  return {
    id: node?._id,
    position: {x: '', y: ''},
    data: {config: node?.config, block: node?.block, label: node?.block?.name},
    type: node?.block?.type,
  };
};

const processTempNode = (nodes, edges) => {
  let newNodes = cloneDeep(nodes);
  let newEdges = cloneDeep(edges);
  let listSource = newEdges.map((edge) => edge.source);
  let lastNodes = newNodes.filter((node) => !listSource.includes(node?.id));

  //create temporary node and edge for last nodes
  lastNodes.forEach((node) => {
    let tempNodeId = uniqueId();
    newNodes.push({
      ...workflowInitTempNode,
      id: tempNodeId,
    });
    newEdges.push({
      id: uniqueId(),
      source: node.id,
      target: tempNodeId,
      type: 'workflowEdge',
      style: {
        strokeDasharray: [5, 10],
      },
    });
  });
  //create temporary node and edge for multi branch node
  newNodes.forEach((node) => {
    let block = node?.data?.block ?? {};
    if (block && block?.branches) {
      let countBranches = block?.branches.length;
      let relateNode = newEdges.filter((edge) => edge?.source === node?.id);
      if (countBranches > relateNode.length) {
        Array.from(Array(countBranches - relateNode.length)).forEach(() => {
          let tempNodeId = uniqueId();
          newNodes.push({
            ...workflowInitTempNode,
            id: tempNodeId,
          });
          newEdges.push({
            id: uniqueId(),
            source: node.id,
            target: tempNodeId,
            type: 'workflowEdge',
            style: {
              strokeDasharray: [5, 10],
            },
          });
        });
      }
    }
  });
  return {
    edges: newEdges,
    nodes: newNodes,
  };
};

const dagreGraph = new dagre.graphlib.Graph();

dagreGraph.setDefaultEdgeLabel(() => ({}));

export const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode = {
      ...node,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - NODE_WIDTH / 2,
        y: nodeWithPosition.y - NODE_HEIGHT / 2,
      },
    };

    return newNode;
  });

  return { nodes: newNodes, edges };
};

export const workflowTranform = async (nodes = []) => {
  let listNode = [];
  let listEdge = [];

  nodes.forEach((node) => {
    listNode.push(nodeTranform(node));
    if (node?.previous && node?.previous_branch && node?.previous_branch !== '') {
      let previousNode = nodes.find((e) => e._id === node?.previous);
      let branches = previousNode?.block?.branches.find(
        (branch) => branch?.value === node?.previous_branch
      );
      let label = branches?.name;
      listEdge.push(edgeTranform(node?._id, node?.previous, label));
    }
  });

  let result = processTempNode(listNode, listEdge);
  let layoutResult = await getLayoutedElements(result?.nodes, result?.edges);
  return layoutResult;
};
