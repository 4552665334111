import {ViewMode} from 'gantt-task-react';

const viewGanttChart = [
  {
    value: ViewMode.Day,
    display: 'Ngày',
  },
  {
    value: ViewMode.Week,
    display: 'Tuần',
  },
  {
    value: ViewMode.Month,
    display: 'Tháng',
  },
];

export const GanttChartViewSelect = ({value, onChange}) => {
  return (
    <>
      {viewGanttChart.map((view) => {
        return (
          <button
            key={view.value}
            className={`btn me-2 btn-sm ${
              view.value === value ? 'btn-info' : 'btn-light'
            } text-capitalize`}
            onClick={() => {
              onChange(view.value);
            }}
          >
            {view.display}
          </button>
        );
      })}
    </>
  );
};
