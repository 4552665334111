import {Modal, OverlayTrigger, Popover} from 'react-bootstrap';
import React, {useMemo, useRef, useState} from 'react';

import {useTranslate} from 'core/i18n/i18nProvider';

const SheetDataViewViewAction = ({
  currentPageView,
  addView,
  deleteView,
  updateView,
  onExportExcel,
  configView,
}) => {
  const {t} = useTranslate();
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [showEditDescriptionModal, setShowEditDescriptionModal] = useState(false);
  const inputNameRef = useRef();
  const inputDescriptionRef = useRef();

  const viewName = useMemo(
    () => (currentPageView?.name !== '' ? currentPageView?.name : currentPageView?.type),
    [currentPageView]
  );

  const handleDeleteView = () => {
    deleteView && deleteView(currentPageView);
    setShowActionMenu(false);
  };

  const hanldeDuplicateView = () => {
    const viewName = currentPageView?.name !== '' ? currentPageView?.name : currentPageView?.type;
    const params = {...currentPageView, name: `${viewName}-copy`};
    addView && addView(params);
    setShowActionMenu(false);
  };

  const handlePrintView = () => {
    setShowActionMenu(false);
    setTimeout(() => {
      window.print();
    }, 300);
  };

  const handleUpdateViewName = () => {
    if (currentPageView) {
      let newName = inputNameRef.current.value;
      let newView = {
        ...currentPageView,
        name: newName,
      };
      updateView && updateView(currentPageView, newView);
      setShowEditNameModal(false);
    }
  };

  const handleUpdateViewDescription = () => {
    if (currentPageView) {
      let newDescription = inputDescriptionRef.current.value;
      let newView = {
        ...currentPageView,
        description: newDescription,
      };
      updateView && updateView(currentPageView, newView);
      setShowEditDescriptionModal(false);
    }
  };

  const handleEditView = () => {
    configView && configView();
    setShowActionMenu(false);
  };

  const handleExportToExcel = () => {
    onExportExcel && onExportExcel();
    setShowActionMenu(false);
  };
  const popover = (
    <Popover>
      <Popover.Body className='p-2'>
        <div className='btn  d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-group' />
          <span>{t('sheet_view_tool_collaborative_view')}</span>
        </div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-arrow-right' />
          <span>{t('sheet_view_tool_assign_as_personal_view')}</span>
        </div>
        <div className='h-8px border-top mx-2'></div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'
          onClick={() => {
            setShowActionMenu(false);
            setShowEditNameModal(true);
          }}
        >
          <i className='la la-edit' />
          <span>{t('sheet_view_tool_rename_view')}</span>
        </div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'
          onClick={() => {
            setShowActionMenu(false);
            setShowEditDescriptionModal(true);
          }}
        >
          <i className='la la-info-circle' />
          <span>{t('sheet_view_tool_edit_view_description')}</span>
        </div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'
          onClick={handleEditView}
        >
          <i className='la la-edit' />
          <span>{t('sheet_view_tool_edit_view_edit')}</span>
        </div>
        <div className='h-8px border-top mx-2'></div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'
          onClick={hanldeDuplicateView}
        >
          <i className='la la-copy' />
          <span>{t('sheet_view_tool_duplicate_view')}</span>
        </div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'>
          <i className='la la-gear' />
          <span>{t('sheet_view_tool_copy_setting')}</span>
        </div>
        <div className='h-8px border-top mx-2'></div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'
          onClick={handleExportToExcel}
        >
          <i className='la la-arrow-circle-o-down' />
          <span>{t('sheet_view_tool_download_excel')}</span>
        </div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2 mb-1'
          onClick={handlePrintView}
        >
          <i className='la la-print' />
          <span>{t('sheet_view_tool_print')}</span>
        </div>
        <div
          className='btn btn-light bg-white d-flex gap-2 align-items-center p-2'
          onClick={handleDeleteView}
        >
          <i className='la la-trash' />
          <span>{t('sheet_view_tool_delete_view')}</span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={popover}
        rootClose
        rootCloseEvent='click'
        show={showActionMenu}
        onToggle={() => setShowActionMenu(!showActionMenu)}
      >
        <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
          <i className='la la-th-list' />
          <span className='text-capitalize'>
            {currentPageView?.name && currentPageView?.name === ''
              ? currentPageView?.name
              : currentPageView?.type}
          </span>
          <i className='la la-angle-down' />
        </div>
      </OverlayTrigger>
      <Modal show={showEditNameModal} onHide={() => setShowEditNameModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_view_tool_rename_view')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{t('sheet_view_tool_rename_view')}</label>
          <input
            className='form-control'
            ref={inputNameRef}
            defaultValue={viewName}
            placeholder={t('sheet_view_setting_name')}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={() => setShowEditNameModal(false)}>
            {t('common_cancel')}
          </button>
          <button className='btn btn-primary' onClick={handleUpdateViewName}>
            {t('common_add')}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditDescriptionModal} onHide={() => setShowEditDescriptionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_view_tool_edit_view_description')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{t('sheet_view_tool_edit_view_description')}</label>
          <input
            className='form-control'
            ref={inputDescriptionRef}
            defaultValue={currentPageView?.description}
            placeholder={t('sheet_item_detail_comment')}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={() => setShowEditDescriptionModal(false)}>
            {t('common_cancel')}
          </button>
          <button className='btn btn-primary' onClick={handleUpdateViewDescription}>
            {t('common_add')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SheetDataViewViewAction;
