import { DropdownField } from 'app/common/_partials/controls/forms/DropdownField';
import { useTranslate } from 'core/i18n/i18nProvider';
import { Field, FieldArray } from 'formik';
import { backgroundColorOptions } from '../../../../view/table/contains';
import { SelectField } from 'app/common/_partials/controls';
import { SelectSearchField } from 'app/common/_partials/controls/forms/SelectSearchField';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';

// Tạo component mới cho item có thể kéo thả
const DraggableStatusItem = ({ statusValue, index, moveItem, ...props }) => {
  const ref = useRef(null);
  
  const [{ isDragging }, drag] = useDrag({
    type: 'status-item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'status-item',
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div 
      ref={ref} 
      className='d-flex mb-1' 
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <i className="la la-grip-vertical me-2 d-flex align-items-center" />
      {props.children}
    </div>
  );
};

function ConfigStatusColumn({values}) {
  const {t} = useTranslate();

  const moveItem = (arrayHelpers, dragIndex, hoverIndex) => {
    const dragItem = values.values[dragIndex];
    const newItems = [...values.values];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);
    arrayHelpers.form.setFieldValue('values', newItems);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='form-group row mb-2 '>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          <label>{t('sheet_item_label_status')}:</label>
        </div>
        <div className='col-9'>
          <FieldArray
            name='values'
            render={(arrayHelpers) => (
              <div>
                {values.values && values.values.length > 0 ? (
                  values.values.map((statusValue, index) => (
                    <DraggableStatusItem 
                      key={index}
                      index={index}
                      statusValue={statusValue}
                      moveItem={(dragIndex, hoverIndex) => moveItem(arrayHelpers, dragIndex, hoverIndex)}
                    >
                      <Field
                        className='form-control form-control-xs'
                        name={`values.${index}.label`}
                      />
                      <Field
                        component={DropdownField}
                        className='form-control form-control-xs ms-1 w-60px'
                        name={`values.${index}.backgroundColor`}
                        displayValue={false}
                        style={{backgroundColor: statusValue.backgroundColor}}
                        optionFormatter={(option) => (
                          <div className='d-flex align-items-center'>
                            <span
                              className='w-20px h-20px rounded-circle px-2 d-inline-block'
                              style={{backgroundColor: `${option.value}`}}
                            ></span>
                            <span className='font-size-sm ms-2'>{option.label}</span>
                          </div>
                        )}
                        optionClassName='py-2'
                        options={backgroundColorOptions}
                      />
                      <button
                        type='button'
                        className='btn btn-icon btn-hover-light btn-xs ms-1 w-40px'
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className='la la-trash' />
                      </button>
                    </DraggableStatusItem>
                  ))
                ) : (
                  <></>
                )}
                {
                  <button
                    className='btn btn-light btn-xs w-100'
                    type='button'
                    onClick={() =>
                      arrayHelpers.push({
                        label: '',
                        value: 'value_' + new Date().getTime(),
                      })
                    }
                  >
                    + Value
                  </button>
                }
              </div>
            )}
          />
        </div>
      </div>
      <div className='form-group row mb-2 '>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          <label>{t('sheet_column_setting_default')}:</label>
        </div>
        <div className='col-9'>
          <SelectField name='default'>
            <option value=''>{t('sheet_item_option_empty')}</option>
            {values.values.map((val) => {
              return (
                <option value={val.value} key={val.value}>
                  {val.label}
                </option>
              );
            })}
          </SelectField>
        </div>
      </div>
      <div className='form-group row mb-2 '>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          <label>{t('sheet_column_setting_done_values')}:</label>
        </div>
        <div className='col-9'>
          <SelectSearchField
            name='done_values'
            isMulti
            options={values.values
              ?.filter((item) => item.value)
              ?.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
          ></SelectSearchField>
        </div>
      </div>
    </DndProvider>
  );
}

export default ConfigStatusColumn;