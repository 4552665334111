import {useEffect, useMemo} from 'react';
import FormEdit from './components/form-edit/FormEdit';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getFormDetailAction, updateFormAction} from '../stores/form/action';
import './formEditView.scss';

export const hiddenColumns = [
  'approval',
  'people',
  'dependency',
  'department',
  'formula',
  'reference',
  'complete',
  'last_updated_date',
  'boolean',
  'index',
];

function FormEditView({formId}) {
  const dispatch = useDispatch();
  const {form} = useSelector((state) => state.storeForm, shallowEqual);

  const handUpdateForm = (updatedFormPage) => {
    dispatch(updateFormAction(form._id, updatedFormPage)).then((res) => {
      dispatch(getFormDetailAction(form._id));
    });
  };

  useEffect(() => {
    if (formId && !form) {
      dispatch(getFormDetailAction(formId));
    }
  }, [formId, form]);

  const listField = useMemo(() => {
    let columns = form?.sheet_info?.columns || [];

    let listColumnsByView = [];
    form?.settings?.columns?.forEach((item) => {
      let columnData = columns.find((column) => column.field === item.field);
      if (columnData) {
        listColumnsByView.push({
          ...columnData,
          hidden: item.hidden,
          deleted: columnData.deleted,
        });
      }
    });
    let listColumns = columns.filter(
      (item) => !listColumnsByView.find((column) => column.field === item.field)
    );
    listColumns = listColumns.map((item) => {
      return {
        ...item,
        hidden: item.hidden === undefined ? true : item.hidden,
        deleted: item.deleted,
      };
    });
    columns = [...listColumnsByView, ...listColumns];
    return columns;
  }, [form?.settings?.columns, form?.sheet_info?.columns]);

  return (
    <div className='form-edit-view'>
      <FormEdit
        isEditMode={true}
        listField={listField}
        form={form}
        onUpdate={handUpdateForm}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
}

export default FormEditView;
