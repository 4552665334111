import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {Field, FieldArray} from 'formik';
import {DropdownField} from 'app/common/_partials/controls/forms/DropdownField';
import {APPROVAL_TYPE} from '../../../field/approval/contants';
import {CheckBoxField, RadioField} from 'app/common/_partials/controls';
import ColumnUserPermittedApprovalEdit from '../../../sheet-config/column/ColumnUserPermittedApprovalEdit';
import {SelectSearchField} from 'app/common/_partials/controls/forms/SelectSearchField';
import {cloneDeep, isArray} from 'lodash';
import {newId} from 'app/common/_helpers/idHelpers';
import clsx from 'clsx';

function ApprovalColumnConfig({values, setFieldValue, setColumnEdit, columns}) {
  const {t} = useTranslate();

  return (
    <>
      <div className='form-group row mb-2 '>
        <div className='col-1 d-flex align-items-center justify-content-end'>
          <label>{t('sheet_column_edit_title_process')}:</label>
        </div>
        <div className='col-11'>
          <div className='overflow-scroll'>
            <div className='d-flex bg-light rounded px-1 py-3 mb-3 title-steps'>
              <div className='w-200px text-center flex-shrink-0'>
                {t('sheet_column_edit_title_status')}
              </div>
              <div className='w-100px text-center flex-shrink-0'>
                {t('sheet_column_edit_title_type')}
              </div>
              <div className='w-150px text-center flex-shrink-0'>
                {t('sheet_column_edit_title_mandatory')}
              </div>
              <div className='w-150px text-start flex-shrink-0'>
                {t('sheet_column_edit_title_allow_end')}
              </div>
              <div className='w-150px text-center flex-shrink-0'>
                {t('sheet_column_edit_title_due')}
              </div>
              <div className='w-150px text-center flex-shrink-0'>
                {t('sheet_column_edit_approve')}
              </div>
              <div className='w-150px text-center flex-shrink-0'>
                {t('sheet_column_edit_person')}
              </div>
              <div className='w-400px text-center flex-shrink-0'>
                {t('sheet_column_edit_action')}
              </div>
              <div className='w-150px text-center flex-shrink-0'>
                {t('sheet_column_edit_reopen')}
              </div>
              <div className='w-150px text-center flex-shrink-0'>
                {t('sheet_column_edit_notification')}
              </div>
            </div>
            <FieldArray
              name='steps'
              render={(arrayHelpers) => (
                <div>
                  {values.steps && values.steps.length > 0 ? (
                    values.steps.map((statusValue, index) => (
                      <div key={statusValue.id || statusValue.value} className='d-flex mb-2'>
                        <Field
                          className='form-control form-control w-200px flex-shrink-0'
                          name={`steps.${index}.name`}
                        />
                        <div className='w-100px flex-shrink-0'>
                          {index > 0 && (
                            <Field
                              component={DropdownField}
                              className={`form-control form-control ms-1 w-100 ${clsx({
                                'opacity-50': index <= 1,
                              })}`}
                              name={`steps.${index}.step_type`}
                              displayValue={false}
                              optionClassName='py-2'
                              disabled={index <= 1}
                              options={[
                                {
                                  label: t('sheet_table_column_edit_parallel'),
                                  value: APPROVAL_TYPE.PARALLEL,
                                },
                                {
                                  label: t('sheet_table_column_edit_serial'),
                                  value: APPROVAL_TYPE.SERIAL,
                                },
                              ]}
                            />
                          )}
                        </div>
                        <div className='w-150px flex-shrink-0 d-flex align-items-center justify-content-center'>
                          {index > 0 && (
                            <Field
                              name={`steps.${index}.mandatory`}
                              component={CheckBoxField}
                              disabled={index === 0}
                              label={t('sheet_column_edit_title_mandatory')}
                            />
                          )}
                        </div>
                        <div className='w-150px flex-shrink-0 d-flex align-items-center'>
                          {index > 0 && index < values.steps.length - 1 && (
                            <Field
                              component={RadioField}
                              name={`allowEnd`}
                              value={values.steps[index].id}
                              label={t('sheet_table_column_edit_allow_end')}
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setFieldValue('allowEnd');
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className='w-150px flex-shrink-0 ms-2'>
                          {index > 0 && (
                            <Field
                              className='form-control form-control'
                              name={`steps.${index}.completion_time`}
                              type='number'
                            />
                          )}
                        </div>
                        <div className='w-150px flex-shrink-0 rounded ms-2'>
                          <Field
                            className='form-control form-control'
                            name={`assignee_role_users.${index}.title`}
                          />
                        </div>
                        <div
                          className={`form-control form-control flex-shrink-0 w-150px border ms-2`}
                        >
                          <ColumnUserPermittedApprovalEdit
                            value={{
                              value: values.assignee_role_users?.[index]?.assignees,
                              columns: values.assignee_role_users?.[index]?.columns,
                            }}
                            column={{type: 'people', display_mode: 'full'}}
                            sheetColumns={columns}
                            fieldEditable={true}
                            onChange={(item, column, value, newPeople) => {
                              setFieldValue(`assignee_role_users.${index}.assignees`, newPeople);
                            }}
                            onChangeUserColumn={(columns) => {
                              setFieldValue(`assignee_role_users.${index}.columns`, columns);
                            }}
                          />
                        </div>
                        <div className='w-400px flex-shrink-0 px-3 d-flex align-items-center justify-content-center'>
                          {index > 0 && (
                            <div className='w-100 d-flex align-items-center justify-content-between'>
                              <div className='d-flex align-items-center'>
                                <Field
                                  name={`steps.${index}.actionReject`}
                                  component={CheckBoxField}
                                  labelClass={'d-flex align-items-center'}
                                />
                                <Field
                                  className='form-control w-100px'
                                  name={`steps.${index}.titleActionReject`}
                                  placeholder={t('sheet_table_column_approval_reject')}
                                />
                              </div>
                              <div className='d-flex align-items-center ms-2'>
                                <Field
                                  name={`steps.${index}.actionReopen`}
                                  component={CheckBoxField}
                                  labelClass={'d-flex align-items-center'}
                                />
                                <Field
                                  className='form-control w-100px'
                                  name={`steps.${index}.titleActionReopen`}
                                  placeholder={t('sheet_table_column_approval_reopen')}
                                />
                              </div>
                              <div className='d-flex align-items-center ms-2'>
                                <Field
                                  name={`steps.${index}.actionApprove`}
                                  component={CheckBoxField}
                                  labelClass={'d-flex align-items-center'}
                                />
                                <Field
                                  className='form-control w-100px'
                                  name={`steps.${index}.titleActionApprove`}
                                  placeholder={t('sheet_table_column_approval_approve')}
                                />
                              </div>
                            </div>
                          )}
                          {index === 0 && (
                            <div className='w-100 d-flex align-items-center justify-content-between'>
                              <div className='d-flex align-items-center'>
                                <Field
                                  name={`steps.${index}.actionCreateApproval`}
                                  component={CheckBoxField}
                                />
                                <Field
                                  className='form-control w-350px'
                                  name={`steps.${index}.titleActionCreateApproval`}
                                  placeholder={t('sheet_table_column_approval_create_approve')}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='w-150px flex-shrink-0 d-flex align-items-center'>
                          {index > 0 && (
                            <Field
                              component={DropdownField}
                              className={`form-control border form-control ms-1 me-3 w-150px`}
                              style={{height: '43px'}}
                              name={`steps.${index}.reopenStep`}
                              displayValue={false}
                              optionClassName='py-2'
                              options={[
                                {
                                  label: t('sheet_table_column_approval_empty_reopen'),
                                  value: null,
                                },
                                ...values.steps.flatMap((step, idx) => {
                                  if (idx < index) {
                                    return {
                                      label: step.name,
                                      value: step.id,
                                    };
                                  } else {
                                    return [];
                                  }
                                }),
                              ]}
                            />
                          )}
                        </div>
                        <div className={`w-150px flex-shrink-0 ms-5`}>
                          <SelectSearchField
                            isMulti={true}
                            name={`steps.${index}.notifications`}
                            options={values.assignee_role_users.map((assignee) => ({
                              label: assignee.title,
                              value: assignee.id,
                            }))}
                            closeMenuOnSelect={true}
                          />
                        </div>
                        {index > 0 ? (
                          <button
                            type='button'
                            className={
                              'btn btn-icon btn-hover-light btn-xs ms-1 w-40px flex-shrink-0'
                            }
                            onClick={() => {
                              const cloneColumnEdit = cloneDeep(values);
                              cloneColumnEdit.assignee_role_users.splice(index, 1);
                              cloneColumnEdit.steps.splice(index, 1);
                              setColumnEdit(cloneColumnEdit);
                            }}
                          >
                            <i className='la la-trash' />
                          </button>
                        ) : (
                          <div className='w-40px'></div>
                        )}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  {
                    <button
                      className='btn btn-light btn-xs flex-shrink-0 add-new-step'
                      type='button'
                      onClick={() => {
                        const approverId = newId();
                        const newSteps = {
                          id: 'value_' + new Date().getTime(),
                          name: 'Bước duyệt',
                          step_type: 'serial',
                          assignee_role: approverId,
                          actionReject: true,
                          actionReopen: true,
                          actionApprove: true,
                        };
                        arrayHelpers.push(newSteps);
                        const cloneColumnEdit = cloneDeep(values);
                        if (!isArray(cloneColumnEdit.assignee_role_users)) {
                          cloneColumnEdit.assignee_role_users = [];
                        }
                        cloneColumnEdit.assignee_role_users.push({
                          id: approverId,
                          title: '',
                        });
                        if (!isArray(cloneColumnEdit.steps)) {
                          cloneColumnEdit.steps = [];
                        }
                        cloneColumnEdit.steps.push(newSteps);
                        setColumnEdit(cloneColumnEdit);
                      }}
                    >
                      + Value
                    </button>
                  }
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ApprovalColumnConfig;
