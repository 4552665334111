import './styles.scss';

import * as Yup from 'yup';

import {Field, Form, Formik} from 'formik';
import {Input, LoadingDialog, RadioField, SelectField} from 'app/common/_partials/controls';
import React, {useEffect, useState} from 'react';
import {boardType, boardTypes, subType} from '../constant';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import DropFileInput from 'app/common/_partials/controls/forms/DropFileInput';
import {Modal} from 'react-bootstrap';
import MultiRadioButton from 'app/common/_partials/controls/forms/MultiRadioButton';
import {TreeSelectField} from 'app/common/_partials/controls/forms/TreeSelectField';
import {cloneDeep} from 'lodash';
import {uploadFileAction} from 'app/modules/work/document/stores/actions';
import {useTranslate} from 'core/i18n/i18nProvider';

const TreeOptionPage = ({pages, dept = 0}) => {
  return (
    <>
      {pages.map((page) => {
        if (page.type === 'page') {
          return (
            <React.Fragment key={page._id}>
              <option value={page._id}>
                {'__'.repeat(dept)}
                {page.name}
              </option>
              {page.children && <TreeOptionPage pages={page.children} dept={dept + 1} />}
            </React.Fragment>
          );
        }
        return <React.Fragment key={page._id}></React.Fragment>;
      })}
    </>
  );
};

function PageAddForm({savePage, initPage, onHide, options}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const PageAddSchema = Yup.object().shape({
    name: Yup.string().required(t('page_name_is_required')),
  });

  const {currentWorkspacePages, actionsLoading} = useSelector((state) => state.work, shallowEqual);
  const [treeData, setTreeData] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);

  const [terminologyOptions, setTerminologyOptions] = useState([]);
  const terminologyItems = require('./terminologyList.json');

  useEffect(() => {
    const _terminologyItems = cloneDeep(terminologyItems);
    setTerminologyOptions(
      _terminologyItems.map((terminologyItem) => ({
        text: terminologyItem.name,
        value: terminologyItem.type,
      }))
    );
  }, []);

  const privacyOptions = [
    {value: 'public', text: t('page_privacy_public')},
    {value: 'private', text: t('page_privacy_private'), icon: <i className='las la-lock me-2'></i>},
    {
      value: 'shareable',
      text: t('page_privacy_shareable'),
      icon: <i className='bi bi-share me-2'></i>,
    },
  ];

  useEffect(() => {
    if (currentWorkspacePages.allPages) {
      const newData = [];
      const itemMap = {};
      currentWorkspacePages.allPages.forEach((item) => {
        itemMap[item._id] = item;
      });

      const newItems = (children) => {
        if (Array.isArray(children)) {
          children.forEach((item) => {
            if (!item) return;
            if (item.type === 'page') {
              const data = {
                name: item.name,
                id: item._id,
                _id: item._id,
                droppable: true,
                parent: item.parent ? item.parent : 0,
                active: false,
                type: item.type,
                groups: item.groups,
                isTemplate: item.is_template,
                templateId: item.template_id,
              };

              if (item.parent && itemMap[item.parent]) {
                data['parent'] = item.parent;
              } else {
                data['parent'] = 0;
              }

              newData.push(data);
            }
          });
        }
      };
      newItems(currentWorkspacePages.allPages);
      setTreeData(newData);
    }
  }, [currentWorkspacePages.allPages]);

  const onUploadFile = (file) => {
    setFileUpload(file);
  };

  const getTerminologyObjectByType = (terminologyType) => {
    let terminologyItem = terminologyItems.find(
      (terminologyItem) => terminologyItem.type === terminologyType
    );
    if (!terminologyItem) {
      terminologyItem = {
        type: terminologyType,
        name: terminologyType,
        singular: terminologyType,
        plural: terminologyType,
      };
    }

    return terminologyItem;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initPage}
        validationSchema={PageAddSchema}
        onSubmit={(values) => {
          if (values?.terminology) {
            values.terminology = getTerminologyObjectByType(values?.terminology);
          }

          if (fileUpload) {
            const uploadFileParams = {
              file: fileUpload,
            };
            dispatch(uploadFileAction(uploadFileParams)).then((result) => {
              savePage({...values, file: result});
            });
          } else {
            savePage(values);
          }
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, setFieldValue, values}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('rubric_form_loading')} />
              <Modal.Body className='overlay cursor-default'>
                <Form className='form form-label-right form-add-page'>
                  <div className='form-group row mb-2'>
                    <div className='col-lg-12'>
                      <SelectField
                        disabled
                        name='type'
                        label={t('page_form_add_type')}
                        customFeedbackLabel
                        withFeedbackLabel
                      >
                        {boardTypes.map((type) => (
                          <option key={type?.type} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </SelectField>
                    </div>
                  </div>
                  <div className='form-group row mb-2'>
                    <div className='col-lg-12'>
                      <Field
                        name='name'
                        component={Input}
                        placeholder={t('page_form_add_name')}
                        label={t('page_form_add_name')}
                        focus
                      />
                    </div>
                  </div>
                  {!options?.disableParent && (
                    <div className='form-group row'>
                      <div className='col-lg-12 mb-2'>
                        <TreeSelectField
                          placeholder='Chọn thư mục cha'
                          treeData={treeData}
                          name='parent'
                          label={t('page_form_add_parent')}
                          customFeedbackLabel
                          withFeedbackLabel
                        />
                      </div>
                    </div>
                  )}

                  {fileUpload?.type && (
                    <div className='form-group row'>
                      <div className='col-lg-12 mb-2'>
                        <label>{t('page_form_add_file')}</label>
                        <div className='p-3 rounded border d-flex align-items-center'>
                          {fileUpload?.name}
                          <div className='ms-auto'>
                            <i
                              className='las la-times cursor-pointer text-dark fs-4'
                              onClick={() => {
                                setFileUpload(null);
                                setFieldValue('type', boardType.DOC);
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!fileUpload?.type && initPage.type === boardType.DOC && (
                    <div className='form-group row'>
                      <div className='col-lg-12 mb-2'>
                        <Field
                          name='google_doc'
                          component={Input}
                          placeholder={t('page_form_add_google_doc')}
                          label={t('page_form_add_google_doc')}
                        />
                      </div>
                    </div>
                  )}
                  {!values.google_doc && !fileUpload?.type && initPage.type === boardType.DOC && (
                    <div className='form-group row'>
                      <div className='col-lg-12 mb-2'>
                        <label>{t('page_form_add_file')}</label>
                        <DropFileInput
                          accept='.pdf'
                          onChange={(file) => {
                            onUploadFile(file);
                            setFieldValue('subType', subType.PDF);
                          }}
                          content={t('page_form_add_file_from_computer')}
                        />
                      </div>
                    </div>
                  )}
                  <div className='form-control mt-5'>
                    <div className='form-group row '>
                      <div className='col-lg-6 mb-2 mt-2'>
                        <Field
                          name='confidential'
                          component={RadioField}
                          value='allow_search'
                          label={t('page_form_add_allow_search')}
                        />
                      </div>
                      <div className='col-lg-6 mb-2 mt-2'>
                        <Field
                          name='confidential'
                          component={RadioField}
                          value='not_allow_search'
                          label={t('page_form_add_not_allow_search')}
                        />
                      </div>
                    </div>
                  </div>
                  {initPage?.type === 'sheet' && (
                    <div className='form-group mt-5'>
                      <MultiRadioButton
                        label={t('sheet_detail_permission_dialog_settings_terminology')}
                        name='terminology'
                        defaultValue='task'
                        options={terminologyOptions}
                        className='terminology-field '
                        controlClass='form-control'
                        useCustomValue
                      />
                    </div>
                  )}

                  <div className='form-group mt-5'>
                    <MultiRadioButton
                      label={t('page_privacy')}
                      name='privacy'
                      defaultValue={initPage?.type === 'doc' ? 'public' : 'private'}
                      options={privacyOptions}
                      className='privacy-field'
                      controlClass='form-control'
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                  {t('common_cancel')}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary btn-elevate'
                  onClick={handleSubmit}
                >
                  {t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default PageAddForm;
