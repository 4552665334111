import {detectOverflow} from '@popperjs/core';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import './dropdownPopover.scss';
import clsx from 'clsx';

const DropdownPopover = forwardRef(
  (
    {
      children,
      renderPopover,
      onToggle,
      triggerClassName,
      popoverClassName,
      popoverContainerClassName,
    },
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [placement, setPlacement] = useState('bottom-start');
    const triggerRef = useRef();

    useImperativeHandle(ref, () => ({
      setShowDialog(newState) {
        setShow(newState);
      },
    }));

    const handlePlacement = () => {
      if (triggerRef.current) {
        const windowBreakPoint = window.innerHeight / 2 + 100;
        if (windowBreakPoint < triggerRef.current.getBoundingClientRect().y) {
          setPlacement('top-start');
        } else {
          setPlacement('bottom-start');
        }
      }
    };

    const handleToggle = () => {
      setShow(!show);
      onToggle && onToggle(true);
    };

    useLayoutEffect(() => {
      if (triggerRef.current) {
        handlePlacement();
      }
    }, [triggerRef]);

    const popperOverflowModifier = {
      name: 'popperOverflowModifier',
      enabled: true,
      phase: 'main',
      requiresIfExists: ['offset'],
      fn({state}) {
        const overflow = detectOverflow(state, {
          rootBoundary: 'document',
        });
        if (overflow.top >= 0) {
          setShow(false);
        }
      },
    };

    const onHide = () => {
      setShow(false);
      onToggle && onToggle(false);
    };

    const elementInViewport = (element) => {
      var top = element.offsetTop;
      var left = element.offsetLeft;
      var width = element.offsetWidth;
      var height = element.offsetHeight;

      while (element.offsetParent) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
      }

      return (
        top >= window.scrollY &&
        left >= window.scrollX &&
        top + height <= window.scrollY + window.innerHeight &&
        left + width <= window.scrollX + window.innerWidth
      );
    };

    useEffect(() => {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (triggerRef?.current && !elementInViewport(triggerRef.current)) {
            setShow(false);
          }
        });
      });
      if (triggerRef?.current) {
        observer.observe(triggerRef?.current, {
          root: document,
          rootMargin: '0px',
          threshold: '1.0',
        });
      }
      return () => {
        observer.disconnect();
      };
    }, [triggerRef]);

    return (
      <>
        <div
          ref={triggerRef}
          className={clsx('h-100 w-100 d-flex align-items-center bg-inherit', triggerClassName)}
          onClick={handleToggle}
        >
          {children}
        </div>
        <Overlay
          target={triggerRef.current}
          show={show}
          rootClose={true}
          trigger='click'
          placement={placement}
          onEntering={handlePlacement}
          onHide={onHide}
          rootCloseEvent={'mousedown'}
          popperConfig={{
            modifiers: [popperOverflowModifier],
          }}
        >
          <Popover bsPrefix={clsx('popover', popoverClassName)}>
            <div className={clsx('popover-container', popoverContainerClassName)}>
              {renderPopover && renderPopover()}
            </div>
          </Popover>
        </Overlay>
      </>
    );
  }
);

export default DropdownPopover;
