import {deleteApiV2, getApiV2, postApiV2, putApiV2, postApiIntegration} from 'core/store/api';

const endPoints = {
  searchApps: '/api/app/search',
  createApp: '/api/app',
  updateApp: (id) => `/api/app/${id}`,
  deleteApp: (id) => `/api/app/${id}`,
  createSheet: '/api/page',
  getApp: (id) => `/api/app/${id}`,
  aiAssistant: '/api/ai-assistant/action',
  generateApp: '/api/app/import',
  generateAppData: '/api/app/import-items',
  generateAppSheet: (id) => `/api/app/${id}/import-pages`,
  createItem: (id) => `/api/sheet/${id}/item`,
  createTokenShareForm: `/api/app/share-form`,
  getShareFormByToken: (token) => `/api/app/share-form/${token}`,
  createSheetForm: `/api/sheet-form`,
  deleteSheetForm: (id) => `/api/sheet-form/${id}`,
  getFormByApp: (appId) => `/api/sheet-form/by-app/${appId}`,
  getFormDetail: (id) => `/api/sheet-form/form/${id}`,
  updateForm: (id) => `/api/sheet-form/${id}`,
  getKeyShareForm: (id) => `/api/sheet-form/get-form-share/${id}`,
  getFromByShareKey: (key) => `/api/sheet-form/by-share-key/${key}`,
  submitForm: (sheetId) => `/api/sheet-form/${sheetId}/submit`,
};

export function getApps(params) {
  return postApiV2(endPoints.searchApps, params);
}

export function createApp(params) {
  return postApiV2(endPoints.createApp, params);
}

export function updateApp(params) {
  return putApiV2(endPoints.updateApp(params?.id), params);
}
export function deleteApp(appId) {
  return deleteApiV2(endPoints.deleteApp(appId));
}

export function createSheet(params) {
  return postApiV2(endPoints.createSheet, params);
}

export function getApp(id) {
  return getApiV2(endPoints.getApp(id));
}

export function promptAiAssistant(params) {
  let request = {
    prompt: params.prompt,
    action: params.action,
  };
  return postApiIntegration(endPoints.aiAssistant, request);
}

export function generateApp(params) {
  let request = {
    content: params.app,
  };
  return postApiV2(endPoints.generateApp, request);
}

export function generateAppSheet(params) {
  let request = {
    content: params.data,
  };
  return postApiV2(endPoints.generateAppSheet(params.appId), request);
}

export function generateAppData(params) {
  let request = {
    content: params.data,
  };
  return postApiV2(endPoints.generateAppData, request);
}

export function addSheetItem(params) {
  return postApiV2(endPoints.createItem(params.sheetId), params);
}

export function createTokenShareForm(params) {
  return postApiV2(endPoints.createTokenShareForm, params);
}

export function getShareFormByToken(token) {
  return getApiV2(endPoints.getShareFormByToken(token));
}

export function createSheetForm(params) {
  return postApiV2(endPoints.createSheetForm, params);
}

export function deleteSheetForm(id) {
  return deleteApiV2(endPoints.deleteSheetForm(id));
}

export function getFormByApp(appId) {
  return getApiV2(endPoints.getFormByApp(appId));
}

export function getFormDetail(id) {
  return getApiV2(endPoints.getFormDetail(id));
}

export function updateForm(id, params) {
  return putApiV2(endPoints.updateForm(id), params);
}

export function getKeyShareForm(id, params) {
  return getApiV2(endPoints.getKeyShareForm(id), params);
}

export function getFromByShareKey(key, params) {
  return getApiV2(endPoints.getFromByShareKey(key), params);
}

export function submitForm(params) {
  return postApiV2(endPoints.submitForm(params.sheetId), params);
}
