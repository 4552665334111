const chartTypes = [
  {
    type: 'pie',
    icon: <i className='las la-chart-pie'></i>,
  },
  {
    type: 'column',
    icon: <i className='las la-chart-bar'></i>,
  },
  {
    type: 'table',
    icon: <i className='las la-table'></i>,
  },
  {
    type: 'pivot',
    icon: <i className='las la-th-list'></i>,
  },
  {
    type: 'gantt',
    icon: <i className='las la-stream'></i>,
  },
  {
    type: 'battery',
    icon: <i className='las la-battery-full'></i>,
  },
  {
    type: 'number',
    // icon: <img src={numberIcons}/>,
    icon: <i className='fa-solid fa-8'></i>,
  },
  {
    type: 'sheet_table',
    icon: <i className='las la-table'></i>,
    label: 'View table'
  },
];

export const ChartTypeSelect = ({value, onChange}) => {
  return (
    <>
      {chartTypes.map((type) => {
        return (
          <button
            key={type.type}
            className={`btn btn-sm ${
              type.type === value ? 'btn-info' : 'btn-hover-info'
            } text-capitalize`}
            onClick={() => {
              onChange(type.type);
            }}
          >
            {type.icon} {type.label || type.type}
          </button>
        );
      })}
    </>
  );
};
