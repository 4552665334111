import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

function ModalConfirm({
  show,
  onHide,
  handleExcute,
  children,
  content,
  title,
  intl,
  btnConfirmText,
  confirmVariant,
  style,
  btnHide = true,
  ...props
}) {
  const {t} = useTranslate();
  return (
    <Modal show={show} onHide={onHide} style={style} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content || children}</Modal.Body>
      <Modal.Footer className='d-flex justify-content-around'>
        {btnHide && (
          <Button variant='secondary' onClick={onHide} className='w-150px'>
            {t('common_no')}
          </Button>
        )}
        <Button
          variant={confirmVariant ? confirmVariant : 'primary'}
          onClick={handleExcute}
          className='w-150px'
        >
          {btnConfirmText || t('common_yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirm;
