import {NumberChartUnitOption} from './components/NumberChartUnitOption';
import {NumberChartFunction} from './components/NumberChartFunction';
import {NumberChartDecimalPlace} from './components/NumberChartDecimalPlace';
import {set} from 'lodash';

function WidgetEditViewInnerNumber({editWidget, setEditWidget}) {
  const handleMetricNumberChange = (key, value) => {
    let _updateMetrics = editWidget.data.metrics;
    _updateMetrics[0] = {..._updateMetrics[0], [key]: value};
    let _editWidget = {...editWidget};
    set(_editWidget, 'data.metrics', _updateMetrics);
    setEditWidget(_editWidget);
  };

  return (
    <>
      <div className='mb-2 fs-3 text-dark'>Unit</div>
      <div className='mb-4'>
        <NumberChartUnitOption value={editWidget} onChange={handleMetricNumberChange} />
      </div>
      <div className='mb-2 fs-3 text-dark'>Function</div>
      <div className='mb-4'>
        <NumberChartFunction value={editWidget} onChange={handleMetricNumberChange} />
      </div>
      <div className='mb-2 fs-3 text-dark'>Decimal Place</div>
      <div className='mb-4'>
        <NumberChartDecimalPlace value={editWidget} onChange={handleMetricNumberChange} />
      </div>
    </>
  );
}

export default WidgetEditViewInnerNumber;
