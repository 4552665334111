import EditorJS from '@editorjs/editorjs';
import {useCallback, useEffect, useMemo, useId, useRef, useState} from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import {blockEventType, editorDefaultTools, editorI18nConfigs} from '../constants';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

const BlockEditor = (props) => {
  const {blocks, createBlock, updateBlock, moveBlock, deleteBlock, setTocBlocks, onUploadFile} =
    props;
  const editorId = useId();
  const {t} = useTranslate();
  const editorRef = useRef(null);
  const tempBlockRef = useRef(null);
  const [isForceRender, setIsForceRender] = useState(false);

  const hasDefaultBlock = !!(blocks.length === 1 && !blocks?.[0]?.content?.id);
  const convertedBlocks = useMemo(
    () =>
      hasDefaultBlock
        ? [
            {
              type: 'paragraph',
              data: {text: ''},
            },
          ]
        : blocks.map((block) => block.content),
    [blocks, hasDefaultBlock]
  );

  const handleSwitchAction = useCallback(
    (editor, event) => {
      return new Promise(async (resolve) => {
        const actionName = event.type;
        let fromIndex;
        let currentIndex;
        let toIndex;
        let blockId;
        let payload = null;

        if (actionName !== blockEventType.BLOCK_MOVED) {
          currentIndex = event?.detail?.index;
          blockId = tempBlockRef.current[currentIndex]?._id;
          payload = null;

          if (actionName !== blockEventType.BLOCK_REMOVED) {
            const blockCounts = editor.blocks.getBlocksCount();

            if (currentIndex >= blockCounts) {
              // since the UI is always updated before the block data was saved in the database,
              // we need to stop the current process because the block index does not exist.
              return resolve();
            } else {
              // this is the focused Block index
              const currentBlock = editor.blocks.getBlockByIndex(currentIndex);
              const blockData = await currentBlock?.save();
              const {tool, ...restData} = blockData;
              payload = {...restData, type: tool};
            }
          }
        } else {
          fromIndex = event?.detail?.fromIndex;
          toIndex = event?.detail?.toIndex;
          blockId = tempBlockRef.current[fromIndex]?._id;
        }

        const currentData = tempBlockRef.current[currentIndex]?.content?.data;
        const newData = payload?.data;

        // https://github.com/codex-team/editor.js/discussions/1907
        // ignore 'move block' action
        if (isEqual(currentData, newData) && !fromIndex && !toIndex) {
          return resolve();
        }

        switch (actionName) {
          case blockEventType.BLOCK_ADDED:
            const createBlockCallback = (createdBlock) => {
              tempBlockRef.current = [
                ...tempBlockRef.current.slice(0, currentIndex),
                createdBlock.data.data,
                ...tempBlockRef.current.slice(currentIndex),
              ];
              setTocBlocks(tempBlockRef.current);
              resolve();
            };
            await createBlock({
              content: payload,
              typeBlock: actionName,
              // After create new block, the UI will be update immediately.
              // That means the currentIndex (the focused Block index) is always the new block index.
              // So we need to minus 1 to get the correct index and the logic will be right.
              currentIndex: currentIndex - 1,
              callback: createBlockCallback,
              refetch: false,
              updatedBlockItems: tempBlockRef.current,
            });
            break;
          case blockEventType.BLOCK_CHANGED:
            const updateCallback = () => {
              tempBlockRef.current = tempBlockRef.current.map((block) => {
                if (block?._id === blockId) {
                  return {
                    ...block,
                    content: payload,
                  };
                }

                return block;
              });
              setTocBlocks(tempBlockRef.current);
              resolve();
            };
            await updateBlock(blockId, {content: payload}, updateCallback, false);
            break;
          case blockEventType.BLOCK_MOVED:
            const moveCallback = (_res, newPosition) => {
              const tempBlock = tempBlockRef.current[fromIndex];
              tempBlockRef.current[fromIndex] = tempBlockRef.current[toIndex];
              tempBlockRef.current[toIndex] = tempBlock;

              tempBlockRef.current[toIndex] = {
                ...tempBlockRef.current[fromIndex],
                pos: newPosition,
              };

              setTocBlocks(tempBlockRef.current);
              resolve();
            };
            await moveBlock({
              blockId,
              previousIndex: fromIndex > toIndex ? fromIndex - 1 : fromIndex + 1,
              nextIndex: toIndex < fromIndex ? toIndex - 1 : toIndex + 1,
              refetch: false,
              updatedBlockItems: tempBlockRef.current,
              callback: moveCallback,
            });
            break;
          case blockEventType.BLOCK_REMOVED:
            const deleteCallback = () => {
              tempBlockRef.current = tempBlockRef.current.filter((block) => block?._id !== blockId);
              setTocBlocks(tempBlockRef.current);
              resolve();
            };
            await deleteBlock(blockId, deleteCallback, false);
            break;
          default:
            break;
        }
      });
    },
    [createBlock, deleteBlock, moveBlock, setTocBlocks, updateBlock]
  );

  const onChange = debounce(async (editor, event) => {
    if (event instanceof Array) {
      for (const item of event) {
        await handleSwitchAction(editor, item);
      }
    } else {
      await handleSwitchAction(editor, event);
    }
  }, 500);

  const initialEditor = () => {
    const editor = new EditorJS({
      holder: editorId,
      autofocus: true,
      placeholder: t('block_placeholder'),
      tools: editorDefaultTools(t, onUploadFile),
      i18n: editorI18nConfigs(t),
      onReady: () => {
        editorRef.current = editor;
        if (convertedBlocks.length === 1 && !convertedBlocks[0]?.content?.id) {
          setIsForceRender(true);
        } else {
          editor.blocks.render({blocks: convertedBlocks});
        }
      },
      onChange,
    });
  };

  useEffect(() => {
    tempBlockRef.current = blocks;
  }, [blocks]);

  useEffect(() => {
    editorRef.current?.isReady.then(() => {
      if (editorRef.current?.configuration?.onChange) {
        editorRef.current.configuration.onChange = onChange;
      }
    });
  }, [onChange]);

  useEffect(() => {
    if (isForceRender) {
      editorRef.current?.isReady.then(() => {
        editorRef.current?.blocks?.render({blocks: convertedBlocks});
      });
    }
  }, [convertedBlocks, isForceRender]);

  useEffect(() => {
    if (!editorRef.current) {
      initialEditor();
    }

    return () => {
      editorRef?.current?.destroy();
      editorRef.current = null;
    };
  }, []);

  return <div id={editorId} className='block-editor' />;
};

export default BlockEditor;
