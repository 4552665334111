import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';

function NumberValue({value, item, column, onChange, disabled}) {
  const effValue = effectiveValue(value);

  return (
    <input
      disabled={disabled}
      type='number'
      className={clsx('w-100 form-control text-center')}
      value={effValue}
      onChange={(e) => onChange({value: e.target.value})}
    ></input>
  );
}

export default NumberValue;
