import {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const initSentry = () => {
  const tracePropagationTargets = window.SENTRY_TRACE_TARGET
  const dsn = window.SENTRY_DSN
  return Sentry.init({
    dsn: dsn,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: tracePropagationTargets,
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

export const closeSentry = (timeout) => {
  return Sentry.close(timeout)
}
//Use SentryRoutes instead of Routes to implement with route
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
