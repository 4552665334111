import * as requestFromServer from './templateApi';
import {templateSlice, callTypes} from './templateSlice';

const {actions} = templateSlice;

export const createItemTemplateActions = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .createItemTemplate(params)
    .catch((error) => {
      error.clientMessage = "Can't create template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getTemplateBySheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getTemplateBySheet(params)
    .catch((error) => {
      error.clientMessage = "Can't get template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const createItemFromTemplateAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .createItemFromTemplate(params)
    .catch((error) => {
      error.clientMessage = "Can't create item from template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updateTemplateAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateTemplate(id, params)
    .catch((error) => {
      error.clientMessage = "Can't update template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const removeTemplateAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .removeTemplate(id)
    .catch((error) => {
      error.clientMessage = "Can't remove template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};
