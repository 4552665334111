import {get, set} from 'lodash';

export function renameDuplicates(arr, attr = 'name') {
  const seen = {}; // Object to keep track of seen names

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const attrVal = item[attr];
    let attrCount = get(seen, attrVal);
    if (!attrCount) {
      set(seen, attrVal, 1);
    } else {
      attrCount++;
      set(seen, attrVal, attrCount);
      arr[i][attr] = `${attrVal} ${attrCount}`; // Rename name with an incremented count
    }
  }

  return arr;
}
