import {createSlice} from '@reduxjs/toolkit';

const initialUserStateState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  userState: {
    app: {},
    workspaces: {},
    boards: {},
    state: {
      folders: {},
      sheets: {}
    }
    
  },
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const userStateSlice = createSlice({
  name: 'userState',
  initialState: initialUserStateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
    userStateUpdate: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userState = {...state.userState, ...data};
    },
  },
});
