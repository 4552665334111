import {timeAgoFromDateShortString} from 'app/common/_helpers/TimeHelper';
import {generateNameAvatar} from 'app/modules/work/helpers';
import {isObject} from 'lodash';
import React from 'react';
import {FormattedDate} from 'react-intl';
import {useSelector, shallowEqual} from 'react-redux';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {format} from 'date-fns';
import {hasViewSheetColumnPermission} from 'app/modules/work/sheet/permission/permissionHelper';
import {useSession} from 'core/store/core/hooks';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

export default function ItemAuditListView({itemAudits, sheet}) {
  const hiddenFields = ['update_ts', 'dmn', '_id', 'sheet', 'group'];
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const {user} = useSession();

  const getFieldValue = (sheet, field, value) => {
    let res = '-';
    let column = null;
    if (!value) return '-';

    if (field === 'created_by') {
      return value?.value?.name;
    }

    if (field === 'content') {
      return value?.value?.replace(/<[^>]*>?/gm, '');
    }

    if (field === 'extra') {
      const file = value?.attachments?.[0];
      const fileName = `${file?.original_name}.${file?.extension}`;
      if (file?.original_name) {
        return fileName;
      } else {
        return '';
      }
    }

    sheet?.columns?.forEach((col) => {
      if (col.field === field) {
        column = col;
      }
    });
    if (column) {
      const blinded = !hasViewSheetColumnPermission(user, column);
      if (blinded) {
        return res;
      }

      if (column.type === 'people') {
        res = value?.value?.reduce(
          (accumulator, currentValue) =>
            accumulator + (accumulator === '' ? '' : ', ') + currentValue.name,
          ''
        );
      } else if (column.type === 'file') {
        res = value?.value?.reduce(
          (accumulator, currentValue) =>
            accumulator +
            (accumulator === '' ? '' : ', ') +
            currentValue?.original_name +
            '.' +
            currentValue?.extension,
          ''
        );
      } else if (column.type === 'department') {
        res = value?.value?.reduce(
          (accumulator, currentValue) =>
            accumulator + (accumulator === '' ? '' : ', ') + currentValue.name,
          ''
        );
      } else if (column.type === 'date') {
        if (column?.settings?.hasTime) {
          res = value?.value ? format(new Date(value.value * 1000), 'dd/MM/yyyy, HH:mm') : '-';
        } else {
          res = value?.value ? (
            <FormattedDate value={new Date(value.value * 1000).toString()}></FormattedDate>
          ) : (
            '-'
          );
        }
      } else if (column.type === 'status' || column.type === 'color') {
        let columnValue = column?.values.filter(
          (x) => x.value === value.value || column.type === value
        )?.[0];
        res = (
          <span
            className='w-100 border-0 m-n2 btn btn-none btn-xs'
            style={{backgroundColor: `${columnValue?.backgroundColor}`}}
          >
            {columnValue?.label}
          </span>
        );
      } else if (column.type === 'reference') {
        res = value?.title?.label || value?.title;
      } else if (column.type === 'timeline') {
        const startDate = value.value?.startDate
          ? format(new Date(value.value?.startDate * 1000), 'dd/MM/yyyy')
          : '';
        const endDate = value.value?.endDate
          ? format(new Date(value.value?.endDate * 1000), 'dd/MM/yyyy')
          : '';
        const startTime = value.value?.startTime
          ? format(new Date(value.value?.startTime * 1000), 'HH:mm')
          : '';
        const endTime = value.value?.endTime
          ? format(new Date(value.value?.endTime * 1000), 'HH:mm')
          : '';
        if (column?.settings?.hasTime) {
          res = startDate + '|' + startTime + ' - ' + endDate + '|' + endTime;
        } else {
          res = startDate + ' - ' + endDate;
        }
      } else if (column.type === 'dependency') {
        res = value?.value?.map((dependency) => dependency.title).join(', ');
      } else {
        res = value?.value ? value?.value.toString() : '-';
      }
    }
    return res;
  };

  const oldValue = (itemAudit) => {
    let values = itemAudit.old_values;
    let value = '';
    let valueField = '';
    Object.keys(values).forEach((key) => {
      if (!hiddenFields.includes(key)) {
        value = values[key];
        valueField = key;
      }
    });
    return getFieldValue(sheet, valueField, value);
  };

  const newValue = (itemAudit) => {
    let values = itemAudit.new_values;
    let value = '';
    let valueField = '';
    Object.keys(values).forEach((key) => {
      if (!hiddenFields.includes(key)) {
        value = values[key];
        valueField = key;
      }
    });
    return getFieldValue(sheet, valueField, value);
  };

  const changeField = (itemAudit) => {
    if (itemAudit.event === 'created') {
      return (
        <>
          <i className='las la-plus-circle'></i>
          <span>Created</span>
        </>
      );
    }
    let values = itemAudit.new_values;
    let field = '';
    Object.keys(values).forEach((key) => {
      if (!hiddenFields.includes(key)) {
        field = key;
      }
    });

    return getFieldName(sheet, field);
  };

  const getFieldName = (sheet, field) => {
    const icons = {
      people: <i className='las la-user'></i>,
      date: <i className='las la-calendar'></i>,
      status: <i className='las la-tasks'></i>,
      reference: <i className='las la-external-link-square-alt'></i>,
      text: <i className='las la-font'></i>,
      title: <i className='las la-font'></i>,
      extra: <i className='las la-file'></i>,
      content: <i className='las la-edit'></i>,
    };

    let column = null;

    let res = capitalizeFirst(field);
    sheet?.columns?.forEach((col) => {
      if (col.field === field) {
        column = col;
      }
    });
    if (column) {
      res = (
        <>
          {icons[column.type] ? icons[column.type] : <i className='las la-minus'></i>}{' '}
          <span>{capitalizeFirst(column.name)}</span>
        </>
      );
    } else if (field === 'extra') {
      res = (
        <>
          {icons[field] ? icons[field] : <i className='las la-minus'></i>} <span>{'Upload'}</span>
        </>
      );
    } else if (field === 'content') {
      res = (
        <>
          {icons[field] ? icons[field] : <i className='las la-edits'></i>} <span>{'Mô tả'}</span>
        </>
      );
    } else {
      res = '';
    }

    return res;
  };

  return (
    <>
      <table className='w-100'>
        <tbody>
          {itemAudits &&
            itemAudits.map((itemAudit) => {
              let personItem = objectUser[itemAudit?.user?._id] || {};
              const keyNewValues = Object.keys(itemAudit?.new_values);
              const keyOldValues = Object.keys(itemAudit?.old_values);
              return keyNewValues.map((keyNewValue, idx) => {
                if (isObject(itemAudit.new_values[keyNewValue])) {
                  const _oldValue = itemAudit.old_values[keyOldValues[idx]] || {};
                  const _newValue = itemAudit.new_values[keyNewValue] || {};
                  return (
                    <tr
                      className='border-none border-bottom border-secondary detail-audit-item'
                      key={`${itemAudit._id}-${idx}`}
                    >
                      <td className='w-80px min-w-80px text-muted font-size-xs me-2'>
                        <OverlayTrigger
                          key={'tooltip-time-item-audit'}
                          placement='top'
                          overlay={
                            <Tooltip
                              className='tooltip-inverse'
                              style={{zIndex: 10000}}
                              id='tooltip-time-item-audit'
                            >
                              {format(new Date(itemAudit?.created_at), 'dd-MM-yyyy HH:mm:ss')}
                            </Tooltip>
                          }
                        >
                          <span>
                            <i className='las la-clock'></i>
                            {timeAgoFromDateShortString(itemAudit?.created_at)}
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td className='w-30px py-2 d-flex align-items-center h-45px'>
                        <span className=' symbol symbol-25px' title={itemAudit?.user?.name}>
                          <span className='symbol-label rounded-circle border border-dark text-uppercase'>
                            <span className='position-absolute'>
                              {generateNameAvatar(itemAudit?.user?.name)}
                            </span>
                            {personItem?.avatar && (
                              <img
                                src={personItem?.avatar}
                                className='w-100 h-100 rounded-circle'
                                style={{zIndex: 10}}
                                onError={(e) => {
                                  e.target.style.display = 'none';
                                }}
                                alt='User avatar'
                              />
                            )}
                          </span>
                        </span>
                      </td>
                      <td></td>
                      <td className='min-w-150px '>{changeField(itemAudit)}</td>
                      <td className='w-150px'>
                        <span
                          className='w-150px d-inline-block text-truncate text-center'
                          title={oldValue({
                            ...itemAudit,
                            old_values: {[keyOldValues[idx]]: _oldValue},
                          })}
                        >
                          {oldValue({...itemAudit, old_values: {[keyOldValues[idx]]: _oldValue}})}
                        </span>
                      </td>
                      <td className='w-20px'>
                        <i className='las la-angle-right'></i>
                      </td>
                      <td className='w-150px'>
                        <span
                          className='w-150px d-inline-block text-truncate text-center'
                          title={newValue({...itemAudit, new_values: {[keyNewValue]: _newValue}})}
                        >
                          {newValue({...itemAudit, new_values: {[keyNewValue]: _newValue}})}
                        </span>
                      </td>
                    </tr>
                  );
                }
                return <React.Fragment key={`${itemAudit._id}-${idx}-fragment`} />;
              });
            })}
        </tbody>
      </table>
    </>
  );
}

function capitalizeFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
