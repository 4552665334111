import { getApiV2, postApiV2 } from "../api";

const endPoints = {
  updateUserState: '/api/user-state/save',
  getUserByUserId: `/api/user-state/byUserId`,
};

export function updateUserState(params) {
  return postApiV2(endPoints.updateUserState, params);
}

export function getUserStateByUserId() {
  return getApiV2(endPoints.getUserByUserId);
}