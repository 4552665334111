import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';

import { getUserSheetPermissionAction } from '../stores/actions';

export const useSheetPermission = ({sheetId}) => {
  const [userSheetPermissions, setSheetUserPermission] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    sheetId && fetchPermission();
  }, [sheetId]);
 

  const fetchPermission = () => {
    dispatch(getUserSheetPermissionAction({sheetId: sheetId})).then((res) => {
      
      setSheetUserPermission(res);
    })
  };

  return {
    userSheetPermissions
  };
};
