import * as requestFromServer from "./meetingApi";
import { meetingSlice, callTypes } from "./meetingSlice";

const { actions } = meetingSlice;

export const fetchMeetingsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getMeetings(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.meetingsFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't get meeting";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    })
    .finally(() => {
      dispatch(actions.endCall({ callType: callTypes.list }));
    });
};


export const fetchMyMeetingsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getMyMeetings(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.meetingsFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't get meeting";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    })
    .finally(() => {
      dispatch(actions.endCall({ callType: callTypes.list }));
    });
};

export const getMeetingAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getMeeting(params)
    .catch((error) => {
      error.clientMessage = "Can't get meeting";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    })
    .finally(() => {
      dispatch(actions.endCall({ callType: callTypes.list }));
    });
};


export const getMeetingStatAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getMeetingStat(params)
    .catch((error) => {
      error.clientMessage = "Can't get meeting";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    })
    .finally(() => {
      dispatch(actions.endCall({ callType: callTypes.list }));
    });
};


export const newMeetingAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer.newMeeting(params).finally(() => {
    dispatch(actions.endCall({ callType: callTypes.action }));
  });
};

export const updateMeetingAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer.updateMeeting(params).finally(() => {
    dispatch(actions.endCall({ callType: callTypes.action }));
  });
};

export const deleteMeetingAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer.deleteMeeting(params).finally(() => {
    dispatch(actions.endCall({ callType: callTypes.action }));
  });
};

export const joinMeetingAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.join }));

  return requestFromServer
    .joinMeeting(params)
    .catch((error) => {
      error.clientMessage = "Can't get upcoming meetings";
      dispatch(actions.catchError({ error, callType: callTypes.join }));
    })
    .finally(() => {
      dispatch(actions.endCall({ callType: callTypes.join }));
    });
};

export const requestUsersAction = (params) => {
  return requestFromServer.getUsers(params);
};

export const getUsersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getUsers(params)
    .catch((error) => {
      error.clientMessage = "Can't get detail users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    })
    .finally(() => {
      dispatch(actions.endCall({ callType: callTypes.list }));
    });
};

export const getAccountSetting = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getAccountSetting(params)
    .catch((error) => {
      error.clientMessage = "Can't get account";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const updateAccountSetting = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateAccountSetting(params)
    .catch((error) => {
      error.clientMessage = "Can't update account";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const aiAssistantCreateMeetingAction = (params) => (dispatch) => {
  return requestFromServer.aiAssistantCreateMeeting(params);
};
