import './itemFile.scss';
import {Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

export default function ItemFileView({itemFile, viewFile}) {
  const imageTypes = ['jpg', 'jpeg', 'png'];
  const videoTypes = ['mp4', 'mpg', 'mpeg'];
  const audioTypes = ['mp3', 'm4a'];
  const pdfTypes = ['pdf'];
  const documentTypes = ['doc', 'docx', 'txt'];
  const excelTypes = ['xls', 'xlsx', 'csv', 'xlsm'];
  const powerpoint = ['pptx', 'ppt'];
  const zip = ['zip', 'rar'];
  const txt = ['txt'];
  const json = ['json'];

  if (!itemFile?.attachments?.length) {
    return null;
  }

  const renderFilePreview = () => {
    if (imageTypes?.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <Image
          className='h-100 w-100 rounded'
          src={itemFile?.attachments?.[0]?.url}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        />
      );
    }

    if (videoTypes.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-video icon-thumb'></i>
        </div>
      );
    }

    if (audioTypes.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-audio icon-thumb'></i>
        </div>
      );
    }

    if (pdfTypes.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-pdf icon-thumb'></i>
        </div>
      );
    }

    if (documentTypes.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-word icon-thumb'></i>
        </div>
      );
    }

    if (excelTypes.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-excel icon-thumb'></i>
        </div>
      );
    }

    if (powerpoint.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-powerpoint icon-thumb'></i>
        </div>
      );
    }

    if (zip.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-zipper icon-thumb'></i>
        </div>
      );
    }

    if (txt.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-lines icon-thumb'></i>
        </div>
      );
    }

    if (json.includes(itemFile?.attachments?.[0]?.extension)) {
      return (
        <div
          title={itemFile?.attachments?.[0]?.display_name}
          onClick={() => viewFile(itemFile?.attachments?.[0])}
        >
          <i className='fa-regular fa-file-code icon-thumb'></i>
        </div>
      );
    }

    return (
      <div className='sheet-item-file-thumb border border-gray rounded d-flex align-items-center justify-content-center'></div>
    );
  };
  const renderFileName = () => (
    <OverlayTrigger
      key='title-item'
      placement='top'
      overlay={
        <Tooltip className='tooltip-inverse tooltip-title-item' id='tooltip-title-item'>
          {itemFile?.attachments?.[0]?.original_name}
        </Tooltip>
      }
    >
      <span className='attachment-name text-truncate position-absolute'>
        {itemFile?.attachments?.[0]?.original_name}
      </span>
    </OverlayTrigger>
  );

  return (
    <div className='sheet-item-file-thumb border border-gray rounded d-flex align-items-center justify-content-center'>
      {renderFilePreview()}
      {renderFileName()}
    </div>
  );
}
