import React, {useEffect, useMemo, useState} from 'react';

import {DataViewContext} from './DataViewContext';
import {Modal} from 'react-bootstrap';
import SheetDataViewToolbar from './toolbar/SheetDataViewToolbar';
import SheetViewEditView from '../../sheet-config/SheetViewEditView';
import SheetViewView from '../../view/SheetViewView';
import SheetViewViewList from './SheetViewViewList';
import {upperFirst} from 'lodash';
import {useSession} from 'core/store/hooks';
import {useSheetMeta} from '../../../SheetMetaHook';
import {useTranslate} from 'core/i18n/i18nProvider';

const SheetDataView = ({page, darkColor, linkableSheets}) => {
  const {t} = useTranslate();
  const {user} = useSession();
  const [sheet, setSheet] = useState(null);
  const sheetMeta = useSheetMeta({sheetId: page?._id, user, sheet, setSheet});
  const pageViews = useMemo(() => sheet?.views ?? page?.views ?? [], [page?.views, sheet?.views]);
  const [currentPageView, setCurrentPageView] = useState(null);
  const [showViewList, setShowViewList] = useState(true);
  const [showEditView, setShowEditView] = useState(false);
  const onToggleViewList = () => {
    setShowViewList(!showViewList);
  };

  const onSelectView = (view) => {
    const selectView = sheet?.views.find((sheetView) => sheetView?.code === view?.code);
    setCurrentPageView(selectView ?? view);
  };

  useEffect(() => {
    // only set default page view if the first view was changed
    const defaultPageView = pageViews?.[0];
    setCurrentPageView(defaultPageView);
  }, [pageViews?.[0]?.code]);

  const onDeleteView = (view) => {
    sheetMeta.deleteView(view, () => {
      const defaultPageView = pageViews[0];
      onSelectView(defaultPageView);
    });
  };

  const handleUpdateView = (oldView, newView) => {
    sheetMeta.updateView(oldView, newView);
    setShowEditView(false);
  };

  const renderToolbar = ({
    sheetId,
    sheet,
    view,
    onAddNewGroup,
    onSetting,
    onExportExcel,
    onAddNew,
    onFilterApply,
    onFilterReset,
    activeFilters,
    filterActive,
    saveFilter,
    onSearch,
    onCreateGroupTasks,
    addItemsAndSubItems,
    options,
    allowFilterByGroup,
    canAddGroup,
    showAddButton,
    onImport,
    mode,
    changeMode,
    userSheetPermissions,
  }) => {
    return (
      <SheetDataViewToolbar
        sheetId={sheetId}
        sheet={sheet}
        view={view}
        onAddNewGroup={onAddNewGroup}
        onSetting={() => setShowEditView(true)}
        onExportExcel={onExportExcel}
        onAddNew={onAddNew}
        onFilterApply={onFilterApply}
        onFilterReset={onFilterReset}
        activeFilters={activeFilters}
        filterActive={filterActive}
        saveFilter={saveFilter}
        onSearch={onSearch}
        onCreateGroupTasks={onCreateGroupTasks}
        addItemsAndSubItems={addItemsAndSubItems}
        options={options}
        allowFilterByGroup={allowFilterByGroup}
        canAddGroup={canAddGroup}
        showAddButton={showAddButton}
        onImport={onImport}
        mode={mode}
        changeMode={changeMode}
        userSheetPermissions={userSheetPermissions}
        linkableSheets={linkableSheets}
      ></SheetDataViewToolbar>
    );
  };

  return (
    <DataViewContext.Provider
      value={{
        currentPageView,
        onToggleViewList,
        updateView: sheetMeta.updateView,
        addColumn: sheetMeta.addColumn,
        addView: sheetMeta.addView,
        deleteView: onDeleteView,
        updateSheet: sheetMeta.updateSheet,
        updateSubSheets: sheetMeta.updateSubSheets,
        darkColor,
      }}
    >
      <div className='application-main-content'>
        {showViewList && (
          <SheetViewViewList
            views={pageViews}
            selectedView={currentPageView}
            onSelectView={onSelectView}
            onAddView={sheetMeta.addView}
            onDeleteView={sheetMeta.deleteView}
            onUpdateView={sheetMeta.updateView}
          />
        )}

        <div className='mw-100 w-100 overflow-auto px-2 bg-content border-left'>
          {currentPageView && (
            <SheetViewView
              view={currentPageView}
              setEditView={setCurrentPageView}
              sheetMeta={sheetMeta}
              renderToolbar={renderToolbar}
              sheet={sheet}
              setSheet={setSheet}
              linkableSheets={linkableSheets}
              options={{
                resizableColumns: true,
                showToolbar: true,
                navigateToItem: true
              }}
            />
          )}
        </div>
      </div>
      <Modal
        className='modal-edit-view'
        size='lg'
        show={showEditView}
        onHide={() => setShowEditView(false)}
        backdropClassName=''
        dialogClassName='bg-white'
        contentClassName='bg-white'
      >
        <Modal.Header>
          <Modal.Title>
            {t('sheet_view_edit_title') +
              ' ' +
              (currentPageView?.name || upperFirst(currentPageView?.type))}
          </Modal.Title>
          <button
            type='button'
            className='btn btn-xs btn-icon btn-light btn-hover-primary'
            onClick={() => setShowEditView(false)}
          >
            <i className='ki ki-close icon-xs text-muted'></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          {currentPageView && (
            <SheetViewEditView
              view={currentPageView}
              sheet={sheet}
              onSubmit={handleUpdateView}
            ></SheetViewEditView>
          )}
        </Modal.Body>
      </Modal>
    </DataViewContext.Provider>
  );
};

export default SheetDataView;
