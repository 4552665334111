import Quote from '@editorjs/quote';
import ImageTool from '@editorjs/image';
import NestedList from '@editorjs/nested-list';
import Checklist from '@editorjs/checklist';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import CodeTool from '@editorjs/code';
import AttachesTool from '@editorjs/attaches';
import Marker from '@editorjs/marker';
import Header from '@editorjs/header';
import Strikethrough from '@sotaproject/strikethrough';
import Underline from '@editorjs/underline';

export const editorDefaultTools = (t, onUploadFile) => {
  return {
    header: {
      class: Header,
      config: {
        placeholder: t('block_placeholder_header'),
        levels: [1, 2, 3],
        defaultLevel: 2,
      },
    },
    quote: {
      class: Quote,
      config: {
        quotePlaceholder: t('block_editor_quote_enter_a_quote'),
        captionPlaceholder: t('block_editor_quote_enter_a_caption'),
      },
    },
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile: (file) => {
            return onUploadFile(file).then((res) => {
              return {
                success: 1,
                file: {
                  url: res.url,
                },
              };
            });
          },
        },
      },
    },
    list: {
      class: NestedList,
      inlineToolbar: true,
      config: {
        defaultStyle: 'unordered',
      },
    },
    checklist: {
      class: Checklist,
      inlineToolbar: false,
    },
    // https://github.com/editor-js/embed/issues/11#issuecomment-482598582
    embed: Embed,
    table: {
      class: Table,
      inlineToolbar: true,
      config: {
        rows: 2,
        cols: 3,
      },
    },
    code: CodeTool,
    attaches: {
      class: AttachesTool,
      config: {
        uploader: {
          uploadByFile: (file) => {
            return onUploadFile(file).then((res) => {
              return {
                success: 1,
                file: {
                  url: res.url,
                },
              };
            });
          },
        },
      },
    },
    marker: Marker,
    strikethrough: Strikethrough,
    underline: Underline,
  };
};

export const editorI18nConfigs = (t) => {
  return {
    messages: {
      toolNames: {
        Text: t('block_control_subitem_add_content'),
        Heading: t('block_control_subitem_add_heading'),
        List: t('block_control_subitem_add_list'),
        Checklist: t('block_control_subitem_add_checkbox'),
        Quote: t('block_control_subitem_add_quote'),
        Image: t('block_control_subitem_add_file_image'),
        Code: t('block_control_subitem_add_code'),
        Table: t('block_control_add_block_table'),
        Link: t('block_control_subitem_add_link'),
        Attachment: t('block_control_subitem_add_attaches'),
        Marker: t('block_control_subitem_add_marker'),
        Italic: t('block_editor_tool_names_italic'),
        Bold: t('block_editor_tool_names_bold'),
        Strikethrough: t('block_control_subitem_add_strikethrough'),
        Underline: t('block_control_subitem_add_underline'),
      },
      tools: {
        header: {
          'Heading 1': t('document_history_created_h1'),
          'Heading 2': t('document_history_created_h2'),
          'Heading 3': t('document_history_created_h3'),
        },
        list: {
          Unordered: t('block_editor_list_unordered'),
          Ordered: t('block_editor_list_ordered'),
        },
        image: {
          'With border': t('block_editor_tools_image_with_border'),
          'Stretch image': t('block_editor_tools_image_stretch_image'),
          'With background': t('block_editor_tools_image_with_background'),
        },
        quote: {
          'Align Left': t('block_editor_quote_align_left'),
          'Align Right': t('block_editor_quote_align_right'),
          'Align Center': t('block_editor_quote_align_center'),
        },
        table: {
          'With headings': t('block_editor_table_with_headings'),
          'Without headings': t('block_editor_table_without_headings'),
          'Add row above': t('block_editor_table_add_row_above'),
          'Add row below': t('block_editor_table_add_row_below'),
          'Delete row': t('block_editor_table_delete_row'),
          'Add column to left': t('block_editor_table_add_column_to_left'),
          'Add column to right': t('block_editor_table_add_column_to_right'),
          'Delete column': t('block_editor_table_delete_column'),
        },
        embed: {
          'Enter a caption': t('block_placeholder'),
        },
      },
      blockTunes: {
        delete: {
          Delete: t('block_control_delete'),
          'Click to delete': t('block_editor_block_tunes_delete_click_to_delete'),
        },
        moveUp: {
          'Move up': t('block_control_up'),
        },
        moveDown: {
          'Move down': t('block_control_down'),
        },
      },
      ui: {
        blockTunes: {
          toggler: {
            'Click to tune': t('block_editor_block_tunes_click_to_tune'),
          },
        },
        inlineToolbar: {
          converter: {
            'Convert to': t('block_editor_block_tunes_convert_to'),
          },
        },
        toolbar: {
          toolbox: {
            Add: t('workspace_add'),
          },
        },
        popover: {
          Filter: t('block_editor_ui_popover_filter'),
          'Nothing found': t('block_editor_ui_popover_not_found'),
          'Convert to': t('block_editor_block_tunes_convert_to'),
        },
      },
    },
  };
};

export const editorPreviewConfigs = {
  quote: {
    className: 'editor-blockquote-preview',
  },
  table: {
    className: 'editor-table-preview',
  },
  image: {
    className: 'editor-image-preview mt-5',
    actionsClassNames: {
      stretched: 'w-100 editor-image-preview--image-stretched',
      withBorder: 'border border-gray-300 p-5 editor-image-preview--with-border',
      withBackground:
        'pt-10 d-flex flex-column align-items-center justify-content-center editor-image-preview--with-background',
    },
  },
  checklist: {
    className: 'editor-checklist-preview',
  },
};

export const blockEventType = {
  BLOCK_ADDED: 'block-added',
  BLOCK_REMOVED: 'block-removed',
  BLOCK_CHANGED: 'block-changed',
  BLOCK_MOVED: 'block-moved',
};

export const BLOCK_V2 = 'block_v2';
