import React from 'react';
import {useFormikContext} from 'formik';
import {Dropdown} from 'react-bootstrap';

export function DropdownField({
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  notifyChange,
  children,
  options,
  className,
  style,
  field,
  optionFormatter,
  optionClassName,
  displayValue,
  disabled = false,
  ...props
}) {
  const {setFieldValue} = useFormikContext();

  field.onChange = (event) => {
    setFieldValue(field.name, event.target.value);
    notifyChange && notifyChange(event.target.value);
  };

  const labelOption = options.find((option) => option.value === field.value)?.label;

  return (
    <>
      {label && <label>{label}</label>}
      <Dropdown>
        <Dropdown.Toggle variant='none' className={className} style={style}>
          {displayValue ? field.value : labelOption}
        </Dropdown.Toggle>
        {!disabled && (
          <Dropdown.Menu>
            {options &&
              options.map((option) => {
                return (
                  <Dropdown.Item
                    className={optionClassName ?? ''}
                    key={option.value}
                    onClick={() => setFieldValue(field.name, option.value)}
                  >
                    {optionFormatter ? optionFormatter(option) : option.label}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
}
