import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import React from 'react';
import './textField.scss';

function TextField({
  value,
  item,
  column,
  mode,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName,
}) {
  const effValue = effectiveValue(value);
  const field = column?.field;
  const isDetailViewMode = mode === 'detail';
  const onBlur = (e) => {
    if (e.target.value !== effValue) {
      onChange && onChange(item, field, effValue, e.target.value || '');
    }
  };

  if (fieldEditable && !readOnly) {
    return (
      <input
        type='text'
        className={clsx(
          'w-100 form-control form-control-xs item-text-input text-opt-secondary bg-transparent',
          containerClassName
        )}
        defaultValue={effValue}
        onBlur={onBlur}
        placeholder={isDetailViewMode ? column?.placeholder : ''}
      ></input>
    );
  }

  return (
    <span
      className={clsx(
        'w-100 px-2 title-limit-text-1 text-opt-secondary cursor-text',
        containerClassName
      )}
    >
      {effValue}
    </span>
  );
}

export default TextField;
