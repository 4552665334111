import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  endOfYear,
  isSameDay,
  differenceInCalendarDays,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  startOfLastQuarter: startOfQuarter(addMonths(new Date(), -3)),
  endOfLastQuarter: endOfQuarter(addMonths(new Date(), -3)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
};

const staticRangeHandler = {
  range: {} as any,
  isSelected(range: any) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges: any) {
  return ranges.map((range: any) => ({...staticRangeHandler, ...range}));
}

export const defaultStaticRanges = createStaticRanges([
  {
    key: 'today',
    label: 'Hôm nay',
    range: () => ({
      startDate: defineds.startOfToday.getTime() / 1000,
      endDate: defineds.endOfToday.getTime() / 1000,
    }),
  },
  {
    key: 'yesterday',
    label: 'Hôm qua',
    range: () => ({
      startDate: defineds.startOfYesterday.getTime() / 1000,
      endDate: defineds.endOfYesterday.getTime() / 1000,
    }),
  },

  {
    key: 'this_week',
    label: 'Tuần này',
    range: () => ({
      startDate: defineds.startOfWeek.getTime() / 1000,
      endDate: defineds.endOfWeek.getTime() / 1000,
    }),
  },
  {
    key: 'last_week',
    label: 'Tuần trước',
    range: () => ({
      startDate: defineds.startOfLastWeek.getTime() / 1000,
      endDate: defineds.endOfLastWeek.getTime() / 1000,
    }),
  },
  {
    key: 'this_month',
    label: 'Tháng này',
    range: () => ({
      startDate: defineds.startOfMonth.getTime() / 1000,
      endDate: defineds.endOfMonth.getTime() / 1000,
    }),
  },
  {
    key: 'last_month',
    label: 'Tháng trước',
    range: () => ({
      startDate: defineds.startOfLastMonth.getTime() / 1000,
      endDate: defineds.endOfLastMonth.getTime() / 1000,
    }),
  },
  {
    key: 'this_quarter',
    label: 'Quý này',
    range: () => ({
      startDate: defineds.startOfQuarter.getTime() / 1000,
      endDate: defineds.endOfQuarter.getTime() / 1000,
    }),
  },
  {
    key: 'last_quarter',
    label: 'Quý trước',
    range: () => ({
      startDate: defineds.startOfLastQuarter.getTime() / 1000,
      endDate: defineds.endOfLastQuarter.getTime() / 1000,
    }),
  },
  {
    key: 'this_year',
    label: 'Năm này',
    range: () => ({
      startDate: defineds.startOfYear.getTime() / 1000,
      endDate: defineds.endOfYear.getTime() / 1000,
    }),
  },
  {
    key: 'custom-day',
    label: '',
    range: (type:string, days:number) => {
      if (type === 'before') {
        return {
          startDate: (addDays(new Date(), -days).getTime()) / 1000,
          endDate: (new Date().getTime()) / 1000,
        };
      }
      return {
        startDate: (new Date().getTime()) / 1000,
        endDate: (addDays(new Date(), days).getTime()) / 1000,
      };
    },
  },
]);

export const getRangeValue = (key: string, type?: string, days?: number) => {
  let selectionRange = defaultStaticRanges.find((item: any) => item.key === key)
  return selectionRange.range(type, days)
}

export const defaultInputRanges = [
  {
    label: 'days up to today',
    range(value: any) {
      return {
        startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
        endDate: defineds.endOfToday,
      };
    },
    getCurrentValue(range: any) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
      if (!range.startDate) return '∞';
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    },
  },
  {
    label: 'days starting today',
    range(value: any) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1),
      };
    },
    getCurrentValue(range: any) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
      if (!range.endDate) return '∞';
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
    },
  },
];
