import {CardBody} from 'app/common/_partials/controls';
import {OGridViewTemplate} from 'app/modules/work/components/OGridViewTemplate';
import {effectiveValue, generateNameAvatar} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {useSession} from 'core/store/hooks';
import {isArray} from 'lodash';
import {useRef, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {registerLocale} from 'react-datepicker';
import vi from 'date-fns/locale/vi'; // the locale you want
import {format} from 'date-fns';
import {fromUnixTime} from 'date-fns';
import {Drawer} from '@material-ui/core';
import ItemDetail from './ItemDetail';
import {HeaderFormatter} from '../../component/view/table/ColumnHeader';
import ItemField from '../../component/field/ItemField';
registerLocale('vi', vi); // register it with the name you want

function TemplatePreview({items, keyField = '_id', columns = [], subItemsColumns = []}) {
  const subItemKey = '-subitems-container-template';
  const [editItem, setEditItem] = useState(null);

  const sheetColumns = () => {
    if (columns) {
      const sheetCols = [];
      columns.forEach((column) => {
        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column.name,
            text: column.name,
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap ',
            column: column,
            formatter: (cell) => {
              return '';
            },
            headerFormatter: (column, colIndex, style) => (
              <HeaderFormatter column={column} colIndex={colIndex} style={style} />
            ),
            editable: false,
          };

          col.formatter = (cell, row, rowIndex, onToggleSubitem) => {
            let props = {
              value: cell,
              item: row,
              column: column,
              readOnly: true,
            };

            if (column?.field === 'title') {
              props.showDetail = (row) => setEditItem(row);
              props.showExpand = true;
              props.onToggleSubitem = onToggleSubitem;
            }

            return <ItemField {...props} />;
          };

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          sheetCols.push(col);
        }
      });

      return sheetCols;
    } else {
      return [
        {
          accessor: '',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header text-left',
          editable: false,
        },
      ];
    }
  };

  const expandRow = {
    renderer: (item) => {
      return (
        <>
          {
            <CardBody className='d-flex border-0'>
              <div className='sheet-group-table-indicator ms-2'></div>
              <SubitemTableView
                parentItemId={item._id}
                keyField={keyField}
                subItems={item.subItems}
                subItemKey={subItemKey}
                columns={subItemsColumns}
              ></SubitemTableView>
            </CardBody>
          }
        </>
      );
    },
  };

  return (
    <div>
      <CardBody className='d-flex'>
        <div className='sheet-group-table-indicator ms-2'></div>
        <OGridViewTemplate
          classes='table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1 sheet-table'
          keyField={keyField}
          data={items || []}
          columns={sheetColumns()}
          subItemKey={subItemKey}
          expandRow={{
            renderer: expandRow.renderer,
          }}
        ></OGridViewTemplate>
      </CardBody>
      <Drawer
        anchor='right'
        variant='persistent'
        open={editItem !== null}
        onClose={() => setEditItem(null)}
        PaperProps={{className: 'bg-body', style: {color: 'unset'}}}
        BackdropProps={{invisible: true}}
      >
        <ItemDetail item={editItem} columns={columns} onHide={() => setEditItem(null)}></ItemDetail>
      </Drawer>
    </div>
  );
}

const SubitemTableView = ({columns, keyField = '_id', subItemKey, subItems}) => {
  const {user} = useSession();
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const itemPeopleRef = useRef();

  const sheetColumns = () => {
    if (columns) {
      const sheetCols = [];
      const isShowTitle = true;

      const displayPerson = (person, isShowComma, isShowTitle) => {
        let personItem = {};
        if (person._id === user._id) {
          personItem = user;
        } else {
          personItem = objectUser[person._id] || {};
        }
        return (
          <div
            key={person._id}
            className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
            data-toggle='tooltip'
            title={isShowTitle ? person?.name : null}
            data-original-title={person.name}
          >
            <span className='symbol-label text-uppercase bg-secondary'>
              <span className='position-absolute'>{generateNameAvatar(person?.name)}</span>
              {personItem?.avatar && (
                <img
                  src={personItem?.avatar}
                  className='w-100 h-100 rounded-circle'
                  style={{zIndex: 10}}
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                  alt=''
                />
              )}
            </span>
          </div>
        );
      };

      columns.forEach((column) => {
        const displayEmpty = () => {
          if (!column?.display_mode || column.display_mode === 'avatar') {
            return <div className='symbol symbol-20px symbol-circle symbol-light border-0'></div>;
          } else {
            return (
              <div className='k'>
                <span className='symbol-label  text-uppercase'></span>
              </div>
            );
          }
        };

        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column.name,
            text: column.name,
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap ',
            column: column,
            footer: '',
            formatter: (cell) => {
              return '';
            },
            editable: false,
          };
          if (column.field === 'title') {
            col.headerClasses = 'sheet-column-header text-center text-nowrap';
            col.formatter = (cell, row, rowIndex) => {
              return (
                <TitleColumn cell={cell} row={row} field={column.field} subItemKey={subItemKey} />
              );
            };
            col.classes = 'h-35px';
          } else if (column.type === 'text') {
            col.formatter = () => {
              return;
            };
            col.footer = '';
            col.editorClasses = () => 'form-control  form-control-xs item-text-input ';
          } else if (column.type === 'date') {
            col.formatter = (cell, row) => {
              const currentValue = effectiveValue(cell);
              return (
                <div className='date-sheet-table-column'>
                  {currentValue ? (
                    <span className='px-2'>
                      {format(
                        fromUnixTime(currentValue),
                        column?.settings?.hasTime ? 'dd-MM-yyyy, HH:mm' : 'dd-MM-yyyy'
                      )}
                    </span>
                  ) : null}{' '}
                </div>
              );
            };
            col.editable = false;
            col.classes = 'multi-edit-yes';
          } else if (column.type === 'status') {
            col.editable = false;
            col.classes = 'text-center multi-edit-yes';
            col.formatter = (cell, row) => {
              const cellOption = column.values?.filter(
                (x) => x.value === cell?.value || x.value === cell || (!cell && x.value === '')
              );
              const label = cellOption?.length > 0 ? cellOption[0].label : '';
              const backgroundColor =
                cellOption?.length > 0 ? cellOption[0].backgroundColor : 'transparent';
              return (
                <div
                  className='table-column-color h-100 py-2'
                  style={{backgroundColor: `${backgroundColor}`}}
                >
                  {label}
                </div>
              );
            };
          } else if (column.type === 'color') {
            col.editable = false;
            col.classes = 'text-center multi-edit-yes';
            col.formatter = (cell, row) => {
              const cellOption = column.values?.filter(
                (x) => x.value === cell?.value || x.value === cell || (!cell && x.value === '')
              );
              const label = cellOption?.length > 0 ? cellOption[0].label : '';
              const backgroundColor =
                cellOption?.length > 0 ? cellOption[0].backgroundColor : 'transparent';
              return (
                <div
                  className='table-column-color h-100 py-2'
                  style={{backgroundColor: `${backgroundColor}`}}
                >
                  {label}
                </div>
              );
            };
          } else if (column.type === 'people') {
            col.editable = false;
            col.classes = 'text-center multi-edit-yes';
            col.formatter = (cell, row) => {
              const currentPeople = effectiveValue(cell);

              return (
                <div>
                  {itemPeopleRef.current?.scrollWidth === itemPeopleRef.current?.offsetWidth ? (
                    <div
                      className={`symbol-group symbol-hover user-select-none d-flex flex-nowrap justify-content-center ms-0 
              ${clsx({'people-name-limit-text-1': column?.display_mode === 'full'})}
              `}
                      ref={itemPeopleRef}
                    >
                      {isArray(currentPeople) && (
                        <>
                          {!column?.display_mode || column?.display_mode === 'avatar' ? (
                            currentPeople?.map((member, index) => {
                              const isShowComma = index < currentPeople.length - 1;
                              return (
                                <div key={member._id || index} className='h-20px'>
                                  {displayPerson(member, isShowComma, isShowTitle)}
                                </div>
                              );
                            })
                          ) : (
                            <div>{currentPeople?.map((member) => member?.name).join(', ')}</div>
                          )}
                        </>
                      )}
                      {(!currentPeople || currentPeople.length === 0) && displayEmpty()}
                    </div>
                  ) : (
                    <OverlayTrigger
                      key='title-item'
                      placement='top'
                      overlay={
                        <Tooltip
                          className='tooltip-inverse tooltip-title-item'
                          id='tooltip-title-item'
                        >
                          {currentPeople.map((people) => people.name).join(', ')}
                        </Tooltip>
                      }
                    >
                      <div
                        className={`symbol-group symbol-hover user-select-none d-flex flex-nowrap justify-content-center ms-0 position-relative ${clsx(
                          {'people-name-limit-text-1': column?.display_mode === 'full'}
                        )}`}
                        ref={itemPeopleRef}
                      >
                        {isArray(currentPeople) && (
                          <>
                            {!column?.display_mode || column?.display_mode === 'avatar' ? (
                              currentPeople?.map((member, index) => {
                                const isShowComma = index < currentPeople.length - 1;
                                return (
                                  <div key={member._id || index} className='h-20px'>
                                    {displayPerson(member, isShowComma, isShowTitle)}
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                <div>{currentPeople?.map((member) => member?.name).join(', ')}</div>
                                <div className='symbol symbol-20px symbol-circle symbol-light border border-dark' />
                              </>
                            )}
                          </>
                        )}
                        <div className='symbol symbol-20px symbol-circle symbol-light border border-dark position-absolute top-0 end-0'>
                          <span className='symbol-label text-uppercase bg-secondary'>
                            {currentPeople.length}
                          </span>
                        </div>
                        {(!currentPeople || currentPeople.length === 0) && displayEmpty()}
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
              );
            };
          } else if (column.type === 'reference') {
            col.editable = false;
            col.classes = 'text-center multi-edit-yes';
            col.formatter = (cell, row) => '';
          } else if (column.type === 'timeline') {
            col.formatter = (cell, row) => '';
            col.editable = false;
            col.classes = '';
          } else if (column.type === 'number') {
            col.editable = false;
            col.classes = 'text-center multi-edit-yes';
            col.formatter = (cell, row) => '';
          } else if (column.type === 'dependency') {
            col.formatter = (cell, row) => '';
            col.editable = false;
            col.classes = '';
          } else if (column.type === 'link') {
            col.formatter = (cell, row) => {
              return '';
            };
            col.footer = '';
            col.editorClasses = () => 'form-control  form-control-xs item-text-input ';
          } else if (column.type === 'formula') {
            col.formatter = (cell, row) => '';
          } else {
            col.editable = false;
            col.classes = 'text-center ';
          }

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          sheetCols.push(col);
        }
      });

      return sheetCols;
    } else {
      return [
        {
          accessor: '',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header text-left',
          footer: '',
          editable: false,
        },
      ];
    }
  };

  return (
    <OGridViewTemplate
      classes='table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1'
      keyField={keyField}
      data={subItems || []}
      columns={sheetColumns()}
      subItemKey={subItemKey}
    ></OGridViewTemplate>
  );
};

const TitleColumn = ({cell, row, showExpand = false, subItemKey, showDetail}) => {
  const effValue = effectiveValue(cell);

  const [showSubItem, setShowSubItem] = useState(false);

  const toggleShowSubitem = () => {
    let newVisibility = !showSubItem;
    let subitemsContainer = document.getElementById(row._id + subItemKey);
    if (subitemsContainer) {
      if (newVisibility) {
        subitemsContainer.style.display = 'block';
      } else {
        subitemsContainer.style.display = 'none';
      }
    }

    setShowSubItem(newVisibility);
  };

  return (
    <div className='d-flex sheet-item-detail-title align-items-center'>
      {showExpand && (
        <div
          className='btn btn-icon btn-xs text-nowrap w-30px flex-shrink-0 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            toggleShowSubitem();
          }}
        >
          {showSubItem ? (
            <i className='bi bi-chevron-down '></i>
          ) : (
            <i className='bi bi-chevron-right cursor-pointer'></i>
          )}
        </div>
      )}

      <div className='flex-grow-1'>
        <span className='w-100 px-2 title-limit-text-1'>{effValue}</span>
      </div>
      <div
        className='btn btn-icon btn-xs text-nowrap w-45px border-start flex-shrink-0'
        onClick={(e) => {
          e.stopPropagation();
          showDetail(row);
        }}
      >
        <i className='icon-md las la-expand-arrows-alt'></i>
      </div>
    </div>
  );
};

export default TemplatePreview;
