export const getGrowthBook = () => {
  return window.GROWTH_BOOK_KEY;
}

export const configOptimizely = () => {
}

const configs = {
  growthBookKey: () => {
    return window.GROWTH_BOOK_KEY;
  },

  growthBookHost: () => {
    return window.GROWTH_BOOK_HOST || 'https://cdn.growthbook.io';
  },

  domain: () => {
    if (window.APP_DOMAIN && window.APP_DOMAIN !== '') {
      return window.APP_DOMAIN;
    } else {
      var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
      if (subdomain) {
        return subdomain;
      } else {
        return '';
      }
    }
  }
}

export default configs;