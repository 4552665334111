import { useOutsideClick } from "app/common/hooks/useOutsideClick";
import { backgroundColorOptions } from "../contains";

export const DialogColorGroup = ({group, onChangeColor, onShowDialogChangeColor}) => {
  const handleClickOutside = () => {
    onShowDialogChangeColor(null);
  };

  const refDialogColor = useOutsideClick(handleClickOutside);

  return (
    <div className='list-color-group rounded shadow p-3' ref={refDialogColor}>
      {backgroundColorOptions.map((color) => {
        return (
          <div
            className='item-color-group rounded-1'
            style={{backgroundColor: color.value}}
            onClick={() => {
              onChangeColor(color.value, group);
            }}
          ></div>
        );
      })}
    </div>
  );
};
