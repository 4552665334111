import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  BackgroundVariant,
  MiniMap,
  Controls,
  ControlButton,
  Panel,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {edgeTypes, nodeTypes, worflowInitNodes, workflowTranform} from '../workflowHelper';
import {cloneDeep, isEmpty, isNil} from 'lodash';
import NodeDetail from './NodeDetail';
function WorkflowEditor({workflowNodes, workflow, onUpdateWorkflow, sheetList}) {
  const reactFlow = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const [nodeDetail, setNodeDetail] = useState(null);
  const isActive = workflow?.status == '1';
  const [worflowName, setWorkflowName] = useState(workflow?.name ?? '');

  useEffect(() => {
    if (workflow) {
      setWorkflowName(workflow?.name);
    }
  }, [workflow]);
  
  const initNodes = async (workflowNodes) => {
    if (workflowNodes && !isEmpty(workflowNodes)) {
      const result = await workflowTranform(workflowNodes);
      setNodes(result?.nodes);
      setEdges(result?.edges);
    } else {
      let newWorkflowNodes = cloneDeep(worflowInitNodes);
      setNodes(newWorkflowNodes);
    }
    setTimeout(() => {
      reactFlow.fitView({padding: 1});
    }, 100);
  };

  useEffect(() => {
    setTimeout(() => {
      reactFlow.fitView({padding: 1});
    }, 100);
  }, []);

  useEffect(() => {
    initNodes(workflowNodes);
  }, [workflowNodes]);

  const onConnect = useCallback((params) => {
    setEdges([...edges, {...params, type: 'workflowEdge'}]);
  }, []);

  const onNodeClick = (event, node) => {
    if (node.type === 'temp') {
      let newNodes = nodes.map((nodeElement) => {
        if (nodeElement.id === node.id) {
          return {...nodeElement, type: 'action'};
        }
        return nodeElement;
      });
      setNodes(newNodes);
    } else {
      if (node?.data?.empty) return;
      setNodeDetail({...node});
    }
  };

  const handleChangeStatus = () => {
    onUpdateWorkflow({status: isActive ? '0' : '1'});
  };

  const onBlurWorkFlowName = (e) => {
    const newWorkflowName = e.target.value;
    if (newWorkflowName !== workflow?.name) {
      onUpdateWorkflow({name: newWorkflowName});
    }
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={onNodeClick}
        nodesDraggable={false}
        zoomOnDoubleClick={false}
      >
        <Panel position='top-left'>
          <div className='bg-white p-3 rounded border fw-bold fs-2 d-flex gap-2 align-items-center'>
            {isActive ? (
              <i className='la la-check text-success fs-2' />
            ) : (
              <i className='la la-close text-danger fs-2' />
            )}
            <input
              value={worflowName}
              className='workflow-input-name fw-bold fs-2 text-truncate'
              onChange={(e) => setWorkflowName(e.target.value)}
              onBlur={onBlurWorkFlowName}
            />
          </div>
        </Panel>
        <Background variant={BackgroundVariant.Dots} />
        <MiniMap />
        <Controls>
          <ControlButton>
            <div onClick={handleChangeStatus}>
              {isActive ? (
                <i className='la la-close text-danger' />
              ) : (
                <i className='la la-check text-success' />
              )}
            </div>
          </ControlButton>
        </Controls>
      </ReactFlow>
      <NodeDetail
        show={!isNil(nodeDetail)}
        onClose={() => {
          setNodeDetail(null);
        }}
        node={nodeDetail}
        sheetList={sheetList}
      />
    </>
  );
}

export default WorkflowEditor;
