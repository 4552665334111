import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {Nav} from 'react-bootstrap';

function NotificationTabNavigation({onSelectTab}) {
  const {t} = useTranslate();
  return (
    <Nav variant='tabs' className='w-100 user-select-none' onSelect={onSelectTab} defaultActiveKey={'all'}>
      <Nav.Item className='cursor-pointer user-select-none'>
        <Nav.Link eventKey='all'>{t('notification.all')}</Nav.Link>
      </Nav.Item>
      <Nav.Item className='cursor-pointer user-select-none'>
        <Nav.Link eventKey='unread'>{t('notification.unread')}</Nav.Link>
      </Nav.Item>
      {/* <Nav.Item className='cursor-pointer user-select-none'>
        <Nav.Link eventKey='metioned'>{t('notification.metioned')}</Nav.Link>
      </Nav.Item>
      <Nav.Item className='cursor-pointer user-select-none'>
        <Nav.Link eventKey='assigned'>{t('notification.assigned')}</Nav.Link>
      </Nav.Item> */}
    </Nav>
  );
}

export default NotificationTabNavigation;
