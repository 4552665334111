import {Badge, Dropdown} from 'react-bootstrap';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {debounce, isArray, isEmpty} from 'lodash';
import {effectiveValue, generateNameAvatar} from 'app/modules/work/helpers';
import {searchPeopleAction, searchTeamsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo, useRef, useState} from 'react';

import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import {useSession} from 'core/store/core/hooks';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import './columnUserPermittedApprovalEdit.scss';
import Select from 'react-select';

function ColumnUserPermittedApprovalEdit({
  value,
  item,
  column,
  sheet,
  onChange,
  onChangeUserColumn,
  fieldEditable,
  readOnly,
  containerClassName,
  onAddValue,
  onRemoveValue,
  sheetColumns,
}) {
  const sortByMembers = sheet?.members;
  const field = column?.field;
  const currentPeople = effectiveValue(value) || [];
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const {user} = useSession();
  const [memberIds, setMemberIds] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [expandedOnce, setExpandedOnce] = useState(false);
  const [peopleOptionIds, setPeopleOptionIds] = useState([]);
  const itemPeopleRef = useRef();
  const isShowTitle = true;
  const [isContentOverflow, setIsContentOverflow] = useState(false);
  const [permittedUserColumns, setPermittedUserColumns] = useState([]);

  const search = async (text) => {
    const people = await dispatch(searchPeopleAction({filter: {q: text}, sort: {ids: memberIds}}));
    const teams = await dispatch(searchTeamsAction({filter: {q: text}}));
    const teamOption = teams.map((team) => {
      return {...team, type: 'team'};
    });
    const newPeopleOptions = [...people, ...teamOption];
    setPeopleOptions(newPeopleOptions);
  };

  useEffect(() => {
    if (expandedOnce) {
      searchPeople('');
    }
  }, [expandedOnce]);

  const searchPeople = (e) => {
    const value = e?.target?.value;
    search(value);
  };

  const selectPerson = (person) => {
    const maxPeopleAllowed = column?.settings?.max_people_allowed;

    const countCurrentPeople = currentPeople?.length || 0;
    if (maxPeopleAllowed > 1 && countCurrentPeople >= maxPeopleAllowed) {
      snackNotification.showWarning(t('sheet_column_people_warning_max_people'));
      return false;
    }

    const newPeople = [];
    const newPeopleIds = [];

    if (currentPeople && isArray(currentPeople) && (maxPeopleAllowed > 1 || !maxPeopleAllowed)) {
      currentPeople.forEach((people) => {
        if (people._id != person._id) {
          newPeople.push(people);
          newPeopleIds.push(people._id);
        }
      });
    }
    let people = {name: person.name, id: person._id, _id: person._id};

    if (person?.type) {
      people.type = person?.type;
    }
    newPeople.push(people);
    setPeopleOptionIds(newPeopleIds);
    onChange && onChange(item, column.field, value, newPeople);

    return true;
  };

  const removePerson = (person) => {
    const newPeople = [];
    const newPeopleIds = [];
    if (currentPeople) {
      currentPeople.forEach((people) => {
        if (people._id !== person._id) {
          newPeople.push(people);
          newPeopleIds.push(people._id);
        }
      });
    }
    setPeopleOptionIds(newPeopleIds);
    onChange && onChange(item, column.field, value, newPeople);
  };

  const selectPersonMultiple = (person) => {
    const maxPeopleAllowed = Number(column?.settings?.max_people_allowed);
    const countCurrentPeople = currentPeople?.length || 0;

    if (maxPeopleAllowed > 1 && countCurrentPeople >= maxPeopleAllowed) {
      snackNotification.showWarning(t('sheet_column_people_warning_max_people'));
      return false;
    }

    const {name, _id: id, _id} = person;
    let newVal = {name, id, _id};
    if (person?.type) {
      newVal.type = person?.type;
    }
    if (maxPeopleAllowed === 1) {
      onChange && onChange(item, field, value, [newVal]);
      return true;
    }

    if (!maxPeopleAllowed || countCurrentPeople < Number(maxPeopleAllowed)) {
      onAddValue && onAddValue(item, field, newVal);
      return true;
    }
  };

  const removePersonMultiple = (person) => {
    onRemoveValue && onRemoveValue(item, field, person);
  };

  const handleSelectPerson = (person) => {
    onAddValue ? selectPersonMultiple(person) : selectPerson(person);
  };

  const handleRemovePerson = (person) => {
    onRemoveValue ? removePersonMultiple(person) : removePerson(person);
  };

  const avatar = (person) => {
    let personItem = {};
    if (person._id === user._id) {
      personItem = user;
    } else {
      personItem = objectUser[person._id] || {};
    }
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={person?.name}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          {personItem?.avatar ? (
            <img
              src={personItem?.avatar}
              className='w-100 h-100 rounded-circle position-absolute'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          ) : (
            <span className='position-absolute'>
              {person?.type && person?.type === 'team' ? (
                <i className='la la-user-friends' />
              ) : (
                generateNameAvatar(person?.name)
              )}
            </span>
          )}
        </span>
      </div>
    );
  };

  const displayPerson = (person, isShowComma, isShowTitle) => {
    let personItem = {};
    if (person._id === user._id) {
      personItem = user;
    } else {
      personItem = objectUser[person._id] || {};
    }
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={isShowTitle ? person?.name : null}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          <span className='position-absolute'>{generateNameAvatar(person?.name)}</span>
          {personItem?.avatar && (
            <img
              src={personItem?.avatar}
              className='w-100 h-100 rounded-circle'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          )}
        </span>
      </div>
    );
  };

  const displayTeam = (team, isShowComma, isShowTitle) => {
    let teamObject = {};
    if (team._id === user._id) {
      teamObject = user;
    } else {
      teamObject = objectUser[team._id] || {};
    }
    return (
      <div
        key={team._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={isShowTitle ? team?.name : null}
        data-original-title={team.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          {teamObject?.avatar ? (
            <img
              src={teamObject?.avatar}
              className='w-100 h-100 rounded-circle position-absolute'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          ) : (
            <span className='position-absolute'>
              <i className='la la-user-friends' />
            </span>
          )}
        </span>
      </div>
    );
  };

  const displayEmpty = () => {
    if (!column?.display_mode || column.display_mode === 'avatar') {
      return <div className='symbol symbol-20px symbol-circle symbol-light border-0'></div>;
    } else {
      return (
        <div className='k'>
          <span className='symbol-label  text-uppercase'></span>
        </div>
      );
    }
  };

  const checkContentOverflow = () => {
    if (itemPeopleRef?.current) {
      return itemPeopleRef.current?.scrollWidth > itemPeopleRef.current?.clientWidth;
    }
    return false;
  };

  const selectUserColumns = (columns) => {
    setPermittedUserColumns(columns);
    onChangeUserColumn(columns);
  };

  useEffect(() => {
    let newPeopleIds = [];
    if (currentPeople && isArray(currentPeople)) {
      newPeopleIds = currentPeople.map((people) => {
        return people._id;
      });
    }
    setPeopleOptionIds(newPeopleIds);
  }, [value]);

  useEffect(() => {
    const listMemberId = sortByMembers?.map((member) => member.id);
    setMemberIds(listMemberId);
  }, [sortByMembers]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // this will get called whenever div dimension changes
      entries.forEach((entry) => {
        setIsContentOverflow(checkContentOverflow());
      });
    });
    if (itemPeopleRef?.current) {
      resizeObserver.observe(itemPeopleRef?.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setIsContentOverflow(checkContentOverflow());
  }, [value]);

  useEffect(() => {
    if (value?.columns) {
      setPermittedUserColumns(value?.columns);
    }
  }, [value?.columns]);

  const columnOptionPermitted = useMemo(() => {
    return sheetColumns
      ?.filter((column) => column.type === 'people' && !column?.deleted && !column?.hidden)
      ?.map((column) => ({label: column.name, value: column.field}));
  }, [sheetColumns]);

  const popover = () => {
    return (
      <div className='m-4 w-350px'>
        {currentPeople?.length > 0 && (
          <div className='mb-2'>
            {isArray(currentPeople) &&
              currentPeople?.map((member, index) => {
                return (
                  <Badge
                    pill
                    key={member._id || index}
                    bg='light'
                    className='m-1 p-1 align-items-center align-middle'
                  >
                    {avatar(member)}
                    <span className='ms-1 text-dark h-100'>{member.name}</span>
                    {fieldEditable && (
                      <button
                        className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                        onClick={() => handleRemovePerson(member)}
                      >
                        <i className='las la-times text-muted'></i>
                      </button>
                    )}
                  </Badge>
                );
              })}
          </div>
        )}
        {fieldEditable && (
          <>
            <div className='mb-2'>
              <Select
                formatOptionLabel={({label}) => <div className='text-capitalize'>{label}</div>}
                cacheOptions
                classNamePrefix='select'
                options={columnOptionPermitted}
                placeholder={t('sheet_edit_column_user_permitted_placeholder')}
                isMulti
                closeMenuOnSelect={false}
                value={permittedUserColumns}
                onChange={(columns) => {
                  selectUserColumns(columns);
                }}
              />
            </div>
            <div className='mb-2'>
              <input
                placeholder='Tìm kiếm'
                type='text'
                className='form-control form-control-sm'
                onChange={debounce(searchPeople, 500)}
              ></input>
            </div>
            <hr className='border-start-1 mt-1' />
            {peopleOptions.map((person) => {
              let personItem = {};
              if (person._id === user._id) {
                personItem = user;
              } else {
                personItem = objectUser[person._id] || {};
              }
              return (
                <Dropdown.Item
                  key={person._id}
                  size='xs'
                  className='dropdown-item-xs d-flex align-items-center'
                  onClick={(e) => {
                    handleSelectPerson(person) || e.preventDefault();
                  }}
                  disabled={peopleOptionIds.includes(person._id)}
                >
                  <div className='position-relative w-20px h-15px'>
                    {person?.type && person?.type === 'team' ? (
                      <i className='la la-user-friends me-2 fs-5 position-absolute' />
                    ) : (
                      <i className='la la-user-circle me-2 fs-5 position-absolute' />
                    )}
                    {personItem?.avatar && (
                      <img
                        src={personItem?.avatar}
                        className='w-15px h-15px rounded-circle me-2 position-absolute'
                        onError={(e) => {
                          e.target.style.display = 'none';
                        }}
                        alt=''
                      />
                    )}
                  </div>
                  {person.name}
                </Dropdown.Item>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const renderPeople = () => {
    const displayMode = column?.display_mode || 'avatar';
    if (displayMode === 'avatar') {
      return (
        <div className='symbol-group symbol-hover people-container justify-content-center flex-nowrap'>
          {currentPeople.map((member, index) => {
            const isShowComma = index < currentPeople.length - 1;
            return (
              <div key={member?._id || index}>
                {member?.type && member?.type === 'team'
                  ? displayTeam(member, isShowComma, isShowTitle)
                  : displayPerson(member, isShowComma, isShowTitle)}
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className='people-container'>
        <div
          className={`${
            isContentOverflow ? 'people-main-content-overflow' : 'people-main-content'
          }`}
        >
          {currentPeople?.map((member) => member?.name).join(', ')}
        </div>
        {isContentOverflow && currentPeople.length > 1 && (
          <div className='people-count-container'>
            <div className='people-count'>{currentPeople.length}</div>
          </div>
        )}
      </div>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <>
        <DropdownPopover
          onToggle={() => setExpandedOnce(true)}
          renderPopover={popover}
          triggerClassName={'d-flex align-items-center'}
        >
          <OverlayTrigger
            key={'title-item'}
            placement='top'
            overlay={
              <Tooltip
                className={`${
                  isContentOverflow ? 'd-block' : 'd-none'
                } tooltip-inverse tooltip-title-item`}
                id='tooltip-title-item'
              >
                {isArray(currentPeople) && currentPeople.map((people) => people.name).join(', ')}
              </Tooltip>
            }
          >
            <div
              className={`w-100 text-truncate d-flex align-items-center ${
                isContentOverflow ? '' : 'justify-content-center'
              }`}
              ref={itemPeopleRef}
            >
              {isArray(currentPeople) && !isEmpty(currentPeople) ? renderPeople() : displayEmpty()}
            </div>
          </OverlayTrigger>
        </DropdownPopover>
      </>
    );
  }

  return (
    <>
      <OverlayTrigger
        key={'title-item'}
        placement='top'
        overlay={
          <Tooltip
            className={`${
              isContentOverflow ? 'd-block' : 'd-none'
            } tooltip-inverse tooltip-title-item`}
            id='tooltip-title-item'
          >
            {isArray(currentPeople) && currentPeople.map((people) => people.name).join(', ')}
          </Tooltip>
        }
      >
        <div
          className={`w-100 text-truncate d-flex align-items-center bg-inherit ${
            isContentOverflow ? '' : 'justify-content-center'
          }`}
          ref={itemPeopleRef}
        >
          {isArray(currentPeople) && !isEmpty(currentPeople) ? renderPeople() : displayEmpty()}
        </div>
      </OverlayTrigger>
    </>
  );
}

export default ColumnUserPermittedApprovalEdit;
