import {isArray, isEmpty, isNil, isString} from 'lodash';
import {boardType} from '../constant';
import {shallowEqual, useSelector} from 'react-redux';
import moment from 'moment';

const useImportPage = () => {
  const {allUsers} = useSelector((state) => ({allUsers: state.core.allUsers}), shallowEqual);
  const generateField = (column, sheet) => {
    let newFieldName = column.type + '1';
    if (sheet.columns?.length > 0) {
      for (let i = 1; i < 100; i++) {
        //Loop until the column name is not exist
        newFieldName = column.type + i;
        let foundColumns = sheet.columns.filter((x) => {
          return x.field === newFieldName || x.field === newFieldName;
        });
        if (foundColumns.length === 0) {
          break;
        }
      }
    }

    return newFieldName;
  };

  const transformRowValue = (value, column) => {
    if (value) {
      if (column?.type === 'people') {
        let peopleValue = [];
        if (isArray(value)) {
          peopleValue = value;
        } else if (isString(value)) {
          peopleValue = value.split(',').map((element) => {
            let user = allUsers?.find((user) => user?.name === element);
            let userId = user?.id ?? user?._id;
            return {id: userId, name: element};
          });
        }

        return {value: peopleValue};
      } else if (['color', 'priority', 'status', 'dropdown'].includes(column?.type)) {
        if (column?.values && isArray(column?.values)) {
          let colorValue = column?.values?.find(
            (val) =>
              val?.label?.toLowerCase() === value?.toLowerCase() ||
              val.value === '' ||
              val.value === 'default' ||
              val.is_default
          );
          if (colorValue) return {...colorValue};
          return {value: value ?? null};
        }
      } else {
        return {value: value};
      }
    } else {
      return {value: null};
    }
  };
  const transformAnalyzeFileToSheet = (analyzeData) => {
    let sheet = {
      name: analyzeData.sheet,
      code: '',
      type: boardType.SHEET,
      parent: '',
      confidential: 'allow_search',
      privacy: 'private',
      terminology: 'task',
      columns: [],
    };

    if (analyzeData?.columns && !isEmpty(analyzeData?.columns)) {
      analyzeData.columns.forEach((column) => {
        let definition = column.definition;
        sheet.columns.push({
          name: column.name,
          field: generateField(definition, sheet),
          styles: definition.styles ?? {width: '100px'},
          ...definition,
        });
      });
    }
    let items = [];
    if (analyzeData.rows) {
      analyzeData.rows.forEach((row) => {
        let rowValue = Object.values(row);
        if (rowValue.some((val) => !isNil(val))) {
          let item = {};
          sheet.columns.forEach((column) => {
            item[column.field] = transformRowValue(row[column.name], column);
          });
          items.push(item);
        }
      });
    }
    return {sheet, items};
  };

  const mappingItemsColumn = (originColumns, mappingColumns, items) => {
    let newItems = [];
    let mapping = {};
    let mappedField = [];

    //mapping title column first
    let titleColumn = originColumns.find(
      (column) => column?.field === 'title' || column?.type === 'text'
    );
    let titleMappingColumn = mappingColumns.find(
      (column) => column?.field === 'title' || column?.type === 'text'
    );
    mapping[titleColumn.field] = titleMappingColumn?.field;
    mappedField.push(titleMappingColumn?.field);
    originColumns = originColumns.filter((column) => column.field !== titleColumn.field);

    //mapping column by field
    originColumns.forEach((column) => {
      let findByField = mappingColumns.find(
        (mappingColumn) =>
          mappingColumn.field === column.field && !mappedField.includes(mappingColumn.field)
      );
      if (findByField) {
        mapping[column.field] = findByField?.field;
        mappedField.push(findByField?.field);
      }
    });

    //mapping column by type
    originColumns.forEach((column) => {
      let mappedColumnField = Object.keys(mapping);
      let isMapped = mappedColumnField.includes(column?.field);
      if (!isMapped) {
        let findByType = mappingColumns.find((mappingColumn) => {
          // If column type is dropdown, mapping type must be color, priority or status
          if (column?.type === 'dropdown') {
            if (!mappedField.includes(mappingColumn.field)) {
              return ['color', 'priority', 'status'].includes(mappingColumn.type);
            }
          }
          return mappingColumn.type === column.type && !mappedField.includes(mappingColumn.field);
        });
        if (findByType) {
          mapping[column.field] = findByType?.field;
          mappedField.push(findByType?.field);
        }
      }
    });
    items.forEach((item) => {
      let newItem = {};
      Object.keys(mapping).forEach((key) => {
        let sheetColumn = mappingColumns.find((column) => column?.field === mapping[key]);
        newItem[mapping[key]] = transformRowValue(item[key]?.value, sheetColumn);
      });
      newItems.push(newItem);
    });
    return newItems;
  };

  return {
    transformAnalyzeFileToSheet,
    mappingItemsColumn,
  };
};

export default useImportPage;
