import {Outlet} from 'react-router-dom';
import {PageDataProvider} from './core';
import {ThemeModeProvider} from './partials/theme-mode/ThemeModeProvider';
import {MasterInit} from './MasterInit';
import './assets/sass/application.scss';
const AppLayout = () => {
  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div id='application-container'>
          <Outlet />
        </div>
        <MasterInit />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export {AppLayout};
