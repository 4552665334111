import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {useLang} from 'core/i18n/Metronici18n';
import {useTranslate} from 'core/i18n/i18nProvider';
import {fromUnixTime} from 'date-fns';
import moment from 'moment';
import {useMemo} from 'react';
import DatePicker from 'react-datepicker';
import './dateValue.scss';

function DateValue({value, item, column, onChange, disabled}) {
  const effValue = effectiveValue(value);
  const lang = useLang();
  const {t} = useTranslate();

  const locale = useMemo(() => {
    if (lang === 'vi') {
      return 'vi';
    }
    if (lang === 'en') {
      return 'en';
    }
    return 'vi';
  }, [lang]); 

  function validateDate(date) {
    const regex = /^\d{1,2}-\d{1,2}-\d{4}(?:, \d{2}:\d{2})?$/;
    return regex.test(date);
  }

  const dateTimeFormat = column?.settings?.hasTime ? 'dd-MM-yyyy, HH:mm' : 'dd-MM-yyyy';

  return (
    <div className={clsx('date-sheet-table-column config-value rounded')}>
      <DatePicker
        disabled={disabled}
        locale={locale}
        dateFormat={dateTimeFormat}
        className='w-100 h-100 bg-transparent border-0 text-center'
        selected={effValue ? fromUnixTime(effValue) : null}
        todayButton={column?.settings?.hasTime ? null : t('sheet_column_date_button_today')}
        showTimeSelect={column?.settings?.hasTime}
        onChange={(val, e) => {
          if (!e || !e?.target?.value || validateDate(e?.target?.value)) {
            onChange({
              value: val ? moment(e?.target?.value || val, 'DD-MM-YYYY, HH:mm').unix() : null,
            });
          }
        }}
      ></DatePicker>
    </div>
  );
}

export default DateValue;
