import PageAddDialog from './PageAddDialog';
import React from 'react';
import {boardType} from '../constant';
import {cloneDeep} from 'lodash';
import {getInitWorkspaceRoles} from 'app/modules/work/workspace/config/constants';
import {initBaseColumns} from 'app/modules/application/templates/sheet';
import {useSession} from 'core/store/hooks';
import {useWorkspace} from 'app/modules/work/workspace/WorkspaceHook';

const initDefaultView = {
  name: '',
  type: 'table-paging',
  code: 'table-paging-1',
  is_default: true, // set table as a default sheet view
};

const PageAdd = ({show, onHide, isCreatingBaseSheet, onSaveSuccess}) => {
  const {user} = useSession();
  const {addPage} = useWorkspace(initDefaultView);
  const handleAddPage = (params) => {
    let _params = cloneDeep(params);
    _params.page.members = [
      {
        id: user._id,
        title: user.name,
        name: user.name,
        role: 'OWNER',
        isOwner: true,
      },
    ];
    _params.page.meta_version = '2.0';
    _params.page.roles = getInitWorkspaceRoles();

    if (isCreatingBaseSheet) {
      _params.page.columns = initBaseColumns;
    }
    addPage(_params);
  };
  return (
    <PageAddDialog
      show={show}
      type={boardType.SHEET}
      initialParentId={''}
      workspace={''}
      onHide={onHide}
      savePage={handleAddPage}
      onSaveSuccess={onSaveSuccess}
      options={{disableParent: true}}
    />
  );
};

export default PageAdd;
