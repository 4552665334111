import {useTranslate} from 'core/i18n/i18nProvider';
import {timeAgoFromDateShortString, timeAgoFromDateString} from 'app/common/_helpers/TimeHelper';
import {Card, CardBody, CardFooter, RichTextField} from 'app/common/_partials/controls';
import {Field, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import './itemUpdate.scss';
import {useSelector, shallowEqual} from 'react-redux';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {format} from 'date-fns';
import {Quill} from 'react-quill';
import CustomToggle from 'app/common/_partials/controls/CustomToggle';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

export default function ItemUpdateView({itemUpdate, saveItemUpdate, deleteItemUpdate, readOnly}) {
  const {t} = useTranslate();
  const objectUser = useSelector((state) => state?.core?.usersByIds, shallowEqual);
  const [isEdit, setIsEdit] = useState(false);
  const contentFieldRef = useRef();

  useEffect(() => {
    if (contentFieldRef.current) {
      const editor = contentFieldRef.current.editor;
      editor.clipboard.addMatcher('IMG', (node, delta) => {
        const Delta = Quill.import('delta');
        return new Delta().insert('');
      });
      editor.clipboard.addMatcher('PICTURE', (node, delta) => {
        const Delta = Quill.import('delta');
        return new Delta().insert('');
      });
    }
  }, [contentFieldRef.current]);

  const onEdit = () => {
    setIsEdit(true);
    setTimeout(() => {
      contentFieldRef.current && contentFieldRef.current.focus();
      contentFieldRef.current.getEditor().setSelection(itemUpdate.content.length, 0);
    }, 0);
  };

  const onDelete = () => {
    deleteItemUpdate && deleteItemUpdate(itemUpdate);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
  };

  const onInputBlur = () => {
    let editingValue = contentFieldRef.current.value;
    if (editingValue === itemUpdate.content) {
      setIsEdit(false);
    }
  };

  return (
    <Card className='border border-secondary rounded item-update-container'>
      <div className='card-header border-bottom-0 min-h-50px px-2 py-2'>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center'>
            <span className='symbol symbol-25px ' title={objectUser?.[itemUpdate?.user?.id]?.name}>
              <span className='symbol-label rounded-circle border border-dark'>
                {objectUser?.[itemUpdate?.user?.id]?.avatar ? (
                  <img
                    src={objectUser?.[itemUpdate?.user?.id]?.avatar}
                    className='w-100 h-100 rounded-circle'
                    alt=''
                  />
                ) : (
                  itemUpdate?.user?.name && itemUpdate?.user?.name[0]
                )}
              </span>
            </span>
            <span className='sub-title m-0 ms-2'>
              {objectUser?.[itemUpdate?.user?.id]?.name}
            </span>
          </div>
        </div>
        {!readOnly && (
          <div className='card-header-toolbar d-flex align-items-center gap-2'>
            <span className='icon-clock fs-icon-s' />
            <OverlayTrigger
              key={'item-update-tooltip'}
              placement='top'
              overlay={
                <Tooltip
                  className='tooltip-inverse'
                  style={{zIndex: 10000}}
                  id='item-update-tooltip'
                >
                  {format(new Date(itemUpdate?.created_at), 'dd-MM-yyyy HH:mm:ss')}
                </Tooltip>
              }
            >
              <span className='text-muted font-size-xs'>
                {timeAgoFromDateShortString(itemUpdate?.created_at)}
              </span>
            </OverlayTrigger>
            <span className='icon-bell fs-icon-s' />

            <Dropdown
              className='dropdown-inline sheet-column-header-tool'
              align='end'
              autoclose='true'
            >
              <Dropdown.Toggle id='dropdown-toggle-top' as={CustomToggle}>
                <span className='icon-more fs-icon' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdown-menu dropdown-menu-xs dropdown-menu-right p-2'>
                <div
                  className='w-100 d-flex justify-content-start align-items-center p-1 cursor-pointer hover-secondary w-50 gap-2'
                  onClick={onEdit}
                >
                  <span className='icon-edit fs-icon' />
                  <span>{t('sheet_item_detail_update_edit')}</span>
                </div>
                <div
                  className='w-100 d-flex justify-content-start align-items-center p-1 cursor-pointer hover-secondary w-50 gap-2'
                  onClick={onDelete}
                >
                  <span className='icon-trash fs-icon' />
                  <span>{t('sheet_item_detail_update_delete')}</span>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <CardBody className='p-2'>
        {
          <div
            className={isEdit ? 'd-none' : 'item-update-content'}
            dangerouslySetInnerHTML={{__html: itemUpdate?.content?.replace('<p><br></p>', '')}}
          ></div>
        }
        {
          <div className={isEdit ? '' : 'd-none'}>
            <Formik
              enableReinitialize={true}
              initialValues={{id: itemUpdate?._id, content: itemUpdate?.content}}
              onSubmit={(values) => {
                saveItemUpdate(values, () => {
                  setIsEdit(false);
                });
              }}
            >
              {({handleSubmit}) => {
                return (
                  <>
                    <Field
                      name='content'
                      placeholder={t('sheet_item_detail_add_update_placeholder')}
                      component={RichTextField}
                      innerRef={contentFieldRef}
                      withFeedbackLabel
                      customFeedbackLabel
                      onBlur={onInputBlur}
                    />
                    <div className='mt-2 d-flex justify-content-between'>
                      <span></span>
                      <span>
                        <button className='btn btn-sm btn-secondary me-2' onClick={onCancelEdit}>
                          {t('common_cancel')}
                        </button>
                        <button className='btn btn-sm btn-primary' onClick={handleSubmit}>
                          {t('common_save')}
                        </button>
                      </span>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        }
      </CardBody>
      {/* <CardFooter className='d-flex p-2'>
        <div className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary w-50 gap-2'>
          <span className='icon-thumbsup fs-icon' />
          <span>{t('sheet_detail_like')}</span>
        </div>
        <div className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary w-50 gap-2'>
          <span className='icon-message fs-icon' />
          <span>{t('sheet_detail_reply')}</span>
        </div>
      </CardFooter> */}
    </Card>
  );
}
