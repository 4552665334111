import './sheetDashboardView.scss';

import {Card, CardBody} from 'app/common/_partials/controls';
import {forwardRef, useEffect, useState} from 'react';
import {Drawer} from '@material-ui/core';
import {ViewMode} from 'gantt-task-react';
import WidgetEditView from './widget-edit/WidgetEditView';
import WidgetView from './WidgetView';
import {useInterfaceThemeContext} from '../../sheet-view/interface-view/InterfaceThemeContext';
import {useSession} from 'core/store/hooks';
import {useSheetData} from '../../../SheetDataHook';
import {useSheetViewEvent} from '../SheetViewEventHook';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ReactSortable} from 'react-sortablejs';

export default function SheetDashboardView({
  sheetId,
  sheet,
  view,
  setSheet,
  onUpdateView,
  onSaveFilter,
  editable,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
  },
  renderToolbar,
  initMode = 'view',
}) {
  const {t} = useTranslate();
  const [editWidget, setEditWidget] = useState(null);
  const [widgets, setWidgets] = useState(view.widgets || []);
  const [mode, setMode] = useState(initMode);
  const [subSheet, setSubSheet] = useState(null);

  useEffect(() => {
    if (view) {
      setWidgets(view?.widgets ?? []);
    }
  }, [view]);

  const {user, getDomain} = useSession();
  const {backgroundColor, textColor} = useInterfaceThemeContext();

  const {
    fetchSheetItems,
    viewItems,
    viewItemsByGroups,
    updateItem: onUpdateItem,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
    requestFetchSheetItem,
  } = useSheetData({id: sheetId, sheet, view, user, setSheet});

  const {fetchSheetItems: fetchSheetSubItems, viewItems: viewSubItems} = useSheetData({
    id: sheet?.sub_boards?.[0],
    setSheet: setSubSheet,
  });

  const eventHandler = useSheetViewEvent();

  useEffect(() => {
    if (sheetId) {
      fetchSheetItems();
      eventHandler.initialize({
        domain: getDomain(),
        sheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [sheetId]);

  useEffect(() => {
    if (sheet?.sub_boards?.[0]) {
      fetchSheetSubItems();
    }
  }, [sheet?.sub_boards?.[0]]);

  const onUpdateWidget = (widget) => {
    const _widgets = widgets.map((aWidget) => {
      if (aWidget.id === widget.id) {
        return widget;
      } else {
        return aWidget;
      }
    });
    setWidgets(_widgets);
    setEditWidget(null);
    const updateView = {
      ...view,
      widgets: _widgets,
    };
    onUpdateView(view, updateView);
  };

  const onEditWidget = (widget) => {
    setEditWidget(widget);
  };

  const onHideEditWidget = () => {
    setEditWidget(null);
  };

  const onAddNew = () => {
    const newWidget = {
      id: new Date().getTime(),
      chart: {
        type: 'pie',
        view: ViewMode.Week,
      },
      data: {
        dateField: 'date',
        dimensions: [
          {
            column: 'status',
            type: 'status',
            name: 'Trạng thái',
          },
        ],
        metrics: [
          {
            aggregation: 'count',
            column: 'title',
            name: '',
          },
        ],
        group: {},
        limit: -1,
        sort: [],
      },
    };

    const _widgets = [...widgets, newWidget];
    setWidgets(_widgets);
    const updateView = {
      ...view,
      widgets: _widgets,
    };
    onUpdateView(view, updateView);
  };

  const onDeleteWidget = (widget) => {
    const _widgets = widgets.flatMap((aWidget) => {
      if (aWidget.id === widget.id) {
        return [];
      } else {
        return aWidget;
      }
    });
    setWidgets(_widgets);
    setEditWidget(null);
    const updateView = {
      ...view,
      widgets: _widgets,
    };
    onUpdateView(view, updateView);
  };

  const showAddButton = () => {
    return editable && mode === 'edit';
  };

  useEffect(() => {
    if (initMode) {
      setMode(initMode);
    }
  }, [initMode]);

  const onDragDropEnds = (oldIndex, newIndex) => {
    let newWidgets = [...widgets];
    [newWidgets[oldIndex], newWidgets[newIndex]] = [newWidgets[newIndex], newWidgets[oldIndex]];
    const updateView = {
      ...view,
      widgets: newWidgets,
    };
    onUpdateView(view, updateView);
  };

  return (
    <>
      {options?.showToolbar && renderToolbar && (
        <>
          {renderToolbar({
            sheet: sheet,
            view: view,
            onFilterApply: applyFilter,
            onFilterReset: resetFilters,
            activeFilters: activeFilters,
            filterActive: filterActive,
            saveFilter: onSaveFilter,
            onAddNew: onAddNew,
            onSearch: applySearch,
            options: {...options?.userActions, add: false},
            changeMode: setMode,
            mode: mode,
            allowFilterByGroup: true,
            showAddButton: showAddButton(),
            // userSheetPermissions
          })}
        </>
      )}
      <Card className='bg-transparent shadow-none'>
        <CardBody className='p-0'>
          {options?.userActions?.add && mode === 'edit' && (
            <div className={`w-150px ms-auto me-3 mb-3`}>
              <div
                onClick={onAddNew}
                className='rounded bg-light p-3 text-center fw-bolder bg-primary cursor-pointer'
                style={{background: backgroundColor, color: textColor}}
              >
                {t('sheet_view_dashboard_add')}
              </div>
            </div>
          )}
          <ReactSortable
            list={widgets.map((widget) => ({...widget, chosen: true}))}
            setList={(newList) => setWidgets(newList)}
            className={`grid-container`}
            disabled={mode !== 'edit'}
            onEnd={({oldIndex, newIndex}) => onDragDropEnds(oldIndex, newIndex)}
          >
            {widgets.map((widget) => {
              return (
                <div key={widget.id} className={`grid-col-${widget?.chart.width || 6} pb-2`}>
                  <WidgetView
                    widget={widget}
                    sheet={sheet}
                    subSheet={subSheet}
                    items={viewItems || []}
                    itemsByGroup={viewItemsByGroups || []}
                    subItems={viewSubItems}
                    onEdit={onEditWidget}
                    mode={mode}
                    onUpdate={onUpdateWidget}
                    onUpdateItem={onUpdateItem}
                  ></WidgetView>
                </div>
              );
            })}
          </ReactSortable>
        </CardBody>
      </Card>
      <Drawer
        BackdropProps={{invisible: true}}
        anchor='right'
        open={editWidget !== null}
        onClose={() => onHideEditWidget()}
      >
        {editWidget && (
          <WidgetEditView
            sheet={sheet}
            subSheet={subSheet}
            previewItems={viewItems || []}
            subItems={viewSubItems}
            widget={editWidget}
            onUpdate={onUpdateWidget}
            onDelete={onDeleteWidget}
            onUpdateItem={onUpdateItem}
          ></WidgetEditView>
        )}
      </Drawer>
    </>
  );
}
