import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {FieldFeedbackLabel} from '..';
import './RichText.scss';
import MagicUrl from 'quill-magic-url';
import Quill from 'quill';

Quill.register('modules/magicUrl', MagicUrl);

const getFieldCSSClasses = (touched, errors) => {
  const classes = ['form-control'];
  if (touched && errors) {
    classes.push('is-invalid');
  }

  if (touched && !errors) {
    classes.push('is-valid');
  }

  return classes.join(' ');
};

export function RichTextField({
  field, // { name, value, onChange, onBlur }
  form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = false,
  customFeedbackLabel,
  type = 'text',
  onChange,
  innerRef,
  theme = 'snow',
  modules,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <ReactQuill
        theme={theme}
        ref={innerRef}
        {...field}
        {...props}
        modules={{
          ...modules,
          magicUrl: true,
        }}
        onChange={onChange ? onChange : field.onChange(field.name)}
        onBlur={(event) => {
          props.onBlur && props.onBlur(event);
        }}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
