import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import {effectiveValue} from 'app/modules/work/helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import debounce from 'debounce-promise';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {
  getItemWithIdsAction,
  getSheetAction,
  getSheetItemsAction,
  searchItemsAction,
} from '../../../stores/sheetActions';
import './linkedField.scss';
import ItemAddNewStandaloneView from '../../view/table/ItemAddNewStandaloneView';
import {useLinkedItemManagement} from './useLinkedItemManagement';
import ItemDetailStandaloneView from '../../item-detail/ItemDetailStandaloneView';
import ItemDetailLinkedField from './ItemDetailLinkedField';
import {isObject} from 'lodash';

const LinkedField = ({value, item, column, onChange, fieldEditable, mode, displayMode}) => {
  const hiddenColumnsType = [];
  const hiddenColumnsField = ['title'];

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const effectiveValueData = effectiveValue(value) || [];
  const isDetailViewMode = mode === 'detail';

  const [expandedOnce, setExpandedOnce] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sheet, setSheet] = useState(null);
  const [linkedIds, setLinkedIds] = useState([]);
  const itemRefs = useRef([]); // Ref để lưu tất cả các phần tử
  const [itemsDetail, setItemsDetail] = useState([]);
  const [linkedSheetId, setLinkedSheetId] = useState(column?.settings?.linked?.value);
  const [showAddModal, setShowAddModal] = useState(false);
  const isMultipleLinked = column?.settings?.is_multiple;
  const isDisableAddLinked = column?.settings?.disable_add_linked;

  const getItemDisplay = () => {
    if (itemsDetail?.length > 0) {
      return itemsDetail;
    }
    return effectiveValueData;
  };

  const {linkedSheetData, linkedItemData, isDetailModalVisible, hideDetailModal, showDetailModal} =
    useLinkedItemManagement(linkedSheetId);

  useEffect(() => {
    setLinkedSheetId(column?.settings?.linked?.value);
  }, [column?.settings?.linked?.value]);

  const getItemLinked = () => {
    let params = {
      id: linkedSheetId,
    };
    if (column?.settings?.has_linked_view_selectable && column?.settings?.linked_view_selectable) {
      let view = sheet?.views?.find(
        (view) => view.code === column?.settings?.linked_view_selectable?.value
      );
      if (view?.rule_filters) {
        params.filter = view?.rule_filters;
      }
    }
    dispatch(getSheetItemsAction(params)).then((res) => {
      setSearchResults(res.data.data);
    });
  };

  const getLinkedSheetData = () => {
    const params = {
      id: linkedSheetId,
    };

    dispatch(getSheetAction(params)).then((res) => {
      const columns = res.data.data?.columns;
      if (
        column?.settings?.linked_display_fields &&
        !isEmpty(column?.settings?.linked_display_fields)
      ) {
        setColumns(column?.settings?.linked_display_fields);
      } else {
        setColumns(columns);
      }
      setSheet(res.data.data);
    });
  };

  useEffect(() => {
    if (sheet) {
      getItemLinked();
    }
  }, [sheet]);

  useEffect(() => {
    if (linkedSheetId && expandedOnce) {
      getLinkedSheetData();
    }
  }, [linkedSheetId, expandedOnce]);

  useEffect(() => {
    const linkedIds = effectiveValueData?.map((linked) => linked.reference_item_id);
    setLinkedIds(linkedIds);
  }, [value]);

  useEffect(() => {
    if (isDetailViewMode && linkedSheetId) {
      getLinkedSheetData();
    }
  }, [isDetailViewMode, linkedSheetId]);

  const getItemWithIds = () => {
    if (linkedSheetId && isDetailViewMode) {
      const params = {
        sheetId: linkedSheetId,
        itemIds: linkedIds,
      };
      dispatch(getItemWithIdsAction(params)).then((res) => {
        setItemsDetail(res.data.data);
      });
    }
  };

  useEffect(() => {
    getItemWithIds();
  }, [linkedSheetId, isDetailViewMode, linkedIds]);

  const search = (text) => {
    let params = {
      searchText: text,
      sheetIds: [linkedSheetId],
    };
    if (column?.settings?.has_linked_view_selectable && column?.settings?.linked_view_selectable) {
      let view = sheet?.views?.find(
        (view) => view.code === column?.settings?.linked_view_selectable?.value
      );
      if (view?.rule_filters) {
        params.filter = view?.rule_filters;
      }
    }
    return dispatch(searchItemsAction(params));
  };

  const searchValue = (e) => {
    const searchText = e.target.value;
    if (searchText && linkedSheetId) {
      search(searchText).then((result) => {
        if (result) {
          setSearchResults(result.data);
        }
      });
    } else if (linkedSheetId) {
      getItemLinked();
    }
  };

  const removeLinked = (itemLinked) => {
    const newLinked = [];
    const newLinkedIds = [];
    const idDeleted = itemLinked._id || itemLinked.reference_item_id;
    if (effectiveValueData) {
      effectiveValueData.forEach((linked) => {
        if (linked.reference_item_id !== idDeleted) {
          newLinked.push(linked);
          newLinkedIds.push(linked.reference_item_id);
        }
      });
    }
    setLinkedIds(newLinkedIds);
    onChange && onChange(item, column.field, value, newLinked);
  };

  const selectValue = (itemLinked) => {
    const newLinked = [];
    const newLinkedIds = [];
    if (effectiveValueData && isArray(effectiveValueData)) {
      effectiveValueData.forEach((linked) => {
        if (linked.reference_item_id !== itemLinked._id) {
          newLinked.push(linked);
          newLinkedIds.push(linked.reference_item_id);
        }
      });
    }
    const title = effectiveValue(itemLinked?.title) || itemLinked?._id;
    newLinked.push({reference_item_id: itemLinked._id, reference_display_value: title});
    setLinkedIds(newLinkedIds);
    onChange && onChange(item, column.field, value, newLinked);
  };

  const handleAddNewItemLinkedSuccess = (newItem) => {
    getItemLinked();
    selectValue(newItem);
  };

  const handleUpdateItemLinked = (itemUpdate, field) => {
    getItemWithIds();
  };

  const isAddItemLinked = useMemo(() => {
    if (isDisableAddLinked) return false;
    if (!fieldEditable) return false;
    if (!isMultipleLinked && effectiveValueData?.length === 0) return true;
    if (isMultipleLinked) return true;
    return false;
  }, [fieldEditable, isMultipleLinked, effectiveValueData?.length, isDisableAddLinked]);

  const renderPopover = () => {
    return (
      <div>
        <div className='m-4 w-500px'>
          <div className='mb-2 d-flex'>
            <input
              type='text'
              placeholder={t('sheet_view_search_users')}
              className='form-control form-control-xs flex-grow-1 me-2'
              onChange={debounce(searchValue, 500)}
            ></input>
            {/* Add new button for creating a new item */}
            {column?.settings?.allow_create && (
              <button
                className='btn btn-outline btn-outline d-flex align-items-center justify-content-center cursor-pointer pe-1 ps-3 py-2'
                onClick={() => setShowAddModal(true)}
              >
                <i className='las la-plus fs-4 me-1'></i>
              </button>
            )}
          </div>
          <div className='overflow-auto mh-200px'>
            {searchResults?.map((item) => {
              if (!linkedIds?.includes(item._id)) {
                return (
                  <Dropdown.Item
                    key={item._id}
                    size='xs'
                    className='dropdown-item-xs item-column-limit-text-1'
                    onClick={() => {
                      selectValue(item);
                    }}
                    title={effectiveValue(item?.title) || item?._id}
                  >
                    <ItemDetailLinkedField
                      item={item}
                      columns={columns}
                      sheet={sheet}
                      hiddenColumnsType={hiddenColumnsType}
                      hiddenColumnsField={hiddenColumnsField}
                    />
                  </Dropdown.Item>
                );
              } else {
                return <React.Fragment key={item._id} />;
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderDisconnectedContent = () => {
    return (
      <div className='mx-2'>
        {getItemDisplay()?.map((item, index) => (
          <span
            key={item?.reference_item_id}
            ref={(el) => (itemRefs.current[index] = el)} // Lưu từng phần tử vào itemRefs
            className='badge badge-light-dark me-1 linked-item-field'
          >
            <span className='limit-text-1 h-100 lh-sm'>
              {item.reference_display_value?.toString() || item.reference_item_id}
            </span>
          </span>
        ))}
      </div>
    );
  };

  if (column?.settings?.linked_disconnected) {
    return renderDisconnectedContent();
  }

  if (isDetailViewMode) {
    return (
      <div className='w-100 d-flex flex-column gap-4 list-item-linked-detail'>
        {itemsDetail?.map((item) => {
          return (
            <div key={item._id} className='position-relative item-linked-detail'>
              <ItemDetailLinkedField
                item={item}
                columns={columns}
                sheet={sheet}
                hiddenColumnsType={hiddenColumnsType}
                hiddenColumnsField={hiddenColumnsField}
                onEditLinkedItem={displayMode === 'drawer' ? showDetailModal : undefined}
              />
              {fieldEditable && (
                <div className='item-linked-detail-remove'>
                  <i className='las la-times fs-8' onClick={() => removeLinked(item)}></i>
                </div>
              )}
            </div>
          );
        })}
        {isAddItemLinked && (
          <DropdownPopover onToggle={() => setExpandedOnce(true)} renderPopover={renderPopover}>
            <div className='btn btn-md btn-outline btn-outline w-120px d-flex align-items-center justify-content-center py-1'>
              <i className='las la-plus fs-4 me-1'></i> <span>{t('sheet_item_detail_add')}</span>
            </div>
          </DropdownPopover>
        )}
        {showAddModal && (
          <ItemAddNewStandaloneView
            onHide={() => setShowAddModal(false)}
            sheet={sheet}
            onSaveSuccess={handleAddNewItemLinkedSuccess}
          />
        )}
        <ItemDetailStandaloneView
          sheet={linkedSheetData}
          item={linkedItemData}
          open={isDetailModalVisible}
          onHide={hideDetailModal}
          onUpdateItem={handleUpdateItemLinked}
        />
      </div>
    );
  }

  return (
    <div className='position-relative linked-field-container overflow-hidden w-100 px-1 h-25px'>
      {getItemDisplay()?.map((item, index) => {
        const id = item?._id || item?.reference_item_id;
        let label =
          effectiveValue(item.title) ||
          item.reference_display_value?.toString() ||
          item.reference_item_id;
        if (isObject(label)) {
          label = item.reference_item_id;
        }
        return (
          <span key={id} className='badge badge-light-dark me-1 linked-item-field'>
            <span
              className='limit-text-1 h-100 lh-sm'
              onClick={() => {
                // Only enable on display mode is drawer
                // Inside detail modal, click on label nothing will happen
                displayMode !== 'drawer' && showDetailModal(id);
              }}
            >
              {label}
            </span>
            {fieldEditable && (
              <i
                className='las la-times cursor-pointer ms-1 mt-1 icon-remove-linked'
                onClick={() => removeLinked(item)}
              ></i>
            )}
          </span>
        );
      })}
      {isAddItemLinked && (
        <DropdownPopover
          onToggle={() => {
            setExpandedOnce(true);
          }}
          renderPopover={renderPopover}
          popoverClassName='popover-add-linked'
        >
          <span className='button-add-linked badge badge-light-dark'>
            <i className='las la-plus'></i>
          </span>
        </DropdownPopover>
      )}
      {showAddModal && (
        <ItemAddNewStandaloneView
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          sheet={sheet}
          onSaveSuccess={handleAddNewItemLinkedSuccess}
        />
      )}
      <ItemDetailStandaloneView
        sheet={linkedSheetData}
        item={linkedItemData}
        open={isDetailModalVisible}
        onHide={hideDetailModal}
        onUpdateItem={handleUpdateItemLinked}
      />
    </div>
  );
};

export default LinkedField;
