import axios from "axios";
import { getApiV2, postApiV2 } from "../api";

export const LOGIN_URL = window.API_BASE_URL + window.API_V1_PATH  + "/user/login";
export const REGISTER_URL = window.API_BASE_URL + "/api/auth/register";
export const REQUEST_PASSWORD_URL = window.API_BASE_URL + "/api/auth/forgot-password";

export const ME_URL = window.API_BASE_URL + "/api/me";

export function login(params) {
  var bodyFormData = new FormData();
  bodyFormData.append("lname", params.email);
  bodyFormData.append("pass", params.password);
  if (params.domain) bodyFormData.append("_sand_domain", params.domain);
  return axios({
    method: "post",
    url: LOGIN_URL,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function loginV2(params) {
  let body = {
    username: params.email,
    password: params.password,
    domain: params.domain
  }
  return postApiV2('/auth/login', body);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return getApiV2('/auth/me');
}
