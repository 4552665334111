import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import TemplatePreview from 'app/modules/work/sheet/apps/item-template/TemplatePreview';
import {getTemplateBySheetAction} from 'app/modules/work/sheet/stores/template/templateActions';
import clsx from 'clsx';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';

function ModalAddNewItemFromTemplate({
  show,
  onHide,
  title,
  sheetId,
  handleAddItemFromTemplate,
}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const [templates, setTemplates] = useState([]);
  const [selectedItemTemplate, setSelectedItemTemplate] = useState(null);

  useEffect(() => {
    if (sheetId) {
      dispatch(getTemplateBySheetAction(sheetId)).then((res) => {
        if (res?.data?.data) {
          setTemplates(res.data.data);
        }
      });
    }
  }, [sheetId]);

  const handleToggleTemplate = (template) => {
    if (selectedItemTemplate) {
      setSelectedItemTemplate(null);
    } else {
      setSelectedItemTemplate(template);
    }
  };

  const addNewItemFromTemplate = () => {
    handleAddItemFromTemplate(selectedItemTemplate.template.items, ({success, error}) => {
      if (error === 'no_group') {
        snackNotification.showError(t('sheet_group_add_item_error'));
        return;
      }
      if (success) {
        snackNotification.showSuccess(t('common_save_new_success'));
        onHide();
      } else {
        snackNotification.showError(t('common_save_new_error'));
        onHide();
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size='lg' centered>
      <Modal.Body>
        <div className='fs-5 fw-bold'>{title || ''}</div>
        <div className='row mt-4'>
          {selectedItemTemplate ? (
            <div className={clsx('rounded bg-light')}>
              <div
                className='d-flex align-items-center border rounded p-2 cursor-pointer bg-body mt-2'
                onClick={() => handleToggleTemplate(selectedItemTemplate)}
              >
                <span className='w-100'>{selectedItemTemplate.name}</span>
              </div>
              <div className=' overflow-scroll mt-2 bg-body rounded mb-2'>
                <TemplatePreview
                  items={selectedItemTemplate.template.items}
                  columns={selectedItemTemplate?.meta?.columns}
                  subItemsColumns={
                    selectedItemTemplate?.meta?.subItemColumns?.length > 0
                      ? selectedItemTemplate?.meta?.subItemColumns
                      : selectedItemTemplate?.meta?.columns
                  }
                />
              </div>
            </div>
          ) : (
            <>
              {templates.map((template, index) => {
                return (
                  <div
                    className={clsx('rounded', {'bg-light': selectedItemTemplate === template._id})} key={index}
                  >
                    <div className='d-flex align-items-center border rounded p-2 cursor-pointer bg-body mt-2 border-secondary'>
                      <span className='w-100'>{template.name}</span>
                      <span className='ms-3' onClick={() => handleToggleTemplate(template)}>
                        <i className='las la-plus text-dark fs-4'></i>
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        {selectedItemTemplate && (
          <Modal.Footer className='p-0 mt-4 border-0'>
            <button
              type='button'
              className='btn btn-light btn-elevate btn-secondary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
              onClick={() => setSelectedItemTemplate(null)}
            >
              {t('common_cancel')}
            </button>
            <button
              type='button'
              className='btn btn-primary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-110px'
              onClick={addNewItemFromTemplate}
            >
              {t('common_use')}
            </button>
          </Modal.Footer>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddNewItemFromTemplate;
