import * as requestFromServer from './api';
import {callTypes, slice} from './slice';

const {actions} = slice;

export const fetchStoreAppsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getStoreApps(params)
    .then((res) => {
      const {data} = res;
      dispatch(actions.storeAppsFetched({data}));
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get apps";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};


export const installStoreAppAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .installStoreApp(params)
    .then((res) => {
      const {data} = res;
      return res.data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
  }