import {useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import {useLocation, useNavigate} from 'react-router-dom';
import {validationErrorCode} from 'app/modules/work/sheet/validation/constants';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from 'app/modules/work/sheet/validation/useValidation';

const useSheetTableHook = ({
  sheetMeta,
  onExportExcel,
  options,
  sheet,
  view,
  sheetItemList,
  onUpdateItem,
  onUpdateItems,
  onDoAction,
  addValueToItems,
  removeValueInItems,
  addValueToItem,
  removeValueInItem,
  onDeleteItem,
  onMoveItems,
  onRemoveItems,
  onRemoveSubItems,
  subSelectItems,
  setSubSelectItems,
  tools,
  selectedItems,
  setSelectedItems,
  selectedItemsData,
  setSelectedItemsData,
}) => {
  const navigate = useNavigate();
  const snackNotification = useSnackNotification();
  const {t} = useTranslate();
  const {getValidationErrorMessage} = useValidation({});
  const {pathname: currentPath} = useLocation();
  const [newItems, setNewItems] = useState([]);
  const [editColumn, setEditColumn] = useState(null);
  const [editFormulaColumn, setEditFormulaColumn] = useState(null);
  const [userEditColumn, setUserEditColumn] = useState(null);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [isShowConfigUsersCanViewColumnDialog, setIsShowConfigUsersCanViewColumnDialog] =
    useState(false);

  const [editItem, setEditItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [columnToDelete, setColumnToDelete] = useState(null);

  const [showAddColumn, setShowAddColumn] = useState(false);

  const onEditColumn = (column) => {
    setEditColumn(column.column);
  };
  const onEditFormula = (column) => {
    setEditFormulaColumn(column);
  };

  const onSaveFormula = (column, formula) => {
    onSaveColumn({...column, formula: formula});
    setEditFormulaColumn(null);
  };

  const onSaveColumn = (column, callback) => {
    sheetMeta.updateColumn(column, () => {
      if (column?.type === 'lookup') {
        sheetMeta?.fetchSheet?.();
      }

      callback?.();
    });

    setEditColumn(null);
  };

  const onToggleColumnPermitted = (column) => {
    const _column = {
      ...column.column,
    };
    if (!column?.column?.is_permitted) {
      _column.is_permitted = true;
      setUserEditColumn(_column);
    } else {
      delete _column?.is_permitted;
    }
    onSaveColumn(_column);
  };
  const onOpenPermittedEditColumn = (column) => {
    setUserEditColumn(column.column);
  };

  const onOpenUsersCanViewColumnDialog = (column) => {
    setCurrentColumn(column?.column);
    setIsShowConfigUsersCanViewColumnDialog(true);
  };

  const onDeleteColumnClick = (column) => {
    setColumnToDelete(column.column);
  };

  const onDeleteColumnConfirm = () => {
    sheetMeta.deleteColumn(columnToDelete);
    setColumnToDelete(null);
  };

  const onExportExcelConfirm = () => {
    snackNotification.showSuccess(t('sheet_export_file_excel'));
    onExportExcel();
  };

  const onShowItemDetail = (item) => {
    if (editItem?._id === item._id) {
      onHideItemDetail(item);
    } else {
      if (options?.navigateToItem) {
        navigate(`${currentPath.split('/items')[0]}/items/${item._id}`, {replace: true});
      }
      setEditItem(item);
    }
  };

  const onHideItemDetail = () => {
    if (options?.navigateToItem) {
      navigate(`${currentPath.split('/items')[0]}`, {replace: true});
    }
    setEditItem(null);
  };

  const onSelectAllClick = (e) => {
    const checked = e.target.checked;
    let selectedItemsClone = cloneDeep(selectedItems);
    let selectedItemsDataClone = cloneDeep(selectedItemsData);
    if (checked) {
      const listIdCheck = sheetItemList?.map((item) => item._id) || [];
      if (listIdCheck.length > 0) {
        selectedItemsClone = listIdCheck;
        selectedItemsDataClone = sheetItemList;
      }
    } else {
      selectedItemsClone = [];
      selectedItemsDataClone = [];
    }
    setSelectedItems(selectedItemsClone);
    setSelectedItemsData(selectedItemsDataClone);
  };

  const onSelectItem = (e, item) => {
    let selectedItemsClone = cloneDeep(selectedItems);
    let selectedItemsDataClone = cloneDeep(selectedItemsData);

    const checked = e.target.checked;
    if (checked) {
      selectedItemsClone = selectedItemsClone ? selectedItemsClone : [];
      selectedItemsDataClone = selectedItemsDataClone ? selectedItemsDataClone : [];
      selectedItemsClone.push(item._id);
      selectedItemsDataClone.push(item);
    } else {
      const newListSelectedItems = [];
      const newListItemData = cloneDeep(selectedItemsDataClone)?.filter((_item) => {
        if (_item._id !== item._id) {
          newListSelectedItems.push(_item._id);
          return true;
        } else {
          return false;
        }
      });
      if (newListSelectedItems?.length > 0) {
        selectedItemsClone = newListSelectedItems;
        selectedItemsDataClone = newListItemData;
      } else {
        selectedItemsClone = [];
        selectedItemsDataClone = [];
      }
    }
    setSelectedItems(selectedItemsClone);
    setSelectedItemsData(selectedItemsDataClone);
  };

  const onUpdateValidation = (column, rules) => {
    sheetMeta.updateColumn({...column, rules: rules});
  };

  const showMessageResult = (res) => {
    if (res?.data?.success) {
      snackNotification.showSuccess(t('common_update_success'));
    } else {
      if (res.data.code === validationErrorCode) {
        snackNotification.showError(
          res?.data?.message
            ? getValidationErrorMessage(res?.data?.message)
            : t('common_update_error')
        );
      } else {
        snackNotification.showError(
          res?.data?.message ? t(res?.data?.message) : t('common_update_error')
        );
      }
    }
  };

  const onSaveItem = (item, field, oldValue, newValue, callback) => {
    // Depending on the behavior, this can either be an array or an object to update sub-items, group items, or list items
    const isArraySelectedItems = Array.isArray(selectedItems);
    if (!isEqual(newValue, oldValue)) {
      if (isArraySelectedItems) {
        if (!selectedItems?.length || !selectedItems?.includes?.(item?._id)) {
          onUpdateItem &&
            onUpdateItem(item, field, oldValue, newValue, callback ?? showMessageResult);
        } else {
          onUpdateItems &&
            onUpdateItems(selectedItems, field, oldValue, newValue, showMessageResult);
        }
      } else {
        // if 'selectedItems' is an object
        const hasIncludeItemUpdate = keys(selectedItems).some((key) => {
          return selectedItems[key]?.includes?.(item?._id);
        });

        if (isEmpty(selectedItems) || !hasIncludeItemUpdate) {
          onUpdateItem &&
            onUpdateItem(item, field, oldValue, newValue, callback ?? showMessageResult);
        } else {
          onUpdateItems &&
            onUpdateItems(selectedItems, field, oldValue, newValue, showMessageResult);
        }
      }
    }
  };

  const onSaveItemAction = (item, params, callback) => {
    if (!selectedItems?.includes(item?._id)) {
      onDoAction(params, callback);
    } else {
      let listItemIdUpdate = selectedItems;
      listItemIdUpdate.forEach((id) => {
        const paramsAction = {
          ...params,
          itemId: id,
        };
        onDoAction(paramsAction, callback);
      });
    }
  };

  const getUpdateItemIds = (item) => {
    let selectedIds = Object.values(selectedItems).flat();

    // if current item not in selectedIds => update one.
    if (!selectedItems?.includes(item?._id)) {
      selectedIds = [item?._id];
    }
    return selectedIds;
  };

  const onAddValueToItems = (item, field, value, callback) => {
    const itemIds = getUpdateItemIds(item);
    if (itemIds && itemIds.length === 1) {
      addValueToItem && addValueToItem(item, field, value, callback ?? showMessageResult);
    } else {
      addValueToItems && addValueToItems(itemIds, field, value, callback ?? showMessageResult);
    }
  };

  const onRemoveValueInItems = (item, field, value, callback) => {
    const itemIds = getUpdateItemIds(item);
    if (itemIds && itemIds.length === 1) {
      removeValueInItem && removeValueInItem(item, field, value, callback ?? showMessageResult);
    } else {
      removeValueInItems &&
        removeValueInItems(itemIds, field, value, callback ?? showMessageResult);
    }
  };

  const onDeleteItemInit = (item) => {
    setItemToDelete(item);
  };

  const onDeleteItemConfirm = () => {
    onDeleteItem(itemToDelete, () => {
      setItemToDelete(null);
      setEditItem(null);
    });
  };

  const onAddColumnClick = () => {
    setShowAddColumn(true);
  };

  const onAddColumnSubmit = (newColumn, callback) => {
    newColumn.hidden = false;
    // 1. add column to sheet.columns
    sheetMeta.addColumn(newColumn, (res) => {

      if (newColumn?.type === 'lookup') {
        sheetMeta?.fetchSheet?.();
      }
      callback?.();
    });

    // 2. add column to sheet.view[x].columns
    const newView = {...view, columns: [...view.columns, newColumn]};
    sheetMeta.updateView(view, newView);

    setShowAddColumn(false);
  };

  const handleMoveItem = (group, sheet, columnsMapping) => {
    onMoveItems &&
      onMoveItems(group, sheet, selectedItems, columnsMapping, () => {
        setSelectedItems([]);
        setSelectedItemsData([]);
        snackNotification.showSuccess(t('common_move_success'));
      });
  };

  const handleRemoveItems = () => {
    onRemoveItems &&
      onRemoveItems(selectedItems, (res) => {
        setSelectedItems([]);
        setSelectedItemsData([]);
        if (res.data.success) {
          snackNotification.showSuccess(t('common_remove_success'));
        } else {
          snackNotification.showError(t('common_remove_fail'));
        }
      });
  };

  const handleRemoveSubItems = () => {
    onRemoveSubItems &&
      onRemoveSubItems(subSelectItems, (res) => {
        setSubSelectItems({});
        if (res.data.success) {
          snackNotification.showSuccess(t('common_remove_success'));
        } else {
          snackNotification.showError(t('common_remove_fail'));
        }
      });
  };

  const onHideToolbar = () => {
    setSelectedItems([]);
    setSelectedItemsData([]);
    setSubSelectItems({});
  };

  const handleAction = (actionName, params) => {
    tools.doAction(actionName, params);
  };

  const highlightKeys = () => {
    if (editItem) {
      return [editItem._id];
    }
    if (newItems) {
      return newItems;
    }
    return [];
  };

  useEffect(() => {
    let timeOut;
    if (newItems?.length) {
      timeOut = setTimeout(() => {
        setNewItems([]);
      }, 5000);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [newItems]);

  const indicatorStyle = (group) => {
    return {
      backgroundColor: group?.settings?.color,
    };
  };

  return {
    newItems,
    setNewItems,
    editColumn,
    setEditColumn,
    onEditColumn,
    onEditFormula,
    editFormulaColumn,
    setEditFormulaColumn,
    onSaveFormula,
    onSaveColumn,
    userEditColumn,
    setUserEditColumn,
    onOpenPermittedEditColumn,
    onToggleColumnPermitted,
    onOpenUsersCanViewColumnDialog,
    currentColumn,
    setCurrentColumn,
    isShowConfigUsersCanViewColumnDialog,
    setIsShowConfigUsersCanViewColumnDialog,
    onDeleteColumnClick,
    onDeleteColumnConfirm,
    onExportExcelConfirm,
    onShowItemDetail,
    onSelectAllClick,
    onSelectItem,
    editItem,
    setEditItem,
    itemToDelete,
    setItemToDelete,
    columnToDelete,
    setColumnToDelete,
    showAddColumn,
    setShowAddColumn,
    onHideItemDetail,
    onUpdateValidation,
    showMessageResult,
    onSaveItem,
    onAddValueToItems,
    onRemoveValueInItems,
    onDeleteItemInit,
    onDeleteItemConfirm,
    onAddColumnClick,
    onAddColumnSubmit,
    snackNotification,
    handleMoveItem,
    handleRemoveItems,
    handleRemoveSubItems,
    onHideToolbar,
    handleAction,
    highlightKeys,
    indicatorStyle,
    onSaveItemAction,
  };
};

export default useSheetTableHook;
