import './styles.scss';
import React, {useEffect, useState} from 'react';

const Spinner = ({isShowLoading, children, size = 'sm', maxLoadingTime = 30000}) => {
  const [isLoading, setIsLoading] = useState(isShowLoading);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, maxLoadingTime);
  }, []);

  if (!isLoading) {
    return children;
  }

  return (
    <div className='text-center field-spinner'>
      <div className={`spinner-border spinner--${size}`} role='status'>
        <span class='sr-only'>Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
