export const NumberChartFunction = ({value, onChange}) => {
  const functionValue = value?.data?.metrics[0];
  const functionOption = [
    {text: 'sum', value: 'sum'},
    {text: 'average', value: 'avg'},
    {text: 'median', value: 'median'},
    {text: 'min', value: 'min'},
    {text: 'max', value: 'max'},
    {text: 'count', value: 'count'},
    {text: 'count item', value: 'count_item'},
  ];
  return (
    <div className='d-flex gap-2 w-100'>
      {functionOption.map((item, index) => (
        <button
          key={index}
          className={`btn btn-sm ${
            item.value === functionValue?.aggregation ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          onClick={() => {
            onChange('aggregation', item.value);
          }}
        >
          {item.text}
        </button>
      ))}
    </div>
  );
};
