import clsx from "clsx";
import React from 'react';


const CustomToggle = React.forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      className={clsx('cursor-pointer', props?.className && props?.className)}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});

export default CustomToggle;