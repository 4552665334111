import {format, fromUnixTime, isValid} from 'date-fns';

export function formatDate(value, dateFormatPattern) {
  return isValid(value) ? format(fromUnixTime(value), dateFormatPattern) : '';
}

export function getFormattedTimeline(item, dateFormatPattern) {
  if (!item?.startDate && !item?.endDate) {
    return '';
  }

  const startDate = item?.startDate ? formatDate(item.startDate, dateFormatPattern) : '';
  const endDate = item?.endDate ? formatDate(item.endDate, dateFormatPattern) : '';
  return `${startDate} - ${endDate}`;
}
