import React, {useState} from 'react';
import {TextareaAutosize} from '@material-ui/core';

const KanbanItemNew = ({addNewItem, onCancel}) => {
  const [title, setTitle] = useState('');
  const handleBlur = () => {
    if (title.trim() === '') {
      onCancel && onCancel();
    } else {
      addNewItem && addNewItem(title.trim());
    }
  };

  return (
    <div className='card mb-2 mb-xl-2 border-primary'>
      <div className='card-body p-4'>
        <div className='d-flex flex-stack mb-2'>
          <div className='flex-grow-1'>
            <TextareaAutosize
              type='text'
              className='form-control  form-control-xs item-text-input border '
              style={{resize: 'none'}}
              maxRows={3}
              minRows={2}
              value={title}
              autoFocus
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onBlur={(e) => handleBlur(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.target.blur();
                }
              }} //Disable new line
            ></TextareaAutosize>
          </div>
        </div>
      </div>
    </div>
  );
};
export default KanbanItemNew;