import * as requestFromServer from './api';
import {callTypes, slice} from './slice';

const {actions} = slice;

export const getFormDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getFormDetail(id)
    .then((res) => {
      dispatch(actions.formDetailFetched({data: res.data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const updateFormAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateForm(id, params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const submitFormAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.submitForm(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};