import './sheetDataViewToolbar.scss';

import {Button, ButtonGroup, Dropdown} from 'react-bootstrap';
import React, {useContext, useEffect, useRef, useState} from 'react';

import {DataViewContext} from '../DataViewContext';
import ModalAddNewItemFromTemplate from 'app/modules/work/sheet/apps/item-template/ModalAddNewItemFromTemplate';
import SheetDataViewFilter from './components/SheetDataViewFilter';
import SheetDataViewFilterColor from './components/SheetDataViewFilterColor';
import SheetDataViewGroup from './components/SheetDataViewGroup';
import SheetDataViewHideColumn from './components/SheetDataViewHideColumn';
import SheetDataViewListViewAction from './components/SheetDataViewListViewAction';
import SheetDataViewRowHeight from './components/SheetDataViewRowHeight';
import SheetDataViewShareAndSync from './components/SheetDataViewShareAndSync';
import SheetDataViewSort from './components/SheetDataViewSort';
import SheetDataViewViewAction from './components/SheetDataViewViewAction';
import {ShowForSheetPermission} from 'app/modules/work/sheet/permission/ShowForSheetPermission';
import clsx from 'clsx';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';
import ModalImportItemsFromFile from 'app/modules/work/sheet/apps/item-from-file/ModalImportItemsFromFile';
import {hasSheetPermission} from 'app/modules/work/sheet/permission/permissionHelper';
import ModalManagerTemplate from 'app/modules/work/sheet/apps/item-template/ModalManagerTemplate';
import ConfigPrivacyDialog from '../../../setting/privacy/ConfigPrivacyDialog';
import ConfigTerminologyDialog from '../../../setting/terminology/ConfigTerminologyDialog';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import SheetDataConfigTool from '../../../setting/sheet/SheetDataConfigTool';

const SheetDataViewToolbar = ({
  sheetId,
  sheet,
  view,
  onAddNewGroup,
  onSetting,
  onExportExcel,
  onAddNew,
  onFilterApply,
  onFilterReset,
  activeFilters,
  filterActive,
  saveFilter,
  onSearch,
  onCreateGroupTasks,
  addItemsAndSubItems,
  options,
  allowFilterByGroup,
  canAddGroup,
  showAddButton,
  onImport,
  changeMode,
  mode,
  userSheetPermissions,
  linkableSheets,
}) => {
  const {user} = useSession();
  const [isContentOverflow, setIsContentOverflow] = useState(false);
  const [showAddItemFromTemplate, setShowAddItemFromTemplate] = useState(false);
  const [showAddItemFromFile, setShowAddItemFromFile] = useState(false);
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [showConfigSheetModal, setShowConfigSheetModal] = useState(false);
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();
  const {
    currentPageView,
    onToggleViewList,
    updateView,
    addColumn,
    addView,
    deleteView,
    darkColor,
    updateSheet,
    updateSubSheets,
  } = useContext(DataViewContext);
  const viewsCanAdd = ['dashboard', 'connected-dashboard', 'table-paging'];
  const toolbarScrollRef = useRef();
  const checkContentOverflow = () => {
    if (toolbarScrollRef?.current) {
      return toolbarScrollRef.current?.scrollWidth > toolbarScrollRef.current?.clientWidth;
    }
    return false;
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // this will get called whenever div dimension changes
      entries.forEach((entry) => {
        setIsContentOverflow(checkContentOverflow());
      });
    });
    if (toolbarScrollRef?.current) {
      resizeObserver.observe(toolbarScrollRef?.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onScrollToolbar = (direction) => {
    const toolbar = toolbarScrollRef.current;
    if (direction === 'left') {
      toolbar.scrollLeft -= 40;
    } else {
      toolbar.scrollLeft += 40;
    }
  };

  const showMessageResult = (res) => {
    snackNotification.showSuccess(t('common_update_success'));
  };

  const onUpdatePrivacy = (privacy) => {
    updateSheet(sheet, 'privacy', sheet.privacy, privacy, (res) => {
      updateSubSheets(sheet, 'privacy', sheet.privacy, privacy);
      showMessageResult();
    });
  };

  const onUpdateTerminology = (terminology) => {
    updateSheet(sheet, 'terminology', sheet.terminology, terminology, showMessageResult);
  };
  return (
    <div className='d-flex w-100 gap-2 bg-content py-3 border-bottom h-55px mb-5 sheet-data-view-toolbar'>
      <SheetDataViewListViewAction onToggleViewList={onToggleViewList} />

      <div className='border-left d-flex justify-content-center w-8px my-2'></div>
      {isContentOverflow && (
        <div
          className='cursor-pointer p-2 d-flex align-items-center justify-content-center'
          onClick={() => onScrollToolbar('left')}
        >
          <i className='la la-chevron-circle-left' />
        </div>
      )}
      <div
        ref={toolbarScrollRef}
        className='w-100 flex-nowrap overflow-scrollbar-hidden overflow-auto whitespace-nowrap d-flex w-100 gap-2'
      >
        <SheetDataViewViewAction
          currentPageView={currentPageView}
          updateView={updateView}
          addView={addView}
          deleteView={deleteView}
          onExportExcel={onExportExcel}
          configView={onSetting}
        />
        <SheetDataViewHideColumn sheet={sheet} view={view} updateView={updateView} />
        <SheetDataViewFilter sheet={sheet} />
        <SheetDataViewGroup sheet={sheet} />
        <SheetDataViewSort sheet={sheet} />
        <SheetDataViewFilterColor />
        <SheetDataViewRowHeight />
        <SheetDataViewShareAndSync />
      </div>
      {isContentOverflow && (
        <div
          className='cursor-pointer p-2 d-flex align-items-center justify-content-center'
          onClick={() => onScrollToolbar('right')}
        >
          <i className='la la-chevron-circle-right' />
        </div>
      )}
      <div className='d-flex gap-2 align-items-center'>
        {showAddButton && (
          <Dropdown as={ButtonGroup} className='dropdown-toggle-toolbar'>
            <Button
              variant=''
              size='sm'
              onClick={onAddNew}
              style={{backgroundColor: darkColor, color: 'white'}}
            >
              {t('common_add')}
            </Button>

            <Dropdown.Toggle
              split
              variant=''
              className='opacity-75 py-2 px-4 d-flex justify-content-center align-items-center'
              size='sm'
              id='dropdown-split-basic'
              style={{backgroundColor: darkColor, color: 'white'}}
            />

            <Dropdown.Menu className='p-2'>
              {canAddGroup && (
                <Dropdown.Item onClick={onAddNewGroup} className='rounded p-2'>
                  <div className='d-flex align-items-center'>
                    <i className='las la-object-group fs-3 text-dark pe-2 ps-1'></i>
                    <span>{t('sheet_add_group')}</span>
                  </div>
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => setShowAddItemFromFile(true)} className='rounded p-2'>
                <div className='d-flex align-items-center'>
                  <i className='las la-upload fs-3 text-dark pe-2 ps-1'></i>
                  <span>{t('sheet_import')}</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setShowAddItemFromTemplate(true)}
                className='rounded p-2'
              >
                <div className='d-flex align-items-center'>
                  <i className='las la-sitemap fs-3 text-dark pe-2 ps-1'></i>
                  <span>{t('sheet_add_item_from_template')}</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <ShowForSheetPermission permission='board.edit' sheet={sheet} user={user}>
          {viewsCanAdd.includes(view?.type) && view?.type !== 'table-paging' && (
            <div className='d-flex'>
              <div className='d-flex align-items-center border rounded border-secondary overflow-hidden'>
                <div
                  className={clsx(
                    'view-mode-switch p-2 w-80px text-center cursor-pointer',
                    mode === 'view' && 'bg-light-primary text-primary'
                  )}
                  onClick={() => changeMode('view')}
                >
                  {t('sheet_dashboard_view_mode')}
                </div>
                <div
                  className={clsx(
                    'view-mode-switch p-2 w-80px text-center cursor-pointer',
                    mode === 'edit' && 'bg-light-primary text-primary'
                  )}
                  onClick={() => changeMode('edit')}
                >
                  {t('sheet_dashboard_edit_mode')}
                </div>
              </div>
            </div>
          )}
        </ShowForSheetPermission>
        {hasSheetPermission(sheet, user, userSheetPermissions?.config) && (
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
              <div className='d-flex align-items-center gap-2 cursor-pointer px-4 py-5 btn btn-light bg-white'>
                <i className='la la-gear' />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-200px'>
              <div className='p-2 d-flex flex-column gap-2'>
                <div
                  className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'
                  onClick={() => {
                    setShowTemplateDialog(true);
                  }}
                >
                  <i className='las la-sitemap'></i>
                  <span>{t('data_page_action_setting_template')}</span>
                </div>
                <ConfigPrivacyDialog
                  title={t('document_config_privacy')}
                  defaultPrivacy={sheet?.privacy}
                  onSave={onUpdatePrivacy}
                >
                  <div className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'>
                    <i className='bi bi-shield'></i>
                    <span>{t('data_page_action_setting_privacy')}</span>
                  </div>
                </ConfigPrivacyDialog>
                <ConfigTerminologyDialog
                  title={t('sheet_detail_permission_dialog_settings_terminology_title')}
                  onSave={onUpdateTerminology}
                  defaultTerminology={sheet?.terminology}
                >
                  <div className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'>
                    <i className='bi bi-braces-asterisk'></i>
                    <span>{t('data_page_action_setting_terminology')}</span>
                  </div>
                </ConfigTerminologyDialog>
                <SheetDataConfigTool
                  sheet={sheet}
                  updateSheet={updateSheet}
                  addColumn={addColumn}
                  linkableSheets={linkableSheets}
                >
                  <div className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'>
                    <i className='la la-align-justify' />
                    <span>{t('data_page_action_setting_sheet')}</span>
                  </div>
                </SheetDataConfigTool>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {showAddItemFromTemplate && (
        <ModalAddNewItemFromTemplate
          title={t('sheet_from_template')}
          show={showAddItemFromTemplate}
          onHide={() => {
            setShowAddItemFromTemplate(false);
          }}
          sheetId={sheetId}
          handleAddItemFromTemplate={addItemsAndSubItems}
        />
      )}
      <ModalImportItemsFromFile
        show={showAddItemFromFile}
        onHide={() => {
          setShowAddItemFromFile(false);
        }}
        sheet={sheet}
      />
      <ModalManagerTemplate
        show={showTemplateDialog}
        onHide={() => setShowTemplateDialog(false)}
        sheetId={sheet._id}
        title={t('sheet_view_template_manager')}
      />
      <SheetDataConfigTool
        show={showConfigSheetModal}
        onHide={() => setShowConfigSheetModal(false)}
        sheet={sheet}
        updateSheet={updateSheet}
        addColumn={addColumn}
      />
    </div>
  );
};
const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));
export default SheetDataViewToolbar;
