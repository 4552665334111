import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import {
  commonValidateOperator,
  dateUnits,
  dateValueOption,
  noValueOperator,
  validateOperator,
} from './constants';
import {useValidation} from './useValidation';
import {capitalize, cloneDeep} from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {Button} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import './validationEditor.scss';

function ValidationEditor({name, type, rules: initRules, onSave}) {
  const {t} = useTranslate();
  const [dates, setDates] = useState([]);
  const [ruleOperatorOption, setRuleOperatorOption] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState([]);
  const dateNumberRef = useRef();
  const {rules, addNewRule, removeRule, onChangeRule} = useValidation({rules: initRules});

  useEffect(() => {
    if (type) {
      let operatorOption = validateOperator[type] ?? validateOperator['all'];
      if (operatorOption) {
        setRuleOperatorOption([...operatorOption]);
      }
    }
  }, [type]);

  const getSelectValue = (options, value) => {
    if (value) {
      let option = options.find((option) => option.value === value);
      if (!option) {
        option = {
          label: value,
          value: value,
        };
      }
      return option;
    }
  };

  const onChangeDate = (index, date) => {
    let newDates = cloneDeep(dates);
    newDates[index] = date;
    setDates(newDates);
  };

  const onShowDatePicker = (index) => {
    let newShowDatePicker = cloneDeep(showDatePicker);
    if (newShowDatePicker.includes(index)) {
      newShowDatePicker = newShowDatePicker.filter((ele) => ele !== index);
    } else {
      newShowDatePicker.push(index);
    }
    setShowDatePicker(newShowDatePicker);
  };

  const getValidateValueDateNumber = (value) => {
    let valueNumber = value.split(' ')[0];
    return Math.abs(valueNumber) ?? 1;
  };

  const getValidateValueDateUnit = (value) => {
    let valueUnit = value.split(' ')[1];
    return dateUnits.find((unit) => unit.value === valueUnit);
  };

  return (
    <div className='d-flex flex-column gap-3'>
      {rules.map((rule, index) => {
        return (
          <div className='d-flex gap-2 align-items-center w-100' key={index}>
            {index > 0 ? (
              <div>{t('validation_and')}</div>
            ) : (
              <div className='text-dark'>{capitalize(name)}</div>
            )}
            <Select
              formatOptionLabel={({label}) => <span>{t(label)}</span>}
              defaultValue={rule?.ruleOperator}
              className='w-100 select-date-validation'
              classNamePrefix='bg-body'
              options={ruleOperatorOption}
              placeholder={t('validation_rule')}
              onChange={(value) => {
                onChangeRule(index, 'ruleOperator', value);
              }}
            />
            {type === 'date' ? (
              <>
                {rule?.ruleOperator?.type === 'date' ? (
                  <>
                    <Select
                      formatOptionLabel={({label}) => <span>{t(label)}</span>}
                      defaultValue={getSelectValue(dateValueOption, rule?.ruleValue)}
                      className='w-100 select-date-validation'
                      classNamePrefix='bg-body'
                      options={dateValueOption}
                      placeholder={t('validation_value')}
                      onChange={({value}) => {
                        if (value !== 'exactDate') {
                          onChangeRule(index, 'ruleValue', value);
                        } else {
                          onShowDatePicker(index);
                        }
                      }}
                    />
                    {showDatePicker.includes(index) && (
                      <div className='w-150px h-40px validation-input'>
                        <DatePicker
                          selected={dates[index]}
                          showIcon={true}
                          autoFocus
                          className='w-100px form-control validation-input'
                          icon='la la-calendar'
                          onChange={(date) => {
                            onChangeDate(index, date);
                            onChangeRule(index, 'ruleValue', moment(date).format('DD-MM-YYYY'));
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      ref={dateNumberRef}
                      type='number'
                      min={0}
                      defaultValue={getValidateValueDateNumber(rule?.ruleValue)}
                      className='form-control w-80px p-2 validation-input'
                      onChange={(e) => {
                        let value = e.target.value;
                        let unit = getValidateValueDateUnit(rule?.ruleValue).value ?? '';
                        onChangeRule(index, 'ruleValue', `${value} ${unit}`);
                      }}
                    />
                    <Select
                      formatOptionLabel={({label}) => <span>{t(label)}</span>}
                      placeholder={t('validation_unit')}
                      className='w-100 select-date-validation'
                      classNamePrefix='bg-body'
                      value={getValidateValueDateUnit(rule?.ruleValue)}
                      options={dateUnits}
                      onChange={({value}) => {
                        onChangeRule(index, 'ruleValue', `${dateNumberRef.current.value} ${value}`);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {!noValueOperator.includes(rule?.ruleValidate) && (
                  <input
                    className='form-control w-150px validation-input'
                    value={rule?.ruleValue}
                    onChange={(e) => onChangeRule(index, 'ruleValue', e.target.value)}
                  />
                )}
              </>
            )}

            {rules.length > 1 && (
              <div
                className='p-2 rounded cursor-pointer bg-hover-secondary'
                onClick={() => removeRule(index)}
              >
                <i className='la la-minus-circle' />
              </div>
            )}
          </div>
        );
      })}
      <div className='d-flex'>
        <div
          className='p-2 rounded cursor-pointer bg-hover-secondary border'
          onClick={() => addNewRule()}
        >
          <i className='la la-plus-circle me-2' />
          <span>{t('validation_new_rule')}</span>
        </div>
      </div>
      <div className='d-flex w-100 justify-content-end'>
        <Button
          variant='primary'
          onClick={() => {
            onSave(rules);
          }}
          className='w-100px'
        >
          {t('common_save')}
        </Button>
      </div>
    </div>
  );
}

export default ValidationEditor;
