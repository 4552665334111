import { deleteApiV2, getApiV2, postApiV2, putApiV2 } from "../../../../../core/store/api";


const endPoints = {
  getWorkflows: '/api/workflow',
  getSheets: '/api/sheet/my-sheets',
  getSheet: (id) => `/api/sheet/${id}`,
  getItemsPaging: (id) => `/api/sheet/${id}/item-paging`,
  getNodes: '/api/workflow/node',
  getBlocks: '/api/workflow/block',
  updateNode: (id) => `/api/workflow/node/${id}`,
  deleteNode: (id) => `/api/workflow/node/${id}`,
  deleteWorkflow: (id) => `/api/workflow/${id}`,
  getWorkspaces: '/api/workspace',
  getWorkspacePages: (id) => `/api/workspace/${id}/pages`,
  getWorkflow: (id) => `/api/workflow/${id}`,
  searchWorkflows: '/api/workflow/search',
  duplicateWorkflow:(id) => `/api/workflow/${id}/copy`
};

export function getWorkflows(params) {
  return postApiV2(endPoints.searchWorkflows, params);
}

export function getSheets(params) {
  return getApiV2(endPoints.getSheets, params);
}

export function getSheet(params) {
  return getApiV2(endPoints.getSheet(params?.sheetId), params);
}

export function getSheetItems(params) {
  return postApiV2(endPoints.getItemsPaging(params?.sheetId), params);
}

export function getNodes(params) {
  return getApiV2(endPoints.getNodes, params);
}

export function getBlocks(params) {
  return getApiV2(endPoints.getBlocks, params);
}

export function createNode(params) {
  return postApiV2(endPoints.getNodes, params);
}

export function createWorkflow(params) {
  return postApiV2(endPoints.getWorkflows, params);
}

export function updateNode(id, params) {
  return putApiV2(endPoints.updateNode(id), params);
}

export function deleteNode(id, params) {
  return deleteApiV2(endPoints.deleteNode(id), params);
}

export function deleteWorkflow(id) {
  return deleteApiV2(endPoints.deleteWorkflow(id));
}

export function getWorkspaces(params) {
  return getApiV2(endPoints.getWorkspaces, params);
}

export function getWorkspacePages(id, params) {
  return getApiV2(endPoints.getWorkspacePages(id), params);
}

export function updateWorkflow(id, params) {
  return putApiV2(endPoints.getWorkflow(id), params);
}

export function getWorkflow(id, params) {
  return getApiV2(endPoints.getWorkflow(id), params);
}

export function duplicateWorkflow(id) {
  return postApiV2(endPoints.duplicateWorkflow(id));
}
