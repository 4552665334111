import {createRoot} from 'react-dom/client';
// Axios
import axios from 'axios';
import {Chart, registerables} from 'chart.js';
// Apps
import {MetronicI18nProvider} from './core/i18n/Metronici18n';
import store, {persistor} from './core/store/store';

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
//import './core/assets/sass/plugins.scss'
//import './core/assets/sass/style.scss'
import './core/layouts/layout2/assets/sass/plugins.scss';
import './core/layouts/layout2/assets/sass/style.scss';
import './core/assets/sass/style.react.scss';
import './app/common/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './app/common/_assets/plugins/flaticon/flaticon.css';
import './app/common/_assets/plugins/flaticon2/flaticon.css';

import {AppRoutes} from './app/routing/AppRoutes';
import {AuthProvider, setupAxios} from './app/modules/auth';
import {LayoutSplashScreen} from './core/layouts/layout2/core';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import * as _store from './core/store';
import './core/monitoring/sentry';

import {OpenFeature,  OpenFeatureProvider, useFlag} from '@openfeature/react-sdk';

import { Context, InitOptions} from '@growthbook/growthbook';
import {GrowthbookClientProvider} from '@openfeature/growthbook-client-provider';
import configs from 'core/config/configs';

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
_store.setupAxios(axios, store);
Chart.register(...registerables);

const gbContext: Context = {
  apiHost: configs.growthBookHost(),
  clientKey: configs.growthBookKey(),
  attributes: {
    dmn: configs.domain()
  }
};

const initOptions: InitOptions = {
  timeout: 2000,
  streaming: true,
};

OpenFeature.setProvider(new GrowthbookClientProvider(gbContext, initOptions));

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <OpenFeatureProvider>
      <MetronicI18nProvider>
        <AuthProvider>
          <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
              <AppRoutes />
            </PersistGate>
          </Provider>
        </AuthProvider>
      </MetronicI18nProvider>
    </OpenFeatureProvider>
  );
}
