import {useTranslate} from 'core/i18n/i18nProvider';
import {useState} from 'react';
import {Modal} from 'react-bootstrap';
import SheetDataConfigModal from './SheetDataConfigModal';
import './sheetDataViewListViewConfig.scss';
import SheetAddColumn from '../../sheet-config/column/add/SheetAddColumn';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import ConfigLookupModal from '../../sheet-config/column/add/ConfigLookupModal';
import { cloneDeep } from 'lodash';

const SheetDataConfigTool = ({sheet, updateSheet, addColumn, linkableSheets, children}) => {
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();
  const [showConfigSheetModal, setShowConfigSheetModal] = useState(false);
  const [showAddNewColumnModal, setShowAddNewColumnModal] = useState(false);

  const handleUpdateSheet = (columns, callback) => {
    updateSheet(sheet, 'columns', sheet?.columns, columns, () => {
      snackNotification.showSuccess(t('common_save_success'));
      callback?.();
    });
  };

  return (
    <>
      <span onClick={() => setShowConfigSheetModal(true)}>{children}</span>
      {showConfigSheetModal && (
        <SheetDataConfigModal
          show={showConfigSheetModal}
          onHide={() => setShowConfigSheetModal(false)}
          sheet={sheet}
          onSaveSheet={handleUpdateSheet}
          onShowModalAddNew={setShowAddNewColumnModal}
        />
      )}
      {showAddNewColumnModal && (
        <AddNewColumnModal
          show={showAddNewColumnModal}
          onHide={() => setShowAddNewColumnModal(false)}
          addColumn={addColumn}
          linkableSheets={linkableSheets}
          sheetColumns={cloneDeep(sheet?.columns)}
        />
      )}
    </>
  );
};

export default SheetDataConfigTool;

const AddNewColumnModal = ({show, onHide, addColumn, linkableSheets, sheetColumns}) => {
  const {t} = useTranslate();
  const [lookupColumnConfigs, setLookupColumnConfigs] = useState({});
  const [isLookupColumnModalVisible, setIsLookupColumnModalVisible] = useState(false);

  const onAddColumnSubmit = (data, callback) => {
    if (data?.type === 'lookup') {
      setLookupColumnConfigs((prevState) => ({
        ...prevState,
        ...data,
        name: '',
        generatedColumnName: '', // used to column name placeholder
      }));
      setIsLookupColumnModalVisible(true);
      callback && callback();
      return;
    }
    const dataAddNew = {
      ...data,
      styles: {
        width: '150px',
      },
    };
    addColumn(dataAddNew);
    onHide();
  };

  const onCreateLookupColumn = (values) => {
    addColumn(values);
    setIsLookupColumnModalVisible(false);
  };

  return (
    <>
      <Modal size='md' centered show={show} onHide={onHide} className='modal-add-new-column'>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_column_add_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SheetAddColumn
            onSubmit={onAddColumnSubmit}
            linkableSheets={linkableSheets}
          ></SheetAddColumn>
        </Modal.Body>
      </Modal>
      <ConfigLookupModal
        sheetColumns={sheetColumns}
        initialConfig={lookupColumnConfigs}
        isLookupColumnModalVisible={isLookupColumnModalVisible}
        setIsLookupColumnModalVisible={setIsLookupColumnModalVisible}
        onCreateLookupColumn={onCreateLookupColumn}
      />
    </>
  );
};
