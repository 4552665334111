import { useSession } from 'core/store/hooks';
import {hasGlobalPermission} from './globalPermissionHelper';

export const ShowForGlobalPermission = ({permission, children}) => {
  const {user, getDomain} = useSession();
  const domain = getDomain();
  if (hasGlobalPermission(user, permission, domain)) {
    return children; // rendering nested elements
  } else {
    /*
     it also might be false, empty string or empty array
     (and for React 18 it can be `return;` or `return undefined` as well);
     also you even can omit explicit `return undefined;` for `else` branch
     but this way intention is more clear
    */
    return null;
  }
};
