import {memo, useEffect, useMemo, useRef, useState} from 'react';
import {NodeToolbar, Position} from 'reactflow';
import './workflowConditionNode.scss';
import clsx from 'clsx';
import {useTranslate} from 'core/i18n/i18nProvider';
import WorkflowHandle from '../handle/WorkflowHandle';
import {useWorkflow} from '../../hook/workflowHook';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';

const WorkflowConditionNode = memo(({id, data, isConnectable}) => {
  const {removeNode, saveNode} = useWorkflow();
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [deleteNode, setDeleteNode] = useState(false);
  const {t} = useTranslate();
  const dataConfig = useMemo(() => data?.config ?? {}, [data]);
  const nodeRef = useRef();
  const label = useMemo(() => data?.label && data?.label !== '' ? data?.label : t('workflows.node.node_empty_label'), [data]);

  const actionMenuRef = useRef();

  useEffect(() => {
    const outsideClick = (e) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(e.target)) {
        setShowActionMenu(false);
      }
    };
    document.addEventListener('click', outsideClick);
    return () => {
      document.removeEventListener('click', outsideClick);
    }
  }, []);

  const onDeleteNode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteNode(id);
  };

  const handleDeleteNode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removeNode(id);
    setDeleteNode(false);
  };

  const onChangeLabel = (value) => {
    saveNode(id, {data: {...data, label: value}});
  };


  return (
    <>
      <div className='workflow-conditon-node p-3 nowheel nodrag' ref={nodeRef}>
        <div className='workflow-conditon-tag'>
          <div className='workflow-conditon-tag-icon'>
            <i className='la la-code-fork text-dark fs-2' />
          </div>
        </div>
        <div
          className='workflow-conditon-node-action'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowActionMenu(!showActionMenu);
          }}
        >
          <i className='ki ki-bold-more-hor' />
        </div>
        <NodeToolbar isVisible={showActionMenu} position={Position.Right}>
          <div className='p-2 bg-white rounded border' ref={actionMenuRef}>
            <div className='workflow-condition-action-menu-item p-2'>
              <i className='la la-pencil-alt' />
              <span>{t('workflows.node.action.edit')}</span>
            </div>
            <div className='workflow-condition-action-menu-item p-2' onClick={onDeleteNode}>
              <i className='la la-trash' />
              <span>{t('workflows.node.action.delete')}</span>
            </div>
          </div>
        </NodeToolbar>
        <div
          className={clsx('workflow-conditon-node-icon', data?.empty && 'workflow-condition-empty')}
        >
          {data?.empty ? (
            <i className={`fa-solid fa-plus fs-2`} />
          ) : (
            <i className={`${data?.icon ?? 'la la-code-fork'} text-white fs-2`} />
          )}
        </div>
        <div>
          <input
            className='workflow-condition-node-input'
            value={label}
            onChange={(e) => onChangeLabel(e.target.value)}
          />
        </div>
      </div>
      <WorkflowHandle
        type='target'
        position={Position.Top}
        id={`node-target-${data?.id}`}
        style={{bottom: -2, background: '#555', visibility: 'hidden'}}
        isConnectable={2}
      />
      <WorkflowHandle
        type='source'
        position={Position.Bottom}
        id={`node-source-${data?.id}`}
        style={{bottom: -2, background: '#555'}}
        isConnectable={1}
      />
      <ModalDelete
        show={deleteNode}
        close={() => setDeleteNode(false)}
        onClick={handleDeleteNode}
        title={t('workflows.node.delete')}
        content={`${t('workflows.node.delete_confirm')} ${label}`}
      />
    </>
  );
});

export default WorkflowConditionNode;