import React from 'react';
import {Modal} from 'react-bootstrap';
import SelectUsers from 'app/modules/work/sheet/component/view/common/SelectUsers';
import {useTranslate} from 'core/i18n/i18nProvider';

const ConfigUsersCanViewColumnDialog = ({show, onHide, column, onSubmit}) => {
  const {t} = useTranslate();
  const title= t('sheet_column_user_access_column') + ' ' + column?.name
  const handleOnChange =(users) => {
    onSubmit({...column, 'view_permitted_users': users})
  }

  return (
    <Modal
      size="md"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <SelectUsers
          defaultUsers={column?.view_permitted_users || []}
          onChange={handleOnChange}
        ></SelectUsers>
      </Modal.Body>
    </Modal>
  );
};

export default ConfigUsersCanViewColumnDialog;