export const orderGap = 131072; //2^17

// use for reOrder
export const getInsertPos = (arrPos, previousIndex, nextIndex) => {
  let pos;
  const length = arrPos.length;
  if (previousIndex === 0 && previousIndex !== length - 1) {
    pos = arrPos.length ? Math.min((arrPos[0] || 0) / 2, orderGap) : orderGap * 100; //move to top
  } else if (previousIndex === length - 1) {
    pos = (arrPos[length - 1] || 0) + orderGap; //move to end
  } else {
    const prevPos = arrPos[previousIndex] ?? orderGap;
    const nextPos = arrPos[nextIndex] ?? orderGap;
    pos = (prevPos + nextPos) / 2;
    if (prevPos === nextPos) {
      pos = nextPos + 1;
    }
  }
  if (!pos) {
    return Math.random();
  }

  return pos;
};

// use for create new item
export const getInsertNextPos = (arrPos, currentIndex) => {
  let pos;
  const nextPos = arrPos[currentIndex + 1];

  if (nextPos) {
    pos = (arrPos[currentIndex] + nextPos) / 2;
  } else {
    pos = arrPos[currentIndex] + orderGap;
  }

  return pos;
};

export const getInsertLastPos = (arrPos) => {
  const lastPos = arrPos[arrPos.length - 1] || orderGap;
  return lastPos + orderGap;
};

export const sortArrByKey = (arr, key = 'id') => {
  arr.sort((a, b) => {
    if (a[key] && !b[key]) {
      return 1;
    } else if (!a[key] && b[key]) {
      return -1;
    } else if (a[key] && b[key]) {
      return a[key] - b[key];
    } else {
      return 0;
    }
  });

  if (arr?.children) {
    arr.forEach((obj) => {
      if (obj.children) {
        sortArrByKey(obj.children, key);
      }
    });
  }

  return arr;
};
