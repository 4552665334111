import React from 'react';
import './checkbox-style.scss'

function CheckBoxField({
  value,
  item,
  column,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName,
}) {
  return (
    <>
      <input type='checkbox' style={{display: 'none'}} />
      <label className='checkbox checkbox-single field-custom-checkbox'>
        <input type='checkbox' checked={value} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

export default CheckBoxField;
