import {getApiV2, postApiV2} from 'core/store/api';


const endPoints = {
  searchStoreApps: '/api/app-store/store-apps',
  installStoreApp: '/api/app-store/store-apps/install',
};

export function getStoreApps(params) {
  return getApiV2(endPoints.searchStoreApps, params);
}

export function installStoreApp(params) {
  return postApiV2(endPoints.installStoreApp, params);
}