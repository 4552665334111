import {createSlice} from '@reduxjs/toolkit';

const initialDepartmentState = {
  listLoading: false,
  actionsLoading: false,
  departments: [],
  countDepartments: 0,
  teamForEdit: {},
  lastError: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const departmentSlice = createSlice({
  name: 'department',
  initialState: initialDepartmentState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    departmentsFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.departments = data.data ? data.data : [];
      state.countDepartments = data.count ?? 0;
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
