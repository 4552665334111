import {createSlice} from '@reduxjs/toolkit';

const initialWorkState = {
  listLoading: false,
  actionsLoading: false,
  currentWorkspacePages: {},
  workspaces: [],
  countWorkspaces: 0,
  currentWorkspace: null,
  countPages: 0,
  error: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};


export const workSlice = createSlice({
  name: 'work',
  initialState: initialWorkState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    
    workspacesFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.workspaces = data.data ? data.data : [];
      if (data.objects) {
        state.countWorkspaces = data.objects.total;
      } else if (data.total) {
        state.countWorkspaces = data.total;
      } else {
        state.countWorkspaces = 0;
      }
    },
    workspaceSelected: (state, action) => {
      const { workspace } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.currentWorkspace = workspace;
      
    },

    currentWorkspacesPagesFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.currentWorkspacePages = {allPages: data.allPages, userPages: data.userPages}
    },

  },
});
