import {FC, lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../core/assets/ts/_utils';
import {WithChildren} from '../../core/helpers';
import {MasterLayout} from '../../core/layouts/layout2/MasterLayout';
import {AppLayout} from '../../core/layouts/layout2/AppLayout';
import {NoAsideLayout} from 'core/layouts/layout2/NoAsideLayout';

const PrivateRoutes = () => {
  const MeetingPage = lazy(() => import('../modules/meeting/MeetingRoutes'));
  const WorkRoutes = lazy(() => import('../modules/work/WorkRoutes'));
  const AdminRoutes = lazy(() => import('../modules/admin/AdminRoutes'));
  const ProfilePage = lazy(() => import('app/modules/profile/ProfilePage'));
  const WorkflowRoutes = lazy(() => import('app/modules/admin/workflows/WorkflowRoutes'));
  const DashboardRoutes = lazy(() => import('app/modules/dashboard/DashboardRoutes'));
  const AppRoutes = lazy(() => import('../modules/application/AppRoutes'));
  const AppManagerRoutes = lazy(() => import('../modules/application/AppManagerRoutes'));
  const HomeRoutes = lazy(() => import('app/modules/home/HomeRoutes'));
  const DeveloperRoutes = lazy(() => import('app/modules/developer/DeveloperRoutes'));
  const SearchRoutes = lazy(() => import('app/modules/search/SearchRoutes'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}

        {/* Lazy Modules */}

        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='home/*'
          element={
            <SuspensedView>
              <HomeRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='search/*'
          element={
            <SuspensedView>
              <SearchRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='work/*'
          element={
            <SuspensedView>
              <WorkRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='workflow/*'
          element={
            <SuspensedView>
              <WorkflowRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='admin/*'
          element={
            <SuspensedView>
              <AdminRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='app-manager/*'
          element={
            <SuspensedView>
              <AppManagerRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='developer/*'
          element={
            <SuspensedView>
              <DeveloperRoutes />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>

      <Route element={<AppLayout />}>
        <Route
          path='application/*'
          element={
            <SuspensedView>
              <AppRoutes />
            </SuspensedView>
          }
        />
      </Route>
      <Route element={<NoAsideLayout />}>
        <Route
          path='meeting/*'
          element={
            <SuspensedView>
              <MeetingPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
