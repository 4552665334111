import React, {useEffect, useState} from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useField, useFormikContext} from 'formik';

const getFieldCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push('is-invalid-select');
  }

  if (touched && !errors) {
    classes.push('is-valid-select');
  }

  return classes.join(' ');
};

function MultiRadioButton({
  options = [],
  useCustomValue,
  onChange,
  defaultValue,
  label,
  className = '',
  controlClass = '',
  ...props
}) {
  const [field, meta] = useField(props);
  const {touched, error} = meta;
  const {setFieldValue} = useFormikContext();

  const {t} = useTranslate();
  const [checkedValue, setCheckedValue] = useState(defaultValue || '');
  const [customValue, setCustomValue] = useState(defaultValue || '');

  useEffect(() => {
    if (checkedValue !== defaultValue) {
      onChange && onChange(checkedValue);
    }
  }, [checkedValue, onChange]);

  const handleRadioChange = (value) => {
    setFieldValue(field.name, value);
    setCheckedValue(value);
    setCustomValue('');
  };

  const handleCustomOptionChange = (e) => {
    setCustomValue(e.target.value);
    setCheckedValue(e.target.value);
    setFieldValue(field.name, e.target.value);
  };

  const optionValues = options.map((option) => option.value);

  return (
    <div className={`${className} `}>
      {label && <div className='mb-2'>{label}</div>}
      <div className={`radio-items ${getFieldCSSClasses(touched, error)} ${controlClass}`}>
        {options.map((option, index) => (
          <label className='radio radio-lg radio-single' key={index}>
            <input
              type='radio'
              id={`${field.name}-radio-${index}`}
              value={option.value}
              checked={checkedValue && checkedValue === option.value}
              onChange={() => handleRadioChange(option.value)}
              className='h-15px me-1'
            />
            <label htmlFor={`${field.name}-radio-${index}`} className='align-middle mb-3'>
              {option.icon && option.icon}
              {option.text}
            </label>
          </label>
        ))}
        {useCustomValue && (
          <label className='radio radio-lg radio-single'>
            <input
              type='radio'
              id={`${field.name}-radio-custom-value`}
              value={customValue}
              checked={
                checkedValue && checkedValue === customValue && !optionValues.includes(checkedValue)
              }
              onChange={() => handleRadioChange(checkedValue)}
              className='h-15px me-1'
            />
            <label htmlFor={`${field.name}-radio-custom-value`} className='align-middle mb-3'>
              <input
                onChange={handleCustomOptionChange}
                placeholder={t('common_custom')}
                value={!optionValues.includes(checkedValue) ? customValue : ''}
                onFocus={handleCustomOptionChange}
              />
            </label>
          </label>
        )}
      </div>
    </div>
  );
}

export default MultiRadioButton;
