import './styles.scss';

import {FormattedNumber} from 'react-intl';
import clsx from 'clsx';
import {effectiveValue} from 'app/modules/work/helpers';
import {useState} from 'react';

function NumberField({
  value,
  item,
  column,
  mode,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName,
}) {
  const effValue = effectiveValue(value);
  const field = column?.field;
  const isDetailViewMode = mode === 'detail';
  const [showInput, setShowInput] = useState(false);
  const [focusInput, setFocusInput] = useState(false);

  const columnDirection = column?.direction || 'right';
  const columnAlignLeft = columnDirection === 'left';

  const onBlur = (e) => {
    setFocusInput(false);
    let newValue = e.target.value;
    if (newValue.trim() === '') {
      newValue = null;
    }
    if (newValue !== effValue) {
      onChange(item, field, value, {value: newValue});
    }
  };

  const unitNumber = () => {
    if (column.unit === 'dollar') {
      return <i className='las la-dollar-sign text-dark'></i>;
    }
    if (column.unit === 'percent') {
      return <i className='las la-percent text-dark'></i>;
    }
    return column.unit;
  };

  const onFocus = (e) => {
    setFocusInput(true);
    e.target.addEventListener(
      'wheel',
      (e) => {
        e.preventDefault();
      },
      {passive: false}
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <div className='d-flex align-items-center px-1 justify-content-end h-100 w-100'>
        {column.unit && columnAlignLeft && <span className='me-1'>{unitNumber()}</span>}
        {showInput || focusInput ? (
          <>
            <input
              type='number'
              className={clsx(
                'w-100 form-control form-control-xs item-text-input hide-input-controls bg-transparent',
                containerClassName,
                columnAlignLeft || isDetailViewMode ? 'text-start' : 'text-end'
              )}
              defaultValue={effValue}
              onBlur={onBlur}
              onFocus={onFocus}
              placeholder={isDetailViewMode ? column?.placeholder : ''}
              onMouseLeave={(e) => setShowInput(false)}
            ></input>
          </>
        ) : (
          <div
            className={clsx(
              'd-flex align-items-center w-100 h-100 overflow-hidden min-h-30px',
              containerClassName,
              columnAlignLeft || isDetailViewMode ? 'justify-content-start' : 'justify-content-end'
            )}
            onMouseEnter={() => setShowInput(true)}
          >
            {effValue || effValue === '0' ? (
              <span
                className={`truncate-long-number ${columnAlignLeft ? 'text-start' : 'text-end'}`}
              >
                <FormattedNumber value={effValue} />
              </span>
            ) : (
              <span className='ps-1 text-gray-500'>
                {isDetailViewMode ? column?.placeholder : ''}
              </span>
            )}
          </div>
        )}
        {column.unit && !columnAlignLeft && <span className='ms-1'>{unitNumber()}</span>}
      </div>
    );
  }

  return (
    <span
      className={clsx(
        'px-2 d-flex align-items-center justify-content-between overflow-hidden w-100',
        containerClassName
      )}
      onMouseEnter={() => setShowInput(true)}
      title={effValue ? new Intl.NumberFormat().format(effValue) : ''}
    >
      {columnAlignLeft && <span className='me-1'>{unitNumber()}</span>}
      {(effValue || effValue === '0') && (
        <span 
          className={`truncate-long-number ${columnAlignLeft ? 'text-start' : 'text-end'}`}
        >
          <FormattedNumber value={effValue} />
        </span>
      )}
      {!columnAlignLeft && (
        <>
          {!effValue ? <span className='d-inline-block'></span> : null}
          <span className='ms-1'>{unitNumber()}</span>
        </>
      )}
    </span>
  );
}

export default NumberField;
