export const dataViewTypes = ['table', 'table-paging', 'list'];

/**
 * check the current sheet view is data view or not
 *
 * @param {string} viewType
 * @return {boolean}
 */
export const checkDataView = (viewType) => {
  return dataViewTypes.includes(viewType);
};
