import './styles.scss';
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslate} from "core/i18n/i18nProvider";
import MultiRadioButton from "app/common/_partials/controls/MultiRadioButton";

const ConfigPrivacyDialog = ({
  title = '',
  children,
  onSave,
  className,
  defaultPrivacy,
}) => {
  const {t} = useTranslate();
  const [privacy, setPrivacy] = useState(defaultPrivacy)
  const [show, setShow] = useState(false);
  const onHide = () => setShow(false);
  const onShow = () => setShow(true);

  useEffect(() => {
    setPrivacy(defaultPrivacy)
  }, [defaultPrivacy])

  const options = [
    {value: 'public', text: t('document_privacy_public')},
    {value: 'private', text: t('document_privacy_private'), icon: <i className='las la-lock me-2'></i>},
    {value: 'shareable', text: t('document_privacy_shareable'), icon: <i className="bi bi-share me-2"></i>},
  ];

  const onChange = (newType) => {
    setPrivacy(newType);
  }

  const onSubmit = () => {
    onSave && onSave(privacy);
    onHide();
  }

  return (
    <>
      <span onClick={onShow}>{children}</span>
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MultiRadioButton
            options={options}
            className={`document-privacy ${className} `}
            defaultValue={privacy}
            onChange={onChange}
          />

        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-light btn-elevate btn-secondary font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
            onClick={onHide}
          >
            {t('common_cancel')}
          </button>
          <button
            onClick={onSubmit}
            className='btn btn-primary btn-elevate font-weight-bold py-2 px-3 px-xxl-5 my-1 w-100px'
          >
            {t('common_save')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfigPrivacyDialog;