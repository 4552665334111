import {memo} from 'react';
import {Position} from 'reactflow';
import './workflowTempNode.scss';
import WorkflowHandle from '../handle/WorkflowHandle';

const WorkflowTempNode = memo(({id, data, isConnectable}) => {
  return (
    <>
      <div className='workflow-temp-node nowheel nodrag'>
        <i className='fa-solid fa-plus' />
      </div>
      <WorkflowHandle
        type='target'
        position={Position.Top}
        id='temp-top'
        style={{top: -2, background: '#555'}}
        isConnectable={1}
      />
    </>
  );
});
export default WorkflowTempNode;