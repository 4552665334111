import '../../assets/scss/_sheet.scss';

import {Button, ButtonGroup, Dropdown, Spinner} from 'react-bootstrap';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import ModalAddNewItemFromTemplate from '../../apps/item-template/ModalAddNewItemFromTemplate';
import ModalImportItemsFromFile from '../../apps/item-from-file/ModalImportItemsFromFile';
import SheetFilterView from './filter/SheetFilterView';
import {ShowForSheetPermission} from '../../permission/ShowForSheetPermission';
import ToolbarAiAssistant from './ToolbarAiAssistant';
import clsx from 'clsx';
import {debounce} from 'lodash';
import {useInterfaceThemeContext} from '../sheet-view/interface-view/InterfaceThemeContext';
import {useSession} from 'core/store/hooks';
import {useState} from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';

//Deprecated
export default function SheetViewToolbar({
  sheetId,
  sheet,
  view,
  onFilterApply,
  activeFilters,
  filterActive,
  saveFilter,
  onFilterReset,
  onSearch,
  onAddNew,
  onAddNewGroup,
  onImport,
  onSetting,
  onCreateGroupTasks,
  mode,
  changeMode,
  onExportExcel,
  options,
  addItemsAndSubItems,
  allowFilterByGroup = false,
  canAddGroup,
  showAddButton,
  isEditMode,
  isLoadingAddItem,
  viewsCanAddItem = ['table', 'kanban', 'table-paging', 'table-paging-with-subitems'],
  viewsCanAdd = ['dashboard', 'connected-dashboard', 'table-paging'],
  ...props
}) {
  const {t} = useTranslate();
  const {user} = useSession();
  const [showAddItemFromTemplate, setShowAddItemFromTemplate] = useState(false);
  const [showAddItemFromFile, setShowAddItemFromFile] = useState(false);
  const {backgroundColor, textColor, addButtonVariant} = useInterfaceThemeContext();

  const onSearchChange = debounce((e) => {
    onSearch && onSearch(e.target.value);
  }, 500);

  const onCreateAiTask = (groupsAndTasks, callback) => {
    onCreateGroupTasks && onCreateGroupTasks(groupsAndTasks, callback);
  };

  return (
    <>
      <div className='py-6 sheet-view-toolbar'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            {options?.add && showAddButton && (
              <Dropdown as={ButtonGroup} className='dropdown-toggle-toolbar'>
                <Button
                  variant={backgroundColor ? '' : addButtonVariant}
                  size='sm'
                  onClick={onAddNew}
                  disabled={isLoadingAddItem}
                  style={{color: textColor, backgroundColor}}
                >
                  {isLoadingAddItem && (
                    <Spinner animation='border' variant='' size='sm' className='me-2' />
                  )}
                  {t('common_add')}
                </Button>

                <Dropdown.Toggle
                  split
                  style={{color: textColor, backgroundColor}}
                  variant={backgroundColor ? '' : addButtonVariant}
                  className='opacity-75 py-2 px-4 d-flex justify-content-center align-items-center'
                  size='sm'
                  id='dropdown-split-basic'
                />

                <Dropdown.Menu className='p-2'>
                  {canAddGroup && (
                    <Dropdown.Item onClick={onAddNewGroup} className='rounded p-2'>
                      <div className='d-flex align-items-center'>
                        <i className='las la-object-group fs-3 text-dark pe-2 ps-1'></i>
                        <span>{t('sheet_add_group')}</span>
                      </div>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setShowAddItemFromFile(true);
                    }}
                    className='rounded p-2'
                  >
                    <div className='d-flex align-items-center'>
                      <i className='las la-upload fs-3 text-dark pe-2 ps-1'></i>
                      <span>{t('sheet_import')}</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setShowAddItemFromTemplate(true)}
                    className='rounded p-2'
                  >
                    <div className='d-flex align-items-center'>
                      <i className='las la-sitemap fs-3 text-dark pe-2 ps-1'></i>
                      <span>{t('sheet_add_item_from_template')}</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            {options?.search && (
              <div className='d-flex align-items-center position-relative my-0 mx-2'>
                <span className='icon-search-big-1 fs-icon position-absolute top-50 translate-middle-y ms-2 left-0'></span>
                <input
                  type='search'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-sm form-control-solid w-150px ps-10 bg-light'
                  placeholder=''
                  onChange={onSearchChange}
                />
              </div>
            )}
            {options?.filter && (
              <div className='d-flex align-items-center'>
                <SheetFilterView
                  sheet={sheet}
                  view={view}
                  filterActive={filterActive}
                  resetFilter={onFilterReset}
                  activeFilters={activeFilters}
                  applyFilter={onFilterApply}
                  saveFilter={saveFilter}
                  allowFilterByGroup={allowFilterByGroup}
                  isEditMode={isEditMode}
                />
              </div>
            )}
            <ShowForSheetPermission permission='board.edit' sheet={sheet} user={user}>
              {options?.ai_assistant && <ToolbarAiAssistant onCreateTasks={onCreateAiTask} />}
            </ShowForSheetPermission>
          </div>

          <div>
            <ShowForSheetPermission permission='board.edit' sheet={sheet} user={user}>
              {viewsCanAdd.includes(view?.type) && view?.type !== 'table-paging' && (
                <div className='d-flex ms-2'>
                  <div className='d-flex align-items-center border rounded border-secondary overflow-hidden'>
                    <div
                      className={clsx(
                        'view-mode-switch p-2 w-80px text-center cursor-pointer',
                        mode === 'view' && 'bg-light-primary text-primary'
                      )}
                      onClick={() => changeMode('view')}
                    >
                      {t('sheet_dashboard_view_mode')}
                    </div>
                    <div
                      className={clsx(
                        'view-mode-switch p-2 w-80px text-center cursor-pointer',
                        mode === 'edit' && 'bg-light-primary text-primary'
                      )}
                      onClick={() => changeMode('edit')}
                    >
                      {t('sheet_dashboard_edit_mode')}
                    </div>
                  </div>
                  <a
                    href='#'
                    onClick={onSetting ? onSetting : null}
                    className='btn btn-icon btn-sm btn-hover-info'
                  >
                    <i className='las la-cog fs-4'></i>
                  </a>
                </div>
              )}
            </ShowForSheetPermission>
            {options?.export_excel && (
              <OverlayTrigger
                key='title-item'
                placement='top'
                overlay={
                  <Tooltip className='tooltip-inverse tooltip-title-item' id='tooltip-title-item'>
                    {t('sheet_export_excel')}
                  </Tooltip>
                }
              >
                <a
                  href='#'
                  onClick={onExportExcel ? onExportExcel : null}
                  className='btn btn-icon btn-sm btn-hover-info'
                >
                  <i className='fa-regular fa-file-excel'></i>
                </a>
              </OverlayTrigger>
            )}
            <ShowForSheetPermission permission='board.edit' sheet={sheet} user={user}>
              {options?.settings && viewsCanAddItem.includes(view?.type) && (
                <a
                  href='#'
                  onClick={onSetting ? onSetting : null}
                  className='btn btn-icon btn-sm btn-hover-info'
                >
                  <i className='las la-cog fs-4'></i>
                </a>
              )}
            </ShowForSheetPermission>
          </div>
        </div>
        {showAddItemFromTemplate && (
          <ModalAddNewItemFromTemplate
            title={t('sheet_from_template')}
            show={showAddItemFromTemplate}
            onHide={() => {
              setShowAddItemFromTemplate(false);
            }}
            sheetId={sheetId}
            handleAddItemFromTemplate={addItemsAndSubItems}
          />
        )}
        <ModalImportItemsFromFile
          show={showAddItemFromFile}
          onHide={() => {
            setShowAddItemFromFile(false);
          }}
          sheet={sheet}
        />
      </div>
    </>
  );
}
