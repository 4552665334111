import React, {useEffect, useState} from 'react';
import {useTranslate} from "core/i18n/i18nProvider";

function MultiRadioButton({
  options = [],
  useCustomValue,
  onChange,
  defaultValue,
  label,
  className = ''
}) {
  const {t} = useTranslate();
  const [checkedValue, setCheckedValue] = useState(defaultValue || '');
  const [customValue, setCustomValue] = useState(defaultValue || '');

  useEffect(() => {
    if (checkedValue !== defaultValue) {
      onChange && onChange(checkedValue);
    }
  }, [checkedValue, onChange]);

  const handleRadioChange = (value) => {
    setCheckedValue(value);
    setCustomValue('');
  };

  const handleCustomOptionChange = (e) => {
    setCustomValue(e.target.value);
    setCheckedValue(e.target.value);
  };

  const optionValues = options.map(option => option.value);

  return (
    <div className={className}>
      {label && <div className=''>{label}</div>}
      {options.map((option, index) => (
        <label className='radio radio-lg radio-single me-5' key={index}>
          <input
            type='radio'
            id={`radio-${index}`}
            value={option.value}
            checked={checkedValue && checkedValue === option.value}
            onChange={() => handleRadioChange(option.value)}
            className='h-15px me-1'
          />
          <label htmlFor={`radio-${index}`} className='align-middle mb-3'>
            {option.text}
          </label>
        </label>
      ))}
      {useCustomValue && (
        <label className='radio radio-lg radio-single me-5'>
          <input
            type='radio'
            id={`radio-custom-value`}
            value={customValue}
            checked={checkedValue && checkedValue === customValue && !optionValues.includes(checkedValue)}
            onChange={() => handleRadioChange(checkedValue)}
            className='h-15px me-1'
          />
          <label htmlFor={`radio-custom-value`} className='align-middle mb-3'>
            <input onChange={handleCustomOptionChange} placeholder={t('common_custom')}
                   value={!optionValues.includes(checkedValue) ? customValue : ''}
                   onFocus={handleCustomOptionChange}/>
          </label>
        </label>
      )}
    </div>
  );
}

export default MultiRadioButton;
