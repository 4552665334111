import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import './textValue.scss';
function TextValue({value, item, column, onChange, disabled}) {
  const effValue = effectiveValue(value);
  return (
    <input
      disabled={disabled}
      type='text'
      className={clsx('w-100 form-control')}
      value={effValue}
      onChange={(e) => onChange({value: e.target.value})}
    ></input>
  );
}

export default TextValue;
