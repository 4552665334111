import { getRangeValue } from 'core/helpers/dateRangeHelpers';
import {get, isEmpty} from 'lodash';
import {useEffect, useState} from 'react';
import { useSelector, shallowEqual} from 'react-redux';

export const useSheetViewData = ({sheet, user}) => {
  const allUsers = useSelector((state) => state.core.allUsers, shallowEqual);
  const [activeFilters, setActiveFilters] = useState({});
  const [filterActive, setFilterActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [viewItems, setViewItems] = useState([]);
  const [viewItemsByGroups, setViewItemsByGroups] = useState([]);
  const [viewItemsByColumns, setViewItemsByColumns] = useState([]);
  const [groupByColumn, setGroupByColumn] = useState(null);
  const [items, setItems] = useState([]);
  const [view, setView] = useState({});

  useEffect(() => {
    if (view) {
      setActiveFilters(view?.rule_filters ? view?.rule_filters : {});
      setFilterActive(isFilterActive(view?.rule_filters));
    }
  }, [view]);

  useEffect(() => {
    if (items) {
      let _viewItems = [];
      let _viewItemsByGroups = {};
      let _searchTermLowercase = searchTerm?.toLowerCase() || '';

      items.forEach((item) => {
        let passFilter = true;

        if (_searchTermLowercase !== '') {
          if (item.title?.value) {
            if (!item.title.value.toLowerCase().includes(_searchTermLowercase)) {
              passFilter = false;
            }
          } else if (item.title && !item.title.toLowerCase().includes(_searchTermLowercase)) {
            passFilter = false;
          }
        }

        if (activeFilters) {
          Object.values(activeFilters).forEach((activeFilter) => {
            if (activeFilter.value?.in?.length > 0) {
              if (activeFilter.field === 'complete') {
                let itemColumnValue = get(item, activeFilter.field);
                if (activeFilter.value.in.includes(itemColumnValue?.value)) {
                } else if (
                  itemColumnValue?.value === undefined &&
                  activeFilter.value.in.includes(false)
                ) {
                  //Pass
                } else {
                  passFilter = false;
                }
              } else if (activeFilter.type === 'status' || activeFilter.type === 'color') {
                let itemColumnValue = get(item, activeFilter.field);
                if (activeFilter.value.in.includes(itemColumnValue?.value || '')) {
                  //Pass
                } else {
                  passFilter = false;
                }
              } else if (activeFilter.type === 'people') {
                let itemColumnValue = get(item, activeFilter.field);
                let effectiveFilterIn = activeFilter.value.in.map((i) => {
                  if (i === 'current') {
                    return user._id;
                  } else {
                    return i;
                  }
                });
                let itemColumnValueMatchs = itemColumnValue?.value?.filter((i) =>{
                  if(i.iid && !i.id){
                    const user = allUsers?.find(user => user.iid === i.iid)
                    return effectiveFilterIn?.includes(user._id);
                  }
                  return effectiveFilterIn.includes(i._id);
                });
                if (itemColumnValueMatchs?.length > 0) {
                  //Pass
                } else {
                  passFilter = false;
                }
              } else if (activeFilter.type === 'date'){
                let itemColumnValue = get(item, activeFilter.field);
                let activeFilterValue = activeFilter?.value?.in?.map(item => {
                  return getRangeValue(item)
                })

                let hasValidValue = activeFilterValue?.find(item => itemColumnValue?.value >= item?.startDate && itemColumnValue?.value <= item?.endDate)
                //support old data type
                let hasValidValueInOldType = activeFilterValue?.find(item => itemColumnValue >= item?.startDate && itemColumnValue <= item?.endDate)
                if(hasValidValue || hasValidValueInOldType){
                  //Pass
                }else{
                  passFilter = false;
                }
              }
            }
          });
        }
        if (passFilter) {
          _viewItems.push(item);
          if (_viewItemsByGroups[item.group]) {
            if(item.group === 'default' || item.group === 'empty' || !item.group) {
              if(isEmpty(_viewItemsByGroups['empty'])){
                _viewItemsByGroups['empty']=[item]
              }else{
                _viewItemsByGroups['empty'].push(item);
              }
            }
            _viewItemsByGroups[item.group].push(item);
          } else {
            _viewItemsByGroups[item.group] = [item];
          }
        }
      });
      setViewItems(_viewItems);
      setViewItemsByGroups(_viewItemsByGroups);
    }
  }, [items, activeFilters, searchTerm]);

  useEffect(() => {
    let _itemsByGroupByColumnValue = {'': []};
    if (groupByColumn && viewItems) {
      if (groupByColumn) {
        for (var i = 0; i < groupByColumn.values.length; i++) {
          _itemsByGroupByColumnValue[groupByColumn.values[i].value] = [];
        }
      }

      for (var j = 0; j < viewItems.length; j++) {
        let itemKanbanValue =
          get(viewItems[j], groupByColumn.field)?.value || get(viewItems[j], groupByColumn.field);
        if (itemKanbanValue && _itemsByGroupByColumnValue[itemKanbanValue]) {
          _itemsByGroupByColumnValue[itemKanbanValue].push(viewItems[j]);
        } else if (
          get(viewItems[j], groupByColumn.field) === null ||
          get(viewItems[j], groupByColumn.field)?.value == null ||
          get(viewItems[j], groupByColumn.field)?.value == ''
        ) {
          _itemsByGroupByColumnValue[''].push(viewItems[j]);
        }
      }
    }
    setViewItemsByColumns(_itemsByGroupByColumnValue);
  }, [viewItems, groupByColumn]);

  const applyFilter = (filter, filterValue) => {
    let _newFilters = {...activeFilters};

    let _convertedFilterValue = {...filterValue};

    if (!_newFilters[filter.field]) {
      _newFilters[filter.field] = {
        ...filter,
        value: _convertedFilterValue,
      };
    } else {
      _newFilters[filter.field] = {
        ..._newFilters[filter.field],
        value: _convertedFilterValue,
      };
    }

    setFilterActive(isFilterActive(_newFilters));
    setActiveFilters(_newFilters);
  };

  const isFilterActive = (filters) => {
    let _filterActive = false;
    if (filters) {
      Object.values(filters).forEach((filter) => {
        if (filter?.value?.in?.length > 0) {
          _filterActive = true;
        }else if(filter?.type === 'date' && filter?.value?.start && filter?.value?.end){
          _filterActive = true;
        }
      });
    }

    return _filterActive;
  };

  const resetFilters = () => {
    setActiveFilters({});
    setFilterActive(false);
  };

  const applySearch = (search) => {
    setSearchTerm(search);
  };

  return {
    viewItems,
    viewItemsByGroups,
    applyFilter,
    activeFilters,
    resetFilters,
    filterActive,
    setGroupByColumn,
    viewItemsByColumns,
    setItems,
    setView,
    applySearch,
  };
};
