import {useTranslate} from 'core/i18n/i18nProvider';
import { isEmpty } from 'lodash';
import React, {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';

function SheetGroupFilter({activeFilters, groups = [], applyFilter}) {
  const [values, setValues] = useState([]);
  const {t} = useTranslate();

  useEffect(() => {
    if (activeFilters && activeFilters?.group) {
      let filter = activeFilters?.group;
      setValues(filter?.value?.in ?? []);
    } else {
      setValues([]);
    }
  }, [activeFilters]);

  const onToggleButton = (groupId) => {
    let newValues = [...values];
    if (values.includes(groupId)) {
      newValues = values.filter((value) => value !== groupId);
    } else {
      newValues.push(groupId);
    }
    setValues(newValues);

    applyFilter && applyFilter({field: 'group'}, {in: newValues});
  };

  if(isEmpty(groups)) return <></>
  
  return (
    <div className='d-flex flex-column align-items-stretch '>
      <ToggleButton
        type='checkbox'
        size='sm'
        variant='filter'
        className={`mb-2 text-center text-nowrap ${
          values.includes('$first') ? 'bg-light-primary' : 'bg-secondary'
        }`}
        value={'$first'}
        id={`toggle-group--$first`}
        onChange={() => onToggleButton('$first')}
      >
        {t('sheet_filter_first_group')}
      </ToggleButton>
      <ToggleButton
        type='checkbox'
        size='sm'
        variant='filter'
        className={`mb-2 text-center text-nowrap ${
          values.includes('empty') ? 'bg-light-primary' : 'bg-secondary'
        }`}
        value={'empty'}
        id={`toggle-group--empty`}
        onChange={() => onToggleButton('empty')}
      >
        {t('sheet_empty_group')}
      </ToggleButton>
      {groups &&
        groups.map((group, index) => (
          <ToggleButton
            key={index}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 text-center text-nowrap ${
              values.includes(group?.id) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            value={group?.id}
            id={`toggle-group--${group?.id}`}
            checked={values.includes(group?.id)}
            onChange={() => onToggleButton(group?.id)}
          >
            {group?.title === ''
              ? `${t('sheet_filter_unname_group')} (${index + 1})`
              : group?.title}
          </ToggleButton>
        ))}
    </div>
  );
}

export default SheetGroupFilter;
