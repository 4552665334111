import {createContext, useContext} from 'react';
export const InterfaceThemeContext = createContext({
  backgroundColor: '',
  textColor: 'white',
  addButtonVariant: 'primary',
});

export const InterfaceThemeProvider = ({children, value}) => {
  return <InterfaceThemeContext.Provider value={value}>{children}</InterfaceThemeContext.Provider>;
};

export const useInterfaceThemeContext = () => useContext(InterfaceThemeContext);
