import {Modal} from 'react-bootstrap';
import React from 'react';
import { useTranslate } from 'core/i18n/i18nProvider';

export function ModalErrorJoinMeeting({
  show,
  close,
  joinError,
}) {
  const {t} = useTranslate()
  return (
    <Modal show={show} onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t(joinError.message)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{t('meeting_join_error')}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-light btn-elevate btn-primary font-weight-bold me-2 py-2 px-3 px-xxl-5 my-1 w-100px'
          onClick={close}
        >
          {t('common_back')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
