import {useCallback, useEffect, useMemo, useState} from 'react';
import {getSheetAction, searchItemsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {useDispatch} from 'react-redux';

export const useLinkedItemManagement = (linkedSheetId) => {
  const dispatch = useDispatch();

  const [linkedSheetData, setLinkedSheetData] = useState(null);
  const [linkedSheetItems, setLinkedSheetItems] = useState([]);
  const [activeLinkedItemId, setActiveLinkedItemId] = useState(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);

  const linkedItemData = useMemo(() => {
    if (!activeLinkedItemId || !linkedSheetItems.length) {
      return null;
    }
    return linkedSheetItems.find((item) => item._id === activeLinkedItemId);
  }, [activeLinkedItemId, linkedSheetItems]);

  const hideDetailModal = useCallback(() => {
    setIsDetailModalVisible(false);
    setActiveLinkedItemId(null);
  }, []);

  const showDetailModal = useCallback((linkedItemId) => {
    setIsDetailModalVisible(true);
    setActiveLinkedItemId(linkedItemId);
  }, []);

  useEffect(() => {
    if (activeLinkedItemId && linkedSheetId) {
      // Fetch linked sheet
      dispatch(getSheetAction({id: linkedSheetId})).then((res) => {
        setLinkedSheetData(res?.data?.data);
      });

      // Fetch items in linked sheet
      dispatch(searchItemsAction({sheetIds: [linkedSheetId]})).then((response) => {
        setLinkedSheetItems(response?.data || []);
      });
    }
  }, [dispatch, linkedSheetId, activeLinkedItemId]);

  return {
    linkedSheetData,
    linkedItemData,
    isDetailModalVisible,
    hideDetailModal,
    showDetailModal,
  };
};
