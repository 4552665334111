import React, {useState} from 'react';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import SheetAddColumn from './SheetAddColumn';
import ConfigLookupModal from './ConfigLookupModal';

export const SheetColumnAddHeader = ({column, colIndex, sheetColumns, onAdd, linkableSheets}) => {
  const {t} = useTranslate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleColumnDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const [lookupColumnConfigs, setLookupColumnConfigs] = useState({});
  const [isLookupColumnModalVisible, setIsLookupColumnModalVisible] = useState(false);

  const submitAddColumn = (data, callback) => {
    if (data?.type === 'lookup') {
      setLookupColumnConfigs((prevState) => ({
        ...prevState,
        ...data,
        name: '',
        generatedColumnName: '', // used to column name placeholder
      }));
      setDropdownVisible(false);
      setIsLookupColumnModalVisible(true);
      callback && callback();
      return;
    }

    onAdd && onAdd(data);
    setDropdownVisible(false);
  };

  const onCreateLookupColumn = (values) => {
    onAdd && onAdd(values);
    setIsLookupColumnModalVisible(false);
  };

  return (
    <div className='w-100 d-flex align-items-center justify-content-left'>
      <Dropdown show={isDropdownVisible} onToggle={toggleColumnDropdown}>
        <Dropdown.Toggle variant='none'>
          <OverlayTrigger
            key='add-column'
            placement='top'
            overlay={
              <Tooltip
                className='tooltip-inverse tooltip-add-new-column'
                id='tooltip-add-new-column'
              >
                {t('sheet_table_view_add_column')}
              </Tooltip>
            }
          >
            <i className='icon-sm ki ki-plus' />
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <SheetAddColumn onSubmit={submitAddColumn} linkableSheets={linkableSheets} />
        </Dropdown.Menu>
      </Dropdown>
      <ConfigLookupModal
        sheetColumns={sheetColumns}
        initialConfig={lookupColumnConfigs}
        isLookupColumnModalVisible={isLookupColumnModalVisible}
        setIsLookupColumnModalVisible={setIsLookupColumnModalVisible}
        onCreateLookupColumn={onCreateLookupColumn}
      />
    </div>
  );
};
