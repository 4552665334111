/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {Badge} from 'react-bootstrap';
import {Tree, getBackendOptions, MultiBackend} from '@minoru/react-dnd-treeview';
import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getDepartmentsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {cloneDeep, debounce, isArray} from 'lodash';
import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import {DndProvider} from 'react-dnd';
import './departmentField.scss';
function DepartmentField({
  value,
  item,
  column,
  mode,
  onChange,
  fieldEditable,
  readOnly,
  containerClass,
}) {
  const dispatch = useDispatch();

  const PADDING_LEFT = 24;
  const currentDepartment = effectiveValue(value);
  const field = column?.field;
  const isDetailViewMode = mode === 'detail';
  const [dataSearch, setDataSearch] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [teamGridView, setTeamGridView] = useState([]);
  const [openItems, setOpenItems] = useState([]);
  const [departmentCurrentIds, setDepartmentCurrentIds] = useState([]);
  const [expandedOnce, setExpandedOnce] = useState(false);

  useEffect(() => {
    if (expandedOnce) {
      const params = {
        paging: {
          pageNumber: 1,
          pageSize: 500,
        },
      };
      dispatch(getDepartmentsAction(params)).then((res) => {
        setTeamGridView(res.data);
      });
    }
  }, [expandedOnce]);

  useEffect(() => {
    const newData = [];
    const itemMap = {};
    teamGridView.forEach((item) => {
      itemMap[item._id] = item;
    });

    const newItems = (children, parent) => {
      if (Array.isArray(children)) {
        children.forEach((item) => {
          if (!item) return;
          const data = {
            name: item.name,
            id: item._id,
            _id: item._id,
            droppable: true,
            parent: item.parent ? item.parent : 0,
            active: false,
            type: item.type,
          };

          if (item.parent && itemMap[item.parent]) {
            data['parent'] = item.parent;
          } else {
            data['parent'] = 0;
          }

          newData.push(data);
        });
      }
    };

    newItems(teamGridView);

    setTreeData(newData);
    setDataSearch(newData);
  }, [teamGridView]);

  useEffect(() => {
    let initialOpenItems = [];
    teamGridView &&
      teamGridView.forEach((item) => {
        initialOpenItems.push(item._id);
      });
    setOpenItems(initialOpenItems);
  }, [teamGridView]);

  useEffect(() => {
    let newDepartmentIds = [];
    if (currentDepartment && isArray(currentDepartment)) {
      newDepartmentIds = currentDepartment.map((department) => {
        return department._id;
      });
    }
    setDepartmentCurrentIds(newDepartmentIds);
  }, [currentDepartment]);

  const onSelectTreeItem = (department) => {
    let newDepartmentsValue = [];
    if (currentDepartment && isArray(currentDepartment)) {
      newDepartmentsValue = [...currentDepartment, department];
    } else {
      newDepartmentsValue.push(department);
    }
    onChange && onChange(item, field, value, newDepartmentsValue);
  };

  const removeDepartment = (departmentRemove) => {
    let newDepartments = [];
    if (currentDepartment) {
      newDepartments = cloneDeep(currentDepartment).filter((department) => {
        return department._id !== departmentRemove._id;
      });
    }
    onChange && onChange(item, field, value, newDepartments);
  };

  const searchDepartment = (e) => {
    const value = e?.target?.value;
    const departmentFilter = cloneDeep(dataSearch).filter((department) => {
      return department?.name?.toLowerCase()?.includes(value?.toLowerCase());
    });
    setTreeData(departmentFilter);
  };

  const popover = () => {
    return (
      <>
        {fieldEditable && (
          <div className='p-4 mh-400px overflow-auto cursor-pointer'>
            {currentDepartment?.length > 0 && (
              <div className='mb-2'>
                {isArray(currentDepartment) &&
                  currentDepartment?.map((department, index) => {
                    return (
                      <Badge
                        pill
                        key={department._id || index}
                        bg='light'
                        className='m-1 p-1 align-items-center align-middle'
                      >
                        <span className='ms-1 text-dark h-100 fs-7'>{department.name}</span>
                        {fieldEditable && (
                          <button
                            className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                            onClick={() => removeDepartment(department)}
                          >
                            <i className='las la-times text-muted'></i>
                          </button>
                        )}
                      </Badge>
                    );
                  })}
              </div>
            )}
            <div className='mb-2'>
              <input
                placeholder='Tìm kiếm'
                type='text'
                className='form-control form-control-sm'
                onChange={debounce(searchDepartment, 500)}
              ></input>
            </div>
            <hr className='border-start-1 mt-1' />
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <Tree
                tree={treeData}
                rootId={0}
                render={(item, {depth, isOpen, onToggle}) => {
                  return (
                    <TreeNode
                      onSelectTreeItem={onSelectTreeItem}
                      departmentCurrentIds={departmentCurrentIds}
                      item={item}
                      depth={depth}
                      isOpen={isOpen}
                      onToggle={onToggle}
                      paddingLeft={PADDING_LEFT}
                    />
                  );
                }}
                sort={false}
                dropTargetOffset={10}
                insertDroppableFirst={false}
                initialOpen={openItems}
                classes={{
                  root: 'list-tree-page',
                  placeholder: 'placeholder-container',
                }}
              />
            </DndProvider>
          </div>
        )}
      </>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <DropdownPopover onToggle={() => setExpandedOnce(true)} renderPopover={popover}>
        <div className='d-flex align-items-center list-dependency h-100 cursor-pointer px-2 min-h-30px'>
          {currentDepartment?.length ? (
            currentDepartment.map((department, index) => {
              return (
                <OverlayTrigger
                  key={index}
                  placement='top'
                  overlay={
                    <Tooltip
                      className='tooltip-inverse tooltip-title-item position-fixed'
                      id='tooltip-title-item'
                    >
                      {department.name}
                    </Tooltip>
                  }
                >
                  <div key={department._id} className='rounded item-dependency px-1'>
                    <span className=' rounded cursor-pointer fs-7'>{department.name}</span>
                  </div>
                </OverlayTrigger>
              );
            })
          ) : isDetailViewMode ? (
            <span className='text-gray-500 lh-xl'>{column?.placeholder || ''}</span>
          ) : null}
        </div>
      </DropdownPopover>
    );
  }

  return (
    <div className='w-100 border-0 py-0 px-2 position-relative button-dropdown-link cursor-pointer'>
      <div className='d-flex align-items-center list-dependency h-100'>
        {currentDepartment?.map((department, index) => {
          return (
            <OverlayTrigger
              key={index}
              placement='top'
              overlay={
                <Tooltip className='tooltip-inverse tooltip-title-item' id='tooltip-title-item'>
                  {department.name}
                </Tooltip>
              }
            >
              <div key={department._id} className='rounded item-dependency px-1'>
                <span className=' rounded cursor-pointer fs-7'>{department.name}</span>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
}

const TreeNode = (props) => {
  const {item, onSelectTreeItem, paddingLeft} = props;
  const itemActive = useRef();

  const indent = 4 + props.depth * paddingLeft;

  const ItemIcon = ({item, isOpen}) => {
    return <i className={`icon-sm fas ${isOpen ? 'fa-folder-open' : 'fa-folder'} `} />;
  };

  return (
    <div
      className={`tree-node root ${clsx({
        'opacity-25': props.departmentCurrentIds.includes(item._id),
      })}`}
      onClick={(event) => {
        if (!props.departmentCurrentIds.includes(item._id)) {
          onSelectTreeItem(item);
        }
      }}
      ref={itemActive}
      style={{paddingInlineStart: indent}}
    >
      <span
        className='svg-icon menu-icon page-item-icon d-flex align-teams-center'
        style={{width: '0.75rem'}}
      >
        <div
          className='rstm-toggle-icon'
          onClick={(e) => {
            props.onToggle();
          }}
        >
          <ItemIcon item={item} isOpen={props.isOpen}></ItemIcon>
        </div>
      </span>
      <div className={'label-grid-item d-flex align-teams-center flex-1 '}>
        <div className='d-inline-block text-nowrap text-truncate'>{item.name}</div>
      </div>
    </div>
  );
};

export default DepartmentField;
