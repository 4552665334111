import {
  addDocAction,
  addPageAction,
  deletePageAction,
  fetchPagesAction,
  movePageAction,
  setEmptyPagesAction,
  updatePageAction,
} from '../stores/actions';
import {cloneDeep, uniqBy} from 'lodash';
import {getInsertPos, orderGap, sortArrByKey} from 'core/helpers/orderArr';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {simpleInitPage, simpleInitSheet} from '../templates/sheet';
import {useEffect, useState} from 'react';

import {simpleInitDocumentRoles} from '../templates/document';
import {subType} from '../pages/page/constant';
import {AnalyticHandler} from 'core/monitoring/analytic';
const terminologyItems = require('app/modules/work/pages/page/add/terminologyList.json');

export const useWorkspace = (
  initDefaultView = {
    name: '',
    type: 'table',
    code: 'table1',
    is_default: true, // set table as a default sheet view
  }
) => {
  const dispatch = useDispatch();
  const [pages, setPages] = useState([]);

  const {currentWorkspacePages, currentWorkspace} = useSelector(
    (state) => state.work,
    shallowEqual
  );

  useEffect(() => {
    if (currentWorkspace) {
      fetchPages();
    }
  }, [currentWorkspace]);

  useEffect(() => {
    if (currentWorkspacePages?.userPages) {
      let _pages = [...currentWorkspacePages.userPages];
      let _pagesParents = currentWorkspacePages.allPages.filter(
        (item) => _pages.findIndex((page) => page.parent === item._id) >= 0
      );
      _pages.push(..._pagesParents);
      //Parent level 2
      _pagesParents = currentWorkspacePages.allPages.filter(
        (item) => _pages.findIndex((page) => page.parent === item._id) >= 0
      );
      _pages.push(..._pagesParents);
      //Parent level 3
      _pagesParents = currentWorkspacePages.allPages.filter(
        (item) => _pages.findIndex((page) => page.parent === item._id) >= 0
      );
      _pages.push(..._pagesParents);
      //Remove duplicate
      _pages = uniqBy(_pages, '_id');

      _pages = sortArrByKey(_pages, 'pos');
      setPages(_pages);
    }
  }, [currentWorkspacePages]);

  const fetchPages = () => {
    if (currentWorkspace?._id) {
      const params = {
        page: 1,
        pageSize: 100,
        workspace: currentWorkspace?._id,
      };
      dispatch(fetchPagesAction(params));
    } else {
      dispatch(setEmptyPagesAction());
    }
  };

  const deletePage = ({pageId, onSuccess, onError}) => {
    const payload = {
      pageId: pageId,
    };
    dispatch(deletePageAction(payload)).then((res) => {
      if (res.data && res.data.success === true) {
        fetchPages();
        onSuccess && onSuccess();
      } else {
        onError && onError(res.data.message);
      }
    });
  };

  const movePage = (page, toPage, toPageIndex, callback) => {
    if (!toPage || toPage.type === 'page') {
      const movePageParams = {
        id: page._id,
        toPage: toPage ? toPage._id : null,
        toPageIndex: toPageIndex,
      };
      dispatch(movePageAction(movePageParams)).then(() => {
        fetchPages();
      });

      const arrPos = pages.map((item) => item?.pos || 0);
      const currentIndex = pages.findIndex((e) => e?._id === page?._id);
      const isMoveDown = toPageIndex > currentIndex;
      const targetIndex = isMoveDown ? toPageIndex - 1 : toPageIndex;
      const nextIndex = isMoveDown ? targetIndex + 1 : targetIndex - 1;
      const newPos = getInsertPos(arrPos, targetIndex, nextIndex);

      dispatch(updatePageAction({pageId: page._id, pos: newPos})).then(() => {
        callback && callback();
      });
    }
  };

  const VERSION = '2.0';

  const addPage = ({page, onSuccess, onError}) => {
    if (page.type === 'sheet') {
      addSheet({page, onSuccess, onError});
    } else {
      const arrPos = pages.map((item) => item?.pos || 0);
      const insertPos = getInsertPos(arrPos, 0);
      let addParams = simpleInitPage();
      if (page.type === 'page') {
        addParams = simpleInitPage();
      } else if (page.type === 'sheet') {
        addParams = simpleInitSheet(initDefaultView);
        const terminology = page?.terminology?.name;
        if (terminology) {
          // update title of first column
          addParams.columns[0].name = terminology;
        }
      } else {
        addParams.type = page.type;
      }

      addParams.code = new Date().getTime();
      addParams.name = page.name;
      addParams.parent = page.parent;
      addParams.workspace = page.workspace;
      addParams.pos = insertPos;
      addParams.members = page.members;
      addParams.confidential = page.confidential;
      addParams.privacy = page.privacy;
      addParams.meta_version = VERSION;
      if (page.metaVersion) {
        addParams.meta_version = page.metaVersion;
      }
      if (page.docBlock) {
        addParams.sub_type = subType.DOC_BLOCK;
        addParams.first_block = {
          content: {
            data: {
              text: '',
            },
            type: 'paragraph',
          },
          pos: orderGap,
        };
      }

      if (page.file) {
        addParams.file = page.file;
        addParams.sub_type = page.subType;
      }

      if (page.google_doc) {
        addParams.file = page.google_doc;
        addParams.sub_type = 'gdoc';
      }

      if (page.type === 'doc') {
        addParams.roles = simpleInitDocumentRoles;
        if (!addParams.first_block) {
          addParams.firstBlock = {
            content: '',
            pos: orderGap,
          };
        }
        addDoc({addParams, onSuccess, onError});
        return;
      }

      dispatch(addPageAction(addParams)).then((res) => {
        if (res.data && res.data.success === true) {
          fetchPages();
          onSuccess && onSuccess(res?.data);
        } else {
          onError && onError(res.data.message);
        }
      });
    }
  };

  const addDoc = ({addParams, onSuccess, onError}) => {
    dispatch(addDocAction(addParams)).then((res) => {
      if (res.data && res.data.success === true) {
        fetchPages();
        onSuccess && onSuccess(res.data);
      } else {
        onError && onError(res.data.message);
      }
    });
  };

  const addSheet = ({page, onSuccess, onError}) => {
    let addParams = simpleInitSheet(initDefaultView);

    const arrPos = pages.map((item) => item?.pos || 0);
    const insertPos = getInsertPos(arrPos, 0);

    addParams.code = new Date().getTime();
    addParams.name = page.name;
    addParams.parent = page.parent;
    addParams.pos = insertPos;
    addParams.workspace = page.workspace;
    addParams.members = page.members;
    addParams.confidential = page.confidential;
    addParams.privacy = page.privacy;
    addParams.meta_version = VERSION;
    addParams.terminology = terminologyItems[0];
    if (page.metaVersion) {
      addParams.meta_version = page.metaVersion;
    }
    if (page?.columns) {
      addParams.columns = cloneDeep(page.columns);
    }

    if (page?.terminology) {
      addParams['terminology'] = page.terminology;
    }

    let addSubboardParams = simpleInitSheet(initDefaultView);

    addSubboardParams.code = new Date().getTime();
    addSubboardParams.name = page.name + ' Subitems';
    addSubboardParams.hidden = true;
    addSubboardParams.members = page.members;
    addSubboardParams.privacy = page.privacy;
    AnalyticHandler.trackAction('sheet.create');
    dispatch(addPageAction(addParams)).then((res) => {
      if (res.data && res.data.success === true) {
        fetchPages();
        onSuccess && onSuccess(res?.data);
      } else {
        onError && onError(res.data.message);
      }
    });
  };

  return {
    pages,
    deletePage,
    movePage,
    addPage,
  };
};
