import React, {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {getSheetColumnValuesAction} from '../../../stores/sheetActions';
import {isEmpty} from 'lodash';
import {useTranslate} from 'core/i18n/i18nProvider';

function SheetReferenceFilter({key, sheet, column, filter, filterValue, applyFilter}) {
  const [values, setValues] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const dispatch = useDispatch();
  const {t} = useTranslate();
  useEffect(() => {
    dispatch(getSheetColumnValuesAction({sheetId: sheet?._id, field: column?.field})).then(
      (res) => {
        const referenceData = res?.data ?? [];
        setReferenceOptions(referenceData);
      }
    );
  }, [column]);

  useEffect(() => {
    if (filterValue && filterValue?.value && filterValue?.value?.in) {
      setValues(filterValue?.value?.in);
    } else {
      setValues([]);
    }
  }, [filterValue]);

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);
    applyFilter && applyFilter(filter, {in: newValues});
  };
  
  return (
    <>
      <div className='d-flex flex-column align-items-stretch '>
        {isEmpty(referenceOptions) && <div className='text-center p-2 bg-secondary rounded w-200px'>{t('sheet.filter.no_reference_selected')}</div>}
        {referenceOptions?.map((option) => (
          <ToggleButton
            id={`toggle-people-${column.field}-${option.id}`}
            key={`toggle-people-${column.field}-${option.id}`}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 d-flex align-items-center text-start text-nowrap ${
              filterValue?.value?.in?.includes(option.id) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            checked={filterValue?.value.in?.includes(option.id)}
            value='1'
            onChange={(e) => onToggleValue(option.id)}
          >
            {option?.title}
          </ToggleButton>
        ))}
      </div>
    </>
  );
}

export default SheetReferenceFilter;
