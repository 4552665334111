import {useTranslate} from 'core/i18n/i18nProvider';
import './formConfigPage.scss';
import React, {useEffect, useMemo, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import FormConfigItem from '../../../../application/app-form/form/FormConfigItem';
import {cloneDeep, set} from 'lodash';
import {useDispatch} from 'react-redux';
import {getFormDetailAction, updateFormAction} from 'app/modules/work/stores/form/action';
import {hiddenColumns} from '../../FormEditView';

export default function ConfigFormPage({formPage}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const [columnViewForm, setColumnViewForm] = useState([]);
  const [numberColumnShow, setNumberColumnShow] = useState(0);
  const [searchNameColumn, setSearchNameColumn] = useState('');
  const [columnFilter, setColumnFilter] = useState([]);

  const handleSaveForm = (form) => {
    const params = {
      settings: form?.settings,
    };
    dispatch(updateFormAction(formPage._id, params)).then((res) => {
      dispatch(getFormDetailAction(formPage._id));
    });
  };

  const handleToggleVisibilityColumn = (visibility, field) => {
    const newListColumn = cloneDeep(columnViewForm)?.map((column) => {
      if (column.field === field) {
        if (visibility === 'show') {
          return {
            ...column,
            hidden: true,
          };
        } else {
          return {
            ...column,
            hidden: false,
          };
        }
      } else {
        return column;
      }
    });
    const _formPage = cloneDeep(formPage);
    set(_formPage, 'settings.columns', newListColumn);
    handleSaveForm(_formPage);
  };

  const listField = useMemo(() => {
    let columns = formPage?.sheet_info?.columns || [];

    let listColumnsByView = [];
    formPage?.settings?.columns?.forEach((item) => {
      let columnData = columns.find((column) => column.field === item.field);
      if (columnData) {
        listColumnsByView.push({
          ...columnData,
          hidden: item.hidden,
        });
      }
    });
    let listColumns = columns.filter(
      (item) => !listColumnsByView.find((column) => column.field === item.field)
    );
    listColumns = listColumns.map((item) => {
      return {...item, hidden: true};
    });
    columns = [...listColumnsByView, ...listColumns];
    return columns;
  }, [formPage?.settings?.columns, formPage?.sheet_info?.columns]);

  useEffect(() => {
    const listColumn = listField;
    const columnViewForm = listColumn?.filter((column) => !hiddenColumns.includes(column?.type));
    setColumnViewForm(columnViewForm);
    setColumnFilter(columnViewForm);
  }, [listField]);

  const isFirstShowColumn = (index) => {
    const firstShowColumnItemIndex = columnViewForm.findIndex(
      (item) => !item.hidden || item.hidden === false
    );
    return firstShowColumnItemIndex === index;
  };

  const isLastShowColumn = (index) => {
    const lastShowColumnItemIndex = columnViewForm.findLastIndex(
      (item) => !item.hidden || item.hidden === false
    );
    return lastShowColumnItemIndex === index;
  };

  const handleMoveItemUp = (index) => {
    moveColumn(index, index - 1);
  };

  const handleMoveItemDown = (index) => {
    moveColumn(index, index + 1);
  };

  const moveColumn = (dragIndex, hoverIndex) => {
    const dragItem = columnViewForm[dragIndex];
    const newListColumn = [...columnViewForm];
    newListColumn.splice(dragIndex, 1);
    newListColumn.splice(hoverIndex, 0, dragItem);
    setColumnViewForm(newListColumn);
    const _formPage = cloneDeep(formPage);
    set(_formPage, 'settings.columns', newListColumn);
    handleSaveForm(_formPage);
  };

  const onSaveNameButtonSubmit = (e) => {
    const nameButton = e.target.value;
    const settings = cloneDeep(formPage?.settings || {});
    set(settings, 'buttonSubmit.label', nameButton);
    handleSaveForm({settings});
  };

  const onSaveContentNotiSubmit = (e) => {
    const content = e.target.value;
    const settings = cloneDeep(formPage?.settings || {});
    set(settings, 'notification.content', content);
    handleSaveForm({settings});
  };

  useEffect(() => {
    const numberShowColumn = columnViewForm?.filter(
      (column) => !column.hidden && !column.deleted && !hiddenColumns.includes(column.type)
    )?.length;
    setNumberColumnShow(numberShowColumn);
  }, [columnViewForm]);

  useEffect(() => {
    if (searchNameColumn) {
      const listColumnFilter = cloneDeep(columnFilter)?.filter((column) => {
        return column.name.toLowerCase().includes(searchNameColumn.toLowerCase());
      });
      setColumnViewForm(listColumnFilter);
    } else {
      setColumnViewForm(columnFilter);
    }
  }, [searchNameColumn]);

  return (
    <>
      <div className='d-flex align-items-center border-bottom'>
        <p className='mb-0 ps-5 py-4 fw-bold'>{t('form_page_config_page')}</p>
      </div>
      <div className='px-5 overflow-auto'>
        <div className='border-bottom py-3'>
          <p className='mb-2 fw-bolder'>{t('app_menu_data')}</p>
          <FormConfigItem
            label={t('interface_page_config_page_column')}
            fieldComponent={
              <Dropdown className='d-block' drop={'down-centered'}>
                <Dropdown.Toggle
                  className='min-w-150px rounded px-3 py-2'
                  id='interface-page-view-config-columns'
                >
                  <div className='d-flex align-items-center justify-content-between cursor-pointer text-dark fs-base fw-normal'>
                    {t('interface_config_show_column', {number: numberColumnShow || 0})}
                    <i className='las la-angle-down text-dark'></i>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='w-350px p-0'>
                  <div className='border-bottom p-4'>
                    <span className='fw-bolder fs-6'>{t('interface_config_column_show')}</span>
                  </div>
                  <div className='border-bottom p-4'>
                    <input
                      type='text'
                      placeholder='Tìm một cột'
                      className='form-control'
                      onChange={(e) => setSearchNameColumn(e.target.value)}
                    />
                  </div>
                  <div className='d-flex flex-column overflow-auto h-250px'>
                    <div className='d-flex flex-column py-2'>
                      {columnViewForm?.map((column, index) => {
                        if (
                          !column.hidden &&
                          !column.deleted &&
                          !hiddenColumns.includes(column.type)
                        ) {
                          return (
                            <div
                              className='py-2 px-4 bg-hover-light rounded d-flex align-items-center justify-content-between'
                              key={column.field}
                            >
                              <span className='fst-normal'>{column?.name}</span>
                              <span>
                                {!isFirstShowColumn(index) && (
                                  <i
                                    className='fa la-arrow-up ms-3 text-gray-600'
                                    onClick={() => handleMoveItemUp(index)}
                                  />
                                )}
                                {!isLastShowColumn(index) ? (
                                  <i
                                    className='fa la-arrow-down ms-3 text-gray-600'
                                    onClick={() => handleMoveItemDown(index)}
                                  />
                                ) : (
                                  <span className='w-20px d-inline-block'></span>
                                )}
                                <i
                                  className='las la-eye-slash text-gray-600 ms-4'
                                  onClick={() => handleToggleVisibilityColumn('show', column.field)}
                                ></i>
                              </span>
                            </div>
                          );
                        } else {
                          return <React.Fragment key={column.field} />;
                        }
                      })}
                    </div>
                    <div className='hidden-field px-4 py-2'>
                      <span className='fw-bolder'>{t('interface_config_column_hidden')}</span>
                    </div>
                    <div className='d-flex flex-column py-2'>
                      {columnViewForm?.map((column) => {
                        if (
                          column.hidden &&
                          !column.deleted &&
                          !hiddenColumns.includes(column.type)
                        ) {
                          return (
                            <div
                              className='py-2 px-4 bg-hover-light rounded d-flex align-items-center justify-content-between'
                              key={column.field}
                            >
                              <span>{column?.name}</span>
                              <i
                                className='las la-eye text-gray-600'
                                onClick={() => handleToggleVisibilityColumn('hidden', column.field)}
                              ></i>
                            </div>
                          );
                        } else {
                          return <React.Fragment key={column.field} />;
                        }
                      })}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            }
          />
        </div>
        <div>
          <FormConfigItem
            label={t('interface_page_config_page_title_button')}
            fieldComponent={
              <input
                name='interface'
                className='border px-2 h-38px rounded input-page-title'
                defaultValue={
                  formPage?.settings?.buttonSubmit?.label ||
                  t('interface_page_config_page_default_button')
                }
                onBlur={onSaveNameButtonSubmit}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.target?.blur();
                  }
                }}
              />
            }
          />
          <FormConfigItem
            label={t('interface_page_config_page_title_noti')}
            fieldComponent={
              <input
                name='interface'
                className='border px-2 h-38px rounded input-page-title'
                defaultValue={
                  formPage?.settings?.notification?.content ||
                  t('interface_page_config_page_default_noti')
                }
                onBlur={onSaveContentNotiSubmit}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.target?.blur();
                  }
                }}
              />
            }
          />
        </div>
      </div>
    </>
  );
}
