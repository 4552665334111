import {searchPeopleAction, searchTeamsAction} from 'app/modules/work/sheet/stores/sheetActions';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useEffect, useMemo, useState} from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {generateNameAvatar} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {useSession} from 'core/store/hooks';
import {debounce, isArray, upperFirst} from 'lodash';
import Select from 'react-select';

export default function ColumnUserPermittedEdit({column, sheetColumns, onSubmit}) {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const {user} = useSession();
  const [permittedUsers, setPermittedUsers] = useState(column?.permitted_users || []);
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [peopleOptionIds, setPeopleOptionIds] = useState([]);
  const [permittedUserColumns, setPermittedUserColumns] = useState([]);

  const search = async (text) => {
    const people = await dispatch(searchPeopleAction({filter: {q: text}, page: 1, pageSize: 10}));
    const teams = await dispatch(searchTeamsAction({filter: {q: text}, page: 1, pageSize: 10}));
    const teamOption = teams.map((team) => {
      return {...team, type: 'team'};
    });
    const newPeopleOptions = [...people, ...teamOption];
    setPeopleOptions(newPeopleOptions);
  };

  const searchPeople = (e) => {
    const value = e?.target?.value || '';
    search(value)
  };

  const selectPerson = (person) => {
    const newPeople = [];
    const newPeopleIds = [];
    if (permittedUsers && isArray(permittedUsers)) {
      permittedUsers.forEach((people) => {
        if (people._id !== person._id) {
          newPeople.push(people);
          newPeopleIds.push(people._id);
        }
      });
    }

    newPeople.push({name: person.name, id: person._id, _id: person._id, type: person?.type ?? 'user'});
    newPeopleIds.push(person._id);

    setPeopleOptionIds(newPeopleIds);
    setPermittedUsers(newPeople);
    const _columnEdit = {...column};
    _columnEdit.permitted_users = newPeople;
    onSubmit(_columnEdit);
  };

  const removePerson = (person) => {
    const newPeople = [];
    const newPeopleIds = [];
    if (permittedUsers) {
      permittedUsers.forEach((people) => {
        if (people._id != person._id) {
          newPeople.push(people);
          newPeopleIds.push(people._id);
        }
      });
    }
    setPeopleOptionIds(newPeopleIds);
    setPermittedUsers(newPeople);
    const _columnEdit = {...column};
    _columnEdit.permitted_users = newPeople;
    onSubmit(_columnEdit);
  };

  const selectUserColumns = (columns) => {
    setPermittedUserColumns(columns);
    const _columnEdit = {...column};
    _columnEdit.permitted_user_columns = columns;
    onSubmit(_columnEdit);
  };

  useEffect(() => {
    if (column?.permitted_users) {
      setPermittedUsers(column?.permitted_users);
      const peopleOptionIds = column?.permitted_users?.map((user) => {
        return user._id;
      });
      setPeopleOptionIds(peopleOptionIds);
    }
    if (column?.permitted_user_columns) {
      setPermittedUserColumns(column?.permitted_user_columns);
    }
  }, [column]);

  const avatar = (person) => {
    let personItem = {};
    if (person._id === user._id) {
      personItem = user;
    } else {
      personItem = objectUser[person._id] || {};
    }
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip className='tooltip-inverse'>{upperFirst(person?.name)}</Tooltip>}
      >
        <div key={person._id} className='symbol symbol-30px symbol-circle'>
          <span className='symbol-label text-uppercase bg-secondary'>
            {personItem?.avatar ? (
              <img src={personItem?.avatar} className='w-100 h-100 rounded-circle' alt='' />
            ) : (
              generateNameAvatar(person?.name)
            )}
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  useEffect(() => {
    searchPeople();
  }, []);

  const columnOptionPermitted = useMemo(() => {
    return sheetColumns
      ?.filter((column) => column.type === 'people' && !column?.deleted && !column?.hidden)
      ?.map((column) => ({label: column.name, value: column.field}));
  }, [sheetColumns]);

  return (
    <>
      <div className=''>
        <div className='form-group row'>
          <div className=''>
            {permittedUsers?.length > 0 && (
              <div className='mb-2'>
                {isArray(permittedUsers) &&
                  permittedUsers?.map((member, index) => {
                    return (
                      <Badge
                        pill
                        key={member._id || index}
                        bg='light'
                        className='m-1 p-1 align-items-center align-middle'
                      >
                        {avatar(member)}
                        <span className='ms-1 text-dark h-100'>{member.name}</span>
                        <button
                          className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                          onClick={() => removePerson(member)}
                        >
                          <i className='las la-times text-muted'></i>
                        </button>
                      </Badge>
                    );
                  })}
              </div>
            )}
            <div>
              <Select
                formatOptionLabel={({label}) => <div className='text-capitalize'>{label}</div>}
                cacheOptions
                classNamePrefix='select'
                options={columnOptionPermitted}
                placeholder={t('sheet_edit_column_user_permitted_placeholder')}
                isMulti
                closeMenuOnSelect={false}
                value={permittedUserColumns}
                onChange={(columns) => {
                  selectUserColumns(columns);
                }}
              />
            </div>
            <div className='mb-2 mt-2'>
              <input
                placeholder='Tìm kiếm'
                type='text'
                className='form-control form-control-sm'
                onChange={debounce(searchPeople, 500)}
              ></input>
            </div>
            <hr className='border-start-1 mt-1' />
            <div className='h-250px overflow-auto'>
              {peopleOptions.map((person) => {
                let personItem = {};
                if (person._id === user._id) {
                  personItem = user;
                } else {
                  personItem = objectUser[person._id] || {};
                }
                return (
                  <div
                    className={`dropdown-item-xs dropdown-item ${clsx({
                      'opacity-50': peopleOptionIds.includes(person._id),
                      'cursor-pointer': !peopleOptionIds.includes(person._id),
                    })}`}
                    key={person._id}
                    onClick={() => {
                      selectPerson(person);
                    }}
                  >
                    {personItem?.avatar ? (
                      <img
                        src={personItem?.avatar}
                        className='w-15px h-15px rounded-circle me-2'
                        alt=''
                      />
                    ) : (
                      <>
                        {person?.type && person?.type === 'team' ?  <i className='la la-user-friends me-2 fs-5'></i> : <i className='la la-user-circle me-2 fs-5'></i> }
                      </>
                    )}
                    {person.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
