import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import './linkField.scss';
import {useTranslate} from 'core/i18n/i18nProvider';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
function LinkField({
  value = {label: '', value: ''},
  item,
  column,
  mode,
  onChange,
  fieldEditable,
  readOnly,
  containerClass,
}) {
  const {t} = useTranslate();
  const field = column?.field;
  const originalFieldValue = value?.value;
  const originalFieldLabel = value?.label;

  const [link, setLink] = useState('');
  const [label, setLabel] = useState('');
  const isDetailViewMode = mode === 'detail';

  const onBlur = (newValue, type) => {
    if (type === 'link') {
      if (newValue !== originalFieldValue) {
        onChange(item, field, value, {value: newValue, label});
      }
    } else {
      if (newValue !== originalFieldLabel) {
        onChange(item, field, value, {value: link, label: newValue});
      }
    }
  };

  const handleRemoveLink = (e) => {
    if (originalFieldValue) {
      setLink('');
      e.stopPropagation();
      onChange(item, field, value, {value: '', label: ''});
    }
  };

  const handleOpenLink = (e) => {
    if (originalFieldValue) {
      e.stopPropagation();
      let newTab = window.open();
      if (!originalFieldValue?.startsWith('https://')) {
        newTab.location.href = `https://${originalFieldValue}`;
      } else {
        newTab.location.href = originalFieldValue;
      }
    }
  };

  useEffect(() => {
    setLabel(originalFieldLabel);
    setLink(originalFieldValue);
  }, [originalFieldLabel, originalFieldValue]);

  const popover = () => {
    return (
      <div className='w-300px p-5'>
        <div className='fw-bold fs-4'>{t('sheet.formatter.field.link_link')}</div>
        <div className='mt-2'>{t('sheet.formatter.field.link_paste_or_type')}</div>
        <input
          type='text'
          value={link}
          onChange={(e) => setLink(e.target.value)}
          onBlur={(e) => {
            e.preventDefault();
            onBlur(e.target.value, 'link');
          }}
          className='form-control form-control-xs mt-2'
        />
        <div className='mt-2'>{t('sheet.formatter.field.link_display')}</div>
        <input
          type='text'
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          defaultValue={originalFieldLabel}
          onBlur={(e) => {
            e.preventDefault();
            onBlur(e.target.value, 'label');
          }}
          className='form-control form-control-xs mt-2'
        />
      </div>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <DropdownPopover renderPopover={popover}>
        <div
          className={clsx(
            'w-100 border-0 p-0 position-relative button-dropdown-link',
            containerClass
          )}
          variant='none'
        >
          <div onClick={handleOpenLink}>
            {isDetailViewMode && !originalFieldLabel && !originalFieldValue ? (
              <span className='ps-1 text-gray-500 w-100'>{column?.placeholder || ''}</span>
            ) : (
              <span className='text-primary link-value link-limit-text-1 h-25px'>
                {originalFieldLabel || originalFieldValue}
              </span>
            )}
          </div>
          {originalFieldValue && (
            <div className='icon-remove-link' onClick={handleRemoveLink}>
              <i className='la la-times' />
            </div>
          )}
        </div>
      </DropdownPopover>
    );
  }

  return (
    <div
      className={clsx('w-100 border-0 p-0 position-relative button-dropdown-link', containerClass)}
    >
      <div onClick={handleOpenLink} className='text-primary link-value link-limit-text-1 h-25px'>
        {originalFieldLabel || originalFieldValue}
      </div>
    </div>
  );
}

export default LinkField;
