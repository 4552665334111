import React from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import {cloneDeep, upperFirst} from 'lodash';
import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';

const SheetViewPermitted = ({
  sheetMembers,
  permittedUsers = [],
  setPermittedUsers,
  isPermitted,
  setIsPermitted,
}) => {
  const {t} = useTranslate();

  const memberOptions = sheetMembers? sheetMembers.flatMap((member) => {
    return {
      label: upperFirst(member.name),
      value: member.id,
    };
  })
  :[];

  return (
    <div className='border border-secondary rounded p-2 mt-7 mb-20'>
      <div
        className='offcanvas-title h5 bg-body'
        style={{marginTop: '-20px', width: 'fit-content'}}
      >
        {t('sheet_view_permission')}
      </div>

      <div className='form-group row mb-2 '>
        <label className='checkbox checkbox-lg checkbox-single me-5 d-flex align-items-center mt-5'>
          <input
            type='checkbox'
            id={`checkbox-permission-limit-can-view`}
            name='sheet_view_permission_limit_can_view'
            checked={isPermitted}
            onChange={() => setIsPermitted(!isPermitted)}
            className='h-15px me-1'
          />
          <span htmlFor={`checkbox-permission-limit-can-view`}>
            {t('sheet_view_permission_limit_can_view_mode')}
          </span>
        </label>
      </div>
      {isPermitted && (
        <div className='mt-5'>
          <SelectSearch
            id='permission_peopple_can_view'
            name='permission_peopple_can_view'
            label={t('sheet_view_permission_people_can_view')}
            placeholder={t('sheet_view_permission_choose_people')}
            isMulti
            options={memberOptions}
            value={memberOptions?.filter((member) => permittedUsers.includes(member?.value))}
            className={'mt-5'}
            onChange={(items) => {
              const newPermittedUsers = items.map((item) => item.value);
              setPermittedUsers(newPermittedUsers);
            }}
          ></SelectSearch>
        </div>
      )}
    </div>
  );
};

export default SheetViewPermitted;
