import { effectiveValue } from 'app/modules/work/helpers';

function FormulaField({value, item, column, sheet, onChange, fieldEditable, readOnly, containerClass}) {
  const effValue = effectiveValue(value);

  return (
    <div className='w-100 text-truncate d-flex justify-content-center align-items-center' style={{...column?.styles, flex: 1}}>
      {effValue}
    </div>
  );
}

export default FormulaField;
