import './_grid.scss';

import {DndProvider, useDrag, useDrop} from 'react-dnd';
import React, {useMemo, useState} from 'react';

import {HTML5Backend} from 'react-dnd-html5-backend';
import {isFunction} from 'lodash';
import {useTable} from 'react-table';

export function OGridViewV2({
  columns,
  data,
  classes,
  keyField,
  selectedKeys,
  highlightKeys,
  dragDrop = false,
  moveRow,
  expandRow,
  rowIndicatorStyle,
  showFooter = true,
  ...props
}) {
  // Render the UI for your table
  return (
    <DndProvider backend={HTML5Backend}>
      <DropGrid
        columns={columns}
        data={data}
        classes={classes}
        selectedKeys={selectedKeys}
        highlightKeys={highlightKeys}
        keyField={keyField}
        dragDrop={dragDrop}
        moveRow={moveRow}
        expandRow={expandRow}
        rowIndicatorStyle={rowIndicatorStyle}
        showFooter={showFooter}
        {...props}
      />
    </DndProvider>
  );
}

const DND_ITEM_TYPE = 'row';

const DropGrid = ({
  columns,
  data,
  classes,
  keyField,
  selectedKeys,
  highlightKeys,
  dragDrop = false,
  moveRow,
  expandRow,
  rowIndicatorStyle,
  showFooter,
  noDataText,
  ...props
}) => {
  const dropRef = React.useRef(null);
  const getRowId = React.useCallback((row) => {
    return row[keyField];
  }, []);
  const [expandedRows, setExpandedRows] = useState([]);

  const onToggleSubitem = (id) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(id)) {
      newExpandedRows = newExpandedRows.filter((expandedRow) => expandedRow !== id);
    } else {
      newExpandedRows.push(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const isExpanded = (id) => {
    return expandedRows.includes(id);
  };

  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
      getRowId,
    }
  );

  const tables = useMemo(() => {
    return document.querySelectorAll('div[role="table"]');
  }, [data]);

  const cellProps = (columnIndex, selected) => {
    return {
      'data-col-idx': columnIndex,
      onMouseEnter: () => {
        if (selected) {
          tables.forEach((table) => {
            table?.classList.add(`active-col-${columnIndex}`);
          });
        }
      },
      onMouseLeave: () => {
        if (selected) {
          tables.forEach((table) => {
            table?.classList.remove(`active-col-${columnIndex}`);
          });
        }
      },
    };
  };

  return (
    <div
      role='table'
      {...getTableProps()}
      className={`grid-table ${classes ? classes : ''}`}
      {...props}
      ref={dropRef}
    >
      <div className='grid-thead'>
        {headerGroups.map((headerGroup, hgIndex) => (
          <div
            className='d-flex justify-content-start grid-thead-group'
            key={hgIndex}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, columnIndex) => (
              <div
                key={columnIndex}
                {...column.getHeaderProps()}
                className={`grid-th ${column.headerClasses ? column.headerClasses : ''}`}
                style={column.headerStyle}
              >
                {column.headerFormatter
                  ? column.headerFormatter(column, columnIndex, {})
                  : column.render('header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()} className='grid-tbody'>
        {rows.map((row, index) => {
          return (
            prepareRow(row) || (
              <Row
                key={row?._id ?? index}
                index={index}
                row={row}
                selected={selectedKeys?.includes(row.id)}
                highlighted={highlightKeys?.includes(row.id)}
                dragDrop={dragDrop}
                cellProps={cellProps}
                expandRow={expandRow}
                indicatorStyle={rowIndicatorStyle}
                {...row.getRowProps()}
                moveRow={moveRow}
                onToggleSubitem={onToggleSubitem}
                isExpanded={isExpanded}
              />
            )
          );
        })}
        {!rows?.length && noDataText && (
          <div>
            <div className='grid-tr d-flex align-items-center justify-content-center py-3'>
              <span className='text-gray-500'>{noDataText}</span>
            </div>
            <div className='grid-tr d-none'></div>
          </div>
        )}
      </div>
      {showFooter && (
        <div className='grid-tfoot foot-sheet-table-view'>
          {footerGroups.map((group, gIndex) => (
            <div className='d-flex grid-tr' key={gIndex} {...group.getFooterGroupProps()}>
              {group.headers.map((column, index) => {
                if (index === 0) {
                  return (
                    <div
                      key={index}
                      className={`grid-td ${column.headerClasses ? column.headerClasses : ''}  `}
                      style={column.headerStyle}
                    ></div>
                  );
                }
                return column.footerFormatter ? (
                  <div
                    className={`grid-td ${column.headerClasses ? column.headerClasses : ''}  `}
                    style={column.headerStyle}
                    key={index}
                    {...column.getFooterProps()}
                  >
                    {column.footerFormatter && column.footerFormatter()}
                  </div>
                ) : (
                  <div
                    className={`grid-td ${column.headerClasses ? column.headerClasses : ''}  `}
                    style={column.headerStyle}
                    key={index}
                  ></div>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Row = ({
  row,
  index,
  selected,
  highlighted,
  dragDrop = false,
  cellProps,
  expandRow,
  indicatorStyle,
  moveRow,
  onToggleSubitem,
  isExpanded,
}) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {},
    drop(item, monitor) {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const toIndex = index;

      // Don't replace items with themselves
      if (dragIndex === toIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < toIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > toIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = toIndex;

      moveRow && moveRow(item, dragIndex, toIndex);
    },
  });

  const [{isDragging}, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: () => ({index, ...row.original}),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <>
      <div
        ref={dropRef}
        className={`grid-tr d-flex ${selected ? 'selected-row' : ''} ${
          highlighted ? 'selected-row' : ''
        } ${'item_' + row.id}`}
        style={{opacity}}
      >
        {row.cells.map((cell, cellIndex) => {
          const cellStyle = cell.column.style ? cell.column.style : null;
          const cellEditable = isFunction(cell.column.userItemEditable)
            ? cell.column.userItemEditable(cell.value, row.original, row.index)
            : cell.column.userItemEditable;
          return (
            <div
              {...cell.getCellProps()}
              {...cellProps(cellIndex, selected)}
              style={cellStyle}
              className={`grid-td ${cell.column.classes ? cell.column.classes : ''}`}
              ref={cellIndex === 0 ? dragRef : null}
            >
              {cell.column.formatter
                ? cell.column.formatter(
                    cell.value,
                    row.original,
                    row.index,
                    onToggleSubitem,
                    cell.column.formatExtraData,
                    cellEditable,
                    cellStyle
                  )
                : cell.value}
            </div>
          );
        })}
      </div>
      {expandRow && (
        <div
          key={row.original._id + '-subitems-container'}
          id={row.original._id + '-subitems-container'}
          style={{display: 'none'}}
          className='grid-sub-tr'
        >
          {expandRow?.renderer(row.original, expandRow.settings, isExpanded)}
        </div>
      )}
    </>
  );
};
