import './widgetEditView.scss';

import {Card, CardBody} from 'app/common/_partials/controls';
import React, {useState} from 'react';
import {cloneDeep, get, isArray, set} from 'lodash';

import {ChartTypeSelect} from './components/ChartTypeSelect';
import {ChartWidthSelect} from './components/ChartWidthSelect';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';
import WidgetEditMetricView from './WidgetEditMetricView';
import WidgetEditViewInnerGantt from './WidgetEditViewInnerGantt';
import WidgetEditViewInnerNumber from './WidgetEditViewInnerNumber';
import WidgetEditViewInnerPivot from './WidgetEditViewInnerPivot';
import WidgetView from '../WidgetView';
import {useTranslate} from 'core/i18n/i18nProvider';

export default function WidgetEditView({
  sheet,
  sheetId,
  subSheet,
  widget,
  previewItems,
  itemsPaging,
  paging,
  className,
  onUpdate,
  onDelete,
  onUpdateItem,
  subItems,
  setInnerSheetId,
  connectedSheetsOptions,
}) {
  const {t} = useTranslate();
  const [showDelete, setShowDelete] = useState(false);
  const [editWidget, setEditWidget] = useState(cloneDeep(widget));

  const handleChange = (field, value) => {
    let _editWidget = {...editWidget};
    set(_editWidget, field, value);
    if (field === 'chart.type' && value === 'gantt') {
      const columnDefault = sheet.columns.find(
        (column) => column.type === 'date' || column.type === 'timeline'
      );
      const dimensions = [
        {column: columnDefault.field, type: columnDefault.type, name: columnDefault.name},
      ];
      set(_editWidget, 'data.dimensions', dimensions);
    }
    setEditWidget(_editWidget);
  };

  const handleDimensionChange = (params, editWidgetDimensionPath = 'data.dimensions') => {
    let _editWidget = {...editWidget};
    if (!params) {
      set(_editWidget, editWidgetDimensionPath, []);
    } else {
      const newDimensions = isArray(params)
        ? params.map((dimension) => dimension.dimension)
        : [params.dimension];

      set(_editWidget, editWidgetDimensionPath, newDimensions);
    }

    setEditWidget(_editWidget);
  };

  const handleSaveWidget = () => {
    onUpdate && onUpdate(editWidget);
  };

  const handleDeleteWidget = () => {
    onDelete && onDelete(editWidget);
  };

  const isUseCountItem =
    editWidget?.data?.metrics[0]?.aggregation === 'count_item' &&
    editWidget.chart.type === 'number';

  const isUseCount =
    editWidget?.data?.metrics[0]?.aggregation === 'count' && editWidget.chart.type === 'number';

  const columnOptions = (sheet) => {
    if (editWidget.chart.type === 'gantt') {
      return sheet?.columns
        ?.filter(
          (column) => !column?.deleted && (column.type === 'timeline' || column.type === 'date')
        )
        ?.map((column) => ({
          label: column.name,
          value: column.field,
          dimension: {
            name: column.name,
            column: column.field,
            type: column.type,
          },
        }));
    } else if (editWidget.chart.type === 'number') {
      return sheet?.columns
        ?.filter((column) => !column?.deleted && (isUseCount || column.type === 'number'))
        ?.map((column) => ({
          label: column.name,
          value: column.field,
          dimension: {
            name: column.name,
            column: column.field,
            type: column.type,
          },
        }));
    } else if (editWidget.chart.type === 'battery') {
      const colorFields = ['status', 'color', 'priority'];
      return sheet?.columns
        ?.filter((column) => colorFields.includes(column?.type))
        ?.map((column) => ({
          label: column.name,
          value: column.field,
          dimension: {
            name: column.name,
            column: column.field,
            type: column.type,
          },
        }));
    } else {
      return sheet?.columns
        ?.filter((column) => !column?.deleted && column.type !== 'timeline')
        ?.map((column) => ({
          label: column.name,
          value: column.field,
          dimension: {
            name: column.name,
            column: column.field,
            type: column.type,
          },
        }));
    }
  };

  const getInitColumnValue = (editWidgetDimensionPath = 'data.dimensions') => {
    const dimensions = get(editWidget, editWidgetDimensionPath, []);
    if (editWidget.chart.type === 'number') {
      return dimensions
        ?.filter((dimension) => isUseCount || dimension.type === 'number')
        ?.map((dimension) => {
          return {
            label: dimension.name,
            value: dimension.column,
            dimension: dimension,
          };
        });
    } else {
      return dimensions.map((dimension) => {
        return {
          label: dimension?.name,
          value: dimension?.column,
          dimension: dimension,
        };
      });
    }
  };

  const getSortOptions = () => {
    const dimensions = get(editWidget, 'data.dimensions', []);
    return dimensions.map((dimension) => ({
      label: dimension?.name,
      value: dimension?.column,
    }));
  };

  const isMultiDimension = () => {
    return editWidget.chart.type !== 'battery' && editWidget.chart.type !== 'pie';
  };

  const onChangeWidgetSheet = (selectedSource) => {
    setInnerSheetId(selectedSource.value);
    handleChange('sheetId', selectedSource.value);
  };

  return (
    <>
      <Card className='mt-4 w-800px shadow-none widget-edit-view rounded-0 m-0'>
        <div className='card-header'>
          <h3 className='card-title font-weight-bolder text-dark'>{widget?.title || ''}</h3>
          <div className='card-header-toolbar d-flex align-items-center'>
            <button className='btn btn-outline-danger me-2' onClick={() => setShowDelete(true)}>
              {t('common_delete')}
            </button>
            <button className='btn btn-primary' onClick={handleSaveWidget}>
              {t('common_save')}
            </button>
          </div>
        </div>
        <CardBody>
          <Card className='border mb-4'>
            <CardBody className='p-4'>
              <ChartWidthSelect
                value={editWidget?.chart?.width || 6}
                onChange={(width) => handleChange('chart.width', width)}
              ></ChartWidthSelect>
            </CardBody>
          </Card>

          <Card className='border mb-4'>
            <CardBody className='p-4'>
              <ChartTypeSelect
                value={editWidget?.chart?.type}
                onChange={(type) => handleChange('chart.type', type)}
              ></ChartTypeSelect>
            </CardBody>
          </Card>

          {connectedSheetsOptions?.length ? (
            <Card className='border mb-4'>
              <CardBody className='p-4'>
                <div className='mb-2 fw-bold'>{t('sheet_view_connected_dashboard_select')}</div>
                <SelectSearch
                  isMulti={false}
                  options={connectedSheetsOptions}
                  value={{label: sheet?.name, value: sheetId}}
                  onChange={onChangeWidgetSheet}
                  placeholder={t('sheet_view_connected_dashboard_select')}
                ></SelectSearch>
              </CardBody>
            </Card>
          ) : null}

          {editWidget.chart.type === 'pivot' && (
            <WidgetEditViewInnerPivot
              sheet={sheet}
              editWidget={editWidget}
              setEditWidget={setEditWidget}
            />
          )}
          {editWidget.chart.type === 'gantt' && (
            <WidgetEditViewInnerGantt
              editWidget={editWidget}
              setEditWidget={setEditWidget}
              handleChange={handleChange}
            />
          )}

          {editWidget.chart.type === 'number' && (
            <WidgetEditViewInnerNumber editWidget={editWidget} setEditWidget={setEditWidget} />
          )}

          {editWidget.chart.type !== 'gantt' &&
            editWidget.chart.type !== 'sheet_table' &&
            editWidget.chart.type !== 'number' && (
              <Card className='border mb-4'>
                <CardBody className='p-4'>
                  <div className='mb-2 fw-bold'>{t('sheet_view_dashboard_metric')}</div>
                  <WidgetEditMetricView
                    editWidget={editWidget}
                    sheet={sheet}
                    setEditWidget={setEditWidget}
                  />
                </CardBody>
              </Card>
            )}

          {!isUseCountItem && editWidget.chart.type !== 'sheet_table' && (
            <>
              <Card className='border mb-4'>
                <CardBody className='p-4'>
                  <div className='mb-4'>
                    <div className='mb-2 fw-bold'>{t('sheet_view_dashboard_column')}</div>
                    <SelectSearch
                      isMulti={isMultiDimension()}
                      options={columnOptions(sheet)}
                      value={getInitColumnValue('data.dimensions')}
                      onChange={(param) => handleDimensionChange(param, 'data.dimensions')}
                      placeholder={t('sheet_view_dashboard_column')}
                      isClearable
                    ></SelectSearch>
                  </div>
                  {editWidget.chart.type === 'battery' && (
                    <div className='mb-4'>
                      <SelectSearch
                        options={columnOptions(subSheet)}
                        value={getInitColumnValue('data.subitem_dimensions')}
                        onChange={(param) =>
                          handleDimensionChange(param, 'data.subitem_dimensions')
                        }
                        label={t('sheet_view_dashboard_sub_item_column')}
                        placeholder={t('sheet_view_dashboard_sub_item_column')}
                        isClearable
                      ></SelectSearch>
                    </div>
                  )}
                </CardBody>
              </Card>
              {editWidget.chart.type === 'column' && (
                <Card className='border mb-4'>
                  <CardBody className='p-4'>
                    <div className='mb-2 fw-bold'>{t('sheet_view_dashboard_sort_column')}</div>
                    <div className='d-flex flex-row mb-2 mb-4 gap-2'>
                      <div className='flex-grow-1'>
                        <SelectSearch
                          isMulti
                          options={getSortOptions()}
                          value={get(editWidget, 'data.sorts')}
                          onChange={(param) => handleChange('data.sorts', param)}
                          placeholder={t('sheet_view_dashboard_sort_column')}
                          isClearable
                        ></SelectSearch>
                      </div>
                      <div className='flex-grow-1'>
                        <SelectSearch
                          options={[
                            {label: t('sheet_view_dashboard_sort_ascending'), value: 'asc'},
                            {label: t('sheet_view_dashboard_sort_descending'), value: 'desc'},
                          ]}
                          onChange={(param) => handleChange('data.sort_type', param)}
                          value={get(editWidget, 'data.sort_type')}
                          placeholder={t('sheet_view_dashboard_sort_type')}
                          isClearable
                        ></SelectSearch>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>
          )}
          <div>
            <WidgetView
              prefix='preview'
              mode='view'
              widget={editWidget}
              items={previewItems}
              itemsPaging={itemsPaging}
              paging={paging}
              subItems={subItems}
              sheet={sheet}
              subSheet={subSheet}
              onUpdateItem={onUpdateItem}
            ></WidgetView>
          </div>
        </CardBody>
      </Card>

      <ModalConfirm
        style={{zIndex: 2000}}
        show={showDelete}
        onHide={() => setShowDelete(false)}
        title={t('sheet_view_dashboard_delete_widget_title')}
        content={t('sheet_view_dashboard_delete_widget_message')}
        handleExcute={handleDeleteWidget}
      />
    </>
  );
}
