import React from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import './selectSearch.scss'

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0.42rem',
    minHeight: 'calc(1.5em + 1.3rem + 2px)',
  }),
};

export function AsyncSelectSearch({label, loadOptions, notifyChange, defaultOptions, ...props}) {
  return (
    <>
      {label && <label className='text-dark'>{label}</label>}
      <AsyncSelect
        cacheOptions
        classNamePrefix='select'
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        {...props}
        styles={customStyles}
      />
    </>
  );
}

export function SelectSearch({label, options, placeholder, ...props}) {
  return (
    <>
      {label && <label className='text-dark'>{label}</label>}
      <Select
        cacheOptions
        classNamePrefix='select'
        options={options}
        placeholder={placeholder}
        {...props}
        styles={customStyles}
      />
    </>
  );
}
