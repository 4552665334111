import * as requestFromServer from './api';
import {workSlice, callTypes} from './slice';

const {actions} = workSlice;



export const fetchWorkspacesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getWorkspaces(params)
    .then((res) => {
      const {data} = res
      dispatch(actions.workspacesFetched({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet"
    })
}


export const selectWorkspaceAction = (workspace) => (dispatch) => {
  dispatch(actions.workspaceSelected({workspace}))
}
export const addWorkspaceAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addWorkspace(params)
    .catch((error) => {
      error.clientMessage = "Can't add workspace"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    })
}

export const updateWorkspaceAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateWorkspace(params, id)
    .catch((error) => {
      error.clientMessage = "Can't update workspace";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteWorkspaceAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deleteWorkspace(id)
    .catch((error) => {
      error.clientMessage = "Can't delete workspace";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const moveToWorkspaceAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .moveToWorkspace(params)
    .catch((error) => {
      error.clientMessage = "Can't move to workpace";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
}


export const deletePageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deletePage(params)
    .catch((error) => {
      error.clientMessage = "Can't delete page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updatePageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updatePage(params)
    .catch((error) => {
      error.clientMessage = "Can't update page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const saveAsTemplateAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .saveAsTemplate(params)
    .catch((error) => {
      error.clientMessage = "Can't save as template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const fetchPagesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getPages(params)
    .then((res) => {
      const {data} = res;
      dispatch(actions.currentWorkspacesPagesFetched({ data: data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet";
    });
};

export const setEmptyPagesAction = () => (dispatch) => {
  dispatch(actions.currentWorkspacesPagesFetched({data: {allPages: [], userPages: []}}));
};

export const movePageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .movePage(params)
    .catch((error) => {
      error.clientMessage = "Can't move page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const addPageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addPage(params)
    .catch((error) => {
      error.clientMessage = "Can't add sheet";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const addDocAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addDoc(params)
    .catch((error) => {
      error.clientMessage = "Can't add doc";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const duplicatePageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .duplicatePage(params)
    .catch((error) => {
      error.clientMessage = "Can't duplicate page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const getTemplatesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getTemplates(params)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't get templates";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};



export const getPageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getPage(params)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't get page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const getUserSheetPermissionAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getUserSheetPermissions(params)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't get page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const getUserAction = (params) => {
  return requestFromServer.getUsers(params);
};


export const removeTemplateAction = (templateId) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .removeTemplate(templateId)
    .catch((error) => {
      error.clientMessage = "Can't remove template";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const getFormDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getFormDetail(id).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const getFromByShareKeyAction = (key, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getFromByShareKey(key, params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const updateFormAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateForm(id, params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};