import * as requestFromServer from './api';
import {docSlice, callTypes} from './slice';

const {actions} = docSlice;

export const getPageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getPage(params)
    .catch((error) => {
      error.clientMessage = "Can't get page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updatePageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updatePage(params)
    .catch((error) => {
      error.clientMessage = "Can't update page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getDocumentObjectsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getDocumentObjects(params)
    .then((response) => {
      if (response.data.data) {
        return response.data.data;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't get page";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const updateObjectAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateDocumentObject(params)
    .catch((error) => {
      error.clientMessage = "Can't update page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const createObjectAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .createDocumentObject(params)
    .catch((error) => {
      error.clientMessage = "Can't update page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteObjectAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .deleteDocumentObject(params)
    .catch((error) => {
      error.clientMessage = "Can't delete page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const callAiAssistantAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .callAiAssistant(params)
    .catch((error) => {
      error.clientMessage = "Can't get page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const uploadFileAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .uploadFile(params)
    .then((response) => {
      if (response.data.result) {
        return response.data.result;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't upload file";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const downloadLinkFileAction = (fileId, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .downloadLinkFile(fileId, params)
    .catch((error) => {
      error.clientMessage = "Can't get link file";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getDocumentAuditsAction = (documentId, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getDocumentAudits(documentId, params)
    .then((response) => {
      return response?.data;
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};
