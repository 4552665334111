import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useSheetPermission} from '../../../SheetPermissionHook';
import ModalAddNewItem from './modal/ModalAddNewItem';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useRef} from 'react';
import {set} from 'lodash';
import {useSession} from 'core/store/hooks';
import {validationErrorCode} from '../../../validation/constants';
import {useValidation} from '../../../validation/useValidation';
import {useDispatch} from 'react-redux';
import {addSheetItemAction} from '../../../stores/sheetActions';

function ItemAddNewStandaloneView({
  sheet,
  view,
  configurable,
  show,
  onHide,
  sheetGroups,
  onSaveSuccess,
}) {
  const {t} = useTranslate();
  const {user} = useSession();
  const dispatch = useDispatch();
  const {userSheetPermissions} = useSheetPermission({sheetId: sheet?._id});
  const snackNotification = useSnackNotification();
  const {getValidationErrorMessage} = useValidation({});
  const isItemBeingAdded = useRef(false);

  const generateItemParams = (item, user) => {
    const newItemParams = {
      sheetId: sheet?._id,
      title: {value: item.title},
      group: item.group ?? null,
    };

    sheet.columns.forEach((column) => {
      if (column.default) {
        const defaultValue = column.default;
        if (column.type === 'date' && defaultValue === 'today') {
          set(newItemParams, column.field, {value: new Date().getTime() / 1000});
        }
        if (column.type === 'people') {
          if (defaultValue === 'current_users') {
            set(newItemParams, column.field, {
              value: [{name: user.name, id: user._id, _id: user._id}],
            });
          }
          if (defaultValue === 'default_users') {
            set(newItemParams, column.field, {value: column.default_users});
          }
        }
        set(newItemParams, column.field, {value: column.default});
      }
    });

    for (const column of sheet.columns) {
      if (item[column.field]) {
        newItemParams[column.field] = {
          value: item[column.field].value ?? null,
          label: item[column.field].label ?? null,
        };
      }
    }

    return newItemParams;
  };

  const handleSubmitNewItem = (newItem) => {
   if (!isItemBeingAdded.current) {
      const itemParams = generateItemParams(
        {...newItem, group: newItem?.group?.value ?? 'default'},
        user
      );
      isItemBeingAdded.current = true;

      dispatch(addSheetItemAction(itemParams)).then((result) => {
        isItemBeingAdded.current = false;
        if (result?.data?.success) {
          onHide();
          onSaveSuccess(result?.data?.data);
        } else {
          snackNotification.showError(
            result?.message ? getValidationErrorMessage(result?.message) : t('common_update_error')
          );
        }
      });
    }
  };

  return (
    <div>
      <ModalAddNewItem
        sheet={sheet}
        view={view}
        sheetItemPermissions={userSheetPermissions}
        currentView={view}
        sheetGroups={sheetGroups}
        show={show}
        configurable={configurable}
        onSave={handleSubmitNewItem}
        onHide={onHide}
      />
    </div>
  );
}

export default ItemAddNewStandaloneView;
