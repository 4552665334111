import React, {useMemo, useRef, useState} from 'react';
import {useSession} from '../../../../../../../../core/store/core/hooks';
import {useDrag, useDrop} from 'react-dnd';
import {get} from 'lodash';
import {ItemCard} from '../item/ItemCard';
import KanbanItemNew from '../item/KanbanItemNew';

const DND_ITEM_TYPE = 'item';
const DND_COLUMN_TYPE = 'column';
const KanbanColumn = ({
  sheet,
  permissions = {},
  column,
  columnValue,
  columnIndex,
  items,
  displayFields,
  onSaveItem,
  onAddItem,
  onShowItemDetail,
  moveColumn,
  saveColumn,
  itemSelected,
  configurable,
  editable,
  onAction,
}) => {
  const dragRef = useRef(null);
  const {user} = useSession();
  const [{handlerId}, dropColumn] = useDrop(
    {
      accept: DND_COLUMN_TYPE,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item, monitor) {
        if (!dragRef.current) {
          return;
        }
        const dragIndex = item.columnIndex;
        const hoverIndex = columnIndex;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientX = clientOffset.x - hoverBoundingRect.left;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
        // Time to actually perform the action
        moveColumn(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.columnIndex = hoverIndex;
      },
      drop() {
        saveColumn();
      },
    },
    [moveColumn, saveColumn]
  );

  const [{isDragging}, dragColumn, preview] = useDrag({
    type: DND_COLUMN_TYPE,
    item: () => {
      return {columnValue, columnIndex};
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  dragColumn(dropColumn(dragRef));

  // Drop item
  const [props, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPE,
      hover() {
        props.backgroundColor = '#FFFFFF';
      },
      canDrop(item) {
        return get(item, 'item.' + column.field + '.value') !== columnValue?.value;
      },
      drop(item) {
        onSaveItem(item.item, column.field, '', {
          value: columnValue.value,
          label: columnValue.label,
        });
      },
    }),
    [onSaveItem]
  );

  const [showAddNew, setShowAddNew] = useState(false);
  const handleAddNew = (title, callback) => {
    let newItem = {
      title: title,
      user,
    };
    newItem[column.field] = {
      value: columnValue.value,
      label: columnValue.label,
    };

    onAddItem &&
      onAddItem(newItem, () => {
        setShowAddNew(false);
        callback && callback();
      });
  };

  const isPermitted = useMemo(() => {
    if (column?.is_permitted) {
      const isEdit = column?.permitted_users?.some(
        (permittedUser) => permittedUser?.id === user?._id
      );
      if (isEdit) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }, [column, user?._id]);

  return (
    <div
      className='kanban-column me-4 d-flex flex-column bg-light rounded-3'
      ref={preview}
      style={{opacity}}
      data-handler-id={handlerId}
    >
      <div className='cursor-pointer' ref={dragRef}>
        <div className='d-flex flex-stack p-2'>
          <div className='fw-bold fs-4'>
            {columnValue.label}
            <span className='fs-6 text-gray-400 ms-2'>{items?.length}</span>
          </div>

          {editable && (
            <div>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                onClick={() => setShowAddNew(true)}
              >
                <i className='ki ki-plus fs-8'></i>
              </button>
            </div>
          )}
        </div>

        <div
          className='h-3px w-100'
          style={{backgroundColor: `${columnValue.backgroundColor}`}}
        ></div>
      </div>
      <div
        ref={drop}
        className='flex-grow-1 px-2 py-3 flex-fill'
        style={{minHeight: '200px', overflow: 'auto'}}
      >
        {showAddNew && (
          <KanbanItemNew addNewItem={handleAddNew} onCancel={() => setShowAddNew(false)} />
        )}
        {items?.map((item) => (
          <ItemCard
            item={item}
            sheet={sheet}
            permissions={permissions}
            displayFields={displayFields}
            onChange={onSaveItem}
            key={item._id}
            onShowDetail={onShowItemDetail}
            itemSelected={itemSelected}
            isPermitted={editable && isPermitted}
            configurable={configurable}
            onAction={onAction}
          />
        ))}
      </div>
    </div>
  );
};

export default KanbanColumn;
