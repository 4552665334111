import {transformListToTree} from 'app/common/_helpers/FunctionHelper';
import {useTranslate} from 'core/i18n/i18nProvider';
import {isEmpty} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import TreeMenu from 'react-simple-tree-menu';
import clsx from 'clsx';
import './modalMoveItemToSheet.scss';

function ModalMoveItemToSheet({title, show, onHide, listItemSelect, onMoveItems}) {
  const {t} = useTranslate();
  const [sheet, setSheet] = useState({});
  const [tree, setTree] = useState([]);

  const convertNodeData = (node) => {
    if (node == null) {
      return null;
    }

    var convertedNode = {
      key: '' + node._id,
      label: node.name,
      parent: node.parent ? node.parent : null,
      nodes: [],
      node: node,
    };

    if (node.children && node.children.length > 0) {
      node.children.forEach((element) => {
        convertedNode.nodes.push(convertNodeData(element));
      });
    }
    if (convertedNode.nodes.length === 0) {
      delete convertedNode.nodes;
    }
    return convertedNode;
  };

  useEffect(() => {
    const treeData = transformListToTree(listItemSelect, 'parent');
    if (treeData && treeData.length > 0) {
      const convertedTreeData = [];
      treeData.forEach((categoryItem) => {
        if (categoryItem._id) {
          convertedTreeData.push(convertNodeData(categoryItem));
        }
      });
      setTree(convertedTreeData);
    }
  }, [listItemSelect]);

  const handleClickNode = (node) => {
    setSheet(node);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <div>
          {sheet.name ? (
            <div className='d-flex justify-content-between'>
              <div className='d-flex align-items-center fs-5 fw-bold'>
                <i className='icon-sm fas fa-list-alt me-2'></i> <span>{sheet.name}</span>
              </div>
              <div className='cursor-pointer' onClick={() => setSheet({})}>
                {t('sheet_move_dialog_move_to_sheet_cancel')}
              </div>
            </div>
          ) : (
            <span className='fs-5 fw-bold'>{title}</span>
          )}
        </div>
        <div className='d-flex flex-column mt-4'>
          {isEmpty(sheet) ? (
            <>
              <TreeMenu data={tree}>
                {({search, items}) => {
                  return (
                    <>
                      {items.map(({key, ...props}) => {
                        if (props?.node?.type === 'sheet' || props?.node?.type === 'page') {
                          return <ListItem key={key} {...props} onClickNode={handleClickNode} />;
                        } else {
                          return <React.Fragment key={key} />;
                        }
                      })}
                    </>
                  );
                }}
              </TreeMenu>
            </>
          ) : (
            <>
              {sheet?.groups?.map((group) => {
                return (
                  <div
                    key={group.id}
                    className='d-flex align-items-center cursor-pointer bg-hover-light p-2 rounded'
                    onClick={() => onMoveItems(group, sheet)}
                  >
                    <div className='bg-secondary rounded-circle w-20px h-20px d-flex align-items-center justify-content-center me-2 '>
                      <i className='fa-solid fa-angle-right'></i>
                    </div>
                    <div>{group.title}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const ListItem = ({
  level = 0,
  hasNodes,
  isOpen,
  label,
  searchTerm,
  openNodes,
  toggleNode,
  matchSearch,
  focused,
  handleToggleOpenNode,
  keyOpen,
  activeRootPath,
  onClickNode,
  ...props
}) => {
  const LEVEL_SPACE_CATEGORY = 17;

  const getPaddingLeft = () => {
    if (level !== 0) {
      return level * LEVEL_SPACE_CATEGORY + LEVEL_SPACE_CATEGORY;
    }
    return LEVEL_SPACE_CATEGORY;
  };

  return (
    <div
      {...props}
      className={`pt-3 pb-3 cursor-pointer position-relative rounded category ${clsx({
        'bg-hover-secondary': !hasNodes,
      })}`}
      style={{
        paddingLeft: `${getPaddingLeft()}px`,
      }}
    >
      {hasNodes ? (
        <>
          <div
            className='d-inline position-relative h-5px'
            onClick={(e) => {
              toggleNode();
              e.stopPropagation();
            }}
          >
            <ToggleIcon on={isOpen} />
          </div>
          <i className='las la-folder fs-3'></i>{' '}
          <span
            onClick={(e) => {
              toggleNode();
              e.stopPropagation();
            }}
          >
            {label}
          </span>
        </>
      ) : (
        <div className='d-flex align-items-center' onClick={() => onClickNode(props.node)}>
          <i className='las la-columns fs-3'></i> <span className='ms-1'>{label}</span>
        </div>
      )}
    </div>
  );
};

const ToggleIcon = ({on}) => (
  <span className='mr-2 position-absolute icon-toggle-menu'>
    {on ? (
      <span className='svg-icon menu-icon d-inline-block w-10px'>
        <i className='fas fa-caret-down text-dark'></i>
      </span>
    ) : (
      <span className='svg-icon menu-icon d-inline-block w-10px'>
        <i className='fas fa-caret-right text-dark'></i>
      </span>
    )}
  </span>
);

export default ModalMoveItemToSheet;
