import '../sheetEditColumn.scss';

import {CheckBoxField, Input, SelectField, Textarea} from 'app/common/_partials/controls';
import {Field, FieldArray, Formik} from 'formik';
import {cloneDeep, isEmpty} from 'lodash';
import {useEffect, useState} from 'react';

import {DropdownField} from 'app/common/_partials/controls/forms/DropdownField';
import {SelectSearchField} from 'app/common/_partials/controls/forms/SelectSearchField';
import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';
import ItemField from 'app/modules/work/sheet/component/field/ItemField';
import {CommonFieldEditor} from 'app/modules/work/sheet/component/sheet-config/column/edit/CommonFieldEditor';
import CustomAIPrompt from 'app/modules/work/sheet/component/sheet-config/column/edit/CustomAIPrompt';
import ConfigLinkedColumn from 'app/modules/work/sheet/component/sheet-config/column/edit/linked/ConfigLinkedColumn';
import ApprovalColumnConfig from 'app/modules/work/sheet/component/view/table/columnEdit/ApprovalColumnConfig';
import {backgroundColorOptions} from 'app/modules/work/sheet/component/view/table/contains';
import {getTranslationTextByField} from 'app/modules/work/sheet/component/view/table/helper';
import {getMySheetListBasicAction} from 'app/modules/work/sheet/stores/sheetActions';
import clsx from 'clsx';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Form, Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import ConfigAutoNumberColumn from '../shared/ConfigAutoNumberColumn';
import * as Yup from 'yup';

const ColumnAddSchema = Yup.object().shape({
  settings: Yup.object().shape({
    initial_number_value: Yup.number()
      .integer('sheet_column_type_auto_number_initial_number_value_error')
      .optional(),
  }),
});

export default function AddColumnDialog({column, onSubmit, show, onHide, sheet, linkableSheets}) {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const [referenceSheets, setReferenceSheets] = useState([]);
  const [columnEdit, setColumnEdit] = useState(column);
  const [error, setError] = useState({});

  const handleSelectUser = (item, column, value, newPeople) => {
    const cloneColumnEdit = cloneDeep(columnEdit);
    cloneColumnEdit.default_users = newPeople;
    setColumnEdit(cloneColumnEdit);
  };

  useEffect(() => {
    if (!isEmpty(linkableSheets)) {
      setReferenceSheets(linkableSheets);
      return;
    }

    dispatch(getMySheetListBasicAction()).then((result) => {
      setReferenceSheets(result.data);
    });
  }, []);

  useEffect(() => {
    let _columnEdit = {
      ...columnEdit,
      ...column,
      name: column?.name || getTranslationTextByField(t, column?.field),
    };
    if (column?.reference?.id) {
      _columnEdit.reference = column.reference.id;
    }
    setColumnEdit(_columnEdit);
  }, [column]);

  if (!columnEdit) return null;
  return (
    <>
      <Modal
        size={columnEdit?.type === 'custom_ai_prompt' ? 'xl' : 'md'}
        dialogClassName={columnEdit?.type === 'approval' ? 'p-10 rounded modal-edit-approval' : ''}
        fullscreen={columnEdit?.type === 'approval'}
        centered
        show={show}
        onHide={onHide}
        enforceFocus={false}
        style={{zIndex: 1000000}}
      >
        <Modal.Header closeButton>
          <Modal.Title>{column?.name || t('sheet_column_edit_title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {columnEdit && (
              <Formik
                enableReinitialize={true}
                initialValues={columnEdit}
                validationSchema={ColumnAddSchema}
                onSubmit={(values) => {
                  let params = {...values};
                  if (values.reference?.value) {
                    params.reference = {
                      id: values?.reference?.value,
                      title: values?.reference?.label,
                    };
                  } else {
                    delete params.reference;
                  }
                  if (values.done_values) {
                    params.done_values = values?.done_values?.map((item) => item?.value);
                  }

                  if (values?.type === 'linked' && !values?.settings?.linked) {
                    setError({linked: t('sheet_column_edit_error_linked')});
                    return;
                  }

                  onSubmit && onSubmit(params, onHide);
                  onHide();
                }}
              >
                {({handleSubmit, values, errors, setFieldValue}) => {
                  return (
                    <>
                      <Form>
                        <div className='form-group row mb-2'>
                          <div
                            className={`d-flex align-items-center justify-content-end ${clsx({
                              'col-1': values?.type === 'approval',
                              'col-3': values?.type !== 'approval',
                            })}`}
                          >
                            <label>{t('sheet_column_setting_name')}:</label>
                          </div>
                          <div
                            className={`${clsx({
                              'col-11': values?.type === 'approval',
                              'col-9': values?.type !== 'approval',
                            })}`}
                          >
                            <Field name='name' component={Input} autoComplete='off' focus />
                          </div>
                        </div>
                        {(values?.type === 'status' || values?.type === 'color') && (
                          <>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_item_label_status')}:</label>
                              </div>
                              <div className='col-9'>
                                <FieldArray
                                  name='values'
                                  render={(arrayHelpers) => (
                                    <div>
                                      {values.values && values.values.length > 0 ? (
                                        values.values.map((statusValue, index) => (
                                          <div key={index} className='d-flex mb-1'>
                                            <Field
                                              className='form-control form-control-xs'
                                              name={`values.${index}.label`}
                                            />
                                            <Field
                                              component={DropdownField}
                                              className='form-control form-control-xs ms-1 w-60px'
                                              name={`values.${index}.backgroundColor`}
                                              displayValue={false}
                                              style={{backgroundColor: statusValue.backgroundColor}}
                                              optionFormatter={(option) => {
                                                return (
                                                  <div className='d-flex align-items-center'>
                                                    <span
                                                      className='w-20px h-20px rounded-circle px-2 d-inline-block'
                                                      style={{backgroundColor: `${option.value}`}}
                                                    ></span>
                                                    <span className='font-size-sm ms-2'>
                                                      {option.label}
                                                    </span>
                                                  </div>
                                                );
                                              }}
                                              optionClassName='py-2'
                                              options={backgroundColorOptions}
                                            />
                                            <button
                                              type='button'
                                              className='btn btn-icon btn-hover-light btn-xs ms-1 w-40px'
                                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            >
                                              <i className='la la-trash' />
                                            </button>
                                          </div>
                                        ))
                                      ) : (
                                        <></>
                                      )}
                                      {
                                        <button
                                          className='btn btn-light btn-xs w-100'
                                          type='button'
                                          onClick={() =>
                                            arrayHelpers.push({
                                              label: '',
                                              value: 'value_' + new Date().getTime(),
                                            })
                                          }
                                        >
                                          + Value
                                        </button>
                                      }
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_default')}:</label>
                              </div>
                              <div className='col-9'>
                                <SelectField name='default'>
                                  <option value=''>{t('sheet_item_option_empty')}</option>
                                  {values.values.map((val) => {
                                    return (
                                      <option value={val.value} key={val.value}>
                                        {val.label}
                                      </option>
                                    );
                                  })}
                                </SelectField>
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_done_values')}:</label>
                              </div>
                              <div className='col-9'>
                                <SelectSearchField
                                  name='done_values'
                                  isMulti
                                  options={values.values
                                    ?.filter((item) => item.value)
                                    ?.map((item) => ({
                                      label: item.label,
                                      value: item.value,
                                    }))}
                                ></SelectSearchField>
                              </div>
                            </div>
                          </>
                        )}
                        {values?.type === 'approval' && (
                          <ApprovalColumnConfig
                            values={values}
                            setFieldValue={setFieldValue}
                            columns={sheet?.columns}
                            setColumnEdit={setColumnEdit}
                          />
                        )}
                        {values?.type === 'reference' && (
                          <div className='form-group row mb-2 '>
                            <div className='col-3 d-flex align-items-center justify-content-end'>
                              <label>{t('sheet_column_setting_type_reference')}:</label>
                            </div>
                            <div className='col-9'>
                              <SelectSearchField
                                isMulti={false}
                                name='reference'
                                options={referenceSheets.map((sheet) => ({
                                  label: sheet.name,
                                  value: sheet._id,
                                }))}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                        )}
                        {values?.type === 'text' && (
                          <>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex  justify-content-end'></div>
                              <div className='col-9'>
                                <div>
                                  <Field
                                    labelClass='fs-6'
                                    name='is_title'
                                    component={CheckBoxField}
                                    autoComplete='off'
                                    label={t('sheet_edit_text_is_title')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-group row mb-2'>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_text_suggestions')}:</label>
                              </div>
                              <div className='col-9'>
                                <Field name='suggestions' component={Textarea} autoComplete='off' />
                              </div>
                            </div>
                          </>
                        )}
                        {values?.type === 'people' && (
                          <>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_default')}:</label>
                              </div>
                              <div className='col-9'>
                                <SelectField
                                  name='default'
                                  notifyChange={(value) => {
                                    setColumnEdit((prev) => ({...prev, default: value}));
                                  }}
                                >
                                  <option value=''>{t('sheet_item_option_empty')}</option>
                                  <option value='current_user'>
                                    {t('sheet_item_option_current_user')}
                                  </option>
                                  <option value='board_admin'>
                                    {t('sheet_item_option_admin_user')}
                                  </option>
                                  <option value='default_users'>
                                    {t('sheet_item_option_admin_user_default')}
                                  </option>
                                </SelectField>
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex  justify-content-end'></div>
                              <div className='col-9'>
                                <div>
                                  <Field
                                    labelClass='fs-6'
                                    name='is_assignee'
                                    component={CheckBoxField}
                                    autoComplete='off'
                                    label={t('sheet_edit_people_is_assignee')}
                                  />
                                </div>
                              </div>
                            </div>

                            {values.default === 'default_users' && (
                              <div className='form-group row mb-2'>
                                <div className='col-3 d-flex align-items-center justify-content-end'>
                                  <label>{t('sheet_item_option_admin_user_default_label')}:</label>
                                </div>
                                <div className='col-9'>
                                  <div className='bg-light h-45px rounded p-2'>
                                    <ItemField
                                      value={{value: values.default_users}}
                                      column={column}
                                      fieldEditable={true}
                                      onChange={handleSelectUser}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_display_mode')}:</label>
                              </div>
                              <div className='col-9'>
                                <SelectField name='display_mode'>
                                  <option value='avatar'>
                                    {t('sheet_item_option_show_avatar')}
                                  </option>
                                  <option value='full'>
                                    {t('sheet_item_option_show_full_name')}
                                  </option>
                                </SelectField>
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_max_people')}:</label>
                              </div>
                              <div className='col-9'>
                                <div className='d-flex'>
                                  <div
                                    className={`border fw-bold rounded-start py-2 px-3 cursor-pointer ${clsx(
                                      {
                                        'border-primary': !values?.settings?.max_people_allowed,
                                      }
                                    )}`}
                                    onClick={() => {
                                      setFieldValue('settings.max_people_allowed', null);
                                    }}
                                  >
                                    {t('sheet_column_setting_max_people_unlimited')}
                                  </div>
                                  <div
                                    className={`border fw-bold py-2 px-7 cursor-pointer ${clsx({
                                      'border-primary':
                                        values?.settings?.max_people_allowed === '1',
                                    })}`}
                                    onClick={() => {
                                      setFieldValue('settings.max_people_allowed', '1');
                                    }}
                                  >
                                    1
                                  </div>
                                  <div
                                    className={`border fw-bold py-2 px-7 cursor-pointer ${clsx({
                                      'border-primary':
                                        values?.settings?.max_people_allowed === '2',
                                    })}`}
                                    onClick={() => {
                                      setFieldValue('settings.max_people_allowed', '2');
                                    }}
                                  >
                                    2
                                  </div>
                                  <div
                                    className={`border fw-bold rounded-end py-2 px-7 cursor-pointer ${clsx(
                                      {
                                        'border-primary':
                                          values?.settings?.max_people_allowed === '3',
                                      }
                                    )}`}
                                    onClick={() => {
                                      setFieldValue('settings.max_people_allowed', '3');
                                    }}
                                  >
                                    3
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {values?.type === 'date' && (
                          <>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_column_setting_default')}:</label>
                              </div>
                              <div className='col-9'>
                                <SelectField name='default'>
                                  <option value=''>{t('sheet_item_option_empty')}</option>
                                  <option value='today'>
                                    {t('sheet_item_option_created_date')}
                                  </option>
                                </SelectField>
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex  justify-content-end'></div>
                              <div className='col-9'>
                                <div>
                                  <Field
                                    labelClass='fs-6'
                                    name='is_assignee'
                                    component={CheckBoxField}
                                    autoComplete='off'
                                    label={t('sheet_edit_column_is_due_date')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex  justify-content-end'>
                                <label>Hiển thị:</label>
                              </div>
                              <div className='col-9'>
                                <div>
                                  <Field
                                    labelClass='fs-6'
                                    name='settings.hasTime'
                                    component={CheckBoxField}
                                    autoComplete='off'
                                    label={t('sheet_edit_date_input')}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {values?.type === 'number' && (
                          <>
                            <div className='form-group row mb-2'>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>{t('sheet_edit_number_unit')}:</label>
                              </div>
                              <div className='col-9'>
                                <div className='d-flex'>
                                  <div
                                    className={`border rounded-start py-2 px-3 cursor-pointer ${clsx(
                                      {
                                        'border-primary': !values.unit,
                                      }
                                    )}`}
                                    onClick={() => {
                                      setFieldValue('unit', null);
                                    }}
                                  >
                                    {t('sheet_edit_number_unit_none')}
                                  </div>
                                  <div
                                    className={`border py-2 px-4 cursor-pointer ${clsx({
                                      'border-primary': values.unit === 'dollar',
                                    })}`}
                                    onClick={() => {
                                      setFieldValue('unit', 'dollar');
                                    }}
                                  >
                                    <i className='las la-dollar-sign text-dark'></i>
                                  </div>
                                  <div
                                    className={`border py-2 px-4 cursor-pointer ${clsx({
                                      'border-primary': values.unit === 'percent',
                                    })}`}
                                    onClick={() => {
                                      setFieldValue('unit', 'percent');
                                    }}
                                  >
                                    <i className='las la-percent text-dark'></i>
                                  </div>
                                  <div
                                    className={`border rounded-end py-1 px-1 cursor-pointer ${clsx({
                                      'border-primary':
                                        values.unit &&
                                        values.unit !== 'percent' &&
                                        values.unit !== 'dollar',
                                    })}`}
                                  >
                                    <Field
                                      className='p-0 h-100 border-0 custom-unit-field'
                                      placeholder={t('sheet_edit_number_unit_config')}
                                      name='unit'
                                      component={Input}
                                      autoComplete='off'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex align-items-center justify-content-end'>
                                <label>Vị trí:</label>
                              </div>
                              <div className='col-9'>
                                <div className='d-flex'>
                                  <div
                                    className={`border rounded-start py-2 px-3 cursor-pointer w-50px text-center ${clsx(
                                      {
                                        'border-primary text-primary': values.direction === 'left',
                                      }
                                    )}`}
                                    onClick={() => {
                                      setFieldValue('direction', 'left');
                                    }}
                                  >
                                    {t('sheet_edit_number_direction_left')}
                                  </div>
                                  <div
                                    className={`border rounded-end py-2 px-3  cursor-pointer w-50px text-center ${clsx(
                                      {
                                        'border-primary text-primary':
                                          !values.direction || values.direction === 'right',
                                      }
                                    )}`}
                                    onClick={() => {
                                      setFieldValue('direction', 'right');
                                    }}
                                  >
                                    {t('sheet_edit_number_direction_right')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {values?.type === 'timeline' && (
                          <>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex  justify-content-end'>
                                <label>Hiển thị:</label>
                              </div>
                              <div className='col-9'>
                                <div>
                                  <Field
                                    labelClass='fs-6'
                                    name='settings.hasTime'
                                    component={CheckBoxField}
                                    autoComplete='off'
                                    label={t('sheet_edit_date_input')}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {values?.type === 'last_updated_date' && (
                          <>
                            <div className='form-group row mb-2 '>
                              <div className='col-3 d-flex  justify-content-end align-items-center'>
                                <label>{t('sheet_edit_last_updated_date_type')}</label>
                              </div>
                              <div className='col-9'>
                                <div className='d-flex border border-secondary rounded justify-content-between'>
                                  <div
                                    className={clsx(
                                      'last-updated-column-display-mode w-100 p-3',
                                      values?.settings?.display === 'person' &&
                                        'bg-primary text-white'
                                    )}
                                    onClick={() => setFieldValue('settings.display', 'person')}
                                  >
                                    {t('sheet_edit_last_updated_display_person')}
                                  </div>
                                  <div
                                    className={clsx(
                                      'last-updated-column-display-mode w-100 p-3',
                                      values?.settings?.display === 'date' &&
                                        'bg-primary text-white'
                                    )}
                                    onClick={() => setFieldValue('settings.display', 'date')}
                                  >
                                    {t('sheet_edit_last_updated_display_date')}
                                  </div>
                                  <div
                                    className={clsx(
                                      'last-updated-column-display-mode w-100 p-3',
                                      values?.settings?.display === 'both' &&
                                        'bg-primary text-white'
                                    )}
                                    onClick={() => setFieldValue('settings.display', 'both')}
                                  >
                                    {t('sheet_edit_last_updated_display_both')}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(values?.settings?.display === 'date' ||
                              values?.settings?.display === 'both') && (
                              <div className='form-group row mb-2 '>
                                <div className='col-3 d-flex justify-content-end align-items-center'>
                                  <label>{t('sheet_edit_last_updated_date_display_type')}</label>
                                </div>
                                <div className='col-9'>
                                  <div className='d-flex border border-secondary rounded justify-content-between'>
                                    <div
                                      className={clsx(
                                        'last-updated-column-display-mode w-100 p-3',
                                        values?.settings?.date_format === 'time-ago' &&
                                          'bg-primary text-white'
                                      )}
                                      onClick={() =>
                                        setFieldValue('settings.date_format', 'time-ago')
                                      }
                                    >
                                      {t('sheet_edit_last_updated_date_type_time_ago')}
                                    </div>
                                    <div
                                      className={clsx(
                                        'last-updated-column-display-mode w-100 p-3',
                                        values?.settings?.date_format === 'date-time' &&
                                          'bg-primary text-white'
                                      )}
                                      onClick={() =>
                                        setFieldValue('settings.date_format', 'date-time')
                                      }
                                    >
                                      {t('sheet_edit_last_updated_date_type_date_time')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {values?.type === 'linked' && (
                          <ConfigLinkedColumn
                            sheetOptions={referenceSheets}
                            column={values}
                            mode={'add'}
                            error={error}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        {values?.type === 'lookup' && (
                          <div className='form-group mb-2'>
                            <div className='row mb-2'>
                              <div className='d-flex align-items-center justify-content-end col-3'>
                                <label>{t('sheet_column_type_reference')}</label>
                              </div>
                              <div className='col-9'>
                                <Field
                                  autoComplete='off'
                                  name='settings.lookup_page_id'
                                  component={SelectSearch}
                                  placeholder={t('sheet_column_type_lookup_select_page_id')}
                                  noOptionsMessage={() => t('sheet_column_type_lookup_no_options')}
                                />
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className='d-flex align-items-center justify-content-end col-3'>
                                <label>{t('sheet_column_type_lookup')}</label>
                              </div>
                              <div className='col-9'>
                                <Field
                                  autoComplete='off'
                                  name='settings.lookup_field'
                                  component={SelectSearch}
                                  placeholder={t('sheet_column_type_lookup_select_field')}
                                  noOptionsMessage={() => t('sheet_column_type_lookup_no_options')}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {values?.type === 'custom_ai_prompt' && (
                          <CustomAIPrompt
                            className={'mt-5'}
                            metaColumns={sheet?.columns}
                            column={columnEdit}
                          />
                        )}
                        {values.type === 'auto_number' && (
                          <ConfigAutoNumberColumn values={values} errors={errors} />
                        )}

                        <CommonFieldEditor values={values} />

                        <div className='form-group row mb-2 '>
                          <div className='col-3 d-flex align-items-center justify-content-end'></div>
                          <div className='col-9 d-flex justify-content-end gap-3'>
                            <button
                              type='button'
                              onClick={onHide}
                              className='btn btn-light btn-elevate w-100px'
                            >
                              {t('common_cancel')}
                            </button>
                            <button
                              type='submit'
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                              className='btn btn-primary btn-elevate w-100px'
                            >
                              {t('common_save')}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
