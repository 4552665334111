import React, {useState} from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import {getAggregationOptionByValue, getAggregationOptions} from 'app/modules/work/helpers';
import {set} from 'lodash';
import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';
import Input from 'app/common/_partials/controls/Input';

function WidgetEditMetricView({editWidget, sheet, setEditWidget}) {
  const {t} = useTranslate();
  const initMetric = {
    aggregation: {},
    column: {},
    type: '',
    name: '',
  };

  const [newMetric, setNewMetric] = useState(initMetric);

  function getColumnName(column) {
    let result = column;
    sheet.columns.forEach((col) => {
      if (col.field === column) {
        result = col.name;
      }
    });
    return result;
  }

  const handleMetricAdd = () => {
    const _newMetric = {
      ...newMetric,
      aggregation: newMetric.aggregation.value,
      aggregationName: newMetric.aggregation.label,
      column: newMetric.column.value,
      type: newMetric.column.type,
    };

    let _editWidget = {...editWidget};
    set(_editWidget, 'data.metrics', [...editWidget.data.metrics, _newMetric]);
    setEditWidget(_editWidget);
    setNewMetric(initMetric);
  };

  const handleMetricAggregateChange = (updateIndex, params) => {
    const _updateMetrics = editWidget.data.metrics.map((metric, i) => {
      if (i === updateIndex) {
        return {
          ...metric,
          aggregation: params.value,
          aggregationName: params.value,
        };
      } else {
        return metric;
      }
    });

    let _editWidget = {...editWidget};
    set(_editWidget, 'data.metrics', _updateMetrics);
    setEditWidget(_editWidget);
  };

  const handleMetricColumnChange = (updateIndex, params) => {
    const _updateMetrics = editWidget.data.metrics.map((metric, i) => {
      if (i === updateIndex) {
        return {
          ...metric,
          column: params.value,
          type: params.type,
        };
      } else {
        return metric;
      }
    });

    let _editWidget = {...editWidget};
    set(_editWidget, 'data.metrics', _updateMetrics);
    setEditWidget(_editWidget);
  };

  const handleChangeMetric = (index, key, value) => {
    let _editWidget = {...editWidget};
    set(_editWidget, `data.metrics.${index}.${key}`, value);
    setEditWidget(_editWidget);
  };

  const handleRemoveMetricColumn = (deleteIndex) => {
    const _updateMetrics = editWidget.data.metrics.filter((_, i) => i !== deleteIndex);

    let _editWidget = {...editWidget};
    set(_editWidget, 'data.metrics', _updateMetrics);
    setEditWidget(_editWidget);
  };

  const getColumnOptions = (aggregation) => {
    if (!aggregation) {
      return [];
    }

    return sheet?.columns
      ?.filter((column) => !column?.deleted)
      ?.flatMap((column) => {
        if (aggregation === 'count' || column.type === 'number' || column.type === 'timeline') {
          return {
            label: column.name,
            value: column.field,
            type: column.type,
          };
        }
        return [];
      });
  };

  const getColumnOptionByValue = (aggregation, value) => {
    return getColumnOptions(aggregation)?.find((column) => column.value === value);
  };

  return (
    <div className='mb-4'>
      {editWidget.data.metrics.map((metric, index) => {
        return (
          <div className='d-flex flex-row mb-2 gap-2' key={index}>
            <SelectSearch
              className='w-200px'
              options={getAggregationOptions()}
              onChange={(value) => handleMetricAggregateChange(index, value)}
              value={getAggregationOptionByValue(metric.aggregation)}
            ></SelectSearch>
            <SelectSearch
              className='w-200px'
              options={getColumnOptions(metric.aggregation)}
              value={getColumnOptionByValue(metric.aggregation, metric.column)}
              onChange={(value) => handleMetricColumnChange(index, value)}
            ></SelectSearch>
            <Input
              value={metric.name}
              onChange={(e) => handleChangeMetric(index, 'name', e.target.value || '')}
              className='w-200px'
              placeholder={`${getAggregationOptionByValue(metric.aggregation)?.label} ${
                metric.column || ''
              }`}
            ></Input>
            <button
              className='btn btn-outline-danger ms-2 w-40px h-40px d-flex align-items-center justify-content-center'
              onClick={() => handleRemoveMetricColumn(index)}
            >
              <i className='las la-trash fs-5'></i>
            </button>
          </div>
        );
      })}
      <button className='btn btn-secondary mt-3' onClick={handleMetricAdd}>
        {t('common_add')}
      </button>
    </div>
  );
}

export default WidgetEditMetricView;
