import 'react-datepicker/dist/react-datepicker.css';
import './itemDetail.scss';

import {useEffect, useState} from 'react';

import {doActionAction, getSheetItemAction, updateSheetItemAction} from '../../stores/sheetActions';
import {useDispatch} from 'react-redux';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {validationErrorCode} from '../../validation/constants';
import {isObject} from 'lodash';
import {useSheetPermission} from '../../SheetPermissionHook';
import ItemDetailModal from './ItemDetailModal';
import ItemDetailDrawer from './ItemDetailDrawer';

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

export default function ItemDetailStandaloneView({
  item,
  sheet,
  open,
  onHide,
  onUpdateItem,
  defaultDisplayMode = 'modal',
}) {
  const [displayMode, setDisplayMode] = useState(defaultDisplayMode);
  const onToggleDisplayMode = () => {
    if (displayMode === 'drawer') {
      setDisplayMode('modal');
    } else {
      setDisplayMode('drawer');
    }
  };
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const notification = useSnackNotification();
  const [itemDetail, setItemDetail] = useState(null);
  const handleSaveItemAction = (item, params, callback) => {
    dispatch(doActionAction(params)).then((res) => {
      loadItem();
      callback && callback(res);
    });
  };

  const loadItem = () => {
    dispatch(getSheetItemAction({sheetId: item.sheet, itemId: item._id})).then((result) => {
      if (result?.data?.success) {
        setItemDetail(result?.data?.data);
      }
    });
  };

  const {userSheetPermissions} = useSheetPermission({sheetId: item?.sheet});

  useEffect(() => {
    if (item) {
      loadItem();
    }
  }, [item]);

  const handleUpdateItem = (item, field, _oldValue, newValue, callback) => {
    let updateItemParams = {
      sheetId: item.sheet,
      itemId: item._id,
    };

    let newFieldValue = null;
    if (isObject(newValue) && newValue.hasOwnProperty('value')) {
      newFieldValue = newValue;
    } else {
      newFieldValue = {
        value: newValue,
      };
    }

    updateItemParams[field] = newFieldValue;
    const itemUpdate = {...itemDetail, [field]: newFieldValue};

    setItemDetail(itemUpdate);

    dispatch(updateSheetItemAction(updateItemParams)).then((result) => {
      if (result?.data?.success) {
        notification.showSuccess(t('common_update_success'));
      } else {
        if (result.data.code === validationErrorCode) {
          notification.showError(
            result?.data?.message
              ? getValidationErrorMessage(result?.data?.message)
              : t('common_update_error')
          );
        } else {
          notification.showError(
            result?.data?.message ? t(result?.data?.message) : t('common_update_error')
          );
        }
      }
      onUpdateItem && onUpdateItem(itemUpdate, field);
      callback && callback(result);
    });
  };
  if (displayMode === 'drawer')
    return (
      <ItemDetailDrawer
        open={open}
        onHide={onHide}
        item={itemDetail}
        permissions={userSheetPermissions}
        onUpdateItem={handleUpdateItem}
        onSaveItemAction={handleSaveItemAction}
        sheet={sheet}
        showDialogControl={false}
        onToggleDisplayMode={onToggleDisplayMode}
      />
    );
  return (
    <ItemDetailModal
      open={open}
      onHide={onHide}
      item={itemDetail}
      permissions={userSheetPermissions}
      onUpdateItem={handleUpdateItem}
      onSaveItemAction={handleSaveItemAction}
      sheet={sheet}
      showDialogControl={false}
      onToggleDisplayMode={onToggleDisplayMode}
    />
  );
}
