import React from 'react';
import {Modal} from 'react-bootstrap';
import FormulaEditor from './FormulaEditor';

function ModalFormulaEditor({column, show, onHide, onSave, metaColumns}) {
  const handleSave = (formula) => {
    onSave(column, formula);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered style={{zIndex: 1400}}>
      <FormulaEditor
        formulaValue={column?.formula ?? ''}
        onSave={handleSave}
        metaColumns={metaColumns}
      />
    </Modal>
  );
}

export default ModalFormulaEditor;
