import './styles.scss';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';

import {isEmpty} from 'lodash';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import {capitalizeFirstLetter} from 'app/common/_helpers/FunctionHelper';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useField, useFormikContext} from 'formik';
import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';

function FormulaField({referenceColumns, ...props}) {
  const [isShowRecommendTooltip, setIsShowRecommendTooltip] = useState(false);
  const [formula, setFormula] = useState('');
  const [formulaColumns, setFormulaColumns] = useState([]);
  const [focusColumn, setFocusColumn] = useState(0);
  const [startFilterColumn, setStartFilterColumn] = useState(false);
  const editor = document.getElementById('editor-textare');
  const {t} = useTranslate();

  const [field, meta] = useField(props);

  const {setFieldValue} = useFormikContext();

  const [showColumnSelect, setShowColumnSelect] = useState(false);

  const MAX_LENGTH = 1000;

  useLayoutEffect(() => {
    getEditorFormulaValue();
  }, []);

  const onFocusTemplatePrompt = () => {
    setShowColumnSelect(true);
  };

  /**
   * Gets the formula value in the editor by replacing the column field with column name (in uppercase)
   * from the referenceColumns.
   *
   * The sortedReferenceColumns is used to ensure that the column with longer field (length) is replaced first.
   * For example, if we have two columns: 'date1' and 'last_updated_date1', the 'last_updated_date1' column should be replaced first.
   * If we don't do this, the 'date1' column will be replaced first and the 'last_updated_date1' column will still contain
   * the original value of {last_updated_date1}.
   */
  const getEditorFormulaValue = () => {
    let newFormulaValue = field?.value || '';
    const sortedReferenceColumns = referenceColumns.sort(
      (a, b) => b?.field?.length - a?.field?.length
    );

    sortedReferenceColumns.forEach((item) => {
      newFormulaValue = newFormulaValue.replaceAll(
        `{${item?.field}}`,
        `{${capitalizeFirstLetter(item?.name)}}`
      );
    });
    setFormula(newFormulaValue);
  };

  const moveCursor = () => {
    editor.focus();
    if (editor && editor?.value && editor.value.length) {
      editor.selectionStart = editor.selectionEnd = editor.value.length;
    }
  };

  const createNewFormula = (text) => {
    let newFormula = formula;
    let cursorPosition = editor.selectionStart;
    let beforeText = formula.substring(0, cursorPosition);
    let afterText = formula.substring(cursorPosition);
    newFormula = beforeText + text + afterText;
    setFormula(newFormula);
    moveCursor();
  };

  const onClickFormulaColumn = (formulaColumn) => {
    let text = '{' + capitalizeFirstLetter(formulaColumn?.label) + '}';
    createNewFormula(text);
  };

  const onClickFormulaColumnRecommend = (formulaColumn) => {
    let text = capitalizeFirstLetter(formulaColumn?.name) + '}';
    createNewFormula(text);
    setIsShowRecommendTooltip(false);
  };

  useEffect(() => {
    if (isShowRecommendTooltip) {
      if (isEmpty(formula) || !formula.includes('{')) {
        setIsShowRecommendTooltip(false);
      }
    }
  }, [formula]);

  const handleFilterColumn = (newValue) => {
    let filterValue = newValue;
    let indexOfBeginFilterValue = newValue.lastIndexOf('{');
    filterValue = filterValue.slice(indexOfBeginFilterValue + 1);
    if (isEmpty(filterValue)) {
      setFormulaColumns(referenceColumns);
    } else {
      const newFormulaColumn = referenceColumns.filter((item) =>
        item.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      setFormulaColumns(newFormulaColumn);
    }
  };

  const onChangFormulaInput = (value) => {
    if (value.length > MAX_LENGTH) {
      return;
    }

    setFormula(value);
    if (startFilterColumn) {
      handleFilterColumn(value);
    }
  };

  const onEditorKeyDown = (e) => {
    const key = e.key;
    const avaibleColumnLength = formulaColumns.length;
    if (isShowRecommendTooltip && key === 'Tab') {
      e.preventDefault();
      setIsShowRecommendTooltip(false);
    }
    if (key === 'Enter') {
      if (isShowRecommendTooltip) {
        e.preventDefault();
        const selectedColumn = formulaColumns[focusColumn];
        onClickFormulaColumnRecommend(selectedColumn);
      }
    }
    if (isShowRecommendTooltip && key === 'ArrowDown') {
      e.preventDefault();
      if (focusColumn < avaibleColumnLength - 1) {
        setFocusColumn(focusColumn + 1);
      }
    }
    if (isShowRecommendTooltip && key === 'ArrowUp') {
      e.preventDefault();
      if (focusColumn > 0) {
        setFocusColumn(focusColumn - 1);
      }
    }
    if (isShowRecommendTooltip && key === 'ArrowRight') {
      setIsShowRecommendTooltip(false);
    }
    if (isShowRecommendTooltip && key === 'ArrowLeft') {
      setIsShowRecommendTooltip(false);
    }
    if (isShowRecommendTooltip && key === 'Backspace') {
      const deleteCharacter = formula.slice(-1);
      if (deleteCharacter === '{') {
        setIsShowRecommendTooltip(false);
      }
    }
    if (key === '{') {
      setIsShowRecommendTooltip(true);
    }
    if (key === '}') {
      setIsShowRecommendTooltip(false);
    }
  };

  useEffect(() => {
    setFormulaColumns(referenceColumns);
    if (isShowRecommendTooltip) {
      setStartFilterColumn(true);
    } else {
      setStartFilterColumn(false);
    }
  }, [isShowRecommendTooltip]);

  useEffect(() => {
    setFocusColumn(0);
  }, [formulaColumns]);

  useEffect(() => {
    setFormulaColumns(referenceColumns);
  }, [referenceColumns]);

  const onSaveFormula = () => {
    let newFormulaValue = formula;
    referenceColumns.forEach((item) => {
      if (!item?.hidden && !item.deleted) {
        let name = `{${capitalizeFirstLetter(item?.name)}}`;
        let field = `{${item?.field}}`;
        newFormulaValue = newFormulaValue.replaceAll(name, field);
      }
    });
    setFieldValue(field?.name, newFormulaValue);
  };

  const columnOptions = referenceColumns.map((column) => {
    return {
      value: column.field,
      label: column.name,
    };
  });

  return (
    <div className={'editor-textare'}>
      <RecommendToolTip
        show={isShowRecommendTooltip}
        columns={formulaColumns}
        onClickFormulaColumnRecommend={onClickFormulaColumnRecommend}
        focusColumn={focusColumn}
        onHide={() => setIsShowRecommendTooltip(false)}
      />
      <Editor
        textareaId='editor-textare'
        value={formula}
        onValueChange={(code) => onChangFormulaInput(code)}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 16,
        }}
        className='w-100 border rounded'
        onKeyDown={onEditorKeyDown}
        onFocus={onFocusTemplatePrompt}
        onBlur={onSaveFormula}
      />
      <div class={'mb-5 text-right'}>
        {formula.length}/{MAX_LENGTH}
      </div>
      {showColumnSelect && (
        <SelectSearch
          className={'custom-ai-select'}
          isMulti={false}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          options={columnOptions}
          onChange={onClickFormulaColumn}
          placeholder={t('sheet_detail_permission_select_column')}
          closeOnSelect={false}
        ></SelectSearch>
      )}
    </div>
  );
}

function RecommendToolTip({show, columns, onClickFormulaColumnRecommend, focusColumn, onHide}) {
  const recommendTooltipRef = useRef();

  useLayoutEffect(() => {
    const selected = recommendTooltipRef?.current?.querySelector('.formula-item-focus');
    if (selected) {
      selected?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [focusColumn]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (recommendTooltipRef.current && !recommendTooltipRef.current.contains(event.target)) {
        onHide();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [recommendTooltipRef]);

  if (!show) return <></>;
  return (
    <>
      <div className='d-none tooltip'></div>
      <div
        ref={recommendTooltipRef}
        className='tooltip-content bg-white rounded p-2 h-200px shadow overflow-auto'
      >
        {columns.map((item, index) => (
          <div
            className={`p-2 rounded cursor-pointer formula-item ${
              index === focusColumn && 'formula-item-focus'
            }`}
            key={index}
            onClick={() => onClickFormulaColumnRecommend(item)}
          >
            <i className='las la-columns me-2' />
            <span className='fw-bold text-capitalize'>{item?.name}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default FormulaField;
