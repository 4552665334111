import React, {useMemo} from 'react';
import {getConnectedEdges, Handle, useNodeId, useStore} from 'reactflow';

const selector = (s) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const WorkflowHandle = (props) => {
  const {nodeInternals, edges} = useStore(selector);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    if (typeof props.isConnectable === 'function') {
      const node = nodeInternals.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);

      return props.isConnectable({node, connectedEdges});
    }

    if (typeof props.isConnectable === 'number') {
      const node = nodeInternals.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);

      return connectedEdges.length < props.isConnectable;
    }

    return props.isConnectable;
  }, [nodeInternals, edges, nodeId, props.isConnectable]);

  return (
    <Handle
      {...props}
      style={{
        width: '10px',
        height: '10px',
        borderRadius: 0,
        clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
        visibility: `${props?.type === 'source' ? 'hidden' : ''}`,
        ...props?.style,
      }}
      isConnectable={isHandleConnectable}
    ></Handle>
  );
};

export default WorkflowHandle;
