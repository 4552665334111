import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  listLoading: true,
  actionsLoading: false,
  myRequestsLoading: true,
  myItems: [],
  myRequests: [],
  mySheets: [],
  quickSheets: [],
  countMyTasks: 0,
  countMyRequests: 0,
  error: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
  myRequests: 'myRequests',
};

export const slice = createSlice({
  name: 'myWork',
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.myRequests) {
        state.myRequestsLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.myRequests) {
        state.myRequestsLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    myItemsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myItems = data.data?.items || [];
      state.quickSheets = data.data?.sheets || [];
      state.listLoading = false;
    },

    myRequestsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myRequests = data.data?.items || [];
      state.mySheets = data.data?.sheets || [];
      state.myRequestsLoading = false;
    },

    countMyRequestsFetched: (state, action) => {
      const {count} = action.payload;
      state.countMyRequests = count;
    },

    countMyTasksFetched: (state, action) => {
      const {count} = action.payload;
      state.countMyTasks = count;
    },

    mySheetsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.mySheets = data.data || [];
    },
  },
});
