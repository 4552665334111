import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  listLoading: true,
  actionsLoading: false,
  myApps: [],
  myWorkspaces: [],
  myTasks: {},
  mySheets:[],
  myRequests: {},
  myMeetings: [],
  error: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const slice = createSlice({
  name: 'myHome',
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.myRequests) {
        state.myRequestsLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.myRequests) {
        state.myRequestsLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    

    myAppsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myApps = data.data || [];
    },
    myWorkspacesFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myWorkspaces = data.data || [];
    },
    myTasksFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myTasks = data.data || [];
    },
    mySheetsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.mySheets = data.data || [];
    },
    myRequestsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myRequests = data.data || [];
    },
    myMeetingsFetched: (state, action) => {
      const {data} = action.payload;
      state.error = null;
      state.myMeetings = data.data || [];
    },
  },
});
