import React, {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {getSheetColumnValuesAction} from '../../../stores/sheetActions';
import {isEmpty, isNumber, isString} from 'lodash';
import {useTranslate} from 'core/i18n/i18nProvider';
import {format, fromUnixTime, isValid} from 'date-fns';
import {vi} from 'date-fns/locale';
import {effectiveValue} from 'app/modules/work/helpers';
function SheetLookupFilter({key, sheet, column, filter, filterValue, applyFilter}) {
  const [values, setValues] = useState([]);
  const [lookOptions, setLookupOptions] = useState([]);
  const dispatch = useDispatch();
  const {t} = useTranslate();
  useEffect(() => {
    dispatch(getSheetColumnValuesAction({sheetId: sheet?._id, field: column?.field})).then(
      (res) => {
        const lookupData = res?.data ?? [];
        let lookupOptions = [];
        let lookupOptionIds = [];
        lookupData.forEach((lookup) => {
          let value = lookup?.value;
          value.forEach((element) => {
            if (element && !lookupOptionIds.includes(element?.reference_item_id)) {
              lookupOptions.push({
                id: element?.reference_item_id,
                title: element?.reference_display_value,
              });
              lookupOptionIds.push(element?.reference_item_id);
            }
          });
        });
        setLookupOptions(lookupOptions);
      }
    );
  }, [column]);

  useEffect(() => {
    if (filterValue && filterValue?.value && filterValue?.value?.in) {
      setValues(filterValue?.value?.in);
    } else {
      setValues([]);
    }
  }, [filterValue]);

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);
    applyFilter && applyFilter(filter, {in: newValues});
  };

  const renderLabel = (label) => {
    const type = column?.settings?.lookup_field?.type;
    if (isString(label)) return <span>{label}</span>;
    if (type === 'timeline')
      return (
        <TimeFormat
          start={label?.startDate}
          end={label?.endDate}
          endTime={label?.endTime}
          startTime={label?.startTime}
        />
      );
    if (type === 'date')
      return <span>{isValid(label) && format(fromUnixTime(label), 'dd-MM-yyyy, HH:mm', {locale: vi})}</span>;
    if (type === 'people') return <PeopleFormat value={label} />;
    if (type === 'status') return <span>{label?.value}</span>;
    return t('common_empty');
  };

  return (
    <>
      <div className='d-flex flex-column align-items-stretch '>
        {isEmpty(lookOptions) && (
          <div className='text-center p-2 bg-secondary rounded w-200px'>
            {t('sheet.filter.no_reference_selected')}
          </div>
        )}
        {lookOptions?.map((option) => (
          <ToggleButton
            id={`toggle-people-${column.field}-${option.id}`}
            key={`toggle-people-${column.field}-${option.id}`}
            type='checkbox'
            size='sm'
            variant='filter'
            className={`mb-2 d-flex align-items-center justify-content-center text-nowrap ${
              filterValue?.value?.in?.includes(option.id) ? 'bg-light-primary' : 'bg-secondary'
            }`}
            checked={filterValue?.value.in?.includes(option.id)}
            value='1'
            onChange={(e) => onToggleValue(option.id)}
          >
            <div className='mw-150px text-truncate'>{renderLabel(option?.title)}</div>
          </ToggleButton>
        ))}
      </div>
    </>
  );
}

const TimeFormat = ({start, end, startTime, endTime}) => {
  const startDate = isNumber(start) ? fromUnixTime(start) : null;
  const endDate = isNumber(end) ? fromUnixTime(end) : null;
  return (
    <>
      {startDate && format(startDate, 'd MMM', {locale: vi})}
      {startTime ? `|${startTime}` : null} - {endDate && format(endDate, 'd MMM', {locale: vi})}
      {endTime ? `|${endTime}` : null}
    </>
  );
};

const PeopleFormat = (value) => {
  const people = effectiveValue(value);
  return people?.map((item) => item?.name).toString();
};

export default SheetLookupFilter;
