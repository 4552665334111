import './sheetViewViewList.scss';

import React, {useEffect, useRef, useState} from 'react';

import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {isNil} from 'lodash';
import {removeSign} from 'app/common/_helpers';
import {useTranslate} from 'core/i18n/i18nProvider';

const viewIcon = {
  kanban: 'la la-columns',
  grid: 'la la-table',
  table: 'la la-table',
  'table-paging': 'la la-table',
  'table-paging-with-subitems': 'la la-table',
  calendar: 'la la-calendar',
  list: 'la la-list',
  timeline: 'la-clock-o',
  gallery: 'la-border-all',
  gantt: 'las la-stream',
  dashboard: 'la la-dashboard',
  'connected-dashboard': 'la la-sitemap',
};

const createableView = [
  {
    name: 'sheet_view_type_list',
    type: 'list',
  },
  {
    name: 'sheet_view_type_table',
    type: 'table',
  },
  {
    name: 'sheet_view_type_table_paging',
    type: 'table-paging',
  },
  {
    name: 'sheet_view_type_table_paging_with_subitems',
    type: 'table-paging-with-subitems',
  },
  {
    name: 'sheet_view_type_calendar',
    type: 'calendar',
  },
  {
    name: 'sheet_view_type_kanban',
    type: 'kanban',
  },
  {
    name: 'sheet_view_type_dashboard',
    type: 'dashboard',
  },
];
const SheetViewViewList = ({
  views = [],
  selectedView,
  onSelectView,
  onAddView,
  onDeleteView,
  onUpdateView,
}) => {
  const {t} = useTranslate();
  const [listView, setListView] = useState(views);
  const [hoverView, setHoverView] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [showCreateSection, setShowCreateSection] = useState(true);
  const [createView, setCreateView] = useState(null);
  const [deleteView, setDeleteView] = useState(null);
  const [editView, setEditView] = useState(null);
  const inputViewNameRef = useRef();
  const viewListRef = useRef();
  const [createViewData, setCreateViewData] = useState({
    name: '',
    privacy: '',
  });

  useEffect(() => {
    setListView(views);
  }, [views]);

  const onMouseEnterView = (view) => {
    setHoverView(view?.code);
  };

  const onMouseLeaveView = () => {
    setHoverView(null);
  };

  useEffect(() => {
    if (searchText && searchText !== '') {
      let searchString = removeSign(searchText.toLocaleLowerCase());
      let newListView = views?.filter((view) => {
        let viewName = view?.name ?? '';
        let viewType = view?.type ?? '';
        viewName = removeSign(viewName.toLocaleLowerCase());
        viewType = removeSign(viewType.toLocaleLowerCase());
        return viewName.includes(searchString) || viewType.includes(searchString);
      });
      setListView(newListView);
    } else {
      setListView(views ?? []);
    }
  }, [searchText]);

  const onChangeCreateViewData = (key, value) => {
    setCreateViewData((prev) => ({...prev, [key]: value}));
  };

  const onCreateView = (view) => {
    if (createView?.name === view?.name) {
      setCreateView(null);
    } else {
      setCreateView(view);
      onChangeCreateViewData('name', t(view?.name));
    }
  };

  const handleCreateView = () => {
    const params = {
      ...createViewData,
      type: createView?.type,
    };
    onAddView &&
      onAddView(params, (view) => {
        setCreateView(null);
        onSelectView(view);
        const viewList = viewListRef.current;
        viewList.scrollTop = viewList.scrollHeight;
      });
  };

  const handleDeleteView = () => {
    onDeleteView && onDeleteView(deleteView);
    setDeleteView(null);
  };

  const handleDuplicateView = (view) => {
    const viewName = view?.name !== '' ? view?.name : view?.type;
    const params = {...view, name: `${viewName}-copy`};
    onAddView && onAddView(params);
  };

  const handleUpdateViewName = () => {
    if (editView) {
      let newName = inputViewNameRef.current.value;
      let newView = {
        ...editView,
        name: newName,
      };
      onUpdateView && onUpdateView(editView, newView);
      setEditView(null);
    }
  };

  const popover = (
    <Popover className='sheet-view-view-create-popover'>
      <Popover.Body>
        <input
          className='form-control'
          value={createViewData?.name}
          onChange={(e) => onChangeCreateViewData('name', e.target.value)}
        />
        <div className='d-flex flex-column gap-3 mt-5 border-top pt-5'>
          <h3>{t('sheet_view_search_who_can_edit')}</h3>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                value={'team'}
                checked={createViewData?.privacy === 'team'}
                onChange={(e) => onChangeCreateViewData('privacy', e.target.value)}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault1'>
                {t('sheet_view_search_edit_privacy_team')}
              </label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                value={'personal'}
                checked={createViewData?.privacy === 'personal'}
                onChange={(e) => onChangeCreateViewData('privacy', e.target.value)}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault1'>
                {t('sheet_view_search_edit_privacy_personal')}
              </label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                value={'lock'}
                checked={createViewData?.privacy === 'lock'}
                onChange={(e) => onChangeCreateViewData('privacy', e.target.value)}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault1'>
                {t('sheet_view_search_edit_privacy_lock')}
              </label>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end mt-5 gap-3 border-top pt-5'>
          <button className='btn btn-secondary' onClick={() => setCreateView(null)}>
            {t('common_cancel')}
          </button>
          <button className='btn btn-primary' onClick={handleCreateView}>
            {t('common_add')}
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  const viewActionPopover = (view) => {
    return (
      <Popover>
        <Popover.Body className='p-3'>
          <div className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'>
            <i className='la la-star-o' />
            <span>{t('data_view_action_favorite')}</span>
          </div>
          <div className='py-3 d-flex flex-column border-top mt-2 gap-2'>
            <div
              className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'
              onClick={() => setEditView(view)}
            >
              <i className='la la-edit' />
              <span>{t('data_view_action_rename')}</span>
            </div>

            <div
              className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'
              onClick={() => handleDuplicateView(view)}
            >
              <i className='la la-copy' />
              <span>{t('data_view_action_duplicate')}</span>
            </div>
            <div
              className='d-flex align-items-center gap-2 cursor-pointer btn btn-light bg-white p-2'
              onClick={() => setDeleteView(view)}
            >
              <i className='la la-trash' />
              <span>{t('data_view_action_delete')}</span>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <div className='sheet-view-view-list-wrapper p-3'>
      <div className='sheet-view-view-list-header-section pb-3'>
        <div className='d-flex align-items-center position-relative'>
          <i className='la fab la-sistrix position-absolute ms-2' />
          <input
            type='search'
            data-kt-user-table-filter='search'
            className='form-control form-control ps-10'
            placeholder={t('sheet_view_search_view_placeholder')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <div className='sheet-view-view-list-content-section gap-3 py-5' ref={viewListRef}>
        {listView && listView.length === 0 && (
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <i className='la fab la-sistrix fs-4' />
            <p className='text-center'>{t('sheet_view_search_no_matching')}</p>
          </div>
        )}
        {listView.map((view, index) => {
          return (
            <div
              className={`border border-none rounded ${
                selectedView?.code == view?.code ? 'bg-light-primary' : 'bg-secondary'
              }  h-35px p-2 d-flex align-items-center justify-content-between cursor-pointer`}
              onMouseLeave={onMouseLeaveView}
              onMouseEnter={() => onMouseEnterView(view)}
              key={index}
              onClick={() => onSelectView(view)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateViewName();
                }
              }}
            >
              <div className='d-flex align-items-center gap-2 mw-200px'>
                <i className={hoverView === view?.code ? 'la la-star-o' : viewIcon[view?.type]} />
                {view?.code === editView?.code ? (
                  <input
                    defaultValue={editView?.name !== '' ? editView?.name : editView?.type}
                    ref={inputViewNameRef}
                    onBlur={handleUpdateViewName}
                  />
                ) : (
                  <div className='text-capitalize text-truncate'>
                    {view?.name !== '' ? view?.name : t(`sheet_view_type_${view?.type}`)}
                  </div>
                )}
              </div>
              {hoverView === view?.code ? (
                <div className='d-flex gap-1 align-items-center'>
                  <OverlayTrigger
                    trigger='click'
                    placement='right'
                    overlay={viewActionPopover(view)}
                    rootClose={true}
                    rootCloseEvent={'click'}
                    onToggle={() => {}}
                  >
                    <i className='la la-chevron-circle-down' />
                  </OverlayTrigger>

                  <i className='la la-braille' />
                </div>
              ) : (
                <i className='la la-check' />
              )}
            </div>
          );
        })}
      </div>
      <div className='sheet-view-view-list-footer-section border-top'>
        <div
          className='d-flex align-items-center justify-content-between cursor-pointer p-2 mb-2'
          onClick={() => setShowCreateSection(!showCreateSection)}
        >
          <span>{t('common_add')}</span>
          <i className={showCreateSection ? 'la la-angle-down' : 'la la-angle-up'} />
        </div>
        {showCreateSection && (
          <div className='d-flex flex-column gap-2 mb-5'>
            {createableView?.map((view, index) => {
              return (
                <OverlayTrigger
                  trigger='click'
                  placement='right'
                  overlay={popover}
                  rootClose={true}
                  rootCloseEvent={'click'}
                  show={createView?.type === view?.type}
                  onToggle={() => onCreateView(view)}
                  key={index}
                >
                  <div className='btn btn-light bg-white d-flex justify-content-between align-items-center p-2'>
                    <div className='d-flex gap-2 align-items-center'>
                      <i className={viewIcon[view?.type]} />
                      <span>{t(view?.name)}</span>
                    </div>
                    <i className='la la-plus' />
                  </div>
                </OverlayTrigger>
              );
            })}
          </div>
        )}
      </div>
      <ModalDelete
        show={!isNil(deleteView)}
        close={() => setDeleteView(null)}
        title={t('data_view_action_delete_title', {
          name: deleteView?.name !== '' ? deleteView?.name : deleteView?.type,
        })}
        content={t('data_view_action_delete_confirm', {
          name: deleteView?.name !== '' ? deleteView?.name : deleteView?.type,
        })}
        onClick={handleDeleteView}
      />
    </div>
  );
};
const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default SheetViewViewList;
