export const NumberChartUnitOption = ({value, onChange}) => {
  const unitValue = value?.data?.metrics[0];
  const unitOption = ['none', '€', '$', '£', '%'];
  const unitPositionOption = [
    {text: 'L', value: 'prefix'},
    {text: 'R', value: 'suffix'},
  ];
  const handleChangeUnitOption = (value) => {
    onChange('unit', value);
    if (!unitValue?.unit_position && value !== 'none') {
      onChange('unit_position', 'suffix');
    }
  };
  return (
    <div className='d-flex justify-content-between w-100'>
      {unitOption.map((item, index) => (
        <button
          key={index}
          className={`btn btn-sm ${
            item === unitValue?.unit ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          onClick={() => {
            handleChangeUnitOption(item);
          }}
        >
          {item}
        </button>
      ))}
      <input
        type='text'
        value={!unitOption.includes(unitValue?.unit) ? unitValue?.unit : ''}
        className='py-2 rounded border border-gray-300 w-100px'
        placeholder='Tùy chỉnh'
        onFocus={() => onChange('unit', '')}
        onChange={(e) => handleChangeUnitOption(e.target.value)}
      />
      {unitPositionOption.map((item, index) => (
        <button
          key={index}
          className={`btn btn-sm ${
            item.value === unitValue?.unit_position ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          onClick={() => {
            onChange('unit_position', item.value);
          }}
        >
          {item.text}
        </button>
      ))}
    </div>
  );
};
