import {Input} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Field} from 'formik';
import {useMemo} from 'react';

// TODO: refactor code: duplicated code in wrapper className label, field
export const CommonFieldEditor = ({values}) => {
  const {t} = useTranslate();
  const formGroupStyle = useMemo(() => {
    if (values?.type === 'approval') {
      return {
        labelClassName: 'col-1',
        fieldClassName: 'col-11',
      };
    }

    return {
      labelClassName: 'col-3',
      fieldClassName: 'col-9',
    };
  }, [values?.type]);

  return (
    <>
      <div className='form-group row mb-2'>
        <div
          className={`d-flex align-items-center justify-content-end ${formGroupStyle.labelClassName}`}
        >
          <label className='text-end'>{t('sheet_column_setting_placeholder')}:</label>
        </div>
        <div className={formGroupStyle.fieldClassName}>
          <Field name='placeholder' component={Input} autoComplete='off' />
        </div>
      </div>
      <div className='form-group row mb-2'>
        <div
          className={`d-flex align-items-center justify-content-end ${formGroupStyle.labelClassName}`}
        >
          <label>{t('sheet_column_setting_width')}:</label>
        </div>
        <div className={formGroupStyle.fieldClassName}>
          <Field name='styles.width' component={Input} autoComplete='off' />
        </div>
      </div>
    </>
  );
};
