import React from 'react'
import { FormattedNumber } from 'react-intl'

function NumberChart({data,widget}) {
  const widgetMetrict = widget?.data?.metrics[0]
  const numberUnit = widgetMetrict?.unit
  const numberUnitPosition = widgetMetrict?.unit_position

  const getNumberValue = () => {
    const decimalPlaceType = widgetMetrict?.decimal_type
    const decimalPlaceValue = widgetMetrict?.decimal_value
    if(decimalPlaceType === 'manual' && decimalPlaceValue) return Number(data).toFixed(decimalPlaceValue)
    return data
  }

  return (
    <div className='d-flex justify-content-center align-items-center w-100 p-3'>
      {numberUnit && numberUnit !== 'none' && numberUnitPosition === 'prefix' && <span className='h2 fw-bold' style={{marginRight:'.25rem'}}>{numberUnit}</span>}
      <span className='h2 fw-bold'><FormattedNumber value={getNumberValue()}/></span>
      {numberUnit && numberUnit !== 'none' && numberUnitPosition === 'suffix' && <span className='h2 fw-bold' style={{marginLeft:'.25rem'}}>{numberUnit}</span>}
    </div>
  )
}

export default NumberChart