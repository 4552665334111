import { deleteApiV2, getApiV2, postApiIntegration, postApiV2, putApiV2 } from "../../../../core/store/api";

//export const MEETING_GET_URL = "/meeting/api/get-upcoming-meetings";
const MEETING_GET_URL =  "/api/meeting";
const MEETING_JOIN_URL = "/meeting/api/join-virtual-class";

const MEETING_NEW_URL = "/meeting/new-meeting";
const MEETING_UPDATE_URL = "/meeting/update";
const MEETING_DELETE_URL =  "/api/meeting/delete";
const MEETING_GET_DETAIL_URL = "/meeting/api/get-detail";
const MEETING_GET_STAT_URL = "/meeting/api/get-stat";


const endPoints = {
  newMeeting: '/api/meeting',
  myMeetings: '/api/meeting/my-meetings',
  meetingDetail: (meetingId) => `/api/meeting/public/${meetingId}`,
  meetingStat: (meetingId) => `/api/meeting/${meetingId}`,
  joinMeeting: (meetingId) => `/api/meeting/public/${meetingId}/join`,
  searchUser: '/api/user/search',
  deleteMeeting: (meetingId) => `/api/meeting/${meetingId}`,
  updateMeeting: (meetingId) => `/api/meeting/${meetingId}`,
  getSetting: `/api/account-setting`,
  updateSetting: `/api/account-setting`,
  aiAssistantCreateMeeting: `/api/ai-assistant/create-meeting`,
};

export function getMeetings(params) {
  return getApiV2(MEETING_GET_URL, params);
}

export function getMyMeetings(params) {
  return getApiV2(endPoints.myMeetings, params);
}

export function getMeeting(params) {
  return getApiV2(endPoints.meetingDetail(params.meetingId), {});
}


export function getMeetingStat(params) {
  return getApiV2(endPoints.meetingStat(params.meetingId), {});
}

export function newMeeting(params) {
  
  return postApiV2(endPoints.newMeeting, params);
}


export function joinMeeting(params) {
  return postApiV2(endPoints.joinMeeting(params.meetingId), params);
}



export function updateMeeting(params) {
  return putApiV2(endPoints.updateMeeting(params._id), params);
}

export function deleteMeeting(params) {
  var bodyFormData = new FormData();

  bodyFormData.append("id", params.meetingId);

  return deleteApiV2(endPoints.deleteMeeting(params.meetingId), bodyFormData);
}


export function getStaffs(params) {
  return postApiV2(endPoints.searchUser, {filter: {q: params.text}});
}

export function getUsers(params) {
  return postApiV2(endPoints.searchUser, { filter: {q: params.text}});
}

export function getAccountSetting(params) {
  return getApiV2(endPoints.getSetting);
}

export function updateAccountSetting(params) {
  return postApiV2(endPoints.updateSetting, params);
}

export function aiAssistantCreateMeeting(params) {
  return postApiIntegration(endPoints.aiAssistantCreateMeeting, params);
}