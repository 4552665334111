import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api';

const endPoints = {
  getWorkspaces: '/api/workspace',
  createWorkspace: '/api/workspace',
  moveToWorkspace: '/api/workspace/move',
  updateWorkspace: (id) => `/api/workspace/${id}`,
  deleteWorkspace: (id) => `/api/workspace/${id}`,
  workspacePages: (id) => `/api/workspace/${id}/pages`,
  deletePage: (id) => `/api/page/${id}`,
  updatePage: (id) => `/api/page/${id}`,
  saveAsTemplate: (id) => `/api/page/${id}/save-as-template`,
  movePage: (id) => `/api/page/${id}/move`,
  createPage: '/api/page',
  createDoc: '/api/document',
  getPage: (id) => `/api/page/${id}`,
  duplicatePage: (id) => `/api/page/${id}/duplicate`,
  getTemplates: `/api/template`,
  searchUser: '/api/user/search',
  removeTemplate: (templateId) => `/api/page/remove-template/${templateId}`,
  getUserSheetPermissions: (sheetId) => `/api/sheet/${sheetId}/my-permissions`,
  getFormDetail: (id) => `/api/sheet-form/form/${id}`,
  getFromByShareKey: (key) => `/api/sheet-form/by-share-key/${key}`,
  updateForm: (id) => `/api/sheet-form/${id}`,
};


export function getWorkspaces(_params) {
  return getApiV2(endPoints.getWorkspaces)
}


export function addWorkspace(params) {
  return postApiV2(endPoints.createWorkspace, params)
}

export function updateWorkspace(params, id) {
  return putApiV2(endPoints.updateWorkspace(id), params);
}

export function deleteWorkspace(id) {
  return deleteApiV2(endPoints.deleteWorkspace(id));
}

export function moveToWorkspace(params) {
  return postApiV2(endPoints.moveToWorkspace, params)
}


export function deletePage(params) {
  return deleteApiV2(endPoints.deletePage(params.pageId), params);
}

export function updatePage(params) {
  return postApiV2(endPoints.updatePage(params.pageId), params);
}

export function saveAsTemplate(params) {
  return postApiV2(endPoints.saveAsTemplate(params.pageId), params);
}


export function getPages(params) {
  return getApiV2(endPoints.workspacePages(params.workspace), params);
}


export function getTemplates(params) {
  return getApiV2(endPoints.getTemplates);
}
export function movePage(params) {
  return postApiV2(endPoints.movePage(params.id), params);
}


export function addPage(params) {
  return postApiV2(endPoints.createPage, params);
}

export function addDoc(params) {
  return postApiV2(endPoints.createDoc, params);
}


export function duplicatePage(params) {
  return postApiV2(endPoints.duplicatePage(params.fromId), params);
}


export function getPage(params) {
  return getApiV2(endPoints.getPage(params.id));
}

export function getUsers(params) {
  return postApiV2(endPoints.searchUser, {filter: {q: params.text}});
}

export function removeTemplate(templateId) {
  return deleteApiV2(endPoints.removeTemplate(templateId));
}

export function getUserSheetPermissions(params) {
  return getApiV2(endPoints.getUserSheetPermissions(params.sheetId), params);
}

export function getFormDetail(id) {
  return getApiV2(endPoints.getFormDetail(id));
}

export function getFromByShareKey(key, params) {
  return getApiV2(endPoints.getFromByShareKey(key), params);
}

export function updateForm(id, params) {
  return putApiV2(endPoints.updateForm(id), params);
}