import './sheetFilterView.scss';

import {Dropdown} from 'react-bootstrap';
import SheetCompletionFilter from './SheetCompletionFilter';
import {SheetDateFilter} from './SheetDateFilter';
import SheetDepartmentFilter from './SheetDepartmentFilter';
import SheetGroupFilter from './SheetGroupFilter';
import SheetPeopleFilter from './SheetPeopleFilter';
import SheetReferenceFilter from './SheetReferenceFilter';
import SheetApprovalFilter from './SheetApprovalFilter';
import SheetStatusFilter from './SheetStatusFilter';
import {ShowForSheetPermission} from '../../../permission/ShowForSheetPermission';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';
import SheetLookupFilter from './SheetLookupFilter';
import SheetLinkedFilter from './SheetLinkedFilter';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
const supportedColumnTypes = [
  'color',
  'status',
  'people',
  'date',
  'department',
  'reference',
  'approval',
  'lookup',
  'linked',
];

export default function SheetFilterView({
  sheet,
  view,
  applyFilter,
  activeFilters,
  filterActive,
  resetFilter,
  saveFilter,
  allowFilterByGroup = false,
  isEditMode,
}) {
  const {t} = useTranslate();
  const {user} = useSession();
  const innerHeightWindow = window.innerHeight;
  const renderColumnFilter = (column) => {
    return (
      <div className='p-2'>
        <div className='text-capitalize mb-2'>{column.name}</div>
        <div
          style={{maxHeight: `${innerHeightWindow - 340 > 300 ? 300 : innerHeightWindow - 340}px`}}
          className='overflow-auto'
        >
          {renderColumnFilterInner(column)}
        </div>
      </div>
    );
  };

  const renderCompletionColumnFilter = () => {
    return (
      <div className='p-2'>
        <div className='text-capitalize mb-2'>{t('sheet_filter_by_completion')}</div>
        <SheetCompletionFilter
          filter={{field: 'complete'}}
          applyFilter={applyFilter}
          filterValue={activeFilters?.complete}
        />
      </div>
    );
  };
  const renderGroupFilter = () => {
    return (
      <div className='p-2'>
        <div className='text-capitalize mb-2'>{t('sheet_filter_by_group')}</div>
        <SheetGroupFilter
          applyFilter={applyFilter}
          groups={sheet?.groups ?? []}
          activeFilters={activeFilters}
        />
      </div>
    );
  };

  const filterColumns = sheet?.columns?.filter(
    (column) => !column.deleted && supportedColumnTypes.includes(column.type)
  );

  const renderColumnFilterInner = (column) => {
    if (column.type === 'date') {
      return (
        <SheetDateFilter
          key={column.column}
          filter={column}
          column={column}
          applyFilter={applyFilter}
          filterValue={activeFilters?.[column?.field]}
        />
      );
    } else if (column.type === 'status' || column.type === 'color') {
      return (
        <SheetStatusFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'people') {
      return (
        <SheetPeopleFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'department') {
      return (
        <SheetDepartmentFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'reference') {
      return (
        <SheetReferenceFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'approval') {
      return (
        <SheetApprovalFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'lookup') {
      return (
        <SheetLookupFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'linked') {
      return (
        <SheetLinkedFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else {
      return (
        <Filter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          applyFilter={applyFilter}
          filterValue={activeFilters?.[column?.field]}
        />
      );
    }
  };

  const popover = () => {
    return (
      <>
        <div className='m-2 d-flex justify-content-between'>
          <div className='text-capitalize fs-4 fw-bold'>
            {t('sheet.view.toolbar.filter.quick_filter')}
          </div>
          <div>
            <button className='btn btn-sm btn-outline' onClick={() => resetFilter({})}>
              {t('sheet.view.toolbar.filter.clear')}
            </button>
            {isEditMode && (
              <ShowForSheetPermission permission='board.edit' sheet={sheet} user={user}>
                <button
                  className='btn btn-sm btn-outline btn-outline-primary ms-2'
                  onClick={() => saveFilter && saveFilter(view, activeFilters)}
                >
                  {t('sheet.view.toolbar.filter.save_to_view')}
                </button>
              </ShowForSheetPermission>
            )}
          </div>
        </div>
        <div className='d-flex flex-column filter-container p-2'>
          <div className='text-capitalize fs-6 fw-bold'>
            {t('sheet.view.toolbar.filter.all_columns')}
          </div>
          <div className='filter-container'>
            <div className='minw-150px align-top'>{renderCompletionColumnFilter()}</div>
            {allowFilterByGroup && (
              <div className='minw-150px align-top'>{renderGroupFilter()}</div>
            )}
            {filterColumns?.map((column, index) => {
              if (supportedColumnTypes.includes(column.type)) {
                return (
                  <div className='minw-150px align-top' key={index}>
                    {renderColumnFilter(column)}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <DropdownPopover
      renderPopover={popover}
      popoverContainerClassName={'shadow w-600px mh-500px p-2 d-flex overflow-hidden flex-column'}
    >
      <div className={`btn btn-sm ${filterActive ? 'btn-light-primary' : 'btn-light'}`}>
        Filter <i className='las la-filter'></i>
      </div>
    </DropdownPopover>
  );
}

function Filter({filter, column}) {
  return <div className='btn btn-sm btn-secondary'>{column.name}</div>;
}
