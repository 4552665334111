import {createContext, useContext, useEffect, useMemo, useState} from 'react';

import {useSession} from 'core/store/hooks';
import {useSheetData} from '../../../SheetDataHook';
import {useSheetViewEvent} from '../SheetViewEventHook';

export const WidgetDataContext = createContext({
  sheet: null,
  widget: null,
  items: [],
  subSheet: [],
  subItems: [],
});

export const WidgetDataProvider = ({children, value}) => {
  const {sheetId} = value;
  const {user, getDomain} = useSession();

  const [sheet, setSheet] = useState(null);
  const [subSheet, setSubSheet] = useState(null);
  const [innerSheetId, setInnerSheetId] = useState(sheetId);

  const eventHandler = useSheetViewEvent();
  const {
    viewItems: items,
    updateItem: onUpdateItem,
    fetchSheetItems,
    requestFetchSheetItem,
  } = useSheetData({
    id: innerSheetId,
    sheet,
    setSheet,
    user,
  });

  const {fetchSheetItems: fetchSheetSubItems, viewItems: subItems} = useSheetData({
    id: sheet?.sub_boards?.[0],
    setSheet: setSubSheet,
  });

  const providerValues = useMemo(
    () => ({
      ...value,
      sheet,
      sheetId: innerSheetId,
      items,
      subSheet,
      subItems,
      onUpdateItem,
      setInnerSheetId,
    }),
    [value, innerSheetId, sheet, items, subSheet, subItems, onUpdateItem]
  );

  useEffect(() => {
    if (sheet?.sub_boards?.[0]) {
      fetchSheetSubItems();
    }
  }, [sheet?.sub_boards?.[0]]);

  useEffect(() => {
    if (innerSheetId) {
      fetchSheetItems();
      eventHandler.initialize({
        domain: getDomain(),
        sheetId: innerSheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [innerSheetId]);

  useEffect(() => {
    if (sheetId) {
      setInnerSheetId(sheetId);
    }
  }, [sheetId]);

  return <WidgetDataContext.Provider value={providerValues}>{children}</WidgetDataContext.Provider>;
};

export const useWidgetViewContext = () => useContext(WidgetDataContext);
