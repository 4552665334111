import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useTranslate} from 'core/i18n/i18nProvider';
import NotificationView from './NotificationView';

function NotificationDrawer({show, handleClose}) {
  const {t} = useTranslate();

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{minWidth: '30vw'}}>
        <Offcanvas.Header className='d-flex flex-column gap-3'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <Offcanvas.Title>{t('notification.title')}</Offcanvas.Title>
            <div className='d-flex gap-5'>
              <div className='cursor-pointer' onClick={handleClose}>
                <i className='ki ki-close icon-xs text-muted' />
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <NotificationView onClose={handleClose} show={show}/>
      </Offcanvas>
    </>
  );
}

export default NotificationDrawer;
