import { getApiV2, postApi, postApiV2 } from "../api";

const DOMAIN_INFORMATION_URL =  "/site/get-information-by-domain";
const endPoints = {
  getUsers: '/api/user',
  getTeams: '/api/team',
  searchTeams: '/api/team/search',
  searchAppsPublish: '/api/app/my-available-apps',
};

export function getDomainInformation(params) {
  
  var bodyFormData = new FormData();
  bodyFormData.append("_sand_hostname",window.APP_HOSTNAME? window.APP_HOSTNAME: window.location.host);

  return postApiV2(DOMAIN_INFORMATION_URL, bodyFormData)
  
}

export function getAllUsers(params) {
  return getApiV2(endPoints.getUsers);
}

export function getAllTeams(params) {
  return getApiV2(endPoints.getTeams);
}

export function getCurrentUserInfo() {
  return getApiV2('/auth/me');
}

export function getApps(params) {
  return postApiV2(endPoints.searchAppsPublish, params);
}