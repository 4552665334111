import {AnalyticsBrowser} from '@segment/analytics-next';

const segmentApiKey = window.SEGMENT_KEY;
const listScreenTracker = [];

class Analytic {
  constructor() {
    if (segmentApiKey) {
      const analytics = new AnalyticsBrowser();
      this.client = analytics.load({writeKey: segmentApiKey});
    }
  }

  async trackNavigation(routeName, properties = {}) {
    if (listScreenTracker.includes(routeName) && this.client) {
      this.client.screen(routeName, properties);
    }
  }

  async trackAction(eventName, properties = {}) {
    if (this.client) {
      this.client.track(eventName, properties);
    }
  }

  async indetifyUser(user) {
    if (this.client) {
      const userId = user?._id;
      this.client.identify(userId, user);
    }
  }
}

export const AnalyticHandler = new Analytic();
