import {
  addSheetItemUpdateAction,
  deleteSheetItemUpdateAction,
  getSheetItemAuditsAction,
  getSheetItemUpdatesAction,
  saveSheetItemUpdateAction,
  uploadFileAction,
} from 'app/modules/work/sheet/stores/sheetActions'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

export const useItemDetail = (item) => {
  const [itemUpdates, setItemUpdates] = useState([])
  const [itemAudits, setItemAudits] = useState([])
  const [itemFiles, setItemFiles] = useState([])
  const [currentFile, setCurrentFile] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (item) {
      fetchItemUpdates()
      fetchItemAudits()
      fetchItemFiles()
    }
  }, [item])

  const fetchItemAudits = () => {
    dispatch(getSheetItemAuditsAction({itemId: item._id, type: 'comment'})).then((result) => {
      setItemAudits(result)
    })
  }

  const fetchItemUpdates = () => {
    dispatch(getSheetItemUpdatesAction({itemId: item._id, type: 'comment'})).then((result) => {
      setItemUpdates(result)
    })
  }

  const fetchItemFiles = () => {
    dispatch(getSheetItemUpdatesAction({itemId: item._id, type: 'file'})).then((result) => {
      setItemFiles(result)
    })
  }

  const addItemUpdate = (data) => {
    const params = {
      itemId: item._id,
      content: data.content,
      type: 'comment',
    }

    return dispatch(addSheetItemUpdateAction(params)).then(() => {
      fetchItemUpdates()
    })
  }

  const addItemFile = (file) => {
    const uploadFileParams = {
      file: file,
    }

    return dispatch(uploadFileAction(uploadFileParams)).then((result) => {
      const itemUpdateParams = {
        itemId: item._id,
        attachments: [result],
        type: 'file',
      }
      dispatch(addSheetItemUpdateAction(itemUpdateParams)).then(() => {
        fetchItemFiles()
      })
    })
  }

  const saveItemUpdate = (data, callback) => {
    const params = {
      itemId: item._id,
      itemUpdateId: data.id,
      content: data.content,
    }

    return dispatch(saveSheetItemUpdateAction(params)).then(() => {
      fetchItemUpdates()
      callback && callback()
    })
  }

  const deleteItemUpdate = (itemUpdate, callback) => {
    const params = {
      itemId: item._id,
      itemUpdateId: itemUpdate._id,
    }

    return dispatch(deleteSheetItemUpdateAction(params)).then(() => {
      callback && callback()
      fetchItemUpdates()
    })
  }

  return {
    itemAudits,
    itemUpdates,
    addItemUpdate,
    saveItemUpdate,
    itemFiles,
    addItemFile,
    currentFile,
    setCurrentFile,
    deleteItemUpdate,
    fetchItemFiles,
  };
}
