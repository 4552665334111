import {Modal, Form} from 'react-bootstrap';
import {Field, Formik} from 'formik';
import {Input} from 'app/common/_partials/controls';
import {ConfigLookupColumn} from '../edit/lookup/ConfigLookupColumn';
import {CommonFieldEditor} from '../edit/CommonFieldEditor';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useMemo} from 'react';
import * as Yup from 'yup';

const ConfigLookupSchema = Yup.object().shape({
  name: Yup.string().optional(),
  settings: Yup.object().shape({
    linked_field: Yup.object().required(),
    lookup_field: Yup.object().when('linked_field', {
      is: (linkedField) => linkedField?.label && linkedField?.value,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  }),
});

export default function ConfigLookupModal({
  initialConfig,
  sheetColumns = [],
  isLookupColumnModalVisible,
  setIsLookupColumnModalVisible,
  onCreateLookupColumn,
}) {
  const {t} = useTranslate();

  const linkedColumnOptions = useMemo(() => {
    if (!sheetColumns?.length) {
      return [];
    }

    const filteredLinkedColumns = sheetColumns?.filter(
      (column) => !column?.deleted && column?.type === 'linked' && column?.settings?.linked?.value
    );

    return filteredLinkedColumns?.map((column) => ({
      label: column?.name,
      value: column?.field,
      page_id: column?.settings?.linked?.value,
    }));
  }, [sheetColumns]);

  const isLinkedColumnExists = linkedColumnOptions?.length > 0;

  return (
    <Modal
      size='md'
      centered
      show={isLookupColumnModalVisible}
      onHide={() => setIsLookupColumnModalVisible(false)}
      style={{zIndex: 1000000}}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('sheet_column_add_title')}&nbsp;{t('sheet_column_type_lookup')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialConfig}
          validationSchema={ConfigLookupSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onCreateLookupColumn}
        >
          {({handleSubmit, setFieldValue, setErrors, values, errors}) => {
            return (
              <Form>
                {!isLinkedColumnExists && (
                  <div className='form-group row mb-4'>
                    <div className='col-12 text-center'>
                      <p className='m-0 fs-5 fw-bolder text-danger'>
                        <span className='icon-warning text-danger me-1'></span>
                        {t('sheet_column_type_linked_not_found_error_title')}
                      </p>
                      <p className='mb-0 mt-1'>
                        <span className='d-inline-block fs-7 fst-italic text-gray-500 w-350px'>
                          {t('sheet_column_type_linked_not_found_error_description')}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                <div className='form-group row mb-2'>
                  <div className='d-flex align-items-center justify-content-end col-3'>
                    <label>{t('sheet_column_setting_name')}:</label>
                  </div>
                  <div className='col-9'>
                    <Field
                      name='name'
                      component={Input}
                      autoComplete='off'
                      placeholder={values?.generatedColumnName}
                    />
                  </div>
                </div>
                <ConfigLookupColumn
                  values={values}
                  errors={errors}
                  setErrors={setErrors}
                  setFieldValue={setFieldValue}
                  linkedColumnOptions={linkedColumnOptions}
                />
                <CommonFieldEditor values={values} />
                <div className='form-group row mt-4'>
                  <div className='col-12 d-flex justify-content-end'>
                    <button
                      type='button'
                      onClick={() => setIsLookupColumnModalVisible(false)}
                      className='btn btn-secondary btn-elevate w-100px me-3'
                    >
                      {t('sheet_column_type_lookup_modal_cancel')}
                    </button>
                    <button
                      type='submit'
                      disabled={!isLinkedColumnExists}
                      onClick={(e) => {
                        e.preventDefault();
                        // use generated column name if name is empty
                        if (!values?.name && values?.generatedColumnName) {
                          setFieldValue('name', values?.generatedColumnName);
                        }
                        handleSubmit();
                      }}
                      className='btn btn-primary btn-elevate w-100px'
                    >
                      {t('common_save')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
