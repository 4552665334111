import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import {effectiveValue as getEffectiveValue} from 'app/modules/work/helpers';
import {stripTags} from 'core/helpers/string';
import {useMemo, useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import './lookupField.scss';

const PopoverContent = ({value}) => (
  <div className='p-2 w-200px'>
    <span>{value}</span>
  </div>
);

export default function LookupDataView({isHtmlString, lookupValue, title = ''}) {
  const dialogRef = useRef(null);
  const effectiveValue = getEffectiveValue(lookupValue);
  const [isDialogVisible, setDialogVisible] = useState(false);

  const displayedRichText = useMemo(() => {
    if (isHtmlString) {
      return <div dangerouslySetInnerHTML={{__html: lookupValue}}></div>;
    }

    return effectiveValue;
  }, [effectiveValue, isHtmlString, lookupValue]);

  // In the table view, display plain text instead rich text
  // Only display rich text in the modal/popover
  const displayedPlainText = useMemo(() => {
    if (isHtmlString) {
      const plainText = stripTags(effectiveValue);

      if (!plainText) {
        return '';
      }
      return plainText?.replace(/&nbsp;/g, '');
    }

    return effectiveValue;
  }, [effectiveValue, isHtmlString]);

  const handleModalClose = () => {
    setDialogVisible(false);
  };

  const handleDialogToggle = () => {
    setDialogVisible(!isDialogVisible);
  };

  return (
    <>
      <Modal centered size='lg' show={isDialogVisible} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{displayedRichText}</Modal.Body>
      </Modal>
      <div className='lookup-data-view-container'>
        <DropdownPopover
          ref={dialogRef}
          renderPopover={() => <PopoverContent value={displayedRichText} />}
          triggerClassName='overflow-hidden ps-1 text-ellipsis lookup-data-view-container__dropdown'
        >
          {displayedPlainText}
        </DropdownPopover>
        <span className='icon-expand me-1 cursor-pointer' onClick={handleDialogToggle}></span>
      </div>
    </>
  );
}
