import {Viewer as PdfViewer} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from 'app/common/_partials/controls';
import {Image, Ratio} from 'react-bootstrap';
import DocViewer, {DocViewerRenderers} from 'react-doc-viewer';
import ReactPlayer from 'react-player';
import FileSaver from 'file-saver';
import './itemFileAttachmentViewer.scss';
import {toAbsoluteUrl} from 'app/common/_helpers';

export function ItemFileAttachmentViewer({files = [], currentFile = null}) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const imageTypes = ['jpg', 'jpeg', 'png'];
  const videoTypes = ['mp4', 'mpg', 'mpeg'];
  const audioTypes = ['mp3', 'm4a'];
  const pdfTypes = ['pdf'];
  const zip = ['zip', 'rar'];
  const json = ['json'];

  const renderFile = () => {
    if (currentFile == null) {
      return <></>;
    } else if (imageTypes.includes(currentFile?.extension)) {
      return (
        <Image
          className='img-fluid image-file-preview rounded-left'
          src={currentFile.url || currentFile.link}
        />
      );
    } else if (videoTypes.includes(currentFile?.extension)) {
      return (
        <Ratio
          aspectRatio='16x9'
          className='border border-secondary w-100 h-100 image-file-preview'
        >
          <div className='embed-responsive-item'>
            <ReactPlayer
              url={currentFile.url || currentFile.link}
              controls
              playsinline
              style={{width: '100% ', height: '100%'}}
            />
          </div>
        </Ratio>
      );
    } else if (audioTypes.includes(currentFile?.extension)) {
      return (
        <div className='embed-responsive-item d-flex justify-content-center image-file-preview'>
          <audio controls>
            <source src={currentFile.link} type='audio/ogg' />
            <source src={currentFile.link} type='audio/mpeg' />
          </audio>
        </div>
      );
    } else if (pdfTypes.includes(currentFile?.extension)) {
      return (
        <div className='h-800px w-100 image-file-preview'>
          <PdfViewer
            fileUrl={currentFile?.url || currentFile.link}
            plugins={[defaultLayoutPluginInstance]}
          ></PdfViewer>
        </div>
      );
    } else if (zip.includes(currentFile?.extension)) {
      return (
        <Image
          className='img-fluid image-file-preview rounded-left h-300px'
          src={toAbsoluteUrl('/media/icons/duotune/files/zip.png')}
        />
      );
    } else if (json.includes(currentFile?.extension)) {
      return <iframe className='min-h-600px' src={currentFile?.url} title='json file' width='100%' height='100%'></iframe>;
    } else {
      return (
        <DocViewer
          prefetchMethod='GET'
          documents={[
            {
              uri: currentFile?.url || currentFile.link,
              fileType: currentFile?.extension,
            },
          ]}
          pluginRenderers={DocViewerRenderers}
          className='h-650px w-100 image-file-preview'
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false,
            },
          }}
        />
      );
    }
  };

  return (
    <>
      <Card className='mb-0'>
        <CardHeader title={currentFile?.original_name + '.' + currentFile?.extension}>
          <CardHeaderToolbar>
            <span
              className='btn btn-sm btn-icon btn-light-primary mr-2'
              onClick={() => {
                FileSaver.saveAs(
                   currentFile?.url,
                  `${currentFile?.original_name}.${currentFile?.extension}`
                );
              }}
            >
              <i className='flaticon2-download'></i>
            </span>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className='mb-0 d-flex justify-content-center'>{renderFile()}</CardBody>
      </Card>
    </>
  );
}