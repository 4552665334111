import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

const SheetDataViewShareAndSync = () => {
  const {t} = useTranslate();
  const popover = (
    <Popover>
      <Popover.Body className='p-2'>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center'>
          <i className='la la-link' />
          <span>{t('sheet_view_tool_share_create_link')}</span>
        </div>
        <div className='h-8px border-top mx-2'></div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center'>
          <i className='la la-bolt' />
          <span>{t('sheet_view_tool_sync_from_another')}</span>
        </div>
        <div className='h-8px border-top mx-2'></div>

        <div className='btn btn-light bg-white d-flex gap-2 align-items-center'>
          <i className='la la-code' />
          <span>{t('sheet_view_tool_embed')}</span>
        </div>
        <div className='h-8px border-top mx-2'></div>
        <div className='btn btn-light bg-white d-flex gap-2 align-items-center'>
          <i className='la fab la-wpforms' />
          <span>{t('sheet_view_tool_create_form_view')}</span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      overlay={popover}
      rootClose
      rootCloseEvent='click'
    >
      <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
        <i className='la la-share-square' />
        <span>{t('sheet_view_tool_share_and_sync')}</span>
      </div>
    </OverlayTrigger>
  );
};

export default SheetDataViewShareAndSync;
