import React, {  } from "react";

export function CheckBoxField({
  field,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  value="0",
  topLabel,
  withFeedbackLabel = false,
  customFeedbackLabel,
  isSelected, children,
  labelClass,
  ...props }) {

  //const [field] = useField(props);


  return (
    <>
    {topLabel && <label>{topLabel}</label>}
    <label className={`checkbox checkbox-lg checkbox-single ${labelClass}`}>
      <input type="checkbox"
      checked={field?.value}
      {...field}
      {...props}/>
      <span />&nbsp;{label}
    </label>
    </>
  );
}
