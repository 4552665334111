import {SelectSearch} from 'app/common/_partials/controls/SelectSearch';
import {isArray, set} from 'lodash';

function WidgetEditViewInnerPivot({sheet, editWidget, setEditWidget}) {
  const handlePivotChange = (params) => {
    const newPivots = isArray(params) ? params.map((pivot) => pivot.pivot) : [params.pivot];
    let _editWidget = {...editWidget};
    set(_editWidget, 'data.pivots', newPivots);
    setEditWidget(_editWidget);
  };
  return (
    <>
      <div className='mb-4'>
        <SelectSearch
          options={sheet.columns
            ?.filter((column) => !column?.deleted)
            ?.map((column) => ({
              label: column.name,
              value: column.field,
              pivot: {
                name: column.name,
                column: column.field,
                type: column.type,
              },
            }))}
          value={
            editWidget.data.pivots
              ? editWidget.data.pivots.map((pivot) => {
                  return {
                    label: pivot.name,
                    value: pivot.column,
                    pivot: pivot,
                  };
                })
              : []
          }
          onChange={handlePivotChange}
        ></SelectSearch>
      </div>
    </>
  );
}

export default WidgetEditViewInnerPivot;
