import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import AdvancedFilter from '../../../../toolbar/filter/advanced/AdvancedFilter';

const SheetDataViewFilter = ({sheet}) => {
  const {t} = useTranslate();
  const popover = (
    <Popover>
      <Popover.Body>
        <p>{t('sheet_view_tool_filter_description')}</p>
        <div className='h-8px border-top mx-2 mb-3'></div>
        <AdvancedFilter columns={sheet?.columns} />
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      overlay={popover}
      rootClose
      rootCloseEvent='click'
    >
      <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
        <i className='la la-filter' />
        <span>{t('sheet_view_tool_filter')}</span>
      </div>
    </OverlayTrigger>
  );
};

export default SheetDataViewFilter;
