import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  listLoading: false,
  joinLoading: false,
  actionsLoading: false,
  workspaces: [],
  workflows: [],
  countWorkflows: 0,
  workflowNodes: [],
  sheets: [],
  blocks: [],
  error: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const slice = createSlice({
  name: 'appWorkflow',
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (!action.payload) {
        console.error(`Invalid action payload: ${action.payload}`);
        return;
      }
      if (!action.payload.error) {
        console.error(`Invalid action error: ${action.payload.error}`);
        return;
      }
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        if (!state.listLoading) {
          console.error(`Invalid listLoading state: ${state.listLoading}`);
          return;
        }
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.join) {
        state.joinLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.join) {
        state.joinLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
  },
});
