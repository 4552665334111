import {useEffect, useState} from 'react';
import {ToggleButton} from 'react-bootstrap';

export default function SheetCompletionFilter({sheet, filter, applyFilter, filterValue}) {
  const [column, setColumn] = useState(null);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if(filterValue && filterValue?.value && filterValue?.value?.in){
      setValues(filterValue?.value?.in)
    }else{
      setValues([])
    }
  },[filterValue])

  useEffect(() => {
    let sheetColumn = null;
    sheet?.columns &&
      sheet.columns.forEach((column) => {
        if (column.field === filter.column) {
          sheetColumn = column;
        }
      });
    setColumn(sheetColumn);
  }, [sheet, filter]);

  const onToggleValue = (value) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.flatMap((v) => (v === value ? [] : [v]));
    } else {
      newValues.push(value);
    }
    setValues(newValues);

    applyFilter && applyFilter(filter, {in: newValues});
  };

  return (
    <div className='d-flex flex-column align-items-stretch '>
      <ToggleButton
        id='complete-check'
        type='checkbox'
        size='sm'
        variant='filter'
        className={`mb-2 text-start text-nowrap ${values.includes(true) ? 'bg-light-primary' : 'bg-secondary'}`}
        checked={values.includes(true)}
        value='1'
        onChange={(e) => onToggleValue(true)}
      >
        Đã hoàn thành
      </ToggleButton>
      <ToggleButton
        id='incomplete-check'
        type='checkbox'
        size='sm'
        variant='filter'
        className={`mb-2 text-start text-nowrap ${values.includes(false) ? 'bg-light-primary' : 'bg-secondary'}`}
        checked={values.includes(false)}
        value='1'
        onChange={(e) => onToggleValue(false)}
      >
        Chưa hoàn thành
      </ToggleButton>
    </div>
  );
}
