export const globalPermissions = {
  userManagement: 'user.edit',
  workspaceManagement: 'workspace.edit',
  appInstall: 'app.install',
};

const isRoot = (user, domain) => {
  let userRoles = user?.roles ?? [];
  return userRoles.includes(`${domain}:root`);
};
export const hasGlobalPermission = (user, permission, domain) => {
  const isRootUser = isRoot(user, domain);
  if (isRootUser) {
    return true;
  } else {
    return false;
  }
};
