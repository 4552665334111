export const NumberChartDecimalPlace = ({value, onChange}) => {
  const decimalPlaceValue = value?.data?.metrics[0];
  return (
    <div className='d-flex justify-content-between w-100'>
      <div className='d-flex gap-2'>
        <button
          className={`btn btn-sm ${
            'automatic' === decimalPlaceValue?.decimal_type ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          onClick={() => {
            onChange('decimal_type', 'automatic');
          }}
        >
          Automatic
        </button>
        <button
          className={`btn btn-sm ${
            'manual' === decimalPlaceValue?.decimal_type ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          onClick={() => {
            onChange('decimal_type', 'manual');
          }}
        >
          Manual
        </button>
      </div>
      <div className='d-flex gap-2'>
        <button
          className={`btn btn-sm ${
            'manual' === decimalPlaceValue?.decimal_type ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          disabled={'manual' !== decimalPlaceValue?.decimal_type}
          onClick={() => {
            let decimalValue = decimalPlaceValue?.decimal_value ?? 0;
            if (decimalValue === 0) return;
            onChange('decimal_value', decimalValue - 1);
          }}
        >
          <i className='las la-arrow-alt-circle-left'></i>
        </button>
        <button
          className={`btn btn-sm ${
            'manual' === decimalPlaceValue?.decimal_type ? 'btn-info' : 'btn-light'
          } text-capitalize`}
          disabled={'manual' !== decimalPlaceValue?.decimal_type}
          onClick={() => {
            let decimalValue = decimalPlaceValue?.decimal_value ?? 0;
            onChange('decimal_value', decimalValue + 1);
          }}
        >
          <i className='las la-arrow-alt-circle-right'></i>
        </button>
      </div>
    </div>
  );
};
