import React, {useMemo} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import moment from 'moment';
import {useTranslate} from 'core/i18n/i18nProvider';

function ApprovalDue({value, effValue}) {
  const {t} = useTranslate();
  const previousApprovalDateTime = effValue
    ? moment(effValue?.[0]?.dateTime * 1000)
    : moment();
  const tomorrow = previousApprovalDateTime.add(value?.completion_time, 'days');
  const duration = moment.duration(tomorrow.diff(moment()));
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours() % 24);
  const minutes = duration.minutes();

  const tooltipDiffTime = useMemo(() => {
    if (days > 0)
      return t('sheet_table_column_approval_day', {days: days, hours: hours, minutes: minutes});
    if (hours > 0) return t('sheet_table_column_approval_hour', {hours: hours, minutes: minutes});
    if (minutes > 0) return t('sheet_table_column_approval_minute', {minutes: minutes});
  }, [days, hours, minutes]);

  if (!tooltipDiffTime){
    return null
  }

  return (
    <>
      <OverlayTrigger
        key={'due-date-item'}
        placement='top'
        overlay={
          <Tooltip className={`tooltip-inverse`} id='due-date-item'>
            {t('sheet_table_column_approval_due_date')}: {tooltipDiffTime}
          </Tooltip>
        }
      >
        <span>
          {moment(value?.created_at).add(value.completion_time, 'days').format('DD/MM/yyyy')}
        </span>
      </OverlayTrigger>
    </>
  );
}

export default ApprovalDue;
