import {get, groupBy, isArray, isEmpty, isEqual, max, mean, min, set, sum} from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import {getAggregationName} from 'app/modules/work/helpers';
import {registerLocale} from 'react-datepicker';
import {startOfDay} from 'date-fns';
import {useIntl} from 'react-intl';
import {vi} from 'date-fns/locale';

registerLocale('vi', vi);

export const useDataAnalyzer = (sheet, widget, items, subItems, subSheet) => {
  const {formatDate} = useIntl();

  const [sheetColumnValues, setSheetColumnValues] = useState({});
  const [sheetColumnByField, setSheetColumnByField] = useState({});

  const [subSheetColumnValues, setSubSheetColumnValues] = useState({});
  const [subSheetColumnByField, setSubSheetColumnByField] = useState({});

  var hash = require('object-hash');

  useEffect(() => {
    let _sheetColumnValues = {};

    //Prepare choice columns (status, color) values for faster lookup
    if (sheet?.columns) {
      sheet.columns.forEach((col) => {
        if (col.values) {
          let colValues = {};
          col.values.forEach((colValue) => {
            colValues[colValue.value] = colValue;
          });
          _sheetColumnValues[col.field] = colValues;
        }
      });
      setSheetColumnValues(_sheetColumnValues);
    }
  }, [sheet]);

  useEffect(() => {
    let _subSheetColumnValues = {};

    //Prepare choice columns (status, color) values for faster lookup
    if (subSheet?.columns) {
      subSheet.columns.forEach((col) => {
        if (col.values) {
          let colValues = {};
          col.values.forEach((colValue) => {
            colValues[colValue.value] = colValue;
          });
          _subSheetColumnValues[col.field] = colValues;
        }
      });
      setSubSheetColumnValues(_subSheetColumnValues);
    }

    let _subSheetColumnByField = {};
    if (subSheet?.columns) {
      subSheet.columns.forEach((col) => {
        _subSheetColumnByField[col.field] = col;
      });
      setSubSheetColumnByField(_subSheetColumnByField);
    }
  }, [subSheet]);

  useEffect(() => {
    let _sheetColumnByField = {};
    if (sheet?.columns) {
      sheet.columns.forEach((col) => {
        _sheetColumnByField[col.field] = col;
      });
      setSheetColumnByField(_sheetColumnByField);
    }
  }, [sheet]);

  const getChartData = () => {
    let res = getMultiDimensionChartData();

    if (!isEmpty(res)) {
      res = sortReportResult(res);
    }

    if (widget.chart.type === 'battery') {
      res = sortReportForBatteryChart(res);
    }

    return res;
  };

  const getBatteryChartData = () => {
    let res = getMultiDimensionChartData();

    res = sortReportForBatteryChart(res);

    const subItemDimensionField = `dimensions.${widget.data.subitem_dimensions?.[0]?.column}.label`;
    const dimensionField = `dimensions.${widget.data.dimensions[0]?.column}.label`;

    const column = widget.data.dimensions[0]?.column;
    const columnOptions = sheet?.columns.find((item) => item.field === column)?.values || [];
    const subColumn = widget.data.subitem_dimensions?.[0]?.column;
    let subItemColumnOptions = [];
    if (subSheet) {
      subItemColumnOptions =
        subSheet?.columns.find((item) => item.field === subColumn)?.values || [];
    }
    let itemConfigs = [...columnOptions, ...subItemColumnOptions];
    res = res.map((item) => {
      const config = itemConfigs.find(
        (i) =>
          isEqual(i.label, get(item, dimensionField)) ||
          [i.value, i.label].includes(get(item, subItemDimensionField))
      );

      return {
        ...item,
        label: get(item, dimensionField) || get(item, subItemDimensionField) || null,
        backgroundColor: config?.backgroundColor,
      };
    });
    return res;
  };

  const getChartColumns = () => {
    const cols = [];
    widget.data.dimensions.forEach((dimension) => {
      cols.push({
        name: dimension.name,
        field: `dimensions.${dimension.column}.label`,
        key: dimension.name,
        type: 'dimension',
        extra: dimension,
      });
    });

    widget.data.metrics.forEach((metric, index) => {
      let name = metric.name ? metric.name : getAggregationName(metric.aggregation);
      if (!metric.name && metric.type === 'timeline') {
        name = getAggregationName(metric.aggregation) + ' ' + metric.name + ' (Phút)';
      }
      cols.push({
        name: name,
        field: `metrics["${metric.column}"][${metric.aggregation}]`,
        key: metric.name + index,
        type: 'metric',
        extra: metric,
      });
    });
    return cols;
  };

  const getNumberChartData = () => {
    let numberFunction = widget?.data?.metrics[0]?.aggregation ?? 'sum';
    let listNumber = [];
    items.forEach((item) => {
      widget.data.dimensions.forEach((dimension) => {
        const dimensionValue = get(item, dimension.column);
        if (dimensionValue?.value) {
          listNumber.push(parseFloat(dimensionValue?.value));
        }
      });
    });

    switch (numberFunction) {
      case 'sum':
        return sum(listNumber);
      case 'avg':
        return sum(listNumber) / listNumber.length;
      case 'min':
        return min(listNumber);
      case 'max':
        return max(listNumber);
      case 'median':
        return mean(listNumber);
      case 'count':
        return listNumber.length;
      case 'count_item':
        return items.length;
      default:
        return 0;
    }
  };

  //Multi dimensions, metrics
  const getMultiDimensionChartData = () => {
    let dataDimensions = {};
    const dimensions = widget.data.dimensions;

    if (!isEmpty(widget.data.dimensions)) {
      dataDimensions = calculateChartData(
        items,
        dimensions || [],
        widget.data.metrics,
        sheetColumnValues,
        sheetColumnByField
      );
    }

    let dataSubItemDimensions = {};
    if (!isEmpty(widget.data.subitem_dimensions)) {
      const subItemDimensions = widget.data.subitem_dimensions;
      const dataTempSubItemDimensions = calculateChartData(
        subItems,
        subItemDimensions,
        widget.data.metrics,
        subSheetColumnValues,
        subSheetColumnByField
      );
      //fix duplicate key between item and subitem.
      Object.keys(dataTempSubItemDimensions).forEach((key) => {
        dataSubItemDimensions[`subItem${key}`] = dataTempSubItemDimensions[key];
      });
    }

    const mergeDataDimensions = {...dataDimensions, ...dataSubItemDimensions};

    let data = Object.values(mergeDataDimensions);

    if (!isEmpty(data)) {
      data = sortReportResult(data);
    }

    return data;
  };

  const calculateChartData = (itemList, dimensions, metrics, columnValues, columnFields) => {
    const dataDimensions = {};
    if (!dimensions || isEmpty(dimensions)) {
      return {};
    }

    itemList.forEach((item) => {
      let dimensionsValue = {};
      dimensions.forEach((dimension) => {
        const dimensionValue = get(item, dimension?.column);

        let effectiveDimensionValue = dimensionValue ? dimensionValue.value || dimensionValue : '';
        let dimensionLabel = dimensionValue ? dimensionValue.label || dimensionValue : '';
        if (dimension?.type === 'date' && effectiveDimensionValue) {
          dimensionLabel = formatDate(effectiveDimensionValue * 1000);
          effectiveDimensionValue =
            Math.floor(effectiveDimensionValue / 24 / 60 / 60) * (24 * 60 * 60); //Round to date only
        } else if (dimension?.type === 'people') {
          dimensionLabel =
            effectiveDimensionValue?.length > 0 ? effectiveDimensionValue[0].name : '';
        } else if (dimension?.type === 'department') {
          const isArrayDimensionLabels = Array.isArray(effectiveDimensionValue);
          if (isArrayDimensionLabels) {
            dimensionLabel =
              effectiveDimensionValue?.length > 0 ? effectiveDimensionValue?.[0]?.name : '';
          } else {
            dimensionLabel = effectiveDimensionValue?.value || '';
          }
        } else if (
          dimension?.type &&
          (dimension.type === 'color' ||
            dimension.type === 'status' ||
            dimension.type === 'priority')
        ) {
          dimensionLabel =
            columnValues[dimension.column]?.[effectiveDimensionValue]?.label ||
            dimensionValue?.label ||
            effectiveDimensionValue;
        }
        if (dimension?.type === 'text') {
          dimensionLabel =
            columnValues[dimension.column]?.[effectiveDimensionValue]?.label ||
            effectiveDimensionValue;
        }
        if (!dimensionLabel) {
          dimensionLabel = '_';
        }

        const hasDimensionObjectValue =
          typeof effectiveDimensionValue === 'object' && !effectiveDimensionValue?.value;

        set(dimensionsValue, dimension?.column, {
          value: hasDimensionObjectValue ? '' : effectiveDimensionValue,
          label: dimensionLabel,
          is_sub_item: 1,
        });
      });

      let dimensionsValueHash = hash(dimensionsValue);

      if (!dataDimensions[dimensionsValueHash]) {
        dataDimensions[dimensionsValueHash] = {
          dimensions: dimensionsValue,
          metrics: {},
        };
        metrics.forEach((metric) => {
          dataDimensions[dimensionsValueHash]['metrics'][metric.column] = {
            count: 0,
            sum: 0,
            max: 0,
            min: 0,
          };
        });
      }

      metrics.forEach((metric) => {
        let dataMetricsValue = dataDimensions[dimensionsValueHash]['metrics'][metric.column];
        let itemMetricsColumnValue = get(item, metric.column)?.value
          ? parseFloat(get(item, metric.column).value)
          : 0;
        if (metric?.type === 'timeline') {
          const column = columnFields[metric.column];
          const itemMetricsColumn = get(item, metric.column)?.value || {};
          if (
            column?.timeline === 'dateTime' &&
            itemMetricsColumn.endDate &&
            itemMetricsColumn.startDate
          ) {
            const startOfDayStartDate =
              startOfDay(new Date(itemMetricsColumn.startDate * 1000)).valueOf() / 1000;
            const startOfDayEndDate =
              startOfDay(new Date(itemMetricsColumn.endDate * 1000)).valueOf() / 1000;
            itemMetricsColumnValue =
              ((startOfDayEndDate +
                itemMetricsColumn.endTime -
                (startOfDayStartDate + itemMetricsColumn.startTime)) /
                3600) *
              60;
          } else {
            if (itemMetricsColumn.endDate && itemMetricsColumn.startDate) {
              itemMetricsColumnValue =
                ((itemMetricsColumn.endDate - itemMetricsColumn.startDate) / 3600) * 60 + 1440;
            }
          }
        }
        dataMetricsValue = {
          count: dataMetricsValue.count + 1,
          sum: dataMetricsValue.sum + itemMetricsColumnValue,
          max:
            dataMetricsValue.max < itemMetricsColumnValue
              ? itemMetricsColumnValue
              : dataMetricsValue.max,
          min:
            dataMetricsValue.min > itemMetricsColumnValue
              ? itemMetricsColumnValue
              : dataMetricsValue.min,
        };
        dataDimensions[dimensionsValueHash]['metrics'][metric.column] = dataMetricsValue;
      });
    });

    return dataDimensions;
  };

  const sortReportResult = (arr) => {
    let sortColumns = widget?.data?.sorts || [];
    let columns = sortColumns.map((i) => i.value);

    if (isEmpty(sortColumns)) {
      sortColumns = widget?.data?.dimensions;
      columns = sortColumns.map((i) => i.column);
    }

    if (isEmpty(sortColumns)) {
      return arr;
    }

    const sortTypeNumber = widget?.data?.sort_type?.value === 'desc' ? -1 : 1;
    arr.sort((a, b) => {
      for (let i = 0; i < columns.length; i++) {
        let attr = `dimensions.${columns[i]}.value`;

        if (isArray(get(a, attr))) {
          attr = `dimensions.${columns[i]}.value.0.name`;
        }

        if (get(a, attr) < get(b, attr)) {
          return -sortTypeNumber;
        } else if (get(a, attr) > get(b, attr)) {
          return sortTypeNumber;
        }
        //case equal => check other column
      }
      return 0;
    });

    return arr;
  };

  const sortReportForBatteryChart = (arr) => {
    const columns = widget?.data?.dimensions.map((i) => i.column);

    // move done value to top
    const columnConfig = sheet?.columns.find((i) => i.field === columns?.[0]);
    const doneValue = columnConfig?.done_values?.[0];
    if (doneValue) {
      const index = arr.findIndex((i) => {
        return get(i, `dimensions.${columns?.[0]}.value`) === doneValue;
      });

      if (index !== -1) {
        const item = arr[index];
        arr.splice(index, 1);
        arr.unshift(item);
      }
    }

    return arr;
  };

  const getPivotChartColumns = () => {
    const cols = [];
    //Suppose pivot by last dimension column
    widget.data.dimensions.forEach((dimension) => {
      cols.push({
        name: dimension.name,
        field: `dimensions.${dimension.column}`,
        key: dimension.name,
        type: 'dimension',
        extra: dimension,
        sort: '',
      });
    });

    if (widget.data.pivots?.length > 0) {
      const pivotDimension = widget?.data?.pivots?.[0] || {};
      const pivotColumns = {};

      items.forEach((item) => {
        let pivotColumnValue =
          get(item, pivotDimension.column)?.value || get(item, pivotDimension.column);

        let displayPivotColumnValue = pivotColumnValue
          ? pivotColumnValue.value || pivotColumnValue
          : null;
        displayPivotColumnValue = displayPivotColumnValue ? displayPivotColumnValue : '';
        if (pivotDimension.type === 'date' && displayPivotColumnValue) {
          pivotColumnValue = getDatePartValue(pivotColumnValue);
          displayPivotColumnValue = formatDate(displayPivotColumnValue * 1000);
        } else if (pivotDimension.type === 'people' || pivotDimension.type === 'department') {
          displayPivotColumnValue =
            displayPivotColumnValue?.length > 0 ? displayPivotColumnValue[0].name : '';
        } else if (
          pivotDimension.type === 'color' ||
          pivotDimension.type === 'status' ||
          pivotDimension.type === 'priority'
        ) {
          displayPivotColumnValue =
            sheetColumnValues[pivotDimension.column]?.[displayPivotColumnValue]?.label ||
            displayPivotColumnValue;
        }
        if (!displayPivotColumnValue) {
          displayPivotColumnValue = '_';
        }
        if (pivotColumnValue && !pivotColumns[pivotColumnValue]) {
          widget.data.metrics.forEach((metric) => {
            cols.push({
              name: displayPivotColumnValue,
              field: `pivots.${pivotDimension.column}.${pivotColumnValue}.${escapeField(
                metric.column
              )}.${metric.aggregation}`,
              key: displayPivotColumnValue,
              type: 'pivot',
              extra: pivotDimension,
              sort: `pivots.${pivotDimension.column}.${pivotColumnValue}.${escapeField(
                metric.column
              )}.${metric.aggregation}`,
            });
            pivotColumns[pivotColumnValue] = 1;
          });
        }
      });
    }

    cols.sort(function (a, b) {
      let x = a.sort.toLowerCase();
      let y = b.sort.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    return cols;
  };

  //Multi dimensions, metrics
  const getPivotChartData = () => {
    let dataDimensions = {};
    const pivotDimension = widget.data.pivots?.length > 0 ? widget.data.pivots[0] : null;

    items.forEach((item) => {
      let dimensionsValue = {};
      widget.data.dimensions.forEach((dimension) => {
        const dimensionValue = get(item, dimension.column);
        let effectiveDimensionValue = dimensionValue
          ? dimensionValue.value || dimensionValue
          : null;
        let dimensionLabel = effectiveDimensionValue ? effectiveDimensionValue : '';
        if (dimension.type === 'date' && effectiveDimensionValue) {
          dimensionLabel = formatDate(effectiveDimensionValue * 1000);
        } else if (dimension.type === 'people' || dimension.type === 'department') {
          dimensionLabel =
            effectiveDimensionValue?.length > 0 ? effectiveDimensionValue[0].name : '';
        } else if (
          dimension.type === 'color' ||
          dimension.type === 'status' ||
          dimension.type === 'priority'
        ) {
          dimensionLabel =
            sheetColumnValues[dimension.column]?.[effectiveDimensionValue]?.label ||
            effectiveDimensionValue;
        }
        if (dimension.type === 'text') {
          dimensionLabel =
            sheetColumnValues[dimension.column]?.[effectiveDimensionValue]?.label ||
            effectiveDimensionValue;
        }
        if (!dimensionLabel) {
          dimensionLabel = '_';
        }
        set(dimensionsValue, dimension.column, {
          value: effectiveDimensionValue,
          label: dimensionLabel,
        });
      });

      let dimensionsValueHash = hash(dimensionsValue);
      if (!dataDimensions[dimensionsValueHash]) {
        dataDimensions[dimensionsValueHash] = {
          dimensions: dimensionsValue,
          pivots: {},
          metrics: {},
        };
      }

      if (pivotDimension) {
        if (!dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column]) {
          dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column] = {};
        }

        let pivotValue =
          get(item, pivotDimension.column)?.value || get(item, pivotDimension.column);
        if (pivotDimension.type === 'people') {
          pivotValue = pivotValue?.length > 0 ? pivotValue[0].name : '';
        } else if (pivotDimension.type === 'date') {
          pivotValue = getDatePartValue(pivotValue);
        }

        if (!dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column][pivotValue]) {
          dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column][pivotValue] = {};
        }

        widget.data.metrics.forEach((metric) => {
          if (
            !dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column][pivotValue][
              escapeField(metric.column)
            ]
          ) {
            dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column][pivotValue][
              escapeField(metric.column)
            ] = {
              count: 0,
              sum: 0,
              max: 0,
              min: 0,
            };
          }

          let dataMetricsValue =
            dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column][pivotValue][
              escapeField(metric.column)
            ];
          let itemMetricsColumnValue = get(item, metric.column)?.value
            ? parseFloat(get(item, metric.column).value)
            : 0;
          if (metric?.type === 'timeline') {
            const column = sheetColumnByField[metric.column];
            const itemMetricsColumn = get(item, metric.column)?.value || {};
            if (
              column.timeline === 'dateTime' &&
              itemMetricsColumn.endDate &&
              itemMetricsColumn.startDate
            ) {
              const startOfDayStartDate =
                startOfDay(new Date(itemMetricsColumn.startDate * 1000)).valueOf() / 1000;
              const startOfDayEndDate =
                startOfDay(new Date(itemMetricsColumn.endDate * 1000)).valueOf() / 1000;
              itemMetricsColumnValue =
                ((startOfDayEndDate +
                  itemMetricsColumn.endTime -
                  (startOfDayStartDate + itemMetricsColumn.startTime)) /
                  3600) *
                60;
            } else {
              if (itemMetricsColumn.endDate && itemMetricsColumn.startDate) {
                itemMetricsColumnValue =
                  ((itemMetricsColumn.endDate - itemMetricsColumn.startDate) / 3600) * 60 + 1440;
              }
            }
          }
          dataMetricsValue = {
            count: dataMetricsValue.count + 1,
            sum: dataMetricsValue.sum + itemMetricsColumnValue,
            max:
              dataMetricsValue.max < itemMetricsColumnValue
                ? itemMetricsColumnValue
                : dataMetricsValue.max,
            min:
              dataMetricsValue.min > itemMetricsColumnValue
                ? itemMetricsColumnValue
                : dataMetricsValue.min,
          };
          dataDimensions[dimensionsValueHash]['pivots'][pivotDimension.column][pivotValue][
            escapeField(metric.column)
          ] = dataMetricsValue;
        });
      }
    });

    let data = [];
    Object.keys(dataDimensions).forEach((dimensionHash) => {
      let row = {
        dimensions: dataDimensions[dimensionHash].dimensions,
        pivots: dataDimensions[dimensionHash].pivots,
      };
      data.push(row);
    });

    return data;
  };

  const getGanttChartData = useCallback(() => {
    const ganttItem = (dimension, item, project) => {
      let startTime = new Date();
      let endTime = new Date();
      if (dimension.type === 'timeline') {
        startTime = new Date(item[dimension.column].value.start * 1000);
        endTime = new Date(item[dimension.column].value.finish * 1000);
      }
      if (dimension.type === 'date') {
        startTime = new Date(item[dimension.column].value * 1000);
        endTime = new Date(item[dimension.column].value * 1000);
      }

      const itemKeys = Object.entries(item);
      const matchDependency = itemKeys.find((value) => /dependency/.test(value));
      const showDependency = sheet.columns?.some(
        (column) => !column[matchDependency?.[0]]?.deleted || !column[matchDependency?.[0]]?.hidden
      );

      const ganttTask = {
        id: item._id + dimension.column,
        start: startTime,
        end: endTime,
        name: item.title.value,
        type: 'task',
        field: dimension.column,
        item: item,
        column: dimension.type,
      };
      if (project) {
        ganttTask.project = project;
      }
      if (showDependency) {
        const dependencies = matchDependency?.[1]?.value?.map(
          (dependency) => dependency.id + dimension.column
        );
        ganttTask.dependencies = dependencies;
      }
      return ganttTask;
    };

    const ganttGroup = (id, name) => {
      return {
        start: new Date(),
        end: new Date(),
        id: id,
        name: name,
        styles: {backgroundColor: 'none'},
        hideChildren: false,
        isDisabled: true,
        type: 'project',
      };
    };

    if (items && sheet) {
      let groupItems = groupBy(items, ({group}) => group);
      if (widget?.data?.group?.column === 'group') {
        groupItems = groupBy(items, ({group}) => group);
      }
      if (widget?.data?.group?.column === 'people') {
        const newItems = [];
        items.forEach((item) => {
          const itemKeys = Object.keys(item);
          const match = itemKeys.find((value) => /^people/.test(value));
          if (match) {
            item[match].value.forEach((people) => {
              newItems.push({...item, peopleGroup: people._id});
            });
          }
        });
        groupItems = groupBy(newItems, ({peopleGroup}) => peopleGroup);
      }
      if (widget?.data?.group?.column === 'department') {
        const newItems = [];
        items.forEach((item) => {
          const itemKeys = Object.keys(item);
          const match = itemKeys.find((value) => /^department/.test(value));
          if (match) {
            item[match].value.forEach((department) => {
              newItems.push({...item, departmentGroup: department._id});
            });
          }
        });
        groupItems = groupBy(newItems, ({departmentGroup}) => departmentGroup);
      }
      if (widget?.data?.group?.column === 'status') {
        let groupKey = null;
        const newItems = items.map((item) => {
          const itemKeys = Object.keys(item);
          const match = itemKeys.find((value) => /^status/.test(value));
          if (match) {
            groupKey = match + 'Group';
            return {
              ...item,
              [groupKey]: item[match].value,
            };
          }
          return item;
        });
        groupItems = groupBy(newItems, (item) => item[groupKey] || 'not_status');
      }

      const dataChart = [];

      if (widget?.data?.group?.column === 'group' && sheet?.groups) {
        sheet.groups.forEach((group) => {
          dataChart.push(ganttGroup(group.id, group.title));
          widget.data.dimensions.forEach((dimension) => {
            groupItems[group.id]?.forEach((item) => {
              if (item[dimension.column]) {
                dataChart.push(ganttItem(dimension, item, group.id));
              }
            });
          });
        });
      }

      if (widget?.data?.group?.column === 'status') {
        const columnStatus = sheet.columns.find(
          (column) => column.field === widget?.data?.group?.column
        );
        columnStatus.values.forEach((status) => {
          if (groupItems[status.value]) {
            dataChart.push(ganttGroup(status.value, status.label));
          }
          if (!status.value) {
            dataChart.push(ganttGroup('not_status', 'Không có trạng thái'));
          }

          widget.data.dimensions.forEach((dimension) => {
            groupItems[status.value || 'not_status']?.forEach((item) => {
              if (item[dimension.column]) {
                dataChart.push(ganttItem(dimension, item, status.value));
              }
            });
          });
        });
      }
      if (widget?.data?.group?.column === 'people') {
        sheet.members.forEach((member) => {
          if (groupItems[member.id]) {
            dataChart.push(ganttGroup(member.id, member.name));
          }

          widget.data.dimensions.forEach((dimension) => {
            groupItems[member.id]?.forEach((item) => {
              if (item[dimension.column]) {
                dataChart.push(ganttItem(dimension, item, member.id));
              }
            });
          });
        });
      }
      if (widget?.data?.group?.column === 'department') {
        sheet.members.forEach((member) => {
          if (groupItems[member.id]) {
            dataChart.push(ganttGroup(member.id, member.name));
          }

          widget.data.dimensions.forEach((dimension) => {
            groupItems[member.id]?.forEach((item) => {
              if (item[dimension.column]) {
                dataChart.push(ganttItem(dimension, item, member.id));
              }
            });
          });
        });
      }

      if (!widget?.data?.group?.column) {
        widget.data.dimensions.forEach((dimension) => {
          items?.forEach((item) => {
            if (item[dimension.column]) {
              dataChart.push(ganttItem(dimension, item));
            }
          });
        });
      }
      return dataChart;
    }
    return [];
  }, [items, sheet, widget.data]);

  const getItemTableChartData = () => {
    return items;
  };

  const getSubItemByParentTableChartData = () => {
    return groupBy(subItems, (item) => {
      return item.parent_item_id;
    });
  };

  const columnItemTableChartData = () => {
    const view = sheet?.views.find((view) => view?.type === 'table-paging');
    let columns = sheet?.columns || [];

    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: false};
      });
      columns = [...listColumnsByView, ...listColumns];
    }

    return columns;
  };

  return {
    getChartData,
    getChartColumns,
    getPivotChartColumns,
    getPivotChartData,
    getGanttChartData,
    getNumberChartData,
    getItemTableChartData,
    getSubItemByParentTableChartData,
    columnItemTableChartData,
    getBatteryChartData,
  };
};

//Handle field that has . in name
function escapeField(field) {
  return field.replace('.', '_');
}

function getDatePartValue(timestamp) {
  return timestamp - (timestamp % (24 * 60 * 60));
}
