import {OverlayTrigger, Popover} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {listColumnEssentials, listColumnsSuperUseful} from '../../../../view/table/constants';

import {removeSign} from 'app/common/_helpers';
import {useTranslate} from 'core/i18n/i18nProvider';

const listColumns = [...listColumnsSuperUseful, ...listColumnEssentials];
const SheetDataViewHideColumn = ({sheet, view, updateView}) => {
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const {t} = useTranslate();

  const getColumn = () => {
    let listColumns = sheet?.columns;
    if (listColumns && sheet?.views) {
      let currentView = sheet?.views.find((sheetView) => sheetView?.code === view?.code);
      if (currentView?.columns) {
        let listColumnsByView = [];
        currentView.columns.forEach((item) => {
          let columnData = listColumns.find((column) => column?.field === item?.field);
          if (columnData) {
            listColumnsByView.push({
              ...columnData,
              hidden: item.hidden,
            });
          }
        });
        let listColumnsBySheet = listColumns.filter(
          (item) => !listColumnsByView.find((column) => column.field === item.field)
        );
        listColumnsBySheet = listColumnsBySheet.map((column) => {
          return {...column, hidden: false};
        });
        listColumns = [...listColumnsByView, ...listColumnsBySheet];
      } else {
        listColumns = listColumns.map((column) => {
          return {
            ...column,
            hidden: Boolean(column?.hidden),
          };
        });
      }
    }
    return listColumns;
  };
  useEffect(() => {
    if (sheet && view) {
      setColumns(getColumn());
    }
  }, [sheet, view]);

  const getColumnIcon = (field) => {
    let column = listColumns.find((column) => column.value === field);
    return column?.icon;
  };

  const onToggleColumnHidden = (field) => {
    const currentView = sheet?.views.find((sheetView) => sheetView.code === view.code);
    const newListColumns = columns.map((column) => {
      if (column.field === field) {
        return {...column, hidden: !column.hidden};
      }
      return column;
    });

    const newView = {...currentView, columns: newListColumns};
    setColumns(newListColumns);
    updateView && updateView(currentView, newView);
  };

  useEffect(() => {
    if (searchText && searchText !== '') {
      let searchString = removeSign(searchText.toLocaleLowerCase());
      let newListView = columns?.filter((column) => {
        let columnName = column?.name ?? '';
        columnName = removeSign(columnName.toLocaleLowerCase());
        return columnName.includes(searchString);
      });
      setColumns(newListView);
    } else {
      setColumns(getColumn() ?? []);
    }
  }, [searchText]);

  const onHideAllColumn = () => {
    const currentView = sheet?.views.find((sheetView) => sheetView.code === view.code);
    const newListColumns = columns.map((column) => {
      if (column.field !== 'title') {
        return {...column, hidden: true};
      }
      return column;
    });

    const newView = {...currentView, columns: newListColumns};
    setColumns(newListColumns);
    updateView && updateView(currentView, newView);
  };

  const onShowAllColumn = () => {
    const currentView = sheet?.views.find((sheetView) => sheetView.code === view.code);
    const newListColumns = columns.map((column) => {
      if (column.field !== 'complete') {
        return {...column, hidden: false};
      }
      return column;
    });

    const newView = {...currentView, columns: newListColumns};
    setColumns(newListColumns);
    updateView && updateView(currentView, newView);
  };

  const popover = (
    <Popover>
      <Popover.Body className='p-3 d-flex flex-column'>
        <div className='d-flex align-items-center position-relative mb-3'>
          <i className='la fab la-sistrix position-absolute ms-2' />
          <input
            type='search'
            data-kt-user-table-filter='search'
            className='form-control form-control ps-10'
            placeholder={t('sheet_view_search_view_placeholder')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className='h-200px overflow-auto'>
          {columns.map((column, index) => {
            return (
              <div
                key={index}
                className='btn btn-light bg-white p-2 d-flex justify-content-between align-items-center mb-1'
              >
                <div className='button-toggle-hidden'>
                  <div className='toggle-switch'>
                    <input
                      className='toggle-input'
                      id={`toggle-${column?.field}`}
                      type='checkbox'
                      checked={!column?.hidden}
                      disabled={column.field === 'title' || column.field === 'complete'}
                      onChange={() => onToggleColumnHidden(column?.field)}
                    />
                    <label className='toggle-label' htmlFor={`toggle-${column?.field}`}></label>
                  </div>
                  <i className={getColumnIcon(column?.field) ?? 'la la-th-list'} />
                  <span>{column.name}</span>
                </div>
                <i className='la la-braille' />
              </div>
            );
          })}
        </div>
        <div className='d-flex gap-3 mt-3 px-3'>
          <button className='btn btn-secondary py-2 px-3' onClick={onShowAllColumn}>
            {t('sheet_view_tool_show_all_column')}
          </button>
          <button className='btn btn-secondary py-2 px-3' onClick={onHideAllColumn}>
            {t('sheet_view_tool_hide_all_column')}
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      overlay={popover}
      rootClose
      rootCloseEvent='click'
    >
      <div className='btn btn-light bg-white p-2 sheet-data-view-toolbar-action gap-2'>
        <i className='la la-eye-slash' />
        <span>{t('sheet_view_tool_hide_column')}</span>
      </div>
    </OverlayTrigger>
  );
};

export default SheetDataViewHideColumn;
