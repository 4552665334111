import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {getViewInfoByType} from './helpers';
import {useTranslate} from 'core/i18n/i18nProvider';

export default function IconBarInterfaceViewMode(props) {
  const {type, code, index, selectedBarIcon, onSelectedView} = props;
  const {t} = useTranslate();
  const viewInfo = getViewInfoByType(type, t);

  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip
          className='tooltip-inverse tooltip-title-item position-fixed'
          id='tooltip-approval-creator'
        >
          <span>{viewInfo.viewName}</span>
        </Tooltip>
      }
    >
      <span
        className={`d-flex justify-content-center align-items-center p-2 cursor-pointer hover-secondary rounded ${
          selectedBarIcon === index ? 'bg-secondary' : ''
        }`}
        onClick={() => onSelectedView(code, index)}
      >
        {viewInfo.icon}
      </span>
    </OverlayTrigger>
  );
}
