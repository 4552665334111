// TODO: Need to refactor the grid style in '/public/grid-style.css'
import './grid.scss';
import DataGrid from 'react-data-grid';
import EmptyRows from './EmptyRows';

// Issue: missing header style
// https://github.com/adazzle/react-data-grid/issues/3463#issuecomment-2095388275

export default function GridView({
  columns,
  rows,
  className = '',
  onFill,
  onCopy,
  noDataText,
  headerRowHeight = 40,
  selectedRows,
  onColumnResize,
  onColumnsReorder,
  onSelectedRowsChange,
  ...props
}) {
  const rowKeyGetter = (row) => {
    return row?._id;
  };

  return (
    <DataGrid
      {...props}
      className={`rdg-light ${
        rows?.length ? 'data-grid-view' : 'data-grid-view-without-rows'
      } ${className}`}
      columns={columns}
      rows={rows}
      direction='ltr'
      onFill={onFill}
      renderers={{
        noRowsFallback: <EmptyRows noDataText={noDataText} />,
      }}
      headerRowHeight={headerRowHeight}
      rowKeyGetter={rowKeyGetter}
      selectedRows={selectedRows}
      onCopy={onCopy}
      onColumnResize={onColumnResize}
      onColumnsReorder={onColumnsReorder}
      onSelectedRowsChange={onSelectedRowsChange}
      onCellKeyDown={(cell, cellEvent) => {
        if (cell?.mode === 'EDIT') {
          if (cellEvent?.nativeEvent?.key === 'Enter') {
            cellEvent?.preventGridDefault();
          }
        }
      }}
    />
  );
}
