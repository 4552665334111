import React, {useEffect, useLayoutEffect} from 'react';
import WorkflowEditor from '../components/WorkflowEditor';
import {useParams} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  getSheetsAction,
  getWorkflowAction,
  getWorkflowBlocksAction,
  getWorkflowNodesAction,
  getWorkspacesAction,
  updateWorkflowAction,
} from '../stores/actions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ReactFlowProvider} from 'reactflow';
function WorkflowDetailPage({sheetList, onUpdateWorkflowSuccess}) {
  const dispatch = useDispatch();
  const {workflowId} = useParams();
  const {t} = useTranslate();
  const {workflowNodes, workflow} = useSelector((state) => state.workflow, shallowEqual);
  const snackNotification = useSnackNotification();

  useLayoutEffect(() => {
    if (!sheetList) {
      dispatch(getSheetsAction({}));
      dispatch(getWorkspacesAction({}));
    }
  }, []);

  useEffect(() => {
    dispatch(getWorkflowNodesAction({workflow_id: workflowId}));
    dispatch(getWorkflowBlocksAction({}));
    dispatch(getWorkflowAction(workflowId, {}));
  }, [workflowId]);

  const onUpdateWorkflow = (data) => {
    dispatch(updateWorkflowAction(workflowId, data)).then((res) => {
      if (res?.success) {
        dispatch(getWorkflowAction(workflowId, {}));
        onUpdateWorkflowSuccess && onUpdateWorkflowSuccess(res);
        snackNotification.showSuccess(t('common_save_success'));
      } else {
        snackNotification.showError(t('common_save_error'));
      }
    });
  };

  return (
    <div className='w-100 h-100 card'>
      <ReactFlowProvider>
        <WorkflowEditor
          workflowNodes={workflowNodes}
          workflow={workflow}
          onUpdateWorkflow={onUpdateWorkflow}
          sheetList={sheetList}
        />
      </ReactFlowProvider>
    </div>
  );
}

export default WorkflowDetailPage;
