import './lookupField.scss';
import cloneDeep from 'lodash/cloneDeep';
import {effectiveValue as getEffectiveValue} from 'app/modules/work/helpers';
import get from 'lodash/get';
import {useMemo} from 'react';
import {formatDate, getFormattedTimeline} from './helper';
import LookupDataView from './LookupDataView';

const SIMPLE_FIELD_TYPES = [
  'title',
  'text',
  'longtext',
  'number',
  'date',
  'timeline',
  'link',
  'custom_ai_prompt',
];

export default function LookupField({value, item, column, sheet, mode, containerClassName = ''}) {
  const effectiveValue = getEffectiveValue(value);
  const isDetailMode = mode === 'detail';
  const fieldType = get(column, ['settings', 'lookup_field', 'type']);

  const computedFieldValue = useMemo(() => {
    if (!effectiveValue) {
      return null;
    }

    const dateFormatPattern = column?.settings?.hasTime ? 'dd-MM-yyyy, HH:mm' : 'dd-MM-yyyy';

    if (Array.isArray(effectiveValue)) {
      const displayValues = effectiveValue.map((item) =>
        getEffectiveValue(get(item, ['reference_display_value']))
      );

      if (SIMPLE_FIELD_TYPES.includes(fieldType)) {
        if (!displayValues.length) {
          return null;
        }

        let formattedValues = cloneDeep(displayValues);

        if (fieldType === 'date') {
          formattedValues = displayValues.map((item) => formatDate(item, dateFormatPattern));
        }

        if (fieldType === 'timeline') {
          formattedValues = displayValues.map((item) =>
            getFormattedTimeline(item, dateFormatPattern)
          );
        }

        const isHtmlString = ['custom_ai_prompt', 'longtext'].includes(fieldType);

        if (isHtmlString) {
          formattedValues = displayValues.map((item) => {
            if (!item) return '';
            const closeTagMatch = item.match(/<\/[\w]>$/g);
            const htmlParts = item.split(/<\/[\w]>$/g);

            // Combine the main content, add a <span> with a non-breaking space, and append the closing tag (if any)
            return htmlParts[0] + '<span>;&nbsp</span>' + (closeTagMatch || '');
          });
        }

        return (
          <LookupDataView
            title={column?.name}
            isHtmlString={isHtmlString}
            lookupValue={formattedValues.join(isHtmlString ? '' : '; ')}
          />
        );
      } else {
        // TODO: Need to handle complex field type
        return null;
      }
    }

    return <span className='p-1'>{effectiveValue}</span>;
  }, [column?.name, column?.settings?.hasTime, effectiveValue, fieldType]);

  if (!computedFieldValue) {
    return null;
  }

  return <div className={`w-100 ${isDetailMode ? 'px-1' : 'p-1'}`}>{computedFieldValue}</div>;
}
