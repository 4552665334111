import '../dashboard/sheetDashboardView.scss';

import {Card, CardBody} from 'app/common/_partials/controls';
import {useEffect, useMemo, useState} from 'react';

import {Drawer} from '@material-ui/core';
import {ViewMode} from 'gantt-task-react';
import {WidgetDataProvider} from './WidgetDataProvider';
import WrapperWidgetView from './WrapperWidgetView';
import {useInterfaceThemeContext} from '../../sheet-view/interface-view/InterfaceThemeContext';
import {useSession} from 'core/store/hooks';
import {useSheetData} from '../../../SheetDataHook';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ReactSortable} from 'react-sortablejs';

export default function ConnectedDashboard({
  sheetId,
  sheet,
  view,
  setSheet,
  onUpdateView,
  onSaveFilter,
  editable,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
  },
  initMode = 'view',
  connectedSheets,
  renderToolbar,
}) {
  const {t} = useTranslate();
  const [editWidget, setEditWidget] = useState(null);
  const [widgets, setWidgets] = useState(view.widgets || []);
  const [mode, setMode] = useState(initMode);

  const {user} = useSession();
  const {backgroundColor, textColor} = useInterfaceThemeContext();

  const {
    updateItem: onUpdateItem,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
  } = useSheetData({id: sheetId, sheet, view, user, setSheet});

  const connectedSheetsOptions = useMemo(() => {
    if (!connectedSheets?.length) {
      return [];
    }

    return connectedSheets.map((sheet) => ({
      label: sheet?.name,
      value: sheet?._id,
    }));
  }, [connectedSheets]);

  const onUpdateWidget = (widget) => {
    const _widgets = widgets.map((aWidget) => {
      if (aWidget.id === widget.id) {
        return widget;
      } else {
        return aWidget;
      }
    });
    setWidgets(_widgets);
    setEditWidget(null);
    const updateView = {
      ...view,
      widgets: _widgets,
    };
    onUpdateView(view, updateView);
  };

  const onEditWidget = (widget) => {
    setEditWidget(widget);
  };

  const onHideEditWidget = () => {
    setEditWidget(null);
  };

  const onAddNew = () => {
    const newWidget = {
      id: new Date().getTime(),
      chart: {
        type: 'pie',
        view: ViewMode.Week,
        width: 6, // default width: 50%
      },
      data: {
        dateField: 'date',
        dimensions: [
          {
            column: 'status',
            type: 'status',
            name: 'Trạng thái',
          },
        ],
        metrics: [
          {
            aggregation: 'count',
            column: 'title',
            name: '',
          },
        ],
        group: {},
        limit: -1,
        sort: [],
      },
    };

    const _widgets = [...widgets, newWidget];
    setWidgets(_widgets);
    const updateView = {
      ...view,
      widgets: _widgets,
    };
    onUpdateView(view, updateView);
  };

  const onDeleteWidget = (widget) => {
    const _widgets = widgets.flatMap((aWidget) => {
      if (aWidget.id === widget.id) {
        return [];
      } else {
        return aWidget;
      }
    });
    setWidgets(_widgets);
    setEditWidget(null);
    const updateView = {
      ...view,
      widgets: _widgets,
    };
    onUpdateView(view, updateView);
  };

  const showAddButton = () => {
    return editable && mode === 'edit';
  };

  useEffect(() => {
    if (view) {
      setWidgets(view?.widgets ?? []);
    }
  }, [view]);

  useEffect(() => {
    if (initMode) {
      setMode(initMode);
    }
  }, [initMode]);

  const onDragDropEnds = (oldIndex, newIndex) => {
    let newWidgets = [...widgets];
    [newWidgets[oldIndex], newWidgets[newIndex]] = [newWidgets[newIndex], newWidgets[oldIndex]];
    const updateView = {
      ...view,
      widgets: newWidgets,
    };
    onUpdateView(view, updateView);
  };

  return (
    <>
      {options?.showToolbar && renderToolbar && (
        <>
          {renderToolbar({
            sheet: sheet,
            view: view,
            onFilterApply: applyFilter,
            onFilterReset: resetFilters,
            activeFilters: activeFilters,
            filterActive: filterActive,
            saveFilter: onSaveFilter,
            onAddNew: onAddNew,
            onSearch: applySearch,
            options: {...options?.userActions, add: false},
            changeMode: setMode,
            mode: mode,
            allowFilterByGroup: true,
            showAddButton: showAddButton(),
          })}
        </>
      )}
      <Card className='bg-transparent shadow-none'>
        <CardBody className='p-0'>
          {options?.userActions?.add && mode === 'edit' && (
            <div className='w-150px ms-auto me-3 mb-3'>
              <div
                onClick={onAddNew}
                className='rounded bg-light p-3 text-center fw-bolder bg-primary cursor-pointer'
                style={{background: backgroundColor, color: textColor}}
              >
                {t('sheet_view_dashboard_add')}
              </div>
            </div>
          )}
          <ReactSortable
            list={widgets.map((widget) => ({...widget, chosen: true}))}
            setList={(newList) => setWidgets(newList)}
            className={`grid-container`}
            disabled={mode !== 'edit'}
            onEnd={({oldIndex, newIndex}) => onDragDropEnds(oldIndex, newIndex)}
          >
            {widgets.map((widget) => {
              return (
                <div key={widget.id} className={`grid-col-${widget?.chart.width || 6}`}>
                  <WidgetDataProvider value={{sheetId: widget?.sheetId || sheetId}}>
                    <WrapperWidgetView
                      mode={mode}
                      widget={widget}
                      onEdit={onEditWidget}
                      onUpdate={onUpdateWidget}
                      onUpdateItem={onUpdateItem}
                    ></WrapperWidgetView>
                  </WidgetDataProvider>
                </div>
              );
            })}
          </ReactSortable>
        </CardBody>
      </Card>
      <Drawer
        BackdropProps={{invisible: true}}
        anchor='right'
        open={editWidget !== null}
        onClose={() => onHideEditWidget()}
      >
        {editWidget && (
          <WidgetDataProvider value={{sheetId: editWidget?.sheetId || sheetId}}>
            <WrapperWidgetView
              isEditWidget
              widget={editWidget}
              onUpdate={onUpdateWidget}
              onDelete={onDeleteWidget}
              onUpdateItem={onUpdateItem}
              connectedSheetsOptions={connectedSheetsOptions}
            ></WrapperWidgetView>
          </WidgetDataProvider>
        )}
      </Drawer>
    </>
  );
}
