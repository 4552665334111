import { transformListToTree } from "app/common/_helpers/FunctionHelper";
import { useTranslate } from "core/i18n/i18nProvider";
import { useEffect, useState } from "react";
import {useSelector, shallowEqual} from 'react-redux';
import clsx from 'clsx';
import TreeMenu from 'react-simple-tree-menu';

export const DialogMoveToSheet = ({onHide, onMoveGroupToSheet, group, sheet}) => {
  const {t} = useTranslate();
  const {currentWorkspacePages} = useSelector((state) => state.work, shallowEqual);
  const [tree, setTree] = useState([]);

   const convertNodeData = (node) => {
     if (node == null) {
       return null;
     }

     var convertedNode = {
       key: '' + node._id,
       label: node.name,
       parent: node.parent ? node.parent : null,
       nodes: [],
       node: node,
     };

     if (node.children && node.children.length > 0) {
       node.children.forEach((element) => {
         convertedNode.nodes.push(convertNodeData(element));
       });
     }
     if (convertedNode.nodes.length === 0) {
       delete convertedNode.nodes;
     }
     return convertedNode;
   };

   useEffect(() => {
     const treeData = transformListToTree(currentWorkspacePages.userPages, 'parent');
     if (treeData && treeData.length > 0) {
       const convertedTreeData = [];
       treeData.forEach((categoryItem) => {
         if (categoryItem._id) {
           convertedTreeData.push(convertNodeData(categoryItem));
         }
       });
       setTree(convertedTreeData);
     }
   }, [currentWorkspacePages]);

     const handleClickNode = (node) => {
       onMoveGroupToSheet(node, group);
     };

  return (
    <div className='d-flex flex-column p-2 w-300px min-h-200px'>
      <div className='dialog-header-sheet d-flex align-items-center justify-content-between mt-2'>
        <span className='font-weight-bold'>{t('sheet_move_dialog_move_to_sheet')}</span>
        <span className='me-2 p-2 bg-hover-light rounded' onClick={onHide}>
          {t('sheet_move_dialog_move_to_sheet_cancel')}
        </span>
      </div>
      <div className='d-flex flex-column mt-2'>
        <TreeMenu data={tree}>
          {({search, items}) => {
            return (
              <>
                {items.map(({key, ...props}) => {
                  return <ListItem key={key} {...props} onClickNode={handleClickNode} />;
                })}
              </>
            );
          }}
        </TreeMenu>
      </div>
    </div>
  );
};

const ListItem = ({
  level = 0,
  hasNodes,
  isOpen,
  label,
  searchTerm,
  openNodes,
  toggleNode,
  matchSearch,
  focused,
  handleToggleOpenNode,
  keyOpen,
  activeRootPath,
  onClickNode,
  ...props
}) => {
  const LEVEL_SPACE_CATEGORY = 17;

  const getPaddingLeft = () => {
    if (level !== 0) {
      return level * LEVEL_SPACE_CATEGORY + LEVEL_SPACE_CATEGORY;
    }
    return LEVEL_SPACE_CATEGORY;
  };

  return (
    <div
      {...props}
      className={`pt-3 pb-3 cursor-pointer position-relative rounded category ${clsx({
        'bg-hover-secondary': !hasNodes,
      })}`}
      style={{
        paddingLeft: `${getPaddingLeft()}px`,
      }}
    >
      {hasNodes ? (
        <>
          <div
            className='d-inline position-relative h-5px'
            onClick={(e) => {
              toggleNode();
              e.stopPropagation();
            }}
          >
            <ToggleIcon on={isOpen} />
          </div>
          <i className='las la-folder fs-3'></i>{' '}
          <span
            onClick={(e) => {
              toggleNode();
              e.stopPropagation();
            }}
          >
            {label}
          </span>
        </>
      ) : (
        <div className='d-flex align-items-center' onClick={() => onClickNode(props.node)}>
          <i className='las la-columns fs-3'></i> <span className='fw-normal ms-1'>{label}</span>
        </div>
      )}
    </div>
  );
};

const ToggleIcon = ({on}) => (
  <span className='mr-2 position-absolute icon-toggle-menu'>
    {on ? (
      <span className='svg-icon menu-icon d-inline-block w-10px'>
        <i className='fas fa-caret-down text-dark'></i>
      </span>
    ) : (
      <span className='svg-icon menu-icon d-inline-block w-10px'>
        <i className='fas fa-caret-right text-dark'></i>
      </span>
    )}
  </span>
);
