import {generateNameAvatar} from 'app/modules/work/helpers';
import {useSession} from 'core/store/hooks';
import React, {useEffect, useMemo, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {shallowEqual, useSelector} from 'react-redux';
import {APPROVAL_STATUS} from './contants';
import { cloneDeep } from 'lodash';

function ApprovalPeople({effValue, value, approvals, item, column, itemValue}) {
  const objectUser = useSelector((state) => state.core.usersByIds, shallowEqual);
  const {user} = useSession();
  const [approvalAssignee, setApprovalAssignee] = useState([]);
  const createBy = item?.created_by?.value;
  const approval = effValue?.find?.((approval) => approval?.assignee_role === value?.assignee_role);
  const assigneeRoleUser = column?.assignee_role_users?.find(
    (approval) => approval?.id === value?.assignee_role
  );

  const approvalCompleted = useMemo(
    () =>
      itemValue?.status === APPROVAL_STATUS.APPROVED ||
      itemValue?.status === APPROVAL_STATUS.REJECTED,
    [itemValue]
  );
  useEffect(() => {
    let listPeople = [];
    if (approval?.assignees) {
      listPeople = cloneDeep(approval.assignees);
    }
    for (let i = 0; i < assigneeRoleUser?.columns?.length; i++) {
      const column = assigneeRoleUser.columns?.[i];
      if (item?.[column?.value]?.value) {
        listPeople.push({...item?.[column?.value]?.value});
      }
    }
    setApprovalAssignee([...listPeople]);
  }, [approval?.assignees, assigneeRoleUser?.columns, item]);

  const avatar = (person) => {
    let personItem = {};
    if (person._id === user._id) {
      personItem = user;
    } else {
      personItem = objectUser[person._id] || {};
    }
    return (
      <div
        key={person._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={person?.name}
        data-original-title={person.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          <span className='position-absolute'>
            {person?.type && person?.type === 'team' ? (
              <i className='la la-user-friends' />
            ) : (
              generateNameAvatar(person?.name)
            )}
          </span>
          {personItem?.avatar && (
            <img
              src={personItem?.avatar}
              className='w-100 h-100 rounded-circle position-absolute'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          )}
        </span>
      </div>
    );
  };

  const displayTeam = (team, isShowComma, isShowTitle) => {
    let teamObject = {};
    if (team._id === user._id) {
      teamObject = user;
    } else {
      teamObject = objectUser[team._id] || {};
    }
    return (
      <div
        key={team._id}
        className='symbol symbol-20px symbol-circle border border-dark-75 text-uppercase'
        data-toggle='tooltip'
        title={isShowTitle ? team?.name : null}
        data-original-title={team.name}
      >
        <span className='symbol-label text-uppercase bg-secondary'>
          <span className='position-absolute'>
            <i className='la la-user-friends' />
          </span>
          {teamObject?.avatar && (
            <img
              src={teamObject?.avatar}
              className='w-100 h-100 rounded-circle position-absolute'
              style={{zIndex: 10}}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
              alt=''
            />
          )}
        </span>
      </div>
    );
  };

  if (!itemValue?.steps || itemValue?.steps?.length === 0) {
    return (
      <OverlayTrigger
        key='title-item'
        placement='top'
        overlay={
          <Tooltip
            className='tooltip-inverse tooltip-title-item position-fixed'
            id='tooltip-title-item'
          >
            {createBy?.name}
          </Tooltip>
        }
      >
        <span>{createBy?.name}</span>
      </OverlayTrigger>
    );
  }

  if (approvalCompleted) return <></>;

  return (
    <>
      <OverlayTrigger
        key='title-item'
        placement='top'
        overlay={
          <Tooltip className='tooltip-inverse tooltip-title-item' id='tooltip-title-item'>
            {approvalAssignee?.map((people) => people.name).join(', ')}
          </Tooltip>
        }
      >
        <>
          {approvalAssignee
            ?.map((member, index) => {
              return member.name;
            })
            .join(', ')}
        </>
      </OverlayTrigger>
    </>
  );
}

export default ApprovalPeople;
