import {getApiV2, postApiV2, putApiV2} from 'core/store/api';


const endPoints = {
  getFormDetail: (id) => `/api/sheet-form/form/${id}`,
  updateForm: (id) => `/api/sheet-form/${id}`,
  submitForm: (sheetId) => `/api/sheet-form/${sheetId}/submit`,
};

export function getFormDetail(id) {
  return getApiV2(endPoints.getFormDetail(id));
}

export function updateForm(id, params) {
  return putApiV2(endPoints.updateForm(id), params);
}

export function submitForm(params) {
  return postApiV2(endPoints.submitForm(params.sheetId), params);
}
