/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {get, isObject} from 'lodash';
import {FormattedNumber} from 'react-intl';
import './tableChart.scss';
import {effectiveValue} from 'app/modules/work/helpers';

export function TableChart({className, data = [], columns = []}) {

  function effectiveLabel(fieldLabel) {
    let effLabel = fieldLabel?.label;
    if (fieldLabel && fieldLabel.hasOwnProperty('label')) {
      effLabel = fieldLabel.label;
    } else if (fieldLabel) {
      effLabel = fieldLabel;
    } else {
      effLabel = null;
    }

    return effLabel;
  }

  const formatValue = (row, column) => {
    let fieldValueLabel = '';
    if (column?.field?.endsWith('.label')) {
      fieldValueLabel = effectiveLabel(get(row, column?.field));
    } else {
      fieldValueLabel = effectiveValue(get(row, column?.field));
    }
    if (['metric', 'pivot'].includes(column?.type)) {
      return <FormattedNumber value={fieldValueLabel}></FormattedNumber>;
    } else {
      if (isObject(fieldValueLabel)) {
        return fieldValueLabel.toString();
      } else {
        return fieldValueLabel;
      }
    }
  };

  return (
    <div className='table-chart-wrapper w-100 table-borderless overflow-auto rounded'>
      <table className='flex-grow-1 fixed_header w-100'>
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={`th-${column.key}`}
                  className={`p-2 fw-bold text-dark text-${
                    ['metric', 'pivot'].includes(column.type) ? 'end' : 'start'
                  }`}
                  scope='col'
                >
                  {column.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {columns.map((column) => {
                  return (
                    <td
                      key={`${column.key}`}
                      className={`p-2 text-dark text-${
                        ['metric', 'pivot'].includes(column.type) ? 'end' : 'start'
                      }`}
                    >
                      {formatValue(row, column)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
