import React from 'react';
import TextValue from './text/TextValue';
import NumberValue from './number/NumberValue';
import DateValue from './date/DateValue';
import ColorValue from './color/ColorValue';
import PeopleValue from './people/PeopleValue';

function ConfigValue({
  value,
  item,
  column,
  sheet,
  onChange,
  disabled
}) {
  const type = column?.type ?? '';

  const renderValue = () => {
    if (type === 'date') {
      return (
        <DateValue
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          disabled={disabled}
        />
      );
    } else if (type === 'number') {
      return (
        <NumberValue
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          disabled={disabled}
        />
      );
    } else if (type === 'people') {
      return (
        <PeopleValue
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          disabled={disabled}
        />
      );
    } else if (type === 'color' || type === 'status' || type === 'priority') {
      return (
        <ColorValue
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          disabled={disabled}
        />
      );
    } else {
      return (
        <TextValue
          value={value}
          item={item}
          column={column}
          sheet={sheet}
          onChange={onChange}
          disabled={disabled}
        />
      );
    }
  };

  return <>{renderValue()}</>;
}

export default ConfigValue;
